/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal Client Api
 * Bumbal API documentation
 *
 * The version of the OpenAPI document: 2.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

 import { exists, mapValues, BaseModel } from '../runtime';
 import {
     AddressModel,
     AddressModelFromJSON,
     AddressModelFromJSONTyped,
     AddressModelToJSON,
     DriverUnavailabilityModel,
     DriverUnavailabilityModelFromJSON,
     DriverUnavailabilityModelFromJSONTyped,
     DriverUnavailabilityModelToJSON,
     LinkModel,
     LinkModelFromJSON,
     LinkModelFromJSONTyped,
     LinkModelToJSON,
     MetaDataModel,
     MetaDataModelFromJSON,
     MetaDataModelFromJSONTyped,
     MetaDataModelToJSON,
     TagModel,
     TagModelFromJSON,
     TagModelFromJSONTyped,
     TagModelToJSON,
     ZoneModel,
     ZoneModelFromJSON,
     ZoneModelFromJSONTyped,
     ZoneModelToJSON,
 } from './';


 export interface UsersModelInterface {
     id?: number;
     uuid?: string;
     role_id?: number;
     role_name?: UsersModelRoleNameEnum;
     party_id?: number;
     party_name?: string;
     first_name?: string;
     name_prefix?: string;
     last_name?: string;
     full_name?: string;
     initials?: string;
     email?: string;
     password?: string;
     lang_code?: string;
     address_id?: number;
     addresses?: Array<AddressModel>;
     pause_id?: number;
     speed_factor?: number;
     duration_factor?: number;
     activated?: boolean;
     removed?: boolean;
     active?: boolean;
     tags?: Array<TagModel>;
     tag_names?: Array<string>;
     zones?: Array<ZoneModel>;
     zone_names?: Array<string>;
     links?: Array<LinkModel>;
     meta_data?: Array<MetaDataModel>;
     driver_unavailabilities?: Array<DriverUnavailabilityModel>;
 }

 /**
  *
  * @export
  * @interface UsersModel
  */
 export class UsersModel extends BaseModel<UsersModelInterface> {

     /**
      *
      * @type {number}
      * @memberof UsersModel
      */
     id?: number;
     /**
      * unique per user
      * @type {string}
      * @memberof UsersModel
      */
     uuid?: string;
     /**
      * id of the user role, 1: Guest, 2: Driver, 3: Planner, 4: Manager, 5: Admin
      * @type {number}
      * @memberof UsersModel
      */
     role_id?: number;
     /**
      * Role name
      * @type {string}
      * @memberof UsersModel
      */
     role_name?: UsersModelRoleNameEnum;
     /**
      * Associated Party ID
      * @type {number}
      * @memberof UsersModel
      */
     party_id?: number;
     /**
      *
      * @type {string}
      * @memberof UsersModel
      */
     party_name?: string;
     /**
      * First name
      * @type {string}
      * @memberof UsersModel
      */
     first_name?: string;
     /**
      * Name prefix
      * @type {string}
      * @memberof UsersModel
      */
     name_prefix?: string;
     /**
      * Last name
      * @type {string}
      * @memberof UsersModel
      */
     last_name?: string;
     /**
      * Full name
      * @type {string}
      * @memberof UsersModel
      */
     full_name?: string;
     /**
      * user password (set only, no read)
      * @type {string}
      * @memberof UsersModel
      */
     initials?: string;
     /**
      * user email (used for login)
      * @type {string}
      * @memberof UsersModel
      */
     email?: string;
     /**
      * user password (set only, no read)
      * @type {string}
      * @memberof UsersModel
      */
     password?: string;
     /**
      * lang code (nl = default)
      * @type {string}
      * @memberof UsersModel
      */
     lang_code?: string;
     /**
      * id of the user address
      * @type {number}
      * @memberof UsersModel
      */
     address_id?: number;
     /**
      * user address (mostly interesting for drivers)
      * @type {Array<AddressModel>}
      * @memberof UsersModel
      */
     addresses?: Array<AddressModel>;
     /**
      * id of the pause to be applied by default for new user routes
      * @type {number}
      * @memberof UsersModel
      */
     pause_id?: number;
     /**
      * Speed Factor
      * @type {number}
      * @memberof UsersModel
      */
     speed_factor?: number;
     /**
      * Duration Factor
      * @type {number}
      * @memberof UsersModel
      */
     duration_factor?: number;
     /**
      * Whether user is activated or not
      * @type {boolean}
      * @memberof UsersModel
      */
     activated?: boolean;
     /**
      * Whether user is removed or not
      * @type {boolean}
      * @memberof UsersModel
      */
     removed?: boolean;
     /**
      * Whether user is still active or not
      * @type {boolean}
      * @memberof UsersModel
      */
     active?: boolean;
     /**
      *
      * @type {Array<TagModel>}
      * @memberof UsersModel
      */
     tags?: Array<TagModel>;
     /**
      * Tag names
      * @type {Array<string>}
      * @memberof UsersModel
      */
     tag_names?: Array<string>;
     /**
      *
      * @type {Array<ZoneModel>}
      * @memberof UsersModel
      */
     zones?: Array<ZoneModel>;
     /**
      * Zone names
      * @type {Array<string>}
      * @memberof UsersModel
      */
     zone_names?: Array<string>;
     /**
      *
      * @type {Array<LinkModel>}
      * @memberof UsersModel
      */
     links?: Array<LinkModel>;
     /**
      *
      * @type {Array<MetaDataModel>}
      * @memberof UsersModel
      */
     meta_data?: Array<MetaDataModel>;
     /**
      *
      * @type {Array<DriverUnavailabilityModel>}
      * @memberof UsersModel
      */
     driver_unavailabilities?: Array<DriverUnavailabilityModel>;

     constructor(data?: UsersModelInterface) {
         super(data);
         Object.assign(this, data ?? {});
     }

     static toJson(value: UsersModelInterface): { [property: string]: any } {
         return {};
     }

 }

 export function UsersModelFromJSON(json: any): UsersModel {
     return UsersModelFromJSONTyped(json, false);
 }

 export function UsersModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): UsersModel {
     if ((json === undefined) || (json === null)) {
         return json;
     }
     let createClass = UsersModel;
     try {
         let localClass = require.context('../override', true)('./UsersModel');
         if (localClass != null) {
             createClass = localClass.default;
         }
     } catch (e) {}
     return new createClass({

         'id': !exists(json, 'id') ? undefined : Number(json['id']),
         'uuid': !exists(json, 'uuid') ? undefined : json['uuid'],
         'role_id': !exists(json, 'role_id') ? undefined : Number(json['role_id']),
         'role_name': !exists(json, 'role_name') ? undefined : json['role_name'],
         'party_id': !exists(json, 'party_id') ? undefined : Number(json['party_id']),
         'party_name': !exists(json, 'party_name') ? undefined : json['party_name'],
         'first_name': !exists(json, 'first_name') ? undefined : json['first_name'],
         'name_prefix': !exists(json, 'name_prefix') ? undefined : json['name_prefix'],
         'last_name': !exists(json, 'last_name') ? undefined : json['last_name'],
         'full_name': !exists(json, 'full_name') ? undefined : json['full_name'],
         'initials': !exists(json, 'initials') ? undefined : json['initials'],
         'email': !exists(json, 'email') ? undefined : json['email'],
         'password': !exists(json, 'password') ? undefined : json['password'],
         'lang_code': !exists(json, 'lang_code') ? undefined : json['lang_code'],
         'address_id': !exists(json, 'address_id') ? undefined : Number(json['address_id']),
         'addresses': !exists(json, 'addresses') ? undefined : ((json['addresses'] as Array<any>).map(AddressModelFromJSON)),
         'pause_id': !exists(json, 'pause_id') ? undefined : Number(json['pause_id']),
         'speed_factor': !exists(json, 'speed_factor') ? undefined : Number(json['speed_factor']),
         'duration_factor': !exists(json, 'duration_factor') ? undefined : Number(json['duration_factor']),
         'activated': !exists(json, 'activated') ? undefined : json['activated'],
         'removed': !exists(json, 'removed') ? undefined : json['removed'],
         'active': !exists(json, 'active') ? undefined : json['active'],
         'tags': !exists(json, 'tags') ? undefined : ((json['tags'] as Array<any>).map(TagModelFromJSON)),
         'tag_names': !exists(json, 'tag_names') ? undefined : json['tag_names'],
         'zones': !exists(json, 'zones') ? undefined : ((json['zones'] as Array<any>).map(ZoneModelFromJSON)),
         'zone_names': !exists(json, 'zone_names') ? undefined : json['zone_names'],
         'links': !exists(json, 'links') ? undefined : ((json['links'] as Array<any>).map(LinkModelFromJSON)),
         'meta_data': !exists(json, 'meta_data') ? undefined : ((json['meta_data'] as Array<any>).map(MetaDataModelFromJSON)),
         'driver_unavailabilities': !exists(json, 'driver_unavailabilities') ? undefined : ((json['driver_unavailabilities'] as Array<any>).map(DriverUnavailabilityModelFromJSON)),
     });
 }

 export function UsersModelToJSON(value?: UsersModel | null): any {
     if (value === undefined) {
         return undefined;
     }
     if (value === null) {
         return null;
     }
     let createClass = UsersModel;
     try {
         let localClass = require.context('../override', true)('./UsersModel');
         if (localClass != null) {
             createClass = localClass.default;
         }
     } catch (e) {}
     return {

         'id': value.id,
         'uuid': value.uuid,
         'role_id': value.role_id,
         'role_name': value.role_name,
         'party_id': value.party_id,
         'party_name': value.party_name,
         'first_name': value.first_name,
         'name_prefix': value.name_prefix,
         'last_name': value.last_name,
         'full_name': value.full_name,
         'initials': value.initials,
         'email': value.email,
         'password': value.password,
         'lang_code': value.lang_code,
         'address_id': value.address_id,
         'addresses': value.addresses === undefined ? undefined : ((value.addresses as Array<any>).map(AddressModelToJSON)),
         'pause_id': value.pause_id,
         'speed_factor': value.speed_factor,
         'duration_factor': value.duration_factor,
         'activated': value.activated,
         'removed': value.removed,
         'active': value.active,
         'tags': value.tags === undefined ? undefined : ((value.tags as Array<any>).map(TagModelToJSON)),
         'tag_names': value.tag_names,
         'zones': value.zones === undefined ? undefined : ((value.zones as Array<any>).map(ZoneModelToJSON)),
         'zone_names': value.zone_names,
         'links': value.links === undefined ? undefined : ((value.links as Array<any>).map(LinkModelToJSON)),
         'meta_data': value.meta_data === undefined ? undefined : ((value.meta_data as Array<any>).map(MetaDataModelToJSON)),
         'driver_unavailabilities': value.driver_unavailabilities === undefined ? undefined : ((value.driver_unavailabilities as Array<any>).map(DriverUnavailabilityModelToJSON)),
         ...createClass.toJson(value),
     };
 }

 /**
 * @export
 * @enum {string}
 */
 export enum UsersModelRoleNameEnum {
     Guest = 'Guest',
     Driver = 'Driver',
     Planner = 'Planner',
     Manager = 'Manager',
     Admin = 'Admin'
 }


