/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal Client Api
 * Bumbal API documentation
 *
 * The version of the OpenAPI document: 2.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiResponse39,
    ApiResponse39FromJSON,
    ApiResponse39ToJSON,
    ApiResponse40,
    ApiResponse40FromJSON,
    ApiResponse40ToJSON,
    ApiResponse401,
    ApiResponse401FromJSON,
    ApiResponse401ToJSON,
    ApiResponse403,
    ApiResponse403FromJSON,
    ApiResponse403ToJSON,
    ApiResponse405,
    ApiResponse405FromJSON,
    ApiResponse405ToJSON,
    ApiResponse41,
    ApiResponse41FromJSON,
    ApiResponse41ToJSON,
    ApiResponse42,
    ApiResponse42FromJSON,
    ApiResponse42ToJSON,
    ApiResponse43,
    ApiResponse43FromJSON,
    ApiResponse43ToJSON,
    ApiResponse44,
    ApiResponse44FromJSON,
    ApiResponse44ToJSON,
    ModelApiResponse,
    ModelApiResponseFromJSON,
    ModelApiResponseToJSON,
    QuestionnaireTemplateQuestionOptionListResponse,
    QuestionnaireTemplateQuestionOptionListResponseFromJSON,
    QuestionnaireTemplateQuestionOptionListResponseToJSON,
    QuestionnaireTemplateQuestionOptionModel,
    QuestionnaireTemplateQuestionOptionModelFromJSON,
    QuestionnaireTemplateQuestionOptionModelToJSON,
    QuestionnaireTemplateQuestionOptionPossibleQuestionsModel,
    QuestionnaireTemplateQuestionOptionPossibleQuestionsModelFromJSON,
    QuestionnaireTemplateQuestionOptionPossibleQuestionsModelToJSON,
    QuestionnaireTemplateQuestionOptionRetrieveListArguments,
    QuestionnaireTemplateQuestionOptionRetrieveListArgumentsFromJSON,
    QuestionnaireTemplateQuestionOptionRetrieveListArgumentsToJSON,
} from '../models';

export interface CreateQuestionnaireTemplateQuestionOptionRequest {
    body?: QuestionnaireTemplateQuestionOptionModel;
}

export interface DeleteQuestionnaireTemplateQuestionOptionRequest {
    questionnaireTemplateQuestionOptionId: number;
}

export interface FindPossibleFollowUpQuestionsRequest {
    body?: QuestionnaireTemplateQuestionOptionPossibleQuestionsModel;
}

export interface GetPossibleFollowUpQuestionsRequest {
    questionnaireTemplateQuestionId: number;
}

export interface RetrieveListQuestionnaireTemplateQuestionOptionRequest {
    arguments: QuestionnaireTemplateQuestionOptionRetrieveListArguments;
}

export interface RetrieveQuestionnaireTemplateQuestionOptionRequest {
    notificationId: number;
}

export interface SetQuestionnaireTemplateQuestionOptionRequest {
    body?: QuestionnaireTemplateQuestionOptionModel;
}

export interface UpdateQuestionnaireTemplateQuestionOptionRequest {
    notificationId: number;
    body?: QuestionnaireTemplateQuestionOptionModel;
}

/**
 * 
 */
export class QuestionnaireTemplateQuestionOptionApi extends runtime.BaseAPI {

    /**
     * Add a new QuestionnaireTemplateQuestionOption
     * Add a new QuestionnaireTemplateQuestionOption
     */
    async createQuestionnaireTemplateQuestionOptionRaw(requestParameters: CreateQuestionnaireTemplateQuestionOptionRequest): Promise<runtime.ApiResponse<ApiResponse42>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/questionnaire-template-question-option`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: QuestionnaireTemplateQuestionOptionModelToJSON(requestParameters.body),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResponse42FromJSON(jsonValue));
    }

    /**
     * Add a new QuestionnaireTemplateQuestionOption
     * Add a new QuestionnaireTemplateQuestionOption
     */
    createQuestionnaireTemplateQuestionOption = async(requestParameters: CreateQuestionnaireTemplateQuestionOptionRequest): Promise<ApiResponse42> => {
        const response = await this.createQuestionnaireTemplateQuestionOptionRaw(requestParameters);
        return await response.value();
    }

    /**
     * Delete an QuestionnaireTemplateQuestionOption entry
     * Delete an QuestionnaireTemplateQuestionOption entry
     */
    async deleteQuestionnaireTemplateQuestionOptionRaw(requestParameters: DeleteQuestionnaireTemplateQuestionOptionRequest): Promise<runtime.ApiResponse<ApiResponse40>> {
        if (requestParameters.questionnaireTemplateQuestionOptionId === null || requestParameters.questionnaireTemplateQuestionOptionId === undefined) {
            throw new runtime.RequiredError('questionnaireTemplateQuestionOptionId','Required parameter requestParameters.questionnaireTemplateQuestionOptionId was null or undefined when calling deleteQuestionnaireTemplateQuestionOption.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/questionnaire-template-question-option/{questionnaire-template-question-optionId}`.replace(`{${"questionnaireTemplateQuestionOptionId"}}`, encodeURIComponent(String(requestParameters.questionnaireTemplateQuestionOptionId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResponse40FromJSON(jsonValue));
    }

    /**
     * Delete an QuestionnaireTemplateQuestionOption entry
     * Delete an QuestionnaireTemplateQuestionOption entry
     */
    deleteQuestionnaireTemplateQuestionOption = async(requestParameters: DeleteQuestionnaireTemplateQuestionOptionRequest): Promise<ApiResponse40> => {
        const response = await this.deleteQuestionnaireTemplateQuestionOptionRaw(requestParameters);
        return await response.value();
    }

    /**
     * find possible follow up questions
     * find possible follow up questions
     */
    async findPossibleFollowUpQuestionsRaw(requestParameters: FindPossibleFollowUpQuestionsRequest): Promise<runtime.ApiResponse<ApiResponse44>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/questionnaire-template-question-option/find-possible-followup-questions`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: QuestionnaireTemplateQuestionOptionPossibleQuestionsModelToJSON(requestParameters.body),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResponse44FromJSON(jsonValue));
    }

    /**
     * find possible follow up questions
     * find possible follow up questions
     */
    findPossibleFollowUpQuestions = async(requestParameters: FindPossibleFollowUpQuestionsRequest): Promise<ApiResponse44> => {
        const response = await this.findPossibleFollowUpQuestionsRaw(requestParameters);
        return await response.value();
    }

    /**
     * get possible follow up questions
     * get possible follow up questions
     */
    async getPossibleFollowUpQuestionsRaw(requestParameters: GetPossibleFollowUpQuestionsRequest): Promise<runtime.ApiResponse<ApiResponse40>> {
        if (requestParameters.questionnaireTemplateQuestionId === null || requestParameters.questionnaireTemplateQuestionId === undefined) {
            throw new runtime.RequiredError('questionnaireTemplateQuestionId','Required parameter requestParameters.questionnaireTemplateQuestionId was null or undefined when calling getPossibleFollowUpQuestions.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/questionnaire-template-question-option/get-possible-followup-questions`.replace(`{${"questionnaireTemplateQuestionId"}}`, encodeURIComponent(String(requestParameters.questionnaireTemplateQuestionId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResponse40FromJSON(jsonValue));
    }

    /**
     * get possible follow up questions
     * get possible follow up questions
     */
    getPossibleFollowUpQuestions = async(requestParameters: GetPossibleFollowUpQuestionsRequest): Promise<ApiResponse40> => {
        const response = await this.getPossibleFollowUpQuestionsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieve List of QuestionnaireTemplateQuestionOption
     * Retrieve List of QuestionnaireTemplateQuestionOption
     */
    async retrieveListQuestionnaireTemplateQuestionOptionRaw(requestParameters: RetrieveListQuestionnaireTemplateQuestionOptionRequest): Promise<runtime.ApiResponse<QuestionnaireTemplateQuestionOptionListResponse>> {
        if (requestParameters.arguments === null || requestParameters.arguments === undefined) {
            throw new runtime.RequiredError('arguments','Required parameter requestParameters.arguments was null or undefined when calling retrieveListQuestionnaireTemplateQuestionOption.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/questionnaire-template-question-option`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: QuestionnaireTemplateQuestionOptionRetrieveListArgumentsToJSON(requestParameters.arguments),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => QuestionnaireTemplateQuestionOptionListResponseFromJSON(jsonValue));
    }

    /**
     * Retrieve List of QuestionnaireTemplateQuestionOption
     * Retrieve List of QuestionnaireTemplateQuestionOption
     */
    retrieveListQuestionnaireTemplateQuestionOption = async(requestParameters: RetrieveListQuestionnaireTemplateQuestionOptionRequest): Promise<QuestionnaireTemplateQuestionOptionListResponse> => {
        const response = await this.retrieveListQuestionnaireTemplateQuestionOptionRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieve an QuestionnaireTemplateQuestionOption
     * Retrieve a QuestionnaireTemplateQuestionOption
     */
    async retrieveQuestionnaireTemplateQuestionOptionRaw(requestParameters: RetrieveQuestionnaireTemplateQuestionOptionRequest): Promise<runtime.ApiResponse<QuestionnaireTemplateQuestionOptionModel>> {
        if (requestParameters.notificationId === null || requestParameters.notificationId === undefined) {
            throw new runtime.RequiredError('notificationId','Required parameter requestParameters.notificationId was null or undefined when calling retrieveQuestionnaireTemplateQuestionOption.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/questionnaire-template-question-option/{questionnaire-template-question-optionId}`.replace(`{${"notificationId"}}`, encodeURIComponent(String(requestParameters.notificationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => QuestionnaireTemplateQuestionOptionModelFromJSON(jsonValue));
    }

    /**
     * Retrieve an QuestionnaireTemplateQuestionOption
     * Retrieve a QuestionnaireTemplateQuestionOption
     */
    retrieveQuestionnaireTemplateQuestionOption = async(requestParameters: RetrieveQuestionnaireTemplateQuestionOptionRequest): Promise<QuestionnaireTemplateQuestionOptionModel> => {
        const response = await this.retrieveQuestionnaireTemplateQuestionOptionRaw(requestParameters);
        return await response.value();
    }

    /**
     * Set (create or update) a d=QuestionnaireTemplateQuestionOption. If id or links are set in the data, and a corresponding QuestionnaireTemplateQuestionOption is found in Bumbal, an update will be performed.
     * Set (create or update) a QuestionnaireTemplateQuestionOption
     */
    async setQuestionnaireTemplateQuestionOptionRaw(requestParameters: SetQuestionnaireTemplateQuestionOptionRequest): Promise<runtime.ApiResponse<ModelApiResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/questionnaire-template-question-option/set`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: QuestionnaireTemplateQuestionOptionModelToJSON(requestParameters.body),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelApiResponseFromJSON(jsonValue));
    }

    /**
     * Set (create or update) a d=QuestionnaireTemplateQuestionOption. If id or links are set in the data, and a corresponding QuestionnaireTemplateQuestionOption is found in Bumbal, an update will be performed.
     * Set (create or update) a QuestionnaireTemplateQuestionOption
     */
    setQuestionnaireTemplateQuestionOption = async(requestParameters: SetQuestionnaireTemplateQuestionOptionRequest): Promise<ModelApiResponse> => {
        const response = await this.setQuestionnaireTemplateQuestionOptionRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update a specific QuestionnaireTemplateQuestionOption object
     * Update a specific QuestionnaireTemplateQuestionOption object
     */
    async updateQuestionnaireTemplateQuestionOptionRaw(requestParameters: UpdateQuestionnaireTemplateQuestionOptionRequest): Promise<runtime.ApiResponse<ApiResponse39>> {
        if (requestParameters.notificationId === null || requestParameters.notificationId === undefined) {
            throw new runtime.RequiredError('notificationId','Required parameter requestParameters.notificationId was null or undefined when calling updateQuestionnaireTemplateQuestionOption.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/questionnaire-template-question-option/{questionnaire-template-question-optionId}`.replace(`{${"notificationId"}}`, encodeURIComponent(String(requestParameters.notificationId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: QuestionnaireTemplateQuestionOptionModelToJSON(requestParameters.body),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResponse39FromJSON(jsonValue));
    }

    /**
     * Update a specific QuestionnaireTemplateQuestionOption object
     * Update a specific QuestionnaireTemplateQuestionOption object
     */
    updateQuestionnaireTemplateQuestionOption = async(requestParameters: UpdateQuestionnaireTemplateQuestionOptionRequest): Promise<ApiResponse39> => {
        const response = await this.updateQuestionnaireTemplateQuestionOptionRaw(requestParameters);
        return await response.value();
    }

}
