/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal Client Api
 * Bumbal API documentation
 *
 * The version of the OpenAPI document: 2.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CapacityTypeListResponse,
    CapacityTypeListResponseFromJSON,
    CapacityTypeListResponseToJSON,
    CapacityTypeModel,
    CapacityTypeModelFromJSON,
    CapacityTypeModelToJSON,
    CapacityTypeRetrieveListArguments,
    CapacityTypeRetrieveListArgumentsFromJSON,
    CapacityTypeRetrieveListArgumentsToJSON,
    ModelApiResponse,
    ModelApiResponseFromJSON,
    ModelApiResponseToJSON,
} from '../models';

export interface DeleteCapacityTypeRequest {
    capacityTypeId: number;
}

export interface RetrieveCapacityTypeRequest {
    capacityTypeId: number;
    includeUom?: boolean;
    includeUomGroup?: boolean;
    includeUomName?: boolean;
}

export interface RetrieveListCapacityTypeRequest {
    arguments: CapacityTypeRetrieveListArguments;
}

export interface SetCapacityTypeRequest {
    body?: CapacityTypeModel;
}

/**
 * 
 */
export class CapacityTypeApi extends runtime.BaseAPI {

    /**
     * Delete a capacity-type
     * Delete a capacity-type
     */
    async deleteCapacityTypeRaw(requestParameters: DeleteCapacityTypeRequest): Promise<runtime.ApiResponse<ModelApiResponse>> {
        if (requestParameters.capacityTypeId === null || requestParameters.capacityTypeId === undefined) {
            throw new runtime.RequiredError('capacityTypeId','Required parameter requestParameters.capacityTypeId was null or undefined when calling deleteCapacityType.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/capacity-type/{capacityTypeId}`.replace(`{${"capacityTypeId"}}`, encodeURIComponent(String(requestParameters.capacityTypeId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelApiResponseFromJSON(jsonValue));
    }

    /**
     * Delete a capacity-type
     * Delete a capacity-type
     */
    deleteCapacityType = async(requestParameters: DeleteCapacityTypeRequest): Promise<ModelApiResponse> => {
        const response = await this.deleteCapacityTypeRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns a single capacity-type
     * Find capacity-type by ID
     */
    async retrieveCapacityTypeRaw(requestParameters: RetrieveCapacityTypeRequest): Promise<runtime.ApiResponse<CapacityTypeModel>> {
        if (requestParameters.capacityTypeId === null || requestParameters.capacityTypeId === undefined) {
            throw new runtime.RequiredError('capacityTypeId','Required parameter requestParameters.capacityTypeId was null or undefined when calling retrieveCapacityType.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.includeUom !== undefined) {
            queryParameters['include_uom'] = requestParameters.includeUom;
        }

        if (requestParameters.includeUomGroup !== undefined) {
            queryParameters['include_uom_group'] = requestParameters.includeUomGroup;
        }

        if (requestParameters.includeUomName !== undefined) {
            queryParameters['include_uom_name'] = requestParameters.includeUomName;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/capacity-type/{capacityTypeId}`.replace(`{${"capacityTypeId"}}`, encodeURIComponent(String(requestParameters.capacityTypeId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CapacityTypeModelFromJSON(jsonValue));
    }

    /**
     * Returns a single capacity-type
     * Find capacity-type by ID
     */
    retrieveCapacityType = async(requestParameters: RetrieveCapacityTypeRequest): Promise<CapacityTypeModel> => {
        const response = await this.retrieveCapacityTypeRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieve List of CapacityTypes
     * Retrieve List of CapacityTypes
     */
    async retrieveListCapacityTypeRaw(requestParameters: RetrieveListCapacityTypeRequest): Promise<runtime.ApiResponse<CapacityTypeListResponse>> {
        if (requestParameters.arguments === null || requestParameters.arguments === undefined) {
            throw new runtime.RequiredError('arguments','Required parameter requestParameters.arguments was null or undefined when calling retrieveListCapacityType.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/capacity-type`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CapacityTypeRetrieveListArgumentsToJSON(requestParameters.arguments),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CapacityTypeListResponseFromJSON(jsonValue));
    }

    /**
     * Retrieve List of CapacityTypes
     * Retrieve List of CapacityTypes
     */
    retrieveListCapacityType = async(requestParameters: RetrieveListCapacityTypeRequest): Promise<CapacityTypeListResponse> => {
        const response = await this.retrieveListCapacityTypeRaw(requestParameters);
        return await response.value();
    }

    /**
     * Set (create or update) a CapacityType. If id is set in the data, and a corresponding capacity-type is found in Bumbal, an update will be performed.
     * Set (create or update) an CapacityType
     */
    async setCapacityTypeRaw(requestParameters: SetCapacityTypeRequest): Promise<runtime.ApiResponse<ModelApiResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/capacity-type/set`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CapacityTypeModelToJSON(requestParameters.body),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelApiResponseFromJSON(jsonValue));
    }

    /**
     * Set (create or update) a CapacityType. If id is set in the data, and a corresponding capacity-type is found in Bumbal, an update will be performed.
     * Set (create or update) an CapacityType
     */
    setCapacityType = async(requestParameters: SetCapacityTypeRequest): Promise<ModelApiResponse> => {
        const response = await this.setCapacityTypeRaw(requestParameters);
        return await response.value();
    }

}
