/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal Client Api
 * Bumbal API documentation
 *
 * The version of the OpenAPI document: 2.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel } from '../runtime';
import {
    MetaDataFiltersModel,
    MetaDataFiltersModelFromJSON,
    MetaDataFiltersModelFromJSONTyped,
    MetaDataFiltersModelToJSON,
    MetaDataOptionsModel,
    MetaDataOptionsModelFromJSON,
    MetaDataOptionsModelFromJSONTyped,
    MetaDataOptionsModelToJSON,
} from './';


export interface MetaDataRetrieveListArgumentsInterface {
    options?: MetaDataOptionsModel;
    filters?: MetaDataFiltersModel;
    limit?: number;
    offset?: number;
    sorting_column?: MetaDataRetrieveListArgumentsSortingColumnEnum;
    sorting_direction?: MetaDataRetrieveListArgumentsSortingDirectionEnum;
    search_text?: string;
    as_list?: boolean;
}

/**
 * 
 * @export
 * @interface MetaDataRetrieveListArguments
 */
export class MetaDataRetrieveListArguments extends BaseModel<MetaDataRetrieveListArgumentsInterface> {

    /**
     * 
     * @type {MetaDataOptionsModel}
     * @memberof MetaDataRetrieveListArguments
     */
    options?: MetaDataOptionsModel;
    /**
     * 
     * @type {MetaDataFiltersModel}
     * @memberof MetaDataRetrieveListArguments
     */
    filters?: MetaDataFiltersModel;
    /**
     * 
     * @type {number}
     * @memberof MetaDataRetrieveListArguments
     */
    limit?: number;
    /**
     * 
     * @type {number}
     * @memberof MetaDataRetrieveListArguments
     */
    offset?: number;
    /**
     * Sorting Column
     * @type {string}
     * @memberof MetaDataRetrieveListArguments
     */
    sorting_column?: MetaDataRetrieveListArgumentsSortingColumnEnum;
    /**
     * Sorting Direction
     * @type {string}
     * @memberof MetaDataRetrieveListArguments
     */
    sorting_direction?: MetaDataRetrieveListArgumentsSortingDirectionEnum;
    /**
     * 
     * @type {string}
     * @memberof MetaDataRetrieveListArguments
     */
    search_text?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MetaDataRetrieveListArguments
     */
    as_list?: boolean;

    constructor(data?: MetaDataRetrieveListArgumentsInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(value: MetaDataRetrieveListArgumentsInterface): { [property: string]: any } {
        return {};
    }

}

export function MetaDataRetrieveListArgumentsFromJSON(json: any): MetaDataRetrieveListArguments {
    return MetaDataRetrieveListArgumentsFromJSONTyped(json, false);
}

export function MetaDataRetrieveListArgumentsFromJSONTyped(json: any, ignoreDiscriminator: boolean): MetaDataRetrieveListArguments {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = MetaDataRetrieveListArguments;
    try {
        let localClass = require.context('../override', true)('./MetaDataRetrieveListArguments');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'options': !exists(json, 'options') ? undefined : MetaDataOptionsModelFromJSON(json['options']),
        'filters': !exists(json, 'filters') ? undefined : MetaDataFiltersModelFromJSON(json['filters']),
        'limit': !exists(json, 'limit') ? undefined : Number(json['limit']),
        'offset': !exists(json, 'offset') ? undefined : Number(json['offset']),
        'sorting_column': !exists(json, 'sorting_column') ? undefined : json['sorting_column'],
        'sorting_direction': !exists(json, 'sorting_direction') ? undefined : json['sorting_direction'],
        'search_text': !exists(json, 'search_text') ? undefined : json['search_text'],
        'as_list': !exists(json, 'as_list') ? undefined : json['as_list'],
    });
}

export function MetaDataRetrieveListArgumentsToJSON(value?: MetaDataRetrieveListArguments | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = MetaDataRetrieveListArguments;
    try {
        let localClass = require.context('../override', true)('./MetaDataRetrieveListArguments');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return {
        
        'options': MetaDataOptionsModelToJSON(value.options),
        'filters': MetaDataFiltersModelToJSON(value.filters),
        'limit': value.limit,
        'offset': value.offset,
        'sorting_column': value.sorting_column,
        'sorting_direction': value.sorting_direction,
        'search_text': value.search_text,
        'as_list': value.as_list,
        ...createClass.toJson(value),
    };
}

/**
* @export
* @enum {string}
*/
export enum MetaDataRetrieveListArgumentsSortingColumnEnum {
    Id = 'id',
    Name = 'name'
}
/**
* @export
* @enum {string}
*/
export enum MetaDataRetrieveListArgumentsSortingDirectionEnum {
    Asc = 'asc',
    Desc = 'desc'
}


