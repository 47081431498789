import { createStore, applyMiddleware, combineReducers, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import { RootSagas } from "../sagas/RootSagas";
import { AppReducers } from "./app/reducers";
import { AuthenticateReducers } from "./authenticate/reducers";
import { UserReducers } from "./user/reducers";

const sagaMiddleware = createSagaMiddleware();

const rootReducer = () => combineReducers({
    app: AppReducers,
    authenticate: AuthenticateReducers,
    user: UserReducers
});

declare global {
    interface Window {
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
    }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const AppStore = createStore(rootReducer(), composeEnhancers(applyMiddleware(sagaMiddleware)))

sagaMiddleware.run(RootSagas);

export type RootState = ReturnType<typeof rootReducer>

export default AppStore;
