import { takeLatest, put, all } from "redux-saga/effects";
import ActionTypes from '../settings/ActionTypes';
import { AuthenticateApi, AuthenticateSignInRequest, AuthenticateSignOutRequest } from "../api";
import AuthenticateActions from "../store/authenticate/actions";
import { SignInAction, SignOutAction } from "../store/authenticate/types";
import { ApiSaga } from "./ApiSaga";
import AppActions from "../store/app/actions";
import LocalStorageManager from "../utils/LocalStorageManager";

export function* AuthenticateSagas() {
    yield takeLatest(ActionTypes.API_SIGN_IN, AuthenticateSaga.signInSaga);
    yield takeLatest(ActionTypes.API_SIGN_OUT, AuthenticateSaga.signOutSaga);
}

export default class AuthenticateSaga {

    static* signInSaga(action: SignInAction) {
        try {
            const api = new AuthenticateApi();
            const authenticateSignInRequest = {
                body: {
                    email: action.params?.email,
                    password: action.params?.password
                }
            } as AuthenticateSignInRequest;
            const response = yield* ApiSaga(api.authenticateSignIn, authenticateSignInRequest);
            yield put(AuthenticateActions.signInSuccess(response))
        } catch (e) {
            yield put(AppActions.createError(ActionTypes.API_SIGN_IN_FAILURE, e as Error))
        }
    }

    static* signOutSaga(action: SignOutAction) {
        try {
            const api = new AuthenticateApi();
            const authenticateSignOutRequest = {
                token: new LocalStorageManager().getToken() ?? ''
            } as AuthenticateSignOutRequest
            yield* ApiSaga(api.authenticateSignOut, authenticateSignOutRequest);
        } catch (e) {}
        yield all([
            put(AuthenticateActions.signOutSuccess())
        ])
    }
}

