import ActionTypes from '../../settings/ActionTypes';
import React, { ReactElement, useEffect } from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../store/app/types';
import { AuthenticateState } from '../../store/authenticate/types';
import { UserState } from '../../store/user/types';
import LocalStorageManager from '../../utils/LocalStorageManager';
import Footer from './navigation/Footer';
import Navbar from './navigation/Navbar';
import UserActions from '../../store/user/actions';

interface StateProps {
    authReducer: AuthenticateState;
    userReducer: UserState;
    appReducer: AppState;
    retrieveUser: (id: number) => void;
}

interface ViewProps {
    children: ReactElement;
}

type AppLayoutProps = ViewProps & StateProps;

export const AppLayout = (props: AppLayoutProps) => {
    const lsm = new LocalStorageManager();

    useEffect(() => {
        if (
            lsm.isLoggedIn() &&
            !props.userReducer.user &&
            !props.appReducer.isFetchingData.get(
                ActionTypes.API_USER_RETRIEVE
            ) &&
            !props.authReducer.error
        ) {
            props.retrieveUser(Number(lsm.getId()));
        }
    });

    return (
        <>
            <Navbar isLoggedIn={props.authReducer.isLoggedIn} />
            {!props.appReducer.isFetchingData.get(
                ActionTypes.API_USER_RETRIEVE
            ) && props.children}
            {props.authReducer.isLoggedIn && <Footer />}
        </>
    );
};

const mapStateToProps = (state) => ({
    authReducer: state.authenticate,
    userReducer: state.user,
    appReducer: state.app,
});

const mapDispatchToProps = (dispatch: Function) => {
    return {
        retrieveUser: (id: number) => dispatch(UserActions.retrieveUser(id)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AppLayout);
