/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal Client Api
 * Bumbal API documentation
 *
 * The version of the OpenAPI document: 2.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiResponse32,
    ApiResponse32FromJSON,
    ApiResponse32ToJSON,
    ApiResponse34,
    ApiResponse34FromJSON,
    ApiResponse34ToJSON,
    ApiResponse401,
    ApiResponse401FromJSON,
    ApiResponse401ToJSON,
    ApiResponse403,
    ApiResponse403FromJSON,
    ApiResponse403ToJSON,
    ApiResponse405,
    ApiResponse405FromJSON,
    ApiResponse405ToJSON,
} from '../models';

export interface GetQuestionnaireTemplateLanguagesRequest {
    questionnaireTemplateId: number;
}

/**
 * 
 */
export class QuestionnaireTemplateGetLanguagesApi extends runtime.BaseAPI {

    /**
     * Retrieves all set languages for an QuestionnaireTemplate entry
     * Retrieves all set languages for an QuestionnaireTemplate entry
     */
    async getQuestionnaireTemplateLanguagesRaw(requestParameters: GetQuestionnaireTemplateLanguagesRequest): Promise<runtime.ApiResponse<ApiResponse34>> {
        if (requestParameters.questionnaireTemplateId === null || requestParameters.questionnaireTemplateId === undefined) {
            throw new runtime.RequiredError('questionnaireTemplateId','Required parameter requestParameters.questionnaireTemplateId was null or undefined when calling getQuestionnaireTemplateLanguages.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/questionnaire-template/get-languages/{questionnaire-templateId}`.replace(`{${"questionnaire_template_id"}}`, encodeURIComponent(String(requestParameters.questionnaireTemplateId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResponse34FromJSON(jsonValue));
    }

    /**
     * Retrieves all set languages for an QuestionnaireTemplate entry
     * Retrieves all set languages for an QuestionnaireTemplate entry
     */
    getQuestionnaireTemplateLanguages = async(requestParameters: GetQuestionnaireTemplateLanguagesRequest): Promise<ApiResponse34> => {
        const response = await this.getQuestionnaireTemplateLanguagesRaw(requestParameters);
        return await response.value();
    }

}
