/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal Client Api
 * Bumbal API documentation
 *
 * The version of the OpenAPI document: 2.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel } from '../runtime';

export interface UsersOptionsModelInterface {
    include_addresses?: boolean;
    include_user_meta_data?: boolean;
    include_user_tags?: boolean;
    include_party_name?: boolean;
    include_pause?: boolean;
    include_zones?: boolean;
    include_driver_unavailabilities?: boolean;
    include_links?: boolean;
}

/**
 * 
 * @export
 * @interface UsersOptionsModel
 */
export class UsersOptionsModel extends BaseModel<UsersOptionsModelInterface> {

    /**
     * 
     * @type {boolean}
     * @memberof UsersOptionsModel
     */
    include_addresses?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UsersOptionsModel
     */
    include_user_meta_data?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UsersOptionsModel
     */
    include_user_tags?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UsersOptionsModel
     */
    include_party_name?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UsersOptionsModel
     */
    include_pause?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UsersOptionsModel
     */
    include_zones?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UsersOptionsModel
     */
    include_driver_unavailabilities?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UsersOptionsModel
     */
    include_links?: boolean;

    constructor(data?: UsersOptionsModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(value: UsersOptionsModelInterface): { [property: string]: any } {
        return {};
    }

}

export function UsersOptionsModelFromJSON(json: any): UsersOptionsModel {
    return UsersOptionsModelFromJSONTyped(json, false);
}

export function UsersOptionsModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): UsersOptionsModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = UsersOptionsModel;
    try {
        let localClass = require.context('../override', true)('./UsersOptionsModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'include_addresses': !exists(json, 'include_addresses') ? undefined : json['include_addresses'],
        'include_user_meta_data': !exists(json, 'include_user_meta_data') ? undefined : json['include_user_meta_data'],
        'include_user_tags': !exists(json, 'include_user_tags') ? undefined : json['include_user_tags'],
        'include_party_name': !exists(json, 'include_party_name') ? undefined : json['include_party_name'],
        'include_pause': !exists(json, 'include_pause') ? undefined : json['include_pause'],
        'include_zones': !exists(json, 'include_zones') ? undefined : json['include_zones'],
        'include_driver_unavailabilities': !exists(json, 'include_driver_unavailabilities') ? undefined : json['include_driver_unavailabilities'],
        'include_links': !exists(json, 'include_links') ? undefined : json['include_links'],
    });
}

export function UsersOptionsModelToJSON(value?: UsersOptionsModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = UsersOptionsModel;
    try {
        let localClass = require.context('../override', true)('./UsersOptionsModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return {
        
        'include_addresses': value.include_addresses,
        'include_user_meta_data': value.include_user_meta_data,
        'include_user_tags': value.include_user_tags,
        'include_party_name': value.include_party_name,
        'include_pause': value.include_pause,
        'include_zones': value.include_zones,
        'include_driver_unavailabilities': value.include_driver_unavailabilities,
        'include_links': value.include_links,
        ...createClass.toJson(value),
    };
}


