/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal Client Api
 * Bumbal API documentation
 *
 * The version of the OpenAPI document: 2.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiResponse401,
    ApiResponse401FromJSON,
    ApiResponse401ToJSON,
    ApiResponse403,
    ApiResponse403FromJSON,
    ApiResponse403ToJSON,
    ApiResponse405,
    ApiResponse405FromJSON,
    ApiResponse405ToJSON,
    ApiResponse52,
    ApiResponse52FromJSON,
    ApiResponse52ToJSON,
    ApiResponse53,
    ApiResponse53FromJSON,
    ApiResponse53ToJSON,
    ApiResponse54,
    ApiResponse54FromJSON,
    ApiResponse54ToJSON,
    ApiResponse55,
    ApiResponse55FromJSON,
    ApiResponse55ToJSON,
    UnsuccessfulReasonListResponse,
    UnsuccessfulReasonListResponseFromJSON,
    UnsuccessfulReasonListResponseToJSON,
    UnsuccessfulReasonModel,
    UnsuccessfulReasonModelFromJSON,
    UnsuccessfulReasonModelToJSON,
    UnsuccessfulReasonRetrieveListArguments,
    UnsuccessfulReasonRetrieveListArgumentsFromJSON,
    UnsuccessfulReasonRetrieveListArgumentsToJSON,
} from '../models';

export interface CreateUnsuccessfulReasonRequest {
    body?: UnsuccessfulReasonModel;
}

export interface DeleteUnsuccessfulReasonRequest {
    unsuccessfulReasonId: number;
}

export interface RetrieveListUnsuccessfulReasonRequest {
    arguments: UnsuccessfulReasonRetrieveListArguments;
}

export interface RetrieveUnsuccessfulReasonRequest {
    unsuccessfulReasonId: number;
    includeObjectTypeName?: boolean;
    includeRecordInfo?: boolean;
}

export interface UpdateUnsuccessfulReasonRequest {
    unsuccessfulReasonId: number;
    body?: UnsuccessfulReasonModel;
}

/**
 * 
 */
export class UnsuccessfulReasonApi extends runtime.BaseAPI {

    /**
     * Add a new UnsuccessfulReason
     * Add a new UnsuccessfulReason
     */
    async createUnsuccessfulReasonRaw(requestParameters: CreateUnsuccessfulReasonRequest): Promise<runtime.ApiResponse<ApiResponse55>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/unsuccessful-reason`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UnsuccessfulReasonModelToJSON(requestParameters.body),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResponse55FromJSON(jsonValue));
    }

    /**
     * Add a new UnsuccessfulReason
     * Add a new UnsuccessfulReason
     */
    createUnsuccessfulReason = async(requestParameters: CreateUnsuccessfulReasonRequest): Promise<ApiResponse55> => {
        const response = await this.createUnsuccessfulReasonRaw(requestParameters);
        return await response.value();
    }

    /**
     * Delete a Metadata entry
     * Delete a UnsuccessfulReason entry
     */
    async deleteUnsuccessfulReasonRaw(requestParameters: DeleteUnsuccessfulReasonRequest): Promise<runtime.ApiResponse<ApiResponse53>> {
        if (requestParameters.unsuccessfulReasonId === null || requestParameters.unsuccessfulReasonId === undefined) {
            throw new runtime.RequiredError('unsuccessfulReasonId','Required parameter requestParameters.unsuccessfulReasonId was null or undefined when calling deleteUnsuccessfulReason.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/unsuccessful-reason/{unsuccessful-reasonId}`.replace(`{${"unsuccessful-reasonId"}}`, encodeURIComponent(String(requestParameters.unsuccessfulReasonId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResponse53FromJSON(jsonValue));
    }

    /**
     * Delete a Metadata entry
     * Delete a UnsuccessfulReason entry
     */
    deleteUnsuccessfulReason = async(requestParameters: DeleteUnsuccessfulReasonRequest): Promise<ApiResponse53> => {
        const response = await this.deleteUnsuccessfulReasonRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieve List of UnsuccessfulReason
     * Retrieve List of UnsuccessfulReason
     */
    async retrieveListUnsuccessfulReasonRaw(requestParameters: RetrieveListUnsuccessfulReasonRequest): Promise<runtime.ApiResponse<UnsuccessfulReasonListResponse>> {
        if (requestParameters.arguments === null || requestParameters.arguments === undefined) {
            throw new runtime.RequiredError('arguments','Required parameter requestParameters.arguments was null or undefined when calling retrieveListUnsuccessfulReason.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/unsuccessful-reason`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UnsuccessfulReasonRetrieveListArgumentsToJSON(requestParameters.arguments),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UnsuccessfulReasonListResponseFromJSON(jsonValue));
    }

    /**
     * Retrieve List of UnsuccessfulReason
     * Retrieve List of UnsuccessfulReason
     */
    retrieveListUnsuccessfulReason = async(requestParameters: RetrieveListUnsuccessfulReasonRequest): Promise<UnsuccessfulReasonListResponse> => {
        const response = await this.retrieveListUnsuccessfulReasonRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieve an UnsuccessfulReason
     * Retrieve a UnsuccessfulReason
     */
    async retrieveUnsuccessfulReasonRaw(requestParameters: RetrieveUnsuccessfulReasonRequest): Promise<runtime.ApiResponse<UnsuccessfulReasonModel>> {
        if (requestParameters.unsuccessfulReasonId === null || requestParameters.unsuccessfulReasonId === undefined) {
            throw new runtime.RequiredError('unsuccessfulReasonId','Required parameter requestParameters.unsuccessfulReasonId was null or undefined when calling retrieveUnsuccessfulReason.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.includeObjectTypeName !== undefined) {
            queryParameters['include_object_type_name'] = requestParameters.includeObjectTypeName;
        }

        if (requestParameters.includeRecordInfo !== undefined) {
            queryParameters['include_record_info'] = requestParameters.includeRecordInfo;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/unsuccessful-reason/{unsuccessful-reasonId}`.replace(`{${"unsuccessful-reasonId"}}`, encodeURIComponent(String(requestParameters.unsuccessfulReasonId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UnsuccessfulReasonModelFromJSON(jsonValue));
    }

    /**
     * Retrieve an UnsuccessfulReason
     * Retrieve a UnsuccessfulReason
     */
    retrieveUnsuccessfulReason = async(requestParameters: RetrieveUnsuccessfulReasonRequest): Promise<UnsuccessfulReasonModel> => {
        const response = await this.retrieveUnsuccessfulReasonRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update a specific UnsuccessfulReason object
     * Update a specific UnsuccessfulReason object
     */
    async updateUnsuccessfulReasonRaw(requestParameters: UpdateUnsuccessfulReasonRequest): Promise<runtime.ApiResponse<ApiResponse52>> {
        if (requestParameters.unsuccessfulReasonId === null || requestParameters.unsuccessfulReasonId === undefined) {
            throw new runtime.RequiredError('unsuccessfulReasonId','Required parameter requestParameters.unsuccessfulReasonId was null or undefined when calling updateUnsuccessfulReason.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/unsuccessful-reason/{unsuccessful-reasonId}`.replace(`{${"unsuccessful-reasonId"}}`, encodeURIComponent(String(requestParameters.unsuccessfulReasonId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UnsuccessfulReasonModelToJSON(requestParameters.body),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResponse52FromJSON(jsonValue));
    }

    /**
     * Update a specific UnsuccessfulReason object
     * Update a specific UnsuccessfulReason object
     */
    updateUnsuccessfulReason = async(requestParameters: UpdateUnsuccessfulReasonRequest): Promise<ApiResponse52> => {
        const response = await this.updateUnsuccessfulReasonRaw(requestParameters);
        return await response.value();
    }

}
