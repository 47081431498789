/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal Client Api
 * Bumbal API documentation
 *
 * The version of the OpenAPI document: 2.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel } from '../runtime';
import {
    LogFiltersModel,
    LogFiltersModelFromJSON,
    LogFiltersModelFromJSONTyped,
    LogFiltersModelToJSON,
    LogOptionsModel,
    LogOptionsModelFromJSON,
    LogOptionsModelFromJSONTyped,
    LogOptionsModelToJSON,
} from './';


export interface LogRetrieveListArgumentsInterface {
    options?: LogOptionsModel;
    filters?: LogFiltersModel;
    limit?: number;
    offset?: number;
    sorting_column?: LogRetrieveListArgumentsSortingColumnEnum;
    sorting_direction?: LogRetrieveListArgumentsSortingDirectionEnum;
    as_list?: boolean;
    count_only?: boolean;
}

/**
 * 
 * @export
 * @interface LogRetrieveListArguments
 */
export class LogRetrieveListArguments extends BaseModel<LogRetrieveListArgumentsInterface> {

    /**
     * 
     * @type {LogOptionsModel}
     * @memberof LogRetrieveListArguments
     */
    options?: LogOptionsModel;
    /**
     * 
     * @type {LogFiltersModel}
     * @memberof LogRetrieveListArguments
     */
    filters?: LogFiltersModel;
    /**
     * Maximum number of messages to return. Default = 100
     * @type {number}
     * @memberof LogRetrieveListArguments
     */
    limit?: number;
    /**
     * Default = 0
     * @type {number}
     * @memberof LogRetrieveListArguments
     */
    offset?: number;
    /**
     * Sorting Column. Default = 'timestamp'
     * @type {string}
     * @memberof LogRetrieveListArguments
     */
    sorting_column?: LogRetrieveListArgumentsSortingColumnEnum;
    /**
     * Sorting Direction. Default = 'desc'
     * @type {string}
     * @memberof LogRetrieveListArguments
     */
    sorting_direction?: LogRetrieveListArgumentsSortingDirectionEnum;
    /**
     * 
     * @type {boolean}
     * @memberof LogRetrieveListArguments
     */
    as_list?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LogRetrieveListArguments
     */
    count_only?: boolean;

    constructor(data?: LogRetrieveListArgumentsInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(value: LogRetrieveListArgumentsInterface): { [property: string]: any } {
        return {};
    }

}

export function LogRetrieveListArgumentsFromJSON(json: any): LogRetrieveListArguments {
    return LogRetrieveListArgumentsFromJSONTyped(json, false);
}

export function LogRetrieveListArgumentsFromJSONTyped(json: any, ignoreDiscriminator: boolean): LogRetrieveListArguments {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = LogRetrieveListArguments;
    try {
        let localClass = require.context('../override', true)('./LogRetrieveListArguments');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'options': !exists(json, 'options') ? undefined : LogOptionsModelFromJSON(json['options']),
        'filters': !exists(json, 'filters') ? undefined : LogFiltersModelFromJSON(json['filters']),
        'limit': !exists(json, 'limit') ? undefined : Number(json['limit']),
        'offset': !exists(json, 'offset') ? undefined : Number(json['offset']),
        'sorting_column': !exists(json, 'sorting_column') ? undefined : json['sorting_column'],
        'sorting_direction': !exists(json, 'sorting_direction') ? undefined : json['sorting_direction'],
        'as_list': !exists(json, 'as_list') ? undefined : json['as_list'],
        'count_only': !exists(json, 'count_only') ? undefined : json['count_only'],
    });
}

export function LogRetrieveListArgumentsToJSON(value?: LogRetrieveListArguments | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = LogRetrieveListArguments;
    try {
        let localClass = require.context('../override', true)('./LogRetrieveListArguments');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return {
        
        'options': LogOptionsModelToJSON(value.options),
        'filters': LogFiltersModelToJSON(value.filters),
        'limit': value.limit,
        'offset': value.offset,
        'sorting_column': value.sorting_column,
        'sorting_direction': value.sorting_direction,
        'as_list': value.as_list,
        'count_only': value.count_only,
        ...createClass.toJson(value),
    };
}

/**
* @export
* @enum {string}
*/
export enum LogRetrieveListArgumentsSortingColumnEnum {
    Timestamp = 'timestamp',
    ClientIp = 'client_ip',
    HttpMethod = 'http_method',
    ApiEndpoint = 'api_endpoint',
    LevelText = 'level_text',
    RequestId = 'request_id',
    Channel = 'channel'
}
/**
* @export
* @enum {string}
*/
export enum LogRetrieveListArgumentsSortingDirectionEnum {
    Asc = 'asc',
    Desc = 'desc'
}


