import { Box, Grid } from "@mui/material";
import React from "react";
import UserManualContent from "./components/UserManualContent";
import UserManualHeader from "./components/UserManualHeader";

export function UserManualView() {

    return (
        <Box>
            <>
                <UserManualHeader />
                <Grid
                    container
                    sx={{
                        backgroundColor: "#F1F1F1",
                        pt: 5,
                        pb: 5,
                    }}
                    alignItems="stretch"
                >
                    <UserManualContent></UserManualContent>
                </Grid>
            </>
        </Box>
    );
}
