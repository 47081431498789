/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal Client Api
 * Bumbal API documentation
 *
 * The version of the OpenAPI document: 2.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiResponse21,
    ApiResponse21FromJSON,
    ApiResponse21ToJSON,
    ApiResponse22,
    ApiResponse22FromJSON,
    ApiResponse22ToJSON,
    ApiResponse401,
    ApiResponse401FromJSON,
    ApiResponse401ToJSON,
    ApiResponse403,
    ApiResponse403FromJSON,
    ApiResponse403ToJSON,
    ApiResponse405,
    ApiResponse405FromJSON,
    ApiResponse405ToJSON,
    ProviderListResponse,
    ProviderListResponseFromJSON,
    ProviderListResponseToJSON,
    ProviderModel,
    ProviderModelFromJSON,
    ProviderModelToJSON,
    ProviderRetrieveListArguments,
    ProviderRetrieveListArgumentsFromJSON,
    ProviderRetrieveListArgumentsToJSON,
} from '../models';

export interface CreateProviderRequest {
    body?: ProviderModel;
}

export interface RetrieveListProviderRequest {
    arguments: ProviderRetrieveListArguments;
}

export interface RetrieveProviderRequest {
    providerId: number;
}

export interface UpdateProviderRequest {
    providerId: number;
    body?: ProviderModel;
}

/**
 * 
 */
export class ProviderApi extends runtime.BaseAPI {

    /**
     * Add a new Provider
     * Add a new Provider
     */
    async createProviderRaw(requestParameters: CreateProviderRequest): Promise<runtime.ApiResponse<ApiResponse22>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/provider`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ProviderModelToJSON(requestParameters.body),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResponse22FromJSON(jsonValue));
    }

    /**
     * Add a new Provider
     * Add a new Provider
     */
    createProvider = async(requestParameters: CreateProviderRequest): Promise<ApiResponse22> => {
        const response = await this.createProviderRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieve List of Providers
     * Retrieve List of Providers
     */
    async retrieveListProviderRaw(requestParameters: RetrieveListProviderRequest): Promise<runtime.ApiResponse<ProviderListResponse>> {
        if (requestParameters.arguments === null || requestParameters.arguments === undefined) {
            throw new runtime.RequiredError('arguments','Required parameter requestParameters.arguments was null or undefined when calling retrieveListProvider.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/provider`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ProviderRetrieveListArgumentsToJSON(requestParameters.arguments),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ProviderListResponseFromJSON(jsonValue));
    }

    /**
     * Retrieve List of Providers
     * Retrieve List of Providers
     */
    retrieveListProvider = async(requestParameters: RetrieveListProviderRequest): Promise<ProviderListResponse> => {
        const response = await this.retrieveListProviderRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieve an Provider
     * Retrieve a Provider
     */
    async retrieveProviderRaw(requestParameters: RetrieveProviderRequest): Promise<runtime.ApiResponse<ProviderModel>> {
        if (requestParameters.providerId === null || requestParameters.providerId === undefined) {
            throw new runtime.RequiredError('providerId','Required parameter requestParameters.providerId was null or undefined when calling retrieveProvider.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/provider/{providerId}`.replace(`{${"providerId"}}`, encodeURIComponent(String(requestParameters.providerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ProviderModelFromJSON(jsonValue));
    }

    /**
     * Retrieve an Provider
     * Retrieve a Provider
     */
    retrieveProvider = async(requestParameters: RetrieveProviderRequest): Promise<ProviderModel> => {
        const response = await this.retrieveProviderRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update a specific provider object
     * Update a specific provider object
     */
    async updateProviderRaw(requestParameters: UpdateProviderRequest): Promise<runtime.ApiResponse<ApiResponse21>> {
        if (requestParameters.providerId === null || requestParameters.providerId === undefined) {
            throw new runtime.RequiredError('providerId','Required parameter requestParameters.providerId was null or undefined when calling updateProvider.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/provider/{providerId}`.replace(`{${"providerId"}}`, encodeURIComponent(String(requestParameters.providerId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ProviderModelToJSON(requestParameters.body),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResponse21FromJSON(jsonValue));
    }

    /**
     * Update a specific provider object
     * Update a specific provider object
     */
    updateProvider = async(requestParameters: UpdateProviderRequest): Promise<ApiResponse21> => {
        const response = await this.updateProviderRaw(requestParameters);
        return await response.value();
    }

}
