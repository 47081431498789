/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal Client Api
 * Bumbal API documentation
 *
 * The version of the OpenAPI document: 2.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel } from '../runtime';

export interface OpeningHoursRuleModelInterface {
    id?: number;
    address_id?: number;
    time_from?: string;
    time_to?: string;
    valid_date_from?: string;
    valid_date_to?: string;
    weekday?: number;
    precedence?: number;
}

/**
 * 
 * @export
 * @interface OpeningHoursRuleModel
 */
export class OpeningHoursRuleModel extends BaseModel<OpeningHoursRuleModelInterface> {

    /**
     * Unique ID
     * @type {number}
     * @memberof OpeningHoursRuleModel
     */
    id?: number;
    /**
     * Address ID asociated with this OpeningHours
     * @type {number}
     * @memberof OpeningHoursRuleModel
     */
    address_id?: number;
    /**
     * 
     * @type {string}
     * @memberof OpeningHoursRuleModel
     */
    time_from?: string;
    /**
     * 
     * @type {string}
     * @memberof OpeningHoursRuleModel
     */
    time_to?: string;
    /**
     * 
     * @type {string}
     * @memberof OpeningHoursRuleModel
     */
    valid_date_from?: string;
    /**
     * 
     * @type {string}
     * @memberof OpeningHoursRuleModel
     */
    valid_date_to?: string;
    /**
     * value 7 represents all weekdays. 0 = monday, etc.
     * @type {number}
     * @memberof OpeningHoursRuleModel
     */
    weekday?: number;
    /**
     * lowest precedence is 0. Accepted values are 0 and 1 right now. If more rules apply to a date, only rules with highest precendence will apply.
     * @type {number}
     * @memberof OpeningHoursRuleModel
     */
    precedence?: number;

    constructor(data?: OpeningHoursRuleModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(value: OpeningHoursRuleModelInterface): { [property: string]: any } {
        return {};
    }

}

export function OpeningHoursRuleModelFromJSON(json: any): OpeningHoursRuleModel {
    return OpeningHoursRuleModelFromJSONTyped(json, false);
}

export function OpeningHoursRuleModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): OpeningHoursRuleModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = OpeningHoursRuleModel;
    try {
        let localClass = require.context('../override', true)('./OpeningHoursRuleModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'id': !exists(json, 'id') ? undefined : Number(json['id']),
        'address_id': !exists(json, 'address_id') ? undefined : Number(json['address_id']),
        'time_from': !exists(json, 'time_from') ? undefined : json['time_from'],
        'time_to': !exists(json, 'time_to') ? undefined : json['time_to'],
        'valid_date_from': !exists(json, 'valid_date_from') ? undefined : json['valid_date_from'],
        'valid_date_to': !exists(json, 'valid_date_to') ? undefined : json['valid_date_to'],
        'weekday': !exists(json, 'weekday') ? undefined : Number(json['weekday']),
        'precedence': !exists(json, 'precedence') ? undefined : Number(json['precedence']),
    });
}

export function OpeningHoursRuleModelToJSON(value?: OpeningHoursRuleModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = OpeningHoursRuleModel;
    try {
        let localClass = require.context('../override', true)('./OpeningHoursRuleModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return {
        
        'id': value.id,
        'address_id': value.address_id,
        'time_from': value.time_from,
        'time_to': value.time_to,
        'valid_date_from': value.valid_date_from,
        'valid_date_to': value.valid_date_to,
        'weekday': value.weekday,
        'precedence': value.precedence,
        ...createClass.toJson(value),
    };
}


