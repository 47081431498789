/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal Client Api
 * Bumbal API documentation
 *
 * The version of the OpenAPI document: 2.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel } from '../runtime';

export interface CredentialsModelInterface {
    email?: string;
    password?: string;
    return_jwt?: boolean;
}

/**
 * 
 * @export
 * @interface CredentialsModel
 */
export class CredentialsModel extends BaseModel<CredentialsModelInterface> {

    /**
     * Email
     * @type {string}
     * @memberof CredentialsModel
     */
    email?: string;
    /**
     * Password
     * @type {string}
     * @memberof CredentialsModel
     */
    password?: string;
    /**
     * Set to true for requesting a jwt token
     * @type {boolean}
     * @memberof CredentialsModel
     */
    return_jwt?: boolean;

    constructor(data?: CredentialsModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(value: CredentialsModelInterface): { [property: string]: any } {
        return {};
    }

}

export function CredentialsModelFromJSON(json: any): CredentialsModel {
    return CredentialsModelFromJSONTyped(json, false);
}

export function CredentialsModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): CredentialsModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = CredentialsModel;
    try {
        let localClass = require.context('../override', true)('./CredentialsModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'email': !exists(json, 'email') ? undefined : json['email'],
        'password': !exists(json, 'password') ? undefined : json['password'],
        'return_jwt': !exists(json, 'return_jwt') ? undefined : json['return_jwt'],
    });
}

export function CredentialsModelToJSON(value?: CredentialsModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = CredentialsModel;
    try {
        let localClass = require.context('../override', true)('./CredentialsModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return {
        
        'email': value.email,
        'password': value.password,
        'return_jwt': value.return_jwt,
        ...createClass.toJson(value),
    };
}


