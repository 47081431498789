import { AuthenticateModel, CredentialsModel } from "../../api/models"
import ActionTypes from "../../settings/ActionTypes"
import { AuthenticateActionTypes } from "./types"


export default class AuthenticateActions {
    static signIn = (body?: CredentialsModel): AuthenticateActionTypes => {
        return {
            type: ActionTypes.API_SIGN_IN,
            params: body
        }
    }
    static signInSuccess = (response: AuthenticateModel): AuthenticateActionTypes => {
        return {
            type: ActionTypes.API_SIGN_IN_SUCCESS,
            response: response
        }
    }
    static signOut = (token: string): AuthenticateActionTypes => {
        return {
            type: ActionTypes.API_SIGN_OUT,
            payload: token
        }
    }
    static signOutSuccess = (): AuthenticateActionTypes => {
        return {
            type: ActionTypes.API_SIGN_OUT_SUCCESS
        }
    }
}