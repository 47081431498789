/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal Client Api
 * Bumbal API documentation
 *
 * The version of the OpenAPI document: 2.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiResponse13,
    ApiResponse13FromJSON,
    ApiResponse13ToJSON,
    ApiResponse14,
    ApiResponse14FromJSON,
    ApiResponse14ToJSON,
    ApiResponse15,
    ApiResponse15FromJSON,
    ApiResponse15ToJSON,
    ApiResponse16,
    ApiResponse16FromJSON,
    ApiResponse16ToJSON,
    ApiResponse17,
    ApiResponse17FromJSON,
    ApiResponse17ToJSON,
    ApiResponse18,
    ApiResponse18FromJSON,
    ApiResponse18ToJSON,
    ApiResponse19,
    ApiResponse19FromJSON,
    ApiResponse19ToJSON,
    ApiResponse20,
    ApiResponse20FromJSON,
    ApiResponse20ToJSON,
    ModelApiResponse,
    ModelApiResponseFromJSON,
    ModelApiResponseToJSON,
    NotificationListResponse,
    NotificationListResponseFromJSON,
    NotificationListResponseToJSON,
    NotificationModel,
    NotificationModelFromJSON,
    NotificationModelToJSON,
    NotificationRetrieveListArguments,
    NotificationRetrieveListArgumentsFromJSON,
    NotificationRetrieveListArgumentsToJSON,
} from '../models';

export interface CreateNotificationRequest {
    body?: NotificationModel;
}

export interface DeleteNotificationRequest {
    notificationId: number;
}

export interface RetrieveListNotificationRequest {
    arguments: NotificationRetrieveListArguments;
}

export interface RetrieveNotificationRequest {
    notificationId: number;
    includeCategoryTypeName?: boolean;
    includeRecordInfo?: boolean;
}

export interface SetNotificationRequest {
    body?: NotificationModel;
}

export interface UpdateNotificationRequest {
    notificationId: number;
    body?: NotificationModel;
}

/**
 * 
 */
export class NotificationApi extends runtime.BaseAPI {

    /**
     * Add a new Notification
     * Add a new Notification
     */
    async createNotificationRaw(requestParameters: CreateNotificationRequest): Promise<runtime.ApiResponse<ApiResponse20>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/notification`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NotificationModelToJSON(requestParameters.body),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResponse20FromJSON(jsonValue));
    }

    /**
     * Add a new Notification
     * Add a new Notification
     */
    createNotification = async(requestParameters: CreateNotificationRequest): Promise<ApiResponse20> => {
        const response = await this.createNotificationRaw(requestParameters);
        return await response.value();
    }

    /**
     * Delete an Notification entry
     * Delete an Notification entry
     */
    async deleteNotificationRaw(requestParameters: DeleteNotificationRequest): Promise<runtime.ApiResponse<ApiResponse17>> {
        if (requestParameters.notificationId === null || requestParameters.notificationId === undefined) {
            throw new runtime.RequiredError('notificationId','Required parameter requestParameters.notificationId was null or undefined when calling deleteNotification.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/notification/{notificationId}`.replace(`{${"notificationId"}}`, encodeURIComponent(String(requestParameters.notificationId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResponse17FromJSON(jsonValue));
    }

    /**
     * Delete an Notification entry
     * Delete an Notification entry
     */
    deleteNotification = async(requestParameters: DeleteNotificationRequest): Promise<ApiResponse17> => {
        const response = await this.deleteNotificationRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieve List of Notification
     * Retrieve List of Notification
     */
    async retrieveListNotificationRaw(requestParameters: RetrieveListNotificationRequest): Promise<runtime.ApiResponse<NotificationListResponse>> {
        if (requestParameters.arguments === null || requestParameters.arguments === undefined) {
            throw new runtime.RequiredError('arguments','Required parameter requestParameters.arguments was null or undefined when calling retrieveListNotification.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/notification`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: NotificationRetrieveListArgumentsToJSON(requestParameters.arguments),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => NotificationListResponseFromJSON(jsonValue));
    }

    /**
     * Retrieve List of Notification
     * Retrieve List of Notification
     */
    retrieveListNotification = async(requestParameters: RetrieveListNotificationRequest): Promise<NotificationListResponse> => {
        const response = await this.retrieveListNotificationRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieve an Notification
     * Retrieve a Notification
     */
    async retrieveNotificationRaw(requestParameters: RetrieveNotificationRequest): Promise<runtime.ApiResponse<NotificationModel>> {
        if (requestParameters.notificationId === null || requestParameters.notificationId === undefined) {
            throw new runtime.RequiredError('notificationId','Required parameter requestParameters.notificationId was null or undefined when calling retrieveNotification.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.includeCategoryTypeName !== undefined) {
            queryParameters['include_category_type_name'] = requestParameters.includeCategoryTypeName;
        }

        if (requestParameters.includeRecordInfo !== undefined) {
            queryParameters['include_record_info'] = requestParameters.includeRecordInfo;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/notification/{notificationId}`.replace(`{${"notificationId"}}`, encodeURIComponent(String(requestParameters.notificationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => NotificationModelFromJSON(jsonValue));
    }

    /**
     * Retrieve an Notification
     * Retrieve a Notification
     */
    retrieveNotification = async(requestParameters: RetrieveNotificationRequest): Promise<NotificationModel> => {
        const response = await this.retrieveNotificationRaw(requestParameters);
        return await response.value();
    }

    /**
     * Set (create or update) a notification. If id or links are set in the data, and a corresponding notification is found in Bumbal, an update will be performed.
     * Set (create or update) a notification
     */
    async setNotificationRaw(requestParameters: SetNotificationRequest): Promise<runtime.ApiResponse<ModelApiResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/notification/set`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NotificationModelToJSON(requestParameters.body),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelApiResponseFromJSON(jsonValue));
    }

    /**
     * Set (create or update) a notification. If id or links are set in the data, and a corresponding notification is found in Bumbal, an update will be performed.
     * Set (create or update) a notification
     */
    setNotification = async(requestParameters: SetNotificationRequest): Promise<ModelApiResponse> => {
        const response = await this.setNotificationRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update a specific Notification object
     * Update a specific Notification object
     */
    async updateNotificationRaw(requestParameters: UpdateNotificationRequest): Promise<runtime.ApiResponse<ApiResponse16>> {
        if (requestParameters.notificationId === null || requestParameters.notificationId === undefined) {
            throw new runtime.RequiredError('notificationId','Required parameter requestParameters.notificationId was null or undefined when calling updateNotification.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/notification/{notificationId}`.replace(`{${"notificationId"}}`, encodeURIComponent(String(requestParameters.notificationId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: NotificationModelToJSON(requestParameters.body),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResponse16FromJSON(jsonValue));
    }

    /**
     * Update a specific Notification object
     * Update a specific Notification object
     */
    updateNotification = async(requestParameters: UpdateNotificationRequest): Promise<ApiResponse16> => {
        const response = await this.updateNotificationRaw(requestParameters);
        return await response.value();
    }

}
