/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal Client Api
 * Bumbal API documentation
 *
 * The version of the OpenAPI document: 2.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel } from '../runtime';

export interface ZoneRangeModelInterface {
    id?: number;
    zipcode_from?: number;
    zipcode_to?: number;
    iso_country?: string;
}

/**
 * 
 * @export
 * @interface ZoneRangeModel
 */
export class ZoneRangeModel extends BaseModel<ZoneRangeModelInterface> {

    /**
     * Unique Zone type ID
     * @type {number}
     * @memberof ZoneRangeModel
     */
    id?: number;
    /**
     * Zipcode range start
     * @type {number}
     * @memberof ZoneRangeModel
     */
    zipcode_from?: number;
    /**
     * Zipcode range end
     * @type {number}
     * @memberof ZoneRangeModel
     */
    zipcode_to?: number;
    /**
     * 
     * @type {string}
     * @memberof ZoneRangeModel
     */
    iso_country?: string;

    constructor(data?: ZoneRangeModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(value: ZoneRangeModelInterface): { [property: string]: any } {
        return {};
    }

}

export function ZoneRangeModelFromJSON(json: any): ZoneRangeModel {
    return ZoneRangeModelFromJSONTyped(json, false);
}

export function ZoneRangeModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ZoneRangeModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = ZoneRangeModel;
    try {
        let localClass = require.context('../override', true)('./ZoneRangeModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'id': !exists(json, 'id') ? undefined : Number(json['id']),
        'zipcode_from': !exists(json, 'zipcode_from') ? undefined : Number(json['zipcode_from']),
        'zipcode_to': !exists(json, 'zipcode_to') ? undefined : Number(json['zipcode_to']),
        'iso_country': !exists(json, 'iso_country') ? undefined : json['iso_country'],
    });
}

export function ZoneRangeModelToJSON(value?: ZoneRangeModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = ZoneRangeModel;
    try {
        let localClass = require.context('../override', true)('./ZoneRangeModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return {
        
        'id': value.id,
        'zipcode_from': value.zipcode_from,
        'zipcode_to': value.zipcode_to,
        'iso_country': value.iso_country,
        ...createClass.toJson(value),
    };
}


