import { AppActionTypes } from './../app/types';
import ActionTypes from '../../settings/ActionTypes';
import apiConfig from '../../settings/ApiConfig';
import LocalStorageManager from '../../utils/LocalStorageManager';
import {
    AuthenticateState,
    AuthenticateActionTypes
} from './types';


const initialState = (): AuthenticateState => {
    const lsm = new LocalStorageManager();
    return {
        isLoggedIn: lsm.isLoggedIn(),
        token: lsm.getToken(),
        isFetchingData: false,
        signInFailure: false,
        user: undefined,
        error: undefined
    }
};

export function AuthenticateReducers(state = initialState(), action: AuthenticateActionTypes | AppActionTypes): AuthenticateState {
    const lsm = new LocalStorageManager();
    switch (action.type) {

        case ActionTypes.API_SIGN_IN:
            return {
                ...state,
                isFetchingData: true
            }

        case ActionTypes.API_SIGN_IN_SUCCESS:
            lsm.setLogin(action.response.token);
            lsm.setId(action.response.user?.id);
            apiConfig(action.response.token);

            return {
                ...state,
                isFetchingData: false,
                signInFailure: false,
                isLoggedIn: true,
                user: action.response.user,
                token: action.response.token,
                error: undefined
            }

        case ActionTypes.API_SIGN_IN_FAILURE:
            return {
                ...state,
                isFetchingData: false,
                signInFailure: true,
                isLoggedIn: false
            }
        case ActionTypes.API_SIGN_OUT:
            return {
                ...state,
                isFetchingData: true
            }
        case ActionTypes.API_SIGN_OUT_SUCCESS:
            lsm.clearLogin();
            lsm.clearEnvironment();
            lsm.clearId();
            window.location.href = '/sign-in';

            return {
                ...state,
                isFetchingData: false,
                isLoggedIn: false,
                token: undefined,
                user: undefined
            }
        case ActionTypes.API_CALL_FAILURE:
            if (action.error && (action.error.status === 401 || action.error.status === 403)) {
                lsm.clearLogin();
                return { ...state, isLoggedIn: false, token: undefined, isFetchingData: false }
            } else {
                return { ...state, error: action.error, isFetchingData: false };
            }

        default:
            return state;
    }
}