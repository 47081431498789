import { SetUserRequest } from './../api/apis/UsersApi';
import { getAcademyMetadata } from './../utils/MetaDataUtil';
import { takeLatest, put, select } from 'redux-saga/effects';
import ActionTypes from '../settings/ActionTypes';
import { ApiSaga } from './ApiSaga';
import AppActions from '../store/app/actions';
import { RetrieveUserAction, UpdateUserAction } from '../store/user/types';
import UserActions from '../store/user/actions';
import { UsersApi } from '../api/apis';
import { UsersOptionsModel } from '../api/models';

export function* UserSagas() {
    yield takeLatest(ActionTypes.API_USER_RETRIEVE, UserSaga.userGetSaga);
    yield takeLatest(ActionTypes.API_USER_UPDATE, UserSaga.userUpdateSaga);
}

const user = (state) => state.user.user;

export default class UserSaga {

    static *userGetSaga(action: RetrieveUserAction) {

        const api = new UsersApi();

        const args = {
            filters: {
                id: [action.params?.usersId]
            },
            as_list: false,
            options: {
                include_user_meta_data: true,
            } as UsersOptionsModel
        }

        try {
            const response = yield* ApiSaga(api.retrieveListUsers, { arguments: args });
            if (response.items?.length === 0) {
                yield put(AppActions.throw404error());
            }

            // dispatch a success action to the store with the activities
            yield put(UserActions.retrieveUserSuccess(response));
        } catch (e) {
            yield put(AppActions.createError(ActionTypes.API_USER_RETRIEVE_ERROR, e as Error))
        }
    }

    static * userUpdateSaga(action: UpdateUserAction) {
        const api = new UsersApi();
        const { course, version, subject, chapter } = action.params;

        const savedUser = yield select(user);
        const academyMetadata = getAcademyMetadata(savedUser.meta_data);

        academyMetadata.value_object[course][version][subject][chapter] = true;

        const setUserRequest = {
            body: {
                id: savedUser.id,
                meta_data: [
                    academyMetadata
                ]
            }
        } as SetUserRequest

        yield put(UserActions.updateMetaData(academyMetadata));

        try {
            const response = yield* ApiSaga(api.setUser, setUserRequest);
            yield put(UserActions.updateUserSuccess(response))
        } catch (e) {
            yield put(AppActions.createError(ActionTypes.API_USER_UPDATE_ERROR, e as Error))
        }
    }
}

