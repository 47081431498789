/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal Client Api
 * Bumbal API documentation
 *
 * The version of the OpenAPI document: 2.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel } from '../runtime';
import {
    ZoneFiltersModel,
    ZoneFiltersModelFromJSON,
    ZoneFiltersModelFromJSONTyped,
    ZoneFiltersModelToJSON,
    ZoneOptionsModel,
    ZoneOptionsModelFromJSON,
    ZoneOptionsModelFromJSONTyped,
    ZoneOptionsModelToJSON,
} from './';


export interface ZoneRetrieveListArgumentsInterface {
    options?: ZoneOptionsModel;
    filters?: ZoneFiltersModel;
    limit?: number;
    offset?: number;
    sorting_column?: ZoneRetrieveListArgumentsSortingColumnEnum;
    sorting_direction?: ZoneRetrieveListArgumentsSortingDirectionEnum;
    search_text?: string;
    count_only?: boolean;
}

/**
 * 
 * @export
 * @interface ZoneRetrieveListArguments
 */
export class ZoneRetrieveListArguments extends BaseModel<ZoneRetrieveListArgumentsInterface> {

    /**
     * 
     * @type {ZoneOptionsModel}
     * @memberof ZoneRetrieveListArguments
     */
    options?: ZoneOptionsModel;
    /**
     * 
     * @type {ZoneFiltersModel}
     * @memberof ZoneRetrieveListArguments
     */
    filters?: ZoneFiltersModel;
    /**
     * 
     * @type {number}
     * @memberof ZoneRetrieveListArguments
     */
    limit?: number;
    /**
     * 
     * @type {number}
     * @memberof ZoneRetrieveListArguments
     */
    offset?: number;
    /**
     * Sorting Column
     * @type {string}
     * @memberof ZoneRetrieveListArguments
     */
    sorting_column?: ZoneRetrieveListArgumentsSortingColumnEnum;
    /**
     * Sorting Direction
     * @type {string}
     * @memberof ZoneRetrieveListArguments
     */
    sorting_direction?: ZoneRetrieveListArgumentsSortingDirectionEnum;
    /**
     * 
     * @type {string}
     * @memberof ZoneRetrieveListArguments
     */
    search_text?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ZoneRetrieveListArguments
     */
    count_only?: boolean;

    constructor(data?: ZoneRetrieveListArgumentsInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(value: ZoneRetrieveListArgumentsInterface): { [property: string]: any } {
        return {};
    }

}

export function ZoneRetrieveListArgumentsFromJSON(json: any): ZoneRetrieveListArguments {
    return ZoneRetrieveListArgumentsFromJSONTyped(json, false);
}

export function ZoneRetrieveListArgumentsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ZoneRetrieveListArguments {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = ZoneRetrieveListArguments;
    try {
        let localClass = require.context('../override', true)('./ZoneRetrieveListArguments');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'options': !exists(json, 'options') ? undefined : ZoneOptionsModelFromJSON(json['options']),
        'filters': !exists(json, 'filters') ? undefined : ZoneFiltersModelFromJSON(json['filters']),
        'limit': !exists(json, 'limit') ? undefined : Number(json['limit']),
        'offset': !exists(json, 'offset') ? undefined : Number(json['offset']),
        'sorting_column': !exists(json, 'sorting_column') ? undefined : json['sorting_column'],
        'sorting_direction': !exists(json, 'sorting_direction') ? undefined : json['sorting_direction'],
        'search_text': !exists(json, 'search_text') ? undefined : json['search_text'],
        'count_only': !exists(json, 'count_only') ? undefined : json['count_only'],
    });
}

export function ZoneRetrieveListArgumentsToJSON(value?: ZoneRetrieveListArguments | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = ZoneRetrieveListArguments;
    try {
        let localClass = require.context('../override', true)('./ZoneRetrieveListArguments');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return {
        
        'options': ZoneOptionsModelToJSON(value.options),
        'filters': ZoneFiltersModelToJSON(value.filters),
        'limit': value.limit,
        'offset': value.offset,
        'sorting_column': value.sorting_column,
        'sorting_direction': value.sorting_direction,
        'search_text': value.search_text,
        'count_only': value.count_only,
        ...createClass.toJson(value),
    };
}

/**
* @export
* @enum {string}
*/
export enum ZoneRetrieveListArgumentsSortingColumnEnum {
    Name = 'name'
}
/**
* @export
* @enum {string}
*/
export enum ZoneRetrieveListArgumentsSortingDirectionEnum {
    Asc = 'asc',
    Desc = 'desc'
}


