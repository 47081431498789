import { CssBaseline, ThemeProvider } from "@mui/material";
import React from "react";
import { Switch, Route } from "react-router";
import "./App.css";
import SignIn from "./views/sign_in/SignIn";
import theme from "./settings/Theme";
import { Home } from "./views/home/Home";
import CourseView from "./views/course/CourseView";
import AppRoute from "./views/shared/AppRoute";
import apiConfig from "./settings/ApiConfig";
import AppLayout from "./views/shared/AppLayout";
import SubjectView from "./views/subject/SubjectView";
import {NotFound} from "./views/shared/NotFound";
import {ComingSoon} from "./views/shared/ComingSoon";
import { UserManualView } from "./views/subject/usermanual/UserManualView";
const App = () => {
    apiConfig();

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <AppLayout>
                <Switch>
                    <Route exact path="/sign-in" component={SignIn} />
                    <AppRoute exact path="/planner" component={CourseView} />
                    <AppRoute exact path="/driver" component={CourseView} />
                    <AppRoute exact path="/admin" component={CourseView} />
                    <Route exact path={["/usermanual/","/usermanual/:page"]} component={UserManualView} />
                    <AppRoute exact path="/contact" component={ComingSoon} />
                    <AppRoute exact path="/driver/:version/:subjectId" component={SubjectView} />
                    <AppRoute exact path="/planner/:version/:subjectId" component={SubjectView} />
                    <AppRoute exact path="/admin/:version/:subjectId" component={SubjectView} />
                    <AppRoute exact path="/driver/:version/:subjectId" component={SubjectView} />
                    <AppRoute exact path="/" component={Home} />
                    <AppRoute path="*" component={NotFound} />
                </Switch>
            </AppLayout>
        </ThemeProvider>
    );
};

export default App;
