/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal Client Api
 * Bumbal API documentation
 *
 * The version of the OpenAPI document: 2.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel } from '../runtime';

export interface EmailModelInterface {
    id?: number;
    address_id?: number;
    email?: string;
    description?: string;
    primary?: boolean;
}

/**
 * 
 * @export
 * @interface EmailModel
 */
export class EmailModel extends BaseModel<EmailModelInterface> {

    /**
     * Unique ID
     * @type {number}
     * @memberof EmailModel
     */
    id?: number;
    /**
     * Address ID asociated with this email address
     * @type {number}
     * @memberof EmailModel
     */
    address_id?: number;
    /**
     * Email address
     * @type {string}
     * @memberof EmailModel
     */
    email?: string;
    /**
     * Email address description
     * @type {string}
     * @memberof EmailModel
     */
    description?: string;
    /**
     * primary Email address
     * @type {boolean}
     * @memberof EmailModel
     */
    primary?: boolean;

    constructor(data?: EmailModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(value: EmailModelInterface): { [property: string]: any } {
        return {};
    }

}

export function EmailModelFromJSON(json: any): EmailModel {
    return EmailModelFromJSONTyped(json, false);
}

export function EmailModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmailModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = EmailModel;
    try {
        let localClass = require.context('../override', true)('./EmailModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'id': !exists(json, 'id') ? undefined : Number(json['id']),
        'address_id': !exists(json, 'address_id') ? undefined : Number(json['address_id']),
        'email': !exists(json, 'email') ? undefined : json['email'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'primary': !exists(json, 'primary') ? undefined : json['primary'],
    });
}

export function EmailModelToJSON(value?: EmailModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = EmailModel;
    try {
        let localClass = require.context('../override', true)('./EmailModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return {
        
        'id': value.id,
        'address_id': value.address_id,
        'email': value.email,
        'description': value.description,
        'primary': value.primary,
        ...createClass.toJson(value),
    };
}


