import React from "react";
import { Card, CardContent, CardHeader, Grid, Grow } from "@mui/material";
import SignInForm, { SignInValues } from "./components/SignInForm";
import { connect } from "react-redux";
import AuthenticateActions from "../../store/authenticate/actions";
import { CredentialsModel } from "../../api/models";
import { Redirect } from "react-router-dom";
import { AuthenticateState } from "../../store/authenticate/types";
import ApiConfig from "../../settings/ApiConfig";

interface DispatchProps {
    signIn: (body?: CredentialsModel) => void
}

interface StateProps {
    authReducer: AuthenticateState
}

type SignInProps = DispatchProps & StateProps;

const SignIn = (props: SignInProps) => {
    const handleSignIn = (values: SignInValues) : void => {
        ApiConfig(null, values.environment);
        props.signIn(values as CredentialsModel);
    }

    if (props.authReducer.isLoggedIn) {
        return (<Redirect to={'/'} />);
    }

    return (
        <Grid container direction="row" justifyContent="center" alignItems="center" sx={{ height: "80vh" }}>
            <Grid>
                <Grow in={true}>
                    <Card sx={{width: '750px', backgroundColor: '#F1F1F1', borderRadius: '10px'}}>
                        <CardHeader titleTypographyProps={{fontWeight: 'bold'}} sx={{textAlign: 'center', borderBottom: '3px solid white'}} title="Bumbal Academy"/>
                        <CardContent>
                            <SignInForm
                                handleSignIn={handleSignIn}
                                hasSignInError={props.authReducer.signInFailure}
                            />
                        </CardContent>
                    </Card>
                </Grow>
            </Grid>
        </Grid>
    );
};

const mapStateToProps = (state) => ({
    authReducer: state.authenticate
});

const mapDispatchToProps = (dispatch: Function) => {
    return {
        signIn: (body?: CredentialsModel) => dispatch(AuthenticateActions.signIn(body))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
