import React from "react";
import { Route } from "react-router-dom";
import TokenUtil from "../../utils/TokenUtil";
import { Redirect } from "react-router-dom";

const AppRoute = ({ component: Component, ...rest }) => {
    const tokenUtil = TokenUtil.getTokenUtil();

    function renderComponent<T>(props: T) {
        return <Component {...props} />
    }

    return (
        <Route {...rest} render={
            props =>
                rest.computedMatch.isExact
                    ? !tokenUtil.isTokenExpired() ? renderComponent(props) : (<Redirect to='/sign-in' />)
                    : <Route {...props} component={<div>Page not found</div>}/>
        } />
    );
}

export default AppRoute;