/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal Client Api
 * Bumbal API documentation
 *
 * The version of the OpenAPI document: 2.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel } from '../runtime';
import {
    UsersModel,
    UsersModelFromJSON,
    UsersModelFromJSONTyped,
    UsersModelToJSON,
} from './';


export interface UsersListResponseInterface {
    items?: Array<UsersModel>;
    count_filtered?: number;
    count_unfiltered?: number;
    count_limited?: number;
}

/**
 * 
 * @export
 * @interface UsersListResponse
 */
export class UsersListResponse extends BaseModel<UsersListResponseInterface> {

    /**
     * 
     * @type {Array<UsersModel>}
     * @memberof UsersListResponse
     */
    items?: Array<UsersModel>;
    /**
     * Count of total items with filters in place
     * @type {number}
     * @memberof UsersListResponse
     */
    count_filtered?: number;
    /**
     * Count of total items without filters in place
     * @type {number}
     * @memberof UsersListResponse
     */
    count_unfiltered?: number;
    /**
     * Count of items with limit in place
     * @type {number}
     * @memberof UsersListResponse
     */
    count_limited?: number;

    constructor(data?: UsersListResponseInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(value: UsersListResponseInterface): { [property: string]: any } {
        return {};
    }

}

export function UsersListResponseFromJSON(json: any): UsersListResponse {
    return UsersListResponseFromJSONTyped(json, false);
}

export function UsersListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UsersListResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = UsersListResponse;
    try {
        let localClass = require.context('../override', true)('./UsersListResponse');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'items': !exists(json, 'items') ? undefined : ((json['items'] as Array<any>).map(UsersModelFromJSON)),
        'count_filtered': !exists(json, 'count_filtered') ? undefined : Number(json['count_filtered']),
        'count_unfiltered': !exists(json, 'count_unfiltered') ? undefined : Number(json['count_unfiltered']),
        'count_limited': !exists(json, 'count_limited') ? undefined : Number(json['count_limited']),
    });
}

export function UsersListResponseToJSON(value?: UsersListResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = UsersListResponse;
    try {
        let localClass = require.context('../override', true)('./UsersListResponse');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return {
        
        'items': value.items === undefined ? undefined : ((value.items as Array<any>).map(UsersModelToJSON)),
        'count_filtered': value.count_filtered,
        'count_unfiltered': value.count_unfiltered,
        'count_limited': value.count_limited,
        ...createClass.toJson(value),
    };
}


