/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal Client Api
 * Bumbal API documentation
 *
 * The version of the OpenAPI document: 2.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel } from '../runtime';
import {
    CapacityModel,
    CapacityModelFromJSON,
    CapacityModelFromJSONTyped,
    CapacityModelToJSON,
    LinkModel,
    LinkModelFromJSON,
    LinkModelFromJSONTyped,
    LinkModelToJSON,
    MetaDataModel,
    MetaDataModelFromJSON,
    MetaDataModelFromJSONTyped,
    MetaDataModelToJSON,
    TagModel,
    TagModelFromJSON,
    TagModelFromJSONTyped,
    TagModelToJSON,
} from './';


export interface EquipmentModelInterface {
    id?: number;
    equipment_type_id?: number;
    equipment_type_name?: EquipmentModelEquipmentTypeNameEnum;
    info?: string;
    name?: string;
    registration_nr?: string;
    max_speed?: number;
    tags?: Array<TagModel>;
    applied_capacities?: object;
    capacities?: Array<CapacityModel>;
    links?: Array<LinkModel>;
    meta_data?: Array<MetaDataModel>;
}

/**
 * 
 * @export
 * @interface EquipmentModel
 */
export class EquipmentModel extends BaseModel<EquipmentModelInterface> {

    /**
     * 
     * @type {number}
     * @memberof EquipmentModel
     */
    id?: number;
    /**
     * Equipment Type ID
     * @type {number}
     * @memberof EquipmentModel
     */
    equipment_type_id?: number;
    /**
     * Equipment Type Name
     * @type {string}
     * @memberof EquipmentModel
     */
    equipment_type_name?: EquipmentModelEquipmentTypeNameEnum;
    /**
     * 
     * @type {string}
     * @memberof EquipmentModel
     */
    info?: string;
    /**
     * 
     * @type {string}
     * @memberof EquipmentModel
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof EquipmentModel
     */
    registration_nr?: string;
    /**
     * 
     * @type {number}
     * @memberof EquipmentModel
     */
    max_speed?: number;
    /**
     * 
     * @type {Array<TagModel>}
     * @memberof EquipmentModel
     */
    tags?: Array<TagModel>;
    /**
     * 
     * @type {object}
     * @memberof EquipmentModel
     */
    applied_capacities?: object;
    /**
     * 
     * @type {Array<CapacityModel>}
     * @memberof EquipmentModel
     */
    capacities?: Array<CapacityModel>;
    /**
     * 
     * @type {Array<LinkModel>}
     * @memberof EquipmentModel
     */
    links?: Array<LinkModel>;
    /**
     * 
     * @type {Array<MetaDataModel>}
     * @memberof EquipmentModel
     */
    meta_data?: Array<MetaDataModel>;

    constructor(data?: EquipmentModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(value: EquipmentModelInterface): { [property: string]: any } {
        return {};
    }

}

export function EquipmentModelFromJSON(json: any): EquipmentModel {
    return EquipmentModelFromJSONTyped(json, false);
}

export function EquipmentModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): EquipmentModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = EquipmentModel;
    try {
        let localClass = require.context('../override', true)('./EquipmentModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'id': !exists(json, 'id') ? undefined : Number(json['id']),
        'equipment_type_id': !exists(json, 'equipment_type_id') ? undefined : Number(json['equipment_type_id']),
        'equipment_type_name': !exists(json, 'equipment_type_name') ? undefined : json['equipment_type_name'],
        'info': !exists(json, 'info') ? undefined : json['info'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'registration_nr': !exists(json, 'registration_nr') ? undefined : json['registration_nr'],
        'max_speed': !exists(json, 'max_speed') ? undefined : Number(json['max_speed']),
        'tags': !exists(json, 'tags') ? undefined : ((json['tags'] as Array<any>).map(TagModelFromJSON)),
        'applied_capacities': !exists(json, 'applied_capacities') ? undefined : json['applied_capacities'],
        'capacities': !exists(json, 'capacities') ? undefined : ((json['capacities'] as Array<any>).map(CapacityModelFromJSON)),
        'links': !exists(json, 'links') ? undefined : ((json['links'] as Array<any>).map(LinkModelFromJSON)),
        'meta_data': !exists(json, 'meta_data') ? undefined : ((json['meta_data'] as Array<any>).map(MetaDataModelFromJSON)),
    });
}

export function EquipmentModelToJSON(value?: EquipmentModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = EquipmentModel;
    try {
        let localClass = require.context('../override', true)('./EquipmentModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return {
        
        'id': value.id,
        'equipment_type_id': value.equipment_type_id,
        'equipment_type_name': value.equipment_type_name,
        'info': value.info,
        'name': value.name,
        'registration_nr': value.registration_nr,
        'max_speed': value.max_speed,
        'tags': value.tags === undefined ? undefined : ((value.tags as Array<any>).map(TagModelToJSON)),
        'applied_capacities': value.applied_capacities,
        'capacities': value.capacities === undefined ? undefined : ((value.capacities as Array<any>).map(CapacityModelToJSON)),
        'links': value.links === undefined ? undefined : ((value.links as Array<any>).map(LinkModelToJSON)),
        'meta_data': value.meta_data === undefined ? undefined : ((value.meta_data as Array<any>).map(MetaDataModelToJSON)),
        ...createClass.toJson(value),
    };
}

/**
* @export
* @enum {string}
*/
export enum EquipmentModelEquipmentTypeNameEnum {
    Car = 'car',
    Trailer = 'trailer',
    Other = 'other'
}


