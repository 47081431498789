/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal Client Api
 * Bumbal API documentation
 *
 * The version of the OpenAPI document: 2.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel } from '../runtime';
import {
    DriverFiltersModel,
    DriverFiltersModelFromJSON,
    DriverFiltersModelFromJSONTyped,
    DriverFiltersModelToJSON,
    DriverOptionsModel,
    DriverOptionsModelFromJSON,
    DriverOptionsModelFromJSONTyped,
    DriverOptionsModelToJSON,
} from './';


export interface DriverRetrieveListArgumentsInterface {
    options?: DriverOptionsModel;
    filters?: DriverFiltersModel;
    limit?: number;
    offset?: number;
    sorting_column?: DriverRetrieveListArgumentsSortingColumnEnum;
    sorting_direction?: DriverRetrieveListArgumentsSortingDirectionEnum;
    search_text?: string;
    as_list?: boolean;
}

/**
 * 
 * @export
 * @interface DriverRetrieveListArguments
 */
export class DriverRetrieveListArguments extends BaseModel<DriverRetrieveListArgumentsInterface> {

    /**
     * 
     * @type {DriverOptionsModel}
     * @memberof DriverRetrieveListArguments
     */
    options?: DriverOptionsModel;
    /**
     * 
     * @type {DriverFiltersModel}
     * @memberof DriverRetrieveListArguments
     */
    filters?: DriverFiltersModel;
    /**
     * 
     * @type {number}
     * @memberof DriverRetrieveListArguments
     */
    limit?: number;
    /**
     * 
     * @type {number}
     * @memberof DriverRetrieveListArguments
     */
    offset?: number;
    /**
     * Sorting Column
     * @type {string}
     * @memberof DriverRetrieveListArguments
     */
    sorting_column?: DriverRetrieveListArgumentsSortingColumnEnum;
    /**
     * Sorting Direction
     * @type {string}
     * @memberof DriverRetrieveListArguments
     */
    sorting_direction?: DriverRetrieveListArgumentsSortingDirectionEnum;
    /**
     * 
     * @type {string}
     * @memberof DriverRetrieveListArguments
     */
    search_text?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DriverRetrieveListArguments
     */
    as_list?: boolean;

    constructor(data?: DriverRetrieveListArgumentsInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(value: DriverRetrieveListArgumentsInterface): { [property: string]: any } {
        return {};
    }

}

export function DriverRetrieveListArgumentsFromJSON(json: any): DriverRetrieveListArguments {
    return DriverRetrieveListArgumentsFromJSONTyped(json, false);
}

export function DriverRetrieveListArgumentsFromJSONTyped(json: any, ignoreDiscriminator: boolean): DriverRetrieveListArguments {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = DriverRetrieveListArguments;
    try {
        let localClass = require.context('../override', true)('./DriverRetrieveListArguments');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'options': !exists(json, 'options') ? undefined : DriverOptionsModelFromJSON(json['options']),
        'filters': !exists(json, 'filters') ? undefined : DriverFiltersModelFromJSON(json['filters']),
        'limit': !exists(json, 'limit') ? undefined : Number(json['limit']),
        'offset': !exists(json, 'offset') ? undefined : Number(json['offset']),
        'sorting_column': !exists(json, 'sorting_column') ? undefined : json['sorting_column'],
        'sorting_direction': !exists(json, 'sorting_direction') ? undefined : json['sorting_direction'],
        'search_text': !exists(json, 'search_text') ? undefined : json['search_text'],
        'as_list': !exists(json, 'as_list') ? undefined : json['as_list'],
    });
}

export function DriverRetrieveListArgumentsToJSON(value?: DriverRetrieveListArguments | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = DriverRetrieveListArguments;
    try {
        let localClass = require.context('../override', true)('./DriverRetrieveListArguments');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return {
        
        'options': DriverOptionsModelToJSON(value.options),
        'filters': DriverFiltersModelToJSON(value.filters),
        'limit': value.limit,
        'offset': value.offset,
        'sorting_column': value.sorting_column,
        'sorting_direction': value.sorting_direction,
        'search_text': value.search_text,
        'as_list': value.as_list,
        ...createClass.toJson(value),
    };
}

/**
* @export
* @enum {string}
*/
export enum DriverRetrieveListArgumentsSortingColumnEnum {
    Id = 'id',
    FirstName = 'first_name',
    LastName = 'last_name',
    Prefix = 'prefix',
    FullName = 'full_name',
    Email = 'email'
}
/**
* @export
* @enum {string}
*/
export enum DriverRetrieveListArgumentsSortingDirectionEnum {
    Asc = 'asc',
    Desc = 'desc'
}


