export * from './runtime';
export * from './apis';
export * from './models';

declare global {
    interface Date {
        toApiFormat(): string;
    }
}

// eslint-disable-next-line
Date.prototype.toApiFormat = function() {
    let date = `${this.getFullYear()}-${(this.getMonth() + 1).toString().padStart(2,'0')}-${(this.getDate()).toString().padStart(2,'0')}`;
    let time = `${this.getHours().toString().padStart(2,'0')}:${this.getMinutes().toString().padStart(2,'0')}`;
    return  `${date} ${time}` ;
}