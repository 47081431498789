/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal Client Api
 * Bumbal API documentation
 *
 * The version of the OpenAPI document: 2.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AssignmentListResponse,
    AssignmentListResponseFromJSON,
    AssignmentListResponseToJSON,
    AssignmentModel,
    AssignmentModelFromJSON,
    AssignmentModelToJSON,
    AssignmentRetrieveListArguments,
    AssignmentRetrieveListArgumentsFromJSON,
    AssignmentRetrieveListArgumentsToJSON,
    ModelApiResponse,
    ModelApiResponseFromJSON,
    ModelApiResponseToJSON,
} from '../models';

export interface DeleteAssignmentRequest {
    assignmentId: number;
}

export interface RetrieveAssignmentRequest {
    assignmentId: number;
    includeActivities?: boolean;
    includeMetaData?: boolean;
    includeLinks?: boolean;
    includeFiles?: boolean;
    includeTagIds?: boolean;
    includeTagNames?: boolean;
    includeBookingAccount?: boolean;
    includeRecordInfo?: boolean;
    includeRecordObject?: boolean;
}

export interface RetrieveListAssignmentRequest {
    arguments: AssignmentRetrieveListArguments;
}

export interface SetAssignmentRequest {
    body?: AssignmentModel;
}

export interface UpdateAssignmentRequest {
    assignmentId: number;
    body?: AssignmentModel;
}

/**
 * 
 */
export class AssignmentApi extends runtime.BaseAPI {

    /**
     * Delete an assignment
     * Delete an assignment
     */
    async deleteAssignmentRaw(requestParameters: DeleteAssignmentRequest): Promise<runtime.ApiResponse<ModelApiResponse>> {
        if (requestParameters.assignmentId === null || requestParameters.assignmentId === undefined) {
            throw new runtime.RequiredError('assignmentId','Required parameter requestParameters.assignmentId was null or undefined when calling deleteAssignment.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/assignment/{assignmentId}`.replace(`{${"assignmentId"}}`, encodeURIComponent(String(requestParameters.assignmentId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelApiResponseFromJSON(jsonValue));
    }

    /**
     * Delete an assignment
     * Delete an assignment
     */
    deleteAssignment = async(requestParameters: DeleteAssignmentRequest): Promise<ModelApiResponse> => {
        const response = await this.deleteAssignmentRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns a single assignment
     * Find assignment by ID
     */
    async retrieveAssignmentRaw(requestParameters: RetrieveAssignmentRequest): Promise<runtime.ApiResponse<AssignmentModel>> {
        if (requestParameters.assignmentId === null || requestParameters.assignmentId === undefined) {
            throw new runtime.RequiredError('assignmentId','Required parameter requestParameters.assignmentId was null or undefined when calling retrieveAssignment.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.includeActivities !== undefined) {
            queryParameters['include_activities'] = requestParameters.includeActivities;
        }

        if (requestParameters.includeMetaData !== undefined) {
            queryParameters['include_meta_data'] = requestParameters.includeMetaData;
        }

        if (requestParameters.includeLinks !== undefined) {
            queryParameters['include_links'] = requestParameters.includeLinks;
        }

        if (requestParameters.includeFiles !== undefined) {
            queryParameters['include_files'] = requestParameters.includeFiles;
        }

        if (requestParameters.includeTagIds !== undefined) {
            queryParameters['include_tag_ids'] = requestParameters.includeTagIds;
        }

        if (requestParameters.includeTagNames !== undefined) {
            queryParameters['include_tag_names'] = requestParameters.includeTagNames;
        }

        if (requestParameters.includeBookingAccount !== undefined) {
            queryParameters['include_booking_account'] = requestParameters.includeBookingAccount;
        }

        if (requestParameters.includeRecordInfo !== undefined) {
            queryParameters['include_record_info'] = requestParameters.includeRecordInfo;
        }

        if (requestParameters.includeRecordObject !== undefined) {
            queryParameters['include_record_object'] = requestParameters.includeRecordObject;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/assignment/{assignmentId}`.replace(`{${"assignmentId"}}`, encodeURIComponent(String(requestParameters.assignmentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AssignmentModelFromJSON(jsonValue));
    }

    /**
     * Returns a single assignment
     * Find assignment by ID
     */
    retrieveAssignment = async(requestParameters: RetrieveAssignmentRequest): Promise<AssignmentModel> => {
        const response = await this.retrieveAssignmentRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieve List of Assignments
     * Retrieve List of Assignments
     */
    async retrieveListAssignmentRaw(requestParameters: RetrieveListAssignmentRequest): Promise<runtime.ApiResponse<AssignmentListResponse>> {
        if (requestParameters.arguments === null || requestParameters.arguments === undefined) {
            throw new runtime.RequiredError('arguments','Required parameter requestParameters.arguments was null or undefined when calling retrieveListAssignment.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/assignment`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AssignmentRetrieveListArgumentsToJSON(requestParameters.arguments),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AssignmentListResponseFromJSON(jsonValue));
    }

    /**
     * Retrieve List of Assignments
     * Retrieve List of Assignments
     */
    retrieveListAssignment = async(requestParameters: RetrieveListAssignmentRequest): Promise<AssignmentListResponse> => {
        const response = await this.retrieveListAssignmentRaw(requestParameters);
        return await response.value();
    }

    /**
     * Set (create or update) an Assignment. If id or links are set in the data, and a corresponding assignment is found in Bumbal, an update will be performed.
     * Set (create or update) an Assignment
     */
    async setAssignmentRaw(requestParameters: SetAssignmentRequest): Promise<runtime.ApiResponse<ModelApiResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/assignment/set`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AssignmentModelToJSON(requestParameters.body),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelApiResponseFromJSON(jsonValue));
    }

    /**
     * Set (create or update) an Assignment. If id or links are set in the data, and a corresponding assignment is found in Bumbal, an update will be performed.
     * Set (create or update) an Assignment
     */
    setAssignment = async(requestParameters: SetAssignmentRequest): Promise<ModelApiResponse> => {
        const response = await this.setAssignmentRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update a assignment
     * Update a assignment
     */
    async updateAssignmentRaw(requestParameters: UpdateAssignmentRequest): Promise<runtime.ApiResponse<ModelApiResponse>> {
        if (requestParameters.assignmentId === null || requestParameters.assignmentId === undefined) {
            throw new runtime.RequiredError('assignmentId','Required parameter requestParameters.assignmentId was null or undefined when calling updateAssignment.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/assignment/{assignmentId}`.replace(`{${"assignmentId"}}`, encodeURIComponent(String(requestParameters.assignmentId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AssignmentModelToJSON(requestParameters.body),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelApiResponseFromJSON(jsonValue));
    }

    /**
     * Update a assignment
     * Update a assignment
     */
    updateAssignment = async(requestParameters: UpdateAssignmentRequest): Promise<ModelApiResponse> => {
        const response = await this.updateAssignmentRaw(requestParameters);
        return await response.value();
    }

}
