/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal Client Api
 * Bumbal API documentation
 *
 * The version of the OpenAPI document: 2.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiResponse35,
    ApiResponse35FromJSON,
    ApiResponse35ToJSON,
    ApiResponse36,
    ApiResponse36FromJSON,
    ApiResponse36ToJSON,
    ApiResponse37,
    ApiResponse37FromJSON,
    ApiResponse37ToJSON,
    ApiResponse38,
    ApiResponse38FromJSON,
    ApiResponse38ToJSON,
    ApiResponse401,
    ApiResponse401FromJSON,
    ApiResponse401ToJSON,
    ApiResponse403,
    ApiResponse403FromJSON,
    ApiResponse403ToJSON,
    ApiResponse405,
    ApiResponse405FromJSON,
    ApiResponse405ToJSON,
    ModelApiResponse,
    ModelApiResponseFromJSON,
    ModelApiResponseToJSON,
    QuestionnaireTemplateQuestionListResponse,
    QuestionnaireTemplateQuestionListResponseFromJSON,
    QuestionnaireTemplateQuestionListResponseToJSON,
    QuestionnaireTemplateQuestionModel,
    QuestionnaireTemplateQuestionModelFromJSON,
    QuestionnaireTemplateQuestionModelToJSON,
    QuestionnaireTemplateQuestionRetrieveListArguments,
    QuestionnaireTemplateQuestionRetrieveListArgumentsFromJSON,
    QuestionnaireTemplateQuestionRetrieveListArgumentsToJSON,
} from '../models';

export interface CreateQuestionnaireTemplateQuestionRequest {
    body?: QuestionnaireTemplateQuestionModel;
}

export interface DeleteQuestionnaireTemplateQuestionRequest {
    questionnaireTemplateQuestionId: number;
}

export interface RetrieveListQuestionnaireTemplateQuestionRequest {
    arguments: QuestionnaireTemplateQuestionRetrieveListArguments;
}

export interface RetrieveQuestionnaireTemplateQuestionRequest {
    questionnaireTemplateQuestionId: number;
}

export interface SetQuestionnaireTemplateQuestionRequest {
    body?: QuestionnaireTemplateQuestionModel;
}

export interface UpdateQuestionnaireTemplateQuestionRequest {
    questionnaireTemplateQuestionId: number;
    body?: QuestionnaireTemplateQuestionModel;
}

/**
 * 
 */
export class QuestionnaireTemplateQuestionApi extends runtime.BaseAPI {

    /**
     * Add a new QuestionnaireTemplateQuestion
     * Add a new QuestionnaireTemplateQuestion
     */
    async createQuestionnaireTemplateQuestionRaw(requestParameters: CreateQuestionnaireTemplateQuestionRequest): Promise<runtime.ApiResponse<ApiResponse38>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/questionnaire-template-question`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: QuestionnaireTemplateQuestionModelToJSON(requestParameters.body),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResponse38FromJSON(jsonValue));
    }

    /**
     * Add a new QuestionnaireTemplateQuestion
     * Add a new QuestionnaireTemplateQuestion
     */
    createQuestionnaireTemplateQuestion = async(requestParameters: CreateQuestionnaireTemplateQuestionRequest): Promise<ApiResponse38> => {
        const response = await this.createQuestionnaireTemplateQuestionRaw(requestParameters);
        return await response.value();
    }

    /**
     * Delete an QuestionnaireTemplateQuestion entry
     * Delete an QuestionnaireTemplateQuestion entry
     */
    async deleteQuestionnaireTemplateQuestionRaw(requestParameters: DeleteQuestionnaireTemplateQuestionRequest): Promise<runtime.ApiResponse<ApiResponse36>> {
        if (requestParameters.questionnaireTemplateQuestionId === null || requestParameters.questionnaireTemplateQuestionId === undefined) {
            throw new runtime.RequiredError('questionnaireTemplateQuestionId','Required parameter requestParameters.questionnaireTemplateQuestionId was null or undefined when calling deleteQuestionnaireTemplateQuestion.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/questionnaire-template-question/{questionnaireTemplateQuestionId}`.replace(`{${"questionnaireTemplateQuestionId"}}`, encodeURIComponent(String(requestParameters.questionnaireTemplateQuestionId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResponse36FromJSON(jsonValue));
    }

    /**
     * Delete an QuestionnaireTemplateQuestion entry
     * Delete an QuestionnaireTemplateQuestion entry
     */
    deleteQuestionnaireTemplateQuestion = async(requestParameters: DeleteQuestionnaireTemplateQuestionRequest): Promise<ApiResponse36> => {
        const response = await this.deleteQuestionnaireTemplateQuestionRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieve List of QuestionnaireTemplateQuestion
     * Retrieve List of QuestionnaireTemplateQuestion
     */
    async retrieveListQuestionnaireTemplateQuestionRaw(requestParameters: RetrieveListQuestionnaireTemplateQuestionRequest): Promise<runtime.ApiResponse<QuestionnaireTemplateQuestionListResponse>> {
        if (requestParameters.arguments === null || requestParameters.arguments === undefined) {
            throw new runtime.RequiredError('arguments','Required parameter requestParameters.arguments was null or undefined when calling retrieveListQuestionnaireTemplateQuestion.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/questionnaire-template-question`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: QuestionnaireTemplateQuestionRetrieveListArgumentsToJSON(requestParameters.arguments),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => QuestionnaireTemplateQuestionListResponseFromJSON(jsonValue));
    }

    /**
     * Retrieve List of QuestionnaireTemplateQuestion
     * Retrieve List of QuestionnaireTemplateQuestion
     */
    retrieveListQuestionnaireTemplateQuestion = async(requestParameters: RetrieveListQuestionnaireTemplateQuestionRequest): Promise<QuestionnaireTemplateQuestionListResponse> => {
        const response = await this.retrieveListQuestionnaireTemplateQuestionRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieve an QuestionnaireTemplateQuestion
     * Retrieve a QuestionnaireTemplateQuestion
     */
    async retrieveQuestionnaireTemplateQuestionRaw(requestParameters: RetrieveQuestionnaireTemplateQuestionRequest): Promise<runtime.ApiResponse<QuestionnaireTemplateQuestionModel>> {
        if (requestParameters.questionnaireTemplateQuestionId === null || requestParameters.questionnaireTemplateQuestionId === undefined) {
            throw new runtime.RequiredError('questionnaireTemplateQuestionId','Required parameter requestParameters.questionnaireTemplateQuestionId was null or undefined when calling retrieveQuestionnaireTemplateQuestion.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/questionnaire-template-question/{questionnaireTemplateQuestionId}`.replace(`{${"questionnaireTemplateQuestionId"}}`, encodeURIComponent(String(requestParameters.questionnaireTemplateQuestionId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => QuestionnaireTemplateQuestionModelFromJSON(jsonValue));
    }

    /**
     * Retrieve an QuestionnaireTemplateQuestion
     * Retrieve a QuestionnaireTemplateQuestion
     */
    retrieveQuestionnaireTemplateQuestion = async(requestParameters: RetrieveQuestionnaireTemplateQuestionRequest): Promise<QuestionnaireTemplateQuestionModel> => {
        const response = await this.retrieveQuestionnaireTemplateQuestionRaw(requestParameters);
        return await response.value();
    }

    /**
     * Set (create or update) a d=QuestionnaireTemplateQuestion. If id or links are set in the data, and a corresponding QuestionnaireTemplateQuestion is found in Bumbal, an update will be performed.
     * Set (create or update) a QuestionnaireTemplateQuestion
     */
    async setQuestionnaireTemplateQuestionRaw(requestParameters: SetQuestionnaireTemplateQuestionRequest): Promise<runtime.ApiResponse<ModelApiResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/questionnaire-template-question/set`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: QuestionnaireTemplateQuestionModelToJSON(requestParameters.body),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelApiResponseFromJSON(jsonValue));
    }

    /**
     * Set (create or update) a d=QuestionnaireTemplateQuestion. If id or links are set in the data, and a corresponding QuestionnaireTemplateQuestion is found in Bumbal, an update will be performed.
     * Set (create or update) a QuestionnaireTemplateQuestion
     */
    setQuestionnaireTemplateQuestion = async(requestParameters: SetQuestionnaireTemplateQuestionRequest): Promise<ModelApiResponse> => {
        const response = await this.setQuestionnaireTemplateQuestionRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update a specific QuestionnaireTemplateQuestion object
     * Update a specific QuestionnaireTemplateQuestion object
     */
    async updateQuestionnaireTemplateQuestionRaw(requestParameters: UpdateQuestionnaireTemplateQuestionRequest): Promise<runtime.ApiResponse<ApiResponse35>> {
        if (requestParameters.questionnaireTemplateQuestionId === null || requestParameters.questionnaireTemplateQuestionId === undefined) {
            throw new runtime.RequiredError('questionnaireTemplateQuestionId','Required parameter requestParameters.questionnaireTemplateQuestionId was null or undefined when calling updateQuestionnaireTemplateQuestion.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/questionnaire-template-question/{questionnaireTemplateQuestionId}`.replace(`{${"questionnaireTemplateQuestionId"}}`, encodeURIComponent(String(requestParameters.questionnaireTemplateQuestionId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: QuestionnaireTemplateQuestionModelToJSON(requestParameters.body),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResponse35FromJSON(jsonValue));
    }

    /**
     * Update a specific QuestionnaireTemplateQuestion object
     * Update a specific QuestionnaireTemplateQuestion object
     */
    updateQuestionnaireTemplateQuestion = async(requestParameters: UpdateQuestionnaireTemplateQuestionRequest): Promise<ApiResponse35> => {
        const response = await this.updateQuestionnaireTemplateQuestionRaw(requestParameters);
        return await response.value();
    }

}
