/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal Client Api
 * Bumbal API documentation
 *
 * The version of the OpenAPI document: 2.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel } from '../runtime';
import {
    MetaDataModel,
    MetaDataModelFromJSON,
    MetaDataModelFromJSONTyped,
    MetaDataModelToJSON,
    TagModel,
    TagModelFromJSON,
    TagModelFromJSONTyped,
    TagModelToJSON,
} from './';


export interface RecurrenceModelInterface {
    id?: number;
    name?: string;
    type_id?: number;
    type_name?: number;
    recurrence_type_name?: RecurrenceModelRecurrenceTypeNameEnum;
    start_date?: Date;
    end_date?: Date;
    period_name?: string;
    period_id?: number;
    recurrence_period_name?: RecurrenceModelRecurrencePeriodNameEnum;
    frequency?: number;
    count?: number;
    show_ahead?: number;
    current?: number;
    summary?: string;
    next_run?: Date;
    last_run?: Date;
    active?: boolean;
    tags?: Array<TagModel>;
    meta_data?: Array<MetaDataModel>;
    created_at?: Date;
    updated_at?: Date;
    updated_by_name?: string;
    object_id?: number;
}

/**
 * 
 * @export
 * @interface RecurrenceModel
 */
export class RecurrenceModel extends BaseModel<RecurrenceModelInterface> {

    /**
     * Unique Identifier
     * @type {number}
     * @memberof RecurrenceModel
     */
    id?: number;
    /**
     * Recurrence name
     * @type {string}
     * @memberof RecurrenceModel
     */
    name?: string;
    /**
     * recurrence type_id, 11:activity, 24:route
     * @type {number}
     * @memberof RecurrenceModel
     */
    type_id?: number;
    /**
     * recurrence type_name, activity, route
     * @type {number}
     * @memberof RecurrenceModel
     */
    type_name?: number;
    /**
     * recurrence typename, activity or route
     * @type {string}
     * @memberof RecurrenceModel
     */
    recurrence_type_name?: RecurrenceModelRecurrenceTypeNameEnum;
    /**
     * Start date
     * @type {Date}
     * @memberof RecurrenceModel
     */
    start_date?: Date;
    /**
     * End date
     * @type {Date}
     * @memberof RecurrenceModel
     */
    end_date?: Date;
    /**
     * period name
     * @type {string}
     * @memberof RecurrenceModel
     */
    period_name?: string;
    /**
     * recurrence period_id, 1:day, 2:week, 3:month
     * @type {number}
     * @memberof RecurrenceModel
     */
    period_id?: number;
    /**
     * recurrence period name: day, week or month
     * @type {string}
     * @memberof RecurrenceModel
     */
    recurrence_period_name?: RecurrenceModelRecurrencePeriodNameEnum;
    /**
     * period frequency of recurrence (2 = repeat each 2 days/weeks/months)
     * @type {number}
     * @memberof RecurrenceModel
     */
    frequency?: number;
    /**
     * nr of last recurrence to be created
     * @type {number}
     * @memberof RecurrenceModel
     */
    count?: number;
    /**
     * nr of recurrences to show ahead in system
     * @type {number}
     * @memberof RecurrenceModel
     */
    show_ahead?: number;
    /**
     * last created recurrence nr
     * @type {number}
     * @memberof RecurrenceModel
     */
    current?: number;
    /**
     * summary of recurrence
     * @type {string}
     * @memberof RecurrenceModel
     */
    summary?: string;
    /**
     * Next date on which a new recurrence will be added
     * @type {Date}
     * @memberof RecurrenceModel
     */
    next_run?: Date;
    /**
     * Last date on which a new recurrence was added
     * @type {Date}
     * @memberof RecurrenceModel
     */
    last_run?: Date;
    /**
     * if active=0: recurrence has been removed and is no longer visible in any bumbal interface
     * @type {boolean}
     * @memberof RecurrenceModel
     */
    active?: boolean;
    /**
     * 
     * @type {Array<TagModel>}
     * @memberof RecurrenceModel
     */
    tags?: Array<TagModel>;
    /**
     * 
     * @type {Array<MetaDataModel>}
     * @memberof RecurrenceModel
     */
    meta_data?: Array<MetaDataModel>;
    /**
     * created_at date time
     * @type {Date}
     * @memberof RecurrenceModel
     */
    created_at?: Date;
    /**
     * updated_at date time
     * @type {Date}
     * @memberof RecurrenceModel
     */
    updated_at?: Date;
    /**
     * Recurrence updated by user full name
     * @type {string}
     * @memberof RecurrenceModel
     */
    updated_by_name?: string;
    /**
     * ID of the object
     * @type {number}
     * @memberof RecurrenceModel
     */
    object_id?: number;

    constructor(data?: RecurrenceModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(value: RecurrenceModelInterface): { [property: string]: any } {
        return {};
    }

}

export function RecurrenceModelFromJSON(json: any): RecurrenceModel {
    return RecurrenceModelFromJSONTyped(json, false);
}

export function RecurrenceModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): RecurrenceModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = RecurrenceModel;
    try {
        let localClass = require.context('../override', true)('./RecurrenceModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'id': !exists(json, 'id') ? undefined : Number(json['id']),
        'name': !exists(json, 'name') ? undefined : json['name'],
        'type_id': !exists(json, 'type_id') ? undefined : Number(json['type_id']),
        'type_name': !exists(json, 'type_name') ? undefined : Number(json['type_name']),
        'recurrence_type_name': !exists(json, 'recurrence_type_name') ? undefined : json['recurrence_type_name'],
        'start_date': !exists(json, 'start_date') ? undefined : (new Date(json['start_date'].replace(' ', 'T'))),
        'end_date': !exists(json, 'end_date') ? undefined : (new Date(json['end_date'].replace(' ', 'T'))),
        'period_name': !exists(json, 'period_name') ? undefined : json['period_name'],
        'period_id': !exists(json, 'period_id') ? undefined : Number(json['period_id']),
        'recurrence_period_name': !exists(json, 'recurrence_period_name') ? undefined : json['recurrence_period_name'],
        'frequency': !exists(json, 'frequency') ? undefined : Number(json['frequency']),
        'count': !exists(json, 'count') ? undefined : Number(json['count']),
        'show_ahead': !exists(json, 'show_ahead') ? undefined : Number(json['show_ahead']),
        'current': !exists(json, 'current') ? undefined : Number(json['current']),
        'summary': !exists(json, 'summary') ? undefined : json['summary'],
        'next_run': !exists(json, 'next_run') ? undefined : (new Date(json['next_run'].replace(' ', 'T'))),
        'last_run': !exists(json, 'last_run') ? undefined : (new Date(json['last_run'].replace(' ', 'T'))),
        'active': !exists(json, 'active') ? undefined : json['active'],
        'tags': !exists(json, 'tags') ? undefined : ((json['tags'] as Array<any>).map(TagModelFromJSON)),
        'meta_data': !exists(json, 'meta_data') ? undefined : ((json['meta_data'] as Array<any>).map(MetaDataModelFromJSON)),
        'created_at': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'].replace(' ', 'T'))),
        'updated_at': !exists(json, 'updated_at') ? undefined : (new Date(json['updated_at'].replace(' ', 'T'))),
        'updated_by_name': !exists(json, 'updated_by_name') ? undefined : json['updated_by_name'],
        'object_id': !exists(json, 'object_id') ? undefined : Number(json['object_id']),
    });
}

export function RecurrenceModelToJSON(value?: RecurrenceModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = RecurrenceModel;
    try {
        let localClass = require.context('../override', true)('./RecurrenceModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return {
        
        'id': value.id,
        'name': value.name,
        'type_id': value.type_id,
        'type_name': value.type_name,
        'recurrence_type_name': value.recurrence_type_name,
        'start_date': value.start_date === undefined ? undefined : (value.start_date.toApiFormat()),
        'end_date': value.end_date === undefined ? undefined : (value.end_date.toApiFormat()),
        'period_name': value.period_name,
        'period_id': value.period_id,
        'recurrence_period_name': value.recurrence_period_name,
        'frequency': value.frequency,
        'count': value.count,
        'show_ahead': value.show_ahead,
        'current': value.current,
        'summary': value.summary,
        'next_run': value.next_run === undefined ? undefined : (value.next_run.toApiFormat()),
        'last_run': value.last_run === undefined ? undefined : (value.last_run.toApiFormat()),
        'active': value.active,
        'tags': value.tags === undefined ? undefined : ((value.tags as Array<any>).map(TagModelToJSON)),
        'meta_data': value.meta_data === undefined ? undefined : ((value.meta_data as Array<any>).map(MetaDataModelToJSON)),
        'created_at': value.created_at === undefined ? undefined : (value.created_at.toApiFormat()),
        'updated_at': value.updated_at === undefined ? undefined : (value.updated_at.toApiFormat()),
        'updated_by_name': value.updated_by_name,
        'object_id': value.object_id,
        ...createClass.toJson(value),
    };
}

/**
* @export
* @enum {string}
*/
export enum RecurrenceModelRecurrenceTypeNameEnum {
    Activity = 'activity',
    Route = 'route'
}
/**
* @export
* @enum {string}
*/
export enum RecurrenceModelRecurrencePeriodNameEnum {
    Day = 'day',
    Week = 'week',
    Month = 'month'
}


