import { MetaDataModel, ModelApiResponse, UsersListResponse } from "../../api";
import ActionTypes from "../../settings/ActionTypes";
import { completeChapterRequest, UserActionTypes } from "./types";

export default class UserActions {

    static retrieveUser = (params: number): UserActionTypes => {
        return {
            type: ActionTypes.API_USER_RETRIEVE,
            params: {
                usersId: params
            }
        }
    }

    static retrieveUserSuccess = (response: UsersListResponse): UserActionTypes => {
        return {
            type: ActionTypes.API_USER_RETRIEVE_SUCCESS,
            response: response
        }
    }

    static updateUser = (params: completeChapterRequest): UserActionTypes => {
        return {
            type: ActionTypes.API_USER_UPDATE,
            params: params
        }
    }

    static updateUserSuccess = (response: ModelApiResponse): UserActionTypes => {
        return {
            type: ActionTypes.API_USER_UPDATE_SUCCESS,
            response: response
        }
    }

    static updateMetaData = (academyMetaData: MetaDataModel): UserActionTypes => {
        return {
            type: ActionTypes.META_DATA_UPDATE,
            params: {
                academyMetaData
            }
        }
    }
   
}
