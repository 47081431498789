/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal Client Api
 * Bumbal API documentation
 *
 * The version of the OpenAPI document: 2.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BrandListResponse,
    BrandListResponseFromJSON,
    BrandListResponseToJSON,
    BrandModel,
    BrandModelFromJSON,
    BrandModelToJSON,
    BrandRetrieveListArguments,
    BrandRetrieveListArgumentsFromJSON,
    BrandRetrieveListArgumentsToJSON,
    ModelApiResponse,
    ModelApiResponseFromJSON,
    ModelApiResponseToJSON,
} from '../models';

export interface CreateBrandRequest {
    body?: BrandModel;
}

export interface DeleteBrandRequest {
    brandId: number;
}

export interface RetrieveBrandRequest {
    brandId: number;
}

export interface RetrieveListBrandRequest {
    arguments: BrandRetrieveListArguments;
}

export interface SetBrandRequest {
    body?: BrandModel;
}

export interface UpdateBrandRequest {
    brandId: number;
}

/**
 * 
 */
export class BrandApi extends runtime.BaseAPI {

    /**
     * Add a new Brand
     * Add a new Brand
     */
    async createBrandRaw(requestParameters: CreateBrandRequest): Promise<runtime.ApiResponse<ModelApiResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/brand`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BrandModelToJSON(requestParameters.body),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelApiResponseFromJSON(jsonValue));
    }

    /**
     * Add a new Brand
     * Add a new Brand
     */
    createBrand = async(requestParameters: CreateBrandRequest): Promise<ModelApiResponse> => {
        const response = await this.createBrandRaw(requestParameters);
        return await response.value();
    }

    /**
     * Delete a Brand
     * Delete a Brand
     */
    async deleteBrandRaw(requestParameters: DeleteBrandRequest): Promise<runtime.ApiResponse<ModelApiResponse>> {
        if (requestParameters.brandId === null || requestParameters.brandId === undefined) {
            throw new runtime.RequiredError('brandId','Required parameter requestParameters.brandId was null or undefined when calling deleteBrand.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/brand/{brandId}`.replace(`{${"brandId"}}`, encodeURIComponent(String(requestParameters.brandId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelApiResponseFromJSON(jsonValue));
    }

    /**
     * Delete a Brand
     * Delete a Brand
     */
    deleteBrand = async(requestParameters: DeleteBrandRequest): Promise<ModelApiResponse> => {
        const response = await this.deleteBrandRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieve an Brand
     * Retrieve a Brand
     */
    async retrieveBrandRaw(requestParameters: RetrieveBrandRequest): Promise<runtime.ApiResponse<BrandModel>> {
        if (requestParameters.brandId === null || requestParameters.brandId === undefined) {
            throw new runtime.RequiredError('brandId','Required parameter requestParameters.brandId was null or undefined when calling retrieveBrand.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/brand/{brandId}`.replace(`{${"brandId"}}`, encodeURIComponent(String(requestParameters.brandId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BrandModelFromJSON(jsonValue));
    }

    /**
     * Retrieve an Brand
     * Retrieve a Brand
     */
    retrieveBrand = async(requestParameters: RetrieveBrandRequest): Promise<BrandModel> => {
        const response = await this.retrieveBrandRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieve List of Brands
     * Retrieve List of Brands
     */
    async retrieveListBrandRaw(requestParameters: RetrieveListBrandRequest): Promise<runtime.ApiResponse<BrandListResponse>> {
        if (requestParameters.arguments === null || requestParameters.arguments === undefined) {
            throw new runtime.RequiredError('arguments','Required parameter requestParameters.arguments was null or undefined when calling retrieveListBrand.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/brand`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: BrandRetrieveListArgumentsToJSON(requestParameters.arguments),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BrandListResponseFromJSON(jsonValue));
    }

    /**
     * Retrieve List of Brands
     * Retrieve List of Brands
     */
    retrieveListBrand = async(requestParameters: RetrieveListBrandRequest): Promise<BrandListResponse> => {
        const response = await this.retrieveListBrandRaw(requestParameters);
        return await response.value();
    }

    /**
     * Set (create or update) a Brand. If id or links are set in the data, and a corresponding brand is found in Bumbal, an update will be performed.
     * Set (create or update) a Brand
     */
    async setBrandRaw(requestParameters: SetBrandRequest): Promise<runtime.ApiResponse<ModelApiResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/brand/set`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BrandModelToJSON(requestParameters.body),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelApiResponseFromJSON(jsonValue));
    }

    /**
     * Set (create or update) a Brand. If id or links are set in the data, and a corresponding brand is found in Bumbal, an update will be performed.
     * Set (create or update) a Brand
     */
    setBrand = async(requestParameters: SetBrandRequest): Promise<ModelApiResponse> => {
        const response = await this.setBrandRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update an Brand
     * Update a Brand
     */
    async updateBrandRaw(requestParameters: UpdateBrandRequest): Promise<runtime.ApiResponse<ModelApiResponse>> {
        if (requestParameters.brandId === null || requestParameters.brandId === undefined) {
            throw new runtime.RequiredError('brandId','Required parameter requestParameters.brandId was null or undefined when calling updateBrand.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/brand/{brandId}`.replace(`{${"brandId"}}`, encodeURIComponent(String(requestParameters.brandId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelApiResponseFromJSON(jsonValue));
    }

    /**
     * Update an Brand
     * Update a Brand
     */
    updateBrand = async(requestParameters: UpdateBrandRequest): Promise<ModelApiResponse> => {
        const response = await this.updateBrandRaw(requestParameters);
        return await response.value();
    }

}
