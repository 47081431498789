/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal Client Api
 * Bumbal API documentation
 *
 * The version of the OpenAPI document: 2.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ModelApiResponse,
    ModelApiResponseFromJSON,
    ModelApiResponseToJSON,
    SayWhenRetrievePortalURLsArguments,
    SayWhenRetrievePortalURLsArgumentsFromJSON,
    SayWhenRetrievePortalURLsArgumentsToJSON,
    SayWhenVisitModel,
    SayWhenVisitModelFromJSON,
    SayWhenVisitModelToJSON,
} from '../models';

export interface SaywhenRetrievePortalURLRequest {
    activityId: number;
}

export interface SaywhenRetrievePortalURLsRequest {
    arguments: SayWhenRetrievePortalURLsArguments;
}

export interface SaywhenRetrieveStatusRequest {
    activityId: number;
}

/**
 * 
 */
export class SaywhenApi extends runtime.BaseAPI {

    /**
     * Retrieve SayWhen Portal URL
     * Retrieve SayWhen Portal URL
     */
    async saywhenRetrievePortalURLRaw(requestParameters: SaywhenRetrievePortalURLRequest): Promise<runtime.ApiResponse<ModelApiResponse>> {
        if (requestParameters.activityId === null || requestParameters.activityId === undefined) {
            throw new runtime.RequiredError('activityId','Required parameter requestParameters.activityId was null or undefined when calling saywhenRetrievePortalURL.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/saywhen/retrieve-portal-url/{activityId}`.replace(`{${"activityId"}}`, encodeURIComponent(String(requestParameters.activityId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelApiResponseFromJSON(jsonValue));
    }

    /**
     * Retrieve SayWhen Portal URL
     * Retrieve SayWhen Portal URL
     */
    saywhenRetrievePortalURL = async(requestParameters: SaywhenRetrievePortalURLRequest): Promise<ModelApiResponse> => {
        const response = await this.saywhenRetrievePortalURLRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieve SayWhen Portal URLs
     * Retrieve SayWhen Portal URLs
     */
    async saywhenRetrievePortalURLsRaw(requestParameters: SaywhenRetrievePortalURLsRequest): Promise<runtime.ApiResponse<ModelApiResponse>> {
        if (requestParameters.arguments === null || requestParameters.arguments === undefined) {
            throw new runtime.RequiredError('arguments','Required parameter requestParameters.arguments was null or undefined when calling saywhenRetrievePortalURLs.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/saywhen/retrieve-portal-urls`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SayWhenRetrievePortalURLsArgumentsToJSON(requestParameters.arguments),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelApiResponseFromJSON(jsonValue));
    }

    /**
     * Retrieve SayWhen Portal URLs
     * Retrieve SayWhen Portal URLs
     */
    saywhenRetrievePortalURLs = async(requestParameters: SaywhenRetrievePortalURLsRequest): Promise<ModelApiResponse> => {
        const response = await this.saywhenRetrievePortalURLsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieve SayWhen Status
     * Retrieve SayWhen Status
     */
    async saywhenRetrieveStatusRaw(requestParameters: SaywhenRetrieveStatusRequest): Promise<runtime.ApiResponse<SayWhenVisitModel>> {
        if (requestParameters.activityId === null || requestParameters.activityId === undefined) {
            throw new runtime.RequiredError('activityId','Required parameter requestParameters.activityId was null or undefined when calling saywhenRetrieveStatus.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/saywhen/retrieve-status/{activityId}`.replace(`{${"activityId"}}`, encodeURIComponent(String(requestParameters.activityId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SayWhenVisitModelFromJSON(jsonValue));
    }

    /**
     * Retrieve SayWhen Status
     * Retrieve SayWhen Status
     */
    saywhenRetrieveStatus = async(requestParameters: SaywhenRetrieveStatusRequest): Promise<SayWhenVisitModel> => {
        const response = await this.saywhenRetrieveStatusRaw(requestParameters);
        return await response.value();
    }

}
