/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal Client Api
 * Bumbal API documentation
 *
 * The version of the OpenAPI document: 2.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiResponse1,
    ApiResponse1FromJSON,
    ApiResponse1ToJSON,
    ApiResponse2,
    ApiResponse2FromJSON,
    ApiResponse2ToJSON,
    ApiResponse3,
    ApiResponse3FromJSON,
    ApiResponse3ToJSON,
    ApiResponse4,
    ApiResponse4FromJSON,
    ApiResponse4ToJSON,
    ApiResponse401,
    ApiResponse401FromJSON,
    ApiResponse401ToJSON,
    ApiResponse403,
    ApiResponse403FromJSON,
    ApiResponse403ToJSON,
    ApiResponse405,
    ApiResponse405FromJSON,
    ApiResponse405ToJSON,
    DriverUnavailabilityListResponse,
    DriverUnavailabilityListResponseFromJSON,
    DriverUnavailabilityListResponseToJSON,
    DriverUnavailabilityModel,
    DriverUnavailabilityModelFromJSON,
    DriverUnavailabilityModelToJSON,
    DriverUnavailabilityRetrieveListArguments,
    DriverUnavailabilityRetrieveListArgumentsFromJSON,
    DriverUnavailabilityRetrieveListArgumentsToJSON,
    ModelApiResponse,
    ModelApiResponseFromJSON,
    ModelApiResponseToJSON,
} from '../models';

export interface CreateDriverUnavailabilityRequest {
    body?: DriverUnavailabilityModel;
}

export interface DeleteDriverUnavailabilityRequest {
    driverunavailabilityId: number;
}

export interface RetrieveDriverUnavailabilityRequest {
    driverunavailabilityId: number;
    includeObjectTypeName?: boolean;
    includeRecordInfo?: boolean;
}

export interface RetrieveListDriverUnavailabilityRequest {
    arguments: DriverUnavailabilityRetrieveListArguments;
}

export interface SetDriverUnavailabilityRequest {
    body?: DriverUnavailabilityModel;
}

export interface UpdateDriverUnavailabilityRequest {
    driverunavailabilityId: number;
    body?: DriverUnavailabilityModel;
}

/**
 * 
 */
export class DriverUnavailabilityApi extends runtime.BaseAPI {

    /**
     * Add a new DriverUnavailability
     * Add a new DriverUnavailability
     */
    async createDriverUnavailabilityRaw(requestParameters: CreateDriverUnavailabilityRequest): Promise<runtime.ApiResponse<ApiResponse4>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/driver-unavailability`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DriverUnavailabilityModelToJSON(requestParameters.body),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResponse4FromJSON(jsonValue));
    }

    /**
     * Add a new DriverUnavailability
     * Add a new DriverUnavailability
     */
    createDriverUnavailability = async(requestParameters: CreateDriverUnavailabilityRequest): Promise<ApiResponse4> => {
        const response = await this.createDriverUnavailabilityRaw(requestParameters);
        return await response.value();
    }

    /**
     * Delete a Metadata entry
     * Delete a DriverUnavailability entry
     */
    async deleteDriverUnavailabilityRaw(requestParameters: DeleteDriverUnavailabilityRequest): Promise<runtime.ApiResponse<ApiResponse2>> {
        if (requestParameters.driverunavailabilityId === null || requestParameters.driverunavailabilityId === undefined) {
            throw new runtime.RequiredError('driverunavailabilityId','Required parameter requestParameters.driverunavailabilityId was null or undefined when calling deleteDriverUnavailability.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/driver-unavailability/{driverunavailabilityId}`.replace(`{${"driverunavailabilityId"}}`, encodeURIComponent(String(requestParameters.driverunavailabilityId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResponse2FromJSON(jsonValue));
    }

    /**
     * Delete a Metadata entry
     * Delete a DriverUnavailability entry
     */
    deleteDriverUnavailability = async(requestParameters: DeleteDriverUnavailabilityRequest): Promise<ApiResponse2> => {
        const response = await this.deleteDriverUnavailabilityRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieve an DriverUnavailability
     * Retrieve a DriverUnavailability
     */
    async retrieveDriverUnavailabilityRaw(requestParameters: RetrieveDriverUnavailabilityRequest): Promise<runtime.ApiResponse<DriverUnavailabilityModel>> {
        if (requestParameters.driverunavailabilityId === null || requestParameters.driverunavailabilityId === undefined) {
            throw new runtime.RequiredError('driverunavailabilityId','Required parameter requestParameters.driverunavailabilityId was null or undefined when calling retrieveDriverUnavailability.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.includeObjectTypeName !== undefined) {
            queryParameters['include_object_type_name'] = requestParameters.includeObjectTypeName;
        }

        if (requestParameters.includeRecordInfo !== undefined) {
            queryParameters['include_record_info'] = requestParameters.includeRecordInfo;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/driver-unavailability/{driverunavailabilityId}`.replace(`{${"driverunavailabilityId"}}`, encodeURIComponent(String(requestParameters.driverunavailabilityId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DriverUnavailabilityModelFromJSON(jsonValue));
    }

    /**
     * Retrieve an DriverUnavailability
     * Retrieve a DriverUnavailability
     */
    retrieveDriverUnavailability = async(requestParameters: RetrieveDriverUnavailabilityRequest): Promise<DriverUnavailabilityModel> => {
        const response = await this.retrieveDriverUnavailabilityRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieve List of DriverUnavailability
     * Retrieve List of DriverUnavailability
     */
    async retrieveListDriverUnavailabilityRaw(requestParameters: RetrieveListDriverUnavailabilityRequest): Promise<runtime.ApiResponse<DriverUnavailabilityListResponse>> {
        if (requestParameters.arguments === null || requestParameters.arguments === undefined) {
            throw new runtime.RequiredError('arguments','Required parameter requestParameters.arguments was null or undefined when calling retrieveListDriverUnavailability.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/driver-unavailability`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: DriverUnavailabilityRetrieveListArgumentsToJSON(requestParameters.arguments),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DriverUnavailabilityListResponseFromJSON(jsonValue));
    }

    /**
     * Retrieve List of DriverUnavailability
     * Retrieve List of DriverUnavailability
     */
    retrieveListDriverUnavailability = async(requestParameters: RetrieveListDriverUnavailabilityRequest): Promise<DriverUnavailabilityListResponse> => {
        const response = await this.retrieveListDriverUnavailabilityRaw(requestParameters);
        return await response.value();
    }

    /**
     * Set (create or update) a driver unavailability. If id or links are set in the data, and a corresponding driver unavailability is found in Bumbal, an update will be performed.
     * Set (create or update) a driver unavailability
     */
    async setDriverUnavailabilityRaw(requestParameters: SetDriverUnavailabilityRequest): Promise<runtime.ApiResponse<ModelApiResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/driver-unavailability/set`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DriverUnavailabilityModelToJSON(requestParameters.body),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelApiResponseFromJSON(jsonValue));
    }

    /**
     * Set (create or update) a driver unavailability. If id or links are set in the data, and a corresponding driver unavailability is found in Bumbal, an update will be performed.
     * Set (create or update) a driver unavailability
     */
    setDriverUnavailability = async(requestParameters: SetDriverUnavailabilityRequest): Promise<ModelApiResponse> => {
        const response = await this.setDriverUnavailabilityRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update a specific DriverUnavailability object
     * Update a specific DriverUnavailability object
     */
    async updateDriverUnavailabilityRaw(requestParameters: UpdateDriverUnavailabilityRequest): Promise<runtime.ApiResponse<ApiResponse1>> {
        if (requestParameters.driverunavailabilityId === null || requestParameters.driverunavailabilityId === undefined) {
            throw new runtime.RequiredError('driverunavailabilityId','Required parameter requestParameters.driverunavailabilityId was null or undefined when calling updateDriverUnavailability.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/driver-unavailability/{driverunavailabilityId}`.replace(`{${"driverunavailabilityId"}}`, encodeURIComponent(String(requestParameters.driverunavailabilityId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: DriverUnavailabilityModelToJSON(requestParameters.body),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResponse1FromJSON(jsonValue));
    }

    /**
     * Update a specific DriverUnavailability object
     * Update a specific DriverUnavailability object
     */
    updateDriverUnavailability = async(requestParameters: UpdateDriverUnavailabilityRequest): Promise<ApiResponse1> => {
        const response = await this.updateDriverUnavailabilityRaw(requestParameters);
        return await response.value();
    }

}
