import { Box, Button, Typography, Link } from '@mui/material';
import React from 'react'
import { useTranslation } from 'react-i18next';
import { Chapter } from '../../../interface/CourseContent';
import { useLocation } from 'react-router-dom';

interface ChapterHeaderProps {
    title: string;
    chapter: Chapter
}

const ChapterHeader = ({ title, chapter }: ChapterHeaderProps) => {
    const { t } = useTranslation();
    const location = useLocation();

    const getOverviewPath = (): string => {
        const pathParts = location.pathname.split('/');
        return pathParts.slice(0, 2).join('/');
    }

    return (
        <Box
            height="175px"
            display="flex"
            position="relative"
            sx={{
                backgroundColor: '#181818',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
            <Box textAlign="center" paddingLeft="40px">
                <Typography variant="h4" color="white">{title}</Typography>
                <Typography variant="h5" color="white" fontWeight="300">{chapter.title}</Typography>
            </Box>
            <Button
                variant="contained"
                component={Link}
                href={getOverviewPath()}
                sx={{ position: 'absolute', right: 20, bottom: 8 }}
            >
                {t('subject.back_to_overview')}
            </Button>
        </Box>
    )
}

export default ChapterHeader;