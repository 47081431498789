/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal Client Api
 * Bumbal API documentation
 *
 * The version of the OpenAPI document: 2.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel } from '../runtime';
import {
    EquipmentFiltersModel,
    EquipmentFiltersModelFromJSON,
    EquipmentFiltersModelFromJSONTyped,
    EquipmentFiltersModelToJSON,
    EquipmentOptionsModel,
    EquipmentOptionsModelFromJSON,
    EquipmentOptionsModelFromJSONTyped,
    EquipmentOptionsModelToJSON,
} from './';


export interface EquipmentRetrieveListArgumentsInterface {
    options?: EquipmentOptionsModel;
    filters?: EquipmentFiltersModel;
    limit?: number;
    offset?: number;
    sorting_column?: EquipmentRetrieveListArgumentsSortingColumnEnum;
    sorting_direction?: EquipmentRetrieveListArgumentsSortingDirectionEnum;
    search_text?: string;
    as_list?: boolean;
    count_only?: boolean;
}

/**
 * 
 * @export
 * @interface EquipmentRetrieveListArguments
 */
export class EquipmentRetrieveListArguments extends BaseModel<EquipmentRetrieveListArgumentsInterface> {

    /**
     * 
     * @type {EquipmentOptionsModel}
     * @memberof EquipmentRetrieveListArguments
     */
    options?: EquipmentOptionsModel;
    /**
     * 
     * @type {EquipmentFiltersModel}
     * @memberof EquipmentRetrieveListArguments
     */
    filters?: EquipmentFiltersModel;
    /**
     * 
     * @type {number}
     * @memberof EquipmentRetrieveListArguments
     */
    limit?: number;
    /**
     * 
     * @type {number}
     * @memberof EquipmentRetrieveListArguments
     */
    offset?: number;
    /**
     * Sorting Column
     * @type {string}
     * @memberof EquipmentRetrieveListArguments
     */
    sorting_column?: EquipmentRetrieveListArgumentsSortingColumnEnum;
    /**
     * Sorting Direction
     * @type {string}
     * @memberof EquipmentRetrieveListArguments
     */
    sorting_direction?: EquipmentRetrieveListArgumentsSortingDirectionEnum;
    /**
     * 
     * @type {string}
     * @memberof EquipmentRetrieveListArguments
     */
    search_text?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EquipmentRetrieveListArguments
     */
    as_list?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EquipmentRetrieveListArguments
     */
    count_only?: boolean;

    constructor(data?: EquipmentRetrieveListArgumentsInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(value: EquipmentRetrieveListArgumentsInterface): { [property: string]: any } {
        return {};
    }

}

export function EquipmentRetrieveListArgumentsFromJSON(json: any): EquipmentRetrieveListArguments {
    return EquipmentRetrieveListArgumentsFromJSONTyped(json, false);
}

export function EquipmentRetrieveListArgumentsFromJSONTyped(json: any, ignoreDiscriminator: boolean): EquipmentRetrieveListArguments {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = EquipmentRetrieveListArguments;
    try {
        let localClass = require.context('../override', true)('./EquipmentRetrieveListArguments');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'options': !exists(json, 'options') ? undefined : EquipmentOptionsModelFromJSON(json['options']),
        'filters': !exists(json, 'filters') ? undefined : EquipmentFiltersModelFromJSON(json['filters']),
        'limit': !exists(json, 'limit') ? undefined : Number(json['limit']),
        'offset': !exists(json, 'offset') ? undefined : Number(json['offset']),
        'sorting_column': !exists(json, 'sorting_column') ? undefined : json['sorting_column'],
        'sorting_direction': !exists(json, 'sorting_direction') ? undefined : json['sorting_direction'],
        'search_text': !exists(json, 'search_text') ? undefined : json['search_text'],
        'as_list': !exists(json, 'as_list') ? undefined : json['as_list'],
        'count_only': !exists(json, 'count_only') ? undefined : json['count_only'],
    });
}

export function EquipmentRetrieveListArgumentsToJSON(value?: EquipmentRetrieveListArguments | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = EquipmentRetrieveListArguments;
    try {
        let localClass = require.context('../override', true)('./EquipmentRetrieveListArguments');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return {
        
        'options': EquipmentOptionsModelToJSON(value.options),
        'filters': EquipmentFiltersModelToJSON(value.filters),
        'limit': value.limit,
        'offset': value.offset,
        'sorting_column': value.sorting_column,
        'sorting_direction': value.sorting_direction,
        'search_text': value.search_text,
        'as_list': value.as_list,
        'count_only': value.count_only,
        ...createClass.toJson(value),
    };
}

/**
* @export
* @enum {string}
*/
export enum EquipmentRetrieveListArgumentsSortingColumnEnum {
    Name = 'name',
    RegistrationNr = 'registration_nr',
    Info = 'info'
}
/**
* @export
* @enum {string}
*/
export enum EquipmentRetrieveListArgumentsSortingDirectionEnum {
    Asc = 'asc',
    Desc = 'desc'
}


