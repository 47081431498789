/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal Client Api
 * Bumbal API documentation
 *
 * The version of the OpenAPI document: 2.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel } from '../runtime';

export interface ActivityTypeModelInterface {
    id?: ActivityTypeModelIdEnum;
    name?: ActivityTypeModelNameEnum;
}

/**
 * 
 * @export
 * @interface ActivityTypeModel
 */
export class ActivityTypeModel extends BaseModel<ActivityTypeModelInterface> {

    /**
     * Unique Activity type ID. 1 (:pickup), 2 (:dropoff), 3 (:route_start), 4 (:route_end), 5 (:car_start), 6 (:car_end), 9 (:driver_start), 10 (:driver_end), 11 (:stop), 13 (:pause), 14 (:gas_refill), 15 (:maintenance_planned), 16 (:maintenance_unplanned), 17 (:user_other), 18 (:unplanned_stop), 19 (:wait), 20 (:breakdown), 21 (:sanitary), 26 (:maintenance), 27 (:car_wash), 28 (:depot), 29 (:combi), 30 (:interior_wash), 31 (:other), 32 (:bundled)
     * @type {number}
     * @memberof ActivityTypeModel
     */
    id?: ActivityTypeModelIdEnum;
    /**
     * Activity Type Name. breakdown (:20), bundled (:32), car_end (:6), car_start (:5), car_wash (:27), combi (:29), depot (:28), driver_end (:10), driver_start (:9), dropoff (:2), gas_refill (:14), interior_wash (:30), maintenance (:26), maintenance_planned (:15), maintenance_unplanned (:16), other (:31), pause (:13), pickup (:1), route_end (:4), route_start (:3), sanitary (:21), stop (:11), unplanned_stop (:18), user_other (:17), wait (:19)
     * @type {string}
     * @memberof ActivityTypeModel
     */
    name?: ActivityTypeModelNameEnum;

    constructor(data?: ActivityTypeModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(value: ActivityTypeModelInterface): { [property: string]: any } {
        return {};
    }

}

export function ActivityTypeModelFromJSON(json: any): ActivityTypeModel {
    return ActivityTypeModelFromJSONTyped(json, false);
}

export function ActivityTypeModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ActivityTypeModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = ActivityTypeModel;
    try {
        let localClass = require.context('../override', true)('./ActivityTypeModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'id': !exists(json, 'id') ? undefined : Number(json['id']),
        'name': !exists(json, 'name') ? undefined : json['name'],
    });
}

export function ActivityTypeModelToJSON(value?: ActivityTypeModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = ActivityTypeModel;
    try {
        let localClass = require.context('../override', true)('./ActivityTypeModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return {
        
        'id': value.id,
        'name': value.name,
        ...createClass.toJson(value),
    };
}

/**
* @export
* @enum {string}
*/
export enum ActivityTypeModelIdEnum {
    NUMBER_1 = 1,
    NUMBER_2 = 2,
    NUMBER_3 = 3,
    NUMBER_4 = 4,
    NUMBER_5 = 5,
    NUMBER_6 = 6,
    NUMBER_9 = 9,
    NUMBER_10 = 10,
    NUMBER_11 = 11,
    NUMBER_13 = 13,
    NUMBER_14 = 14,
    NUMBER_15 = 15,
    NUMBER_16 = 16,
    NUMBER_17 = 17,
    NUMBER_18 = 18,
    NUMBER_19 = 19,
    NUMBER_20 = 20,
    NUMBER_21 = 21,
    NUMBER_26 = 26,
    NUMBER_27 = 27,
    NUMBER_28 = 28,
    NUMBER_29 = 29,
    NUMBER_30 = 30,
    NUMBER_31 = 31,
    NUMBER_32 = 32
}
/**
* @export
* @enum {string}
*/
export enum ActivityTypeModelNameEnum {
    Breakdown = 'breakdown',
    Bundled = 'bundled',
    CarEnd = 'car_end',
    CarStart = 'car_start',
    CarWash = 'car_wash',
    Combi = 'combi',
    Depot = 'depot',
    DriverEnd = 'driver_end',
    DriverStart = 'driver_start',
    Dropoff = 'dropoff',
    GasRefill = 'gas_refill',
    InteriorWash = 'interior_wash',
    Maintenance = 'maintenance',
    MaintenancePlanned = 'maintenance_planned',
    MaintenanceUnplanned = 'maintenance_unplanned',
    Other = 'other',
    Pause = 'pause',
    Pickup = 'pickup',
    RouteEnd = 'route_end',
    RouteStart = 'route_start',
    Sanitary = 'sanitary',
    Stop = 'stop',
    UnplannedStop = 'unplanned_stop',
    UserOther = 'user_other',
    Wait = 'wait'
}


