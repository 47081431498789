/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal Client Api
 * Bumbal API documentation
 *
 * The version of the OpenAPI document: 2.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ModelApiResponse,
    ModelApiResponseFromJSON,
    ModelApiResponseToJSON,
    ServiceWindowsSchemeListResponse,
    ServiceWindowsSchemeListResponseFromJSON,
    ServiceWindowsSchemeListResponseToJSON,
    ServiceWindowsSchemeModel,
    ServiceWindowsSchemeModelFromJSON,
    ServiceWindowsSchemeModelToJSON,
    ServiceWindowsSchemeRetrieveListArguments,
    ServiceWindowsSchemeRetrieveListArgumentsFromJSON,
    ServiceWindowsSchemeRetrieveListArgumentsToJSON,
} from '../models';

export interface DeleteServiceWindowsSchemeRequest {
    serviceWindowsSchemeId: number;
}

export interface RetrieveListServiceWindowsSchemeRequest {
    arguments: ServiceWindowsSchemeRetrieveListArguments;
}

export interface RetrieveServiceWindowsSchemeRequest {
    serviceWindowsSchemeId: number;
}

export interface SetServiceWindowsSchemeRequest {
    body?: ServiceWindowsSchemeModel;
}

/**
 * 
 */
export class ServiceWindowsSchemeApi extends runtime.BaseAPI {

    /**
     * Delete a service windows scheme
     * Delete a service windows scheme
     */
    async deleteServiceWindowsSchemeRaw(requestParameters: DeleteServiceWindowsSchemeRequest): Promise<runtime.ApiResponse<ModelApiResponse>> {
        if (requestParameters.serviceWindowsSchemeId === null || requestParameters.serviceWindowsSchemeId === undefined) {
            throw new runtime.RequiredError('serviceWindowsSchemeId','Required parameter requestParameters.serviceWindowsSchemeId was null or undefined when calling deleteServiceWindowsScheme.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/service-windows-scheme/{serviceWindowsSchemeId}`.replace(`{${"serviceWindowsSchemeId"}}`, encodeURIComponent(String(requestParameters.serviceWindowsSchemeId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelApiResponseFromJSON(jsonValue));
    }

    /**
     * Delete a service windows scheme
     * Delete a service windows scheme
     */
    deleteServiceWindowsScheme = async(requestParameters: DeleteServiceWindowsSchemeRequest): Promise<ModelApiResponse> => {
        const response = await this.deleteServiceWindowsSchemeRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieve a list of service windows schemes
     * Retrieve a list of service windows schemes
     */
    async retrieveListServiceWindowsSchemeRaw(requestParameters: RetrieveListServiceWindowsSchemeRequest): Promise<runtime.ApiResponse<ServiceWindowsSchemeListResponse>> {
        if (requestParameters.arguments === null || requestParameters.arguments === undefined) {
            throw new runtime.RequiredError('arguments','Required parameter requestParameters.arguments was null or undefined when calling retrieveListServiceWindowsScheme.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/service-windows-scheme`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ServiceWindowsSchemeRetrieveListArgumentsToJSON(requestParameters.arguments),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ServiceWindowsSchemeListResponseFromJSON(jsonValue));
    }

    /**
     * Retrieve a list of service windows schemes
     * Retrieve a list of service windows schemes
     */
    retrieveListServiceWindowsScheme = async(requestParameters: RetrieveListServiceWindowsSchemeRequest): Promise<ServiceWindowsSchemeListResponse> => {
        const response = await this.retrieveListServiceWindowsSchemeRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieve a single service windows scheme
     * Retrieve a single service windows scheme
     */
    async retrieveServiceWindowsSchemeRaw(requestParameters: RetrieveServiceWindowsSchemeRequest): Promise<runtime.ApiResponse<ServiceWindowsSchemeModel>> {
        if (requestParameters.serviceWindowsSchemeId === null || requestParameters.serviceWindowsSchemeId === undefined) {
            throw new runtime.RequiredError('serviceWindowsSchemeId','Required parameter requestParameters.serviceWindowsSchemeId was null or undefined when calling retrieveServiceWindowsScheme.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/service-windows-scheme/{serviceWindowsSchemeId}`.replace(`{${"serviceWindowsSchemeId"}}`, encodeURIComponent(String(requestParameters.serviceWindowsSchemeId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ServiceWindowsSchemeModelFromJSON(jsonValue));
    }

    /**
     * Retrieve a single service windows scheme
     * Retrieve a single service windows scheme
     */
    retrieveServiceWindowsScheme = async(requestParameters: RetrieveServiceWindowsSchemeRequest): Promise<ServiceWindowsSchemeModel> => {
        const response = await this.retrieveServiceWindowsSchemeRaw(requestParameters);
        return await response.value();
    }

    /**
     * Add or update a service windows scheme
     * Add or update a service windows scheme
     */
    async setServiceWindowsSchemeRaw(requestParameters: SetServiceWindowsSchemeRequest): Promise<runtime.ApiResponse<ModelApiResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/service-windows-scheme/set`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ServiceWindowsSchemeModelToJSON(requestParameters.body),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelApiResponseFromJSON(jsonValue));
    }

    /**
     * Add or update a service windows scheme
     * Add or update a service windows scheme
     */
    setServiceWindowsScheme = async(requestParameters: SetServiceWindowsSchemeRequest): Promise<ModelApiResponse> => {
        const response = await this.setServiceWindowsSchemeRaw(requestParameters);
        return await response.value();
    }

}
