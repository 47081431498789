import React from 'react';
import { Box, LinearProgress, LinearProgressProps } from '@mui/material';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';

interface ComponentProps {
    value: number;
    barColor: string;
    startIcon?: React.ReactNode;
    endIcon?: React.ReactNode;
}

type ProgressBarProps = LinearProgressProps & ComponentProps;

const ProgressBar = ({ barColor, startIcon, endIcon, ...props }: ProgressBarProps) => {
    return (
        <Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {startIcon &&
                    <Box
                        sx={{
                            width: '25px',
                            height: '25px',
                            backgroundColor: 'secondary.main',
                            borderRadius: '50%',
                            verticalAlign: 'middle',
                            display: 'table-cell'
                        }}>
                        {startIcon}
                    </Box>}
                <Box sx={{ width: '100%', position: 'relative' }}>
                    <LinearProgress variant="determinate" color="secondary" {...props} sx={{ height: '4px', backgroundColor: barColor }}></LinearProgress>
                    {props.value > 2 &&
                        <div style={{ position: 'absolute', left: -25, bottom: -15, transform: `translateX(${props.value}%)`, width: '100%', zIndex: 3 }}>
                            <LocalShippingIcon sx={{ fontSize: '1.75rem' }} />
                        </div>}
                </Box>
                {endIcon &&
                    <Box
                        sx={{
                            width: '25px',
                            height: '25px',
                            backgroundColor: 'primary.main',
                            borderRadius: '50%',
                            verticalAlign: 'middle',
                            display: 'table-cell'
                        }}>
                        {endIcon}
                    </Box>}
            </Box>
        </Box>
    )
}

export default ProgressBar;