/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal Client Api
 * Bumbal API documentation
 *
 * The version of the OpenAPI document: 2.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel } from '../runtime';
import {
    PortalSettingFiltersModel,
    PortalSettingFiltersModelFromJSON,
    PortalSettingFiltersModelFromJSONTyped,
    PortalSettingFiltersModelToJSON,
    PortalSettingOptionsModel,
    PortalSettingOptionsModelFromJSON,
    PortalSettingOptionsModelFromJSONTyped,
    PortalSettingOptionsModelToJSON,
} from './';


export interface PortalSettingRetrieveListArgumentsInterface {
    options?: PortalSettingOptionsModel;
    filters?: PortalSettingFiltersModel;
    limit?: number;
    offset?: number;
    sorting_column?: PortalSettingRetrieveListArgumentsSortingColumnEnum;
    sorting_direction?: PortalSettingRetrieveListArgumentsSortingDirectionEnum;
    search_text?: string;
    as_list?: boolean;
}

/**
 * 
 * @export
 * @interface PortalSettingRetrieveListArguments
 */
export class PortalSettingRetrieveListArguments extends BaseModel<PortalSettingRetrieveListArgumentsInterface> {

    /**
     * 
     * @type {PortalSettingOptionsModel}
     * @memberof PortalSettingRetrieveListArguments
     */
    options?: PortalSettingOptionsModel;
    /**
     * 
     * @type {PortalSettingFiltersModel}
     * @memberof PortalSettingRetrieveListArguments
     */
    filters?: PortalSettingFiltersModel;
    /**
     * 
     * @type {number}
     * @memberof PortalSettingRetrieveListArguments
     */
    limit?: number;
    /**
     * 
     * @type {number}
     * @memberof PortalSettingRetrieveListArguments
     */
    offset?: number;
    /**
     * Sorting Column
     * @type {string}
     * @memberof PortalSettingRetrieveListArguments
     */
    sorting_column?: PortalSettingRetrieveListArgumentsSortingColumnEnum;
    /**
     * Sorting Direction
     * @type {string}
     * @memberof PortalSettingRetrieveListArguments
     */
    sorting_direction?: PortalSettingRetrieveListArgumentsSortingDirectionEnum;
    /**
     * 
     * @type {string}
     * @memberof PortalSettingRetrieveListArguments
     */
    search_text?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PortalSettingRetrieveListArguments
     */
    as_list?: boolean;

    constructor(data?: PortalSettingRetrieveListArgumentsInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(value: PortalSettingRetrieveListArgumentsInterface): { [property: string]: any } {
        return {};
    }

}

export function PortalSettingRetrieveListArgumentsFromJSON(json: any): PortalSettingRetrieveListArguments {
    return PortalSettingRetrieveListArgumentsFromJSONTyped(json, false);
}

export function PortalSettingRetrieveListArgumentsFromJSONTyped(json: any, ignoreDiscriminator: boolean): PortalSettingRetrieveListArguments {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = PortalSettingRetrieveListArguments;
    try {
        let localClass = require.context('../override', true)('./PortalSettingRetrieveListArguments');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'options': !exists(json, 'options') ? undefined : PortalSettingOptionsModelFromJSON(json['options']),
        'filters': !exists(json, 'filters') ? undefined : PortalSettingFiltersModelFromJSON(json['filters']),
        'limit': !exists(json, 'limit') ? undefined : Number(json['limit']),
        'offset': !exists(json, 'offset') ? undefined : Number(json['offset']),
        'sorting_column': !exists(json, 'sorting_column') ? undefined : json['sorting_column'],
        'sorting_direction': !exists(json, 'sorting_direction') ? undefined : json['sorting_direction'],
        'search_text': !exists(json, 'search_text') ? undefined : json['search_text'],
        'as_list': !exists(json, 'as_list') ? undefined : json['as_list'],
    });
}

export function PortalSettingRetrieveListArgumentsToJSON(value?: PortalSettingRetrieveListArguments | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = PortalSettingRetrieveListArguments;
    try {
        let localClass = require.context('../override', true)('./PortalSettingRetrieveListArguments');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return {
        
        'options': PortalSettingOptionsModelToJSON(value.options),
        'filters': PortalSettingFiltersModelToJSON(value.filters),
        'limit': value.limit,
        'offset': value.offset,
        'sorting_column': value.sorting_column,
        'sorting_direction': value.sorting_direction,
        'search_text': value.search_text,
        'as_list': value.as_list,
        ...createClass.toJson(value),
    };
}

/**
* @export
* @enum {string}
*/
export enum PortalSettingRetrieveListArgumentsSortingColumnEnum {
    Id = 'id',
    Name = 'name',
    Description = 'description',
    Subject = 'subject'
}
/**
* @export
* @enum {string}
*/
export enum PortalSettingRetrieveListArgumentsSortingDirectionEnum {
    ASC = 'ASC',
    DESC = 'DESC'
}


