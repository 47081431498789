/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal Client Api
 * Bumbal API documentation
 *
 * The version of the OpenAPI document: 2.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ActivityFiltersModel,
    ActivityFiltersModelFromJSON,
    ActivityFiltersModelToJSON,
    ActivityListResponse,
    ActivityListResponseFromJSON,
    ActivityListResponseToJSON,
    ActivityModel,
    ActivityModelFromJSON,
    ActivityModelToJSON,
    ActivityRetrieveListArguments,
    ActivityRetrieveListArgumentsFromJSON,
    ActivityRetrieveListArgumentsToJSON,
    ModelApiResponse,
    ModelApiResponseFromJSON,
    ModelApiResponseToJSON,
    UnsuccessfulModel,
    UnsuccessfulModelFromJSON,
    UnsuccessfulModelToJSON,
    UnsuccessfulResponseModel,
    UnsuccessfulResponseModelFromJSON,
    UnsuccessfulResponseModelToJSON,
} from '../models';

export interface DeleteActivityRequest {
    activityId: number;
}

export interface LockActivityRequest {
    filters: ActivityFiltersModel;
}

export interface LockActivityOnRouteRequest {
    filters: ActivityFiltersModel;
}

export interface LockActivityOnRouteAndTimeRequest {
    filters: ActivityFiltersModel;
}

export interface RetrieveActivityRequest {
    activityId: number;
    includeActivityStatus: boolean;
    includeActivityTypeName: boolean;
    includeActivityMetaData: boolean;
    includeAddressObject: boolean;
    includeTimeSlots: boolean;
    includeRouteInfo: boolean;
    includeRoute: boolean;
    includePackageLines: boolean;
    includePackageLinesInfo: boolean;
    includeDriverInfo: boolean;
    includeCommunication: boolean;
    includeCommunicationObject: boolean;
    includeActivityLinks: boolean;
    includeActivityFiles: boolean;
    includeActivityFilesMetaData: boolean;
    includeAssignmentNr: boolean;
    includeAssignment: boolean;
    includeActivityTags: boolean;
    includeTagTypeName: boolean;
    includeActivityRecordInfo: boolean;
    includeActivityNotes: boolean;
    includeActivityNoteTags: boolean;
    includeDepotAddressObject: boolean;
    includeCapacityObject: boolean;
    includeZones: boolean;
    includeBrand: boolean;
    includeBrandColours: boolean;
    includeBrandFiles: boolean;
    includeRelations: boolean;
}

export interface RetrieveListActivityRequest {
    arguments: ActivityRetrieveListArguments;
}

export interface SetActivityRequest {
    body?: ActivityModel;
}

export interface UnlockActivityRequest {
    filters: ActivityFiltersModel;
}

export interface UnsuccessfulRequest {
    arguments: UnsuccessfulModel;
}

export interface UpdateActivityRequest {
    activityId: number;
    body?: ActivityModel;
}

/**
 * 
 */
export class ActivityApi extends runtime.BaseAPI {

    /**
     * Delete an activity
     * Delete an activity
     */
    async deleteActivityRaw(requestParameters: DeleteActivityRequest): Promise<runtime.ApiResponse<ModelApiResponse>> {
        if (requestParameters.activityId === null || requestParameters.activityId === undefined) {
            throw new runtime.RequiredError('activityId','Required parameter requestParameters.activityId was null or undefined when calling deleteActivity.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/activity/{activityId}`.replace(`{${"activityId"}}`, encodeURIComponent(String(requestParameters.activityId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelApiResponseFromJSON(jsonValue));
    }

    /**
     * Delete an activity
     * Delete an activity
     */
    deleteActivity = async(requestParameters: DeleteActivityRequest): Promise<ModelApiResponse> => {
        const response = await this.deleteActivityRaw(requestParameters);
        return await response.value();
    }

    /**
     * Lock activities which satisfy set filters
     * Lock activities which satisfy set filters
     */
    async lockActivityRaw(requestParameters: LockActivityRequest): Promise<runtime.ApiResponse<ModelApiResponse>> {
        if (requestParameters.filters === null || requestParameters.filters === undefined) {
            throw new runtime.RequiredError('filters','Required parameter requestParameters.filters was null or undefined when calling lockActivity.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/activity/lock`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ActivityFiltersModelToJSON(requestParameters.filters),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelApiResponseFromJSON(jsonValue));
    }

    /**
     * Lock activities which satisfy set filters
     * Lock activities which satisfy set filters
     */
    lockActivity = async(requestParameters: LockActivityRequest): Promise<ModelApiResponse> => {
        const response = await this.lockActivityRaw(requestParameters);
        return await response.value();
    }

    /**
     * Lock activities on route which satisfy set filters
     * Lock activities on route which satisfy set filters
     */
    async lockActivityOnRouteRaw(requestParameters: LockActivityOnRouteRequest): Promise<runtime.ApiResponse<ModelApiResponse>> {
        if (requestParameters.filters === null || requestParameters.filters === undefined) {
            throw new runtime.RequiredError('filters','Required parameter requestParameters.filters was null or undefined when calling lockActivityOnRoute.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/activity/lock-on-route`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ActivityFiltersModelToJSON(requestParameters.filters),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelApiResponseFromJSON(jsonValue));
    }

    /**
     * Lock activities on route which satisfy set filters
     * Lock activities on route which satisfy set filters
     */
    lockActivityOnRoute = async(requestParameters: LockActivityOnRouteRequest): Promise<ModelApiResponse> => {
        const response = await this.lockActivityOnRouteRaw(requestParameters);
        return await response.value();
    }

    /**
     * Lock activities on route and time which satisfy set filters
     * Lock activities on route and time which satisfy set filters
     */
    async lockActivityOnRouteAndTimeRaw(requestParameters: LockActivityOnRouteAndTimeRequest): Promise<runtime.ApiResponse<ModelApiResponse>> {
        if (requestParameters.filters === null || requestParameters.filters === undefined) {
            throw new runtime.RequiredError('filters','Required parameter requestParameters.filters was null or undefined when calling lockActivityOnRouteAndTime.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/activity/lock-on-route-and-time`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ActivityFiltersModelToJSON(requestParameters.filters),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelApiResponseFromJSON(jsonValue));
    }

    /**
     * Lock activities on route and time which satisfy set filters
     * Lock activities on route and time which satisfy set filters
     */
    lockActivityOnRouteAndTime = async(requestParameters: LockActivityOnRouteAndTimeRequest): Promise<ModelApiResponse> => {
        const response = await this.lockActivityOnRouteAndTimeRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns a single activity
     * Find activity by ID
     */
    async retrieveActivityRaw(requestParameters: RetrieveActivityRequest): Promise<runtime.ApiResponse<ActivityModel>> {
        if (requestParameters.activityId === null || requestParameters.activityId === undefined) {
            throw new runtime.RequiredError('activityId','Required parameter requestParameters.activityId was null or undefined when calling retrieveActivity.');
        }

        if (requestParameters.includeActivityStatus === null || requestParameters.includeActivityStatus === undefined) {
            throw new runtime.RequiredError('includeActivityStatus','Required parameter requestParameters.includeActivityStatus was null or undefined when calling retrieveActivity.');
        }

        if (requestParameters.includeActivityTypeName === null || requestParameters.includeActivityTypeName === undefined) {
            throw new runtime.RequiredError('includeActivityTypeName','Required parameter requestParameters.includeActivityTypeName was null or undefined when calling retrieveActivity.');
        }

        if (requestParameters.includeActivityMetaData === null || requestParameters.includeActivityMetaData === undefined) {
            throw new runtime.RequiredError('includeActivityMetaData','Required parameter requestParameters.includeActivityMetaData was null or undefined when calling retrieveActivity.');
        }

        if (requestParameters.includeAddressObject === null || requestParameters.includeAddressObject === undefined) {
            throw new runtime.RequiredError('includeAddressObject','Required parameter requestParameters.includeAddressObject was null or undefined when calling retrieveActivity.');
        }

        if (requestParameters.includeTimeSlots === null || requestParameters.includeTimeSlots === undefined) {
            throw new runtime.RequiredError('includeTimeSlots','Required parameter requestParameters.includeTimeSlots was null or undefined when calling retrieveActivity.');
        }

        if (requestParameters.includeRouteInfo === null || requestParameters.includeRouteInfo === undefined) {
            throw new runtime.RequiredError('includeRouteInfo','Required parameter requestParameters.includeRouteInfo was null or undefined when calling retrieveActivity.');
        }

        if (requestParameters.includeRoute === null || requestParameters.includeRoute === undefined) {
            throw new runtime.RequiredError('includeRoute','Required parameter requestParameters.includeRoute was null or undefined when calling retrieveActivity.');
        }

        if (requestParameters.includePackageLines === null || requestParameters.includePackageLines === undefined) {
            throw new runtime.RequiredError('includePackageLines','Required parameter requestParameters.includePackageLines was null or undefined when calling retrieveActivity.');
        }

        if (requestParameters.includePackageLinesInfo === null || requestParameters.includePackageLinesInfo === undefined) {
            throw new runtime.RequiredError('includePackageLinesInfo','Required parameter requestParameters.includePackageLinesInfo was null or undefined when calling retrieveActivity.');
        }

        if (requestParameters.includeDriverInfo === null || requestParameters.includeDriverInfo === undefined) {
            throw new runtime.RequiredError('includeDriverInfo','Required parameter requestParameters.includeDriverInfo was null or undefined when calling retrieveActivity.');
        }

        if (requestParameters.includeCommunication === null || requestParameters.includeCommunication === undefined) {
            throw new runtime.RequiredError('includeCommunication','Required parameter requestParameters.includeCommunication was null or undefined when calling retrieveActivity.');
        }

        if (requestParameters.includeCommunicationObject === null || requestParameters.includeCommunicationObject === undefined) {
            throw new runtime.RequiredError('includeCommunicationObject','Required parameter requestParameters.includeCommunicationObject was null or undefined when calling retrieveActivity.');
        }

        if (requestParameters.includeActivityLinks === null || requestParameters.includeActivityLinks === undefined) {
            throw new runtime.RequiredError('includeActivityLinks','Required parameter requestParameters.includeActivityLinks was null or undefined when calling retrieveActivity.');
        }

        if (requestParameters.includeActivityFiles === null || requestParameters.includeActivityFiles === undefined) {
            throw new runtime.RequiredError('includeActivityFiles','Required parameter requestParameters.includeActivityFiles was null or undefined when calling retrieveActivity.');
        }

        if (requestParameters.includeActivityFilesMetaData === null || requestParameters.includeActivityFilesMetaData === undefined) {
            throw new runtime.RequiredError('includeActivityFilesMetaData','Required parameter requestParameters.includeActivityFilesMetaData was null or undefined when calling retrieveActivity.');
        }

        if (requestParameters.includeAssignmentNr === null || requestParameters.includeAssignmentNr === undefined) {
            throw new runtime.RequiredError('includeAssignmentNr','Required parameter requestParameters.includeAssignmentNr was null or undefined when calling retrieveActivity.');
        }

        if (requestParameters.includeAssignment === null || requestParameters.includeAssignment === undefined) {
            throw new runtime.RequiredError('includeAssignment','Required parameter requestParameters.includeAssignment was null or undefined when calling retrieveActivity.');
        }

        if (requestParameters.includeActivityTags === null || requestParameters.includeActivityTags === undefined) {
            throw new runtime.RequiredError('includeActivityTags','Required parameter requestParameters.includeActivityTags was null or undefined when calling retrieveActivity.');
        }

        if (requestParameters.includeTagTypeName === null || requestParameters.includeTagTypeName === undefined) {
            throw new runtime.RequiredError('includeTagTypeName','Required parameter requestParameters.includeTagTypeName was null or undefined when calling retrieveActivity.');
        }

        if (requestParameters.includeActivityRecordInfo === null || requestParameters.includeActivityRecordInfo === undefined) {
            throw new runtime.RequiredError('includeActivityRecordInfo','Required parameter requestParameters.includeActivityRecordInfo was null or undefined when calling retrieveActivity.');
        }

        if (requestParameters.includeActivityNotes === null || requestParameters.includeActivityNotes === undefined) {
            throw new runtime.RequiredError('includeActivityNotes','Required parameter requestParameters.includeActivityNotes was null or undefined when calling retrieveActivity.');
        }

        if (requestParameters.includeActivityNoteTags === null || requestParameters.includeActivityNoteTags === undefined) {
            throw new runtime.RequiredError('includeActivityNoteTags','Required parameter requestParameters.includeActivityNoteTags was null or undefined when calling retrieveActivity.');
        }

        if (requestParameters.includeDepotAddressObject === null || requestParameters.includeDepotAddressObject === undefined) {
            throw new runtime.RequiredError('includeDepotAddressObject','Required parameter requestParameters.includeDepotAddressObject was null or undefined when calling retrieveActivity.');
        }

        if (requestParameters.includeCapacityObject === null || requestParameters.includeCapacityObject === undefined) {
            throw new runtime.RequiredError('includeCapacityObject','Required parameter requestParameters.includeCapacityObject was null or undefined when calling retrieveActivity.');
        }

        if (requestParameters.includeZones === null || requestParameters.includeZones === undefined) {
            throw new runtime.RequiredError('includeZones','Required parameter requestParameters.includeZones was null or undefined when calling retrieveActivity.');
        }

        if (requestParameters.includeBrand === null || requestParameters.includeBrand === undefined) {
            throw new runtime.RequiredError('includeBrand','Required parameter requestParameters.includeBrand was null or undefined when calling retrieveActivity.');
        }

        if (requestParameters.includeBrandColours === null || requestParameters.includeBrandColours === undefined) {
            throw new runtime.RequiredError('includeBrandColours','Required parameter requestParameters.includeBrandColours was null or undefined when calling retrieveActivity.');
        }

        if (requestParameters.includeBrandFiles === null || requestParameters.includeBrandFiles === undefined) {
            throw new runtime.RequiredError('includeBrandFiles','Required parameter requestParameters.includeBrandFiles was null or undefined when calling retrieveActivity.');
        }

        if (requestParameters.includeRelations === null || requestParameters.includeRelations === undefined) {
            throw new runtime.RequiredError('includeRelations','Required parameter requestParameters.includeRelations was null or undefined when calling retrieveActivity.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.includeActivityStatus !== undefined) {
            queryParameters['include_activity_status'] = requestParameters.includeActivityStatus;
        }

        if (requestParameters.includeActivityTypeName !== undefined) {
            queryParameters['include_activity_type_name'] = requestParameters.includeActivityTypeName;
        }

        if (requestParameters.includeActivityMetaData !== undefined) {
            queryParameters['include_activity_meta_data'] = requestParameters.includeActivityMetaData;
        }

        if (requestParameters.includeAddressObject !== undefined) {
            queryParameters['include_address_object'] = requestParameters.includeAddressObject;
        }

        if (requestParameters.includeTimeSlots !== undefined) {
            queryParameters['include_time_slots'] = requestParameters.includeTimeSlots;
        }

        if (requestParameters.includeRouteInfo !== undefined) {
            queryParameters['include_route_info'] = requestParameters.includeRouteInfo;
        }

        if (requestParameters.includeRoute !== undefined) {
            queryParameters['include_route'] = requestParameters.includeRoute;
        }

        if (requestParameters.includePackageLines !== undefined) {
            queryParameters['include_package_lines'] = requestParameters.includePackageLines;
        }

        if (requestParameters.includePackageLinesInfo !== undefined) {
            queryParameters['include_package_lines_info'] = requestParameters.includePackageLinesInfo;
        }

        if (requestParameters.includeDriverInfo !== undefined) {
            queryParameters['include_driver_info'] = requestParameters.includeDriverInfo;
        }

        if (requestParameters.includeCommunication !== undefined) {
            queryParameters['include_communication'] = requestParameters.includeCommunication;
        }

        if (requestParameters.includeCommunicationObject !== undefined) {
            queryParameters['include_communication_object'] = requestParameters.includeCommunicationObject;
        }

        if (requestParameters.includeActivityLinks !== undefined) {
            queryParameters['include_activity_links'] = requestParameters.includeActivityLinks;
        }

        if (requestParameters.includeActivityFiles !== undefined) {
            queryParameters['include_activity_files'] = requestParameters.includeActivityFiles;
        }

        if (requestParameters.includeActivityFilesMetaData !== undefined) {
            queryParameters['include_activity_files_meta_data'] = requestParameters.includeActivityFilesMetaData;
        }

        if (requestParameters.includeAssignmentNr !== undefined) {
            queryParameters['include_assignment_nr'] = requestParameters.includeAssignmentNr;
        }

        if (requestParameters.includeAssignment !== undefined) {
            queryParameters['include_assignment'] = requestParameters.includeAssignment;
        }

        if (requestParameters.includeActivityTags !== undefined) {
            queryParameters['include_activity_tags'] = requestParameters.includeActivityTags;
        }

        if (requestParameters.includeTagTypeName !== undefined) {
            queryParameters['include_tag_type_name'] = requestParameters.includeTagTypeName;
        }

        if (requestParameters.includeActivityRecordInfo !== undefined) {
            queryParameters['include_activity_record_info'] = requestParameters.includeActivityRecordInfo;
        }

        if (requestParameters.includeActivityNotes !== undefined) {
            queryParameters['include_activity_notes'] = requestParameters.includeActivityNotes;
        }

        if (requestParameters.includeActivityNoteTags !== undefined) {
            queryParameters['include_activity_note_tags'] = requestParameters.includeActivityNoteTags;
        }

        if (requestParameters.includeDepotAddressObject !== undefined) {
            queryParameters['include_depot_address_object'] = requestParameters.includeDepotAddressObject;
        }

        if (requestParameters.includeCapacityObject !== undefined) {
            queryParameters['include_capacity_object'] = requestParameters.includeCapacityObject;
        }

        if (requestParameters.includeZones !== undefined) {
            queryParameters['include_zones'] = requestParameters.includeZones;
        }

        if (requestParameters.includeBrand !== undefined) {
            queryParameters['include_brand'] = requestParameters.includeBrand;
        }

        if (requestParameters.includeBrandColours !== undefined) {
            queryParameters['include_brand_colours'] = requestParameters.includeBrandColours;
        }

        if (requestParameters.includeBrandFiles !== undefined) {
            queryParameters['include_brand_files'] = requestParameters.includeBrandFiles;
        }

        if (requestParameters.includeRelations !== undefined) {
            queryParameters['include_relations'] = requestParameters.includeRelations;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/activity/{activityId}`.replace(`{${"activityId"}}`, encodeURIComponent(String(requestParameters.activityId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ActivityModelFromJSON(jsonValue));
    }

    /**
     * Returns a single activity
     * Find activity by ID
     */
    retrieveActivity = async(requestParameters: RetrieveActivityRequest): Promise<ActivityModel> => {
        const response = await this.retrieveActivityRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieve List of Activities
     * Retrieve List of Activities
     */
    async retrieveListActivityRaw(requestParameters: RetrieveListActivityRequest): Promise<runtime.ApiResponse<ActivityListResponse>> {
        if (requestParameters.arguments === null || requestParameters.arguments === undefined) {
            throw new runtime.RequiredError('arguments','Required parameter requestParameters.arguments was null or undefined when calling retrieveListActivity.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/activity`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ActivityRetrieveListArgumentsToJSON(requestParameters.arguments),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ActivityListResponseFromJSON(jsonValue));
    }

    /**
     * Retrieve List of Activities
     * Retrieve List of Activities
     */
    retrieveListActivity = async(requestParameters: RetrieveListActivityRequest): Promise<ActivityListResponse> => {
        const response = await this.retrieveListActivityRaw(requestParameters);
        return await response.value();
    }

    /**
     * Set (create or update) an Activity. If id or links are set in the data, and a corresponding activity is found in Bumbal, an update will be performed.
     * Set (create or update) an Activity
     */
    async setActivityRaw(requestParameters: SetActivityRequest): Promise<runtime.ApiResponse<ModelApiResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/activity/set`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ActivityModelToJSON(requestParameters.body),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelApiResponseFromJSON(jsonValue));
    }

    /**
     * Set (create or update) an Activity. If id or links are set in the data, and a corresponding activity is found in Bumbal, an update will be performed.
     * Set (create or update) an Activity
     */
    setActivity = async(requestParameters: SetActivityRequest): Promise<ModelApiResponse> => {
        const response = await this.setActivityRaw(requestParameters);
        return await response.value();
    }

    /**
     * Unlock activities which satisfy set filters
     * Unlock activities which satisfy set filters
     */
    async unlockActivityRaw(requestParameters: UnlockActivityRequest): Promise<runtime.ApiResponse<ModelApiResponse>> {
        if (requestParameters.filters === null || requestParameters.filters === undefined) {
            throw new runtime.RequiredError('filters','Required parameter requestParameters.filters was null or undefined when calling unlockActivity.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/activity/unlock`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ActivityFiltersModelToJSON(requestParameters.filters),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelApiResponseFromJSON(jsonValue));
    }

    /**
     * Unlock activities which satisfy set filters
     * Unlock activities which satisfy set filters
     */
    unlockActivity = async(requestParameters: UnlockActivityRequest): Promise<ModelApiResponse> => {
        const response = await this.unlockActivityRaw(requestParameters);
        return await response.value();
    }

    /**
     * Report an unsuccessful activity
     * Report an unsuccessful activity
     */
    async unsuccessfulRaw(requestParameters: UnsuccessfulRequest): Promise<runtime.ApiResponse<UnsuccessfulResponseModel>> {
        if (requestParameters.arguments === null || requestParameters.arguments === undefined) {
            throw new runtime.RequiredError('arguments','Required parameter requestParameters.arguments was null or undefined when calling unsuccessful.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/activity/unsuccessful`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UnsuccessfulModelToJSON(requestParameters.arguments),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UnsuccessfulResponseModelFromJSON(jsonValue));
    }

    /**
     * Report an unsuccessful activity
     * Report an unsuccessful activity
     */
    unsuccessful = async(requestParameters: UnsuccessfulRequest): Promise<UnsuccessfulResponseModel> => {
        const response = await this.unsuccessfulRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update a activity
     * Update a activity
     */
    async updateActivityRaw(requestParameters: UpdateActivityRequest): Promise<runtime.ApiResponse<ModelApiResponse>> {
        if (requestParameters.activityId === null || requestParameters.activityId === undefined) {
            throw new runtime.RequiredError('activityId','Required parameter requestParameters.activityId was null or undefined when calling updateActivity.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/activity/{activityId}`.replace(`{${"activityId"}}`, encodeURIComponent(String(requestParameters.activityId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ActivityModelToJSON(requestParameters.body),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelApiResponseFromJSON(jsonValue));
    }

    /**
     * Update a activity
     * Update a activity
     */
    updateActivity = async(requestParameters: UpdateActivityRequest): Promise<ModelApiResponse> => {
        const response = await this.updateActivityRaw(requestParameters);
        return await response.value();
    }

}
