/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal Client Api
 * Bumbal API documentation
 *
 * The version of the OpenAPI document: 2.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel } from '../runtime';

export interface BrandColourModelInterface {
    id?: number;
    brand_id?: number;
    name?: string;
    hex?: string;
    r?: number;
    g?: number;
    b?: number;
    a?: number;
}

/**
 * 
 * @export
 * @interface BrandColourModel
 */
export class BrandColourModel extends BaseModel<BrandColourModelInterface> {

    /**
     * Unique Identifier
     * @type {number}
     * @memberof BrandColourModel
     */
    id?: number;
    /**
     * id for brand where this colour belongs to
     * @type {number}
     * @memberof BrandColourModel
     */
    brand_id?: number;
    /**
     * name
     * @type {string}
     * @memberof BrandColourModel
     */
    name?: string;
    /**
     * hex
     * @type {string}
     * @memberof BrandColourModel
     */
    hex?: string;
    /**
     * red value for rgba
     * @type {number}
     * @memberof BrandColourModel
     */
    r?: number;
    /**
     * green value for rgba
     * @type {number}
     * @memberof BrandColourModel
     */
    g?: number;
    /**
     * blue value for rgba
     * @type {number}
     * @memberof BrandColourModel
     */
    b?: number;
    /**
     * alpha value (opacity/transparency) for rgba
     * @type {number}
     * @memberof BrandColourModel
     */
    a?: number;

    constructor(data?: BrandColourModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(value: BrandColourModelInterface): { [property: string]: any } {
        return {};
    }

}

export function BrandColourModelFromJSON(json: any): BrandColourModel {
    return BrandColourModelFromJSONTyped(json, false);
}

export function BrandColourModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): BrandColourModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = BrandColourModel;
    try {
        let localClass = require.context('../override', true)('./BrandColourModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'id': !exists(json, 'id') ? undefined : Number(json['id']),
        'brand_id': !exists(json, 'brand_id') ? undefined : Number(json['brand_id']),
        'name': !exists(json, 'name') ? undefined : json['name'],
        'hex': !exists(json, 'hex') ? undefined : json['hex'],
        'r': !exists(json, 'r') ? undefined : Number(json['r']),
        'g': !exists(json, 'g') ? undefined : Number(json['g']),
        'b': !exists(json, 'b') ? undefined : Number(json['b']),
        'a': !exists(json, 'a') ? undefined : Number(json['a']),
    });
}

export function BrandColourModelToJSON(value?: BrandColourModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = BrandColourModel;
    try {
        let localClass = require.context('../override', true)('./BrandColourModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return {
        
        'id': value.id,
        'brand_id': value.brand_id,
        'name': value.name,
        'hex': value.hex,
        'r': value.r,
        'g': value.g,
        'b': value.b,
        'a': value.a,
        ...createClass.toJson(value),
    };
}


