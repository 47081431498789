/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal Client Api
 * Bumbal API documentation
 *
 * The version of the OpenAPI document: 2.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel } from '../runtime';
import {
    UsersModel,
    UsersModelFromJSON,
    UsersModelFromJSONTyped,
    UsersModelToJSON,
} from './';


export interface AuthenticateModelInterface {
    token?: string;
    user?: UsersModel;
}

/**
 * 
 * @export
 * @interface AuthenticateModel
 */
export class AuthenticateModel extends BaseModel<AuthenticateModelInterface> {

    /**
     * your access token. If request was made with 'return_jwt', then this token will be a JWT token. Use this token in a header Authorization:Bearer <jwt goes here>. This token currently has an expiration time of 8 hours, but will also be invalidated when signing out
     * @type {string}
     * @memberof AuthenticateModel
     */
    token?: string;
    /**
     * 
     * @type {UsersModel}
     * @memberof AuthenticateModel
     */
    user?: UsersModel;

    constructor(data?: AuthenticateModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(value: AuthenticateModelInterface): { [property: string]: any } {
        return {};
    }

}

export function AuthenticateModelFromJSON(json: any): AuthenticateModel {
    return AuthenticateModelFromJSONTyped(json, false);
}

export function AuthenticateModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuthenticateModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = AuthenticateModel;
    try {
        let localClass = require.context('../override', true)('./AuthenticateModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'token': !exists(json, 'token') ? undefined : json['token'],
        'user': !exists(json, 'user') ? undefined : UsersModelFromJSON(json['user']),
    });
}

export function AuthenticateModelToJSON(value?: AuthenticateModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = AuthenticateModel;
    try {
        let localClass = require.context('../override', true)('./AuthenticateModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return {
        
        'token': value.token,
        'user': UsersModelToJSON(value.user),
        ...createClass.toJson(value),
    };
}


