import React from 'react'
import { Box } from '@mui/material';





const UserManualHeader = () => {
  
      return (
        <Box
        height="175px"
        display="flex"
        position="relative"
        sx={{
            backgroundColor: '#181818',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
      
      
    </Box>
  )
}
export default UserManualHeader;
