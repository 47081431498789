/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal Client Api
 * Bumbal API documentation
 *
 * The version of the OpenAPI document: 2.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiResponse401,
    ApiResponse401FromJSON,
    ApiResponse401ToJSON,
    ApiResponse403,
    ApiResponse403FromJSON,
    ApiResponse403ToJSON,
    ApiResponse405,
    ApiResponse405FromJSON,
    ApiResponse405ToJSON,
    QuestionnaireTypeListResponse,
    QuestionnaireTypeListResponseFromJSON,
    QuestionnaireTypeListResponseToJSON,
    QuestionnaireTypeModel,
    QuestionnaireTypeModelFromJSON,
    QuestionnaireTypeModelToJSON,
    QuestionnaireTypeRetrieveListArguments,
    QuestionnaireTypeRetrieveListArgumentsFromJSON,
    QuestionnaireTypeRetrieveListArgumentsToJSON,
} from '../models';

export interface RetrieveListQuestionnaireTypeRequest {
    arguments: QuestionnaireTypeRetrieveListArguments;
}

export interface RetrieveQuestionnaireTypeRequest {
    notificationId: number;
}

/**
 * 
 */
export class QuestionnaireTypeApi extends runtime.BaseAPI {

    /**
     * Retrieve List of QuestionnaireType
     * Retrieve List of QuestionnaireType
     */
    async retrieveListQuestionnaireTypeRaw(requestParameters: RetrieveListQuestionnaireTypeRequest): Promise<runtime.ApiResponse<QuestionnaireTypeListResponse>> {
        if (requestParameters.arguments === null || requestParameters.arguments === undefined) {
            throw new runtime.RequiredError('arguments','Required parameter requestParameters.arguments was null or undefined when calling retrieveListQuestionnaireType.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/questionnaire-type`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: QuestionnaireTypeRetrieveListArgumentsToJSON(requestParameters.arguments),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => QuestionnaireTypeListResponseFromJSON(jsonValue));
    }

    /**
     * Retrieve List of QuestionnaireType
     * Retrieve List of QuestionnaireType
     */
    retrieveListQuestionnaireType = async(requestParameters: RetrieveListQuestionnaireTypeRequest): Promise<QuestionnaireTypeListResponse> => {
        const response = await this.retrieveListQuestionnaireTypeRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieve an QuestionnaireType
     * Retrieve a QuestionnaireType
     */
    async retrieveQuestionnaireTypeRaw(requestParameters: RetrieveQuestionnaireTypeRequest): Promise<runtime.ApiResponse<QuestionnaireTypeModel>> {
        if (requestParameters.notificationId === null || requestParameters.notificationId === undefined) {
            throw new runtime.RequiredError('notificationId','Required parameter requestParameters.notificationId was null or undefined when calling retrieveQuestionnaireType.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/questionnaire-type/{questionnaire-typeId}`.replace(`{${"notificationId"}}`, encodeURIComponent(String(requestParameters.notificationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => QuestionnaireTypeModelFromJSON(jsonValue));
    }

    /**
     * Retrieve an QuestionnaireType
     * Retrieve a QuestionnaireType
     */
    retrieveQuestionnaireType = async(requestParameters: RetrieveQuestionnaireTypeRequest): Promise<QuestionnaireTypeModel> => {
        const response = await this.retrieveQuestionnaireTypeRaw(requestParameters);
        return await response.value();
    }

}
