import { Box, Button } from '@mui/material';
import { t } from 'i18next';
import { useHistory } from 'react-router-dom';
import React from 'react';

interface SubjectNavigatorProps {
    chapterIndex: number;
    subjectIndex: number;
    finalChapterIndex: number;
    finalSubjectIndex: number;
    switchChapter: (number: number) => void;
}

const SubjectNavigator = ({
    subjectIndex,
    finalSubjectIndex,
    finalChapterIndex,
    chapterIndex,
    switchChapter,
}: SubjectNavigatorProps) => {
    const history = useHistory();

    const traverseChapter = (offset: number) => {
        const pathParts = history.location.pathname.split('/');
        const newIndex = chapterIndex += offset;

        if (newIndex > finalChapterIndex || newIndex < 0) {
            pathParts[3] = Number(pathParts[3]) + offset;
            history.push({
                pathname: pathParts.join('/')
            });
        } else {
            switchChapter(newIndex);
        }
    };

    return (
        <Box display='flex' paddingTop='10px' sx={{ justifyContent: 'center' }}>
            <Button
                variant='contained'
                disabled={subjectIndex === 0 && chapterIndex === 0}
                onClick={(e) => traverseChapter(-1)}
                sx={{ mr: '10px' }}
            >
                {t('subject.previous')}
            </Button>
            <Button
                variant='contained'
                disabled={subjectIndex === finalSubjectIndex && chapterIndex === finalChapterIndex}
                onClick={(e) => traverseChapter(1)}
            >
                {t('subject.next_subject')}
            </Button>
        </Box>
    );
};

export default SubjectNavigator;
