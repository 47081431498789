/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal Client Api
 * Bumbal API documentation
 *
 * The version of the OpenAPI document: 2.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ModelApiResponse,
    ModelApiResponseFromJSON,
    ModelApiResponseToJSON,
    ServiceWindowListResponse,
    ServiceWindowListResponseFromJSON,
    ServiceWindowListResponseToJSON,
    ServiceWindowModel,
    ServiceWindowModelFromJSON,
    ServiceWindowModelToJSON,
    ServiceWindowRetrieveListArguments,
    ServiceWindowRetrieveListArgumentsFromJSON,
    ServiceWindowRetrieveListArgumentsToJSON,
} from '../models';

export interface RetrieveListServiceWindowRequest {
    arguments: ServiceWindowRetrieveListArguments;
}

export interface RetrieveServiceWindowRequest {
    serviceWindowId: number;
}

export interface SetServiceWindowRequest {
    body?: ServiceWindowModel;
}

/**
 * 
 */
export class ServiceWindowApi extends runtime.BaseAPI {

    /**
     * Retrieve a list of service windows
     * Retrieve a list of service windows
     */
    async retrieveListServiceWindowRaw(requestParameters: RetrieveListServiceWindowRequest): Promise<runtime.ApiResponse<ServiceWindowListResponse>> {
        if (requestParameters.arguments === null || requestParameters.arguments === undefined) {
            throw new runtime.RequiredError('arguments','Required parameter requestParameters.arguments was null or undefined when calling retrieveListServiceWindow.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/service-window`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ServiceWindowRetrieveListArgumentsToJSON(requestParameters.arguments),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ServiceWindowListResponseFromJSON(jsonValue));
    }

    /**
     * Retrieve a list of service windows
     * Retrieve a list of service windows
     */
    retrieveListServiceWindow = async(requestParameters: RetrieveListServiceWindowRequest): Promise<ServiceWindowListResponse> => {
        const response = await this.retrieveListServiceWindowRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieve a single service window
     * Retrieve a single service window
     */
    async retrieveServiceWindowRaw(requestParameters: RetrieveServiceWindowRequest): Promise<runtime.ApiResponse<ServiceWindowModel>> {
        if (requestParameters.serviceWindowId === null || requestParameters.serviceWindowId === undefined) {
            throw new runtime.RequiredError('serviceWindowId','Required parameter requestParameters.serviceWindowId was null or undefined when calling retrieveServiceWindow.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/service-window/{serviceWindowId}`.replace(`{${"serviceWindowId"}}`, encodeURIComponent(String(requestParameters.serviceWindowId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ServiceWindowModelFromJSON(jsonValue));
    }

    /**
     * Retrieve a single service window
     * Retrieve a single service window
     */
    retrieveServiceWindow = async(requestParameters: RetrieveServiceWindowRequest): Promise<ServiceWindowModel> => {
        const response = await this.retrieveServiceWindowRaw(requestParameters);
        return await response.value();
    }

    /**
     * Add or update a service window
     * Add or update a service window
     */
    async setServiceWindowRaw(requestParameters: SetServiceWindowRequest): Promise<runtime.ApiResponse<ModelApiResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/service-window/set`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ServiceWindowModelToJSON(requestParameters.body),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelApiResponseFromJSON(jsonValue));
    }

    /**
     * Add or update a service window
     * Add or update a service window
     */
    setServiceWindow = async(requestParameters: SetServiceWindowRequest): Promise<ModelApiResponse> => {
        const response = await this.setServiceWindowRaw(requestParameters);
        return await response.value();
    }

}
