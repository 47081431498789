/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal Client Api
 * Bumbal API documentation
 *
 * The version of the OpenAPI document: 2.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiResponse401,
    ApiResponse401FromJSON,
    ApiResponse401ToJSON,
    ApiResponse403,
    ApiResponse403FromJSON,
    ApiResponse403ToJSON,
    ApiResponse405,
    ApiResponse405FromJSON,
    ApiResponse405ToJSON,
    VehicleTypeCreateResponse,
    VehicleTypeCreateResponseFromJSON,
    VehicleTypeCreateResponseToJSON,
    VehicleTypeDeleteNotFoundResponse,
    VehicleTypeDeleteNotFoundResponseFromJSON,
    VehicleTypeDeleteNotFoundResponseToJSON,
    VehicleTypeDeleteResponse,
    VehicleTypeDeleteResponseFromJSON,
    VehicleTypeDeleteResponseToJSON,
    VehicleTypeListResponse,
    VehicleTypeListResponseFromJSON,
    VehicleTypeListResponseToJSON,
    VehicleTypeModel,
    VehicleTypeModelFromJSON,
    VehicleTypeModelToJSON,
    VehicleTypeRetrieveListArguments,
    VehicleTypeRetrieveListArgumentsFromJSON,
    VehicleTypeRetrieveListArgumentsToJSON,
    VehicleTypeUpdateResponse,
    VehicleTypeUpdateResponseFromJSON,
    VehicleTypeUpdateResponseToJSON,
} from '../models';

export interface CreateVehicleTypeRequest {
    body?: VehicleTypeModel;
}

export interface DeleteVehicleTypeRequest {
    vehicleTypeId: number;
}

export interface RetrieveListVehicleTypeRequest {
    arguments: VehicleTypeRetrieveListArguments;
}

export interface RetrieveVehicleTypeRequest {
    providerId: number;
}

export interface UpdateVehicleTypeRequest {
    vehicleTypeId: number;
    body?: VehicleTypeModel;
}

/**
 * 
 */
export class VehicleTypeApi extends runtime.BaseAPI {

    /**
     * Add a new VehicleType
     * Add a new VehicleType
     */
    async createVehicleTypeRaw(requestParameters: CreateVehicleTypeRequest): Promise<runtime.ApiResponse<VehicleTypeCreateResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/vehicle-type`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: VehicleTypeModelToJSON(requestParameters.body),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => VehicleTypeCreateResponseFromJSON(jsonValue));
    }

    /**
     * Add a new VehicleType
     * Add a new VehicleType
     */
    createVehicleType = async(requestParameters: CreateVehicleTypeRequest): Promise<VehicleTypeCreateResponse> => {
        const response = await this.createVehicleTypeRaw(requestParameters);
        return await response.value();
    }

    /**
     * Delete a VehicleType entry
     * Delete a VehicleType entry
     */
    async deleteVehicleTypeRaw(requestParameters: DeleteVehicleTypeRequest): Promise<runtime.ApiResponse<VehicleTypeDeleteResponse>> {
        if (requestParameters.vehicleTypeId === null || requestParameters.vehicleTypeId === undefined) {
            throw new runtime.RequiredError('vehicleTypeId','Required parameter requestParameters.vehicleTypeId was null or undefined when calling deleteVehicleType.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/vehicle-type/{vehicleTypeId}`.replace(`{${"vehicleTypeId"}}`, encodeURIComponent(String(requestParameters.vehicleTypeId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => VehicleTypeDeleteResponseFromJSON(jsonValue));
    }

    /**
     * Delete a VehicleType entry
     * Delete a VehicleType entry
     */
    deleteVehicleType = async(requestParameters: DeleteVehicleTypeRequest): Promise<VehicleTypeDeleteResponse> => {
        const response = await this.deleteVehicleTypeRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieve List of VehicleTypes
     * Retrieve List of VehicleTypes
     */
    async retrieveListVehicleTypeRaw(requestParameters: RetrieveListVehicleTypeRequest): Promise<runtime.ApiResponse<VehicleTypeListResponse>> {
        if (requestParameters.arguments === null || requestParameters.arguments === undefined) {
            throw new runtime.RequiredError('arguments','Required parameter requestParameters.arguments was null or undefined when calling retrieveListVehicleType.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/vehicle-type`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: VehicleTypeRetrieveListArgumentsToJSON(requestParameters.arguments),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => VehicleTypeListResponseFromJSON(jsonValue));
    }

    /**
     * Retrieve List of VehicleTypes
     * Retrieve List of VehicleTypes
     */
    retrieveListVehicleType = async(requestParameters: RetrieveListVehicleTypeRequest): Promise<VehicleTypeListResponse> => {
        const response = await this.retrieveListVehicleTypeRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieve an VehicleType
     * Retrieve a VehicleType
     */
    async retrieveVehicleTypeRaw(requestParameters: RetrieveVehicleTypeRequest): Promise<runtime.ApiResponse<VehicleTypeModel>> {
        if (requestParameters.providerId === null || requestParameters.providerId === undefined) {
            throw new runtime.RequiredError('providerId','Required parameter requestParameters.providerId was null or undefined when calling retrieveVehicleType.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/vehicle-type/{vehicleTypeId}`.replace(`{${"providerId"}}`, encodeURIComponent(String(requestParameters.providerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => VehicleTypeModelFromJSON(jsonValue));
    }

    /**
     * Retrieve an VehicleType
     * Retrieve a VehicleType
     */
    retrieveVehicleType = async(requestParameters: RetrieveVehicleTypeRequest): Promise<VehicleTypeModel> => {
        const response = await this.retrieveVehicleTypeRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update a specific VehicleType object
     * Update a specific VehicleType object
     */
    async updateVehicleTypeRaw(requestParameters: UpdateVehicleTypeRequest): Promise<runtime.ApiResponse<VehicleTypeUpdateResponse>> {
        if (requestParameters.vehicleTypeId === null || requestParameters.vehicleTypeId === undefined) {
            throw new runtime.RequiredError('vehicleTypeId','Required parameter requestParameters.vehicleTypeId was null or undefined when calling updateVehicleType.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/vehicle-type/{vehicleTypeId}`.replace(`{${"vehicleTypeId"}}`, encodeURIComponent(String(requestParameters.vehicleTypeId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: VehicleTypeModelToJSON(requestParameters.body),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => VehicleTypeUpdateResponseFromJSON(jsonValue));
    }

    /**
     * Update a specific VehicleType object
     * Update a specific VehicleType object
     */
    updateVehicleType = async(requestParameters: UpdateVehicleTypeRequest): Promise<VehicleTypeUpdateResponse> => {
        const response = await this.updateVehicleTypeRaw(requestParameters);
        return await response.value();
    }

}
