
enum ActionTypes {
    API_CALL_START = "API_CALL_START",
    API_CALL_FINISH = "API_CALL_FINISH",
    API_CALL_FAILURE = "API_CALL_FAILURE",

    API_404_ERROR = "API_404_ERROR",

    // Authentication/user action types
    API_SIGN_IN = 'API_SIGN_IN',
    API_SIGN_IN_SUCCESS = 'API_SIGN_IN_SUCCESS',
    API_SIGN_IN_FAILURE = 'API_SIGN_IN_FAILURE',

    API_SIGN_OUT = "API_SIGN_OUT",
    API_SIGN_OUT_SUCCESS = "API_SIGN_OUT_SUCCESS",

    API_USER_RETRIEVE = "API_USER_RETRIEVE",
    API_USER_RETRIEVE_SUCCESS = "API_USER_RETRIEVE_SUCCESS",
    API_USER_RETRIEVE_ERROR = "API_USER_RETRIEVE_ERROR",

    API_USER_UPDATE = "API_USER_UPDATE",
    API_USER_UPDATE_SUCCESS = "API_USER_UPDATE_SUCCESS",
    API_USER_UPDATE_ERROR = "API_USER_UPDATE_ERROR",

    META_DATA_UPDATE = "META_DATA_UPDATE",
    META_DATA_RESET = "META_DATA_RESET"
}

export default ActionTypes;