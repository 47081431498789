/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal Client Api
 * Bumbal API documentation
 *
 * The version of the OpenAPI document: 2.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel } from '../runtime';

export interface TagModelInterface {
    id?: number;
    object_type?: number;
    tag_type_id?: number;
    tag_type_name?: string;
    tag_name?: string;
}

/**
 * 
 * @export
 * @interface TagModel
 */
export class TagModel extends BaseModel<TagModelInterface> {

    /**
     * Unique ID
     * @type {number}
     * @memberof TagModel
     */
    id?: number;
    /**
     * Object type ID
     * @type {number}
     * @memberof TagModel
     */
    object_type?: number;
    /**
     * Tag type ID
     * @type {number}
     * @memberof TagModel
     */
    tag_type_id?: number;
    /**
     * Tag type Name
     * @type {string}
     * @memberof TagModel
     */
    tag_type_name?: string;
    /**
     * Tag Name (same as tag_type_name, but tag_type_name will become deprecated in the future)
     * @type {string}
     * @memberof TagModel
     */
    tag_name?: string;

    constructor(data?: TagModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(value: TagModelInterface): { [property: string]: any } {
        return {};
    }

}

export function TagModelFromJSON(json: any): TagModel {
    return TagModelFromJSONTyped(json, false);
}

export function TagModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): TagModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = TagModel;
    try {
        let localClass = require.context('../override', true)('./TagModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'id': !exists(json, 'id') ? undefined : Number(json['id']),
        'object_type': !exists(json, 'object_type') ? undefined : Number(json['object_type']),
        'tag_type_id': !exists(json, 'tag_type_id') ? undefined : Number(json['tag_type_id']),
        'tag_type_name': !exists(json, 'tag_type_name') ? undefined : json['tag_type_name'],
        'tag_name': !exists(json, 'tag_name') ? undefined : json['tag_name'],
    });
}

export function TagModelToJSON(value?: TagModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = TagModel;
    try {
        let localClass = require.context('../override', true)('./TagModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return {
        
        'id': value.id,
        'object_type': value.object_type,
        'tag_type_id': value.tag_type_id,
        'tag_type_name': value.tag_type_name,
        'tag_name': value.tag_name,
        ...createClass.toJson(value),
    };
}


