import { Box, Paper } from '@mui/material';
import { Markup } from 'interweave';
import React from 'react';
import { Chapter } from '../../../interface/CourseContent'

interface ChapterContentProps {
    chapter: Chapter;
}

const ChapterContent = ({ chapter }: ChapterContentProps) => {
    return (
        <Paper>
            <Box
                position="relative"
                paddingBottom="56.25%"
                height="0"
            >
                <iframe
                    src={chapter.content.video}
                    title="Chapter video"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%'
                    }}
                />
            </Box>
            <Box sx={{ p: '0px 25px 15px 25px' }} >
                <Markup content={chapter.content.text} />
            </Box>
        </Paper>
    )
}

export default ChapterContent