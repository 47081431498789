/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal Client Api
 * Bumbal API documentation
 *
 * The version of the OpenAPI document: 2.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel } from '../runtime';
import {
    LinkModel,
    LinkModelFromJSON,
    LinkModelFromJSONTyped,
    LinkModelToJSON,
    MetaDataModel,
    MetaDataModelFromJSON,
    MetaDataModelFromJSONTyped,
    MetaDataModelToJSON,
} from './';


export interface FileModelInterface {
    id?: number;
    uuid?: string;
    file_type_id?: number;
    file_type_name?: string;
    object_id?: number;
    object_type?: number;
    object_type_name?: string;
    reference?: string;
    location?: string;
    base64?: string;
    meta_data?: Array<MetaDataModel>;
    created_by?: number;
    updated_by?: number;
    created_at?: Date;
    updated_at?: Date;
    links?: Array<LinkModel>;
}

/**
 * 
 * @export
 * @interface FileModel
 */
export class FileModel extends BaseModel<FileModelInterface> {

    /**
     * 
     * @type {number}
     * @memberof FileModel
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof FileModel
     */
    uuid?: string;
    /**
     * 
     * @type {number}
     * @memberof FileModel
     */
    file_type_id?: number;
    /**
     * 
     * @type {string}
     * @memberof FileModel
     */
    file_type_name?: string;
    /**
     * 
     * @type {number}
     * @memberof FileModel
     */
    object_id?: number;
    /**
     * Object type ID
     * @type {number}
     * @memberof FileModel
     */
    object_type?: number;
    /**
     * Object type name
     * @type {string}
     * @memberof FileModel
     */
    object_type_name?: string;
    /**
     * 
     * @type {string}
     * @memberof FileModel
     */
    reference?: string;
    /**
     * 
     * @type {string}
     * @memberof FileModel
     */
    location?: string;
    /**
     * 
     * @type {string}
     * @memberof FileModel
     */
    base64?: string;
    /**
     * 
     * @type {Array<MetaDataModel>}
     * @memberof FileModel
     */
    meta_data?: Array<MetaDataModel>;
    /**
     * 
     * @type {number}
     * @memberof FileModel
     */
    created_by?: number;
    /**
     * 
     * @type {number}
     * @memberof FileModel
     */
    updated_by?: number;
    /**
     * created_at
     * @type {Date}
     * @memberof FileModel
     */
    created_at?: Date;
    /**
     * updated_at
     * @type {Date}
     * @memberof FileModel
     */
    updated_at?: Date;
    /**
     * 
     * @type {Array<LinkModel>}
     * @memberof FileModel
     */
    links?: Array<LinkModel>;

    constructor(data?: FileModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(value: FileModelInterface): { [property: string]: any } {
        return {};
    }

}

export function FileModelFromJSON(json: any): FileModel {
    return FileModelFromJSONTyped(json, false);
}

export function FileModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): FileModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = FileModel;
    try {
        let localClass = require.context('../override', true)('./FileModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'id': !exists(json, 'id') ? undefined : Number(json['id']),
        'uuid': !exists(json, 'uuid') ? undefined : json['uuid'],
        'file_type_id': !exists(json, 'file_type_id') ? undefined : Number(json['file_type_id']),
        'file_type_name': !exists(json, 'file_type_name') ? undefined : json['file_type_name'],
        'object_id': !exists(json, 'object_id') ? undefined : Number(json['object_id']),
        'object_type': !exists(json, 'object_type') ? undefined : Number(json['object_type']),
        'object_type_name': !exists(json, 'object_type_name') ? undefined : json['object_type_name'],
        'reference': !exists(json, 'reference') ? undefined : json['reference'],
        'location': !exists(json, 'location') ? undefined : json['location'],
        'base64': !exists(json, 'base64') ? undefined : json['base64'],
        'meta_data': !exists(json, 'meta_data') ? undefined : ((json['meta_data'] as Array<any>).map(MetaDataModelFromJSON)),
        'created_by': !exists(json, 'created_by') ? undefined : Number(json['created_by']),
        'updated_by': !exists(json, 'updated_by') ? undefined : Number(json['updated_by']),
        'created_at': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'].replace(' ', 'T'))),
        'updated_at': !exists(json, 'updated_at') ? undefined : (new Date(json['updated_at'].replace(' ', 'T'))),
        'links': !exists(json, 'links') ? undefined : ((json['links'] as Array<any>).map(LinkModelFromJSON)),
    });
}

export function FileModelToJSON(value?: FileModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = FileModel;
    try {
        let localClass = require.context('../override', true)('./FileModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return {
        
        'id': value.id,
        'uuid': value.uuid,
        'file_type_id': value.file_type_id,
        'file_type_name': value.file_type_name,
        'object_id': value.object_id,
        'object_type': value.object_type,
        'object_type_name': value.object_type_name,
        'reference': value.reference,
        'location': value.location,
        'base64': value.base64,
        'meta_data': value.meta_data === undefined ? undefined : ((value.meta_data as Array<any>).map(MetaDataModelToJSON)),
        'created_by': value.created_by,
        'updated_by': value.updated_by,
        'created_at': value.created_at === undefined ? undefined : (value.created_at.toApiFormat()),
        'updated_at': value.updated_at === undefined ? undefined : (value.updated_at.toApiFormat()),
        'links': value.links === undefined ? undefined : ((value.links as Array<any>).map(LinkModelToJSON)),
        ...createClass.toJson(value),
    };
}


