/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal Client Api
 * Bumbal API documentation
 *
 * The version of the OpenAPI document: 2.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel } from '../runtime';

export interface ModelApiResponseInterface {
    code?: number;
    type?: string;
    message?: string;
    additional_data?: object;
}

/**
 * 
 * @export
 * @interface ModelApiResponse
 */
export class ModelApiResponse extends BaseModel<ModelApiResponseInterface> {

    /**
     * 
     * @type {number}
     * @memberof ModelApiResponse
     */
    code?: number;
    /**
     * 
     * @type {string}
     * @memberof ModelApiResponse
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelApiResponse
     */
    message?: string;
    /**
     * 
     * @type {object}
     * @memberof ModelApiResponse
     */
    additional_data?: object;

    constructor(data?: ModelApiResponseInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(value: ModelApiResponseInterface): { [property: string]: any } {
        return {};
    }

}

export function ModelApiResponseFromJSON(json: any): ModelApiResponse {
    return ModelApiResponseFromJSONTyped(json, false);
}

export function ModelApiResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ModelApiResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = ModelApiResponse;
    try {
        let localClass = require.context('../override', true)('./ModelApiResponse');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'code': !exists(json, 'code') ? undefined : Number(json['code']),
        'type': !exists(json, 'type') ? undefined : json['type'],
        'message': !exists(json, 'message') ? undefined : json['message'],
        'additional_data': !exists(json, 'additional_data') ? undefined : json['additional_data'],
    });
}

export function ModelApiResponseToJSON(value?: ModelApiResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = ModelApiResponse;
    try {
        let localClass = require.context('../override', true)('./ModelApiResponse');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return {
        
        'code': value.code,
        'type': value.type,
        'message': value.message,
        'additional_data': value.additional_data,
        ...createClass.toJson(value),
    };
}


