/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal Client Api
 * Bumbal API documentation
 *
 * The version of the OpenAPI document: 2.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel } from '../runtime';
import {
    QuestionnaireTypeFiltersModel,
    QuestionnaireTypeFiltersModelFromJSON,
    QuestionnaireTypeFiltersModelFromJSONTyped,
    QuestionnaireTypeFiltersModelToJSON,
    QuestionnaireTypeOptionsModel,
    QuestionnaireTypeOptionsModelFromJSON,
    QuestionnaireTypeOptionsModelFromJSONTyped,
    QuestionnaireTypeOptionsModelToJSON,
} from './';


export interface QuestionnaireTypeRetrieveListArgumentsInterface {
    options?: QuestionnaireTypeOptionsModel;
    filters?: QuestionnaireTypeFiltersModel;
    limit?: number;
    offset?: number;
    sorting_column?: QuestionnaireTypeRetrieveListArgumentsSortingColumnEnum;
    sorting_direction?: QuestionnaireTypeRetrieveListArgumentsSortingDirectionEnum;
    search_text?: string;
}

/**
 * 
 * @export
 * @interface QuestionnaireTypeRetrieveListArguments
 */
export class QuestionnaireTypeRetrieveListArguments extends BaseModel<QuestionnaireTypeRetrieveListArgumentsInterface> {

    /**
     * 
     * @type {QuestionnaireTypeOptionsModel}
     * @memberof QuestionnaireTypeRetrieveListArguments
     */
    options?: QuestionnaireTypeOptionsModel;
    /**
     * 
     * @type {QuestionnaireTypeFiltersModel}
     * @memberof QuestionnaireTypeRetrieveListArguments
     */
    filters?: QuestionnaireTypeFiltersModel;
    /**
     * 
     * @type {number}
     * @memberof QuestionnaireTypeRetrieveListArguments
     */
    limit?: number;
    /**
     * 
     * @type {number}
     * @memberof QuestionnaireTypeRetrieveListArguments
     */
    offset?: number;
    /**
     * Sorting Column
     * @type {string}
     * @memberof QuestionnaireTypeRetrieveListArguments
     */
    sorting_column?: QuestionnaireTypeRetrieveListArgumentsSortingColumnEnum;
    /**
     * Sorting Direction
     * @type {string}
     * @memberof QuestionnaireTypeRetrieveListArguments
     */
    sorting_direction?: QuestionnaireTypeRetrieveListArgumentsSortingDirectionEnum;
    /**
     * 
     * @type {string}
     * @memberof QuestionnaireTypeRetrieveListArguments
     */
    search_text?: string;

    constructor(data?: QuestionnaireTypeRetrieveListArgumentsInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(value: QuestionnaireTypeRetrieveListArgumentsInterface): { [property: string]: any } {
        return {};
    }

}

export function QuestionnaireTypeRetrieveListArgumentsFromJSON(json: any): QuestionnaireTypeRetrieveListArguments {
    return QuestionnaireTypeRetrieveListArgumentsFromJSONTyped(json, false);
}

export function QuestionnaireTypeRetrieveListArgumentsFromJSONTyped(json: any, ignoreDiscriminator: boolean): QuestionnaireTypeRetrieveListArguments {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = QuestionnaireTypeRetrieveListArguments;
    try {
        let localClass = require.context('../override', true)('./QuestionnaireTypeRetrieveListArguments');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'options': !exists(json, 'options') ? undefined : QuestionnaireTypeOptionsModelFromJSON(json['options']),
        'filters': !exists(json, 'filters') ? undefined : QuestionnaireTypeFiltersModelFromJSON(json['filters']),
        'limit': !exists(json, 'limit') ? undefined : Number(json['limit']),
        'offset': !exists(json, 'offset') ? undefined : Number(json['offset']),
        'sorting_column': !exists(json, 'sorting_column') ? undefined : json['sorting_column'],
        'sorting_direction': !exists(json, 'sorting_direction') ? undefined : json['sorting_direction'],
        'search_text': !exists(json, 'search_text') ? undefined : json['search_text'],
    });
}

export function QuestionnaireTypeRetrieveListArgumentsToJSON(value?: QuestionnaireTypeRetrieveListArguments | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = QuestionnaireTypeRetrieveListArguments;
    try {
        let localClass = require.context('../override', true)('./QuestionnaireTypeRetrieveListArguments');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return {
        
        'options': QuestionnaireTypeOptionsModelToJSON(value.options),
        'filters': QuestionnaireTypeFiltersModelToJSON(value.filters),
        'limit': value.limit,
        'offset': value.offset,
        'sorting_column': value.sorting_column,
        'sorting_direction': value.sorting_direction,
        'search_text': value.search_text,
        ...createClass.toJson(value),
    };
}

/**
* @export
* @enum {string}
*/
export enum QuestionnaireTypeRetrieveListArgumentsSortingColumnEnum {
    Id = 'id',
    Name = 'name',
    Description = 'description',
    Subject = 'subject'
}
/**
* @export
* @enum {string}
*/
export enum QuestionnaireTypeRetrieveListArgumentsSortingDirectionEnum {
    ASC = 'ASC',
    DESC = 'DESC'
}


