/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal Client Api
 * Bumbal API documentation
 *
 * The version of the OpenAPI document: 2.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiResponse401,
    ApiResponse401FromJSON,
    ApiResponse401ToJSON,
    ApiResponse403,
    ApiResponse403FromJSON,
    ApiResponse403ToJSON,
    ApiResponse405,
    ApiResponse405FromJSON,
    ApiResponse405ToJSON,
    PaymentCreateResponse,
    PaymentCreateResponseFromJSON,
    PaymentCreateResponseToJSON,
    PaymentDeleteNotFoundResponse,
    PaymentDeleteNotFoundResponseFromJSON,
    PaymentDeleteNotFoundResponseToJSON,
    PaymentDeleteResponse,
    PaymentDeleteResponseFromJSON,
    PaymentDeleteResponseToJSON,
    PaymentListResponse,
    PaymentListResponseFromJSON,
    PaymentListResponseToJSON,
    PaymentModel,
    PaymentModelFromJSON,
    PaymentModelToJSON,
    PaymentRetrieveListArguments,
    PaymentRetrieveListArgumentsFromJSON,
    PaymentRetrieveListArgumentsToJSON,
    PaymentSetResponse,
    PaymentSetResponseFromJSON,
    PaymentSetResponseToJSON,
    PaymentUpdateResponse,
    PaymentUpdateResponseFromJSON,
    PaymentUpdateResponseToJSON,
} from '../models';

export interface CreatePaymentRequest {
    body?: PaymentModel;
}

export interface DeletePaymentRequest {
    paymentId: number;
}

export interface RetrieveListPaymentRequest {
    arguments: PaymentRetrieveListArguments;
}

export interface RetrievePaymentRequest {
    paymentId: number;
}

export interface SetPaymentRequest {
    body?: PaymentModel;
}

export interface UpdatePaymentRequest {
    paymentId: number;
    body?: PaymentModel;
}

/**
 * 
 */
export class PaymentApi extends runtime.BaseAPI {

    /**
     * Add a new Payment
     * Add a new Payment
     */
    async createPaymentRaw(requestParameters: CreatePaymentRequest): Promise<runtime.ApiResponse<PaymentCreateResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/payment`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PaymentModelToJSON(requestParameters.body),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PaymentCreateResponseFromJSON(jsonValue));
    }

    /**
     * Add a new Payment
     * Add a new Payment
     */
    createPayment = async(requestParameters: CreatePaymentRequest): Promise<PaymentCreateResponse> => {
        const response = await this.createPaymentRaw(requestParameters);
        return await response.value();
    }

    /**
     * Delete an Payment entry
     * Delete an Payment entry
     */
    async deletePaymentRaw(requestParameters: DeletePaymentRequest): Promise<runtime.ApiResponse<PaymentDeleteResponse>> {
        if (requestParameters.paymentId === null || requestParameters.paymentId === undefined) {
            throw new runtime.RequiredError('paymentId','Required parameter requestParameters.paymentId was null or undefined when calling deletePayment.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/payment/{paymentId}`.replace(`{${"paymentId"}}`, encodeURIComponent(String(requestParameters.paymentId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PaymentDeleteResponseFromJSON(jsonValue));
    }

    /**
     * Delete an Payment entry
     * Delete an Payment entry
     */
    deletePayment = async(requestParameters: DeletePaymentRequest): Promise<PaymentDeleteResponse> => {
        const response = await this.deletePaymentRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieve List of Payment
     * Retrieve List of Payment
     */
    async retrieveListPaymentRaw(requestParameters: RetrieveListPaymentRequest): Promise<runtime.ApiResponse<PaymentListResponse>> {
        if (requestParameters.arguments === null || requestParameters.arguments === undefined) {
            throw new runtime.RequiredError('arguments','Required parameter requestParameters.arguments was null or undefined when calling retrieveListPayment.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/payment`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PaymentRetrieveListArgumentsToJSON(requestParameters.arguments),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PaymentListResponseFromJSON(jsonValue));
    }

    /**
     * Retrieve List of Payment
     * Retrieve List of Payment
     */
    retrieveListPayment = async(requestParameters: RetrieveListPaymentRequest): Promise<PaymentListResponse> => {
        const response = await this.retrieveListPaymentRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieve an Payment
     * Retrieve a Payment
     */
    async retrievePaymentRaw(requestParameters: RetrievePaymentRequest): Promise<runtime.ApiResponse<PaymentModel>> {
        if (requestParameters.paymentId === null || requestParameters.paymentId === undefined) {
            throw new runtime.RequiredError('paymentId','Required parameter requestParameters.paymentId was null or undefined when calling retrievePayment.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/payment/{paymentId}`.replace(`{${"paymentId"}}`, encodeURIComponent(String(requestParameters.paymentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PaymentModelFromJSON(jsonValue));
    }

    /**
     * Retrieve an Payment
     * Retrieve a Payment
     */
    retrievePayment = async(requestParameters: RetrievePaymentRequest): Promise<PaymentModel> => {
        const response = await this.retrievePaymentRaw(requestParameters);
        return await response.value();
    }

    /**
     * Set (create or update) a d=Payment. If id or links are set in the data, and a corresponding Payment is found in Bumbal, an update will be performed.
     * Set (create or update) a Payment
     */
    async setPaymentRaw(requestParameters: SetPaymentRequest): Promise<runtime.ApiResponse<PaymentSetResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/payment/set`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PaymentModelToJSON(requestParameters.body),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PaymentSetResponseFromJSON(jsonValue));
    }

    /**
     * Set (create or update) a d=Payment. If id or links are set in the data, and a corresponding Payment is found in Bumbal, an update will be performed.
     * Set (create or update) a Payment
     */
    setPayment = async(requestParameters: SetPaymentRequest): Promise<PaymentSetResponse> => {
        const response = await this.setPaymentRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update a specific Payment object
     * Update a specific Payment object
     */
    async updatePaymentRaw(requestParameters: UpdatePaymentRequest): Promise<runtime.ApiResponse<PaymentUpdateResponse>> {
        if (requestParameters.paymentId === null || requestParameters.paymentId === undefined) {
            throw new runtime.RequiredError('paymentId','Required parameter requestParameters.paymentId was null or undefined when calling updatePayment.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/payment/{paymentId}`.replace(`{${"paymentId"}}`, encodeURIComponent(String(requestParameters.paymentId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PaymentModelToJSON(requestParameters.body),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PaymentUpdateResponseFromJSON(jsonValue));
    }

    /**
     * Update a specific Payment object
     * Update a specific Payment object
     */
    updatePayment = async(requestParameters: UpdatePaymentRequest): Promise<PaymentUpdateResponse> => {
        const response = await this.updatePaymentRaw(requestParameters);
        return await response.value();
    }

}
