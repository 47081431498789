/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal Client Api
 * Bumbal API documentation
 *
 * The version of the OpenAPI document: 2.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiResponse23,
    ApiResponse23FromJSON,
    ApiResponse23ToJSON,
    ApiResponse24,
    ApiResponse24FromJSON,
    ApiResponse24ToJSON,
    ApiResponse25,
    ApiResponse25FromJSON,
    ApiResponse25ToJSON,
    ApiResponse26,
    ApiResponse26FromJSON,
    ApiResponse26ToJSON,
    ApiResponse401,
    ApiResponse401FromJSON,
    ApiResponse401ToJSON,
    ApiResponse403,
    ApiResponse403FromJSON,
    ApiResponse403ToJSON,
    ApiResponse405,
    ApiResponse405FromJSON,
    ApiResponse405ToJSON,
    ModelApiResponse,
    ModelApiResponseFromJSON,
    ModelApiResponseToJSON,
    QuestionnaireAnswerListResponse,
    QuestionnaireAnswerListResponseFromJSON,
    QuestionnaireAnswerListResponseToJSON,
    QuestionnaireAnswerModel,
    QuestionnaireAnswerModelFromJSON,
    QuestionnaireAnswerModelToJSON,
    QuestionnaireAnswerRetrieveListArguments,
    QuestionnaireAnswerRetrieveListArgumentsFromJSON,
    QuestionnaireAnswerRetrieveListArgumentsToJSON,
} from '../models';

export interface CreateQuestionnaireAnswerRequest {
    body?: QuestionnaireAnswerModel;
}

export interface DeleteQuestionnaireAnswerRequest {
    questionnaireAnswerId: number;
}

export interface RetrieveListQuestionnaireAnswerRequest {
    arguments: QuestionnaireAnswerRetrieveListArguments;
}

export interface RetrieveQuestionnaireAnswerRequest {
    questionnaireAnswerId: number;
}

export interface SetQuestionnaireAnswerRequest {
    body?: QuestionnaireAnswerModel;
}

export interface UpdateQuestionnaireAnswerRequest {
    questionnaireAnswerId: number;
    body?: QuestionnaireAnswerModel;
}

/**
 * 
 */
export class QuestionnaireAnswerApi extends runtime.BaseAPI {

    /**
     * Add a new QuestionnaireAnswer
     * Add a new QuestionnaireAnswer
     */
    async createQuestionnaireAnswerRaw(requestParameters: CreateQuestionnaireAnswerRequest): Promise<runtime.ApiResponse<ApiResponse26>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/questionnaire-answer`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: QuestionnaireAnswerModelToJSON(requestParameters.body),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResponse26FromJSON(jsonValue));
    }

    /**
     * Add a new QuestionnaireAnswer
     * Add a new QuestionnaireAnswer
     */
    createQuestionnaireAnswer = async(requestParameters: CreateQuestionnaireAnswerRequest): Promise<ApiResponse26> => {
        const response = await this.createQuestionnaireAnswerRaw(requestParameters);
        return await response.value();
    }

    /**
     * Delete an QuestionnaireAnswer entry
     * Delete an QuestionnaireAnswer entry
     */
    async deleteQuestionnaireAnswerRaw(requestParameters: DeleteQuestionnaireAnswerRequest): Promise<runtime.ApiResponse<ApiResponse24>> {
        if (requestParameters.questionnaireAnswerId === null || requestParameters.questionnaireAnswerId === undefined) {
            throw new runtime.RequiredError('questionnaireAnswerId','Required parameter requestParameters.questionnaireAnswerId was null or undefined when calling deleteQuestionnaireAnswer.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/questionnaire-answer/{questionnaire-answerId}`.replace(`{${"questionnaireAnswerId"}}`, encodeURIComponent(String(requestParameters.questionnaireAnswerId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResponse24FromJSON(jsonValue));
    }

    /**
     * Delete an QuestionnaireAnswer entry
     * Delete an QuestionnaireAnswer entry
     */
    deleteQuestionnaireAnswer = async(requestParameters: DeleteQuestionnaireAnswerRequest): Promise<ApiResponse24> => {
        const response = await this.deleteQuestionnaireAnswerRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieve List of QuestionnaireAnswer
     * Retrieve List of QuestionnaireAnswer
     */
    async retrieveListQuestionnaireAnswerRaw(requestParameters: RetrieveListQuestionnaireAnswerRequest): Promise<runtime.ApiResponse<QuestionnaireAnswerListResponse>> {
        if (requestParameters.arguments === null || requestParameters.arguments === undefined) {
            throw new runtime.RequiredError('arguments','Required parameter requestParameters.arguments was null or undefined when calling retrieveListQuestionnaireAnswer.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/questionnaire-answer`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: QuestionnaireAnswerRetrieveListArgumentsToJSON(requestParameters.arguments),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => QuestionnaireAnswerListResponseFromJSON(jsonValue));
    }

    /**
     * Retrieve List of QuestionnaireAnswer
     * Retrieve List of QuestionnaireAnswer
     */
    retrieveListQuestionnaireAnswer = async(requestParameters: RetrieveListQuestionnaireAnswerRequest): Promise<QuestionnaireAnswerListResponse> => {
        const response = await this.retrieveListQuestionnaireAnswerRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieve an QuestionnaireAnswer
     * Retrieve a QuestionnaireAnswer
     */
    async retrieveQuestionnaireAnswerRaw(requestParameters: RetrieveQuestionnaireAnswerRequest): Promise<runtime.ApiResponse<QuestionnaireAnswerModel>> {
        if (requestParameters.questionnaireAnswerId === null || requestParameters.questionnaireAnswerId === undefined) {
            throw new runtime.RequiredError('questionnaireAnswerId','Required parameter requestParameters.questionnaireAnswerId was null or undefined when calling retrieveQuestionnaireAnswer.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/questionnaire-answer/{questionnaire-answerId}`.replace(`{${"questionnaireAnswerId"}}`, encodeURIComponent(String(requestParameters.questionnaireAnswerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => QuestionnaireAnswerModelFromJSON(jsonValue));
    }

    /**
     * Retrieve an QuestionnaireAnswer
     * Retrieve a QuestionnaireAnswer
     */
    retrieveQuestionnaireAnswer = async(requestParameters: RetrieveQuestionnaireAnswerRequest): Promise<QuestionnaireAnswerModel> => {
        const response = await this.retrieveQuestionnaireAnswerRaw(requestParameters);
        return await response.value();
    }

    /**
     * Set (create or update) a QuestionnaireAnswer. If id or links are set in the data, and a corresponding QuestionnaireAnswer is found in Bumbal, an update will be performed.
     * Set (create or update) a QuestionnaireAnswer
     */
    async setQuestionnaireAnswerRaw(requestParameters: SetQuestionnaireAnswerRequest): Promise<runtime.ApiResponse<ModelApiResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/questionnaire-answer/set`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: QuestionnaireAnswerModelToJSON(requestParameters.body),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelApiResponseFromJSON(jsonValue));
    }

    /**
     * Set (create or update) a QuestionnaireAnswer. If id or links are set in the data, and a corresponding QuestionnaireAnswer is found in Bumbal, an update will be performed.
     * Set (create or update) a QuestionnaireAnswer
     */
    setQuestionnaireAnswer = async(requestParameters: SetQuestionnaireAnswerRequest): Promise<ModelApiResponse> => {
        const response = await this.setQuestionnaireAnswerRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update a specific QuestionnaireAnswer object
     * Update a specific QuestionnaireAnswer object
     */
    async updateQuestionnaireAnswerRaw(requestParameters: UpdateQuestionnaireAnswerRequest): Promise<runtime.ApiResponse<ApiResponse23>> {
        if (requestParameters.questionnaireAnswerId === null || requestParameters.questionnaireAnswerId === undefined) {
            throw new runtime.RequiredError('questionnaireAnswerId','Required parameter requestParameters.questionnaireAnswerId was null or undefined when calling updateQuestionnaireAnswer.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/questionnaire-answer/{questionnaire-answerId}`.replace(`{${"questionnaireAnswerId"}}`, encodeURIComponent(String(requestParameters.questionnaireAnswerId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: QuestionnaireAnswerModelToJSON(requestParameters.body),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResponse23FromJSON(jsonValue));
    }

    /**
     * Update a specific QuestionnaireAnswer object
     * Update a specific QuestionnaireAnswer object
     */
    updateQuestionnaireAnswer = async(requestParameters: UpdateQuestionnaireAnswerRequest): Promise<ApiResponse23> => {
        const response = await this.updateQuestionnaireAnswerRaw(requestParameters);
        return await response.value();
    }

}
