/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal Client Api
 * Bumbal API documentation
 *
 * The version of the OpenAPI document: 2.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiResponse13,
    ApiResponse13FromJSON,
    ApiResponse13ToJSON,
    ApiResponse14,
    ApiResponse14FromJSON,
    ApiResponse14ToJSON,
    ApiResponse15,
    ApiResponse15FromJSON,
    ApiResponse15ToJSON,
    SystemProviderListResponse,
    SystemProviderListResponseFromJSON,
    SystemProviderListResponseToJSON,
    SystemProviderModel,
    SystemProviderModelFromJSON,
    SystemProviderModelToJSON,
    SystemProviderRetrieveListArguments,
    SystemProviderRetrieveListArgumentsFromJSON,
    SystemProviderRetrieveListArgumentsToJSON,
} from '../models';

export interface RetrieveListSystemProviderRequest {
    arguments: SystemProviderRetrieveListArguments;
}

export interface RetrieveSystemProviderRequest {
    providerId: number;
}

/**
 * 
 */
export class SystemProviderApi extends runtime.BaseAPI {

    /**
     * Retrieve List of System Providers
     * Retrieve List of System Providers
     */
    async retrieveListSystemProviderRaw(requestParameters: RetrieveListSystemProviderRequest): Promise<runtime.ApiResponse<SystemProviderListResponse>> {
        if (requestParameters.arguments === null || requestParameters.arguments === undefined) {
            throw new runtime.RequiredError('arguments','Required parameter requestParameters.arguments was null or undefined when calling retrieveListSystemProvider.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/system-provider`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SystemProviderRetrieveListArgumentsToJSON(requestParameters.arguments),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SystemProviderListResponseFromJSON(jsonValue));
    }

    /**
     * Retrieve List of System Providers
     * Retrieve List of System Providers
     */
    retrieveListSystemProvider = async(requestParameters: RetrieveListSystemProviderRequest): Promise<SystemProviderListResponse> => {
        const response = await this.retrieveListSystemProviderRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieve a System Provider
     * Retrieve a System Provider
     */
    async retrieveSystemProviderRaw(requestParameters: RetrieveSystemProviderRequest): Promise<runtime.ApiResponse<SystemProviderModel>> {
        if (requestParameters.providerId === null || requestParameters.providerId === undefined) {
            throw new runtime.RequiredError('providerId','Required parameter requestParameters.providerId was null or undefined when calling retrieveSystemProvider.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/system-provider/{providerId}`.replace(`{${"providerId"}}`, encodeURIComponent(String(requestParameters.providerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SystemProviderModelFromJSON(jsonValue));
    }

    /**
     * Retrieve a System Provider
     * Retrieve a System Provider
     */
    retrieveSystemProvider = async(requestParameters: RetrieveSystemProviderRequest): Promise<SystemProviderModel> => {
        const response = await this.retrieveSystemProviderRaw(requestParameters);
        return await response.value();
    }

}
