/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal Client Api
 * Bumbal API documentation
 *
 * The version of the OpenAPI document: 2.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ModelApiResponse,
    ModelApiResponseFromJSON,
    ModelApiResponseToJSON,
    TagTypeModel,
    TagTypeModelFromJSON,
    TagTypeModelToJSON,
    TagTypeRetrieveListArguments,
    TagTypeRetrieveListArgumentsFromJSON,
    TagTypeRetrieveListArgumentsToJSON,
    TagTypeRetrieveResponse,
    TagTypeRetrieveResponseFromJSON,
    TagTypeRetrieveResponseToJSON,
} from '../models';

export interface CreateTagTypeRequest {
    body: TagTypeModel;
}

export interface DeleteTagTypeRequest {
    tagTypeId: number;
}

export interface RetrieveListTagTypeRequest {
    arguments: TagTypeRetrieveListArguments;
}

export interface RetrieveTagTypeRequest {
    tagTypeId: number;
    includeObjectTypes?: boolean;
}

export interface SetTagTypeRequest {
    body?: TagTypeModel;
}

export interface UpdateTagTypeRequest {
    tagTypeId: number;
}

/**
 * 
 */
export class TagTypeApi extends runtime.BaseAPI {

    /**
     * Add a new Tag type
     * Add a new Tag type
     */
    async createTagTypeRaw(requestParameters: CreateTagTypeRequest): Promise<runtime.ApiResponse<ModelApiResponse>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling createTagType.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/tag-type`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TagTypeModelToJSON(requestParameters.body),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelApiResponseFromJSON(jsonValue));
    }

    /**
     * Add a new Tag type
     * Add a new Tag type
     */
    createTagType = async(requestParameters: CreateTagTypeRequest): Promise<ModelApiResponse> => {
        const response = await this.createTagTypeRaw(requestParameters);
        return await response.value();
    }

    /**
     * Delete a Tag type
     * Delete a Tag type
     */
    async deleteTagTypeRaw(requestParameters: DeleteTagTypeRequest): Promise<runtime.ApiResponse<ModelApiResponse>> {
        if (requestParameters.tagTypeId === null || requestParameters.tagTypeId === undefined) {
            throw new runtime.RequiredError('tagTypeId','Required parameter requestParameters.tagTypeId was null or undefined when calling deleteTagType.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/tag-type/{tagTypeId}`.replace(`{${"tagTypeId"}}`, encodeURIComponent(String(requestParameters.tagTypeId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelApiResponseFromJSON(jsonValue));
    }

    /**
     * Delete a Tag type
     * Delete a Tag type
     */
    deleteTagType = async(requestParameters: DeleteTagTypeRequest): Promise<ModelApiResponse> => {
        const response = await this.deleteTagTypeRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieve List of Tags
     * Retrieve List of Tag types
     */
    async retrieveListTagTypeRaw(requestParameters: RetrieveListTagTypeRequest): Promise<runtime.ApiResponse<TagTypeRetrieveResponse>> {
        if (requestParameters.arguments === null || requestParameters.arguments === undefined) {
            throw new runtime.RequiredError('arguments','Required parameter requestParameters.arguments was null or undefined when calling retrieveListTagType.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/tag-type`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: TagTypeRetrieveListArgumentsToJSON(requestParameters.arguments),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TagTypeRetrieveResponseFromJSON(jsonValue));
    }

    /**
     * Retrieve List of Tags
     * Retrieve List of Tag types
     */
    retrieveListTagType = async(requestParameters: RetrieveListTagTypeRequest): Promise<TagTypeRetrieveResponse> => {
        const response = await this.retrieveListTagTypeRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieve an Tag type
     * Retrieve a Tag type
     */
    async retrieveTagTypeRaw(requestParameters: RetrieveTagTypeRequest): Promise<runtime.ApiResponse<TagTypeModel>> {
        if (requestParameters.tagTypeId === null || requestParameters.tagTypeId === undefined) {
            throw new runtime.RequiredError('tagTypeId','Required parameter requestParameters.tagTypeId was null or undefined when calling retrieveTagType.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.includeObjectTypes !== undefined) {
            queryParameters['include_object_types'] = requestParameters.includeObjectTypes;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/tag-type/{tagTypeId}`.replace(`{${"tagTypeId"}}`, encodeURIComponent(String(requestParameters.tagTypeId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TagTypeModelFromJSON(jsonValue));
    }

    /**
     * Retrieve an Tag type
     * Retrieve a Tag type
     */
    retrieveTagType = async(requestParameters: RetrieveTagTypeRequest): Promise<TagTypeModel> => {
        const response = await this.retrieveTagTypeRaw(requestParameters);
        return await response.value();
    }

    /**
     * Set (create or update) Tag type. If a tag type with same name is found in Bumbal, the tag type id is returned.
     * Set (create or update) Tag type
     */
    async setTagTypeRaw(requestParameters: SetTagTypeRequest): Promise<runtime.ApiResponse<ModelApiResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/tag-type/set`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TagTypeModelToJSON(requestParameters.body),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelApiResponseFromJSON(jsonValue));
    }

    /**
     * Set (create or update) Tag type. If a tag type with same name is found in Bumbal, the tag type id is returned.
     * Set (create or update) Tag type
     */
    setTagType = async(requestParameters: SetTagTypeRequest): Promise<ModelApiResponse> => {
        const response = await this.setTagTypeRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update a Tag type
     * Update a Tag type
     */
    async updateTagTypeRaw(requestParameters: UpdateTagTypeRequest): Promise<runtime.ApiResponse<ModelApiResponse>> {
        if (requestParameters.tagTypeId === null || requestParameters.tagTypeId === undefined) {
            throw new runtime.RequiredError('tagTypeId','Required parameter requestParameters.tagTypeId was null or undefined when calling updateTagType.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/tag-type/{tagTypeId}`.replace(`{${"tagTypeId"}}`, encodeURIComponent(String(requestParameters.tagTypeId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelApiResponseFromJSON(jsonValue));
    }

    /**
     * Update a Tag type
     * Update a Tag type
     */
    updateTagType = async(requestParameters: UpdateTagTypeRequest): Promise<ModelApiResponse> => {
        const response = await this.updateTagTypeRaw(requestParameters);
        return await response.value();
    }

}
