/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal Client Api
 * Bumbal API documentation
 *
 * The version of the OpenAPI document: 2.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiResponse401,
    ApiResponse401FromJSON,
    ApiResponse401ToJSON,
    ApiResponse403,
    ApiResponse403FromJSON,
    ApiResponse403ToJSON,
    ApiResponse405,
    ApiResponse405FromJSON,
    ApiResponse405ToJSON,
    BlockedDateListResponse,
    BlockedDateListResponseFromJSON,
    BlockedDateListResponseToJSON,
    BlockedDateModel,
    BlockedDateModelFromJSON,
    BlockedDateModelToJSON,
    BlockedDateRetrieveListArguments,
    BlockedDateRetrieveListArgumentsFromJSON,
    BlockedDateRetrieveListArgumentsToJSON,
    ModelApiResponse,
    ModelApiResponseFromJSON,
    ModelApiResponseToJSON,
} from '../models';

export interface DeleteBlockedDateRequest {
    blockedDateId: number;
}

export interface RetrieveListBlockedDateRequest {
    arguments: BlockedDateRetrieveListArguments;
}

export interface SetBlockedDateRequest {
    body?: BlockedDateModel;
}

/**
 * 
 */
export class BlockedDateApi extends runtime.BaseAPI {

    /**
     * Delete a blocked date
     * Delete a blocked date
     */
    async deleteBlockedDateRaw(requestParameters: DeleteBlockedDateRequest): Promise<runtime.ApiResponse<ModelApiResponse>> {
        if (requestParameters.blockedDateId === null || requestParameters.blockedDateId === undefined) {
            throw new runtime.RequiredError('blockedDateId','Required parameter requestParameters.blockedDateId was null or undefined when calling deleteBlockedDate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/blocked-date/{blockedDateId}`.replace(`{${"blockedDateId"}}`, encodeURIComponent(String(requestParameters.blockedDateId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelApiResponseFromJSON(jsonValue));
    }

    /**
     * Delete a blocked date
     * Delete a blocked date
     */
    deleteBlockedDate = async(requestParameters: DeleteBlockedDateRequest): Promise<ModelApiResponse> => {
        const response = await this.deleteBlockedDateRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieve List of blocked dates
     * Retrieve List of blocked dates
     */
    async retrieveListBlockedDateRaw(requestParameters: RetrieveListBlockedDateRequest): Promise<runtime.ApiResponse<BlockedDateListResponse>> {
        if (requestParameters.arguments === null || requestParameters.arguments === undefined) {
            throw new runtime.RequiredError('arguments','Required parameter requestParameters.arguments was null or undefined when calling retrieveListBlockedDate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/blocked-date`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: BlockedDateRetrieveListArgumentsToJSON(requestParameters.arguments),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BlockedDateListResponseFromJSON(jsonValue));
    }

    /**
     * Retrieve List of blocked dates
     * Retrieve List of blocked dates
     */
    retrieveListBlockedDate = async(requestParameters: RetrieveListBlockedDateRequest): Promise<BlockedDateListResponse> => {
        const response = await this.retrieveListBlockedDateRaw(requestParameters);
        return await response.value();
    }

    /**
     * Set (create or update) a blocked date.
     * Set (create or update) a blocked date
     */
    async setBlockedDateRaw(requestParameters: SetBlockedDateRequest): Promise<runtime.ApiResponse<ModelApiResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/blocked-date/set`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BlockedDateModelToJSON(requestParameters.body),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelApiResponseFromJSON(jsonValue));
    }

    /**
     * Set (create or update) a blocked date.
     * Set (create or update) a blocked date
     */
    setBlockedDate = async(requestParameters: SetBlockedDateRequest): Promise<ModelApiResponse> => {
        const response = await this.setBlockedDateRaw(requestParameters);
        return await response.value();
    }

}
