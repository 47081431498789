/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal Client Api
 * Bumbal API documentation
 *
 * The version of the OpenAPI document: 2.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel } from '../runtime';
import {
    BrandColourModel,
    BrandColourModelFromJSON,
    BrandColourModelFromJSONTyped,
    BrandColourModelToJSON,
    FileModel,
    FileModelFromJSON,
    FileModelFromJSONTyped,
    FileModelToJSON,
    LinkModel,
    LinkModelFromJSON,
    LinkModelFromJSONTyped,
    LinkModelToJSON,
    MetaDataModel,
    MetaDataModelFromJSON,
    MetaDataModelFromJSONTyped,
    MetaDataModelToJSON,
    PortalSettingModel,
    PortalSettingModelFromJSON,
    PortalSettingModelFromJSONTyped,
    PortalSettingModelToJSON,
    TagModel,
    TagModelFromJSON,
    TagModelFromJSONTyped,
    TagModelToJSON,
    UsersModel,
    UsersModelFromJSON,
    UsersModelFromJSONTyped,
    UsersModelToJSON,
} from './';


export interface BrandModelInterface {
    id?: number;
    name?: string;
    tags?: Array<TagModel>;
    links?: Array<LinkModel>;
    meta_data?: Array<MetaDataModel>;
    brand_colours?: Array<BrandColourModel>;
    files?: Array<FileModel>;
    portal_settings?: Array<PortalSettingModel>;
    brand_created_by?: UsersModel;
    brand_updated_by?: UsersModel;
    brand_created_at?: Date;
    brand_updated_at?: Date;
}

/**
 * 
 * @export
 * @interface BrandModel
 */
export class BrandModel extends BaseModel<BrandModelInterface> {

    /**
     * Unique Identifier
     * @type {number}
     * @memberof BrandModel
     */
    id?: number;
    /**
     * Name of brand
     * @type {string}
     * @memberof BrandModel
     */
    name?: string;
    /**
     * 
     * @type {Array<TagModel>}
     * @memberof BrandModel
     */
    tags?: Array<TagModel>;
    /**
     * 
     * @type {Array<LinkModel>}
     * @memberof BrandModel
     */
    links?: Array<LinkModel>;
    /**
     * 
     * @type {Array<MetaDataModel>}
     * @memberof BrandModel
     */
    meta_data?: Array<MetaDataModel>;
    /**
     * 
     * @type {Array<BrandColourModel>}
     * @memberof BrandModel
     */
    brand_colours?: Array<BrandColourModel>;
    /**
     * 
     * @type {Array<FileModel>}
     * @memberof BrandModel
     */
    files?: Array<FileModel>;
    /**
     * 
     * @type {Array<PortalSettingModel>}
     * @memberof BrandModel
     */
    portal_settings?: Array<PortalSettingModel>;
    /**
     * 
     * @type {UsersModel}
     * @memberof BrandModel
     */
    brand_created_by?: UsersModel;
    /**
     * 
     * @type {UsersModel}
     * @memberof BrandModel
     */
    brand_updated_by?: UsersModel;
    /**
     * created_at date time
     * @type {Date}
     * @memberof BrandModel
     */
    brand_created_at?: Date;
    /**
     * updated_at date time
     * @type {Date}
     * @memberof BrandModel
     */
    brand_updated_at?: Date;

    constructor(data?: BrandModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(value: BrandModelInterface): { [property: string]: any } {
        return {};
    }

}

export function BrandModelFromJSON(json: any): BrandModel {
    return BrandModelFromJSONTyped(json, false);
}

export function BrandModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): BrandModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = BrandModel;
    try {
        let localClass = require.context('../override', true)('./BrandModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'id': !exists(json, 'id') ? undefined : Number(json['id']),
        'name': !exists(json, 'name') ? undefined : json['name'],
        'tags': !exists(json, 'tags') ? undefined : ((json['tags'] as Array<any>).map(TagModelFromJSON)),
        'links': !exists(json, 'links') ? undefined : ((json['links'] as Array<any>).map(LinkModelFromJSON)),
        'meta_data': !exists(json, 'meta_data') ? undefined : ((json['meta_data'] as Array<any>).map(MetaDataModelFromJSON)),
        'brand_colours': !exists(json, 'brand_colours') ? undefined : ((json['brand_colours'] as Array<any>).map(BrandColourModelFromJSON)),
        'files': !exists(json, 'files') ? undefined : ((json['files'] as Array<any>).map(FileModelFromJSON)),
        'portal_settings': !exists(json, 'portal_settings') ? undefined : ((json['portal_settings'] as Array<any>).map(PortalSettingModelFromJSON)),
        'brand_created_by': !exists(json, 'brand_created_by') ? undefined : UsersModelFromJSON(json['brand_created_by']),
        'brand_updated_by': !exists(json, 'brand_updated_by') ? undefined : UsersModelFromJSON(json['brand_updated_by']),
        'brand_created_at': !exists(json, 'brand_created_at') ? undefined : (new Date(json['brand_created_at'].replace(' ', 'T'))),
        'brand_updated_at': !exists(json, 'brand_updated_at') ? undefined : (new Date(json['brand_updated_at'].replace(' ', 'T'))),
    });
}

export function BrandModelToJSON(value?: BrandModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = BrandModel;
    try {
        let localClass = require.context('../override', true)('./BrandModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return {
        
        'id': value.id,
        'name': value.name,
        'tags': value.tags === undefined ? undefined : ((value.tags as Array<any>).map(TagModelToJSON)),
        'links': value.links === undefined ? undefined : ((value.links as Array<any>).map(LinkModelToJSON)),
        'meta_data': value.meta_data === undefined ? undefined : ((value.meta_data as Array<any>).map(MetaDataModelToJSON)),
        'brand_colours': value.brand_colours === undefined ? undefined : ((value.brand_colours as Array<any>).map(BrandColourModelToJSON)),
        'files': value.files === undefined ? undefined : ((value.files as Array<any>).map(FileModelToJSON)),
        'portal_settings': value.portal_settings === undefined ? undefined : ((value.portal_settings as Array<any>).map(PortalSettingModelToJSON)),
        'brand_created_by': UsersModelToJSON(value.brand_created_by),
        'brand_updated_by': UsersModelToJSON(value.brand_updated_by),
        'brand_created_at': value.brand_created_at === undefined ? undefined : (value.brand_created_at.toApiFormat()),
        'brand_updated_at': value.brand_updated_at === undefined ? undefined : (value.brand_updated_at.toApiFormat()),
        ...createClass.toJson(value),
    };
}


