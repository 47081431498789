/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal Client Api
 * Bumbal API documentation
 *
 * The version of the OpenAPI document: 2.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel } from '../runtime';

export interface MetaDataModelInterface {
    id?: number;
    name?: string;
    value?: string;
    object_id?: number;
    object_type_name?: string;
    created_at?: Date;
    updated_at?: Date;
}

/**
 * 
 * @export
 * @interface MetaDataModel
 */
export class MetaDataModel extends BaseModel<MetaDataModelInterface> {

    /**
     * Unique ID
     * @type {number}
     * @memberof MetaDataModel
     */
    id?: number;
    /**
     * The name of the MetaData field
     * @type {string}
     * @memberof MetaDataModel
     */
    name?: string;
    /**
     * The value of the MetaData field
     * @type {string}
     * @memberof MetaDataModel
     */
    value?: string;
    /**
     * Object ID
     * @type {number}
     * @memberof MetaDataModel
     */
    object_id?: number;
    /**
     * Object type name
     * @type {string}
     * @memberof MetaDataModel
     */
    object_type_name?: string;
    /**
     * created_at date time
     * @type {Date}
     * @memberof MetaDataModel
     */
    created_at?: Date;
    /**
     * updated_at date time
     * @type {Date}
     * @memberof MetaDataModel
     */
    updated_at?: Date;

    constructor(data?: MetaDataModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(value: MetaDataModelInterface): { [property: string]: any } {
        return {};
    }

}

export function MetaDataModelFromJSON(json: any): MetaDataModel {
    return MetaDataModelFromJSONTyped(json, false);
}

export function MetaDataModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): MetaDataModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = MetaDataModel;
    try {
        let localClass = require.context('../override', true)('./MetaDataModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'id': !exists(json, 'id') ? undefined : Number(json['id']),
        'name': !exists(json, 'name') ? undefined : json['name'],
        'value': !exists(json, 'value') ? undefined : json['value'],
        'object_id': !exists(json, 'object_id') ? undefined : Number(json['object_id']),
        'object_type_name': !exists(json, 'object_type_name') ? undefined : json['object_type_name'],
        'created_at': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'].replace(' ', 'T'))),
        'updated_at': !exists(json, 'updated_at') ? undefined : (new Date(json['updated_at'].replace(' ', 'T'))),
    });
}

export function MetaDataModelToJSON(value?: MetaDataModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = MetaDataModel;
    try {
        let localClass = require.context('../override', true)('./MetaDataModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return {
        
        'id': value.id,
        'name': value.name,
        'value': value.value,
        'object_id': value.object_id,
        'object_type_name': value.object_type_name,
        'created_at': value.created_at === undefined ? undefined : (value.created_at.toApiFormat()),
        'updated_at': value.updated_at === undefined ? undefined : (value.updated_at.toApiFormat()),
        ...createClass.toJson(value),
    };
}


