/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal Client Api
 * Bumbal API documentation
 *
 * The version of the OpenAPI document: 2.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel } from '../runtime';

export interface CommunicationModelInterface {
    id?: number;
    saywhen?: boolean;
    bumbal?: boolean;
    send_invite?: boolean;
    send_reminder?: boolean;
    send_pref_confirmation?: boolean;
    send_planned?: boolean;
    send_eta?: boolean;
    send_executed?: boolean;
    send_cancelled?: boolean;
    email?: string;
    phone_nr?: string;
    saywhen_status_id?: number;
    saywhen_status_name?: CommunicationModelSaywhenStatusNameEnum;
}

/**
 * 
 * @export
 * @interface CommunicationModel
 */
export class CommunicationModel extends BaseModel<CommunicationModelInterface> {

    /**
     * 
     * @type {number}
     * @memberof CommunicationModel
     */
    id?: number;
    /**
     * Whether or not activity should be synced with Saywhen
     * @type {boolean}
     * @memberof CommunicationModel
     */
    saywhen?: boolean;
    /**
     * Whether or not activity is handled by Bumbal Communication Server
     * @type {boolean}
     * @memberof CommunicationModel
     */
    bumbal?: boolean;
    /**
     * Send invite
     * @type {boolean}
     * @memberof CommunicationModel
     */
    send_invite?: boolean;
    /**
     * Send reminder
     * @type {boolean}
     * @memberof CommunicationModel
     */
    send_reminder?: boolean;
    /**
     * Send confirmation on preferences received
     * @type {boolean}
     * @memberof CommunicationModel
     */
    send_pref_confirmation?: boolean;
    /**
     * Send planned notice
     * @type {boolean}
     * @memberof CommunicationModel
     */
    send_planned?: boolean;
    /**
     * Send eta notice
     * @type {boolean}
     * @memberof CommunicationModel
     */
    send_eta?: boolean;
    /**
     * Send executed notice
     * @type {boolean}
     * @memberof CommunicationModel
     */
    send_executed?: boolean;
    /**
     * Send cancelled notice
     * @type {boolean}
     * @memberof CommunicationModel
     */
    send_cancelled?: boolean;
    /**
     * Email for customer communication
     * @type {string}
     * @memberof CommunicationModel
     */
    email?: string;
    /**
     * Phone nr for customer communication
     * @type {string}
     * @memberof CommunicationModel
     */
    phone_nr?: string;
    /**
     * Saywhen StatusId of this Activity, 1:cancelled, 2:offered, 3:preffed, 4:confirmed, 5:accepted, 6:planned, 7:scheduled, 8:started, 9:completed
     * @type {number}
     * @memberof CommunicationModel
     */
    saywhen_status_id?: number;
    /**
     * Saywhen Status name
     * @type {string}
     * @memberof CommunicationModel
     */
    saywhen_status_name?: CommunicationModelSaywhenStatusNameEnum;

    constructor(data?: CommunicationModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(value: CommunicationModelInterface): { [property: string]: any } {
        return {};
    }

}

export function CommunicationModelFromJSON(json: any): CommunicationModel {
    return CommunicationModelFromJSONTyped(json, false);
}

export function CommunicationModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): CommunicationModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = CommunicationModel;
    try {
        let localClass = require.context('../override', true)('./CommunicationModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'id': !exists(json, 'id') ? undefined : Number(json['id']),
        'saywhen': !exists(json, 'saywhen') ? undefined : json['saywhen'],
        'bumbal': !exists(json, 'bumbal') ? undefined : json['bumbal'],
        'send_invite': !exists(json, 'send_invite') ? undefined : json['send_invite'],
        'send_reminder': !exists(json, 'send_reminder') ? undefined : json['send_reminder'],
        'send_pref_confirmation': !exists(json, 'send_pref_confirmation') ? undefined : json['send_pref_confirmation'],
        'send_planned': !exists(json, 'send_planned') ? undefined : json['send_planned'],
        'send_eta': !exists(json, 'send_eta') ? undefined : json['send_eta'],
        'send_executed': !exists(json, 'send_executed') ? undefined : json['send_executed'],
        'send_cancelled': !exists(json, 'send_cancelled') ? undefined : json['send_cancelled'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'phone_nr': !exists(json, 'phone_nr') ? undefined : json['phone_nr'],
        'saywhen_status_id': !exists(json, 'saywhen_status_id') ? undefined : Number(json['saywhen_status_id']),
        'saywhen_status_name': !exists(json, 'saywhen_status_name') ? undefined : json['saywhen_status_name'],
    });
}

export function CommunicationModelToJSON(value?: CommunicationModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = CommunicationModel;
    try {
        let localClass = require.context('../override', true)('./CommunicationModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return {
        
        'id': value.id,
        'saywhen': value.saywhen,
        'bumbal': value.bumbal,
        'send_invite': value.send_invite,
        'send_reminder': value.send_reminder,
        'send_pref_confirmation': value.send_pref_confirmation,
        'send_planned': value.send_planned,
        'send_eta': value.send_eta,
        'send_executed': value.send_executed,
        'send_cancelled': value.send_cancelled,
        'email': value.email,
        'phone_nr': value.phone_nr,
        'saywhen_status_id': value.saywhen_status_id,
        'saywhen_status_name': value.saywhen_status_name,
        ...createClass.toJson(value),
    };
}

/**
* @export
* @enum {string}
*/
export enum CommunicationModelSaywhenStatusNameEnum {
    Cancelled = 'cancelled',
    Offered = 'offered',
    Preffed = 'preffed',
    Confirmed = 'confirmed',
    Accepted = 'accepted',
    Planned = 'planned',
    Scheduled = 'scheduled',
    Started = 'started',
    Completed = 'completed'
}


