/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal Client Api
 * Bumbal API documentation
 *
 * The version of the OpenAPI document: 2.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel } from '../runtime';

export interface PhoneNrModelInterface {
    id?: number;
    address_id?: number;
    country_code?: string;
    phone_nr_type_name?: PhoneNrModelPhoneNrTypeNameEnum;
    nr?: string;
    description?: string;
    primary?: boolean;
}

/**
 * 
 * @export
 * @interface PhoneNrModel
 */
export class PhoneNrModel extends BaseModel<PhoneNrModelInterface> {

    /**
     * Unique ID
     * @type {number}
     * @memberof PhoneNrModel
     */
    id?: number;
    /**
     * Address ID asociated with this email address
     * @type {number}
     * @memberof PhoneNrModel
     */
    address_id?: number;
    /**
     * Phone number Country Code
     * @type {string}
     * @memberof PhoneNrModel
     */
    country_code?: string;
    /**
     * Phone number type name
     * @type {string}
     * @memberof PhoneNrModel
     */
    phone_nr_type_name?: PhoneNrModelPhoneNrTypeNameEnum;
    /**
     * Phone number
     * @type {string}
     * @memberof PhoneNrModel
     */
    nr?: string;
    /**
     * Phone number description
     * @type {string}
     * @memberof PhoneNrModel
     */
    description?: string;
    /**
     * primary phone number
     * @type {boolean}
     * @memberof PhoneNrModel
     */
    primary?: boolean;

    constructor(data?: PhoneNrModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(value: PhoneNrModelInterface): { [property: string]: any } {
        return {};
    }

}

export function PhoneNrModelFromJSON(json: any): PhoneNrModel {
    return PhoneNrModelFromJSONTyped(json, false);
}

export function PhoneNrModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): PhoneNrModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = PhoneNrModel;
    try {
        let localClass = require.context('../override', true)('./PhoneNrModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'id': !exists(json, 'id') ? undefined : Number(json['id']),
        'address_id': !exists(json, 'address_id') ? undefined : Number(json['address_id']),
        'country_code': !exists(json, 'country_code') ? undefined : json['country_code'],
        'phone_nr_type_name': !exists(json, 'phone_nr_type_name') ? undefined : json['phone_nr_type_name'],
        'nr': !exists(json, 'nr') ? undefined : json['nr'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'primary': !exists(json, 'primary') ? undefined : json['primary'],
    });
}

export function PhoneNrModelToJSON(value?: PhoneNrModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = PhoneNrModel;
    try {
        let localClass = require.context('../override', true)('./PhoneNrModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return {
        
        'id': value.id,
        'address_id': value.address_id,
        'country_code': value.country_code,
        'phone_nr_type_name': value.phone_nr_type_name,
        'nr': value.nr,
        'description': value.description,
        'primary': value.primary,
        ...createClass.toJson(value),
    };
}

/**
* @export
* @enum {string}
*/
export enum PhoneNrModelPhoneNrTypeNameEnum {
    Mobile = 'mobile',
    Landline = 'landline',
    Fax = 'fax'
}


