/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal Client Api
 * Bumbal API documentation
 *
 * The version of the OpenAPI document: 2.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel } from '../runtime';
import {
    UsersModel,
    UsersModelFromJSON,
    UsersModelFromJSONTyped,
    UsersModelToJSON,
    ValueOptionModel,
    ValueOptionModelFromJSON,
    ValueOptionModelFromJSONTyped,
    ValueOptionModelToJSON,
} from './';


export interface SettingsModelInterface {
    id?: number;
    settings_group_id?: number;
    settings_group_name?: SettingsModelSettingsGroupNameEnum;
    key?: string;
    value?: string;
    value_options?: Array<ValueOptionModel>;
    setting_updated_at?: Date;
    setting_updated_by?: number;
    setting_updated_by_user?: UsersModel;
}

/**
 * 
 * @export
 * @interface SettingsModel
 */
export class SettingsModel extends BaseModel<SettingsModelInterface> {

    /**
     * 
     * @type {number}
     * @memberof SettingsModel
     */
    id?: number;
    /**
     * SettingsGroup id of this setting. Possible values: 1: general, 2: address, 3: package, 4: activity, 5: equipment, 6: note, 7: optimisation, 8: filters
     * @type {number}
     * @memberof SettingsModel
     */
    settings_group_id?: number;
    /**
     * SettingsGroup name of this setting
     * @type {string}
     * @memberof SettingsModel
     */
    settings_group_name?: SettingsModelSettingsGroupNameEnum;
    /**
     * Unique string key for setting identification
     * @type {string}
     * @memberof SettingsModel
     */
    key?: string;
    /**
     * Set value for setting
     * @type {string}
     * @memberof SettingsModel
     */
    value?: string;
    /**
     * 
     * @type {Array<ValueOptionModel>}
     * @memberof SettingsModel
     */
    value_options?: Array<ValueOptionModel>;
    /**
     * updated_at date time
     * @type {Date}
     * @memberof SettingsModel
     */
    setting_updated_at?: Date;
    /**
     * updated_by user id
     * @type {number}
     * @memberof SettingsModel
     */
    setting_updated_by?: number;
    /**
     * 
     * @type {UsersModel}
     * @memberof SettingsModel
     */
    setting_updated_by_user?: UsersModel;

    constructor(data?: SettingsModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(value: SettingsModelInterface): { [property: string]: any } {
        return {};
    }

}

export function SettingsModelFromJSON(json: any): SettingsModel {
    return SettingsModelFromJSONTyped(json, false);
}

export function SettingsModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): SettingsModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = SettingsModel;
    try {
        let localClass = require.context('../override', true)('./SettingsModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'id': !exists(json, 'id') ? undefined : Number(json['id']),
        'settings_group_id': !exists(json, 'settings_group_id') ? undefined : Number(json['settings_group_id']),
        'settings_group_name': !exists(json, 'settings_group_name') ? undefined : json['settings_group_name'],
        'key': !exists(json, 'key') ? undefined : json['key'],
        'value': !exists(json, 'value') ? undefined : json['value'],
        'value_options': !exists(json, 'value_options') ? undefined : ((json['value_options'] as Array<any>).map(ValueOptionModelFromJSON)),
        'setting_updated_at': !exists(json, 'setting_updated_at') ? undefined : (new Date(json['setting_updated_at'].replace(' ', 'T'))),
        'setting_updated_by': !exists(json, 'setting_updated_by') ? undefined : Number(json['setting_updated_by']),
        'setting_updated_by_user': !exists(json, 'setting_updated_by_user') ? undefined : UsersModelFromJSON(json['setting_updated_by_user']),
    });
}

export function SettingsModelToJSON(value?: SettingsModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = SettingsModel;
    try {
        let localClass = require.context('../override', true)('./SettingsModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return {
        
        'id': value.id,
        'settings_group_id': value.settings_group_id,
        'settings_group_name': value.settings_group_name,
        'key': value.key,
        'value': value.value,
        'value_options': value.value_options === undefined ? undefined : ((value.value_options as Array<any>).map(ValueOptionModelToJSON)),
        'setting_updated_at': value.setting_updated_at === undefined ? undefined : (value.setting_updated_at.toApiFormat()),
        'setting_updated_by': value.setting_updated_by,
        'setting_updated_by_user': UsersModelToJSON(value.setting_updated_by_user),
        ...createClass.toJson(value),
    };
}

/**
* @export
* @enum {string}
*/
export enum SettingsModelSettingsGroupNameEnum {
    General = 'general',
    Address = 'address',
    Package = 'package',
    Activity = 'activity',
    Equipment = 'equipment',
    Note = 'note',
    Optimisation = 'optimisation',
    Filters = 'filters'
}


