import i18n from 'i18next';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { format as formatDate, parseISO } from 'date-fns';
import { de, enGB, fr, nl } from 'date-fns/locale';

const DateFnsLocale = {'de': de, 'en': enGB, 'fr': fr, 'nl': nl};

i18n
    .use(HttpApi)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    .init({
        fallbackLng: 'en',
        debug: true,
        ns: ['translations'],
        defaultNS: 'translations',
        interpolation: {
            format: function(value, format, lng) {
                if(value instanceof Date) return formatDate(value, format, {locale:DateFnsLocale[lng]});
                return value;
            },
            escapeValue: false, // not needed for react as it escapes by default
        },
        react: {
            useSuspense: true
        }
    });

export function format(value, format) {
    let formattedDate = (value instanceof Date) ? value :  parseISO(value);
    return formatDate(formattedDate, format, {locale:DateFnsLocale[i18n.language]})
}

export default i18n;
