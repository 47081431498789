/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal Client Api
 * Bumbal API documentation
 *
 * The version of the OpenAPI document: 2.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel } from '../runtime';
import {
    ActivityModel,
    ActivityModelFromJSON,
    ActivityModelFromJSONTyped,
    ActivityModelToJSON,
    TimeSlotModel,
    TimeSlotModelFromJSON,
    TimeSlotModelFromJSONTyped,
    TimeSlotModelToJSON,
} from './';


export interface AutoPlanResponseInterface {
    token?: string;
    status?: AutoPlanResponseStatusEnum;
    affected_activities?: Array<ActivityModel>;
    unavailable_timewindows?: Array<TimeSlotModel>;
    latest_analyzed_date?: Date;
}

/**
 * 
 * @export
 * @interface AutoPlanResponse
 */
export class AutoPlanResponse extends BaseModel<AutoPlanResponseInterface> {

    /**
     * token for the auto plan job
     * @type {string}
     * @memberof AutoPlanResponse
     */
    token?: string;
    /**
     * current status for request
     * @type {string}
     * @memberof AutoPlanResponse
     */
    status?: AutoPlanResponseStatusEnum;
    /**
     * 
     * @type {Array<ActivityModel>}
     * @memberof AutoPlanResponse
     */
    affected_activities?: Array<ActivityModel>;
    /**
     * 
     * @type {Array<TimeSlotModel>}
     * @memberof AutoPlanResponse
     */
    unavailable_timewindows?: Array<TimeSlotModel>;
    /**
     * 
     * @type {Date}
     * @memberof AutoPlanResponse
     */
    latest_analyzed_date?: Date;

    constructor(data?: AutoPlanResponseInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(value: AutoPlanResponseInterface): { [property: string]: any } {
        return {};
    }

}

export function AutoPlanResponseFromJSON(json: any): AutoPlanResponse {
    return AutoPlanResponseFromJSONTyped(json, false);
}

export function AutoPlanResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): AutoPlanResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = AutoPlanResponse;
    try {
        let localClass = require.context('../override', true)('./AutoPlanResponse');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'token': !exists(json, 'token') ? undefined : json['token'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'affected_activities': !exists(json, 'affected_activities') ? undefined : ((json['affected_activities'] as Array<any>).map(ActivityModelFromJSON)),
        'unavailable_timewindows': !exists(json, 'unavailable_timewindows') ? undefined : ((json['unavailable_timewindows'] as Array<any>).map(TimeSlotModelFromJSON)),
        'latest_analyzed_date': !exists(json, 'latest_analyzed_date') ? undefined : (new Date(json['latest_analyzed_date'].replace(' ', 'T'))),
    });
}

export function AutoPlanResponseToJSON(value?: AutoPlanResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = AutoPlanResponse;
    try {
        let localClass = require.context('../override', true)('./AutoPlanResponse');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return {
        
        'token': value.token,
        'status': value.status,
        'affected_activities': value.affected_activities === undefined ? undefined : ((value.affected_activities as Array<any>).map(ActivityModelToJSON)),
        'unavailable_timewindows': value.unavailable_timewindows === undefined ? undefined : ((value.unavailable_timewindows as Array<any>).map(TimeSlotModelToJSON)),
        'latest_analyzed_date': value.latest_analyzed_date === undefined ? undefined : (value.latest_analyzed_date.toApiFormat().substr(0,10)),
        ...createClass.toJson(value),
    };
}

/**
* @export
* @enum {string}
*/
export enum AutoPlanResponseStatusEnum {
    NotReady = 'not_ready',
    Done = 'done',
    Cancelled = 'cancelled'
}


