/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal Client Api
 * Bumbal API documentation
 *
 * The version of the OpenAPI document: 2.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel } from '../runtime';
import {
    TransactionFiltersModel,
    TransactionFiltersModelFromJSON,
    TransactionFiltersModelFromJSONTyped,
    TransactionFiltersModelToJSON,
    TransactionOptionsModel,
    TransactionOptionsModelFromJSON,
    TransactionOptionsModelFromJSONTyped,
    TransactionOptionsModelToJSON,
} from './';


export interface TransactionRetrieveListArgumentsInterface {
    options?: TransactionOptionsModel;
    filters?: TransactionFiltersModel;
    limit?: number;
    offset?: number;
    sorting_column?: TransactionRetrieveListArgumentsSortingColumnEnum;
    sorting_direction?: TransactionRetrieveListArgumentsSortingDirectionEnum;
    search_text?: string;
}

/**
 * 
 * @export
 * @interface TransactionRetrieveListArguments
 */
export class TransactionRetrieveListArguments extends BaseModel<TransactionRetrieveListArgumentsInterface> {

    /**
     * 
     * @type {TransactionOptionsModel}
     * @memberof TransactionRetrieveListArguments
     */
    options?: TransactionOptionsModel;
    /**
     * 
     * @type {TransactionFiltersModel}
     * @memberof TransactionRetrieveListArguments
     */
    filters?: TransactionFiltersModel;
    /**
     * 
     * @type {number}
     * @memberof TransactionRetrieveListArguments
     */
    limit?: number;
    /**
     * 
     * @type {number}
     * @memberof TransactionRetrieveListArguments
     */
    offset?: number;
    /**
     * Sorting Column
     * @type {string}
     * @memberof TransactionRetrieveListArguments
     */
    sorting_column?: TransactionRetrieveListArgumentsSortingColumnEnum;
    /**
     * Sorting Direction
     * @type {string}
     * @memberof TransactionRetrieveListArguments
     */
    sorting_direction?: TransactionRetrieveListArgumentsSortingDirectionEnum;
    /**
     * 
     * @type {string}
     * @memberof TransactionRetrieveListArguments
     */
    search_text?: string;

    constructor(data?: TransactionRetrieveListArgumentsInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(value: TransactionRetrieveListArgumentsInterface): { [property: string]: any } {
        return {};
    }

}

export function TransactionRetrieveListArgumentsFromJSON(json: any): TransactionRetrieveListArguments {
    return TransactionRetrieveListArgumentsFromJSONTyped(json, false);
}

export function TransactionRetrieveListArgumentsFromJSONTyped(json: any, ignoreDiscriminator: boolean): TransactionRetrieveListArguments {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = TransactionRetrieveListArguments;
    try {
        let localClass = require.context('../override', true)('./TransactionRetrieveListArguments');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'options': !exists(json, 'options') ? undefined : TransactionOptionsModelFromJSON(json['options']),
        'filters': !exists(json, 'filters') ? undefined : TransactionFiltersModelFromJSON(json['filters']),
        'limit': !exists(json, 'limit') ? undefined : Number(json['limit']),
        'offset': !exists(json, 'offset') ? undefined : Number(json['offset']),
        'sorting_column': !exists(json, 'sorting_column') ? undefined : json['sorting_column'],
        'sorting_direction': !exists(json, 'sorting_direction') ? undefined : json['sorting_direction'],
        'search_text': !exists(json, 'search_text') ? undefined : json['search_text'],
    });
}

export function TransactionRetrieveListArgumentsToJSON(value?: TransactionRetrieveListArguments | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = TransactionRetrieveListArguments;
    try {
        let localClass = require.context('../override', true)('./TransactionRetrieveListArguments');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return {
        
        'options': TransactionOptionsModelToJSON(value.options),
        'filters': TransactionFiltersModelToJSON(value.filters),
        'limit': value.limit,
        'offset': value.offset,
        'sorting_column': value.sorting_column,
        'sorting_direction': value.sorting_direction,
        'search_text': value.search_text,
        ...createClass.toJson(value),
    };
}

/**
* @export
* @enum {string}
*/
export enum TransactionRetrieveListArgumentsSortingColumnEnum {
    Id = 'id',
    Name = 'name',
    Description = 'description',
    Subject = 'subject'
}
/**
* @export
* @enum {string}
*/
export enum TransactionRetrieveListArgumentsSortingDirectionEnum {
    ASC = 'ASC',
    DESC = 'DESC'
}


