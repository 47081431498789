import { Grid } from "@mui/material";
import React from "react";
import { Subject } from "../../../../interface/CourseContent";
import SubjectCard from "./SubjectCard";
import { useLocation } from "react-router-dom";
import MetaData from "../../../../api/override/MetaDataModel";

interface SubjectOverviewProps {
    version: number;
    subjects: Subject[];
    data: MetaData;
    course: string;
}

const SubjectOverview = ({ data, course, version, subjects }: SubjectOverviewProps) => {
    const location = useLocation();

    return (
        <Grid
            container
            columnSpacing={5}
            sx={{ px: "10%", mt: 8 }}
        >
            {subjects.map((subject, i) => (
                <Grid item xs={12} sm={6} md={4} sx={{ pb: '60px' }} key={i}>
                    <SubjectCard
                        subject={subject}
                        url={`${location.pathname}/${version}/${i}`}
                        index={i}
                        progress={data.getSubjectProgress(course, version, i)}
                    />
                </Grid>
            ))}
        </Grid>
    );
}

export default SubjectOverview;