/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal Client Api
 * Bumbal API documentation
 *
 * The version of the OpenAPI document: 2.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel } from '../runtime';
import {
    ActivityFiltersModel,
    ActivityFiltersModelFromJSON,
    ActivityFiltersModelFromJSONTyped,
    ActivityFiltersModelToJSON,
} from './';


export interface SayWhenRetrievePortalURLsArgumentsInterface {
    filters?: ActivityFiltersModel;
    limit?: number;
    offset?: number;
    sorting_column?: SayWhenRetrievePortalURLsArgumentsSortingColumnEnum;
    sorting_direction?: SayWhenRetrievePortalURLsArgumentsSortingDirectionEnum;
    search_text?: string;
}

/**
 * 
 * @export
 * @interface SayWhenRetrievePortalURLsArguments
 */
export class SayWhenRetrievePortalURLsArguments extends BaseModel<SayWhenRetrievePortalURLsArgumentsInterface> {

    /**
     * 
     * @type {ActivityFiltersModel}
     * @memberof SayWhenRetrievePortalURLsArguments
     */
    filters?: ActivityFiltersModel;
    /**
     * 
     * @type {number}
     * @memberof SayWhenRetrievePortalURLsArguments
     */
    limit?: number;
    /**
     * 
     * @type {number}
     * @memberof SayWhenRetrievePortalURLsArguments
     */
    offset?: number;
    /**
     * Sorting Column
     * @type {string}
     * @memberof SayWhenRetrievePortalURLsArguments
     */
    sorting_column?: SayWhenRetrievePortalURLsArgumentsSortingColumnEnum;
    /**
     * Sorting Direction
     * @type {string}
     * @memberof SayWhenRetrievePortalURLsArguments
     */
    sorting_direction?: SayWhenRetrievePortalURLsArgumentsSortingDirectionEnum;
    /**
     * 
     * @type {string}
     * @memberof SayWhenRetrievePortalURLsArguments
     */
    search_text?: string;

    constructor(data?: SayWhenRetrievePortalURLsArgumentsInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(value: SayWhenRetrievePortalURLsArgumentsInterface): { [property: string]: any } {
        return {};
    }

}

export function SayWhenRetrievePortalURLsArgumentsFromJSON(json: any): SayWhenRetrievePortalURLsArguments {
    return SayWhenRetrievePortalURLsArgumentsFromJSONTyped(json, false);
}

export function SayWhenRetrievePortalURLsArgumentsFromJSONTyped(json: any, ignoreDiscriminator: boolean): SayWhenRetrievePortalURLsArguments {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = SayWhenRetrievePortalURLsArguments;
    try {
        let localClass = require.context('../override', true)('./SayWhenRetrievePortalURLsArguments');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'filters': !exists(json, 'filters') ? undefined : ActivityFiltersModelFromJSON(json['filters']),
        'limit': !exists(json, 'limit') ? undefined : Number(json['limit']),
        'offset': !exists(json, 'offset') ? undefined : Number(json['offset']),
        'sorting_column': !exists(json, 'sorting_column') ? undefined : json['sorting_column'],
        'sorting_direction': !exists(json, 'sorting_direction') ? undefined : json['sorting_direction'],
        'search_text': !exists(json, 'search_text') ? undefined : json['search_text'],
    });
}

export function SayWhenRetrievePortalURLsArgumentsToJSON(value?: SayWhenRetrievePortalURLsArguments | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = SayWhenRetrievePortalURLsArguments;
    try {
        let localClass = require.context('../override', true)('./SayWhenRetrievePortalURLsArguments');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return {
        
        'filters': ActivityFiltersModelToJSON(value.filters),
        'limit': value.limit,
        'offset': value.offset,
        'sorting_column': value.sorting_column,
        'sorting_direction': value.sorting_direction,
        'search_text': value.search_text,
        ...createClass.toJson(value),
    };
}

/**
* @export
* @enum {string}
*/
export enum SayWhenRetrievePortalURLsArgumentsSortingColumnEnum {
    Id = 'id',
    Nr = 'nr'
}
/**
* @export
* @enum {string}
*/
export enum SayWhenRetrievePortalURLsArgumentsSortingDirectionEnum {
    ASC = 'ASC',
    DESC = 'DESC'
}


