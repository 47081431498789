/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal Client Api
 * Bumbal API documentation
 *
 * The version of the OpenAPI document: 2.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiResponse18,
    ApiResponse18FromJSON,
    ApiResponse18ToJSON,
    ApiResponse401,
    ApiResponse401FromJSON,
    ApiResponse401ToJSON,
    ApiResponse403,
    ApiResponse403FromJSON,
    ApiResponse403ToJSON,
    ApiResponse405,
    ApiResponse405FromJSON,
    ApiResponse405ToJSON,
    ApiResponse45,
    ApiResponse45FromJSON,
    ApiResponse45ToJSON,
    ApiResponse46,
    ApiResponse46FromJSON,
    ApiResponse46ToJSON,
    ApiResponse47,
    ApiResponse47FromJSON,
    ApiResponse47ToJSON,
    ModelApiResponse,
    ModelApiResponseFromJSON,
    ModelApiResponseToJSON,
    QuestionnaireTemplateQuestionOptionTextListResponse,
    QuestionnaireTemplateQuestionOptionTextListResponseFromJSON,
    QuestionnaireTemplateQuestionOptionTextListResponseToJSON,
    QuestionnaireTemplateQuestionOptionTextModel,
    QuestionnaireTemplateQuestionOptionTextModelFromJSON,
    QuestionnaireTemplateQuestionOptionTextModelToJSON,
    QuestionnaireTemplateQuestionOptionTextRetrieveListArguments,
    QuestionnaireTemplateQuestionOptionTextRetrieveListArgumentsFromJSON,
    QuestionnaireTemplateQuestionOptionTextRetrieveListArgumentsToJSON,
} from '../models';

export interface CreateQuestionnaireTemplateQuestionOptionTextRequest {
    body?: QuestionnaireTemplateQuestionOptionTextModel;
}

export interface DeleteQuestionnaireTemplateQuestionOptionTextRequest {
    notificationId: number;
}

export interface RetrieveListQuestionnaireTemplateQuestionOptionTextRequest {
    arguments: QuestionnaireTemplateQuestionOptionTextRetrieveListArguments;
}

export interface RetrieveQuestionnaireTemplateQuestionOptionTextRequest {
    notificationId: number;
}

export interface SetQuestionnaireTemplateQuestionOptionTextRequest {
    body?: QuestionnaireTemplateQuestionOptionTextModel;
}

export interface UpdateQuestionnaireTemplateQuestionOptionTextRequest {
    notificationId: number;
    body?: QuestionnaireTemplateQuestionOptionTextModel;
}

/**
 * 
 */
export class QuestionnaireTemplateQuestionOptionTextApi extends runtime.BaseAPI {

    /**
     * Add a new QuestionnaireTemplateQuestionOptionText
     * Add a new QuestionnaireTemplateQuestionOptionText
     */
    async createQuestionnaireTemplateQuestionOptionTextRaw(requestParameters: CreateQuestionnaireTemplateQuestionOptionTextRequest): Promise<runtime.ApiResponse<ApiResponse47>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/questionnaire-template-question-option-text`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: QuestionnaireTemplateQuestionOptionTextModelToJSON(requestParameters.body),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResponse47FromJSON(jsonValue));
    }

    /**
     * Add a new QuestionnaireTemplateQuestionOptionText
     * Add a new QuestionnaireTemplateQuestionOptionText
     */
    createQuestionnaireTemplateQuestionOptionText = async(requestParameters: CreateQuestionnaireTemplateQuestionOptionTextRequest): Promise<ApiResponse47> => {
        const response = await this.createQuestionnaireTemplateQuestionOptionTextRaw(requestParameters);
        return await response.value();
    }

    /**
     * Delete an QuestionnaireTemplateQuestionOptionText entry
     * Delete an QuestionnaireTemplateQuestionOptionText entry
     */
    async deleteQuestionnaireTemplateQuestionOptionTextRaw(requestParameters: DeleteQuestionnaireTemplateQuestionOptionTextRequest): Promise<runtime.ApiResponse<ApiResponse46>> {
        if (requestParameters.notificationId === null || requestParameters.notificationId === undefined) {
            throw new runtime.RequiredError('notificationId','Required parameter requestParameters.notificationId was null or undefined when calling deleteQuestionnaireTemplateQuestionOptionText.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/questionnaire-template-question-option-text/{questionnaire-template-question-option-textId}`.replace(`{${"notificationId"}}`, encodeURIComponent(String(requestParameters.notificationId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResponse46FromJSON(jsonValue));
    }

    /**
     * Delete an QuestionnaireTemplateQuestionOptionText entry
     * Delete an QuestionnaireTemplateQuestionOptionText entry
     */
    deleteQuestionnaireTemplateQuestionOptionText = async(requestParameters: DeleteQuestionnaireTemplateQuestionOptionTextRequest): Promise<ApiResponse46> => {
        const response = await this.deleteQuestionnaireTemplateQuestionOptionTextRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieve List of QuestionnaireTemplateQuestionOptionText
     * Retrieve List of QuestionnaireTemplateQuestionOptionText
     */
    async retrieveListQuestionnaireTemplateQuestionOptionTextRaw(requestParameters: RetrieveListQuestionnaireTemplateQuestionOptionTextRequest): Promise<runtime.ApiResponse<QuestionnaireTemplateQuestionOptionTextListResponse>> {
        if (requestParameters.arguments === null || requestParameters.arguments === undefined) {
            throw new runtime.RequiredError('arguments','Required parameter requestParameters.arguments was null or undefined when calling retrieveListQuestionnaireTemplateQuestionOptionText.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/questionnaire-template-question-option-text`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: QuestionnaireTemplateQuestionOptionTextRetrieveListArgumentsToJSON(requestParameters.arguments),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => QuestionnaireTemplateQuestionOptionTextListResponseFromJSON(jsonValue));
    }

    /**
     * Retrieve List of QuestionnaireTemplateQuestionOptionText
     * Retrieve List of QuestionnaireTemplateQuestionOptionText
     */
    retrieveListQuestionnaireTemplateQuestionOptionText = async(requestParameters: RetrieveListQuestionnaireTemplateQuestionOptionTextRequest): Promise<QuestionnaireTemplateQuestionOptionTextListResponse> => {
        const response = await this.retrieveListQuestionnaireTemplateQuestionOptionTextRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieve an QuestionnaireTemplateQuestionOptionText
     * Retrieve a QuestionnaireTemplateQuestionOptionText
     */
    async retrieveQuestionnaireTemplateQuestionOptionTextRaw(requestParameters: RetrieveQuestionnaireTemplateQuestionOptionTextRequest): Promise<runtime.ApiResponse<QuestionnaireTemplateQuestionOptionTextModel>> {
        if (requestParameters.notificationId === null || requestParameters.notificationId === undefined) {
            throw new runtime.RequiredError('notificationId','Required parameter requestParameters.notificationId was null or undefined when calling retrieveQuestionnaireTemplateQuestionOptionText.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/questionnaire-template-question-option-text/{questionnaire-template-question-option-textId}`.replace(`{${"notificationId"}}`, encodeURIComponent(String(requestParameters.notificationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => QuestionnaireTemplateQuestionOptionTextModelFromJSON(jsonValue));
    }

    /**
     * Retrieve an QuestionnaireTemplateQuestionOptionText
     * Retrieve a QuestionnaireTemplateQuestionOptionText
     */
    retrieveQuestionnaireTemplateQuestionOptionText = async(requestParameters: RetrieveQuestionnaireTemplateQuestionOptionTextRequest): Promise<QuestionnaireTemplateQuestionOptionTextModel> => {
        const response = await this.retrieveQuestionnaireTemplateQuestionOptionTextRaw(requestParameters);
        return await response.value();
    }

    /**
     * Set (create or update) a d=QuestionnaireTemplateQuestionOptionText. If id or links are set in the data, and a corresponding QuestionnaireTemplateQuestionOptionText is found in Bumbal, an update will be performed.
     * Set (create or update) a QuestionnaireTemplateQuestionOptionText
     */
    async setQuestionnaireTemplateQuestionOptionTextRaw(requestParameters: SetQuestionnaireTemplateQuestionOptionTextRequest): Promise<runtime.ApiResponse<ModelApiResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/questionnaire-template-question-option-text/set`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: QuestionnaireTemplateQuestionOptionTextModelToJSON(requestParameters.body),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelApiResponseFromJSON(jsonValue));
    }

    /**
     * Set (create or update) a d=QuestionnaireTemplateQuestionOptionText. If id or links are set in the data, and a corresponding QuestionnaireTemplateQuestionOptionText is found in Bumbal, an update will be performed.
     * Set (create or update) a QuestionnaireTemplateQuestionOptionText
     */
    setQuestionnaireTemplateQuestionOptionText = async(requestParameters: SetQuestionnaireTemplateQuestionOptionTextRequest): Promise<ModelApiResponse> => {
        const response = await this.setQuestionnaireTemplateQuestionOptionTextRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update a specific QuestionnaireTemplateQuestionOptionText object
     * Update a specific QuestionnaireTemplateQuestionOptionText object
     */
    async updateQuestionnaireTemplateQuestionOptionTextRaw(requestParameters: UpdateQuestionnaireTemplateQuestionOptionTextRequest): Promise<runtime.ApiResponse<ApiResponse45>> {
        if (requestParameters.notificationId === null || requestParameters.notificationId === undefined) {
            throw new runtime.RequiredError('notificationId','Required parameter requestParameters.notificationId was null or undefined when calling updateQuestionnaireTemplateQuestionOptionText.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/questionnaire-template-question-option-text/{questionnaire-template-question-option-textId}`.replace(`{${"notificationId"}}`, encodeURIComponent(String(requestParameters.notificationId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: QuestionnaireTemplateQuestionOptionTextModelToJSON(requestParameters.body),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResponse45FromJSON(jsonValue));
    }

    /**
     * Update a specific QuestionnaireTemplateQuestionOptionText object
     * Update a specific QuestionnaireTemplateQuestionOptionText object
     */
    updateQuestionnaireTemplateQuestionOptionText = async(requestParameters: UpdateQuestionnaireTemplateQuestionOptionTextRequest): Promise<ApiResponse45> => {
        const response = await this.updateQuestionnaireTemplateQuestionOptionTextRaw(requestParameters);
        return await response.value();
    }

}
