import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import 'typeface-open-sans';
import App from './App';
import AppStore from './store/AppStore';
import i18n from './services/i18n';


const ApplicationContainer = () => {
    return (
        <I18nextProvider i18n={i18n}>
            <Provider store={AppStore}>
                <Suspense fallback={<div>Loading...</div>}>
                    <BrowserRouter basename='/'>
                        <App />
                    </BrowserRouter>
                </Suspense>
            </Provider>
        </I18nextProvider>
    )
}

ReactDOM.render(<ApplicationContainer />, document.getElementById('root'));
