import React from "react";
import CourseOverview from "./components/CourseOverview";
import Banner from "./components/Banner";
import NewsLetter from "./components/NewsLetter";
import IntroVideo from "./components/IntroVideo";

export const Home = () => {
	return (
		<>
			<Banner />
			<CourseOverview />
			<IntroVideo />
			<NewsLetter />
		</>
	);
};
