/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal Client Api
 * Bumbal API documentation
 *
 * The version of the OpenAPI document: 2.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ModelApiResponse,
    ModelApiResponseFromJSON,
    ModelApiResponseToJSON,
    PauseListResponse,
    PauseListResponseFromJSON,
    PauseListResponseToJSON,
    PauseModel,
    PauseModelFromJSON,
    PauseModelToJSON,
    PauseRetrieveListArguments,
    PauseRetrieveListArgumentsFromJSON,
    PauseRetrieveListArgumentsToJSON,
} from '../models';

export interface CreatePauseRequest {
    body?: PauseModel;
}

export interface DeletePauseRequest {
    pauseId: number;
}

export interface RetrieveListPauseRequest {
    arguments: PauseRetrieveListArguments;
}

export interface RetrievePauseRequest {
    pauseId: number;
}

export interface SetPauseRequest {
    body?: PauseModel;
}

export interface UpdatePauseRequest {
    pauseId: number;
}

/**
 * 
 */
export class PauseApi extends runtime.BaseAPI {

    /**
     * Add a new Pause
     * Add a new Pause
     */
    async createPauseRaw(requestParameters: CreatePauseRequest): Promise<runtime.ApiResponse<ModelApiResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/pause`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PauseModelToJSON(requestParameters.body),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelApiResponseFromJSON(jsonValue));
    }

    /**
     * Add a new Pause
     * Add a new Pause
     */
    createPause = async(requestParameters: CreatePauseRequest): Promise<ModelApiResponse> => {
        const response = await this.createPauseRaw(requestParameters);
        return await response.value();
    }

    /**
     * Delete a Pause
     * Delete a Pause
     */
    async deletePauseRaw(requestParameters: DeletePauseRequest): Promise<runtime.ApiResponse<ModelApiResponse>> {
        if (requestParameters.pauseId === null || requestParameters.pauseId === undefined) {
            throw new runtime.RequiredError('pauseId','Required parameter requestParameters.pauseId was null or undefined when calling deletePause.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/pause/{pauseId}`.replace(`{${"pauseId"}}`, encodeURIComponent(String(requestParameters.pauseId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelApiResponseFromJSON(jsonValue));
    }

    /**
     * Delete a Pause
     * Delete a Pause
     */
    deletePause = async(requestParameters: DeletePauseRequest): Promise<ModelApiResponse> => {
        const response = await this.deletePauseRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieve List of Pauses
     * Retrieve List of Pauses
     */
    async retrieveListPauseRaw(requestParameters: RetrieveListPauseRequest): Promise<runtime.ApiResponse<PauseListResponse>> {
        if (requestParameters.arguments === null || requestParameters.arguments === undefined) {
            throw new runtime.RequiredError('arguments','Required parameter requestParameters.arguments was null or undefined when calling retrieveListPause.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/pause`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PauseRetrieveListArgumentsToJSON(requestParameters.arguments),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PauseListResponseFromJSON(jsonValue));
    }

    /**
     * Retrieve List of Pauses
     * Retrieve List of Pauses
     */
    retrieveListPause = async(requestParameters: RetrieveListPauseRequest): Promise<PauseListResponse> => {
        const response = await this.retrieveListPauseRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieve an Pause
     * Retrieve a Pause
     */
    async retrievePauseRaw(requestParameters: RetrievePauseRequest): Promise<runtime.ApiResponse<PauseModel>> {
        if (requestParameters.pauseId === null || requestParameters.pauseId === undefined) {
            throw new runtime.RequiredError('pauseId','Required parameter requestParameters.pauseId was null or undefined when calling retrievePause.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/pause/{pauseId}`.replace(`{${"pauseId"}}`, encodeURIComponent(String(requestParameters.pauseId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PauseModelFromJSON(jsonValue));
    }

    /**
     * Retrieve an Pause
     * Retrieve a Pause
     */
    retrievePause = async(requestParameters: RetrievePauseRequest): Promise<PauseModel> => {
        const response = await this.retrievePauseRaw(requestParameters);
        return await response.value();
    }

    /**
     * Set (create or update) a Pause. If id or links are set in the data, and a corresponding pause is found in Bumbal, an update will be performed.
     * Set (create or update) a Pause
     */
    async setPauseRaw(requestParameters: SetPauseRequest): Promise<runtime.ApiResponse<ModelApiResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/pause/set`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PauseModelToJSON(requestParameters.body),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelApiResponseFromJSON(jsonValue));
    }

    /**
     * Set (create or update) a Pause. If id or links are set in the data, and a corresponding pause is found in Bumbal, an update will be performed.
     * Set (create or update) a Pause
     */
    setPause = async(requestParameters: SetPauseRequest): Promise<ModelApiResponse> => {
        const response = await this.setPauseRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update an Pause
     * Update a Pause
     */
    async updatePauseRaw(requestParameters: UpdatePauseRequest): Promise<runtime.ApiResponse<ModelApiResponse>> {
        if (requestParameters.pauseId === null || requestParameters.pauseId === undefined) {
            throw new runtime.RequiredError('pauseId','Required parameter requestParameters.pauseId was null or undefined when calling updatePause.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/pause/{pauseId}`.replace(`{${"pauseId"}}`, encodeURIComponent(String(requestParameters.pauseId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelApiResponseFromJSON(jsonValue));
    }

    /**
     * Update an Pause
     * Update a Pause
     */
    updatePause = async(requestParameters: UpdatePauseRequest): Promise<ModelApiResponse> => {
        const response = await this.updatePauseRaw(requestParameters);
        return await response.value();
    }

}
