/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal Client Api
 * Bumbal API documentation
 *
 * The version of the OpenAPI document: 2.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel } from '../runtime';
import {
    ActivityModel,
    ActivityModelFromJSON,
    ActivityModelFromJSONTyped,
    ActivityModelToJSON,
    FileModel,
    FileModelFromJSON,
    FileModelFromJSONTyped,
    FileModelToJSON,
    LinkModel,
    LinkModelFromJSON,
    LinkModelFromJSONTyped,
    LinkModelToJSON,
    MetaDataModel,
    MetaDataModelFromJSON,
    MetaDataModelFromJSONTyped,
    MetaDataModelToJSON,
    NoteModel,
    NoteModelFromJSON,
    NoteModelFromJSONTyped,
    NoteModelToJSON,
    PartyModel,
    PartyModelFromJSON,
    PartyModelFromJSONTyped,
    PartyModelToJSON,
    UsersModel,
    UsersModelFromJSON,
    UsersModelFromJSONTyped,
    UsersModelToJSON,
} from './';


export interface AssignmentModelInterface {
    id?: number;
    party_id?: number;
    booking_account_id?: number;
    tag_names?: Array<string>;
    tag_ids?: Array<number>;
    activities?: Array<ActivityModel>;
    booking_account?: PartyModel;
    status_id?: AssignmentModelStatusIdEnum;
    nr?: string;
    party_link?: Array<LinkModel>;
    account_name?: string;
    party_name?: string;
    reference?: string;
    description?: string;
    remarks?: string;
    date_time_from?: Date;
    date_time_to?: Date;
    multi_day?: boolean;
    links?: Array<LinkModel>;
    meta_data?: Array<MetaDataModel>;
    notes?: Array<NoteModel>;
    files?: Array<FileModel>;
    assignment_created_at?: Date;
    assignment_updated_at?: Date;
    assignment_created_by?: number;
    assignment_updated_by?: number;
    assignment_created_by_user?: UsersModel;
    assignment_updated_by_user?: UsersModel;
    assignment_active?: boolean;
    assignment_removed?: boolean;
}

/**
 * 
 * @export
 * @interface AssignmentModel
 */
export class AssignmentModel extends BaseModel<AssignmentModelInterface> {

    /**
     * Unique ID of this Assignment
     * @type {number}
     * @memberof AssignmentModel
     */
    id?: number;
    /**
     * Party ID
     * @type {number}
     * @memberof AssignmentModel
     */
    party_id?: number;
    /**
     * Booking account ID
     * @type {number}
     * @memberof AssignmentModel
     */
    booking_account_id?: number;
    /**
     * Tag names
     * @type {Array<string>}
     * @memberof AssignmentModel
     */
    tag_names?: Array<string>;
    /**
     * Tag ids
     * @type {Array<number>}
     * @memberof AssignmentModel
     */
    tag_ids?: Array<number>;
    /**
     * 
     * @type {Array<ActivityModel>}
     * @memberof AssignmentModel
     */
    activities?: Array<ActivityModel>;
    /**
     * 
     * @type {PartyModel}
     * @memberof AssignmentModel
     */
    booking_account?: PartyModel;
    /**
     * Status ID of this Assignment
     * @type {number}
     * @memberof AssignmentModel
     */
    status_id?: AssignmentModelStatusIdEnum;
    /**
     * Non-Unique number of this Assignment
     * @type {string}
     * @memberof AssignmentModel
     */
    nr?: string;
    /**
     * 
     * @type {Array<LinkModel>}
     * @memberof AssignmentModel
     */
    party_link?: Array<LinkModel>;
    /**
     * Account Name associated with this Assignment
     * @type {string}
     * @memberof AssignmentModel
     */
    account_name?: string;
    /**
     * Party Name associated with this Assignment
     * @type {string}
     * @memberof AssignmentModel
     */
    party_name?: string;
    /**
     * Reference text of this Assignment
     * @type {string}
     * @memberof AssignmentModel
     */
    reference?: string;
    /**
     * Description text of this Assignment
     * @type {string}
     * @memberof AssignmentModel
     */
    description?: string;
    /**
     * Remarks about this Assignment
     * @type {string}
     * @memberof AssignmentModel
     */
    remarks?: string;
    /**
     * Earliest start time of all Activities is this Assignment
     * @type {Date}
     * @memberof AssignmentModel
     */
    date_time_from?: Date;
    /**
     * Latest end time of all Activities is this Assignment
     * @type {Date}
     * @memberof AssignmentModel
     */
    date_time_to?: Date;
    /**
     * Multi day type assignment
     * @type {boolean}
     * @memberof AssignmentModel
     */
    multi_day?: boolean;
    /**
     * 
     * @type {Array<LinkModel>}
     * @memberof AssignmentModel
     */
    links?: Array<LinkModel>;
    /**
     * 
     * @type {Array<MetaDataModel>}
     * @memberof AssignmentModel
     */
    meta_data?: Array<MetaDataModel>;
    /**
     * 
     * @type {Array<NoteModel>}
     * @memberof AssignmentModel
     */
    notes?: Array<NoteModel>;
    /**
     * 
     * @type {Array<FileModel>}
     * @memberof AssignmentModel
     */
    files?: Array<FileModel>;
    /**
     * created_at date time
     * @type {Date}
     * @memberof AssignmentModel
     */
    assignment_created_at?: Date;
    /**
     * updated_at date time
     * @type {Date}
     * @memberof AssignmentModel
     */
    assignment_updated_at?: Date;
    /**
     * created_by user id
     * @type {number}
     * @memberof AssignmentModel
     */
    assignment_created_by?: number;
    /**
     * updated_by user id
     * @type {number}
     * @memberof AssignmentModel
     */
    assignment_updated_by?: number;
    /**
     * 
     * @type {UsersModel}
     * @memberof AssignmentModel
     */
    assignment_created_by_user?: UsersModel;
    /**
     * 
     * @type {UsersModel}
     * @memberof AssignmentModel
     */
    assignment_updated_by_user?: UsersModel;
    /**
     * Assignment is active (=true). Inactive assignments are not automatically considered in any of the application algorithms and will not be shown in the Bumbal Gui.
     * @type {boolean}
     * @memberof AssignmentModel
     */
    assignment_active?: boolean;
    /**
     * Assignment is removed (=true). Removed assignments are not automatically considered in any of the application algorithms and will not be shown in the Bumbal Gui. Removed assignments are usually irrepairable.
     * @type {boolean}
     * @memberof AssignmentModel
     */
    assignment_removed?: boolean;

    constructor(data?: AssignmentModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(value: AssignmentModelInterface): { [property: string]: any } {
        return {};
    }

}

export function AssignmentModelFromJSON(json: any): AssignmentModel {
    return AssignmentModelFromJSONTyped(json, false);
}

export function AssignmentModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): AssignmentModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = AssignmentModel;
    try {
        let localClass = require.context('../override', true)('./AssignmentModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'id': !exists(json, 'id') ? undefined : Number(json['id']),
        'party_id': !exists(json, 'party_id') ? undefined : Number(json['party_id']),
        'booking_account_id': !exists(json, 'booking_account_id') ? undefined : Number(json['booking_account_id']),
        'tag_names': !exists(json, 'tag_names') ? undefined : json['tag_names'],
        'tag_ids': !exists(json, 'tag_ids') ? undefined : json['tag_ids'],
        'activities': !exists(json, 'activities') ? undefined : ((json['activities'] as Array<any>).map(ActivityModelFromJSON)),
        'booking_account': !exists(json, 'booking_account') ? undefined : PartyModelFromJSON(json['booking_account']),
        'status_id': !exists(json, 'status_id') ? undefined : Number(json['status_id']),
        'nr': !exists(json, 'nr') ? undefined : json['nr'],
        'party_link': !exists(json, 'party_link') ? undefined : ((json['party_link'] as Array<any>).map(LinkModelFromJSON)),
        'account_name': !exists(json, 'account_name') ? undefined : json['account_name'],
        'party_name': !exists(json, 'party_name') ? undefined : json['party_name'],
        'reference': !exists(json, 'reference') ? undefined : json['reference'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'remarks': !exists(json, 'remarks') ? undefined : json['remarks'],
        'date_time_from': !exists(json, 'date_time_from') ? undefined : (new Date(json['date_time_from'].replace(' ', 'T'))),
        'date_time_to': !exists(json, 'date_time_to') ? undefined : (new Date(json['date_time_to'].replace(' ', 'T'))),
        'multi_day': !exists(json, 'multi_day') ? undefined : json['multi_day'],
        'links': !exists(json, 'links') ? undefined : ((json['links'] as Array<any>).map(LinkModelFromJSON)),
        'meta_data': !exists(json, 'meta_data') ? undefined : ((json['meta_data'] as Array<any>).map(MetaDataModelFromJSON)),
        'notes': !exists(json, 'notes') ? undefined : ((json['notes'] as Array<any>).map(NoteModelFromJSON)),
        'files': !exists(json, 'files') ? undefined : ((json['files'] as Array<any>).map(FileModelFromJSON)),
        'assignment_created_at': !exists(json, 'assignment_created_at') ? undefined : (new Date(json['assignment_created_at'].replace(' ', 'T'))),
        'assignment_updated_at': !exists(json, 'assignment_updated_at') ? undefined : (new Date(json['assignment_updated_at'].replace(' ', 'T'))),
        'assignment_created_by': !exists(json, 'assignment_created_by') ? undefined : Number(json['assignment_created_by']),
        'assignment_updated_by': !exists(json, 'assignment_updated_by') ? undefined : Number(json['assignment_updated_by']),
        'assignment_created_by_user': !exists(json, 'assignment_created_by_user') ? undefined : UsersModelFromJSON(json['assignment_created_by_user']),
        'assignment_updated_by_user': !exists(json, 'assignment_updated_by_user') ? undefined : UsersModelFromJSON(json['assignment_updated_by_user']),
        'assignment_active': !exists(json, 'assignment_active') ? undefined : json['assignment_active'],
        'assignment_removed': !exists(json, 'assignment_removed') ? undefined : json['assignment_removed'],
    });
}

export function AssignmentModelToJSON(value?: AssignmentModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = AssignmentModel;
    try {
        let localClass = require.context('../override', true)('./AssignmentModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return {
        
        'id': value.id,
        'party_id': value.party_id,
        'booking_account_id': value.booking_account_id,
        'tag_names': value.tag_names,
        'tag_ids': value.tag_ids,
        'activities': value.activities === undefined ? undefined : ((value.activities as Array<any>).map(ActivityModelToJSON)),
        'booking_account': PartyModelToJSON(value.booking_account),
        'status_id': value.status_id,
        'nr': value.nr,
        'party_link': value.party_link === undefined ? undefined : ((value.party_link as Array<any>).map(LinkModelToJSON)),
        'account_name': value.account_name,
        'party_name': value.party_name,
        'reference': value.reference,
        'description': value.description,
        'remarks': value.remarks,
        'date_time_from': value.date_time_from === undefined ? undefined : (value.date_time_from.toApiFormat()),
        'date_time_to': value.date_time_to === undefined ? undefined : (value.date_time_to.toApiFormat()),
        'multi_day': value.multi_day,
        'links': value.links === undefined ? undefined : ((value.links as Array<any>).map(LinkModelToJSON)),
        'meta_data': value.meta_data === undefined ? undefined : ((value.meta_data as Array<any>).map(MetaDataModelToJSON)),
        'notes': value.notes === undefined ? undefined : ((value.notes as Array<any>).map(NoteModelToJSON)),
        'files': value.files === undefined ? undefined : ((value.files as Array<any>).map(FileModelToJSON)),
        'assignment_created_at': value.assignment_created_at === undefined ? undefined : (value.assignment_created_at.toApiFormat()),
        'assignment_updated_at': value.assignment_updated_at === undefined ? undefined : (value.assignment_updated_at.toApiFormat()),
        'assignment_created_by': value.assignment_created_by,
        'assignment_updated_by': value.assignment_updated_by,
        'assignment_created_by_user': UsersModelToJSON(value.assignment_created_by_user),
        'assignment_updated_by_user': UsersModelToJSON(value.assignment_updated_by_user),
        'assignment_active': value.assignment_active,
        'assignment_removed': value.assignment_removed,
        ...createClass.toJson(value),
    };
}

/**
* @export
* @enum {string}
*/
export enum AssignmentModelStatusIdEnum {
    NUMBER_13 = 13,
    NUMBER_14 = 14,
    NUMBER_19 = 19,
    NUMBER_30 = 30,
    NUMBER_40 = 40
}


