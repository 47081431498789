/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal Client Api
 * Bumbal API documentation
 *
 * The version of the OpenAPI document: 2.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel } from '../runtime';
import {
    UomFiltersModel,
    UomFiltersModelFromJSON,
    UomFiltersModelFromJSONTyped,
    UomFiltersModelToJSON,
    UomOptionsModel,
    UomOptionsModelFromJSON,
    UomOptionsModelFromJSONTyped,
    UomOptionsModelToJSON,
} from './';


export interface UomRetrieveListArgumentsInterface {
    options?: UomOptionsModel;
    filters?: UomFiltersModel;
    limit?: number;
    offset?: number;
    sorting_column?: UomRetrieveListArgumentsSortingColumnEnum;
    sorting_direction?: UomRetrieveListArgumentsSortingDirectionEnum;
    as_list?: boolean;
}

/**
 * 
 * @export
 * @interface UomRetrieveListArguments
 */
export class UomRetrieveListArguments extends BaseModel<UomRetrieveListArgumentsInterface> {

    /**
     * 
     * @type {UomOptionsModel}
     * @memberof UomRetrieveListArguments
     */
    options?: UomOptionsModel;
    /**
     * 
     * @type {UomFiltersModel}
     * @memberof UomRetrieveListArguments
     */
    filters?: UomFiltersModel;
    /**
     * 
     * @type {number}
     * @memberof UomRetrieveListArguments
     */
    limit?: number;
    /**
     * 
     * @type {number}
     * @memberof UomRetrieveListArguments
     */
    offset?: number;
    /**
     * Sorting Column
     * @type {string}
     * @memberof UomRetrieveListArguments
     */
    sorting_column?: UomRetrieveListArgumentsSortingColumnEnum;
    /**
     * Sorting Direction
     * @type {string}
     * @memberof UomRetrieveListArguments
     */
    sorting_direction?: UomRetrieveListArgumentsSortingDirectionEnum;
    /**
     * 
     * @type {boolean}
     * @memberof UomRetrieveListArguments
     */
    as_list?: boolean;

    constructor(data?: UomRetrieveListArgumentsInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(value: UomRetrieveListArgumentsInterface): { [property: string]: any } {
        return {};
    }

}

export function UomRetrieveListArgumentsFromJSON(json: any): UomRetrieveListArguments {
    return UomRetrieveListArgumentsFromJSONTyped(json, false);
}

export function UomRetrieveListArgumentsFromJSONTyped(json: any, ignoreDiscriminator: boolean): UomRetrieveListArguments {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = UomRetrieveListArguments;
    try {
        let localClass = require.context('../override', true)('./UomRetrieveListArguments');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'options': !exists(json, 'options') ? undefined : UomOptionsModelFromJSON(json['options']),
        'filters': !exists(json, 'filters') ? undefined : UomFiltersModelFromJSON(json['filters']),
        'limit': !exists(json, 'limit') ? undefined : Number(json['limit']),
        'offset': !exists(json, 'offset') ? undefined : Number(json['offset']),
        'sorting_column': !exists(json, 'sorting_column') ? undefined : json['sorting_column'],
        'sorting_direction': !exists(json, 'sorting_direction') ? undefined : json['sorting_direction'],
        'as_list': !exists(json, 'as_list') ? undefined : json['as_list'],
    });
}

export function UomRetrieveListArgumentsToJSON(value?: UomRetrieveListArguments | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = UomRetrieveListArguments;
    try {
        let localClass = require.context('../override', true)('./UomRetrieveListArguments');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return {
        
        'options': UomOptionsModelToJSON(value.options),
        'filters': UomFiltersModelToJSON(value.filters),
        'limit': value.limit,
        'offset': value.offset,
        'sorting_column': value.sorting_column,
        'sorting_direction': value.sorting_direction,
        'as_list': value.as_list,
        ...createClass.toJson(value),
    };
}

/**
* @export
* @enum {string}
*/
export enum UomRetrieveListArgumentsSortingColumnEnum {
    Name = 'name',
    Id = 'id'
}
/**
* @export
* @enum {string}
*/
export enum UomRetrieveListArgumentsSortingDirectionEnum {
    Asc = 'asc',
    Desc = 'desc'
}


