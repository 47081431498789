/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal Client Api
 * Bumbal API documentation
 *
 * The version of the OpenAPI document: 2.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiResponse401,
    ApiResponse401FromJSON,
    ApiResponse401ToJSON,
    ApiResponse403,
    ApiResponse403FromJSON,
    ApiResponse403ToJSON,
    ApiResponse405,
    ApiResponse405FromJSON,
    ApiResponse405ToJSON,
    LanguageListResponse,
    LanguageListResponseFromJSON,
    LanguageListResponseToJSON,
    LanguageRetrieveListArguments,
    LanguageRetrieveListArgumentsFromJSON,
    LanguageRetrieveListArgumentsToJSON,
} from '../models';

export interface RetrieveListLanguageRequest {
    arguments: LanguageRetrieveListArguments;
}

/**
 * 
 */
export class LanguageApi extends runtime.BaseAPI {

    /**
     * Retrieve List of Language
     * Retrieve List of Language
     */
    async retrieveListLanguageRaw(requestParameters: RetrieveListLanguageRequest): Promise<runtime.ApiResponse<LanguageListResponse>> {
        if (requestParameters.arguments === null || requestParameters.arguments === undefined) {
            throw new runtime.RequiredError('arguments','Required parameter requestParameters.arguments was null or undefined when calling retrieveListLanguage.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/language`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: LanguageRetrieveListArgumentsToJSON(requestParameters.arguments),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => LanguageListResponseFromJSON(jsonValue));
    }

    /**
     * Retrieve List of Language
     * Retrieve List of Language
     */
    retrieveListLanguage = async(requestParameters: RetrieveListLanguageRequest): Promise<LanguageListResponse> => {
        const response = await this.retrieveListLanguageRaw(requestParameters);
        return await response.value();
    }

}
