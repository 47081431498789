export default class EnvUtil {

    static isStage() {
        return process.env.REACT_APP_DEPLOY_ENV === 'stage';
    }

    static isProduction() {
        return process.env.REACT_APP_DEPLOY_ENV === 'production';
    }

    static isDevelopment() {
        return process.env.REACT_APP_DEPLOY_ENV === 'development';
    }

    static getPublicUrl() {
        return process.env.PUBLIC_URL + '/';
    }

}