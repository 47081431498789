/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal Client Api
 * Bumbal API documentation
 *
 * The version of the OpenAPI document: 2.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    LinkListResponse,
    LinkListResponseFromJSON,
    LinkListResponseToJSON,
    LinkModel,
    LinkModelFromJSON,
    LinkModelToJSON,
    LinkRetrieveListArguments,
    LinkRetrieveListArgumentsFromJSON,
    LinkRetrieveListArgumentsToJSON,
    ModelApiResponse,
    ModelApiResponseFromJSON,
    ModelApiResponseToJSON,
} from '../models';

export interface DeleteLinkRequest {
    driverId: number;
}

export interface RetrieveListLinkRequest {
    arguments: LinkRetrieveListArguments;
}

export interface UpdateLinkRequest {
    linkId: number;
    body?: LinkModel;
}

/**
 * 
 */
export class LinkApi extends runtime.BaseAPI {

    /**
     * Delete a link
     * Delete a link
     */
    async deleteLinkRaw(requestParameters: DeleteLinkRequest): Promise<runtime.ApiResponse<ModelApiResponse>> {
        if (requestParameters.driverId === null || requestParameters.driverId === undefined) {
            throw new runtime.RequiredError('driverId','Required parameter requestParameters.driverId was null or undefined when calling deleteLink.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/link/{linkId}`.replace(`{${"driverId"}}`, encodeURIComponent(String(requestParameters.driverId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelApiResponseFromJSON(jsonValue));
    }

    /**
     * Delete a link
     * Delete a link
     */
    deleteLink = async(requestParameters: DeleteLinkRequest): Promise<ModelApiResponse> => {
        const response = await this.deleteLinkRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieve List of Links
     * Retrieve List of Links
     */
    async retrieveListLinkRaw(requestParameters: RetrieveListLinkRequest): Promise<runtime.ApiResponse<Array<LinkListResponse>>> {
        if (requestParameters.arguments === null || requestParameters.arguments === undefined) {
            throw new runtime.RequiredError('arguments','Required parameter requestParameters.arguments was null or undefined when calling retrieveListLink.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/link`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: LinkRetrieveListArgumentsToJSON(requestParameters.arguments),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(LinkListResponseFromJSON));
    }

    /**
     * Retrieve List of Links
     * Retrieve List of Links
     */
    retrieveListLink = async(requestParameters: RetrieveListLinkRequest): Promise<Array<LinkListResponse>> => {
        const response = await this.retrieveListLinkRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update a specific link object
     * Update a specific link object
     */
    async updateLinkRaw(requestParameters: UpdateLinkRequest): Promise<runtime.ApiResponse<ModelApiResponse>> {
        if (requestParameters.linkId === null || requestParameters.linkId === undefined) {
            throw new runtime.RequiredError('linkId','Required parameter requestParameters.linkId was null or undefined when calling updateLink.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/link/{linkId}`.replace(`{${"linkId"}}`, encodeURIComponent(String(requestParameters.linkId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: LinkModelToJSON(requestParameters.body),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelApiResponseFromJSON(jsonValue));
    }

    /**
     * Update a specific link object
     * Update a specific link object
     */
    updateLink = async(requestParameters: UpdateLinkRequest): Promise<ModelApiResponse> => {
        const response = await this.updateLinkRaw(requestParameters);
        return await response.value();
    }

}
