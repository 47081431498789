/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal Client Api
 * Bumbal API documentation
 *
 * The version of the OpenAPI document: 2.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ModelApiResponse,
    ModelApiResponseFromJSON,
    ModelApiResponseToJSON,
    NoteModel,
    NoteModelFromJSON,
    NoteModelToJSON,
    NoteRetrieveListArguments,
    NoteRetrieveListArgumentsFromJSON,
    NoteRetrieveListArgumentsToJSON,
} from '../models';

export interface DeleteNoteRequest {
    noteId: number;
}

export interface RetrieveListNoteRequest {
    arguments: NoteRetrieveListArguments;
}

export interface RetrieveNoteRequest {
    noteId: number;
    includeNoteTags: boolean;
    includeNoteTagTypeLinkIds: boolean;
    includeNoteObjectLinkIds: boolean;
    includeUpdatedBy: boolean;
}

export interface SetNoteRequest {
    body?: NoteModel;
}

/**
 * 
 */
export class NoteApi extends runtime.BaseAPI {

    /**
     * Delete an note
     * Delete an note
     */
    async deleteNoteRaw(requestParameters: DeleteNoteRequest): Promise<runtime.ApiResponse<ModelApiResponse>> {
        if (requestParameters.noteId === null || requestParameters.noteId === undefined) {
            throw new runtime.RequiredError('noteId','Required parameter requestParameters.noteId was null or undefined when calling deleteNote.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/note/{noteId}`.replace(`{${"noteId"}}`, encodeURIComponent(String(requestParameters.noteId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelApiResponseFromJSON(jsonValue));
    }

    /**
     * Delete an note
     * Delete an note
     */
    deleteNote = async(requestParameters: DeleteNoteRequest): Promise<ModelApiResponse> => {
        const response = await this.deleteNoteRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieve List of Notes
     * Retrieve List of Notes
     */
    async retrieveListNoteRaw(requestParameters: RetrieveListNoteRequest): Promise<runtime.ApiResponse<Array<NoteModel>>> {
        if (requestParameters.arguments === null || requestParameters.arguments === undefined) {
            throw new runtime.RequiredError('arguments','Required parameter requestParameters.arguments was null or undefined when calling retrieveListNote.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/note`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: NoteRetrieveListArgumentsToJSON(requestParameters.arguments),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(NoteModelFromJSON));
    }

    /**
     * Retrieve List of Notes
     * Retrieve List of Notes
     */
    retrieveListNote = async(requestParameters: RetrieveListNoteRequest): Promise<Array<NoteModel>> => {
        const response = await this.retrieveListNoteRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns a single note
     * Find note by ID
     */
    async retrieveNoteRaw(requestParameters: RetrieveNoteRequest): Promise<runtime.ApiResponse<NoteModel>> {
        if (requestParameters.noteId === null || requestParameters.noteId === undefined) {
            throw new runtime.RequiredError('noteId','Required parameter requestParameters.noteId was null or undefined when calling retrieveNote.');
        }

        if (requestParameters.includeNoteTags === null || requestParameters.includeNoteTags === undefined) {
            throw new runtime.RequiredError('includeNoteTags','Required parameter requestParameters.includeNoteTags was null or undefined when calling retrieveNote.');
        }

        if (requestParameters.includeNoteTagTypeLinkIds === null || requestParameters.includeNoteTagTypeLinkIds === undefined) {
            throw new runtime.RequiredError('includeNoteTagTypeLinkIds','Required parameter requestParameters.includeNoteTagTypeLinkIds was null or undefined when calling retrieveNote.');
        }

        if (requestParameters.includeNoteObjectLinkIds === null || requestParameters.includeNoteObjectLinkIds === undefined) {
            throw new runtime.RequiredError('includeNoteObjectLinkIds','Required parameter requestParameters.includeNoteObjectLinkIds was null or undefined when calling retrieveNote.');
        }

        if (requestParameters.includeUpdatedBy === null || requestParameters.includeUpdatedBy === undefined) {
            throw new runtime.RequiredError('includeUpdatedBy','Required parameter requestParameters.includeUpdatedBy was null or undefined when calling retrieveNote.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.includeNoteTags !== undefined) {
            queryParameters['include_note_tags'] = requestParameters.includeNoteTags;
        }

        if (requestParameters.includeNoteTagTypeLinkIds !== undefined) {
            queryParameters['include_note_tag_type_link_ids'] = requestParameters.includeNoteTagTypeLinkIds;
        }

        if (requestParameters.includeNoteObjectLinkIds !== undefined) {
            queryParameters['include_note_object_link_ids'] = requestParameters.includeNoteObjectLinkIds;
        }

        if (requestParameters.includeUpdatedBy !== undefined) {
            queryParameters['include_updated_by'] = requestParameters.includeUpdatedBy;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/note/{noteId}`.replace(`{${"noteId"}}`, encodeURIComponent(String(requestParameters.noteId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => NoteModelFromJSON(jsonValue));
    }

    /**
     * Returns a single note
     * Find note by ID
     */
    retrieveNote = async(requestParameters: RetrieveNoteRequest): Promise<NoteModel> => {
        const response = await this.retrieveNoteRaw(requestParameters);
        return await response.value();
    }

    /**
     * Set (create or update) a note. If id or links are set in the data, and a corresponding note is found in Bumbal, an update will be performed.
     * Set (create or update) a note
     */
    async setNoteRaw(requestParameters: SetNoteRequest): Promise<runtime.ApiResponse<ModelApiResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/note/set`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NoteModelToJSON(requestParameters.body),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelApiResponseFromJSON(jsonValue));
    }

    /**
     * Set (create or update) a note. If id or links are set in the data, and a corresponding note is found in Bumbal, an update will be performed.
     * Set (create or update) a note
     */
    setNote = async(requestParameters: SetNoteRequest): Promise<ModelApiResponse> => {
        const response = await this.setNoteRaw(requestParameters);
        return await response.value();
    }

}
