import { Box, Grid, Paper, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ChapterContent from './components/ChapterContent';
import ChapterList from './components/ChapterList';
import { useParams, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import UserActions from '../../store/user/actions';
import { completeChapterRequest, UserState } from '../../store/user/types';
import { connect } from 'react-redux';
import { getAcademyMetadata } from '../../utils/MetaDataUtil';
import { RouteComponentProps } from 'react-router-dom';
import ChapterHeader from './components/ChapterHeader';
import ProgressBar from '../progress_bar/ProgressBar';
import SubjectNavigator from './components/SubjectNavigator';

interface StateProps {
    userReducer: UserState;
    completeChapter: (params: completeChapterRequest) => void;
}

interface ViewProps {
    history: RouteComponentProps['history'];
}

type SubjectViewProps = StateProps & ViewProps;

const SubjectView = ({
    userReducer,
    completeChapter,
    history,
}: SubjectViewProps) => {
    const { version, subjectId } = useParams();
    const { i18n } = useTranslation();
    const { t } = useTranslation();
    const location = useLocation();

    const language = i18n.resolvedLanguage;
    const course = history.location.pathname.split('/')[1];
    const content = require(`../../static/users/${language}/${course}.json`);

    const academyMetaData = getAcademyMetadata(
        userReducer?.user?.meta_data ?? []
    );
    const subject = content.versions[version].subjects[subjectId];

    const [currentChapter, setCurrentChapter] = useState<number>(0);

    const switchChapter = (index: number) => {
        setCurrentChapter(index);
    };

    useEffect(() => {
        setCurrentChapter(
            academyMetaData.getCurrentChapter(course, version, subjectId)
        );
    }, [location, course, version, academyMetaData, subjectId]);

    useEffect(() => {
        if (
            !academyMetaData.value_object[course][version][subjectId][
                currentChapter
            ]
        ) {
            completeChapter({
                course,
                version,
                subject: subjectId,
                chapter: currentChapter,
            });
        }
    }, [
        currentChapter,
        academyMetaData.value_object,
        completeChapter,
        course,
        subjectId,
        userReducer,
        version,
    ]);

    return (
        <Box>
            {currentChapter <= subject.chapters.length - 1 && (
                <>
                    <ChapterHeader
                        title={subject.title}
                        chapter={subject.chapters[currentChapter]}
                    />
                    <Grid
                        container
                        sx={{ backgroundColor: '#F1F1F1', pt: 5, pb: 5 }}
                        columnSpacing={5}
                        rowSpacing={4}
                    >
                        <Grid item md={3}>
                            <Paper sx={{ padding: '5px 0px 20px' }}>
                                <Typography
                                    variant='h5'
                                    fontWeight='600'
                                    sx={{ pb: '10px', textAlign: 'center' }}
                                >
                                    {t('course.overview')}
                                </Typography>
                                <ProgressBar
                                    value={academyMetaData.getSubjectProgress(
                                        course,
                                        version,
                                        subjectId
                                    )}
                                    barColor='secondary.main'
                                />
                                <ChapterList
                                    chapters={subject.chapters}
                                    activeIndex={currentChapter}
                                    switchChapter={switchChapter}
                                />
                            </Paper>
                            <SubjectNavigator
                                finalChapterIndex={subject.chapters.length - 1}
                                switchChapter={switchChapter}
                                chapterIndex={currentChapter}
                                subjectIndex={Number(subjectId)}
                                finalSubjectIndex={
                                    content.versions[version].subjects.length -
                                    1
                                }
                            />
                        </Grid>
                        <Grid item md={6} alignItems='center'>
                            <ChapterContent
                                chapter={subject.chapters[currentChapter]}
                            />
                        </Grid>
                    </Grid>
                </>
            )}
        </Box>
    );
};

const mapStateToProps = (state) => ({
    userReducer: state.user,
});

const mapDispatchToProps = (dispatch: Function) => {
    return {
        completeChapter: (params: completeChapterRequest) =>
            dispatch(UserActions.updateUser(params)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SubjectView);
