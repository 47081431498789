import { defaultAcademyMetadata } from './../models/DefaultProgress';
import { MetaDataModel } from "../api";
import MetaData from '../api/override/MetaDataModel';

export const getAcademyMetadata = (metadata: MetaDataModel[]): MetaData => {
    const meta_data = metadata.find((item) => item.name === 'bumbal_academy')

    const defaultData = new MetaData(defaultAcademyMetadata);

    let countLength = ((meta_data?.value?.match(/true/g)?.length) ?? 0) + ((meta_data?.value?.match(/false/g)?.length) ?? 0)

    if (countLength === defaultData.value?.match(/false/g)?.length) {
        return (metadata.find((item) => item.name === 'bumbal_academy')) as MetaData;
    }
    else {
        return defaultData as MetaData;

    }

}