/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal Client Api
 * Bumbal API documentation
 *
 * The version of the OpenAPI document: 2.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel } from '../runtime';

export interface ApiResponse56Interface {
    message?: string;
    type?: string;
    code?: number;
    additional_data?: object;
}

/**
 * 
 * @export
 * @interface ApiResponse56
 */
export class ApiResponse56 extends BaseModel<ApiResponse56Interface> {

    /**
     * Message describing the code
     * @type {string}
     * @memberof ApiResponse56
     */
    message?: string;
    /**
     * Ready
     * @type {string}
     * @memberof ApiResponse56
     */
    type?: string;
    /**
     * 
     * @type {number}
     * @memberof ApiResponse56
     */
    code?: number;
    /**
     * 
     * @type {object}
     * @memberof ApiResponse56
     */
    additional_data?: object;

    constructor(data?: ApiResponse56Interface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(value: ApiResponse56Interface): { [property: string]: any } {
        return {};
    }

}

export function ApiResponse56FromJSON(json: any): ApiResponse56 {
    return ApiResponse56FromJSONTyped(json, false);
}

export function ApiResponse56FromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiResponse56 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = ApiResponse56;
    try {
        let localClass = require.context('../override', true)('./ApiResponse56');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'message': !exists(json, 'message') ? undefined : json['message'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'code': !exists(json, 'code') ? undefined : Number(json['code']),
        'additional_data': !exists(json, 'additional_data') ? undefined : json['additional_data'],
    });
}

export function ApiResponse56ToJSON(value?: ApiResponse56 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = ApiResponse56;
    try {
        let localClass = require.context('../override', true)('./ApiResponse56');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return {
        
        'message': value.message,
        'type': value.type,
        'code': value.code,
        'additional_data': value.additional_data,
        ...createClass.toJson(value),
    };
}


