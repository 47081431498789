/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal Client Api
 * Bumbal API documentation
 *
 * The version of the OpenAPI document: 2.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    UomListResponse,
    UomListResponseFromJSON,
    UomListResponseToJSON,
    UomRetrieveListArguments,
    UomRetrieveListArgumentsFromJSON,
    UomRetrieveListArgumentsToJSON,
} from '../models';

export interface RetrieveListUomRequest {
    arguments: UomRetrieveListArguments;
}

/**
 * 
 */
export class UomApi extends runtime.BaseAPI {

    /**
     * Retrieve List of Uom\'s
     * Retrieve List of Uom\'s
     */
    async retrieveListUomRaw(requestParameters: RetrieveListUomRequest): Promise<runtime.ApiResponse<UomListResponse>> {
        if (requestParameters.arguments === null || requestParameters.arguments === undefined) {
            throw new runtime.RequiredError('arguments','Required parameter requestParameters.arguments was null or undefined when calling retrieveListUom.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/uom`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UomRetrieveListArgumentsToJSON(requestParameters.arguments),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UomListResponseFromJSON(jsonValue));
    }

    /**
     * Retrieve List of Uom\'s
     * Retrieve List of Uom\'s
     */
    retrieveListUom = async(requestParameters: RetrieveListUomRequest): Promise<UomListResponse> => {
        const response = await this.retrieveListUomRaw(requestParameters);
        return await response.value();
    }

}
