/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal Client Api
 * Bumbal API documentation
 *
 * The version of the OpenAPI document: 2.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel } from '../runtime';
import {
    BrandModel,
    BrandModelFromJSON,
    BrandModelFromJSONTyped,
    BrandModelToJSON,
    LinkModel,
    LinkModelFromJSON,
    LinkModelFromJSONTyped,
    LinkModelToJSON,
    MetaDataModel,
    MetaDataModelFromJSON,
    MetaDataModelFromJSONTyped,
    MetaDataModelToJSON,
    ZoneRangeModel,
    ZoneRangeModelFromJSON,
    ZoneRangeModelFromJSONTyped,
    ZoneRangeModelToJSON,
} from './';


export interface ZoneModelInterface {
    id?: number;
    name?: string;
    filter_tag_names?: Array<string>;
    filter_tags?: object;
    links?: Array<LinkModel>;
    meta_data?: Array<MetaDataModel>;
    zone_ranges?: Array<ZoneRangeModel>;
    brands?: Array<BrandModel>;
    zone_created_at?: Date;
    zone_updated_at?: Date;
    zone_created_by?: number;
    zone_updated_by?: number;
}

/**
 * 
 * @export
 * @interface ZoneModel
 */
export class ZoneModel extends BaseModel<ZoneModelInterface> {

    /**
     * Unique ID
     * @type {number}
     * @memberof ZoneModel
     */
    id?: number;
    /**
     * Zone Name
     * @type {string}
     * @memberof ZoneModel
     */
    name?: string;
    /**
     * Tag names
     * @type {Array<string>}
     * @memberof ZoneModel
     */
    filter_tag_names?: Array<string>;
    /**
     * 
     * @type {object}
     * @memberof ZoneModel
     */
    filter_tags?: object;
    /**
     * 
     * @type {Array<LinkModel>}
     * @memberof ZoneModel
     */
    links?: Array<LinkModel>;
    /**
     * 
     * @type {Array<MetaDataModel>}
     * @memberof ZoneModel
     */
    meta_data?: Array<MetaDataModel>;
    /**
     * 
     * @type {Array<ZoneRangeModel>}
     * @memberof ZoneModel
     */
    zone_ranges?: Array<ZoneRangeModel>;
    /**
     * 
     * @type {Array<BrandModel>}
     * @memberof ZoneModel
     */
    brands?: Array<BrandModel>;
    /**
     * created_at date time
     * @type {Date}
     * @memberof ZoneModel
     */
    zone_created_at?: Date;
    /**
     * updated_at date time
     * @type {Date}
     * @memberof ZoneModel
     */
    zone_updated_at?: Date;
    /**
     * created_by user id
     * @type {number}
     * @memberof ZoneModel
     */
    zone_created_by?: number;
    /**
     * created_by user id
     * @type {number}
     * @memberof ZoneModel
     */
    zone_updated_by?: number;

    constructor(data?: ZoneModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(value: ZoneModelInterface): { [property: string]: any } {
        return {};
    }

}

export function ZoneModelFromJSON(json: any): ZoneModel {
    return ZoneModelFromJSONTyped(json, false);
}

export function ZoneModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ZoneModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = ZoneModel;
    try {
        let localClass = require.context('../override', true)('./ZoneModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'id': !exists(json, 'id') ? undefined : Number(json['id']),
        'name': !exists(json, 'name') ? undefined : json['name'],
        'filter_tag_names': !exists(json, 'filter_tag_names') ? undefined : json['filter_tag_names'],
        'filter_tags': !exists(json, 'filter_tags') ? undefined : json['filter_tags'],
        'links': !exists(json, 'links') ? undefined : ((json['links'] as Array<any>).map(LinkModelFromJSON)),
        'meta_data': !exists(json, 'meta_data') ? undefined : ((json['meta_data'] as Array<any>).map(MetaDataModelFromJSON)),
        'zone_ranges': !exists(json, 'zone_ranges') ? undefined : ((json['zone_ranges'] as Array<any>).map(ZoneRangeModelFromJSON)),
        'brands': !exists(json, 'brands') ? undefined : ((json['brands'] as Array<any>).map(BrandModelFromJSON)),
        'zone_created_at': !exists(json, 'zone_created_at') ? undefined : (new Date(json['zone_created_at'].replace(' ', 'T'))),
        'zone_updated_at': !exists(json, 'zone_updated_at') ? undefined : (new Date(json['zone_updated_at'].replace(' ', 'T'))),
        'zone_created_by': !exists(json, 'zone_created_by') ? undefined : Number(json['zone_created_by']),
        'zone_updated_by': !exists(json, 'zone_updated_by') ? undefined : Number(json['zone_updated_by']),
    });
}

export function ZoneModelToJSON(value?: ZoneModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = ZoneModel;
    try {
        let localClass = require.context('../override', true)('./ZoneModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return {
        
        'id': value.id,
        'name': value.name,
        'filter_tag_names': value.filter_tag_names,
        'filter_tags': value.filter_tags,
        'links': value.links === undefined ? undefined : ((value.links as Array<any>).map(LinkModelToJSON)),
        'meta_data': value.meta_data === undefined ? undefined : ((value.meta_data as Array<any>).map(MetaDataModelToJSON)),
        'zone_ranges': value.zone_ranges === undefined ? undefined : ((value.zone_ranges as Array<any>).map(ZoneRangeModelToJSON)),
        'brands': value.brands === undefined ? undefined : ((value.brands as Array<any>).map(BrandModelToJSON)),
        'zone_created_at': value.zone_created_at === undefined ? undefined : (value.zone_created_at.toApiFormat()),
        'zone_updated_at': value.zone_updated_at === undefined ? undefined : (value.zone_updated_at.toApiFormat()),
        'zone_created_by': value.zone_created_by,
        'zone_updated_by': value.zone_updated_by,
        ...createClass.toJson(value),
    };
}


