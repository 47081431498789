/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal Client Api
 * Bumbal API documentation
 *
 * The version of the OpenAPI document: 2.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiResponse401,
    ApiResponse401FromJSON,
    ApiResponse401ToJSON,
    ApiResponse403,
    ApiResponse403FromJSON,
    ApiResponse403ToJSON,
    ApiResponse405,
    ApiResponse405FromJSON,
    ApiResponse405ToJSON,
    ApiResponse56,
    ApiResponse56FromJSON,
    ApiResponse56ToJSON,
    ModelApiResponse,
    ModelApiResponseFromJSON,
    ModelApiResponseToJSON,
    UserNotificationListResponse,
    UserNotificationListResponseFromJSON,
    UserNotificationListResponseToJSON,
    UserNotificationModel,
    UserNotificationModelFromJSON,
    UserNotificationModelToJSON,
    UserNotificationRetrieveListArguments,
    UserNotificationRetrieveListArgumentsFromJSON,
    UserNotificationRetrieveListArgumentsToJSON,
    UsersListResponse,
    UsersListResponseFromJSON,
    UsersListResponseToJSON,
    UsersModel,
    UsersModelFromJSON,
    UsersModelToJSON,
    UsersRetrieveListArguments,
    UsersRetrieveListArgumentsFromJSON,
    UsersRetrieveListArgumentsToJSON,
} from '../models';

export interface CheckCredentialsUserRequest {
    email: string;
    password: string;
}

export interface DeleteUserRequest {
    userId: number;
}

export interface RetrieveListUserNotificationRequest {
    arguments: UserNotificationRetrieveListArguments;
}

export interface RetrieveListUsersRequest {
    arguments: UsersRetrieveListArguments;
}

export interface RetrieveUsersRequest {
    usersId: number;
}

export interface SetUserRequest {
    body?: UsersModel;
}

export interface SetUserNotificationRequest {
    body?: UserNotificationModel;
}

export interface UpdateUsersRequest {
    usersId: number;
}

/**
 * 
 */
export class UsersApi extends runtime.BaseAPI {

    /**
     * Checks the credentials of a User
     * Checks the credentials of a User
     */
    async checkCredentialsUserRaw(requestParameters: CheckCredentialsUserRequest): Promise<runtime.ApiResponse<UsersModel>> {
        if (requestParameters.email === null || requestParameters.email === undefined) {
            throw new runtime.RequiredError('email','Required parameter requestParameters.email was null or undefined when calling checkCredentialsUser.');
        }

        if (requestParameters.password === null || requestParameters.password === undefined) {
            throw new runtime.RequiredError('password','Required parameter requestParameters.password was null or undefined when calling checkCredentialsUser.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.email !== undefined) {
            queryParameters['email'] = requestParameters.email;
        }

        if (requestParameters.password !== undefined) {
            queryParameters['password'] = requestParameters.password;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/users/check-credentials`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UsersModelFromJSON(jsonValue));
    }

    /**
     * Checks the credentials of a User
     * Checks the credentials of a User
     */
    checkCredentialsUser = async(requestParameters: CheckCredentialsUserRequest): Promise<UsersModel> => {
        const response = await this.checkCredentialsUserRaw(requestParameters);
        return await response.value();
    }

    /**
     * Delete a user
     * Delete a user
     */
    async deleteUserRaw(requestParameters: DeleteUserRequest): Promise<runtime.ApiResponse<ModelApiResponse>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling deleteUser.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/users/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelApiResponseFromJSON(jsonValue));
    }

    /**
     * Delete a user
     * Delete a user
     */
    deleteUser = async(requestParameters: DeleteUserRequest): Promise<ModelApiResponse> => {
        const response = await this.deleteUserRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieve List of UserNotification
     * Retrieve List of UserNotification
     */
    async retrieveListUserNotificationRaw(requestParameters: RetrieveListUserNotificationRequest): Promise<runtime.ApiResponse<UserNotificationListResponse>> {
        if (requestParameters.arguments === null || requestParameters.arguments === undefined) {
            throw new runtime.RequiredError('arguments','Required parameter requestParameters.arguments was null or undefined when calling retrieveListUserNotification.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/users/notification`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UserNotificationRetrieveListArgumentsToJSON(requestParameters.arguments),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserNotificationListResponseFromJSON(jsonValue));
    }

    /**
     * Retrieve List of UserNotification
     * Retrieve List of UserNotification
     */
    retrieveListUserNotification = async(requestParameters: RetrieveListUserNotificationRequest): Promise<UserNotificationListResponse> => {
        const response = await this.retrieveListUserNotificationRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieve List of Users
     * Retrieve List of Users
     */
    async retrieveListUsersRaw(requestParameters: RetrieveListUsersRequest): Promise<runtime.ApiResponse<UsersListResponse>> {
        if (requestParameters.arguments === null || requestParameters.arguments === undefined) {
            throw new runtime.RequiredError('arguments','Required parameter requestParameters.arguments was null or undefined when calling retrieveListUsers.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/users`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UsersRetrieveListArgumentsToJSON(requestParameters.arguments),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UsersListResponseFromJSON(jsonValue));
    }

    /**
     * Retrieve List of Users
     * Retrieve List of Users
     */
    retrieveListUsers = async(requestParameters: RetrieveListUsersRequest): Promise<UsersListResponse> => {
        const response = await this.retrieveListUsersRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieve a Users
     * Retrieve a Users
     */
    async retrieveUsersRaw(requestParameters: RetrieveUsersRequest): Promise<runtime.ApiResponse<UsersModel>> {
        if (requestParameters.usersId === null || requestParameters.usersId === undefined) {
            throw new runtime.RequiredError('usersId','Required parameter requestParameters.usersId was null or undefined when calling retrieveUsers.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/users/{usersId}`.replace(`{${"usersId"}}`, encodeURIComponent(String(requestParameters.usersId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UsersModelFromJSON(jsonValue));
    }

    /**
     * Retrieve a Users
     * Retrieve a Users
     */
    retrieveUsers = async(requestParameters: RetrieveUsersRequest): Promise<UsersModel> => {
        const response = await this.retrieveUsersRaw(requestParameters);
        return await response.value();
    }

    /**
     * Set (create or update) a User. If id or links are set in the data, and a corresponding users is found in Bumbal, an update will be performed.
     * Set (create or update) a User
     */
    async setUserRaw(requestParameters: SetUserRequest): Promise<runtime.ApiResponse<ModelApiResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/users/set`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UsersModelToJSON(requestParameters.body),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelApiResponseFromJSON(jsonValue));
    }

    /**
     * Set (create or update) a User. If id or links are set in the data, and a corresponding users is found in Bumbal, an update will be performed.
     * Set (create or update) a User
     */
    setUser = async(requestParameters: SetUserRequest): Promise<ModelApiResponse> => {
        const response = await this.setUserRaw(requestParameters);
        return await response.value();
    }

    /**
     * Set (create or update) a user notification. If id or links are set in the data, and a corresponding user notification is found in Bumbal, an update will be performed.
     * Create a new UserNotification or update an existing one
     */
    async setUserNotificationRaw(requestParameters: SetUserNotificationRequest): Promise<runtime.ApiResponse<ApiResponse56>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/users/notification`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserNotificationModelToJSON(requestParameters.body),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResponse56FromJSON(jsonValue));
    }

    /**
     * Set (create or update) a user notification. If id or links are set in the data, and a corresponding user notification is found in Bumbal, an update will be performed.
     * Create a new UserNotification or update an existing one
     */
    setUserNotification = async(requestParameters: SetUserNotificationRequest): Promise<ApiResponse56> => {
        const response = await this.setUserNotificationRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update a Setting
     * Update a Users
     */
    async updateUsersRaw(requestParameters: UpdateUsersRequest): Promise<runtime.ApiResponse<ModelApiResponse>> {
        if (requestParameters.usersId === null || requestParameters.usersId === undefined) {
            throw new runtime.RequiredError('usersId','Required parameter requestParameters.usersId was null or undefined when calling updateUsers.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/users/{usersId}`.replace(`{${"usersId"}}`, encodeURIComponent(String(requestParameters.usersId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelApiResponseFromJSON(jsonValue));
    }

    /**
     * Update a Setting
     * Update a Users
     */
    updateUsers = async(requestParameters: UpdateUsersRequest): Promise<ModelApiResponse> => {
        const response = await this.updateUsersRaw(requestParameters);
        return await response.value();
    }

}
