/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal Client Api
 * Bumbal API documentation
 *
 * The version of the OpenAPI document: 2.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel } from '../runtime';
import {
    FileModel,
    FileModelFromJSON,
    FileModelFromJSONTyped,
    FileModelToJSON,
    LinkModel,
    LinkModelFromJSON,
    LinkModelFromJSONTyped,
    LinkModelToJSON,
    MetaDataModel,
    MetaDataModelFromJSON,
    MetaDataModelFromJSONTyped,
    MetaDataModelToJSON,
} from './';


export interface NoteModelInterface {
    id?: number;
    object_type_name?: string;
    object_id?: number;
    object_link?: LinkModel;
    note_category_id?: number;
    note_category_name?: string;
    visible_for_driver?: boolean;
    title?: string;
    updated_by_name?: string;
    content?: string;
    files?: Array<FileModel>;
    links?: Array<LinkModel>;
    meta_data?: Array<MetaDataModel>;
    active?: boolean;
    created_at?: Date;
    updated_at?: Date;
    created_by?: number;
    updated_by?: number;
}

/**
 * 
 * @export
 * @interface NoteModel
 */
export class NoteModel extends BaseModel<NoteModelInterface> {

    /**
     * Unique Identifier
     * @type {number}
     * @memberof NoteModel
     */
    id?: number;
    /**
     * Object type name
     * @type {string}
     * @memberof NoteModel
     */
    object_type_name?: string;
    /**
     * Object ID
     * @type {number}
     * @memberof NoteModel
     */
    object_id?: number;
    /**
     * 
     * @type {LinkModel}
     * @memberof NoteModel
     */
    object_link?: LinkModel;
    /**
     * Note category id
     * @type {number}
     * @memberof NoteModel
     */
    note_category_id?: number;
    /**
     * Note category name
     * @type {string}
     * @memberof NoteModel
     */
    note_category_name?: string;
    /**
     * Notition is visible in driver App
     * @type {boolean}
     * @memberof NoteModel
     */
    visible_for_driver?: boolean;
    /**
     * Note title
     * @type {string}
     * @memberof NoteModel
     */
    title?: string;
    /**
     * Note updated by user full name
     * @type {string}
     * @memberof NoteModel
     */
    updated_by_name?: string;
    /**
     * Note content
     * @type {string}
     * @memberof NoteModel
     */
    content?: string;
    /**
     * 
     * @type {Array<FileModel>}
     * @memberof NoteModel
     */
    files?: Array<FileModel>;
    /**
     * 
     * @type {Array<LinkModel>}
     * @memberof NoteModel
     */
    links?: Array<LinkModel>;
    /**
     * 
     * @type {Array<MetaDataModel>}
     * @memberof NoteModel
     */
    meta_data?: Array<MetaDataModel>;
    /**
     * if active=0: note has been removed and is no longer visible in any bumbal interface
     * @type {boolean}
     * @memberof NoteModel
     */
    active?: boolean;
    /**
     * created_at date time
     * @type {Date}
     * @memberof NoteModel
     */
    created_at?: Date;
    /**
     * updated_at date time
     * @type {Date}
     * @memberof NoteModel
     */
    updated_at?: Date;
    /**
     * created_by user id
     * @type {number}
     * @memberof NoteModel
     */
    created_by?: number;
    /**
     * updated_by user id
     * @type {number}
     * @memberof NoteModel
     */
    updated_by?: number;

    constructor(data?: NoteModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(value: NoteModelInterface): { [property: string]: any } {
        return {};
    }

}

export function NoteModelFromJSON(json: any): NoteModel {
    return NoteModelFromJSONTyped(json, false);
}

export function NoteModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): NoteModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = NoteModel;
    try {
        let localClass = require.context('../override', true)('./NoteModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'id': !exists(json, 'id') ? undefined : Number(json['id']),
        'object_type_name': !exists(json, 'object_type_name') ? undefined : json['object_type_name'],
        'object_id': !exists(json, 'object_id') ? undefined : Number(json['object_id']),
        'object_link': !exists(json, 'object_link') ? undefined : LinkModelFromJSON(json['object_link']),
        'note_category_id': !exists(json, 'note_category_id') ? undefined : Number(json['note_category_id']),
        'note_category_name': !exists(json, 'note_category_name') ? undefined : json['note_category_name'],
        'visible_for_driver': !exists(json, 'visible_for_driver') ? undefined : json['visible_for_driver'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'updated_by_name': !exists(json, 'updated_by_name') ? undefined : json['updated_by_name'],
        'content': !exists(json, 'content') ? undefined : json['content'],
        'files': !exists(json, 'files') ? undefined : ((json['files'] as Array<any>).map(FileModelFromJSON)),
        'links': !exists(json, 'links') ? undefined : ((json['links'] as Array<any>).map(LinkModelFromJSON)),
        'meta_data': !exists(json, 'meta_data') ? undefined : ((json['meta_data'] as Array<any>).map(MetaDataModelFromJSON)),
        'active': !exists(json, 'active') ? undefined : json['active'],
        'created_at': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'].replace(' ', 'T'))),
        'updated_at': !exists(json, 'updated_at') ? undefined : (new Date(json['updated_at'].replace(' ', 'T'))),
        'created_by': !exists(json, 'created_by') ? undefined : Number(json['created_by']),
        'updated_by': !exists(json, 'updated_by') ? undefined : Number(json['updated_by']),
    });
}

export function NoteModelToJSON(value?: NoteModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = NoteModel;
    try {
        let localClass = require.context('../override', true)('./NoteModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return {
        
        'id': value.id,
        'object_type_name': value.object_type_name,
        'object_id': value.object_id,
        'object_link': LinkModelToJSON(value.object_link),
        'note_category_id': value.note_category_id,
        'note_category_name': value.note_category_name,
        'visible_for_driver': value.visible_for_driver,
        'title': value.title,
        'updated_by_name': value.updated_by_name,
        'content': value.content,
        'files': value.files === undefined ? undefined : ((value.files as Array<any>).map(FileModelToJSON)),
        'links': value.links === undefined ? undefined : ((value.links as Array<any>).map(LinkModelToJSON)),
        'meta_data': value.meta_data === undefined ? undefined : ((value.meta_data as Array<any>).map(MetaDataModelToJSON)),
        'active': value.active,
        'created_at': value.created_at === undefined ? undefined : (value.created_at.toApiFormat()),
        'updated_at': value.updated_at === undefined ? undefined : (value.updated_at.toApiFormat()),
        'created_by': value.created_by,
        'updated_by': value.updated_by,
        ...createClass.toJson(value),
    };
}


