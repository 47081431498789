/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal Client Api
 * Bumbal API documentation
 *
 * The version of the OpenAPI document: 2.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel } from '../runtime';
import {
    QuestionnaireTemplateQuestionFiltersModel,
    QuestionnaireTemplateQuestionFiltersModelFromJSON,
    QuestionnaireTemplateQuestionFiltersModelFromJSONTyped,
    QuestionnaireTemplateQuestionFiltersModelToJSON,
    QuestionnaireTemplateQuestionOptionsModel,
    QuestionnaireTemplateQuestionOptionsModelFromJSON,
    QuestionnaireTemplateQuestionOptionsModelFromJSONTyped,
    QuestionnaireTemplateQuestionOptionsModelToJSON,
} from './';


export interface QuestionnaireTemplateQuestionRetrieveListArgumentsInterface {
    options?: QuestionnaireTemplateQuestionOptionsModel;
    filters?: QuestionnaireTemplateQuestionFiltersModel;
    limit?: number;
    offset?: number;
    sorting_column?: QuestionnaireTemplateQuestionRetrieveListArgumentsSortingColumnEnum;
    sorting_direction?: QuestionnaireTemplateQuestionRetrieveListArgumentsSortingDirectionEnum;
    search_text?: string;
}

/**
 * 
 * @export
 * @interface QuestionnaireTemplateQuestionRetrieveListArguments
 */
export class QuestionnaireTemplateQuestionRetrieveListArguments extends BaseModel<QuestionnaireTemplateQuestionRetrieveListArgumentsInterface> {

    /**
     * 
     * @type {QuestionnaireTemplateQuestionOptionsModel}
     * @memberof QuestionnaireTemplateQuestionRetrieveListArguments
     */
    options?: QuestionnaireTemplateQuestionOptionsModel;
    /**
     * 
     * @type {QuestionnaireTemplateQuestionFiltersModel}
     * @memberof QuestionnaireTemplateQuestionRetrieveListArguments
     */
    filters?: QuestionnaireTemplateQuestionFiltersModel;
    /**
     * 
     * @type {number}
     * @memberof QuestionnaireTemplateQuestionRetrieveListArguments
     */
    limit?: number;
    /**
     * 
     * @type {number}
     * @memberof QuestionnaireTemplateQuestionRetrieveListArguments
     */
    offset?: number;
    /**
     * Sorting Column
     * @type {string}
     * @memberof QuestionnaireTemplateQuestionRetrieveListArguments
     */
    sorting_column?: QuestionnaireTemplateQuestionRetrieveListArgumentsSortingColumnEnum;
    /**
     * Sorting Direction
     * @type {string}
     * @memberof QuestionnaireTemplateQuestionRetrieveListArguments
     */
    sorting_direction?: QuestionnaireTemplateQuestionRetrieveListArgumentsSortingDirectionEnum;
    /**
     * 
     * @type {string}
     * @memberof QuestionnaireTemplateQuestionRetrieveListArguments
     */
    search_text?: string;

    constructor(data?: QuestionnaireTemplateQuestionRetrieveListArgumentsInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(value: QuestionnaireTemplateQuestionRetrieveListArgumentsInterface): { [property: string]: any } {
        return {};
    }

}

export function QuestionnaireTemplateQuestionRetrieveListArgumentsFromJSON(json: any): QuestionnaireTemplateQuestionRetrieveListArguments {
    return QuestionnaireTemplateQuestionRetrieveListArgumentsFromJSONTyped(json, false);
}

export function QuestionnaireTemplateQuestionRetrieveListArgumentsFromJSONTyped(json: any, ignoreDiscriminator: boolean): QuestionnaireTemplateQuestionRetrieveListArguments {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = QuestionnaireTemplateQuestionRetrieveListArguments;
    try {
        let localClass = require.context('../override', true)('./QuestionnaireTemplateQuestionRetrieveListArguments');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'options': !exists(json, 'options') ? undefined : QuestionnaireTemplateQuestionOptionsModelFromJSON(json['options']),
        'filters': !exists(json, 'filters') ? undefined : QuestionnaireTemplateQuestionFiltersModelFromJSON(json['filters']),
        'limit': !exists(json, 'limit') ? undefined : Number(json['limit']),
        'offset': !exists(json, 'offset') ? undefined : Number(json['offset']),
        'sorting_column': !exists(json, 'sorting_column') ? undefined : json['sorting_column'],
        'sorting_direction': !exists(json, 'sorting_direction') ? undefined : json['sorting_direction'],
        'search_text': !exists(json, 'search_text') ? undefined : json['search_text'],
    });
}

export function QuestionnaireTemplateQuestionRetrieveListArgumentsToJSON(value?: QuestionnaireTemplateQuestionRetrieveListArguments | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = QuestionnaireTemplateQuestionRetrieveListArguments;
    try {
        let localClass = require.context('../override', true)('./QuestionnaireTemplateQuestionRetrieveListArguments');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return {
        
        'options': QuestionnaireTemplateQuestionOptionsModelToJSON(value.options),
        'filters': QuestionnaireTemplateQuestionFiltersModelToJSON(value.filters),
        'limit': value.limit,
        'offset': value.offset,
        'sorting_column': value.sorting_column,
        'sorting_direction': value.sorting_direction,
        'search_text': value.search_text,
        ...createClass.toJson(value),
    };
}

/**
* @export
* @enum {string}
*/
export enum QuestionnaireTemplateQuestionRetrieveListArgumentsSortingColumnEnum {
    Id = 'id',
    Order = 'order'
}
/**
* @export
* @enum {string}
*/
export enum QuestionnaireTemplateQuestionRetrieveListArgumentsSortingDirectionEnum {
    ASC = 'ASC',
    DESC = 'DESC'
}


