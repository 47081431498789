/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal Client Api
 * Bumbal API documentation
 *
 * The version of the OpenAPI document: 2.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiResponse10,
    ApiResponse10FromJSON,
    ApiResponse10ToJSON,
    ApiResponse11,
    ApiResponse11FromJSON,
    ApiResponse11ToJSON,
    ApiResponse12,
    ApiResponse12FromJSON,
    ApiResponse12ToJSON,
    ApiResponse401,
    ApiResponse401FromJSON,
    ApiResponse401ToJSON,
    ApiResponse403,
    ApiResponse403FromJSON,
    ApiResponse403ToJSON,
    ApiResponse405,
    ApiResponse405FromJSON,
    ApiResponse405ToJSON,
    ApiResponse8,
    ApiResponse8FromJSON,
    ApiResponse8ToJSON,
    ApiResponse9,
    ApiResponse9FromJSON,
    ApiResponse9ToJSON,
    NotificationCategoryListResponse,
    NotificationCategoryListResponseFromJSON,
    NotificationCategoryListResponseToJSON,
    NotificationCategoryModel,
    NotificationCategoryModelFromJSON,
    NotificationCategoryModelToJSON,
    NotificationCategoryRetrieveListArguments,
    NotificationCategoryRetrieveListArgumentsFromJSON,
    NotificationCategoryRetrieveListArgumentsToJSON,
} from '../models';

export interface CreateNotificationCategoryRequest {
    body?: NotificationCategoryModel;
}

export interface DeleteNotificationCategoryRequest {
    notificationCategoryId: number;
}

export interface RetrieveListNotificationCategoryRequest {
    arguments: NotificationCategoryRetrieveListArguments;
}

export interface RetrieveNotificationCategoryRequest {
    notificationCategoryId: number;
    includeObjectTypeName?: boolean;
    includeRecordInfo?: boolean;
}

export interface SetNotificationCategoryRequest {
    body?: NotificationCategoryModel;
}

export interface UpdateNotificationCategoryRequest {
    notificationCategoryId: number;
    body?: NotificationCategoryModel;
}

/**
 * 
 */
export class NotificationCategoryApi extends runtime.BaseAPI {

    /**
     * Add a new NotificationCategory
     * Add a new NotificationCategory
     */
    async createNotificationCategoryRaw(requestParameters: CreateNotificationCategoryRequest): Promise<runtime.ApiResponse<ApiResponse11>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/notification-category`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NotificationCategoryModelToJSON(requestParameters.body),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResponse11FromJSON(jsonValue));
    }

    /**
     * Add a new NotificationCategory
     * Add a new NotificationCategory
     */
    createNotificationCategory = async(requestParameters: CreateNotificationCategoryRequest): Promise<ApiResponse11> => {
        const response = await this.createNotificationCategoryRaw(requestParameters);
        return await response.value();
    }

    /**
     * Delete an NotificationCategory entry
     * Delete an NotificationCategory entry
     */
    async deleteNotificationCategoryRaw(requestParameters: DeleteNotificationCategoryRequest): Promise<runtime.ApiResponse<ApiResponse9>> {
        if (requestParameters.notificationCategoryId === null || requestParameters.notificationCategoryId === undefined) {
            throw new runtime.RequiredError('notificationCategoryId','Required parameter requestParameters.notificationCategoryId was null or undefined when calling deleteNotificationCategory.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/notification-category/{notification-categoryId}`.replace(`{${"notification-categoryId"}}`, encodeURIComponent(String(requestParameters.notificationCategoryId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResponse9FromJSON(jsonValue));
    }

    /**
     * Delete an NotificationCategory entry
     * Delete an NotificationCategory entry
     */
    deleteNotificationCategory = async(requestParameters: DeleteNotificationCategoryRequest): Promise<ApiResponse9> => {
        const response = await this.deleteNotificationCategoryRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieve List of NotificationCategory
     * Retrieve List of NotificationCategory
     */
    async retrieveListNotificationCategoryRaw(requestParameters: RetrieveListNotificationCategoryRequest): Promise<runtime.ApiResponse<NotificationCategoryListResponse>> {
        if (requestParameters.arguments === null || requestParameters.arguments === undefined) {
            throw new runtime.RequiredError('arguments','Required parameter requestParameters.arguments was null or undefined when calling retrieveListNotificationCategory.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/notification-category`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: NotificationCategoryRetrieveListArgumentsToJSON(requestParameters.arguments),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => NotificationCategoryListResponseFromJSON(jsonValue));
    }

    /**
     * Retrieve List of NotificationCategory
     * Retrieve List of NotificationCategory
     */
    retrieveListNotificationCategory = async(requestParameters: RetrieveListNotificationCategoryRequest): Promise<NotificationCategoryListResponse> => {
        const response = await this.retrieveListNotificationCategoryRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieve an NotificationCategory
     * Retrieve a NotificationCategory
     */
    async retrieveNotificationCategoryRaw(requestParameters: RetrieveNotificationCategoryRequest): Promise<runtime.ApiResponse<NotificationCategoryModel>> {
        if (requestParameters.notificationCategoryId === null || requestParameters.notificationCategoryId === undefined) {
            throw new runtime.RequiredError('notificationCategoryId','Required parameter requestParameters.notificationCategoryId was null or undefined when calling retrieveNotificationCategory.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.includeObjectTypeName !== undefined) {
            queryParameters['include_object_type_name'] = requestParameters.includeObjectTypeName;
        }

        if (requestParameters.includeRecordInfo !== undefined) {
            queryParameters['include_record_info'] = requestParameters.includeRecordInfo;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/notification-category/{notification-categoryId}`.replace(`{${"notification-categoryId"}}`, encodeURIComponent(String(requestParameters.notificationCategoryId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => NotificationCategoryModelFromJSON(jsonValue));
    }

    /**
     * Retrieve an NotificationCategory
     * Retrieve a NotificationCategory
     */
    retrieveNotificationCategory = async(requestParameters: RetrieveNotificationCategoryRequest): Promise<NotificationCategoryModel> => {
        const response = await this.retrieveNotificationCategoryRaw(requestParameters);
        return await response.value();
    }

    /**
     * Set (create or update) a notification category. If id or links are set in the data, and a corresponding notification category is found in Bumbal, an update will be performed.
     * Create a new NotificationCategory or update an existing one
     */
    async setNotificationCategoryRaw(requestParameters: SetNotificationCategoryRequest): Promise<runtime.ApiResponse<ApiResponse12>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/notification-category/set`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NotificationCategoryModelToJSON(requestParameters.body),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResponse12FromJSON(jsonValue));
    }

    /**
     * Set (create or update) a notification category. If id or links are set in the data, and a corresponding notification category is found in Bumbal, an update will be performed.
     * Create a new NotificationCategory or update an existing one
     */
    setNotificationCategory = async(requestParameters: SetNotificationCategoryRequest): Promise<ApiResponse12> => {
        const response = await this.setNotificationCategoryRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update a specific NotificationCategory object
     * Update a specific NotificationCategory object
     */
    async updateNotificationCategoryRaw(requestParameters: UpdateNotificationCategoryRequest): Promise<runtime.ApiResponse<ApiResponse8>> {
        if (requestParameters.notificationCategoryId === null || requestParameters.notificationCategoryId === undefined) {
            throw new runtime.RequiredError('notificationCategoryId','Required parameter requestParameters.notificationCategoryId was null or undefined when calling updateNotificationCategory.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/notification-category/{notification-categoryId}`.replace(`{${"notification-categoryId"}}`, encodeURIComponent(String(requestParameters.notificationCategoryId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: NotificationCategoryModelToJSON(requestParameters.body),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResponse8FromJSON(jsonValue));
    }

    /**
     * Update a specific NotificationCategory object
     * Update a specific NotificationCategory object
     */
    updateNotificationCategory = async(requestParameters: UpdateNotificationCategoryRequest): Promise<ApiResponse8> => {
        const response = await this.updateNotificationCategoryRaw(requestParameters);
        return await response.value();
    }

}
