/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal Client Api
 * Bumbal API documentation
 *
 * The version of the OpenAPI document: 2.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ModelApiResponse,
    ModelApiResponseFromJSON,
    ModelApiResponseToJSON,
    PortalListResponse,
    PortalListResponseFromJSON,
    PortalListResponseToJSON,
    PortalModel,
    PortalModelFromJSON,
    PortalModelToJSON,
    PortalRetrieveListArguments,
    PortalRetrieveListArgumentsFromJSON,
    PortalRetrieveListArgumentsToJSON,
} from '../models';

export interface CreatePortalRequest {
    body?: PortalModel;
}

export interface DeletePortalRequest {
    portalId: number;
}

export interface RetrieveListPortalRequest {
    arguments: PortalRetrieveListArguments;
}

export interface RetrievePortalRequest {
    portalId: number;
}

export interface SetPortalRequest {
    body?: PortalModel;
}

export interface UpdatePortalRequest {
    portalId: number;
}

/**
 * 
 */
export class PortalApi extends runtime.BaseAPI {

    /**
     * Add a new Portal
     * Add a new Portal
     */
    async createPortalRaw(requestParameters: CreatePortalRequest): Promise<runtime.ApiResponse<ModelApiResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/portal`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PortalModelToJSON(requestParameters.body),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelApiResponseFromJSON(jsonValue));
    }

    /**
     * Add a new Portal
     * Add a new Portal
     */
    createPortal = async(requestParameters: CreatePortalRequest): Promise<ModelApiResponse> => {
        const response = await this.createPortalRaw(requestParameters);
        return await response.value();
    }

    /**
     * Delete a Portal
     * Delete a Portal
     */
    async deletePortalRaw(requestParameters: DeletePortalRequest): Promise<runtime.ApiResponse<ModelApiResponse>> {
        if (requestParameters.portalId === null || requestParameters.portalId === undefined) {
            throw new runtime.RequiredError('portalId','Required parameter requestParameters.portalId was null or undefined when calling deletePortal.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/portal/{portalId}`.replace(`{${"portalId"}}`, encodeURIComponent(String(requestParameters.portalId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelApiResponseFromJSON(jsonValue));
    }

    /**
     * Delete a Portal
     * Delete a Portal
     */
    deletePortal = async(requestParameters: DeletePortalRequest): Promise<ModelApiResponse> => {
        const response = await this.deletePortalRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieve List of Portals
     * Retrieve List of Portals
     */
    async retrieveListPortalRaw(requestParameters: RetrieveListPortalRequest): Promise<runtime.ApiResponse<PortalListResponse>> {
        if (requestParameters.arguments === null || requestParameters.arguments === undefined) {
            throw new runtime.RequiredError('arguments','Required parameter requestParameters.arguments was null or undefined when calling retrieveListPortal.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/portal`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PortalRetrieveListArgumentsToJSON(requestParameters.arguments),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PortalListResponseFromJSON(jsonValue));
    }

    /**
     * Retrieve List of Portals
     * Retrieve List of Portals
     */
    retrieveListPortal = async(requestParameters: RetrieveListPortalRequest): Promise<PortalListResponse> => {
        const response = await this.retrieveListPortalRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieve an Portal
     * Retrieve a Portal
     */
    async retrievePortalRaw(requestParameters: RetrievePortalRequest): Promise<runtime.ApiResponse<PortalModel>> {
        if (requestParameters.portalId === null || requestParameters.portalId === undefined) {
            throw new runtime.RequiredError('portalId','Required parameter requestParameters.portalId was null or undefined when calling retrievePortal.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/portal/{portalId}`.replace(`{${"portalId"}}`, encodeURIComponent(String(requestParameters.portalId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PortalModelFromJSON(jsonValue));
    }

    /**
     * Retrieve an Portal
     * Retrieve a Portal
     */
    retrievePortal = async(requestParameters: RetrievePortalRequest): Promise<PortalModel> => {
        const response = await this.retrievePortalRaw(requestParameters);
        return await response.value();
    }

    /**
     * Set (create or update) a Portal. If id or links are set in the data, and a corresponding portal is found in Bumbal, an update will be performed.
     * Set (create or update) a Portal
     */
    async setPortalRaw(requestParameters: SetPortalRequest): Promise<runtime.ApiResponse<ModelApiResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/portal/set`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PortalModelToJSON(requestParameters.body),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelApiResponseFromJSON(jsonValue));
    }

    /**
     * Set (create or update) a Portal. If id or links are set in the data, and a corresponding portal is found in Bumbal, an update will be performed.
     * Set (create or update) a Portal
     */
    setPortal = async(requestParameters: SetPortalRequest): Promise<ModelApiResponse> => {
        const response = await this.setPortalRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update an Portal
     * Update a Portal
     */
    async updatePortalRaw(requestParameters: UpdatePortalRequest): Promise<runtime.ApiResponse<ModelApiResponse>> {
        if (requestParameters.portalId === null || requestParameters.portalId === undefined) {
            throw new runtime.RequiredError('portalId','Required parameter requestParameters.portalId was null or undefined when calling updatePortal.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/portal/{portalId}`.replace(`{${"portalId"}}`, encodeURIComponent(String(requestParameters.portalId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelApiResponseFromJSON(jsonValue));
    }

    /**
     * Update an Portal
     * Update a Portal
     */
    updatePortal = async(requestParameters: UpdatePortalRequest): Promise<ModelApiResponse> => {
        const response = await this.updatePortalRaw(requestParameters);
        return await response.value();
    }

}
