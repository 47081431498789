/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal Client Api
 * Bumbal API documentation
 *
 * The version of the OpenAPI document: 2.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    LogListResponse,
    LogListResponseFromJSON,
    LogListResponseToJSON,
    LogModel,
    LogModelFromJSON,
    LogModelToJSON,
    LogRetrieveListArguments,
    LogRetrieveListArgumentsFromJSON,
    LogRetrieveListArgumentsToJSON,
    ModelApiResponse,
    ModelApiResponseFromJSON,
    ModelApiResponseToJSON,
} from '../models';

export interface RetrieveListLogRequest {
    arguments: LogRetrieveListArguments;
}

export interface SetLogRequest {
    body: LogModel;
}

/**
 * 
 */
export class LogApi extends runtime.BaseAPI {

    /**
     * Retrieve List of Log messages
     * Retrieve List of Log messages
     */
    async retrieveListLogRaw(requestParameters: RetrieveListLogRequest): Promise<runtime.ApiResponse<LogListResponse>> {
        if (requestParameters.arguments === null || requestParameters.arguments === undefined) {
            throw new runtime.RequiredError('arguments','Required parameter requestParameters.arguments was null or undefined when calling retrieveListLog.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/log`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: LogRetrieveListArgumentsToJSON(requestParameters.arguments),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => LogListResponseFromJSON(jsonValue));
    }

    /**
     * Retrieve List of Log messages
     * Retrieve List of Log messages
     */
    retrieveListLog = async(requestParameters: RetrieveListLogRequest): Promise<LogListResponse> => {
        const response = await this.retrieveListLogRaw(requestParameters);
        return await response.value();
    }

    /**
     * Post a Log message.
     * Post a Log message
     */
    async setLogRaw(requestParameters: SetLogRequest): Promise<runtime.ApiResponse<ModelApiResponse>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling setLog.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/log/set`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LogModelToJSON(requestParameters.body),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelApiResponseFromJSON(jsonValue));
    }

    /**
     * Post a Log message.
     * Post a Log message
     */
    setLog = async(requestParameters: SetLogRequest): Promise<ModelApiResponse> => {
        const response = await this.setLogRaw(requestParameters);
        return await response.value();
    }

}
