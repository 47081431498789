import { Box, Toolbar, AppBar as MUIAppBar } from '@mui/material'
import React, { ReactNode } from 'react'

interface AppBarProps {
    children: ReactNode
    toolbarStyles?: React.CSSProperties
}

const AppBar = ({ children, toolbarStyles }: AppBarProps) => {
    return (
        <Box sx={{ flexGrow: 1 }}>
            <MUIAppBar position="static" color="secondary">
                <Toolbar disableGutters sx={{ mr: '20px', ...toolbarStyles }}>
                    {children}
                </Toolbar>
            </MUIAppBar>
        </Box>
    )
}

export default AppBar