/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal Client Api
 * Bumbal API documentation
 *
 * The version of the OpenAPI document: 2.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel } from '../runtime';

export interface UsersFiltersModelInterface {
    id?: Array<number>;
    uuid?: Array<string>;
    pause_id?: Array<number>;
    role_id?: Array<number>;
    party_id?: Array<number>;
    tag_names?: Array<string>;
    zone_names?: Array<string>;
    system?: Array<boolean>;
    activated?: Array<boolean>;
}

/**
 * 
 * @export
 * @interface UsersFiltersModel
 */
export class UsersFiltersModel extends BaseModel<UsersFiltersModelInterface> {

    /**
     * 
     * @type {Array<number>}
     * @memberof UsersFiltersModel
     */
    id?: Array<number>;
    /**
     * unique per user
     * @type {Array<string>}
     * @memberof UsersFiltersModel
     */
    uuid?: Array<string>;
    /**
     * ids of pause schemes applied to user with teh role driver
     * @type {Array<number>}
     * @memberof UsersFiltersModel
     */
    pause_id?: Array<number>;
    /**
     * ids of the user roles, 1: Guest, 2: Driver, 3: Planner, 4: Manager, 5: Admin
     * @type {Array<number>}
     * @memberof UsersFiltersModel
     */
    role_id?: Array<number>;
    /**
     * Associated Party IDs
     * @type {Array<number>}
     * @memberof UsersFiltersModel
     */
    party_id?: Array<number>;
    /**
     * Tag names
     * @type {Array<string>}
     * @memberof UsersFiltersModel
     */
    tag_names?: Array<string>;
    /**
     * Zone names
     * @type {Array<string>}
     * @memberof UsersFiltersModel
     */
    zone_names?: Array<string>;
    /**
     * System users
     * @type {Array<boolean>}
     * @memberof UsersFiltersModel
     */
    system?: Array<boolean>;
    /**
     * Activated users
     * @type {Array<boolean>}
     * @memberof UsersFiltersModel
     */
    activated?: Array<boolean>;

    constructor(data?: UsersFiltersModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(value: UsersFiltersModelInterface): { [property: string]: any } {
        return {};
    }

}

export function UsersFiltersModelFromJSON(json: any): UsersFiltersModel {
    return UsersFiltersModelFromJSONTyped(json, false);
}

export function UsersFiltersModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): UsersFiltersModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = UsersFiltersModel;
    try {
        let localClass = require.context('../override', true)('./UsersFiltersModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'uuid': !exists(json, 'uuid') ? undefined : json['uuid'],
        'pause_id': !exists(json, 'pause_id') ? undefined : json['pause_id'],
        'role_id': !exists(json, 'role_id') ? undefined : json['role_id'],
        'party_id': !exists(json, 'party_id') ? undefined : json['party_id'],
        'tag_names': !exists(json, 'tag_names') ? undefined : json['tag_names'],
        'zone_names': !exists(json, 'zone_names') ? undefined : json['zone_names'],
        'system': !exists(json, 'system') ? undefined : json['system'],
        'activated': !exists(json, 'activated') ? undefined : json['activated'],
    });
}

export function UsersFiltersModelToJSON(value?: UsersFiltersModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = UsersFiltersModel;
    try {
        let localClass = require.context('../override', true)('./UsersFiltersModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return {
        
        'id': value.id,
        'uuid': value.uuid,
        'pause_id': value.pause_id,
        'role_id': value.role_id,
        'party_id': value.party_id,
        'tag_names': value.tag_names,
        'zone_names': value.zone_names,
        'system': value.system,
        'activated': value.activated,
        ...createClass.toJson(value),
    };
}


