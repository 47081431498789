import { Grid } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
const UserManualContent = () => {
    const userManList = [
        {
            slug: "adresgegevens-aanpassen-afspraak-portaal",
            url: "https://doc.clickup.com/2496135/d/2c5m7-4821/verzamel-doc-handleidingennieuw/2c5m7-30847/adresgegevens-aanpassen-afspraak-portaal",
        },
        {
            slug: "geldigheid-beschikbare-tijdvakken",
            url: "https://doc.clickup.com/2496135/d/2c5m7-4821/verzamel-doc-handleidingennieuw/2c5m7-30827/geldigheid-beschikbare-tijdvakken",
        },
        {
            slug: "vragenlijsten",
            url: "https://doc.clickup.com/2496135/d/2c5m7-4821/verzamel-doc-handleidingennieuw/2c5m7-18701/vragenlijsten",
        },
        {
            slug: "main",
            url: "https://doc.clickup.com/2496135/d/2c5m7-4821/verzamel-doc-handleidingennieuw/2c5m7-16301/doelstelling-van-dit-document",
        },
    ];

    let { page } = useParams();
    
    const destinationPage = () => {
        if (page === undefined)
        return userManList[3].url
        for (let i = 0; i < userManList.length; i++) {
            if (userManList[i].slug.includes(page)) {
                return userManList[i].url;
            }
        }
    };
    return (
        <Grid item md={12} sm={12} xs={12} alignItems="stretch">
            <div>
                <iframe
                    id="userman"
                    title="userman"
                    src={destinationPage()}
                    width="100%"
                    height="600px"
                >
                    <script
                        async
                        src="https://app-cdn.clickup.com/assets/js/forms-embed/v1.js"
                    ></script>
                </iframe>
            </div>
        </Grid>
    );
};

export default UserManualContent;
