import { Box, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { navLinks } from "./CourseOverview";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getAcademyMetadata } from "../../../utils/MetaDataUtil";
import completed from "../../../assets/completed.png";
import onefourth from "../../../assets/onefourth.png";
import half from "../../../assets/half.png";
import threefourth from "../../../assets/threefourth.png";
import notstarted from "../../../assets/notstarted.png";
import tinyArrow from "../../../assets/tinyArrow.png";
const ProgressIndicator = ({ userReducer }) => {
    const { t } = useTranslation();

    const academyMetaData = getAcademyMetadata(
        userReducer?.user?.meta_data ?? []
    );
    const progressIcon = (link) => {
        const totalProgress = academyMetaData.getTotalProgres(link.text, 0);
        switch (true) {
            case totalProgress === 100:
                return completed;
            case totalProgress > 75:
                return threefourth;
            case totalProgress > 50 && totalProgress <= 75:
                return half;
            case totalProgress > 25 && totalProgress <= 50:
                return onefourth;
            case totalProgress >= 0 && totalProgress <= 25:
                return notstarted;
        }
    };
    return (
        <Box
            style={{
                display: "flex",
                width: "60%",
                height: "100%",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                backgroundColor: "RGBA(0,0,0,0.40)",
                borderRadius: "10px",
            }}
        >
            <Box
                style={{
                    display: "flex",
                    width: "80%",
                    height: "25%",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Typography
                    variant="body2"
                    fontWeight="bold"
                    color="secondary.contrastText"
                    textAlign="center"
                >
                    {t("home.progressindicator.continue")}
                </Typography>
            </Box>
            {navLinks.map((link) => (
                <Box
                    key={link.text}
                    style={{
                        display: "flex",
                        width: "80%",
                        height: "40%",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                    }}
                >
                    <Link
                        to={link.to}
                        style={{
                            width: "100%",
                            height: "80%",
                            textDecoration: "none",
                        }}
                    >
                        <Box
                            style={{
                                display: "flex",
                                width: "100%",
                                height: "100%",
                                backgroundColor: "black",
                                flexDirection: "row",
                                alignItems: "center",
                                paddingInline: "2%",
                                borderRadius: "10px",
                                justifyContent: "flex-start",
                            }}
                        >
                            <Box
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    maxWidth:"30%",
                                    width:"50%"
                                }}
                            >
                                <Typography
                                    variant="caption"
                                    fontWeight="bold"
                                    color="secondary.contrastText"
                                >
                                    {t(`navigation.links.${link.text}`)}
                                </Typography>
                            </Box>
                                <Box
                                    style={{
                                        width: "25%",
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "left",
                                        justifyContent: "flex-end",
                                    }}
                                >
                                    <img
                                        src={tinyArrow}
                                        alt="arrow"
                                        width="35%"
                                      
                                    />
                                </Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    width: "100%",
                                    justifyContent: "flex-end",
                                }}
                            >
                                <Box
                                    style={{
                                        width: "20%",
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",

                                        justifyContent: "flex-end",
                                    }}
                                >
                                    <Typography
                                        variant="caption"
                                        fontWeight="bold"
                                        color="secondary.contrastText"
                                    >
                                        {academyMetaData?.getTotalProgres(
                                            link.text,
                                            0
                                        )}
                                        %
                                    </Typography>
                                </Box>
                                <Box
                                    style={{
                                        width: "20%",
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "flex-end",
                                    }}
                                >
                                    <img
                                        src={progressIcon(link)}
                                        alt={progressIcon(link)}
                                        style={{
                                            width: "60%",
                                            height: "fit-parent",
                                        }}
                                    />
                                </Box>
                            </Box>
                        </Box>
                    </Link>
                </Box>
            ))}
        </Box>
    );
};

const mapStateToProps = (state) => ({
    userReducer: state.user,
});

export default connect(mapStateToProps)(ProgressIndicator);
