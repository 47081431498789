/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal Client Api
 * Bumbal API documentation
 *
 * The version of the OpenAPI document: 2.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ModelApiResponse,
    ModelApiResponseFromJSON,
    ModelApiResponseToJSON,
    NoteCategoryListResponse,
    NoteCategoryListResponseFromJSON,
    NoteCategoryListResponseToJSON,
    NoteCategoryModel,
    NoteCategoryModelFromJSON,
    NoteCategoryModelToJSON,
    NoteCategoryRetrieveListArguments,
    NoteCategoryRetrieveListArgumentsFromJSON,
    NoteCategoryRetrieveListArgumentsToJSON,
} from '../models';

export interface DeleteNoteCategoryRequest {
    noteCategoryId: number;
}

export interface RetrieveListNoteCategoryRequest {
    arguments: NoteCategoryRetrieveListArguments;
}

export interface RetrieveNoteCategoryRequest {
    noteCategoryId: number;
}

export interface SetNoteCategoryRequest {
    body?: NoteCategoryModel;
}

/**
 * 
 */
export class NoteCategoryApi extends runtime.BaseAPI {

    /**
     * Delete a note-category
     * Delete a note-category
     */
    async deleteNoteCategoryRaw(requestParameters: DeleteNoteCategoryRequest): Promise<runtime.ApiResponse<ModelApiResponse>> {
        if (requestParameters.noteCategoryId === null || requestParameters.noteCategoryId === undefined) {
            throw new runtime.RequiredError('noteCategoryId','Required parameter requestParameters.noteCategoryId was null or undefined when calling deleteNoteCategory.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/note-category/{noteCategoryId}`.replace(`{${"noteCategoryId"}}`, encodeURIComponent(String(requestParameters.noteCategoryId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelApiResponseFromJSON(jsonValue));
    }

    /**
     * Delete a note-category
     * Delete a note-category
     */
    deleteNoteCategory = async(requestParameters: DeleteNoteCategoryRequest): Promise<ModelApiResponse> => {
        const response = await this.deleteNoteCategoryRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieve List of NoteCategories
     * Retrieve List of NoteCategories
     */
    async retrieveListNoteCategoryRaw(requestParameters: RetrieveListNoteCategoryRequest): Promise<runtime.ApiResponse<NoteCategoryListResponse>> {
        if (requestParameters.arguments === null || requestParameters.arguments === undefined) {
            throw new runtime.RequiredError('arguments','Required parameter requestParameters.arguments was null or undefined when calling retrieveListNoteCategory.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/note-category`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: NoteCategoryRetrieveListArgumentsToJSON(requestParameters.arguments),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => NoteCategoryListResponseFromJSON(jsonValue));
    }

    /**
     * Retrieve List of NoteCategories
     * Retrieve List of NoteCategories
     */
    retrieveListNoteCategory = async(requestParameters: RetrieveListNoteCategoryRequest): Promise<NoteCategoryListResponse> => {
        const response = await this.retrieveListNoteCategoryRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns a single note-category
     * Find note-category by ID
     */
    async retrieveNoteCategoryRaw(requestParameters: RetrieveNoteCategoryRequest): Promise<runtime.ApiResponse<NoteCategoryModel>> {
        if (requestParameters.noteCategoryId === null || requestParameters.noteCategoryId === undefined) {
            throw new runtime.RequiredError('noteCategoryId','Required parameter requestParameters.noteCategoryId was null or undefined when calling retrieveNoteCategory.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/note-category/{noteCategoryId}`.replace(`{${"noteCategoryId"}}`, encodeURIComponent(String(requestParameters.noteCategoryId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => NoteCategoryModelFromJSON(jsonValue));
    }

    /**
     * Returns a single note-category
     * Find note-category by ID
     */
    retrieveNoteCategory = async(requestParameters: RetrieveNoteCategoryRequest): Promise<NoteCategoryModel> => {
        const response = await this.retrieveNoteCategoryRaw(requestParameters);
        return await response.value();
    }

    /**
     * Set (create or update) a NoteCategory. If id is set in the data, and a corresponding note-category is found in Bumbal, an update will be performed.
     * Set (create or update) an NoteCategory
     */
    async setNoteCategoryRaw(requestParameters: SetNoteCategoryRequest): Promise<runtime.ApiResponse<ModelApiResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/note-category/set`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NoteCategoryModelToJSON(requestParameters.body),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelApiResponseFromJSON(jsonValue));
    }

    /**
     * Set (create or update) a NoteCategory. If id is set in the data, and a corresponding note-category is found in Bumbal, an update will be performed.
     * Set (create or update) an NoteCategory
     */
    setNoteCategory = async(requestParameters: SetNoteCategoryRequest): Promise<ModelApiResponse> => {
        const response = await this.setNoteCategoryRaw(requestParameters);
        return await response.value();
    }

}
