/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal Client Api
 * Bumbal API documentation
 *
 * The version of the OpenAPI document: 2.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ModelApiResponse,
    ModelApiResponseFromJSON,
    ModelApiResponseToJSON,
    PartyListResponse,
    PartyListResponseFromJSON,
    PartyListResponseToJSON,
    PartyModel,
    PartyModelFromJSON,
    PartyModelToJSON,
    PartyRetrieveListArguments,
    PartyRetrieveListArgumentsFromJSON,
    PartyRetrieveListArgumentsToJSON,
} from '../models';

export interface CreatePartyRequest {
    body?: PartyModel;
}

export interface DeletePartyRequest {
    partyId: number;
}

export interface RetrieveListPartyRequest {
    arguments: PartyRetrieveListArguments;
}

export interface RetrievePartyRequest {
    partyId: number;
}

export interface SetPartyRequest {
    body?: PartyModel;
}

export interface UpdatePartyRequest {
    partyId: number;
    body?: PartyModel;
}

/**
 * 
 */
export class PartyApi extends runtime.BaseAPI {

    /**
     * Create or update an Party. If id or links are set in the data, and a corresponding party    *     is found in Bumbal, an update will be performed.
     * Create or update an Party
     */
    async createPartyRaw(requestParameters: CreatePartyRequest): Promise<runtime.ApiResponse<ModelApiResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/party`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PartyModelToJSON(requestParameters.body),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelApiResponseFromJSON(jsonValue));
    }

    /**
     * Create or update an Party. If id or links are set in the data, and a corresponding party    *     is found in Bumbal, an update will be performed.
     * Create or update an Party
     */
    createParty = async(requestParameters: CreatePartyRequest): Promise<ModelApiResponse> => {
        const response = await this.createPartyRaw(requestParameters);
        return await response.value();
    }

    /**
     * Delete an party
     * Delete an party
     */
    async deletePartyRaw(requestParameters: DeletePartyRequest): Promise<runtime.ApiResponse<ModelApiResponse>> {
        if (requestParameters.partyId === null || requestParameters.partyId === undefined) {
            throw new runtime.RequiredError('partyId','Required parameter requestParameters.partyId was null or undefined when calling deleteParty.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/party/{partyId}`.replace(`{${"partyId"}}`, encodeURIComponent(String(requestParameters.partyId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelApiResponseFromJSON(jsonValue));
    }

    /**
     * Delete an party
     * Delete an party
     */
    deleteParty = async(requestParameters: DeletePartyRequest): Promise<ModelApiResponse> => {
        const response = await this.deletePartyRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieve List of Parties
     * Retrieve List of Parties
     */
    async retrieveListPartyRaw(requestParameters: RetrieveListPartyRequest): Promise<runtime.ApiResponse<PartyListResponse>> {
        if (requestParameters.arguments === null || requestParameters.arguments === undefined) {
            throw new runtime.RequiredError('arguments','Required parameter requestParameters.arguments was null or undefined when calling retrieveListParty.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/party`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PartyRetrieveListArgumentsToJSON(requestParameters.arguments),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PartyListResponseFromJSON(jsonValue));
    }

    /**
     * Retrieve List of Parties
     * Retrieve List of Parties
     */
    retrieveListParty = async(requestParameters: RetrieveListPartyRequest): Promise<PartyListResponse> => {
        const response = await this.retrieveListPartyRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns a single party
     * Find party by ID
     */
    async retrievePartyRaw(requestParameters: RetrievePartyRequest): Promise<runtime.ApiResponse<PartyModel>> {
        if (requestParameters.partyId === null || requestParameters.partyId === undefined) {
            throw new runtime.RequiredError('partyId','Required parameter requestParameters.partyId was null or undefined when calling retrieveParty.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/party/{partyId}`.replace(`{${"partyId"}}`, encodeURIComponent(String(requestParameters.partyId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PartyModelFromJSON(jsonValue));
    }

    /**
     * Returns a single party
     * Find party by ID
     */
    retrieveParty = async(requestParameters: RetrievePartyRequest): Promise<PartyModel> => {
        const response = await this.retrievePartyRaw(requestParameters);
        return await response.value();
    }

    /**
     * Set (create or update) an Party. If id or links are set in the data, and a corresponding party             is found in Bumbal, an update will be performed.
     * Set (create or update) an Party
     */
    async setPartyRaw(requestParameters: SetPartyRequest): Promise<runtime.ApiResponse<ModelApiResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/party/set`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PartyModelToJSON(requestParameters.body),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelApiResponseFromJSON(jsonValue));
    }

    /**
     * Set (create or update) an Party. If id or links are set in the data, and a corresponding party             is found in Bumbal, an update will be performed.
     * Set (create or update) an Party
     */
    setParty = async(requestParameters: SetPartyRequest): Promise<ModelApiResponse> => {
        const response = await this.setPartyRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update a party
     * Update a party
     */
    async updatePartyRaw(requestParameters: UpdatePartyRequest): Promise<runtime.ApiResponse<ModelApiResponse>> {
        if (requestParameters.partyId === null || requestParameters.partyId === undefined) {
            throw new runtime.RequiredError('partyId','Required parameter requestParameters.partyId was null or undefined when calling updateParty.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/party/{partyId}`.replace(`{${"partyId"}}`, encodeURIComponent(String(requestParameters.partyId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PartyModelToJSON(requestParameters.body),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelApiResponseFromJSON(jsonValue));
    }

    /**
     * Update a party
     * Update a party
     */
    updateParty = async(requestParameters: UpdatePartyRequest): Promise<ModelApiResponse> => {
        const response = await this.updatePartyRaw(requestParameters);
        return await response.value();
    }

}
