/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal Client Api
 * Bumbal API documentation
 *
 * The version of the OpenAPI document: 2.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel } from '../runtime';

export interface TimeSlotModelInterface {
    id?: number;
    time_slot_type?: TimeSlotModelTimeSlotTypeEnum;
    time_slot_type_id?: TimeSlotModelTimeSlotTypeIdEnum;
    activity_id?: number;
    date_from?: Date;
    time_from?: string;
    date_time_from?: Date;
    date_to?: Date;
    time_to?: string;
    date_time_to?: Date;
    planned?: boolean;
}

/**
 * 
 * @export
 * @interface TimeSlotModel
 */
export class TimeSlotModel extends BaseModel<TimeSlotModelInterface> {

    /**
     * 
     * @type {number}
     * @memberof TimeSlotModel
     */
    id?: number;
    /**
     * Time Slot Type. first-entry (:1), planned (:2), actual (:3)
     * @type {string}
     * @memberof TimeSlotModel
     */
    time_slot_type?: TimeSlotModelTimeSlotTypeEnum;
    /**
     * Time Slot Type ID, by default 1 if left out of the request. 1: first-entry, 2: planned, 3: actual
     * @type {number}
     * @memberof TimeSlotModel
     */
    time_slot_type_id?: TimeSlotModelTimeSlotTypeIdEnum;
    /**
     * Activity ID to which this TimeSlot belongs
     * @type {number}
     * @memberof TimeSlotModel
     */
    activity_id?: number;
    /**
     * 
     * @type {Date}
     * @memberof TimeSlotModel
     */
    date_from?: Date;
    /**
     * 
     * @type {string}
     * @memberof TimeSlotModel
     */
    time_from?: string;
    /**
     * 
     * @type {Date}
     * @memberof TimeSlotModel
     */
    date_time_from?: Date;
    /**
     * 
     * @type {Date}
     * @memberof TimeSlotModel
     */
    date_to?: Date;
    /**
     * 
     * @type {string}
     * @memberof TimeSlotModel
     */
    time_to?: string;
    /**
     * 
     * @type {Date}
     * @memberof TimeSlotModel
     */
    date_time_to?: Date;
    /**
     * true if this time_slot was used to plan the activity within
     * @type {boolean}
     * @memberof TimeSlotModel
     */
    planned?: boolean;

    constructor(data?: TimeSlotModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(value: TimeSlotModelInterface): { [property: string]: any } {
        return {};
    }

}

export function TimeSlotModelFromJSON(json: any): TimeSlotModel {
    return TimeSlotModelFromJSONTyped(json, false);
}

export function TimeSlotModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): TimeSlotModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = TimeSlotModel;
    try {
        let localClass = require.context('../override', true)('./TimeSlotModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'id': !exists(json, 'id') ? undefined : Number(json['id']),
        'time_slot_type': !exists(json, 'time_slot_type') ? undefined : json['time_slot_type'],
        'time_slot_type_id': !exists(json, 'time_slot_type_id') ? undefined : Number(json['time_slot_type_id']),
        'activity_id': !exists(json, 'activity_id') ? undefined : Number(json['activity_id']),
        'date_from': !exists(json, 'date_from') ? undefined : (new Date(json['date_from'].replace(' ', 'T'))),
        'time_from': !exists(json, 'time_from') ? undefined : json['time_from'],
        'date_time_from': !exists(json, 'date_time_from') ? undefined : (new Date(json['date_time_from'].replace(' ', 'T'))),
        'date_to': !exists(json, 'date_to') ? undefined : (new Date(json['date_to'].replace(' ', 'T'))),
        'time_to': !exists(json, 'time_to') ? undefined : json['time_to'],
        'date_time_to': !exists(json, 'date_time_to') ? undefined : (new Date(json['date_time_to'].replace(' ', 'T'))),
        'planned': !exists(json, 'planned') ? undefined : json['planned'],
    });
}

export function TimeSlotModelToJSON(value?: TimeSlotModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = TimeSlotModel;
    try {
        let localClass = require.context('../override', true)('./TimeSlotModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return {
        
        'id': value.id,
        'time_slot_type': value.time_slot_type,
        'time_slot_type_id': value.time_slot_type_id,
        'activity_id': value.activity_id,
        'date_from': value.date_from === undefined ? undefined : (value.date_from.toApiFormat().substr(0,10)),
        'time_from': value.time_from,
        'date_time_from': value.date_time_from === undefined ? undefined : (value.date_time_from.toApiFormat()),
        'date_to': value.date_to === undefined ? undefined : (value.date_to.toApiFormat().substr(0,10)),
        'time_to': value.time_to,
        'date_time_to': value.date_time_to === undefined ? undefined : (value.date_time_to.toApiFormat()),
        'planned': value.planned,
        ...createClass.toJson(value),
    };
}

/**
* @export
* @enum {string}
*/
export enum TimeSlotModelTimeSlotTypeEnum {
    FirstEntry = 'first-entry',
    Planned = 'planned',
    Actual = 'actual'
}
/**
* @export
* @enum {string}
*/
export enum TimeSlotModelTimeSlotTypeIdEnum {
    NUMBER_1 = 1,
    NUMBER_2 = 2,
    NUMBER_3 = 3
}


