/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal Client Api
 * Bumbal API documentation
 *
 * The version of the OpenAPI document: 2.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel } from '../runtime';
import {
    UserNotificationModel,
    UserNotificationModelFromJSON,
    UserNotificationModelFromJSONTyped,
    UserNotificationModelToJSON,
} from './';


export interface UserNotificationListResponseInterface {
    items?: Array<UserNotificationModel>;
    count_filtered?: number;
    count_unfiltered?: number;
    count_limited?: number;
}

/**
 * 
 * @export
 * @interface UserNotificationListResponse
 */
export class UserNotificationListResponse extends BaseModel<UserNotificationListResponseInterface> {

    /**
     * 
     * @type {Array<UserNotificationModel>}
     * @memberof UserNotificationListResponse
     */
    items?: Array<UserNotificationModel>;
    /**
     * Count of total items with filters in place
     * @type {number}
     * @memberof UserNotificationListResponse
     */
    count_filtered?: number;
    /**
     * Count of total items without filters in place
     * @type {number}
     * @memberof UserNotificationListResponse
     */
    count_unfiltered?: number;
    /**
     * Count of items with limit in place
     * @type {number}
     * @memberof UserNotificationListResponse
     */
    count_limited?: number;

    constructor(data?: UserNotificationListResponseInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(value: UserNotificationListResponseInterface): { [property: string]: any } {
        return {};
    }

}

export function UserNotificationListResponseFromJSON(json: any): UserNotificationListResponse {
    return UserNotificationListResponseFromJSONTyped(json, false);
}

export function UserNotificationListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserNotificationListResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = UserNotificationListResponse;
    try {
        let localClass = require.context('../override', true)('./UserNotificationListResponse');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'items': !exists(json, 'items') ? undefined : ((json['items'] as Array<any>).map(UserNotificationModelFromJSON)),
        'count_filtered': !exists(json, 'count_filtered') ? undefined : Number(json['count_filtered']),
        'count_unfiltered': !exists(json, 'count_unfiltered') ? undefined : Number(json['count_unfiltered']),
        'count_limited': !exists(json, 'count_limited') ? undefined : Number(json['count_limited']),
    });
}

export function UserNotificationListResponseToJSON(value?: UserNotificationListResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = UserNotificationListResponse;
    try {
        let localClass = require.context('../override', true)('./UserNotificationListResponse');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return {
        
        'items': value.items === undefined ? undefined : ((value.items as Array<any>).map(UserNotificationModelToJSON)),
        'count_filtered': value.count_filtered,
        'count_unfiltered': value.count_unfiltered,
        'count_limited': value.count_limited,
        ...createClass.toJson(value),
    };
}


