/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal Client Api
 * Bumbal API documentation
 *
 * The version of the OpenAPI document: 2.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel } from '../runtime';

export interface UserNotificationModelInterface {
    id?: number;
    user_id?: number;
    notification_id?: number;
    unread?: number;
    pinned?: number;
    archived?: number;
    archive_date?: Date;
    notification_created_at?: Date;
    object_id?: number;
    object_type?: number;
    notification_updated_at?: Date;
    object_type_name?: string;
}

/**
 * 
 * @export
 * @interface UserNotificationModel
 */
export class UserNotificationModel extends BaseModel<UserNotificationModelInterface> {

    /**
     * Unique Identifier
     * @type {number}
     * @memberof UserNotificationModel
     */
    id?: number;
    /**
     * User Identifier
     * @type {number}
     * @memberof UserNotificationModel
     */
    user_id?: number;
    /**
     * Notification Identifier
     * @type {number}
     * @memberof UserNotificationModel
     */
    notification_id?: number;
    /**
     * Unread status
     * @type {number}
     * @memberof UserNotificationModel
     */
    unread?: number;
    /**
     * Pinned status
     * @type {number}
     * @memberof UserNotificationModel
     */
    pinned?: number;
    /**
     * Archived status
     * @type {number}
     * @memberof UserNotificationModel
     */
    archived?: number;
    /**
     * Archive date
     * @type {Date}
     * @memberof UserNotificationModel
     */
    archive_date?: Date;
    /**
     * Creation date
     * @type {Date}
     * @memberof UserNotificationModel
     */
    notification_created_at?: Date;
    /**
     * Object Identifier
     * @type {number}
     * @memberof UserNotificationModel
     */
    object_id?: number;
    /**
     * Object type ID
     * @type {number}
     * @memberof UserNotificationModel
     */
    object_type?: number;
    /**
     * Modification date
     * @type {Date}
     * @memberof UserNotificationModel
     */
    notification_updated_at?: Date;
    /**
     * Object type name
     * @type {string}
     * @memberof UserNotificationModel
     */
    object_type_name?: string;

    constructor(data?: UserNotificationModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(value: UserNotificationModelInterface): { [property: string]: any } {
        return {};
    }

}

export function UserNotificationModelFromJSON(json: any): UserNotificationModel {
    return UserNotificationModelFromJSONTyped(json, false);
}

export function UserNotificationModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserNotificationModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = UserNotificationModel;
    try {
        let localClass = require.context('../override', true)('./UserNotificationModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'id': !exists(json, 'id') ? undefined : Number(json['id']),
        'user_id': !exists(json, 'user_id') ? undefined : Number(json['user_id']),
        'notification_id': !exists(json, 'notification_id') ? undefined : Number(json['notification_id']),
        'unread': !exists(json, 'unread') ? undefined : Number(json['unread']),
        'pinned': !exists(json, 'pinned') ? undefined : Number(json['pinned']),
        'archived': !exists(json, 'archived') ? undefined : Number(json['archived']),
        'archive_date': !exists(json, 'archive_date') ? undefined : (new Date(json['archive_date'].replace(' ', 'T'))),
        'notification_created_at': !exists(json, 'notification_created_at') ? undefined : (new Date(json['notification_created_at'].replace(' ', 'T'))),
        'object_id': !exists(json, 'object_id') ? undefined : Number(json['object_id']),
        'object_type': !exists(json, 'object_type') ? undefined : Number(json['object_type']),
        'notification_updated_at': !exists(json, 'notification_updated_at') ? undefined : (new Date(json['notification_updated_at'].replace(' ', 'T'))),
        'object_type_name': !exists(json, 'object_type_name') ? undefined : json['object_type_name'],
    });
}

export function UserNotificationModelToJSON(value?: UserNotificationModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = UserNotificationModel;
    try {
        let localClass = require.context('../override', true)('./UserNotificationModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return {
        
        'id': value.id,
        'user_id': value.user_id,
        'notification_id': value.notification_id,
        'unread': value.unread,
        'pinned': value.pinned,
        'archived': value.archived,
        'archive_date': value.archive_date === undefined ? undefined : (value.archive_date.toApiFormat()),
        'notification_created_at': value.notification_created_at === undefined ? undefined : (value.notification_created_at.toApiFormat()),
        'object_id': value.object_id,
        'object_type': value.object_type,
        'notification_updated_at': value.notification_updated_at === undefined ? undefined : (value.notification_updated_at.toApiFormat()),
        'object_type_name': value.object_type_name,
        ...createClass.toJson(value),
    };
}


