import { createTheme, ThemeOptions } from "@mui/material";

const baseThemeOptions: ThemeOptions = {
    palette: {
        primary: {
            main: '#FFCB04'
        },
        secondary: {
            main: '#000000',
            contrastText: '#fff'
        },
        mode: 'light',
    },
    shape: {
        borderRadius: 4,
    },
    typography: {
        fontFamily: 'Open Sans',
        fontSize: 12,
        h1: {
            fontSize: '5.125rem',
            lineHeight: '6rem',
            letterSpacing: '-1.5px',
        },
        h2: {
            fontSize: '3.25rem',
            lineHeight: '3.875rem',
            letterSpacing: '-0.5px',
        },
        h3: {
            fontSize: '2.625rem',
            lineHeight: '3.5rem',
        },
        h4: {
            fontSize: '1.875rem',
            letterSpacing: '0.25px',
            lineHeight: '2.625rem',
        },
        h5: {
            fontSize: '1.25rem',
            lineHeight: '2rem',
        },
        h6: {
            fontSize: '1.0625rem',
            fontWeight: 600,
            letterSpacing: '0.15px',
            lineHeight: '1.625rem'
        },
        subtitle1: {
            fontWeight: 600,
            fontSize: '0.875rem',
            letterSpacing: '0.15px',
            lineHeight: '1.25rem'
        },
        subtitle2: {
            fontWeight: 600,
            letterSpacing: '0.1px',
            lineHeight: '1rem'
        },
        body1: {
            fontSize: '0.875rem',
            lineHeight: '1.25rem'
        },
        body2: {
            letterSpacing: '0.25px',
            lineHeight: '1rem'
        },
        button: {
            fontSize: '0.875rem',
            fontWeight: 700,
            letterSpacing: '0.4px',
            textTransform: 'none',
        },
        caption: {
            fontSize: '0.625rem',
            letterSpacing: '0.4px',
        }
    },

    components: {

    }
};

const baseTheme = createTheme(baseThemeOptions);

const theme = createTheme(baseTheme, {
    components: {
        MuiSvgIcon: {
            styleOverrides: {
                root: {
                    fontSize: '1.5rem',
                }
            }
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    lineHeight: '1.15rem',
                    backgroundColor: '#fff'
                },
                input: {
                    height: '1.25rem'
                }
            }
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    '&:first-letter': {
                        textTransform: 'uppercase',
                    },
                }
            }
        },
        MuiFormControlLabel: {
            styleOverrides: {
                label: {
                    '&:first-letter': {
                        textTransform: 'uppercase',
                    }
                }
            }
        },
        MuiInputLabel: {
            defaultProps: {
                shrink: true
            }
        },
        MuiOutlinedInput: {
            defaultProps: {
                notched: true
            }
        },
        MuiTextField: {
            defaultProps: {
                variant: 'outlined',
                margin: 'dense',
                fullWidth: true
            }
        },
        MuiButtonGroup: {
            styleOverrides: {
                grouped: {
                    '&:not(:last-of-type)': {
                        borderRight: 'none',
                        borderRadius: '10px 10px 0px 0px',
                    },
                    '&:not(:first-of-type)': {
                        borderRadius: '10px 10px 0px 0px',
                    }
                }
            }
        }
    },
});


export default theme;