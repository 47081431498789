import { Box, Switch, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { Version } from "../../../interface/CourseContent";

interface CourseHeaderProps {
    title: string;
    version: Version;
    isActive: boolean;
    showSwitch: boolean;
    changeVersion: (version: number) => void;
}

const CourseHeader = ({
    title,
    version,
    isActive,
    showSwitch,
    changeVersion,
}: CourseHeaderProps) => {
    const { t } = useTranslation();

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        changeVersion(e.target.checked ? 1 : 0);
    };

    return (
        <Box
            display="flex"
            sx={{
                py: 5,
                flexDirection: "column",
                alignItems: "center",
                position: "relative",
                boxShadow: "0px 1px 27px -16px rgba(0,0,0,0.73)",
            }}
        >
            <Box
                display="flex"
                sx={{ justifyContent: "space-between", width: "70em" }}
            >
                <Box width="150px"></Box>
                <Box textAlign="center">
                    <Typography variant="h3">{title}</Typography>
                </Box>
                <Box textAlign="center" width="150px">
                    {showSwitch && (
                        <>
                            <Switch
                                aria-label="Switch interface"
                                onChange={handleChange}
                                checked={isActive}
                            />
                            <Typography variant="body1">
                                {t("course.switch_label")}
                            </Typography>
                        </>
                    )}
                </Box>
            </Box>

            <Box
                component="img"
                sx={{
                  height: '250px',
                  width: '600px'
                }}
                src={`images/${version.image}`}
            >
              
            </Box>
        </Box>
    );
};

export default CourseHeader;
