/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal Client Api
 * Bumbal API documentation
 *
 * The version of the OpenAPI document: 2.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel } from '../runtime';

export interface LogModelInterface {
    original_message?: string;
    message?: string;
    full_message?: object;
    channel?: string;
    level_text?: LogModelLevelTextEnum;
    http_method?: LogModelHttpMethodEnum;
    api_endpoint?: string;
    client_ip?: string;
    request_id?: string;
    log_type?: string;
    timestamp?: string;
    user?: object;
}

/**
 * 
 * @export
 * @interface LogModel
 */
export class LogModel extends BaseModel<LogModelInterface> {

    /**
     * The log message.
     * @type {string}
     * @memberof LogModel
     */
    original_message?: string;
    /**
     * The log message. Can be decorated with extra data
     * @type {string}
     * @memberof LogModel
     */
    message?: string;
    /**
     * Any additional data you want to store about this log message
     * @type {object}
     * @memberof LogModel
     */
    full_message?: object;
    /**
     * Groups log messages
     * @type {string}
     * @memberof LogModel
     */
    channel?: string;
    /**
     * Log level
     * @type {string}
     * @memberof LogModel
     */
    level_text?: LogModelLevelTextEnum;
    /**
     * HTTP method
     * @type {string}
     * @memberof LogModel
     */
    http_method?: LogModelHttpMethodEnum;
    /**
     * Path of API endpoint
     * @type {string}
     * @memberof LogModel
     */
    api_endpoint?: string;
    /**
     * Client IP
     * @type {string}
     * @memberof LogModel
     */
    client_ip?: string;
    /**
     * Identifier to relate different log messages
     * @type {string}
     * @memberof LogModel
     */
    request_id?: string;
    /**
     * Type of log message
     * @type {string}
     * @memberof LogModel
     */
    log_type?: string;
    /**
     * Datetime of log message
     * @type {string}
     * @memberof LogModel
     */
    timestamp?: string;
    /**
     * User details
     * @type {object}
     * @memberof LogModel
     */
    user?: object;

    constructor(data?: LogModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(value: LogModelInterface): { [property: string]: any } {
        return {};
    }

}

export function LogModelFromJSON(json: any): LogModel {
    return LogModelFromJSONTyped(json, false);
}

export function LogModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): LogModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = LogModel;
    try {
        let localClass = require.context('../override', true)('./LogModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'original_message': !exists(json, 'original_message') ? undefined : json['original_message'],
        'message': !exists(json, 'message') ? undefined : json['message'],
        'full_message': !exists(json, 'full_message') ? undefined : json['full_message'],
        'channel': !exists(json, 'channel') ? undefined : json['channel'],
        'level_text': !exists(json, 'level_text') ? undefined : json['level_text'],
        'http_method': !exists(json, 'http_method') ? undefined : json['http_method'],
        'api_endpoint': !exists(json, 'api_endpoint') ? undefined : json['api_endpoint'],
        'client_ip': !exists(json, 'client_ip') ? undefined : json['client_ip'],
        'request_id': !exists(json, 'request_id') ? undefined : json['request_id'],
        'log_type': !exists(json, 'log_type') ? undefined : json['log_type'],
        'timestamp': !exists(json, 'timestamp') ? undefined : json['timestamp'],
        'user': !exists(json, 'user') ? undefined : json['user'],
    });
}

export function LogModelToJSON(value?: LogModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = LogModel;
    try {
        let localClass = require.context('../override', true)('./LogModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return {
        
        'original_message': value.original_message,
        'message': value.message,
        'full_message': value.full_message,
        'channel': value.channel,
        'level_text': value.level_text,
        'http_method': value.http_method,
        'api_endpoint': value.api_endpoint,
        'client_ip': value.client_ip,
        'request_id': value.request_id,
        'log_type': value.log_type,
        'timestamp': value.timestamp,
        'user': value.user,
        ...createClass.toJson(value),
    };
}

/**
* @export
* @enum {string}
*/
export enum LogModelLevelTextEnum {
    Debug = 'debug',
    Info = 'info',
    Notice = 'notice',
    Warning = 'warning',
    Error = 'error',
    Critical = 'critical',
    Alert = 'alert',
    Emergency = 'emergency'
}
/**
* @export
* @enum {string}
*/
export enum LogModelHttpMethodEnum {
    POST = 'POST',
    GET = 'GET',
    PATCH = 'PATCH',
    DELETE = 'DELETE',
    PUT = 'PUT'
}


