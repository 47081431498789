/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal Client Api
 * Bumbal API documentation
 *
 * The version of the OpenAPI document: 2.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiResponse401,
    ApiResponse401FromJSON,
    ApiResponse401ToJSON,
    ApiResponse403,
    ApiResponse403FromJSON,
    ApiResponse403ToJSON,
    ApiResponse405,
    ApiResponse405FromJSON,
    ApiResponse405ToJSON,
    ApiResponse5,
    ApiResponse5FromJSON,
    ApiResponse5ToJSON,
    ApiResponse6,
    ApiResponse6FromJSON,
    ApiResponse6ToJSON,
    ApiResponse7,
    ApiResponse7FromJSON,
    ApiResponse7ToJSON,
    MetaDataListResponse,
    MetaDataListResponseFromJSON,
    MetaDataListResponseToJSON,
    MetaDataModel,
    MetaDataModelFromJSON,
    MetaDataModelToJSON,
    MetaDataRetrieveListArguments,
    MetaDataRetrieveListArgumentsFromJSON,
    MetaDataRetrieveListArgumentsToJSON,
    ModelApiResponse,
    ModelApiResponseFromJSON,
    ModelApiResponseToJSON,
} from '../models';

export interface CreateMetaDataRequest {
    body?: MetaDataModel;
}

export interface DeleteMetaDataRequest {
    metadataId: number;
}

export interface RetrieveListMetaDataRequest {
    arguments: MetaDataRetrieveListArguments;
}

export interface RetrieveMetaDataRequest {
    metadataId: number;
    includeObjectTypeName?: boolean;
    includeRecordInfo?: boolean;
}

export interface SetMetaDataRequest {
    body?: MetaDataModel;
}

export interface UpdateMetaDataRequest {
    metadataId: number;
    body?: MetaDataModel;
}

/**
 * 
 */
export class MetadataApi extends runtime.BaseAPI {

    /**
     * Add a new MetaData
     * Add a new MetaData
     */
    async createMetaDataRaw(requestParameters: CreateMetaDataRequest): Promise<runtime.ApiResponse<ModelApiResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/metadata`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MetaDataModelToJSON(requestParameters.body),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelApiResponseFromJSON(jsonValue));
    }

    /**
     * Add a new MetaData
     * Add a new MetaData
     */
    createMetaData = async(requestParameters: CreateMetaDataRequest): Promise<ModelApiResponse> => {
        const response = await this.createMetaDataRaw(requestParameters);
        return await response.value();
    }

    /**
     * Delete a Metadata entry
     * Delete a MetaData entry
     */
    async deleteMetaDataRaw(requestParameters: DeleteMetaDataRequest): Promise<runtime.ApiResponse<ApiResponse6>> {
        if (requestParameters.metadataId === null || requestParameters.metadataId === undefined) {
            throw new runtime.RequiredError('metadataId','Required parameter requestParameters.metadataId was null or undefined when calling deleteMetaData.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/metadata/{metadataId}`.replace(`{${"metadataId"}}`, encodeURIComponent(String(requestParameters.metadataId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResponse6FromJSON(jsonValue));
    }

    /**
     * Delete a Metadata entry
     * Delete a MetaData entry
     */
    deleteMetaData = async(requestParameters: DeleteMetaDataRequest): Promise<ApiResponse6> => {
        const response = await this.deleteMetaDataRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieve List of MetaData
     * Retrieve List of MetaData
     */
    async retrieveListMetaDataRaw(requestParameters: RetrieveListMetaDataRequest): Promise<runtime.ApiResponse<MetaDataListResponse>> {
        if (requestParameters.arguments === null || requestParameters.arguments === undefined) {
            throw new runtime.RequiredError('arguments','Required parameter requestParameters.arguments was null or undefined when calling retrieveListMetaData.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/metadata`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MetaDataRetrieveListArgumentsToJSON(requestParameters.arguments),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MetaDataListResponseFromJSON(jsonValue));
    }

    /**
     * Retrieve List of MetaData
     * Retrieve List of MetaData
     */
    retrieveListMetaData = async(requestParameters: RetrieveListMetaDataRequest): Promise<MetaDataListResponse> => {
        const response = await this.retrieveListMetaDataRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieve an MetaData
     * Retrieve a MetaData
     */
    async retrieveMetaDataRaw(requestParameters: RetrieveMetaDataRequest): Promise<runtime.ApiResponse<MetaDataModel>> {
        if (requestParameters.metadataId === null || requestParameters.metadataId === undefined) {
            throw new runtime.RequiredError('metadataId','Required parameter requestParameters.metadataId was null or undefined when calling retrieveMetaData.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.includeObjectTypeName !== undefined) {
            queryParameters['include_object_type_name'] = requestParameters.includeObjectTypeName;
        }

        if (requestParameters.includeRecordInfo !== undefined) {
            queryParameters['include_record_info'] = requestParameters.includeRecordInfo;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/metadata/{metadataId}`.replace(`{${"metadataId"}}`, encodeURIComponent(String(requestParameters.metadataId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MetaDataModelFromJSON(jsonValue));
    }

    /**
     * Retrieve an MetaData
     * Retrieve a MetaData
     */
    retrieveMetaData = async(requestParameters: RetrieveMetaDataRequest): Promise<MetaDataModel> => {
        const response = await this.retrieveMetaDataRaw(requestParameters);
        return await response.value();
    }

    /**
     * Set (create or update) a meta data record. If id or name is set in the data, and a corresponding meta data record is found in Bumbal, an update will be performed.
     * Set (create or update) a MetaData record
     */
    async setMetaDataRaw(requestParameters: SetMetaDataRequest): Promise<runtime.ApiResponse<ModelApiResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/metadata/set`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MetaDataModelToJSON(requestParameters.body),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelApiResponseFromJSON(jsonValue));
    }

    /**
     * Set (create or update) a meta data record. If id or name is set in the data, and a corresponding meta data record is found in Bumbal, an update will be performed.
     * Set (create or update) a MetaData record
     */
    setMetaData = async(requestParameters: SetMetaDataRequest): Promise<ModelApiResponse> => {
        const response = await this.setMetaDataRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update a specific MetaData object
     * Update a specific MetaData object
     */
    async updateMetaDataRaw(requestParameters: UpdateMetaDataRequest): Promise<runtime.ApiResponse<ApiResponse5>> {
        if (requestParameters.metadataId === null || requestParameters.metadataId === undefined) {
            throw new runtime.RequiredError('metadataId','Required parameter requestParameters.metadataId was null or undefined when calling updateMetaData.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/metadata/{metadataId}`.replace(`{${"metadataId"}}`, encodeURIComponent(String(requestParameters.metadataId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MetaDataModelToJSON(requestParameters.body),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResponse5FromJSON(jsonValue));
    }

    /**
     * Update a specific MetaData object
     * Update a specific MetaData object
     */
    updateMetaData = async(requestParameters: UpdateMetaDataRequest): Promise<ApiResponse5> => {
        const response = await this.updateMetaDataRaw(requestParameters);
        return await response.value();
    }

}
