/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal Client Api
 * Bumbal API documentation
 *
 * The version of the OpenAPI document: 2.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    FileCopyArguments,
    FileCopyArgumentsFromJSON,
    FileCopyArgumentsToJSON,
    FileModel,
    FileModelFromJSON,
    FileModelToJSON,
    FileRetrieveListArguments,
    FileRetrieveListArgumentsFromJSON,
    FileRetrieveListArgumentsToJSON,
    ModelApiResponse,
    ModelApiResponseFromJSON,
    ModelApiResponseToJSON,
} from '../models';

export interface DeleteFileRequest {
    fileId: number;
}

export interface FileCopyRequest {
    arguments: FileCopyArguments;
}

export interface RetrieveFileRequest {
    fileId: number;
}

export interface RetrieveListFileRequest {
    arguments: FileRetrieveListArguments;
}

export interface SetFileRequest {
    body?: FileModel;
}

/**
 * 
 */
export class FileApi extends runtime.BaseAPI {

    /**
     * Delete an File
     * Delete an File
     */
    async deleteFileRaw(requestParameters: DeleteFileRequest): Promise<runtime.ApiResponse<ModelApiResponse>> {
        if (requestParameters.fileId === null || requestParameters.fileId === undefined) {
            throw new runtime.RequiredError('fileId','Required parameter requestParameters.fileId was null or undefined when calling deleteFile.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/file/{fileId}`.replace(`{${"fileId"}}`, encodeURIComponent(String(requestParameters.fileId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelApiResponseFromJSON(jsonValue));
    }

    /**
     * Delete an File
     * Delete an File
     */
    deleteFile = async(requestParameters: DeleteFileRequest): Promise<ModelApiResponse> => {
        const response = await this.deleteFileRaw(requestParameters);
        return await response.value();
    }

    /**
     * Copy file from one object to another
     * Copy File
     */
    async fileCopyRaw(requestParameters: FileCopyRequest): Promise<runtime.ApiResponse<ModelApiResponse>> {
        if (requestParameters.arguments === null || requestParameters.arguments === undefined) {
            throw new runtime.RequiredError('arguments','Required parameter requestParameters.arguments was null or undefined when calling fileCopy.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/file/copy`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FileCopyArgumentsToJSON(requestParameters.arguments),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelApiResponseFromJSON(jsonValue));
    }

    /**
     * Copy file from one object to another
     * Copy File
     */
    fileCopy = async(requestParameters: FileCopyRequest): Promise<ModelApiResponse> => {
        const response = await this.fileCopyRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieve an File
     * Retrieve a File
     */
    async retrieveFileRaw(requestParameters: RetrieveFileRequest): Promise<runtime.ApiResponse<FileModel>> {
        if (requestParameters.fileId === null || requestParameters.fileId === undefined) {
            throw new runtime.RequiredError('fileId','Required parameter requestParameters.fileId was null or undefined when calling retrieveFile.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/file/{fileId}`.replace(`{${"fileId"}}`, encodeURIComponent(String(requestParameters.fileId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => FileModelFromJSON(jsonValue));
    }

    /**
     * Retrieve an File
     * Retrieve a File
     */
    retrieveFile = async(requestParameters: RetrieveFileRequest): Promise<FileModel> => {
        const response = await this.retrieveFileRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieve List of Files
     * Retrieve List of Files
     */
    async retrieveListFileRaw(requestParameters: RetrieveListFileRequest): Promise<runtime.ApiResponse<Array<FileModel>>> {
        if (requestParameters.arguments === null || requestParameters.arguments === undefined) {
            throw new runtime.RequiredError('arguments','Required parameter requestParameters.arguments was null or undefined when calling retrieveListFile.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/file`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: FileRetrieveListArgumentsToJSON(requestParameters.arguments),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FileModelFromJSON));
    }

    /**
     * Retrieve List of Files
     * Retrieve List of Files
     */
    retrieveListFile = async(requestParameters: RetrieveListFileRequest): Promise<Array<FileModel>> => {
        const response = await this.retrieveListFileRaw(requestParameters);
        return await response.value();
    }

    /**
     * Create or Update a File Object
     * Create or Update a File Object
     */
    async setFileRaw(requestParameters: SetFileRequest): Promise<runtime.ApiResponse<ModelApiResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/file/set`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FileModelToJSON(requestParameters.body),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelApiResponseFromJSON(jsonValue));
    }

    /**
     * Create or Update a File Object
     * Create or Update a File Object
     */
    setFile = async(requestParameters: SetFileRequest): Promise<ModelApiResponse> => {
        const response = await this.setFileRaw(requestParameters);
        return await response.value();
    }

}
