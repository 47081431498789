/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal Client Api
 * Bumbal API documentation
 *
 * The version of the OpenAPI document: 2.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ModelApiResponse,
    ModelApiResponseFromJSON,
    ModelApiResponseToJSON,
    PackageTypeListResponse,
    PackageTypeListResponseFromJSON,
    PackageTypeListResponseToJSON,
    PackageTypeModel,
    PackageTypeModelFromJSON,
    PackageTypeModelToJSON,
    PackageTypeRetrieveListArguments,
    PackageTypeRetrieveListArgumentsFromJSON,
    PackageTypeRetrieveListArgumentsToJSON,
} from '../models';

export interface CreatePackageTypeRequest {
    body?: PackageTypeModel;
}

export interface DeletePackageTypeRequest {
    packageTypeId: number;
}

export interface RetrieveListPackageTypeRequest {
    arguments: PackageTypeRetrieveListArguments;
}

export interface RetrievePackageTypeRequest {
    packageTypeId: number;
}

export interface SetPackageTypeRequest {
    body?: PackageTypeModel;
}

export interface UpdatePackageTypeRequest {
    packageTypeId: number;
    body?: PackageTypeModel;
}

/**
 * 
 */
export class PackageTypeApi extends runtime.BaseAPI {

    /**
     * Create or update a PackageType. If id or links are set in the data, and a corresponding package-type    *     is found in Bumbal, an update will be performed.
     * Create or update a Package Line
     */
    async createPackageTypeRaw(requestParameters: CreatePackageTypeRequest): Promise<runtime.ApiResponse<ModelApiResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/package-type`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PackageTypeModelToJSON(requestParameters.body),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelApiResponseFromJSON(jsonValue));
    }

    /**
     * Create or update a PackageType. If id or links are set in the data, and a corresponding package-type    *     is found in Bumbal, an update will be performed.
     * Create or update a Package Line
     */
    createPackageType = async(requestParameters: CreatePackageTypeRequest): Promise<ModelApiResponse> => {
        const response = await this.createPackageTypeRaw(requestParameters);
        return await response.value();
    }

    /**
     * Delete an package-type
     * Delete an package-type
     */
    async deletePackageTypeRaw(requestParameters: DeletePackageTypeRequest): Promise<runtime.ApiResponse<ModelApiResponse>> {
        if (requestParameters.packageTypeId === null || requestParameters.packageTypeId === undefined) {
            throw new runtime.RequiredError('packageTypeId','Required parameter requestParameters.packageTypeId was null or undefined when calling deletePackageType.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/package-type/{packageTypeId}`.replace(`{${"packageTypeId"}}`, encodeURIComponent(String(requestParameters.packageTypeId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelApiResponseFromJSON(jsonValue));
    }

    /**
     * Delete an package-type
     * Delete an package-type
     */
    deletePackageType = async(requestParameters: DeletePackageTypeRequest): Promise<ModelApiResponse> => {
        const response = await this.deletePackageTypeRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieve List of PackageTypes
     * Retrieve List of PackageTypes
     */
    async retrieveListPackageTypeRaw(requestParameters: RetrieveListPackageTypeRequest): Promise<runtime.ApiResponse<PackageTypeListResponse>> {
        if (requestParameters.arguments === null || requestParameters.arguments === undefined) {
            throw new runtime.RequiredError('arguments','Required parameter requestParameters.arguments was null or undefined when calling retrieveListPackageType.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/package-type`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PackageTypeRetrieveListArgumentsToJSON(requestParameters.arguments),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PackageTypeListResponseFromJSON(jsonValue));
    }

    /**
     * Retrieve List of PackageTypes
     * Retrieve List of PackageTypes
     */
    retrieveListPackageType = async(requestParameters: RetrieveListPackageTypeRequest): Promise<PackageTypeListResponse> => {
        const response = await this.retrieveListPackageTypeRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns a single package-type
     * Find package-type by ID
     */
    async retrievePackageTypeRaw(requestParameters: RetrievePackageTypeRequest): Promise<runtime.ApiResponse<PackageTypeModel>> {
        if (requestParameters.packageTypeId === null || requestParameters.packageTypeId === undefined) {
            throw new runtime.RequiredError('packageTypeId','Required parameter requestParameters.packageTypeId was null or undefined when calling retrievePackageType.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/package-type/{packageTypeId}`.replace(`{${"packageTypeId"}}`, encodeURIComponent(String(requestParameters.packageTypeId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PackageTypeModelFromJSON(jsonValue));
    }

    /**
     * Returns a single package-type
     * Find package-type by ID
     */
    retrievePackageType = async(requestParameters: RetrievePackageTypeRequest): Promise<PackageTypeModel> => {
        const response = await this.retrievePackageTypeRaw(requestParameters);
        return await response.value();
    }

    /**
     * Set (create or update) an PackageType. If id or links are set in the data, and a corresponding package-type is found in Bumbal, an update will be performed.
     * Set (create or update) an PackageType
     */
    async setPackageTypeRaw(requestParameters: SetPackageTypeRequest): Promise<runtime.ApiResponse<ModelApiResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/package-type/set`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PackageTypeModelToJSON(requestParameters.body),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelApiResponseFromJSON(jsonValue));
    }

    /**
     * Set (create or update) an PackageType. If id or links are set in the data, and a corresponding package-type is found in Bumbal, an update will be performed.
     * Set (create or update) an PackageType
     */
    setPackageType = async(requestParameters: SetPackageTypeRequest): Promise<ModelApiResponse> => {
        const response = await this.setPackageTypeRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update a package-type
     * Update a package-type
     */
    async updatePackageTypeRaw(requestParameters: UpdatePackageTypeRequest): Promise<runtime.ApiResponse<ModelApiResponse>> {
        if (requestParameters.packageTypeId === null || requestParameters.packageTypeId === undefined) {
            throw new runtime.RequiredError('packageTypeId','Required parameter requestParameters.packageTypeId was null or undefined when calling updatePackageType.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/package-type/{packageTypeId}`.replace(`{${"packageTypeId"}}`, encodeURIComponent(String(requestParameters.packageTypeId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PackageTypeModelToJSON(requestParameters.body),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelApiResponseFromJSON(jsonValue));
    }

    /**
     * Update a package-type
     * Update a package-type
     */
    updatePackageType = async(requestParameters: UpdatePackageTypeRequest): Promise<ModelApiResponse> => {
        const response = await this.updatePackageTypeRaw(requestParameters);
        return await response.value();
    }

}
