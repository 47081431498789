import React from 'react';
import AppBar from './AppBar';
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import { Box, IconButton, Link, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const Footer = () => {
    const { t } = useTranslation();

    return (
        <AppBar toolbarStyles={{ padding: '25px 10px', display: 'flex', alignItems: 'flex-end' }}>
            <Box sx={{ flexGrow: 1 }}>
                <IconButton href="https://www.facebook.com/bumbalsoftware" target="_blank" component={Link} sx={{ pb: '0px' }}>
                    <FacebookIcon htmlColor='#fff' />
                </IconButton>
                <IconButton href="https://www.youtube.com/channel/UCzW83_buD_Cx62dub7PSpjw?view_as=subscriber" target="_blank" component={Link} sx={{ pb: '0px' }}>
                    <YouTubeIcon htmlColor='#fff' />
                </IconButton>
                <IconButton href="https://www.linkedin.com/uas/login?session_redirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2F17915829%2Fadmin%2F" target="_blank" component={Link} sx={{ pb: '0px' }}>
                    <LinkedInIcon htmlColor='#fff' />
                </IconButton>
                <IconButton href="https://www.instagram.com/bumbalsoftware/" target="_blank" component={Link} sx={{ pb: '0px' }}>
                    <InstagramIcon htmlColor='#fff' />
                </IconButton>
            </Box>
            <Box sx={{ textAlign: 'right' }}>
                <Typography variant="body1" sx={{ underline: '1px solid' }}>{t('navigation.contact_us')}</Typography>
                <Typography variant="body2">Nieuwlandstraat 48 5038 SP Tilburg</Typography>
                <Typography variant="body2">bumbal@support.eu</Typography>
                <Typography variant="body2" color="primary">+31 013 220 1600</Typography>
            </Box>
        </AppBar>
    );
}

export default Footer;
