import { CredentialsModel, CredentialsModelInterface } from "../models";


export default class Credentials extends CredentialsModel {

    static toJson(value: CredentialsModelInterface): { [property: string]: any } {
        return {
            ...value,
            'return_jwt': true
        };
    }
}