import React from 'react';
import AppBar from './AppBar';
import Box from '@mui/material/Box';
import { useDispatch } from 'react-redux';
import { Button } from '@mui/material';
import NavLinkList from './components/NavLinkList';
import { BBNavLink } from '../../../interface/NavLink'
import { useTranslation } from 'react-i18next';
import { ReactComponent as BBLogo } from '../../../assets/logo_bumbal.svg';
import ActionTypes from '../../../settings/ActionTypes';

interface NavbarProps {
    isLoggedIn: Boolean
}

const Navbar = ({ isLoggedIn }: NavbarProps) => {
    const dispatch = useDispatch();

    const { t } = useTranslation();

    const navLinks: BBNavLink[] = [
        {
            to: '/',
            text: t('navigation.links.home')
        },
        {
            text: t('navigation.links.users'),
            menuItems: [
                {
                    to: '/driver',
                    text: t('navigation.links.driver')
                },
                {
                    to: '/admin',
                    text: t('navigation.links.admin')
                },
                {
                    to: '/planner',
                    text: t('navigation.links.planner')
                }
            ]
        },
        {
            to: '/usermanual/',
            text: t('navigation.links.usermanual')
        },
        {
            to: '/contact',
            text: t('navigation.links.contact')
        }
    ];

    return (
        <AppBar toolbarStyles={{ position: 'relative', height: '90px' }}>
            <Box
                sx={{
                    backgroundColor: 'primary.main',
                    height: "140px",
                    width: "320px",
                    px: "70px",
                    mr: "30px",
                    position: 'absolute',
                    borderBottomRightRadius: '15px',
                    zIndex: 1,
                }}
            >
                <BBLogo style={{ paddingRight: '20px' }} />
            </Box>
            {
                isLoggedIn &&
                <>
                    <NavLinkList links={navLinks} />
                    <Button
                        onClick={() => dispatch({ type: ActionTypes.API_SIGN_OUT })}
                        variant="contained"
                        sx={{ ml: 'auto', fontWeight: 'bold' }}
                    >
                        {t('navigation.sign_out')}
                    </Button>
                </>
            }
        </AppBar >
    );
}

export default Navbar;