/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal Client Api
 * Bumbal API documentation
 *
 * The version of the OpenAPI document: 2.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ModelApiResponse,
    ModelApiResponseFromJSON,
    ModelApiResponseToJSON,
    ZoneListResponse,
    ZoneListResponseFromJSON,
    ZoneListResponseToJSON,
    ZoneModel,
    ZoneModelFromJSON,
    ZoneModelToJSON,
    ZoneRetrieveListArguments,
    ZoneRetrieveListArgumentsFromJSON,
    ZoneRetrieveListArgumentsToJSON,
} from '../models';

export interface CreateZoneRequest {
    body?: ZoneModel;
}

export interface DeleteZoneRequest {
    zoneId: number;
}

export interface RetrieveListZoneRequest {
    arguments: ZoneRetrieveListArguments;
}

export interface RetrieveZoneRequest {
    zoneId: number;
}

export interface SetZoneRequest {
    body?: ZoneModel;
}

export interface UpdateZoneRequest {
    zoneId: number;
}

/**
 * 
 */
export class ZoneApi extends runtime.BaseAPI {

    /**
     * Add a new Zone
     * Add a new Zone
     */
    async createZoneRaw(requestParameters: CreateZoneRequest): Promise<runtime.ApiResponse<ModelApiResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/zone`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ZoneModelToJSON(requestParameters.body),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelApiResponseFromJSON(jsonValue));
    }

    /**
     * Add a new Zone
     * Add a new Zone
     */
    createZone = async(requestParameters: CreateZoneRequest): Promise<ModelApiResponse> => {
        const response = await this.createZoneRaw(requestParameters);
        return await response.value();
    }

    /**
     * Delete a Zone
     * Delete a Zone
     */
    async deleteZoneRaw(requestParameters: DeleteZoneRequest): Promise<runtime.ApiResponse<ModelApiResponse>> {
        if (requestParameters.zoneId === null || requestParameters.zoneId === undefined) {
            throw new runtime.RequiredError('zoneId','Required parameter requestParameters.zoneId was null or undefined when calling deleteZone.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/zone/{zoneId}`.replace(`{${"zoneId"}}`, encodeURIComponent(String(requestParameters.zoneId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelApiResponseFromJSON(jsonValue));
    }

    /**
     * Delete a Zone
     * Delete a Zone
     */
    deleteZone = async(requestParameters: DeleteZoneRequest): Promise<ModelApiResponse> => {
        const response = await this.deleteZoneRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieve List of Zone
     * Retrieve List of Zone
     */
    async retrieveListZoneRaw(requestParameters: RetrieveListZoneRequest): Promise<runtime.ApiResponse<ZoneListResponse>> {
        if (requestParameters.arguments === null || requestParameters.arguments === undefined) {
            throw new runtime.RequiredError('arguments','Required parameter requestParameters.arguments was null or undefined when calling retrieveListZone.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/zone`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ZoneRetrieveListArgumentsToJSON(requestParameters.arguments),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ZoneListResponseFromJSON(jsonValue));
    }

    /**
     * Retrieve List of Zone
     * Retrieve List of Zone
     */
    retrieveListZone = async(requestParameters: RetrieveListZoneRequest): Promise<ZoneListResponse> => {
        const response = await this.retrieveListZoneRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieve an Zone
     * Retrieve a Zone
     */
    async retrieveZoneRaw(requestParameters: RetrieveZoneRequest): Promise<runtime.ApiResponse<ZoneModel>> {
        if (requestParameters.zoneId === null || requestParameters.zoneId === undefined) {
            throw new runtime.RequiredError('zoneId','Required parameter requestParameters.zoneId was null or undefined when calling retrieveZone.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/zone/{zoneId}`.replace(`{${"zoneId"}}`, encodeURIComponent(String(requestParameters.zoneId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ZoneModelFromJSON(jsonValue));
    }

    /**
     * Retrieve an Zone
     * Retrieve a Zone
     */
    retrieveZone = async(requestParameters: RetrieveZoneRequest): Promise<ZoneModel> => {
        const response = await this.retrieveZoneRaw(requestParameters);
        return await response.value();
    }

    /**
     * Set (create or update) a Zone. If id or links are set in the data, and a corresponding zone is found in Bumbal, an update will be performed.
     * Set (create or update) a Zone
     */
    async setZoneRaw(requestParameters: SetZoneRequest): Promise<runtime.ApiResponse<ModelApiResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/zone/set`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ZoneModelToJSON(requestParameters.body),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelApiResponseFromJSON(jsonValue));
    }

    /**
     * Set (create or update) a Zone. If id or links are set in the data, and a corresponding zone is found in Bumbal, an update will be performed.
     * Set (create or update) a Zone
     */
    setZone = async(requestParameters: SetZoneRequest): Promise<ModelApiResponse> => {
        const response = await this.setZoneRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update an Zone
     * Update a Zone
     */
    async updateZoneRaw(requestParameters: UpdateZoneRequest): Promise<runtime.ApiResponse<ModelApiResponse>> {
        if (requestParameters.zoneId === null || requestParameters.zoneId === undefined) {
            throw new runtime.RequiredError('zoneId','Required parameter requestParameters.zoneId was null or undefined when calling updateZone.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/zone/{zoneId}`.replace(`{${"zoneId"}}`, encodeURIComponent(String(requestParameters.zoneId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelApiResponseFromJSON(jsonValue));
    }

    /**
     * Update an Zone
     * Update a Zone
     */
    updateZone = async(requestParameters: UpdateZoneRequest): Promise<ModelApiResponse> => {
        const response = await this.updateZoneRaw(requestParameters);
        return await response.value();
    }

}
