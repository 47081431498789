/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal Client Api
 * Bumbal API documentation
 *
 * The version of the OpenAPI document: 2.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel } from '../runtime';
import {
    RoutesEtaFiltersModel,
    RoutesEtaFiltersModelFromJSON,
    RoutesEtaFiltersModelFromJSONTyped,
    RoutesEtaFiltersModelToJSON,
    RoutesEtaOptionsModel,
    RoutesEtaOptionsModelFromJSON,
    RoutesEtaOptionsModelFromJSONTyped,
    RoutesEtaOptionsModelToJSON,
} from './';


export interface RoutesEtaArgumentsInterface {
    options?: RoutesEtaOptionsModel;
    filters?: RoutesEtaFiltersModel;
    limit?: number;
    offset?: number;
    search_text?: string;
    sorting_column?: RoutesEtaArgumentsSortingColumnEnum;
    sorting_direction?: RoutesEtaArgumentsSortingDirectionEnum;
    as_list?: boolean;
}

/**
 * 
 * @export
 * @interface RoutesEtaArguments
 */
export class RoutesEtaArguments extends BaseModel<RoutesEtaArgumentsInterface> {

    /**
     * 
     * @type {RoutesEtaOptionsModel}
     * @memberof RoutesEtaArguments
     */
    options?: RoutesEtaOptionsModel;
    /**
     * 
     * @type {RoutesEtaFiltersModel}
     * @memberof RoutesEtaArguments
     */
    filters?: RoutesEtaFiltersModel;
    /**
     * 
     * @type {number}
     * @memberof RoutesEtaArguments
     */
    limit?: number;
    /**
     * 
     * @type {number}
     * @memberof RoutesEtaArguments
     */
    offset?: number;
    /**
     * 
     * @type {string}
     * @memberof RoutesEtaArguments
     */
    search_text?: string;
    /**
     * Sorting Column
     * @type {string}
     * @memberof RoutesEtaArguments
     */
    sorting_column?: RoutesEtaArgumentsSortingColumnEnum;
    /**
     * Sorting Direction
     * @type {string}
     * @memberof RoutesEtaArguments
     */
    sorting_direction?: RoutesEtaArgumentsSortingDirectionEnum;
    /**
     * 
     * @type {boolean}
     * @memberof RoutesEtaArguments
     */
    as_list?: boolean;

    constructor(data?: RoutesEtaArgumentsInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(value: RoutesEtaArgumentsInterface): { [property: string]: any } {
        return {};
    }

}

export function RoutesEtaArgumentsFromJSON(json: any): RoutesEtaArguments {
    return RoutesEtaArgumentsFromJSONTyped(json, false);
}

export function RoutesEtaArgumentsFromJSONTyped(json: any, ignoreDiscriminator: boolean): RoutesEtaArguments {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = RoutesEtaArguments;
    try {
        let localClass = require.context('../override', true)('./RoutesEtaArguments');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'options': !exists(json, 'options') ? undefined : RoutesEtaOptionsModelFromJSON(json['options']),
        'filters': !exists(json, 'filters') ? undefined : RoutesEtaFiltersModelFromJSON(json['filters']),
        'limit': !exists(json, 'limit') ? undefined : Number(json['limit']),
        'offset': !exists(json, 'offset') ? undefined : Number(json['offset']),
        'search_text': !exists(json, 'search_text') ? undefined : json['search_text'],
        'sorting_column': !exists(json, 'sorting_column') ? undefined : json['sorting_column'],
        'sorting_direction': !exists(json, 'sorting_direction') ? undefined : json['sorting_direction'],
        'as_list': !exists(json, 'as_list') ? undefined : json['as_list'],
    });
}

export function RoutesEtaArgumentsToJSON(value?: RoutesEtaArguments | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = RoutesEtaArguments;
    try {
        let localClass = require.context('../override', true)('./RoutesEtaArguments');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return {
        
        'options': RoutesEtaOptionsModelToJSON(value.options),
        'filters': RoutesEtaFiltersModelToJSON(value.filters),
        'limit': value.limit,
        'offset': value.offset,
        'search_text': value.search_text,
        'sorting_column': value.sorting_column,
        'sorting_direction': value.sorting_direction,
        'as_list': value.as_list,
        ...createClass.toJson(value),
    };
}

/**
* @export
* @enum {string}
*/
export enum RoutesEtaArgumentsSortingColumnEnum {
    StatusId = 'status_id',
    DateTimeFrom = 'date_time_from',
    Id = 'id',
    Nr = 'nr',
    Name = 'name',
    EarliestDateTime = 'earliest_date_time',
    LatestDateTime = 'latest_date_time'
}
/**
* @export
* @enum {string}
*/
export enum RoutesEtaArgumentsSortingDirectionEnum {
    Asc = 'asc',
    Desc = 'desc'
}


