/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal Client Api
 * Bumbal API documentation
 *
 * The version of the OpenAPI document: 2.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ActivityTypeListResponse,
    ActivityTypeListResponseFromJSON,
    ActivityTypeListResponseToJSON,
    ActivityTypeModel,
    ActivityTypeModelFromJSON,
    ActivityTypeModelToJSON,
    ActivityTypeRetrieveListArguments,
    ActivityTypeRetrieveListArgumentsFromJSON,
    ActivityTypeRetrieveListArgumentsToJSON,
} from '../models';

export interface RetrieveActivityTypeRequest {
    activityTypeId: number;
}

export interface RetrieveListActivityTypeRequest {
    arguments: ActivityTypeRetrieveListArguments;
}

/**
 * 
 */
export class ActivityTypeApi extends runtime.BaseAPI {

    /**
     * Returns a single ActivityType
     * Find ActivityType by ID
     */
    async retrieveActivityTypeRaw(requestParameters: RetrieveActivityTypeRequest): Promise<runtime.ApiResponse<ActivityTypeModel>> {
        if (requestParameters.activityTypeId === null || requestParameters.activityTypeId === undefined) {
            throw new runtime.RequiredError('activityTypeId','Required parameter requestParameters.activityTypeId was null or undefined when calling retrieveActivityType.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/activity-type/{activityTypeId}`.replace(`{${"activityTypeId"}}`, encodeURIComponent(String(requestParameters.activityTypeId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ActivityTypeModelFromJSON(jsonValue));
    }

    /**
     * Returns a single ActivityType
     * Find ActivityType by ID
     */
    retrieveActivityType = async(requestParameters: RetrieveActivityTypeRequest): Promise<ActivityTypeModel> => {
        const response = await this.retrieveActivityTypeRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieve List of ActivityTypes
     * Retrieve List of ActivityTypes
     */
    async retrieveListActivityTypeRaw(requestParameters: RetrieveListActivityTypeRequest): Promise<runtime.ApiResponse<ActivityTypeListResponse>> {
        if (requestParameters.arguments === null || requestParameters.arguments === undefined) {
            throw new runtime.RequiredError('arguments','Required parameter requestParameters.arguments was null or undefined when calling retrieveListActivityType.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/activity-type`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ActivityTypeRetrieveListArgumentsToJSON(requestParameters.arguments),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ActivityTypeListResponseFromJSON(jsonValue));
    }

    /**
     * Retrieve List of ActivityTypes
     * Retrieve List of ActivityTypes
     */
    retrieveListActivityType = async(requestParameters: RetrieveListActivityTypeRequest): Promise<ActivityTypeListResponse> => {
        const response = await this.retrieveListActivityTypeRaw(requestParameters);
        return await response.value();
    }

}
