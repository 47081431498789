import React, { MouseEvent } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { ArrowDropDown } from '@mui/icons-material';
import { NavLink, useLocation } from 'react-router-dom';
import { Box } from '@mui/material';

interface NavMenuLinkProps {
    link: NavLink
}

const NavMenuLink = ({ link }: NavMenuLinkProps) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const location = useLocation();
    const isActive = () => {
        const paths = ['planner', 'admin', 'driver'];
        return paths.some((path) => location.pathname.includes(path));
    }

    const handleClick = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Box>
            <Button
                color='secondary'
                aria-controls={open ? link.text : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                variant="contained"
                disableElevation
                onClick={handleClick}
                sx={{
                    mx: '50px',
                    px: '0px',
                    pb: '0px',
                    borderRadius: '0px',
                    fontSize: '1.2em',
                    color: (isActive()) ? 'primary.main' : '#fff',
                    borderBottom: (isActive()) ? '1px solid #F3B70D' : 'none'
                }}
                endIcon={<ArrowDropDown />}
            >
                {link.text}
            </Button>
            <Menu
                MenuListProps={{
                    'aria-labelledby': link.text,
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                {link.menuItems?.map((item) => (
                    <MenuItem key={item.text} onClick={handleClose} disableRipple component={NavLink} to={item.to}>
                        {item.text}
                    </MenuItem>
                ))}
            </Menu>
        </Box>
    );
}

export default NavMenuLink;