/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal Client Api
 * Bumbal API documentation
 *
 * The version of the OpenAPI document: 2.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel } from '../runtime';
import {
    LinkModel,
    LinkModelFromJSON,
    LinkModelFromJSONTyped,
    LinkModelToJSON,
} from './';


export interface DriverUnavailabilityModelInterface {
    id?: number;
    user_id?: string;
    reference?: string;
    date_time_to?: Date;
    date_time_from?: Date;
    user_link?: LinkModel;
    created_at?: Date;
    updated_at?: Date;
    links?: Array<LinkModel>;
    active?: boolean;
}

/**
 * 
 * @export
 * @interface DriverUnavailabilityModel
 */
export class DriverUnavailabilityModel extends BaseModel<DriverUnavailabilityModelInterface> {

    /**
     * Unique ID
     * @type {number}
     * @memberof DriverUnavailabilityModel
     */
    id?: number;
    /**
     * The user_id of the DriverUnavailability
     * @type {string}
     * @memberof DriverUnavailabilityModel
     */
    user_id?: string;
    /**
     * The reference of the DriverUnavailability
     * @type {string}
     * @memberof DriverUnavailabilityModel
     */
    reference?: string;
    /**
     * date_time_to date time
     * @type {Date}
     * @memberof DriverUnavailabilityModel
     */
    date_time_to?: Date;
    /**
     * date_time_from date time
     * @type {Date}
     * @memberof DriverUnavailabilityModel
     */
    date_time_from?: Date;
    /**
     * 
     * @type {LinkModel}
     * @memberof DriverUnavailabilityModel
     */
    user_link?: LinkModel;
    /**
     * created_at date time
     * @type {Date}
     * @memberof DriverUnavailabilityModel
     */
    created_at?: Date;
    /**
     * updated_at date time
     * @type {Date}
     * @memberof DriverUnavailabilityModel
     */
    updated_at?: Date;
    /**
     * 
     * @type {Array<LinkModel>}
     * @memberof DriverUnavailabilityModel
     */
    links?: Array<LinkModel>;
    /**
     * if active=0: Driver Unavailability has been removed and is no longer visible in any bumbal interface
     * @type {boolean}
     * @memberof DriverUnavailabilityModel
     */
    active?: boolean;

    constructor(data?: DriverUnavailabilityModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(value: DriverUnavailabilityModelInterface): { [property: string]: any } {
        return {};
    }

}

export function DriverUnavailabilityModelFromJSON(json: any): DriverUnavailabilityModel {
    return DriverUnavailabilityModelFromJSONTyped(json, false);
}

export function DriverUnavailabilityModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): DriverUnavailabilityModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = DriverUnavailabilityModel;
    try {
        let localClass = require.context('../override', true)('./DriverUnavailabilityModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'id': !exists(json, 'id') ? undefined : Number(json['id']),
        'user_id': !exists(json, 'user_id') ? undefined : json['user_id'],
        'reference': !exists(json, 'reference') ? undefined : json['reference'],
        'date_time_to': !exists(json, 'date_time_to') ? undefined : (new Date(json['date_time_to'].replace(' ', 'T'))),
        'date_time_from': !exists(json, 'date_time_from') ? undefined : (new Date(json['date_time_from'].replace(' ', 'T'))),
        'user_link': !exists(json, 'user_link') ? undefined : LinkModelFromJSON(json['user_link']),
        'created_at': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'].replace(' ', 'T'))),
        'updated_at': !exists(json, 'updated_at') ? undefined : (new Date(json['updated_at'].replace(' ', 'T'))),
        'links': !exists(json, 'links') ? undefined : ((json['links'] as Array<any>).map(LinkModelFromJSON)),
        'active': !exists(json, 'active') ? undefined : json['active'],
    });
}

export function DriverUnavailabilityModelToJSON(value?: DriverUnavailabilityModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = DriverUnavailabilityModel;
    try {
        let localClass = require.context('../override', true)('./DriverUnavailabilityModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return {
        
        'id': value.id,
        'user_id': value.user_id,
        'reference': value.reference,
        'date_time_to': value.date_time_to === undefined ? undefined : (value.date_time_to.toApiFormat()),
        'date_time_from': value.date_time_from === undefined ? undefined : (value.date_time_from.toApiFormat()),
        'user_link': LinkModelToJSON(value.user_link),
        'created_at': value.created_at === undefined ? undefined : (value.created_at.toApiFormat()),
        'updated_at': value.updated_at === undefined ? undefined : (value.updated_at.toApiFormat()),
        'links': value.links === undefined ? undefined : ((value.links as Array<any>).map(LinkModelToJSON)),
        'active': value.active,
        ...createClass.toJson(value),
    };
}


