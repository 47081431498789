/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal Client Api
 * Bumbal API documentation
 *
 * The version of the OpenAPI document: 2.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiResponse401,
    ApiResponse401FromJSON,
    ApiResponse401ToJSON,
    ApiResponse403,
    ApiResponse403FromJSON,
    ApiResponse403ToJSON,
    ApiResponse405,
    ApiResponse405FromJSON,
    ApiResponse405ToJSON,
    ModelApiResponse,
    ModelApiResponseFromJSON,
    ModelApiResponseToJSON,
    RecurrenceFinishResponse,
    RecurrenceFinishResponseFromJSON,
    RecurrenceFinishResponseToJSON,
    RecurrenceGetRunsArguments,
    RecurrenceGetRunsArgumentsFromJSON,
    RecurrenceGetRunsArgumentsToJSON,
    RecurrenceGetRunsResponse,
    RecurrenceGetRunsResponseFromJSON,
    RecurrenceGetRunsResponseToJSON,
    RecurrenceListResponse,
    RecurrenceListResponseFromJSON,
    RecurrenceListResponseToJSON,
    RecurrenceModel,
    RecurrenceModelFromJSON,
    RecurrenceModelToJSON,
    RecurrenceProcessRunsArguments,
    RecurrenceProcessRunsArgumentsFromJSON,
    RecurrenceProcessRunsArgumentsToJSON,
    RecurrenceProcessRunsResponse,
    RecurrenceProcessRunsResponseFromJSON,
    RecurrenceProcessRunsResponseToJSON,
    RecurrenceRetrieveListArguments,
    RecurrenceRetrieveListArgumentsFromJSON,
    RecurrenceRetrieveListArgumentsToJSON,
} from '../models';

export interface CreateActivityRecurrenceRequest {
    body: RecurrenceModel;
}

export interface CreateRouteRecurrenceRequest {
    body: RecurrenceModel;
}

export interface DeleteRecurrenceRequest {
    recurrenceId: number;
}

export interface GetRunsRequest {
    arguments: RecurrenceGetRunsArguments;
}

export interface ProcessRunsRequest {
    arguments: RecurrenceProcessRunsArguments;
}

export interface RetrieveListRecurrenceRequest {
    arguments: RecurrenceRetrieveListArguments;
}

export interface RetrieveRecurrenceRequest {
    recurrenceId: number;
}

export interface UpdateRecurrenceRequest {
    recurrenceId: number;
}

/**
 * 
 */
export class RecurrenceApi extends runtime.BaseAPI {

    /**
     * create a activity recurrence
     * create a activity recurrence
     */
    async createActivityRecurrenceRaw(requestParameters: CreateActivityRecurrenceRequest): Promise<runtime.ApiResponse<ModelApiResponse>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling createActivityRecurrence.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/recurrence/create-activity-recurrence`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RecurrenceModelToJSON(requestParameters.body),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelApiResponseFromJSON(jsonValue));
    }

    /**
     * create a activity recurrence
     * create a activity recurrence
     */
    createActivityRecurrence = async(requestParameters: CreateActivityRecurrenceRequest): Promise<ModelApiResponse> => {
        const response = await this.createActivityRecurrenceRaw(requestParameters);
        return await response.value();
    }

    /**
     * create a route recurrence
     * create a route recurrence
     */
    async createRouteRecurrenceRaw(requestParameters: CreateRouteRecurrenceRequest): Promise<runtime.ApiResponse<ModelApiResponse>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling createRouteRecurrence.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/recurrence/create-route-recurrence`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RecurrenceModelToJSON(requestParameters.body),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelApiResponseFromJSON(jsonValue));
    }

    /**
     * create a route recurrence
     * create a route recurrence
     */
    createRouteRecurrence = async(requestParameters: CreateRouteRecurrenceRequest): Promise<ModelApiResponse> => {
        const response = await this.createRouteRecurrenceRaw(requestParameters);
        return await response.value();
    }

    /**
     * Delete an Recurrence
     * Delete an Recurrence
     */
    async deleteRecurrenceRaw(requestParameters: DeleteRecurrenceRequest): Promise<runtime.ApiResponse<ModelApiResponse>> {
        if (requestParameters.recurrenceId === null || requestParameters.recurrenceId === undefined) {
            throw new runtime.RequiredError('recurrenceId','Required parameter requestParameters.recurrenceId was null or undefined when calling deleteRecurrence.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/recurrence/{recurrenceId}`.replace(`{${"recurrenceId"}}`, encodeURIComponent(String(requestParameters.recurrenceId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelApiResponseFromJSON(jsonValue));
    }

    /**
     * Delete an Recurrence
     * Delete an Recurrence
     */
    deleteRecurrence = async(requestParameters: DeleteRecurrenceRequest): Promise<ModelApiResponse> => {
        const response = await this.deleteRecurrenceRaw(requestParameters);
        return await response.value();
    }

    /**
     * Cleans up after the process run
     */
    async finishRaw(): Promise<runtime.ApiResponse<RecurrenceFinishResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/recurrence/finish`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RecurrenceFinishResponseFromJSON(jsonValue));
    }

    /**
     * Cleans up after the process run
     */
    finish = async(): Promise<RecurrenceFinishResponse> => {
        const response = await this.finishRaw();
        return await response.value();
    }

    /**
     * Returns the given runs for the next recurrences!
     */
    async getRunsRaw(requestParameters: GetRunsRequest): Promise<runtime.ApiResponse<RecurrenceGetRunsResponse>> {
        if (requestParameters.arguments === null || requestParameters.arguments === undefined) {
            throw new runtime.RequiredError('arguments','Required parameter requestParameters.arguments was null or undefined when calling getRuns.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/recurrence/get-runs`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RecurrenceGetRunsArgumentsToJSON(requestParameters.arguments),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RecurrenceGetRunsResponseFromJSON(jsonValue));
    }

    /**
     * Returns the given runs for the next recurrences!
     */
    getRuns = async(requestParameters: GetRunsRequest): Promise<RecurrenceGetRunsResponse> => {
        const response = await this.getRunsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Executes the the processes for the ids retrieved with get-runs
     */
    async processRunsRaw(requestParameters: ProcessRunsRequest): Promise<runtime.ApiResponse<RecurrenceProcessRunsResponse>> {
        if (requestParameters.arguments === null || requestParameters.arguments === undefined) {
            throw new runtime.RequiredError('arguments','Required parameter requestParameters.arguments was null or undefined when calling processRuns.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/recurrence/process-runs`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RecurrenceProcessRunsArgumentsToJSON(requestParameters.arguments),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RecurrenceProcessRunsResponseFromJSON(jsonValue));
    }

    /**
     * Executes the the processes for the ids retrieved with get-runs
     */
    processRuns = async(requestParameters: ProcessRunsRequest): Promise<RecurrenceProcessRunsResponse> => {
        const response = await this.processRunsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieve List of Recurrences
     * Retrieve List of Recurrences
     */
    async retrieveListRecurrenceRaw(requestParameters: RetrieveListRecurrenceRequest): Promise<runtime.ApiResponse<RecurrenceListResponse>> {
        if (requestParameters.arguments === null || requestParameters.arguments === undefined) {
            throw new runtime.RequiredError('arguments','Required parameter requestParameters.arguments was null or undefined when calling retrieveListRecurrence.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/recurrence`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: RecurrenceRetrieveListArgumentsToJSON(requestParameters.arguments),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RecurrenceListResponseFromJSON(jsonValue));
    }

    /**
     * Retrieve List of Recurrences
     * Retrieve List of Recurrences
     */
    retrieveListRecurrence = async(requestParameters: RetrieveListRecurrenceRequest): Promise<RecurrenceListResponse> => {
        const response = await this.retrieveListRecurrenceRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieve an Recurrence
     * Retrieve a Recurrence
     */
    async retrieveRecurrenceRaw(requestParameters: RetrieveRecurrenceRequest): Promise<runtime.ApiResponse<RecurrenceModel>> {
        if (requestParameters.recurrenceId === null || requestParameters.recurrenceId === undefined) {
            throw new runtime.RequiredError('recurrenceId','Required parameter requestParameters.recurrenceId was null or undefined when calling retrieveRecurrence.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/recurrence/{recurrenceId}`.replace(`{${"recurrenceId"}}`, encodeURIComponent(String(requestParameters.recurrenceId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RecurrenceModelFromJSON(jsonValue));
    }

    /**
     * Retrieve an Recurrence
     * Retrieve a Recurrence
     */
    retrieveRecurrence = async(requestParameters: RetrieveRecurrenceRequest): Promise<RecurrenceModel> => {
        const response = await this.retrieveRecurrenceRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update an Recurrence
     * Update a Recurrence
     */
    async updateRecurrenceRaw(requestParameters: UpdateRecurrenceRequest): Promise<runtime.ApiResponse<ModelApiResponse>> {
        if (requestParameters.recurrenceId === null || requestParameters.recurrenceId === undefined) {
            throw new runtime.RequiredError('recurrenceId','Required parameter requestParameters.recurrenceId was null or undefined when calling updateRecurrence.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/recurrence/{recurrenceId}`.replace(`{${"recurrenceId"}}`, encodeURIComponent(String(requestParameters.recurrenceId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelApiResponseFromJSON(jsonValue));
    }

    /**
     * Update an Recurrence
     * Update a Recurrence
     */
    updateRecurrence = async(requestParameters: UpdateRecurrenceRequest): Promise<ModelApiResponse> => {
        const response = await this.updateRecurrenceRaw(requestParameters);
        return await response.value();
    }

}
