/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal Client Api
 * Bumbal API documentation
 *
 * The version of the OpenAPI document: 2.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel } from '../runtime';
import {
    CapacityModel,
    CapacityModelFromJSON,
    CapacityModelFromJSONTyped,
    CapacityModelToJSON,
    CompartmentModel,
    CompartmentModelFromJSON,
    CompartmentModelFromJSONTyped,
    CompartmentModelToJSON,
    FileModel,
    FileModelFromJSON,
    FileModelFromJSONTyped,
    FileModelToJSON,
    LinkModel,
    LinkModelFromJSON,
    LinkModelFromJSONTyped,
    LinkModelToJSON,
    MetaDataModel,
    MetaDataModelFromJSON,
    MetaDataModelFromJSONTyped,
    MetaDataModelToJSON,
    NoteModel,
    NoteModelFromJSON,
    NoteModelFromJSONTyped,
    NoteModelToJSON,
} from './';


export interface PackageLineModelInterface {
    id?: number;
    activity_id?: number;
    activity_ids?: Array<number>;
    nr?: string;
    status_id?: number;
    status_name?: PackageLineModelStatusNameEnum;
    nr_of_packages?: number;
    package_type_name?: string;
    package_type_id?: number;
    barcode?: string;
    barcodes?: Array<string>;
    action_type_id?: PackageLineModelActionTypeIdEnum;
    action_type_name?: PackageLineModelActionTypeNameEnum;
    checked_by_driver?: boolean;
    adr?: boolean;
    adr_class?: number;
    adr_un_nr?: number;
    temp?: boolean;
    temp_min?: number;
    temp_max?: number;
    hs_code?: string;
    description?: string;
    compartments?: Array<CompartmentModel>;
    active?: boolean;
    applied_capacities?: object;
    capacities?: Array<CapacityModel>;
    activity_links?: Array<LinkModel>;
    links?: Array<LinkModel>;
    meta_data?: Array<MetaDataModel>;
    notes?: Array<NoteModel>;
    files?: Array<FileModel>;
    created_at?: Date;
    updated_at?: Date;
    package_line_created_at?: Date;
    package_line_updated_at?: Date;
    package_line_created_by?: number;
    package_line_updated_by?: number;
}

/**
 * 
 * @export
 * @interface PackageLineModel
 */
export class PackageLineModel extends BaseModel<PackageLineModelInterface> {

    /**
     * Unique Identifier
     * @type {number}
     * @memberof PackageLineModel
     */
    id?: number;
    /**
     * Unique Identifier for activity where this packageline is related to
     * @type {number}
     * @memberof PackageLineModel
     */
    activity_id?: number;
    /**
     * Unique Identifier for activities where this packageline is related to
     * @type {Array<number>}
     * @memberof PackageLineModel
     */
    activity_ids?: Array<number>;
    /**
     * Number of this PackageLine
     * @type {string}
     * @memberof PackageLineModel
     */
    nr?: string;
    /**
     * StatusId of this PackageLine, 31: package_line_cancelled, 23: package_line_incomplete, 24: package_line_new, 42: package_line_awaiting, 25: package_line_accepted, 10: package_line_planned, 11: package_line_in_progress, 12: package_line_executed
     * @type {number}
     * @memberof PackageLineModel
     */
    status_id?: number;
    /**
     * PackageLine Status
     * @type {string}
     * @memberof PackageLineModel
     */
    status_name?: PackageLineModelStatusNameEnum;
    /**
     * Number of packages in package line
     * @type {number}
     * @memberof PackageLineModel
     */
    nr_of_packages?: number;
    /**
     * Type of the Packages in the package line
     * @type {string}
     * @memberof PackageLineModel
     */
    package_type_name?: string;
    /**
     * ID of the package type for the packages in this PackageLine
     * @type {number}
     * @memberof PackageLineModel
     */
    package_type_id?: number;
    /**
     * Barcode of this packageline
     * @type {string}
     * @memberof PackageLineModel
     */
    barcode?: string;
    /**
     * For every barcode in this array, a seperate packageline will be created
     * @type {Array<string>}
     * @memberof PackageLineModel
     */
    barcodes?: Array<string>;
    /**
     * Action type name, 1:inbound, 2:outbound, 3:assess
     * @type {string}
     * @memberof PackageLineModel
     */
    action_type_id?: PackageLineModelActionTypeIdEnum;
    /**
     * Action type name
     * @type {string}
     * @memberof PackageLineModel
     */
    action_type_name?: PackageLineModelActionTypeNameEnum;
    /**
     * boolean for whether or not the packages have been checked by the driver
     * @type {boolean}
     * @memberof PackageLineModel
     */
    checked_by_driver?: boolean;
    /**
     * boolean for whether or not the packages in this package line should be considered as ADR
     * @type {boolean}
     * @memberof PackageLineModel
     */
    adr?: boolean;
    /**
     * ADR class of packages in package line
     * @type {number}
     * @memberof PackageLineModel
     */
    adr_class?: number;
    /**
     * ADR UN Nr of packages in package line
     * @type {number}
     * @memberof PackageLineModel
     */
    adr_un_nr?: number;
    /**
     * boolean for whether or not the packages in this package line should be considered as temperature dependent
     * @type {boolean}
     * @memberof PackageLineModel
     */
    temp?: boolean;
    /**
     * minimum temperature for packages in package line
     * @type {number}
     * @memberof PackageLineModel
     */
    temp_min?: number;
    /**
     * maximum temperature for packages in package line
     * @type {number}
     * @memberof PackageLineModel
     */
    temp_max?: number;
    /**
     * Harmonized System code for packages in this package line
     * @type {string}
     * @memberof PackageLineModel
     */
    hs_code?: string;
    /**
     * description of this package_line
     * @type {string}
     * @memberof PackageLineModel
     */
    description?: string;
    /**
     * 
     * @type {Array<CompartmentModel>}
     * @memberof PackageLineModel
     */
    compartments?: Array<CompartmentModel>;
    /**
     * if active=0: package line has been removed and is no longer visible in any bumbal interface
     * @type {boolean}
     * @memberof PackageLineModel
     */
    active?: boolean;
    /**
     * 
     * @type {object}
     * @memberof PackageLineModel
     */
    applied_capacities?: object;
    /**
     * 
     * @type {Array<CapacityModel>}
     * @memberof PackageLineModel
     */
    capacities?: Array<CapacityModel>;
    /**
     * links to activities connected to this package_line
     * @type {Array<LinkModel>}
     * @memberof PackageLineModel
     */
    activity_links?: Array<LinkModel>;
    /**
     * 
     * @type {Array<LinkModel>}
     * @memberof PackageLineModel
     */
    links?: Array<LinkModel>;
    /**
     * 
     * @type {Array<MetaDataModel>}
     * @memberof PackageLineModel
     */
    meta_data?: Array<MetaDataModel>;
    /**
     * 
     * @type {Array<NoteModel>}
     * @memberof PackageLineModel
     */
    notes?: Array<NoteModel>;
    /**
     * 
     * @type {Array<FileModel>}
     * @memberof PackageLineModel
     */
    files?: Array<FileModel>;
    /**
     * created_at date time
     * @type {Date}
     * @memberof PackageLineModel
     */
    created_at?: Date;
    /**
     * updated_at date time
     * @type {Date}
     * @memberof PackageLineModel
     */
    updated_at?: Date;
    /**
     * created_at date time
     * @type {Date}
     * @memberof PackageLineModel
     */
    package_line_created_at?: Date;
    /**
     * updated_at date time
     * @type {Date}
     * @memberof PackageLineModel
     */
    package_line_updated_at?: Date;
    /**
     * created_by user id
     * @type {number}
     * @memberof PackageLineModel
     */
    package_line_created_by?: number;
    /**
     * updated_by user id
     * @type {number}
     * @memberof PackageLineModel
     */
    package_line_updated_by?: number;

    constructor(data?: PackageLineModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(value: PackageLineModelInterface): { [property: string]: any } {
        return {};
    }

}

export function PackageLineModelFromJSON(json: any): PackageLineModel {
    return PackageLineModelFromJSONTyped(json, false);
}

export function PackageLineModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): PackageLineModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = PackageLineModel;
    try {
        let localClass = require.context('../override', true)('./PackageLineModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'id': !exists(json, 'id') ? undefined : Number(json['id']),
        'activity_id': !exists(json, 'activity_id') ? undefined : Number(json['activity_id']),
        'activity_ids': !exists(json, 'activity_ids') ? undefined : json['activity_ids'],
        'nr': !exists(json, 'nr') ? undefined : json['nr'],
        'status_id': !exists(json, 'status_id') ? undefined : Number(json['status_id']),
        'status_name': !exists(json, 'status_name') ? undefined : json['status_name'],
        'nr_of_packages': !exists(json, 'nr_of_packages') ? undefined : Number(json['nr_of_packages']),
        'package_type_name': !exists(json, 'package_type_name') ? undefined : json['package_type_name'],
        'package_type_id': !exists(json, 'package_type_id') ? undefined : Number(json['package_type_id']),
        'barcode': !exists(json, 'barcode') ? undefined : json['barcode'],
        'barcodes': !exists(json, 'barcodes') ? undefined : json['barcodes'],
        'action_type_id': !exists(json, 'action_type_id') ? undefined : json['action_type_id'],
        'action_type_name': !exists(json, 'action_type_name') ? undefined : json['action_type_name'],
        'checked_by_driver': !exists(json, 'checked_by_driver') ? undefined : json['checked_by_driver'],
        'adr': !exists(json, 'ADR') ? undefined : json['ADR'],
        'adr_class': !exists(json, 'ADR_class') ? undefined : Number(json['ADR_class']),
        'adr_un_nr': !exists(json, 'ADR_UN_nr') ? undefined : Number(json['ADR_UN_nr']),
        'temp': !exists(json, 'temp') ? undefined : json['temp'],
        'temp_min': !exists(json, 'temp_min') ? undefined : Number(json['temp_min']),
        'temp_max': !exists(json, 'temp_max') ? undefined : Number(json['temp_max']),
        'hs_code': !exists(json, 'hs_code') ? undefined : json['hs_code'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'compartments': !exists(json, 'compartments') ? undefined : ((json['compartments'] as Array<any>).map(CompartmentModelFromJSON)),
        'active': !exists(json, 'active') ? undefined : json['active'],
        'applied_capacities': !exists(json, 'applied_capacities') ? undefined : json['applied_capacities'],
        'capacities': !exists(json, 'capacities') ? undefined : ((json['capacities'] as Array<any>).map(CapacityModelFromJSON)),
        'activity_links': !exists(json, 'activity_links') ? undefined : ((json['activity_links'] as Array<any>).map(LinkModelFromJSON)),
        'links': !exists(json, 'links') ? undefined : ((json['links'] as Array<any>).map(LinkModelFromJSON)),
        'meta_data': !exists(json, 'meta_data') ? undefined : ((json['meta_data'] as Array<any>).map(MetaDataModelFromJSON)),
        'notes': !exists(json, 'notes') ? undefined : ((json['notes'] as Array<any>).map(NoteModelFromJSON)),
        'files': !exists(json, 'files') ? undefined : ((json['files'] as Array<any>).map(FileModelFromJSON)),
        'created_at': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'].replace(' ', 'T'))),
        'updated_at': !exists(json, 'updated_at') ? undefined : (new Date(json['updated_at'].replace(' ', 'T'))),
        'package_line_created_at': !exists(json, 'package_line_created_at') ? undefined : (new Date(json['package_line_created_at'].replace(' ', 'T'))),
        'package_line_updated_at': !exists(json, 'package_line_updated_at') ? undefined : (new Date(json['package_line_updated_at'].replace(' ', 'T'))),
        'package_line_created_by': !exists(json, 'package_line_created_by') ? undefined : Number(json['package_line_created_by']),
        'package_line_updated_by': !exists(json, 'package_line_updated_by') ? undefined : Number(json['package_line_updated_by']),
    });
}

export function PackageLineModelToJSON(value?: PackageLineModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = PackageLineModel;
    try {
        let localClass = require.context('../override', true)('./PackageLineModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return {
        
        'id': value.id,
        'activity_id': value.activity_id,
        'activity_ids': value.activity_ids,
        'nr': value.nr,
        'status_id': value.status_id,
        'status_name': value.status_name,
        'nr_of_packages': value.nr_of_packages,
        'package_type_name': value.package_type_name,
        'package_type_id': value.package_type_id,
        'barcode': value.barcode,
        'barcodes': value.barcodes,
        'action_type_id': value.action_type_id,
        'action_type_name': value.action_type_name,
        'checked_by_driver': value.checked_by_driver,
        'ADR': value.adr,
        'ADR_class': value.adr_class,
        'ADR_UN_nr': value.adr_un_nr,
        'temp': value.temp,
        'temp_min': value.temp_min,
        'temp_max': value.temp_max,
        'hs_code': value.hs_code,
        'description': value.description,
        'compartments': value.compartments === undefined ? undefined : ((value.compartments as Array<any>).map(CompartmentModelToJSON)),
        'active': value.active,
        'applied_capacities': value.applied_capacities,
        'capacities': value.capacities === undefined ? undefined : ((value.capacities as Array<any>).map(CapacityModelToJSON)),
        'activity_links': value.activity_links === undefined ? undefined : ((value.activity_links as Array<any>).map(LinkModelToJSON)),
        'links': value.links === undefined ? undefined : ((value.links as Array<any>).map(LinkModelToJSON)),
        'meta_data': value.meta_data === undefined ? undefined : ((value.meta_data as Array<any>).map(MetaDataModelToJSON)),
        'notes': value.notes === undefined ? undefined : ((value.notes as Array<any>).map(NoteModelToJSON)),
        'files': value.files === undefined ? undefined : ((value.files as Array<any>).map(FileModelToJSON)),
        'created_at': value.created_at === undefined ? undefined : (value.created_at.toApiFormat()),
        'updated_at': value.updated_at === undefined ? undefined : (value.updated_at.toApiFormat()),
        'package_line_created_at': value.package_line_created_at === undefined ? undefined : (value.package_line_created_at.toApiFormat()),
        'package_line_updated_at': value.package_line_updated_at === undefined ? undefined : (value.package_line_updated_at.toApiFormat()),
        'package_line_created_by': value.package_line_created_by,
        'package_line_updated_by': value.package_line_updated_by,
        ...createClass.toJson(value),
    };
}

/**
* @export
* @enum {string}
*/
export enum PackageLineModelStatusNameEnum {
    Cancelled = 'package_line_cancelled',
    Incomplete = 'package_line_incomplete',
    New = 'package_line_new',
    Awaiting = 'package_line_awaiting',
    Accepted = 'package_line_accepted',
    Planned = 'package_line_planned',
    InProgress = 'package_line_in_progress',
    Executed = 'package_line_executed'
}
/**
* @export
* @enum {string}
*/
export enum PackageLineModelActionTypeIdEnum {
    _1 = '1',
    _2 = '2',
    _3 = '3'
}
/**
* @export
* @enum {string}
*/
export enum PackageLineModelActionTypeNameEnum {
    Inbound = 'inbound',
    Outbound = 'outbound',
    Assess = 'assess'
}


