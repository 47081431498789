/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal Client Api
 * Bumbal API documentation
 *
 * The version of the OpenAPI document: 2.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ModelApiResponse,
    ModelApiResponseFromJSON,
    ModelApiResponseToJSON,
    PortalSettingListResponse,
    PortalSettingListResponseFromJSON,
    PortalSettingListResponseToJSON,
    PortalSettingModel,
    PortalSettingModelFromJSON,
    PortalSettingModelToJSON,
    PortalSettingRetrieveListArguments,
    PortalSettingRetrieveListArgumentsFromJSON,
    PortalSettingRetrieveListArgumentsToJSON,
} from '../models';

export interface CreatePortalSettingRequest {
    body?: PortalSettingModel;
}

export interface DeletePortalSettingRequest {
    portalSettingId: number;
}

export interface RetrieveListPortalSettingRequest {
    arguments: PortalSettingRetrieveListArguments;
}

export interface RetrievePortalSettingRequest {
    portalSettingId: number;
}

export interface SetPortalSettingRequest {
    body?: PortalSettingModel;
}

export interface UpdatePortalSettingRequest {
    portalSettingId: number;
}

/**
 * 
 */
export class PortalSettingApi extends runtime.BaseAPI {

    /**
     * Add a new PortalSetting
     * Add a new PortalSetting
     */
    async createPortalSettingRaw(requestParameters: CreatePortalSettingRequest): Promise<runtime.ApiResponse<ModelApiResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/portal-setting`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PortalSettingModelToJSON(requestParameters.body),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelApiResponseFromJSON(jsonValue));
    }

    /**
     * Add a new PortalSetting
     * Add a new PortalSetting
     */
    createPortalSetting = async(requestParameters: CreatePortalSettingRequest): Promise<ModelApiResponse> => {
        const response = await this.createPortalSettingRaw(requestParameters);
        return await response.value();
    }

    /**
     * Delete a PortalSetting
     * Delete a PortalSetting
     */
    async deletePortalSettingRaw(requestParameters: DeletePortalSettingRequest): Promise<runtime.ApiResponse<ModelApiResponse>> {
        if (requestParameters.portalSettingId === null || requestParameters.portalSettingId === undefined) {
            throw new runtime.RequiredError('portalSettingId','Required parameter requestParameters.portalSettingId was null or undefined when calling deletePortalSetting.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/portal-setting/{portal-settingId}`.replace(`{${"portal-settingId"}}`, encodeURIComponent(String(requestParameters.portalSettingId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelApiResponseFromJSON(jsonValue));
    }

    /**
     * Delete a PortalSetting
     * Delete a PortalSetting
     */
    deletePortalSetting = async(requestParameters: DeletePortalSettingRequest): Promise<ModelApiResponse> => {
        const response = await this.deletePortalSettingRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieve List of Portal Settings
     * Retrieve List of Portal Settings
     */
    async retrieveListPortalSettingRaw(requestParameters: RetrieveListPortalSettingRequest): Promise<runtime.ApiResponse<PortalSettingListResponse>> {
        if (requestParameters.arguments === null || requestParameters.arguments === undefined) {
            throw new runtime.RequiredError('arguments','Required parameter requestParameters.arguments was null or undefined when calling retrieveListPortalSetting.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/portal-setting`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PortalSettingRetrieveListArgumentsToJSON(requestParameters.arguments),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PortalSettingListResponseFromJSON(jsonValue));
    }

    /**
     * Retrieve List of Portal Settings
     * Retrieve List of Portal Settings
     */
    retrieveListPortalSetting = async(requestParameters: RetrieveListPortalSettingRequest): Promise<PortalSettingListResponse> => {
        const response = await this.retrieveListPortalSettingRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieve an PortalSetting
     * Retrieve a PortalSetting
     */
    async retrievePortalSettingRaw(requestParameters: RetrievePortalSettingRequest): Promise<runtime.ApiResponse<PortalSettingModel>> {
        if (requestParameters.portalSettingId === null || requestParameters.portalSettingId === undefined) {
            throw new runtime.RequiredError('portalSettingId','Required parameter requestParameters.portalSettingId was null or undefined when calling retrievePortalSetting.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/portal-setting/{portal-settingId}`.replace(`{${"portal-settingId"}}`, encodeURIComponent(String(requestParameters.portalSettingId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PortalSettingModelFromJSON(jsonValue));
    }

    /**
     * Retrieve an PortalSetting
     * Retrieve a PortalSetting
     */
    retrievePortalSetting = async(requestParameters: RetrievePortalSettingRequest): Promise<PortalSettingModel> => {
        const response = await this.retrievePortalSettingRaw(requestParameters);
        return await response.value();
    }

    /**
     * Set (create or update) a PortalSetting. If id or links are set in the data, and a corresponding portal-setting is found in Bumbal, an update will be performed.
     * Set (create or update) a PortalSetting
     */
    async setPortalSettingRaw(requestParameters: SetPortalSettingRequest): Promise<runtime.ApiResponse<ModelApiResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/portal-setting/set`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PortalSettingModelToJSON(requestParameters.body),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelApiResponseFromJSON(jsonValue));
    }

    /**
     * Set (create or update) a PortalSetting. If id or links are set in the data, and a corresponding portal-setting is found in Bumbal, an update will be performed.
     * Set (create or update) a PortalSetting
     */
    setPortalSetting = async(requestParameters: SetPortalSettingRequest): Promise<ModelApiResponse> => {
        const response = await this.setPortalSettingRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update an PortalSetting
     * Update a PortalSetting
     */
    async updatePortalSettingRaw(requestParameters: UpdatePortalSettingRequest): Promise<runtime.ApiResponse<ModelApiResponse>> {
        if (requestParameters.portalSettingId === null || requestParameters.portalSettingId === undefined) {
            throw new runtime.RequiredError('portalSettingId','Required parameter requestParameters.portalSettingId was null or undefined when calling updatePortalSetting.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/portal-setting/{portal-settingId}`.replace(`{${"portal-settingId"}}`, encodeURIComponent(String(requestParameters.portalSettingId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelApiResponseFromJSON(jsonValue));
    }

    /**
     * Update an PortalSetting
     * Update a PortalSetting
     */
    updatePortalSetting = async(requestParameters: UpdatePortalSettingRequest): Promise<ModelApiResponse> => {
        const response = await this.updatePortalSettingRaw(requestParameters);
        return await response.value();
    }

}
