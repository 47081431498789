/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal Client Api
 * Bumbal API documentation
 *
 * The version of the OpenAPI document: 2.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel } from '../runtime';

export interface UserNotificationOptionsModelInterface {
    include_notification?: boolean;
}

/**
 * 
 * @export
 * @interface UserNotificationOptionsModel
 */
export class UserNotificationOptionsModel extends BaseModel<UserNotificationOptionsModelInterface> {

    /**
     * 
     * @type {boolean}
     * @memberof UserNotificationOptionsModel
     */
    include_notification?: boolean;

    constructor(data?: UserNotificationOptionsModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(value: UserNotificationOptionsModelInterface): { [property: string]: any } {
        return {};
    }

}

export function UserNotificationOptionsModelFromJSON(json: any): UserNotificationOptionsModel {
    return UserNotificationOptionsModelFromJSONTyped(json, false);
}

export function UserNotificationOptionsModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserNotificationOptionsModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = UserNotificationOptionsModel;
    try {
        let localClass = require.context('../override', true)('./UserNotificationOptionsModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'include_notification': !exists(json, 'include_notification') ? undefined : json['include_notification'],
    });
}

export function UserNotificationOptionsModelToJSON(value?: UserNotificationOptionsModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = UserNotificationOptionsModel;
    try {
        let localClass = require.context('../override', true)('./UserNotificationOptionsModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return {
        
        'include_notification': value.include_notification,
        ...createClass.toJson(value),
    };
}


