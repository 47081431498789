/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal Client Api
 * Bumbal API documentation
 *
 * The version of the OpenAPI document: 2.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel } from '../runtime';

export interface UserNotificationFiltersModelInterface {
    id?: Array<number>;
    user_id?: Array<number>;
    notification_id?: Array<number>;
    unread?: number;
    pinned?: number;
    archived?: number;
    notification_category_id?: Array<number>;
    notification_category_name?: Array<string>;
    object_type?: Array<number>;
    object_type_name?: Array<string>;
}

/**
 * 
 * @export
 * @interface UserNotificationFiltersModel
 */
export class UserNotificationFiltersModel extends BaseModel<UserNotificationFiltersModelInterface> {

    /**
     * UserNotification id's
     * @type {Array<number>}
     * @memberof UserNotificationFiltersModel
     */
    id?: Array<number>;
    /**
     * User id's
     * @type {Array<number>}
     * @memberof UserNotificationFiltersModel
     */
    user_id?: Array<number>;
    /**
     * Notification id's
     * @type {Array<number>}
     * @memberof UserNotificationFiltersModel
     */
    notification_id?: Array<number>;
    /**
     * Unread status
     * @type {number}
     * @memberof UserNotificationFiltersModel
     */
    unread?: number;
    /**
     * Pinned status
     * @type {number}
     * @memberof UserNotificationFiltersModel
     */
    pinned?: number;
    /**
     * Archived status
     * @type {number}
     * @memberof UserNotificationFiltersModel
     */
    archived?: number;
    /**
     * Notification category id
     * @type {Array<number>}
     * @memberof UserNotificationFiltersModel
     */
    notification_category_id?: Array<number>;
    /**
     * Notification category name
     * @type {Array<string>}
     * @memberof UserNotificationFiltersModel
     */
    notification_category_name?: Array<string>;
    /**
     * Object type ID
     * @type {Array<number>}
     * @memberof UserNotificationFiltersModel
     */
    object_type?: Array<number>;
    /**
     * Object type name
     * @type {Array<string>}
     * @memberof UserNotificationFiltersModel
     */
    object_type_name?: Array<string>;

    constructor(data?: UserNotificationFiltersModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(value: UserNotificationFiltersModelInterface): { [property: string]: any } {
        return {};
    }

}

export function UserNotificationFiltersModelFromJSON(json: any): UserNotificationFiltersModel {
    return UserNotificationFiltersModelFromJSONTyped(json, false);
}

export function UserNotificationFiltersModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserNotificationFiltersModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = UserNotificationFiltersModel;
    try {
        let localClass = require.context('../override', true)('./UserNotificationFiltersModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'user_id': !exists(json, 'user_id') ? undefined : json['user_id'],
        'notification_id': !exists(json, 'notification_id') ? undefined : json['notification_id'],
        'unread': !exists(json, 'unread') ? undefined : Number(json['unread']),
        'pinned': !exists(json, 'pinned') ? undefined : Number(json['pinned']),
        'archived': !exists(json, 'archived') ? undefined : Number(json['archived']),
        'notification_category_id': !exists(json, 'notification_category_id') ? undefined : json['notification_category_id'],
        'notification_category_name': !exists(json, 'notification_category_name') ? undefined : json['notification_category_name'],
        'object_type': !exists(json, 'object_type') ? undefined : json['object_type'],
        'object_type_name': !exists(json, 'object_type_name') ? undefined : json['object_type_name'],
    });
}

export function UserNotificationFiltersModelToJSON(value?: UserNotificationFiltersModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = UserNotificationFiltersModel;
    try {
        let localClass = require.context('../override', true)('./UserNotificationFiltersModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return {
        
        'id': value.id,
        'user_id': value.user_id,
        'notification_id': value.notification_id,
        'unread': value.unread,
        'pinned': value.pinned,
        'archived': value.archived,
        'notification_category_id': value.notification_category_id,
        'notification_category_name': value.notification_category_name,
        'object_type': value.object_type,
        'object_type_name': value.object_type_name,
        ...createClass.toJson(value),
    };
}


