/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal Client Api
 * Bumbal API documentation
 *
 * The version of the OpenAPI document: 2.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    RoutePointsModel,
    RoutePointsModelFromJSON,
    RoutePointsModelToJSON,
    RoutePointsRetrieveListArguments,
    RoutePointsRetrieveListArgumentsFromJSON,
    RoutePointsRetrieveListArgumentsToJSON,
} from '../models';

export interface RetrieveListRoutePointsRequest {
    arguments: RoutePointsRetrieveListArguments;
}

export interface RetrieveRoutePointsRequest {
    routeId: number;
}

/**
 * 
 */
export class RoutePointsApi extends runtime.BaseAPI {

    /**
     * Returns array of RoutePointsModels
     * Find Route Points for multiple routes by route ID
     */
    async retrieveListRoutePointsRaw(requestParameters: RetrieveListRoutePointsRequest): Promise<runtime.ApiResponse<Array<RoutePointsModel>>> {
        if (requestParameters.arguments === null || requestParameters.arguments === undefined) {
            throw new runtime.RequiredError('arguments','Required parameter requestParameters.arguments was null or undefined when calling retrieveListRoutePoints.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/route-points`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: RoutePointsRetrieveListArgumentsToJSON(requestParameters.arguments),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RoutePointsModelFromJSON));
    }

    /**
     * Returns array of RoutePointsModels
     * Find Route Points for multiple routes by route ID
     */
    retrieveListRoutePoints = async(requestParameters: RetrieveListRoutePointsRequest): Promise<Array<RoutePointsModel>> => {
        const response = await this.retrieveListRoutePointsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns route_id with a GeoJSON object
     * Find Route Points by route ID
     */
    async retrieveRoutePointsRaw(requestParameters: RetrieveRoutePointsRequest): Promise<runtime.ApiResponse<RoutePointsModel>> {
        if (requestParameters.routeId === null || requestParameters.routeId === undefined) {
            throw new runtime.RequiredError('routeId','Required parameter requestParameters.routeId was null or undefined when calling retrieveRoutePoints.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/route-points/{routeId}`.replace(`{${"routeId"}}`, encodeURIComponent(String(requestParameters.routeId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RoutePointsModelFromJSON(jsonValue));
    }

    /**
     * Returns route_id with a GeoJSON object
     * Find Route Points by route ID
     */
    retrieveRoutePoints = async(requestParameters: RetrieveRoutePointsRequest): Promise<RoutePointsModel> => {
        const response = await this.retrieveRoutePointsRaw(requestParameters);
        return await response.value();
    }

}
