import { Box, Button } from "@mui/material";
import React from "react";
import { Chapter } from "../../../interface/CourseContent";

interface ChapterListProps {
    chapters: Chapter[];
    activeIndex: number;
    switchChapter: (number: number) => void;
}

const ChapterList = ({
    chapters,
    activeIndex,
    switchChapter,
}: ChapterListProps) => {
    return (
        <Box>
            {chapters.map((chapter, i) => (
                <Button
                    key={i}
                    color="secondary"
                    component="span"
                    sx={{
                        p: "5px 15px",
                        backgroundColor:
                            activeIndex === i ? "primary.main" : "#fff",
                        borderRadius: "8px",
                        width: "100%",
                        mt: "10px",
                        justifyContent: "flex-start",
                        fontWeight: "650",
                        letterSpacing: "-0.5px",
                    }}
                    onClick={(e) => {
                        switchChapter(i);
                    }}
                >
                    {chapter.title}
                </Button>
            ))}
        </Box>
    );
};

export default ChapterList;
