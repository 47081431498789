import { Alert, Button, Grid, TextField } from '@mui/material';
import React, { useState, FormEvent, ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next';
import { CredentialsModel } from '../../../api/models';

interface SignInFormProps {
    handleSignIn: (values: SignInValues) => void,
    hasSignInError: boolean
}

export interface SignInValues extends CredentialsModel {
    environment: string
}

const SignInForm = (props: SignInFormProps) => {

    const { t } = useTranslation();

    const [credentials, setCredentials] = useState<SignInValues>({
        environment: '',
        email: '',
        password: ''
    });

    const handleChange =  (e: ChangeEvent<HTMLInputElement>): void => {
        setCredentials({
            ...credentials,
            [e.target.name]: e.target.value
        });
    }

    const handleSubmit = (e: FormEvent<HTMLFormElement>): void => {
        e.preventDefault();
        props.handleSignIn(credentials);
    }

    return (
        <form onSubmit={handleSubmit}>
            <Grid container direction="column" spacing={2.5} style={{padding: '10px 75px 15px'}}>
                {props.hasSignInError &&
                    (
                        <Grid item>
                            <Alert severity="error">{t('sign_in_form.failure')}</Alert>
                        </Grid>
                    )}
                <Grid item>
                    <TextField
                        value={credentials.environment}
                        name="environment"
                        label={t('sign_in_form.environment')}
                        size="small"
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item>
                    <TextField
                        value={credentials.email}
                        name="email"
                        label="E-mail"
                        size="small"
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item>
                    <TextField
                        value={credentials.password}
                        name="password"
                        label={t('sign_in_form.password')}
                        type="password"
                        size="small"
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item>
                    <Button
                        type="submit"
                        variant="contained"
                        size="large"
                        sx={{float: 'right', textTransform: 'none'}}
                    >
                        {t('sign_in_form.sign_in')}
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
}

export default SignInForm;