/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal Client Api
 * Bumbal API documentation
 *
 * The version of the OpenAPI document: 2.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel } from '../runtime';
import {
    CapacityTypeModel,
    CapacityTypeModelFromJSON,
    CapacityTypeModelFromJSONTyped,
    CapacityTypeModelToJSON,
    UnitValueModel,
    UnitValueModelFromJSON,
    UnitValueModelFromJSONTyped,
    UnitValueModelToJSON,
} from './';


export interface CapacityModelInterface {
    id?: number;
    capacity_type_id?: number;
    capacity_type_name?: string;
    capacity_type?: CapacityTypeModel;
    capacity_value?: number;
    unit_values?: Array<UnitValueModel>;
    capacity_value_uom_name?: CapacityModelCapacityValueUomNameEnum;
    unit_values_uom_name?: CapacityModelUnitValuesUomNameEnum;
}

/**
 * 
 * @export
 * @interface CapacityModel
 */
export class CapacityModel extends BaseModel<CapacityModelInterface> {

    /**
     * Unique ID
     * @type {number}
     * @memberof CapacityModel
     */
    id?: number;
    /**
     * id for capacity type
     * @type {number}
     * @memberof CapacityModel
     */
    capacity_type_id?: number;
    /**
     * name of capacity type
     * @type {string}
     * @memberof CapacityModel
     */
    capacity_type_name?: string;
    /**
     * 
     * @type {CapacityTypeModel}
     * @memberof CapacityModel
     */
    capacity_type?: CapacityTypeModel;
    /**
     * Capacity value
     * @type {number}
     * @memberof CapacityModel
     */
    capacity_value?: number;
    /**
     * 
     * @type {Array<UnitValueModel>}
     * @memberof CapacityModel
     */
    unit_values?: Array<UnitValueModel>;
    /**
     * Name of used unit of measurement for values provided in capacity_value
     * @type {string}
     * @memberof CapacityModel
     */
    capacity_value_uom_name?: CapacityModelCapacityValueUomNameEnum;
    /**
     * Name of used unit of measurement for values provided in unit_values
     * @type {string}
     * @memberof CapacityModel
     */
    unit_values_uom_name?: CapacityModelUnitValuesUomNameEnum;

    constructor(data?: CapacityModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(value: CapacityModelInterface): { [property: string]: any } {
        return {};
    }

}

export function CapacityModelFromJSON(json: any): CapacityModel {
    return CapacityModelFromJSONTyped(json, false);
}

export function CapacityModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): CapacityModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = CapacityModel;
    try {
        let localClass = require.context('../override', true)('./CapacityModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'id': !exists(json, 'id') ? undefined : Number(json['id']),
        'capacity_type_id': !exists(json, 'capacity_type_id') ? undefined : Number(json['capacity_type_id']),
        'capacity_type_name': !exists(json, 'capacity_type_name') ? undefined : json['capacity_type_name'],
        'capacity_type': !exists(json, 'capacity_type') ? undefined : CapacityTypeModelFromJSON(json['capacity_type']),
        'capacity_value': !exists(json, 'capacity_value') ? undefined : Number(json['capacity_value']),
        'unit_values': !exists(json, 'unit_values') ? undefined : ((json['unit_values'] as Array<any>).map(UnitValueModelFromJSON)),
        'capacity_value_uom_name': !exists(json, 'capacity_value_uom_name') ? undefined : json['capacity_value_uom_name'],
        'unit_values_uom_name': !exists(json, 'unit_values_uom_name') ? undefined : json['unit_values_uom_name'],
    });
}

export function CapacityModelToJSON(value?: CapacityModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = CapacityModel;
    try {
        let localClass = require.context('../override', true)('./CapacityModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return {
        
        'id': value.id,
        'capacity_type_id': value.capacity_type_id,
        'capacity_type_name': value.capacity_type_name,
        'capacity_type': CapacityTypeModelToJSON(value.capacity_type),
        'capacity_value': value.capacity_value,
        'unit_values': value.unit_values === undefined ? undefined : ((value.unit_values as Array<any>).map(UnitValueModelToJSON)),
        'capacity_value_uom_name': value.capacity_value_uom_name,
        'unit_values_uom_name': value.unit_values_uom_name,
        ...createClass.toJson(value),
    };
}

/**
* @export
* @enum {string}
*/
export enum CapacityModelCapacityValueUomNameEnum {
    CM = 'CM',
    M = 'M',
    MI = 'MI',
    CM3 = 'CM3',
    M3 = 'M3',
    LI = 'LI',
    KG = 'KG',
    PD = 'PD',
    Amount = 'amount',
    MM = 'MM',
    MM3 = 'MM3',
    DM = 'DM',
    DM3 = 'DM3',
    G = 'G',
    MG = 'MG',
    CG = 'CG',
    DG = 'DG'
}
/**
* @export
* @enum {string}
*/
export enum CapacityModelUnitValuesUomNameEnum {
    CM = 'CM',
    M = 'M',
    MI = 'MI',
    CM3 = 'CM3',
    M3 = 'M3',
    LI = 'LI',
    KG = 'KG',
    PD = 'PD',
    Amount = 'amount',
    MM = 'MM',
    MM3 = 'MM3',
    DM = 'DM',
    DM3 = 'DM3',
    G = 'G',
    MG = 'MG',
    CG = 'CG',
    DG = 'DG'
}


