/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal Client Api
 * Bumbal API documentation
 *
 * The version of the OpenAPI document: 2.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ModelApiResponse,
    ModelApiResponseFromJSON,
    ModelApiResponseToJSON,
    RoutesEtaArguments,
    RoutesEtaArgumentsFromJSON,
    RoutesEtaArgumentsToJSON,
    RoutesEtaResponse,
    RoutesEtaResponseFromJSON,
    RoutesEtaResponseToJSON,
} from '../models';

export interface TrackTraceCalculateETARequest {
    activityId: number;
}

export interface TrackTraceCalculateRoutesETARequest {
    arguments: RoutesEtaArguments;
}

/**
 * 
 */
export class TrackTraceApi extends runtime.BaseAPI {

    /**
     * Calculate ETA for Activity
     * Calculate ETA for Activity
     */
    async trackTraceCalculateETARaw(requestParameters: TrackTraceCalculateETARequest): Promise<runtime.ApiResponse<ModelApiResponse>> {
        if (requestParameters.activityId === null || requestParameters.activityId === undefined) {
            throw new runtime.RequiredError('activityId','Required parameter requestParameters.activityId was null or undefined when calling trackTraceCalculateETA.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/track-and-trace/calculate-eta/{activityId}`.replace(`{${"activityId"}}`, encodeURIComponent(String(requestParameters.activityId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelApiResponseFromJSON(jsonValue));
    }

    /**
     * Calculate ETA for Activity
     * Calculate ETA for Activity
     */
    trackTraceCalculateETA = async(requestParameters: TrackTraceCalculateETARequest): Promise<ModelApiResponse> => {
        const response = await this.trackTraceCalculateETARaw(requestParameters);
        return await response.value();
    }

    /**
     * Calculate ETA for Activities on Routes
     * Calculate ETA for Activities on Routes
     */
    async trackTraceCalculateRoutesETARaw(requestParameters: TrackTraceCalculateRoutesETARequest): Promise<runtime.ApiResponse<RoutesEtaResponse>> {
        if (requestParameters.arguments === null || requestParameters.arguments === undefined) {
            throw new runtime.RequiredError('arguments','Required parameter requestParameters.arguments was null or undefined when calling trackTraceCalculateRoutesETA.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/track-and-trace/calculate-routes-eta`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: RoutesEtaArgumentsToJSON(requestParameters.arguments),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RoutesEtaResponseFromJSON(jsonValue));
    }

    /**
     * Calculate ETA for Activities on Routes
     * Calculate ETA for Activities on Routes
     */
    trackTraceCalculateRoutesETA = async(requestParameters: TrackTraceCalculateRoutesETARequest): Promise<RoutesEtaResponse> => {
        const response = await this.trackTraceCalculateRoutesETARaw(requestParameters);
        return await response.value();
    }

}
