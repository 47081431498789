/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal Client Api
 * Bumbal API documentation
 *
 * The version of the OpenAPI document: 2.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    DriverListResponse,
    DriverListResponseFromJSON,
    DriverListResponseToJSON,
    DriverModel,
    DriverModelFromJSON,
    DriverModelToJSON,
    DriverRetrieveListArguments,
    DriverRetrieveListArgumentsFromJSON,
    DriverRetrieveListArgumentsToJSON,
    ModelApiResponse,
    ModelApiResponseFromJSON,
    ModelApiResponseToJSON,
} from '../models';

export interface CreateDriverRequest {
    body?: DriverModel;
}

export interface DeleteDriverRequest {
    driverId: number;
}

export interface RetrieveDriverRequest {
    driverId: number;
    includeDriverTags: boolean;
    includeUpdatedBy: boolean;
}

export interface RetrieveListDriverRequest {
    arguments: DriverRetrieveListArguments;
}

export interface SetDriverRequest {
    body?: DriverModel;
}

export interface UpdateDriverRequest {
    driverId: number;
    body?: DriverModel;
}

/**
 * 
 */
export class DriverApi extends runtime.BaseAPI {

    /**
     * Add a driver
     * Add a driver
     */
    async createDriverRaw(requestParameters: CreateDriverRequest): Promise<runtime.ApiResponse<ModelApiResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/driver`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DriverModelToJSON(requestParameters.body),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelApiResponseFromJSON(jsonValue));
    }

    /**
     * Add a driver
     * Add a driver
     */
    createDriver = async(requestParameters: CreateDriverRequest): Promise<ModelApiResponse> => {
        const response = await this.createDriverRaw(requestParameters);
        return await response.value();
    }

    /**
     * Delete an driver
     * Delete an driver
     */
    async deleteDriverRaw(requestParameters: DeleteDriverRequest): Promise<runtime.ApiResponse<ModelApiResponse>> {
        if (requestParameters.driverId === null || requestParameters.driverId === undefined) {
            throw new runtime.RequiredError('driverId','Required parameter requestParameters.driverId was null or undefined when calling deleteDriver.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/driver/{driverId}`.replace(`{${"driverId"}}`, encodeURIComponent(String(requestParameters.driverId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelApiResponseFromJSON(jsonValue));
    }

    /**
     * Delete an driver
     * Delete an driver
     */
    deleteDriver = async(requestParameters: DeleteDriverRequest): Promise<ModelApiResponse> => {
        const response = await this.deleteDriverRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns a single driver
     * Find driver by ID
     */
    async retrieveDriverRaw(requestParameters: RetrieveDriverRequest): Promise<runtime.ApiResponse<DriverModel>> {
        if (requestParameters.driverId === null || requestParameters.driverId === undefined) {
            throw new runtime.RequiredError('driverId','Required parameter requestParameters.driverId was null or undefined when calling retrieveDriver.');
        }

        if (requestParameters.includeDriverTags === null || requestParameters.includeDriverTags === undefined) {
            throw new runtime.RequiredError('includeDriverTags','Required parameter requestParameters.includeDriverTags was null or undefined when calling retrieveDriver.');
        }

        if (requestParameters.includeUpdatedBy === null || requestParameters.includeUpdatedBy === undefined) {
            throw new runtime.RequiredError('includeUpdatedBy','Required parameter requestParameters.includeUpdatedBy was null or undefined when calling retrieveDriver.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.includeDriverTags !== undefined) {
            queryParameters['include_driver_tags'] = requestParameters.includeDriverTags;
        }

        if (requestParameters.includeUpdatedBy !== undefined) {
            queryParameters['include_updated_by'] = requestParameters.includeUpdatedBy;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/driver/{driverId}`.replace(`{${"driverId"}}`, encodeURIComponent(String(requestParameters.driverId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DriverModelFromJSON(jsonValue));
    }

    /**
     * Returns a single driver
     * Find driver by ID
     */
    retrieveDriver = async(requestParameters: RetrieveDriverRequest): Promise<DriverModel> => {
        const response = await this.retrieveDriverRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieve List of Drivers
     * Retrieve List of Drivers
     */
    async retrieveListDriverRaw(requestParameters: RetrieveListDriverRequest): Promise<runtime.ApiResponse<DriverListResponse>> {
        if (requestParameters.arguments === null || requestParameters.arguments === undefined) {
            throw new runtime.RequiredError('arguments','Required parameter requestParameters.arguments was null or undefined when calling retrieveListDriver.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/driver`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: DriverRetrieveListArgumentsToJSON(requestParameters.arguments),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DriverListResponseFromJSON(jsonValue));
    }

    /**
     * Retrieve List of Drivers
     * Retrieve List of Drivers
     */
    retrieveListDriver = async(requestParameters: RetrieveListDriverRequest): Promise<DriverListResponse> => {
        const response = await this.retrieveListDriverRaw(requestParameters);
        return await response.value();
    }

    /**
     * Set (create or update) a driver. If id or links are set in the data, and a corresponding driver is found in Bumbal, an update will be performed.
     * Set (create or update) a driver
     */
    async setDriverRaw(requestParameters: SetDriverRequest): Promise<runtime.ApiResponse<ModelApiResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/driver/set`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DriverModelToJSON(requestParameters.body),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelApiResponseFromJSON(jsonValue));
    }

    /**
     * Set (create or update) a driver. If id or links are set in the data, and a corresponding driver is found in Bumbal, an update will be performed.
     * Set (create or update) a driver
     */
    setDriver = async(requestParameters: SetDriverRequest): Promise<ModelApiResponse> => {
        const response = await this.setDriverRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update a driver
     * Update a driver
     */
    async updateDriverRaw(requestParameters: UpdateDriverRequest): Promise<runtime.ApiResponse<ModelApiResponse>> {
        if (requestParameters.driverId === null || requestParameters.driverId === undefined) {
            throw new runtime.RequiredError('driverId','Required parameter requestParameters.driverId was null or undefined when calling updateDriver.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/driver/{driverId}`.replace(`{${"driverId"}}`, encodeURIComponent(String(requestParameters.driverId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: DriverModelToJSON(requestParameters.body),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelApiResponseFromJSON(jsonValue));
    }

    /**
     * Update a driver
     * Update a driver
     */
    updateDriver = async(requestParameters: UpdateDriverRequest): Promise<ModelApiResponse> => {
        const response = await this.updateDriverRaw(requestParameters);
        return await response.value();
    }

}
