/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal Client Api
 * Bumbal API documentation
 *
 * The version of the OpenAPI document: 2.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiResponse30,
    ApiResponse30FromJSON,
    ApiResponse30ToJSON,
    ApiResponse31,
    ApiResponse31FromJSON,
    ApiResponse31ToJSON,
    ApiResponse32,
    ApiResponse32FromJSON,
    ApiResponse32ToJSON,
    ApiResponse33,
    ApiResponse33FromJSON,
    ApiResponse33ToJSON,
    ApiResponse401,
    ApiResponse401FromJSON,
    ApiResponse401ToJSON,
    ApiResponse403,
    ApiResponse403FromJSON,
    ApiResponse403ToJSON,
    ApiResponse405,
    ApiResponse405FromJSON,
    ApiResponse405ToJSON,
    ModelApiResponse,
    ModelApiResponseFromJSON,
    ModelApiResponseToJSON,
    QuestionnaireTemplateListResponse,
    QuestionnaireTemplateListResponseFromJSON,
    QuestionnaireTemplateListResponseToJSON,
    QuestionnaireTemplateModel,
    QuestionnaireTemplateModelFromJSON,
    QuestionnaireTemplateModelToJSON,
    QuestionnaireTemplateRetrieveListArguments,
    QuestionnaireTemplateRetrieveListArgumentsFromJSON,
    QuestionnaireTemplateRetrieveListArgumentsToJSON,
} from '../models';

export interface CreateQuestionnaireTemplateRequest {
    body?: QuestionnaireTemplateModel;
}

export interface DeleteQuestionnaireTemplateRequest {
    questionnaireTemplateId: number;
}

export interface RetrieveListQuestionnaireTemplateRequest {
    arguments: QuestionnaireTemplateRetrieveListArguments;
}

export interface RetrieveQuestionnaireTemplateRequest {
    questionnaireTemplateId: number;
}

export interface SetQuestionnaireTemplateRequest {
    body?: QuestionnaireTemplateModel;
}

export interface UpdateQuestionnaireTemplateRequest {
    questionnaireTemplateId: number;
    body?: QuestionnaireTemplateModel;
}

/**
 * 
 */
export class QuestionnaireTemplateApi extends runtime.BaseAPI {

    /**
     * Add a new QuestionnaireTemplate
     * Add a new QuestionnaireTemplate
     */
    async createQuestionnaireTemplateRaw(requestParameters: CreateQuestionnaireTemplateRequest): Promise<runtime.ApiResponse<ApiResponse33>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/questionnaire-template`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: QuestionnaireTemplateModelToJSON(requestParameters.body),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResponse33FromJSON(jsonValue));
    }

    /**
     * Add a new QuestionnaireTemplate
     * Add a new QuestionnaireTemplate
     */
    createQuestionnaireTemplate = async(requestParameters: CreateQuestionnaireTemplateRequest): Promise<ApiResponse33> => {
        const response = await this.createQuestionnaireTemplateRaw(requestParameters);
        return await response.value();
    }

    /**
     * Delete an QuestionnaireTemplate entry
     * Delete an QuestionnaireTemplate entry
     */
    async deleteQuestionnaireTemplateRaw(requestParameters: DeleteQuestionnaireTemplateRequest): Promise<runtime.ApiResponse<ApiResponse31>> {
        if (requestParameters.questionnaireTemplateId === null || requestParameters.questionnaireTemplateId === undefined) {
            throw new runtime.RequiredError('questionnaireTemplateId','Required parameter requestParameters.questionnaireTemplateId was null or undefined when calling deleteQuestionnaireTemplate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/questionnaire-template/{questionnaire-templateId}`.replace(`{${"questionnaire-templateId"}}`, encodeURIComponent(String(requestParameters.questionnaireTemplateId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResponse31FromJSON(jsonValue));
    }

    /**
     * Delete an QuestionnaireTemplate entry
     * Delete an QuestionnaireTemplate entry
     */
    deleteQuestionnaireTemplate = async(requestParameters: DeleteQuestionnaireTemplateRequest): Promise<ApiResponse31> => {
        const response = await this.deleteQuestionnaireTemplateRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieve List of QuestionnaireTemplate
     * Retrieve List of QuestionnaireTemplate
     */
    async retrieveListQuestionnaireTemplateRaw(requestParameters: RetrieveListQuestionnaireTemplateRequest): Promise<runtime.ApiResponse<QuestionnaireTemplateListResponse>> {
        if (requestParameters.arguments === null || requestParameters.arguments === undefined) {
            throw new runtime.RequiredError('arguments','Required parameter requestParameters.arguments was null or undefined when calling retrieveListQuestionnaireTemplate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/questionnaire-template`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: QuestionnaireTemplateRetrieveListArgumentsToJSON(requestParameters.arguments),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => QuestionnaireTemplateListResponseFromJSON(jsonValue));
    }

    /**
     * Retrieve List of QuestionnaireTemplate
     * Retrieve List of QuestionnaireTemplate
     */
    retrieveListQuestionnaireTemplate = async(requestParameters: RetrieveListQuestionnaireTemplateRequest): Promise<QuestionnaireTemplateListResponse> => {
        const response = await this.retrieveListQuestionnaireTemplateRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieve an QuestionnaireTemplate
     * Retrieve a QuestionnaireTemplate
     */
    async retrieveQuestionnaireTemplateRaw(requestParameters: RetrieveQuestionnaireTemplateRequest): Promise<runtime.ApiResponse<QuestionnaireTemplateModel>> {
        if (requestParameters.questionnaireTemplateId === null || requestParameters.questionnaireTemplateId === undefined) {
            throw new runtime.RequiredError('questionnaireTemplateId','Required parameter requestParameters.questionnaireTemplateId was null or undefined when calling retrieveQuestionnaireTemplate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/questionnaire-template/{questionnaire-templateId}`.replace(`{${"questionnaire-templateId"}}`, encodeURIComponent(String(requestParameters.questionnaireTemplateId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => QuestionnaireTemplateModelFromJSON(jsonValue));
    }

    /**
     * Retrieve an QuestionnaireTemplate
     * Retrieve a QuestionnaireTemplate
     */
    retrieveQuestionnaireTemplate = async(requestParameters: RetrieveQuestionnaireTemplateRequest): Promise<QuestionnaireTemplateModel> => {
        const response = await this.retrieveQuestionnaireTemplateRaw(requestParameters);
        return await response.value();
    }

    /**
     * Set (create or update) a d=QuestionnaireTemplate. If id or links are set in the data, and a corresponding QuestionnaireTemplate is found in Bumbal, an update will be performed.
     * Set (create or update) a QuestionnaireTemplate
     */
    async setQuestionnaireTemplateRaw(requestParameters: SetQuestionnaireTemplateRequest): Promise<runtime.ApiResponse<ModelApiResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/questionnaire-template/set`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: QuestionnaireTemplateModelToJSON(requestParameters.body),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelApiResponseFromJSON(jsonValue));
    }

    /**
     * Set (create or update) a d=QuestionnaireTemplate. If id or links are set in the data, and a corresponding QuestionnaireTemplate is found in Bumbal, an update will be performed.
     * Set (create or update) a QuestionnaireTemplate
     */
    setQuestionnaireTemplate = async(requestParameters: SetQuestionnaireTemplateRequest): Promise<ModelApiResponse> => {
        const response = await this.setQuestionnaireTemplateRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update a specific QuestionnaireTemplate object
     * Update a specific QuestionnaireTemplate object
     */
    async updateQuestionnaireTemplateRaw(requestParameters: UpdateQuestionnaireTemplateRequest): Promise<runtime.ApiResponse<ApiResponse30>> {
        if (requestParameters.questionnaireTemplateId === null || requestParameters.questionnaireTemplateId === undefined) {
            throw new runtime.RequiredError('questionnaireTemplateId','Required parameter requestParameters.questionnaireTemplateId was null or undefined when calling updateQuestionnaireTemplate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/questionnaire-template/{questionnaire-templateId}`.replace(`{${"questionnaire-templateId"}}`, encodeURIComponent(String(requestParameters.questionnaireTemplateId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: QuestionnaireTemplateModelToJSON(requestParameters.body),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResponse30FromJSON(jsonValue));
    }

    /**
     * Update a specific QuestionnaireTemplate object
     * Update a specific QuestionnaireTemplate object
     */
    updateQuestionnaireTemplate = async(requestParameters: UpdateQuestionnaireTemplateRequest): Promise<ApiResponse30> => {
        const response = await this.updateQuestionnaireTemplateRaw(requestParameters);
        return await response.value();
    }

}
