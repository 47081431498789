/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal Client Api
 * Bumbal API documentation
 *
 * The version of the OpenAPI document: 2.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel } from '../runtime';
import {
    DriverUnavailabilityFiltersModel,
    DriverUnavailabilityFiltersModelFromJSON,
    DriverUnavailabilityFiltersModelFromJSONTyped,
    DriverUnavailabilityFiltersModelToJSON,
    DriverUnavailabilityOptionsModel,
    DriverUnavailabilityOptionsModelFromJSON,
    DriverUnavailabilityOptionsModelFromJSONTyped,
    DriverUnavailabilityOptionsModelToJSON,
} from './';


export interface DriverUnavailabilityRetrieveListArgumentsInterface {
    options?: DriverUnavailabilityOptionsModel;
    filters?: DriverUnavailabilityFiltersModel;
    limit?: number;
    offset?: number;
    sorting_column?: DriverUnavailabilityRetrieveListArgumentsSortingColumnEnum;
    sorting_direction?: DriverUnavailabilityRetrieveListArgumentsSortingDirectionEnum;
    search_text?: string;
    as_list?: boolean;
}

/**
 * 
 * @export
 * @interface DriverUnavailabilityRetrieveListArguments
 */
export class DriverUnavailabilityRetrieveListArguments extends BaseModel<DriverUnavailabilityRetrieveListArgumentsInterface> {

    /**
     * 
     * @type {DriverUnavailabilityOptionsModel}
     * @memberof DriverUnavailabilityRetrieveListArguments
     */
    options?: DriverUnavailabilityOptionsModel;
    /**
     * 
     * @type {DriverUnavailabilityFiltersModel}
     * @memberof DriverUnavailabilityRetrieveListArguments
     */
    filters?: DriverUnavailabilityFiltersModel;
    /**
     * 
     * @type {number}
     * @memberof DriverUnavailabilityRetrieveListArguments
     */
    limit?: number;
    /**
     * 
     * @type {number}
     * @memberof DriverUnavailabilityRetrieveListArguments
     */
    offset?: number;
    /**
     * Sorting Column
     * @type {string}
     * @memberof DriverUnavailabilityRetrieveListArguments
     */
    sorting_column?: DriverUnavailabilityRetrieveListArgumentsSortingColumnEnum;
    /**
     * Sorting Direction
     * @type {string}
     * @memberof DriverUnavailabilityRetrieveListArguments
     */
    sorting_direction?: DriverUnavailabilityRetrieveListArgumentsSortingDirectionEnum;
    /**
     * 
     * @type {string}
     * @memberof DriverUnavailabilityRetrieveListArguments
     */
    search_text?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DriverUnavailabilityRetrieveListArguments
     */
    as_list?: boolean;

    constructor(data?: DriverUnavailabilityRetrieveListArgumentsInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(value: DriverUnavailabilityRetrieveListArgumentsInterface): { [property: string]: any } {
        return {};
    }

}

export function DriverUnavailabilityRetrieveListArgumentsFromJSON(json: any): DriverUnavailabilityRetrieveListArguments {
    return DriverUnavailabilityRetrieveListArgumentsFromJSONTyped(json, false);
}

export function DriverUnavailabilityRetrieveListArgumentsFromJSONTyped(json: any, ignoreDiscriminator: boolean): DriverUnavailabilityRetrieveListArguments {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = DriverUnavailabilityRetrieveListArguments;
    try {
        let localClass = require.context('../override', true)('./DriverUnavailabilityRetrieveListArguments');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'options': !exists(json, 'options') ? undefined : DriverUnavailabilityOptionsModelFromJSON(json['options']),
        'filters': !exists(json, 'filters') ? undefined : DriverUnavailabilityFiltersModelFromJSON(json['filters']),
        'limit': !exists(json, 'limit') ? undefined : Number(json['limit']),
        'offset': !exists(json, 'offset') ? undefined : Number(json['offset']),
        'sorting_column': !exists(json, 'sorting_column') ? undefined : json['sorting_column'],
        'sorting_direction': !exists(json, 'sorting_direction') ? undefined : json['sorting_direction'],
        'search_text': !exists(json, 'search_text') ? undefined : json['search_text'],
        'as_list': !exists(json, 'as_list') ? undefined : json['as_list'],
    });
}

export function DriverUnavailabilityRetrieveListArgumentsToJSON(value?: DriverUnavailabilityRetrieveListArguments | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = DriverUnavailabilityRetrieveListArguments;
    try {
        let localClass = require.context('../override', true)('./DriverUnavailabilityRetrieveListArguments');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return {
        
        'options': DriverUnavailabilityOptionsModelToJSON(value.options),
        'filters': DriverUnavailabilityFiltersModelToJSON(value.filters),
        'limit': value.limit,
        'offset': value.offset,
        'sorting_column': value.sorting_column,
        'sorting_direction': value.sorting_direction,
        'search_text': value.search_text,
        'as_list': value.as_list,
        ...createClass.toJson(value),
    };
}

/**
* @export
* @enum {string}
*/
export enum DriverUnavailabilityRetrieveListArgumentsSortingColumnEnum {
    Id = 'id',
    Name = 'name'
}
/**
* @export
* @enum {string}
*/
export enum DriverUnavailabilityRetrieveListArgumentsSortingDirectionEnum {
    Asc = 'asc',
    Desc = 'desc'
}


