import { BaseAPI } from '../api';
import LocalStorageManager from '../utils/LocalStorageManager';

export default function apiConfig(newToken: string | null = null, environment: string | null = null) {
    const lsm = new LocalStorageManager();
    if (environment) {
        lsm.setEnvironment(environment);
    }

    const API_URL = `https://${environment ?? lsm.getEnvironment()}.freightlive.eu/api/v2`;
    let storedKey = newToken ?? lsm.getToken() ?? '';
    BaseAPI.setConfig(API_URL, (header: string) => {return header === 'Authorization' ? 'Bearer '+ storedKey : ''});
}