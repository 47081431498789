/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal Client Api
 * Bumbal API documentation
 *
 * The version of the OpenAPI document: 2.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiResponse401,
    ApiResponse401FromJSON,
    ApiResponse401ToJSON,
    ApiResponse403,
    ApiResponse403FromJSON,
    ApiResponse403ToJSON,
    ApiResponse405,
    ApiResponse405FromJSON,
    ApiResponse405ToJSON,
    ApiResponse57,
    ApiResponse57FromJSON,
    ApiResponse57ToJSON,
    ApiResponse58,
    ApiResponse58FromJSON,
    ApiResponse58ToJSON,
    ApiResponse59,
    ApiResponse59FromJSON,
    ApiResponse59ToJSON,
    ModelApiResponse,
    ModelApiResponseFromJSON,
    ModelApiResponseToJSON,
    VehicleModel,
    VehicleModelFromJSON,
    VehicleModelToJSON,
    VehicleRetrieveListArguments,
    VehicleRetrieveListArgumentsFromJSON,
    VehicleRetrieveListArgumentsToJSON,
} from '../models';

export interface CreateVehicleRequest {
    body?: VehicleModel;
}

export interface DeleteVehicleRequest {
    vehicleId: number;
}

export interface RetrieveListVehicleRequest {
    arguments: VehicleRetrieveListArguments;
}

export interface RetrieveVehicleRequest {
    vehicleId: number;
    includeVehicleTags: boolean;
    includeUpdatedBy: boolean;
}

export interface SetVehicleRequest {
    body?: VehicleModel;
}

export interface UpdateVehicleRequest {
    vehicleId: number;
    body?: VehicleModel;
}

/**
 * 
 */
export class VehicleApi extends runtime.BaseAPI {

    /**
     * Add a new vehicle
     * Add a new vehicle
     */
    async createVehicleRaw(requestParameters: CreateVehicleRequest): Promise<runtime.ApiResponse<ApiResponse57>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/vehicle`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: VehicleModelToJSON(requestParameters.body),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResponse57FromJSON(jsonValue));
    }

    /**
     * Add a new vehicle
     * Add a new vehicle
     */
    createVehicle = async(requestParameters: CreateVehicleRequest): Promise<ApiResponse57> => {
        const response = await this.createVehicleRaw(requestParameters);
        return await response.value();
    }

    /**
     * Delete a vehicle entry
     * Delete a vehicle entry
     */
    async deleteVehicleRaw(requestParameters: DeleteVehicleRequest): Promise<runtime.ApiResponse<ApiResponse58>> {
        if (requestParameters.vehicleId === null || requestParameters.vehicleId === undefined) {
            throw new runtime.RequiredError('vehicleId','Required parameter requestParameters.vehicleId was null or undefined when calling deleteVehicle.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/vehicle/{vehicleId}`.replace(`{${"vehicleId"}}`, encodeURIComponent(String(requestParameters.vehicleId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResponse58FromJSON(jsonValue));
    }

    /**
     * Delete a vehicle entry
     * Delete a vehicle entry
     */
    deleteVehicle = async(requestParameters: DeleteVehicleRequest): Promise<ApiResponse58> => {
        const response = await this.deleteVehicleRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieve List of Vehicles
     * Retrieve List of Vehicles
     */
    async retrieveListVehicleRaw(requestParameters: RetrieveListVehicleRequest): Promise<runtime.ApiResponse<Array<VehicleModel>>> {
        if (requestParameters.arguments === null || requestParameters.arguments === undefined) {
            throw new runtime.RequiredError('arguments','Required parameter requestParameters.arguments was null or undefined when calling retrieveListVehicle.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/vehicle`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: VehicleRetrieveListArgumentsToJSON(requestParameters.arguments),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(VehicleModelFromJSON));
    }

    /**
     * Retrieve List of Vehicles
     * Retrieve List of Vehicles
     */
    retrieveListVehicle = async(requestParameters: RetrieveListVehicleRequest): Promise<Array<VehicleModel>> => {
        const response = await this.retrieveListVehicleRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns a single vehicle
     * Find vehicle by ID
     */
    async retrieveVehicleRaw(requestParameters: RetrieveVehicleRequest): Promise<runtime.ApiResponse<VehicleModel>> {
        if (requestParameters.vehicleId === null || requestParameters.vehicleId === undefined) {
            throw new runtime.RequiredError('vehicleId','Required parameter requestParameters.vehicleId was null or undefined when calling retrieveVehicle.');
        }

        if (requestParameters.includeVehicleTags === null || requestParameters.includeVehicleTags === undefined) {
            throw new runtime.RequiredError('includeVehicleTags','Required parameter requestParameters.includeVehicleTags was null or undefined when calling retrieveVehicle.');
        }

        if (requestParameters.includeUpdatedBy === null || requestParameters.includeUpdatedBy === undefined) {
            throw new runtime.RequiredError('includeUpdatedBy','Required parameter requestParameters.includeUpdatedBy was null or undefined when calling retrieveVehicle.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.includeVehicleTags !== undefined) {
            queryParameters['include_vehicle_tags'] = requestParameters.includeVehicleTags;
        }

        if (requestParameters.includeUpdatedBy !== undefined) {
            queryParameters['include_updated_by'] = requestParameters.includeUpdatedBy;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/vehicle/{vehicleId}`.replace(`{${"vehicleId"}}`, encodeURIComponent(String(requestParameters.vehicleId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => VehicleModelFromJSON(jsonValue));
    }

    /**
     * Returns a single vehicle
     * Find vehicle by ID
     */
    retrieveVehicle = async(requestParameters: RetrieveVehicleRequest): Promise<VehicleModel> => {
        const response = await this.retrieveVehicleRaw(requestParameters);
        return await response.value();
    }

    /**
     * Set (create or update) a vehicle. If id or links are set in the data, and a corresponding vehicle is found in Bumbal, an update will be performed.
     * Set (create or update) a vehicle
     */
    async setVehicleRaw(requestParameters: SetVehicleRequest): Promise<runtime.ApiResponse<ModelApiResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/vehicle/set`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: VehicleModelToJSON(requestParameters.body),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelApiResponseFromJSON(jsonValue));
    }

    /**
     * Set (create or update) a vehicle. If id or links are set in the data, and a corresponding vehicle is found in Bumbal, an update will be performed.
     * Set (create or update) a vehicle
     */
    setVehicle = async(requestParameters: SetVehicleRequest): Promise<ModelApiResponse> => {
        const response = await this.setVehicleRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update a vehicle
     * Update a vehicle
     */
    async updateVehicleRaw(requestParameters: UpdateVehicleRequest): Promise<runtime.ApiResponse<ModelApiResponse>> {
        if (requestParameters.vehicleId === null || requestParameters.vehicleId === undefined) {
            throw new runtime.RequiredError('vehicleId','Required parameter requestParameters.vehicleId was null or undefined when calling updateVehicle.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/vehicle/{vehicleId}`.replace(`{${"vehicleId"}}`, encodeURIComponent(String(requestParameters.vehicleId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: VehicleModelToJSON(requestParameters.body),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelApiResponseFromJSON(jsonValue));
    }

    /**
     * Update a vehicle
     * Update a vehicle
     */
    updateVehicle = async(requestParameters: UpdateVehicleRequest): Promise<ModelApiResponse> => {
        const response = await this.updateVehicleRaw(requestParameters);
        return await response.value();
    }

}
