/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal Client Api
 * Bumbal API documentation
 *
 * The version of the OpenAPI document: 2.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel } from '../runtime';
import {
    UnsuccessfulReasonFiltersModel,
    UnsuccessfulReasonFiltersModelFromJSON,
    UnsuccessfulReasonFiltersModelFromJSONTyped,
    UnsuccessfulReasonFiltersModelToJSON,
} from './';


export interface UnsuccessfulReasonRetrieveListArgumentsInterface {
    options?: object;
    filters?: UnsuccessfulReasonFiltersModel;
    limit?: number;
    offset?: number;
    sorting_column?: UnsuccessfulReasonRetrieveListArgumentsSortingColumnEnum;
    sorting_direction?: UnsuccessfulReasonRetrieveListArgumentsSortingDirectionEnum;
    search_text?: string;
    as_list?: boolean;
}

/**
 * 
 * @export
 * @interface UnsuccessfulReasonRetrieveListArguments
 */
export class UnsuccessfulReasonRetrieveListArguments extends BaseModel<UnsuccessfulReasonRetrieveListArgumentsInterface> {

    /**
     * 
     * @type {object}
     * @memberof UnsuccessfulReasonRetrieveListArguments
     */
    options?: object;
    /**
     * 
     * @type {UnsuccessfulReasonFiltersModel}
     * @memberof UnsuccessfulReasonRetrieveListArguments
     */
    filters?: UnsuccessfulReasonFiltersModel;
    /**
     * 
     * @type {number}
     * @memberof UnsuccessfulReasonRetrieveListArguments
     */
    limit?: number;
    /**
     * 
     * @type {number}
     * @memberof UnsuccessfulReasonRetrieveListArguments
     */
    offset?: number;
    /**
     * Sorting Column
     * @type {string}
     * @memberof UnsuccessfulReasonRetrieveListArguments
     */
    sorting_column?: UnsuccessfulReasonRetrieveListArgumentsSortingColumnEnum;
    /**
     * Sorting Direction
     * @type {string}
     * @memberof UnsuccessfulReasonRetrieveListArguments
     */
    sorting_direction?: UnsuccessfulReasonRetrieveListArgumentsSortingDirectionEnum;
    /**
     * 
     * @type {string}
     * @memberof UnsuccessfulReasonRetrieveListArguments
     */
    search_text?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UnsuccessfulReasonRetrieveListArguments
     */
    as_list?: boolean;

    constructor(data?: UnsuccessfulReasonRetrieveListArgumentsInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(value: UnsuccessfulReasonRetrieveListArgumentsInterface): { [property: string]: any } {
        return {};
    }

}

export function UnsuccessfulReasonRetrieveListArgumentsFromJSON(json: any): UnsuccessfulReasonRetrieveListArguments {
    return UnsuccessfulReasonRetrieveListArgumentsFromJSONTyped(json, false);
}

export function UnsuccessfulReasonRetrieveListArgumentsFromJSONTyped(json: any, ignoreDiscriminator: boolean): UnsuccessfulReasonRetrieveListArguments {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = UnsuccessfulReasonRetrieveListArguments;
    try {
        let localClass = require.context('../override', true)('./UnsuccessfulReasonRetrieveListArguments');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'options': !exists(json, 'options') ? undefined : json['options'],
        'filters': !exists(json, 'filters') ? undefined : UnsuccessfulReasonFiltersModelFromJSON(json['filters']),
        'limit': !exists(json, 'limit') ? undefined : Number(json['limit']),
        'offset': !exists(json, 'offset') ? undefined : Number(json['offset']),
        'sorting_column': !exists(json, 'sorting_column') ? undefined : json['sorting_column'],
        'sorting_direction': !exists(json, 'sorting_direction') ? undefined : json['sorting_direction'],
        'search_text': !exists(json, 'search_text') ? undefined : json['search_text'],
        'as_list': !exists(json, 'as_list') ? undefined : json['as_list'],
    });
}

export function UnsuccessfulReasonRetrieveListArgumentsToJSON(value?: UnsuccessfulReasonRetrieveListArguments | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = UnsuccessfulReasonRetrieveListArguments;
    try {
        let localClass = require.context('../override', true)('./UnsuccessfulReasonRetrieveListArguments');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return {
        
        'options': value.options,
        'filters': UnsuccessfulReasonFiltersModelToJSON(value.filters),
        'limit': value.limit,
        'offset': value.offset,
        'sorting_column': value.sorting_column,
        'sorting_direction': value.sorting_direction,
        'search_text': value.search_text,
        'as_list': value.as_list,
        ...createClass.toJson(value),
    };
}

/**
* @export
* @enum {string}
*/
export enum UnsuccessfulReasonRetrieveListArgumentsSortingColumnEnum {
    Id = 'id',
    Name = 'name'
}
/**
* @export
* @enum {string}
*/
export enum UnsuccessfulReasonRetrieveListArgumentsSortingDirectionEnum {
    Asc = 'asc',
    Desc = 'desc'
}


