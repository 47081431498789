/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal Client Api
 * Bumbal API documentation
 *
 * The version of the OpenAPI document: 2.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel } from '../runtime';
import {
    UserNotificationFiltersModel,
    UserNotificationFiltersModelFromJSON,
    UserNotificationFiltersModelFromJSONTyped,
    UserNotificationFiltersModelToJSON,
    UserNotificationOptionsModel,
    UserNotificationOptionsModelFromJSON,
    UserNotificationOptionsModelFromJSONTyped,
    UserNotificationOptionsModelToJSON,
} from './';


export interface UserNotificationRetrieveListArgumentsInterface {
    options?: UserNotificationOptionsModel;
    filters?: UserNotificationFiltersModel;
    limit?: number;
    offset?: number;
    sorting_column?: UserNotificationRetrieveListArgumentsSortingColumnEnum;
    sorting_direction?: UserNotificationRetrieveListArgumentsSortingDirectionEnum;
    search_text?: string;
}

/**
 * 
 * @export
 * @interface UserNotificationRetrieveListArguments
 */
export class UserNotificationRetrieveListArguments extends BaseModel<UserNotificationRetrieveListArgumentsInterface> {

    /**
     * 
     * @type {UserNotificationOptionsModel}
     * @memberof UserNotificationRetrieveListArguments
     */
    options?: UserNotificationOptionsModel;
    /**
     * 
     * @type {UserNotificationFiltersModel}
     * @memberof UserNotificationRetrieveListArguments
     */
    filters?: UserNotificationFiltersModel;
    /**
     * 
     * @type {number}
     * @memberof UserNotificationRetrieveListArguments
     */
    limit?: number;
    /**
     * 
     * @type {number}
     * @memberof UserNotificationRetrieveListArguments
     */
    offset?: number;
    /**
     * Sorting Column
     * @type {string}
     * @memberof UserNotificationRetrieveListArguments
     */
    sorting_column?: UserNotificationRetrieveListArgumentsSortingColumnEnum;
    /**
     * Sorting Direction
     * @type {string}
     * @memberof UserNotificationRetrieveListArguments
     */
    sorting_direction?: UserNotificationRetrieveListArgumentsSortingDirectionEnum;
    /**
     * 
     * @type {string}
     * @memberof UserNotificationRetrieveListArguments
     */
    search_text?: string;

    constructor(data?: UserNotificationRetrieveListArgumentsInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(value: UserNotificationRetrieveListArgumentsInterface): { [property: string]: any } {
        return {};
    }

}

export function UserNotificationRetrieveListArgumentsFromJSON(json: any): UserNotificationRetrieveListArguments {
    return UserNotificationRetrieveListArgumentsFromJSONTyped(json, false);
}

export function UserNotificationRetrieveListArgumentsFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserNotificationRetrieveListArguments {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = UserNotificationRetrieveListArguments;
    try {
        let localClass = require.context('../override', true)('./UserNotificationRetrieveListArguments');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'options': !exists(json, 'options') ? undefined : UserNotificationOptionsModelFromJSON(json['options']),
        'filters': !exists(json, 'filters') ? undefined : UserNotificationFiltersModelFromJSON(json['filters']),
        'limit': !exists(json, 'limit') ? undefined : Number(json['limit']),
        'offset': !exists(json, 'offset') ? undefined : Number(json['offset']),
        'sorting_column': !exists(json, 'sorting_column') ? undefined : json['sorting_column'],
        'sorting_direction': !exists(json, 'sorting_direction') ? undefined : json['sorting_direction'],
        'search_text': !exists(json, 'search_text') ? undefined : json['search_text'],
    });
}

export function UserNotificationRetrieveListArgumentsToJSON(value?: UserNotificationRetrieveListArguments | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = UserNotificationRetrieveListArguments;
    try {
        let localClass = require.context('../override', true)('./UserNotificationRetrieveListArguments');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return {
        
        'options': UserNotificationOptionsModelToJSON(value.options),
        'filters': UserNotificationFiltersModelToJSON(value.filters),
        'limit': value.limit,
        'offset': value.offset,
        'sorting_column': value.sorting_column,
        'sorting_direction': value.sorting_direction,
        'search_text': value.search_text,
        ...createClass.toJson(value),
    };
}

/**
* @export
* @enum {string}
*/
export enum UserNotificationRetrieveListArgumentsSortingColumnEnum {
    Id = 'id',
    Name = 'name',
    Description = 'description',
    Subject = 'subject'
}
/**
* @export
* @enum {string}
*/
export enum UserNotificationRetrieveListArgumentsSortingDirectionEnum {
    ASC = 'ASC',
    DESC = 'DESC'
}


