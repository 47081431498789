/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal Client Api
 * Bumbal API documentation
 *
 * The version of the OpenAPI document: 2.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ModelApiResponse,
    ModelApiResponseFromJSON,
    ModelApiResponseToJSON,
    TrailerModel,
    TrailerModelFromJSON,
    TrailerModelToJSON,
    TrailerRetrieveListArguments,
    TrailerRetrieveListArgumentsFromJSON,
    TrailerRetrieveListArgumentsToJSON,
} from '../models';

export interface CreateTrailerRequest {
    body?: TrailerModel;
}

export interface DeleteTrailerRequest {
    trailerId: number;
}

export interface RetrieveListTrailerRequest {
    arguments: TrailerRetrieveListArguments;
}

export interface RetrieveTrailerRequest {
    trailerId: number;
    includeTrailerTags: boolean;
    includeUpdatedBy: boolean;
}

export interface SetTrailerRequest {
    body?: TrailerModel;
}

export interface UpdateTrailerRequest {
    trailerId: number;
    body?: TrailerModel;
}

/**
 * 
 */
export class TrailerApi extends runtime.BaseAPI {

    /**
     * Add a trailer
     * Add a trailer
     */
    async createTrailerRaw(requestParameters: CreateTrailerRequest): Promise<runtime.ApiResponse<ModelApiResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/trailer`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TrailerModelToJSON(requestParameters.body),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelApiResponseFromJSON(jsonValue));
    }

    /**
     * Add a trailer
     * Add a trailer
     */
    createTrailer = async(requestParameters: CreateTrailerRequest): Promise<ModelApiResponse> => {
        const response = await this.createTrailerRaw(requestParameters);
        return await response.value();
    }

    /**
     * Delete an trailer
     * Delete an trailer
     */
    async deleteTrailerRaw(requestParameters: DeleteTrailerRequest): Promise<runtime.ApiResponse<ModelApiResponse>> {
        if (requestParameters.trailerId === null || requestParameters.trailerId === undefined) {
            throw new runtime.RequiredError('trailerId','Required parameter requestParameters.trailerId was null or undefined when calling deleteTrailer.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/trailer/{trailerId}`.replace(`{${"trailerId"}}`, encodeURIComponent(String(requestParameters.trailerId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelApiResponseFromJSON(jsonValue));
    }

    /**
     * Delete an trailer
     * Delete an trailer
     */
    deleteTrailer = async(requestParameters: DeleteTrailerRequest): Promise<ModelApiResponse> => {
        const response = await this.deleteTrailerRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieve List of Trailers
     * Retrieve List of Trailers
     */
    async retrieveListTrailerRaw(requestParameters: RetrieveListTrailerRequest): Promise<runtime.ApiResponse<Array<TrailerModel>>> {
        if (requestParameters.arguments === null || requestParameters.arguments === undefined) {
            throw new runtime.RequiredError('arguments','Required parameter requestParameters.arguments was null or undefined when calling retrieveListTrailer.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/trailer`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: TrailerRetrieveListArgumentsToJSON(requestParameters.arguments),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TrailerModelFromJSON));
    }

    /**
     * Retrieve List of Trailers
     * Retrieve List of Trailers
     */
    retrieveListTrailer = async(requestParameters: RetrieveListTrailerRequest): Promise<Array<TrailerModel>> => {
        const response = await this.retrieveListTrailerRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns a single trailer
     * Find trailer by ID
     */
    async retrieveTrailerRaw(requestParameters: RetrieveTrailerRequest): Promise<runtime.ApiResponse<TrailerModel>> {
        if (requestParameters.trailerId === null || requestParameters.trailerId === undefined) {
            throw new runtime.RequiredError('trailerId','Required parameter requestParameters.trailerId was null or undefined when calling retrieveTrailer.');
        }

        if (requestParameters.includeTrailerTags === null || requestParameters.includeTrailerTags === undefined) {
            throw new runtime.RequiredError('includeTrailerTags','Required parameter requestParameters.includeTrailerTags was null or undefined when calling retrieveTrailer.');
        }

        if (requestParameters.includeUpdatedBy === null || requestParameters.includeUpdatedBy === undefined) {
            throw new runtime.RequiredError('includeUpdatedBy','Required parameter requestParameters.includeUpdatedBy was null or undefined when calling retrieveTrailer.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.includeTrailerTags !== undefined) {
            queryParameters['include_trailer_tags'] = requestParameters.includeTrailerTags;
        }

        if (requestParameters.includeUpdatedBy !== undefined) {
            queryParameters['include_updated_by'] = requestParameters.includeUpdatedBy;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/trailer/{trailerId}`.replace(`{${"trailerId"}}`, encodeURIComponent(String(requestParameters.trailerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TrailerModelFromJSON(jsonValue));
    }

    /**
     * Returns a single trailer
     * Find trailer by ID
     */
    retrieveTrailer = async(requestParameters: RetrieveTrailerRequest): Promise<TrailerModel> => {
        const response = await this.retrieveTrailerRaw(requestParameters);
        return await response.value();
    }

    /**
     * Set (create or update) a trailer. If id or links are set in the data, and a corresponding trailer is found in Bumbal, an update will be performed.
     * Set (create or update) a trailer
     */
    async setTrailerRaw(requestParameters: SetTrailerRequest): Promise<runtime.ApiResponse<ModelApiResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/trailer/set`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TrailerModelToJSON(requestParameters.body),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelApiResponseFromJSON(jsonValue));
    }

    /**
     * Set (create or update) a trailer. If id or links are set in the data, and a corresponding trailer is found in Bumbal, an update will be performed.
     * Set (create or update) a trailer
     */
    setTrailer = async(requestParameters: SetTrailerRequest): Promise<ModelApiResponse> => {
        const response = await this.setTrailerRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update a trailer
     * Update a trailer
     */
    async updateTrailerRaw(requestParameters: UpdateTrailerRequest): Promise<runtime.ApiResponse<ModelApiResponse>> {
        if (requestParameters.trailerId === null || requestParameters.trailerId === undefined) {
            throw new runtime.RequiredError('trailerId','Required parameter requestParameters.trailerId was null or undefined when calling updateTrailer.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/trailer/{trailerId}`.replace(`{${"trailerId"}}`, encodeURIComponent(String(requestParameters.trailerId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: TrailerModelToJSON(requestParameters.body),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelApiResponseFromJSON(jsonValue));
    }

    /**
     * Update a trailer
     * Update a trailer
     */
    updateTrailer = async(requestParameters: UpdateTrailerRequest): Promise<ModelApiResponse> => {
        const response = await this.updateTrailerRaw(requestParameters);
        return await response.value();
    }

}
