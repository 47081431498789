/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal Client Api
 * Bumbal API documentation
 *
 * The version of the OpenAPI document: 2.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    GetExecutableActivitiesArguments,
    GetExecutableActivitiesArgumentsFromJSON,
    GetExecutableActivitiesArgumentsToJSON,
    ModelApiResponse,
    ModelApiResponseFromJSON,
    ModelApiResponseToJSON,
    RouteFiltersModel,
    RouteFiltersModelFromJSON,
    RouteFiltersModelToJSON,
    RouteListResponse,
    RouteListResponseFromJSON,
    RouteListResponseToJSON,
    RouteModel,
    RouteModelFromJSON,
    RouteModelToJSON,
    RouteRetrieveListArguments,
    RouteRetrieveListArgumentsFromJSON,
    RouteRetrieveListArgumentsToJSON,
    RouteStoreGeoLocations,
    RouteStoreGeoLocationsFromJSON,
    RouteStoreGeoLocationsToJSON,
} from '../models';

export interface BlockRoutesRequest {
    filters: RouteFiltersModel;
}

export interface CreateRouteRequest {
    body?: RouteModel;
}

export interface DeleteRouteRequest {
    routeId: number;
    cancelActivities: boolean;
}

export interface GetExecutableActivitiesRequest {
    arguments: GetExecutableActivitiesArguments;
}

export interface RetrieveListRouteRequest {
    arguments: RouteRetrieveListArguments;
}

export interface RetrieveRouteRequest {
    routeId: number;
    includeAddressObject: boolean;
    includeRouteStatus: boolean;
    includeRouteTags: boolean;
    includeDriverInfo: boolean;
    includeEquipmentInfoCar: boolean;
    includeGpsLocations: boolean;
    includeActivityIds: boolean;
    includeLatestPosition: boolean;
}

export interface RouteStoreGeoLocationsRequest {
    arguments: RouteStoreGeoLocations;
}

export interface SetRouteRequest {
    body?: RouteModel;
}

export interface UnblockRoutesRequest {
    filters: RouteFiltersModel;
}

export interface UpdateRouteRequest {
    routeId: number;
}

/**
 * 
 */
export class RouteApi extends runtime.BaseAPI {

    /**
     * Block routes which satisfy set filters
     * Block routes which satisfy set filters
     */
    async blockRoutesRaw(requestParameters: BlockRoutesRequest): Promise<runtime.ApiResponse<ModelApiResponse>> {
        if (requestParameters.filters === null || requestParameters.filters === undefined) {
            throw new runtime.RequiredError('filters','Required parameter requestParameters.filters was null or undefined when calling blockRoutes.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/route/block-routes`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RouteFiltersModelToJSON(requestParameters.filters),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelApiResponseFromJSON(jsonValue));
    }

    /**
     * Block routes which satisfy set filters
     * Block routes which satisfy set filters
     */
    blockRoutes = async(requestParameters: BlockRoutesRequest): Promise<ModelApiResponse> => {
        const response = await this.blockRoutesRaw(requestParameters);
        return await response.value();
    }

    /**
     * Add a new Route
     * Add a new Route
     */
    async createRouteRaw(requestParameters: CreateRouteRequest): Promise<runtime.ApiResponse<ModelApiResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/route`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RouteModelToJSON(requestParameters.body),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelApiResponseFromJSON(jsonValue));
    }

    /**
     * Add a new Route
     * Add a new Route
     */
    createRoute = async(requestParameters: CreateRouteRequest): Promise<ModelApiResponse> => {
        const response = await this.createRouteRaw(requestParameters);
        return await response.value();
    }

    /**
     * Delete an Route
     * Delete an Route
     */
    async deleteRouteRaw(requestParameters: DeleteRouteRequest): Promise<runtime.ApiResponse<ModelApiResponse>> {
        if (requestParameters.routeId === null || requestParameters.routeId === undefined) {
            throw new runtime.RequiredError('routeId','Required parameter requestParameters.routeId was null or undefined when calling deleteRoute.');
        }

        if (requestParameters.cancelActivities === null || requestParameters.cancelActivities === undefined) {
            throw new runtime.RequiredError('cancelActivities','Required parameter requestParameters.cancelActivities was null or undefined when calling deleteRoute.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.cancelActivities !== undefined) {
            queryParameters['cancel_activities'] = requestParameters.cancelActivities;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/route/{routeId}`.replace(`{${"routeId"}}`, encodeURIComponent(String(requestParameters.routeId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelApiResponseFromJSON(jsonValue));
    }

    /**
     * Delete an Route
     * Delete an Route
     */
    deleteRoute = async(requestParameters: DeleteRouteRequest): Promise<ModelApiResponse> => {
        const response = await this.deleteRouteRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns all activities in this route which have not been executed yet. This will be in execution order.
     * Returns all activities in this route which hav enot been executed yet.
     */
    async getExecutableActivitiesRaw(requestParameters: GetExecutableActivitiesRequest): Promise<runtime.ApiResponse<ModelApiResponse>> {
        if (requestParameters.arguments === null || requestParameters.arguments === undefined) {
            throw new runtime.RequiredError('arguments','Required parameter requestParameters.arguments was null or undefined when calling getExecutableActivities.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/route/get-executable-activities`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetExecutableActivitiesArgumentsToJSON(requestParameters.arguments),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelApiResponseFromJSON(jsonValue));
    }

    /**
     * Returns all activities in this route which have not been executed yet. This will be in execution order.
     * Returns all activities in this route which hav enot been executed yet.
     */
    getExecutableActivities = async(requestParameters: GetExecutableActivitiesRequest): Promise<ModelApiResponse> => {
        const response = await this.getExecutableActivitiesRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieve List of Routes
     * Retrieve List of Routes
     */
    async retrieveListRouteRaw(requestParameters: RetrieveListRouteRequest): Promise<runtime.ApiResponse<RouteListResponse>> {
        if (requestParameters.arguments === null || requestParameters.arguments === undefined) {
            throw new runtime.RequiredError('arguments','Required parameter requestParameters.arguments was null or undefined when calling retrieveListRoute.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/route`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: RouteRetrieveListArgumentsToJSON(requestParameters.arguments),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RouteListResponseFromJSON(jsonValue));
    }

    /**
     * Retrieve List of Routes
     * Retrieve List of Routes
     */
    retrieveListRoute = async(requestParameters: RetrieveListRouteRequest): Promise<RouteListResponse> => {
        const response = await this.retrieveListRouteRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieve an Route
     * Retrieve a Route
     */
    async retrieveRouteRaw(requestParameters: RetrieveRouteRequest): Promise<runtime.ApiResponse<RouteModel>> {
        if (requestParameters.routeId === null || requestParameters.routeId === undefined) {
            throw new runtime.RequiredError('routeId','Required parameter requestParameters.routeId was null or undefined when calling retrieveRoute.');
        }

        if (requestParameters.includeAddressObject === null || requestParameters.includeAddressObject === undefined) {
            throw new runtime.RequiredError('includeAddressObject','Required parameter requestParameters.includeAddressObject was null or undefined when calling retrieveRoute.');
        }

        if (requestParameters.includeRouteStatus === null || requestParameters.includeRouteStatus === undefined) {
            throw new runtime.RequiredError('includeRouteStatus','Required parameter requestParameters.includeRouteStatus was null or undefined when calling retrieveRoute.');
        }

        if (requestParameters.includeRouteTags === null || requestParameters.includeRouteTags === undefined) {
            throw new runtime.RequiredError('includeRouteTags','Required parameter requestParameters.includeRouteTags was null or undefined when calling retrieveRoute.');
        }

        if (requestParameters.includeDriverInfo === null || requestParameters.includeDriverInfo === undefined) {
            throw new runtime.RequiredError('includeDriverInfo','Required parameter requestParameters.includeDriverInfo was null or undefined when calling retrieveRoute.');
        }

        if (requestParameters.includeEquipmentInfoCar === null || requestParameters.includeEquipmentInfoCar === undefined) {
            throw new runtime.RequiredError('includeEquipmentInfoCar','Required parameter requestParameters.includeEquipmentInfoCar was null or undefined when calling retrieveRoute.');
        }

        if (requestParameters.includeGpsLocations === null || requestParameters.includeGpsLocations === undefined) {
            throw new runtime.RequiredError('includeGpsLocations','Required parameter requestParameters.includeGpsLocations was null or undefined when calling retrieveRoute.');
        }

        if (requestParameters.includeActivityIds === null || requestParameters.includeActivityIds === undefined) {
            throw new runtime.RequiredError('includeActivityIds','Required parameter requestParameters.includeActivityIds was null or undefined when calling retrieveRoute.');
        }

        if (requestParameters.includeLatestPosition === null || requestParameters.includeLatestPosition === undefined) {
            throw new runtime.RequiredError('includeLatestPosition','Required parameter requestParameters.includeLatestPosition was null or undefined when calling retrieveRoute.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.includeAddressObject !== undefined) {
            queryParameters['include_address_object'] = requestParameters.includeAddressObject;
        }

        if (requestParameters.includeRouteStatus !== undefined) {
            queryParameters['include_route_status'] = requestParameters.includeRouteStatus;
        }

        if (requestParameters.includeRouteTags !== undefined) {
            queryParameters['include_route_tags'] = requestParameters.includeRouteTags;
        }

        if (requestParameters.includeDriverInfo !== undefined) {
            queryParameters['include_driver_info'] = requestParameters.includeDriverInfo;
        }

        if (requestParameters.includeEquipmentInfoCar !== undefined) {
            queryParameters['include_equipment_info_car'] = requestParameters.includeEquipmentInfoCar;
        }

        if (requestParameters.includeGpsLocations !== undefined) {
            queryParameters['include_gps_locations'] = requestParameters.includeGpsLocations;
        }

        if (requestParameters.includeActivityIds !== undefined) {
            queryParameters['include_activity_ids'] = requestParameters.includeActivityIds;
        }

        if (requestParameters.includeLatestPosition !== undefined) {
            queryParameters['include_latest_position'] = requestParameters.includeLatestPosition;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/route/{routeId}`.replace(`{${"routeId"}}`, encodeURIComponent(String(requestParameters.routeId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RouteModelFromJSON(jsonValue));
    }

    /**
     * Retrieve an Route
     * Retrieve a Route
     */
    retrieveRoute = async(requestParameters: RetrieveRouteRequest): Promise<RouteModel> => {
        const response = await this.retrieveRouteRaw(requestParameters);
        return await response.value();
    }

    /**
     * Store tracked Geo Locations in bulk
     * Store tracked Geo Locations in bulk
     */
    async routeStoreGeoLocationsRaw(requestParameters: RouteStoreGeoLocationsRequest): Promise<runtime.ApiResponse<ModelApiResponse>> {
        if (requestParameters.arguments === null || requestParameters.arguments === undefined) {
            throw new runtime.RequiredError('arguments','Required parameter requestParameters.arguments was null or undefined when calling routeStoreGeoLocations.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/route/store-geo-locations`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RouteStoreGeoLocationsToJSON(requestParameters.arguments),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelApiResponseFromJSON(jsonValue));
    }

    /**
     * Store tracked Geo Locations in bulk
     * Store tracked Geo Locations in bulk
     */
    routeStoreGeoLocations = async(requestParameters: RouteStoreGeoLocationsRequest): Promise<ModelApiResponse> => {
        const response = await this.routeStoreGeoLocationsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Set (create or update) an Route. If id or links are set in the data, and a corresponding route is found in Bumbal, an update will be performed.
     * Set (create or update) an Route
     */
    async setRouteRaw(requestParameters: SetRouteRequest): Promise<runtime.ApiResponse<ModelApiResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/route/set`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RouteModelToJSON(requestParameters.body),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelApiResponseFromJSON(jsonValue));
    }

    /**
     * Set (create or update) an Route. If id or links are set in the data, and a corresponding route is found in Bumbal, an update will be performed.
     * Set (create or update) an Route
     */
    setRoute = async(requestParameters: SetRouteRequest): Promise<ModelApiResponse> => {
        const response = await this.setRouteRaw(requestParameters);
        return await response.value();
    }

    /**
     * Unblock routes which satisfy set filters
     * Unblock routes which satisfy set filters
     */
    async unblockRoutesRaw(requestParameters: UnblockRoutesRequest): Promise<runtime.ApiResponse<ModelApiResponse>> {
        if (requestParameters.filters === null || requestParameters.filters === undefined) {
            throw new runtime.RequiredError('filters','Required parameter requestParameters.filters was null or undefined when calling unblockRoutes.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/route/unblock-routes`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RouteFiltersModelToJSON(requestParameters.filters),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelApiResponseFromJSON(jsonValue));
    }

    /**
     * Unblock routes which satisfy set filters
     * Unblock routes which satisfy set filters
     */
    unblockRoutes = async(requestParameters: UnblockRoutesRequest): Promise<ModelApiResponse> => {
        const response = await this.unblockRoutesRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update an Route
     * Update a Route
     */
    async updateRouteRaw(requestParameters: UpdateRouteRequest): Promise<runtime.ApiResponse<ModelApiResponse>> {
        if (requestParameters.routeId === null || requestParameters.routeId === undefined) {
            throw new runtime.RequiredError('routeId','Required parameter requestParameters.routeId was null or undefined when calling updateRoute.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/route/{routeId}`.replace(`{${"routeId"}}`, encodeURIComponent(String(requestParameters.routeId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelApiResponseFromJSON(jsonValue));
    }

    /**
     * Update an Route
     * Update a Route
     */
    updateRoute = async(requestParameters: UpdateRouteRequest): Promise<ModelApiResponse> => {
        const response = await this.updateRouteRaw(requestParameters);
        return await response.value();
    }

}
