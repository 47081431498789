/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal Client Api
 * Bumbal API documentation
 *
 * The version of the OpenAPI document: 2.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiResponse401,
    ApiResponse401FromJSON,
    ApiResponse401ToJSON,
    ApiResponse403,
    ApiResponse403FromJSON,
    ApiResponse403ToJSON,
    ApiResponse405,
    ApiResponse405FromJSON,
    ApiResponse405ToJSON,
    EquipmentCreateResponse,
    EquipmentCreateResponseFromJSON,
    EquipmentCreateResponseToJSON,
    EquipmentDeleteNotFoundResponse,
    EquipmentDeleteNotFoundResponseFromJSON,
    EquipmentDeleteNotFoundResponseToJSON,
    EquipmentDeleteResponse,
    EquipmentDeleteResponseFromJSON,
    EquipmentDeleteResponseToJSON,
    EquipmentListResponse,
    EquipmentListResponseFromJSON,
    EquipmentListResponseToJSON,
    EquipmentModel,
    EquipmentModelFromJSON,
    EquipmentModelToJSON,
    EquipmentRetrieveListArguments,
    EquipmentRetrieveListArgumentsFromJSON,
    EquipmentRetrieveListArgumentsToJSON,
    EquipmentSetResponse,
    EquipmentSetResponseFromJSON,
    EquipmentSetResponseToJSON,
    EquipmentUpdateResponse,
    EquipmentUpdateResponseFromJSON,
    EquipmentUpdateResponseToJSON,
} from '../models';

export interface CreateEquipmentRequest {
    body?: EquipmentModel;
}

export interface DeleteEquipmentRequest {
    equipmentId: number;
}

export interface RetrieveEquipmentRequest {
    equipmentId: number;
}

export interface RetrieveListEquipmentRequest {
    arguments: EquipmentRetrieveListArguments;
}

export interface SetEquipmentRequest {
    body?: EquipmentModel;
}

export interface UpdateEquipmentRequest {
    equipmentId: number;
    body?: EquipmentModel;
}

/**
 * 
 */
export class EquipmentApi extends runtime.BaseAPI {

    /**
     * Add a new Equipment
     * Add a new Equipment
     */
    async createEquipmentRaw(requestParameters: CreateEquipmentRequest): Promise<runtime.ApiResponse<EquipmentCreateResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/equipment`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EquipmentModelToJSON(requestParameters.body),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => EquipmentCreateResponseFromJSON(jsonValue));
    }

    /**
     * Add a new Equipment
     * Add a new Equipment
     */
    createEquipment = async(requestParameters: CreateEquipmentRequest): Promise<EquipmentCreateResponse> => {
        const response = await this.createEquipmentRaw(requestParameters);
        return await response.value();
    }

    /**
     * Delete an Equipment entry
     * Delete an Equipment entry
     */
    async deleteEquipmentRaw(requestParameters: DeleteEquipmentRequest): Promise<runtime.ApiResponse<EquipmentDeleteResponse>> {
        if (requestParameters.equipmentId === null || requestParameters.equipmentId === undefined) {
            throw new runtime.RequiredError('equipmentId','Required parameter requestParameters.equipmentId was null or undefined when calling deleteEquipment.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/equipment/{equipmentId}`.replace(`{${"equipmentId"}}`, encodeURIComponent(String(requestParameters.equipmentId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => EquipmentDeleteResponseFromJSON(jsonValue));
    }

    /**
     * Delete an Equipment entry
     * Delete an Equipment entry
     */
    deleteEquipment = async(requestParameters: DeleteEquipmentRequest): Promise<EquipmentDeleteResponse> => {
        const response = await this.deleteEquipmentRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieve an Equipment
     * Retrieve a Equipment
     */
    async retrieveEquipmentRaw(requestParameters: RetrieveEquipmentRequest): Promise<runtime.ApiResponse<EquipmentModel>> {
        if (requestParameters.equipmentId === null || requestParameters.equipmentId === undefined) {
            throw new runtime.RequiredError('equipmentId','Required parameter requestParameters.equipmentId was null or undefined when calling retrieveEquipment.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/equipment/{equipmentId}`.replace(`{${"equipmentId"}}`, encodeURIComponent(String(requestParameters.equipmentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => EquipmentModelFromJSON(jsonValue));
    }

    /**
     * Retrieve an Equipment
     * Retrieve a Equipment
     */
    retrieveEquipment = async(requestParameters: RetrieveEquipmentRequest): Promise<EquipmentModel> => {
        const response = await this.retrieveEquipmentRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieve List of Equipment
     * Retrieve List of Equipment
     */
    async retrieveListEquipmentRaw(requestParameters: RetrieveListEquipmentRequest): Promise<runtime.ApiResponse<EquipmentListResponse>> {
        if (requestParameters.arguments === null || requestParameters.arguments === undefined) {
            throw new runtime.RequiredError('arguments','Required parameter requestParameters.arguments was null or undefined when calling retrieveListEquipment.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/equipment`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: EquipmentRetrieveListArgumentsToJSON(requestParameters.arguments),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => EquipmentListResponseFromJSON(jsonValue));
    }

    /**
     * Retrieve List of Equipment
     * Retrieve List of Equipment
     */
    retrieveListEquipment = async(requestParameters: RetrieveListEquipmentRequest): Promise<EquipmentListResponse> => {
        const response = await this.retrieveListEquipmentRaw(requestParameters);
        return await response.value();
    }

    /**
     * Set (create or update) a d=Equipment. If id or links are set in the data, and a corresponding Equipment is found in Bumbal, an update will be performed.
     * Set (create or update) a Equipment
     */
    async setEquipmentRaw(requestParameters: SetEquipmentRequest): Promise<runtime.ApiResponse<EquipmentSetResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/equipment/set`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EquipmentModelToJSON(requestParameters.body),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => EquipmentSetResponseFromJSON(jsonValue));
    }

    /**
     * Set (create or update) a d=Equipment. If id or links are set in the data, and a corresponding Equipment is found in Bumbal, an update will be performed.
     * Set (create or update) a Equipment
     */
    setEquipment = async(requestParameters: SetEquipmentRequest): Promise<EquipmentSetResponse> => {
        const response = await this.setEquipmentRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update a specific Equipment object
     * Update a specific Equipment object
     */
    async updateEquipmentRaw(requestParameters: UpdateEquipmentRequest): Promise<runtime.ApiResponse<EquipmentUpdateResponse>> {
        if (requestParameters.equipmentId === null || requestParameters.equipmentId === undefined) {
            throw new runtime.RequiredError('equipmentId','Required parameter requestParameters.equipmentId was null or undefined when calling updateEquipment.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/equipment/{equipmentId}`.replace(`{${"equipmentId"}}`, encodeURIComponent(String(requestParameters.equipmentId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: EquipmentModelToJSON(requestParameters.body),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => EquipmentUpdateResponseFromJSON(jsonValue));
    }

    /**
     * Update a specific Equipment object
     * Update a specific Equipment object
     */
    updateEquipment = async(requestParameters: UpdateEquipmentRequest): Promise<EquipmentUpdateResponse> => {
        const response = await this.updateEquipmentRaw(requestParameters);
        return await response.value();
    }

}
