import { put } from "redux-saga/effects";
import ActionTypes from "../settings/ActionTypes";
import AppActions from "../store/app/actions";

export const ApiSaga = function* (func: Function, args?: any) {
    yield put({ type: ActionTypes.API_CALL_START })
    try {
        const data = yield args ? func(args) : func()
        yield put({ type: ActionTypes.API_CALL_FINISH })
        return data
    } catch (error) {
        yield put(AppActions.createError(ActionTypes.API_CALL_FAILURE, error as Error))
        throw error;
    }
}
