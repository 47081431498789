/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal Client Api
 * Bumbal API documentation
 *
 * The version of the OpenAPI document: 2.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiResponse401,
    ApiResponse401FromJSON,
    ApiResponse401ToJSON,
    ApiResponse403,
    ApiResponse403FromJSON,
    ApiResponse403ToJSON,
    ApiResponse405,
    ApiResponse405FromJSON,
    ApiResponse405ToJSON,
    ApiResponse51,
    ApiResponse51FromJSON,
    ApiResponse51ToJSON,
    TransactionCreateResponse,
    TransactionCreateResponseFromJSON,
    TransactionCreateResponseToJSON,
    TransactionDeleteNotFoundResponse,
    TransactionDeleteNotFoundResponseFromJSON,
    TransactionDeleteNotFoundResponseToJSON,
    TransactionDeleteResponse,
    TransactionDeleteResponseFromJSON,
    TransactionDeleteResponseToJSON,
    TransactionListResponse,
    TransactionListResponseFromJSON,
    TransactionListResponseToJSON,
    TransactionModel,
    TransactionModelFromJSON,
    TransactionModelToJSON,
    TransactionRetrieveListArguments,
    TransactionRetrieveListArgumentsFromJSON,
    TransactionRetrieveListArgumentsToJSON,
    TransactionSetResponse,
    TransactionSetResponseFromJSON,
    TransactionSetResponseToJSON,
    TransactionTokenArguments,
    TransactionTokenArgumentsFromJSON,
    TransactionTokenArgumentsToJSON,
    TransactionTokenHasFailedArguments,
    TransactionTokenHasFailedArgumentsFromJSON,
    TransactionTokenHasFailedArgumentsToJSON,
    TransactionTokenHasFailedResponse,
    TransactionTokenHasFailedResponseFromJSON,
    TransactionTokenHasFailedResponseToJSON,
    TransactionTokenIsCancelledArguments,
    TransactionTokenIsCancelledArgumentsFromJSON,
    TransactionTokenIsCancelledArgumentsToJSON,
    TransactionTokenIsCancelledResponse,
    TransactionTokenIsCancelledResponseFromJSON,
    TransactionTokenIsCancelledResponseToJSON,
    TransactionTokenIsPaidArguments,
    TransactionTokenIsPaidArgumentsFromJSON,
    TransactionTokenIsPaidArgumentsToJSON,
    TransactionTokenIsPaidResponse,
    TransactionTokenIsPaidResponseFromJSON,
    TransactionTokenIsPaidResponseToJSON,
    TransactionTokenResponse,
    TransactionTokenResponseFromJSON,
    TransactionTokenResponseToJSON,
    TransactionUpdateResponse,
    TransactionUpdateResponseFromJSON,
    TransactionUpdateResponseToJSON,
} from '../models';

export interface CreateTransactionRequest {
    body?: TransactionModel;
}

export interface DeleteTransactionRequest {
    transactionId: number;
}

export interface RetrieveListTransactionRequest {
    arguments: TransactionRetrieveListArguments;
}

export interface RetrieveTransactionRequest {
    transactionId: number;
}

export interface SetTransactionRequest {
    body?: TransactionModel;
}

export interface TokenRequest {
    arguments: TransactionTokenArguments;
}

export interface Token0Request {
    arguments: TransactionTokenIsPaidArguments;
}

export interface Token1Request {
    arguments: TransactionTokenIsCancelledArguments;
}

export interface Token2Request {
    arguments: TransactionTokenHasFailedArguments;
}

export interface UpdateTransactionRequest {
    transactionId: number;
    body?: TransactionModel;
}

/**
 * 
 */
export class TransactionApi extends runtime.BaseAPI {

    /**
     * Add a new Transaction
     * Add a new Transaction
     */
    async createTransactionRaw(requestParameters: CreateTransactionRequest): Promise<runtime.ApiResponse<TransactionCreateResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/transaction`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TransactionModelToJSON(requestParameters.body),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TransactionCreateResponseFromJSON(jsonValue));
    }

    /**
     * Add a new Transaction
     * Add a new Transaction
     */
    createTransaction = async(requestParameters: CreateTransactionRequest): Promise<TransactionCreateResponse> => {
        const response = await this.createTransactionRaw(requestParameters);
        return await response.value();
    }

    /**
     * Delete an Transaction entry
     * Delete an Transaction entry
     */
    async deleteTransactionRaw(requestParameters: DeleteTransactionRequest): Promise<runtime.ApiResponse<TransactionDeleteResponse>> {
        if (requestParameters.transactionId === null || requestParameters.transactionId === undefined) {
            throw new runtime.RequiredError('transactionId','Required parameter requestParameters.transactionId was null or undefined when calling deleteTransaction.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/transaction/{transactionId}`.replace(`{${"transactionId"}}`, encodeURIComponent(String(requestParameters.transactionId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TransactionDeleteResponseFromJSON(jsonValue));
    }

    /**
     * Delete an Transaction entry
     * Delete an Transaction entry
     */
    deleteTransaction = async(requestParameters: DeleteTransactionRequest): Promise<TransactionDeleteResponse> => {
        const response = await this.deleteTransactionRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieve List of Transaction
     * Retrieve List of Transaction
     */
    async retrieveListTransactionRaw(requestParameters: RetrieveListTransactionRequest): Promise<runtime.ApiResponse<TransactionListResponse>> {
        if (requestParameters.arguments === null || requestParameters.arguments === undefined) {
            throw new runtime.RequiredError('arguments','Required parameter requestParameters.arguments was null or undefined when calling retrieveListTransaction.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/transaction`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: TransactionRetrieveListArgumentsToJSON(requestParameters.arguments),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TransactionListResponseFromJSON(jsonValue));
    }

    /**
     * Retrieve List of Transaction
     * Retrieve List of Transaction
     */
    retrieveListTransaction = async(requestParameters: RetrieveListTransactionRequest): Promise<TransactionListResponse> => {
        const response = await this.retrieveListTransactionRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieve an Transaction
     * Retrieve a Transaction
     */
    async retrieveTransactionRaw(requestParameters: RetrieveTransactionRequest): Promise<runtime.ApiResponse<TransactionModel>> {
        if (requestParameters.transactionId === null || requestParameters.transactionId === undefined) {
            throw new runtime.RequiredError('transactionId','Required parameter requestParameters.transactionId was null or undefined when calling retrieveTransaction.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/transaction/{transactionId}`.replace(`{${"transactionId"}}`, encodeURIComponent(String(requestParameters.transactionId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TransactionModelFromJSON(jsonValue));
    }

    /**
     * Retrieve an Transaction
     * Retrieve a Transaction
     */
    retrieveTransaction = async(requestParameters: RetrieveTransactionRequest): Promise<TransactionModel> => {
        const response = await this.retrieveTransactionRaw(requestParameters);
        return await response.value();
    }

    /**
     * Set (create or update) a d=Transaction. If id or links are set in the data, and a corresponding Transaction is found in Bumbal, an update will be performed.
     * Set (create or update) a Transaction
     */
    async setTransactionRaw(requestParameters: SetTransactionRequest): Promise<runtime.ApiResponse<TransactionSetResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/transaction/set`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TransactionModelToJSON(requestParameters.body),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TransactionSetResponseFromJSON(jsonValue));
    }

    /**
     * Set (create or update) a d=Transaction. If id or links are set in the data, and a corresponding Transaction is found in Bumbal, an update will be performed.
     * Set (create or update) a Transaction
     */
    setTransaction = async(requestParameters: SetTransactionRequest): Promise<TransactionSetResponse> => {
        const response = await this.setTransactionRaw(requestParameters);
        return await response.value();
    }

    /**
     * get a transaction token
     * get a transaction token
     */
    async tokenRaw(requestParameters: TokenRequest): Promise<runtime.ApiResponse<TransactionTokenResponse>> {
        if (requestParameters.arguments === null || requestParameters.arguments === undefined) {
            throw new runtime.RequiredError('arguments','Required parameter requestParameters.arguments was null or undefined when calling token.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/transaction/token`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TransactionTokenArgumentsToJSON(requestParameters.arguments),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TransactionTokenResponseFromJSON(jsonValue));
    }

    /**
     * get a transaction token
     * get a transaction token
     */
    token = async(requestParameters: TokenRequest): Promise<TransactionTokenResponse> => {
        const response = await this.tokenRaw(requestParameters);
        return await response.value();
    }

    /**
     * set a transaction to paid with token
     * set a transaction to paid with token
     */
    async token_1Raw(requestParameters: Token0Request): Promise<runtime.ApiResponse<TransactionTokenIsPaidResponse>> {
        if (requestParameters.arguments === null || requestParameters.arguments === undefined) {
            throw new runtime.RequiredError('arguments','Required parameter requestParameters.arguments was null or undefined when calling token_1.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/transaction/token-is-paid`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TransactionTokenIsPaidArgumentsToJSON(requestParameters.arguments),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TransactionTokenIsPaidResponseFromJSON(jsonValue));
    }

    /**
     * set a transaction to paid with token
     * set a transaction to paid with token
     */
    token_1 = async(requestParameters: Token0Request): Promise<TransactionTokenIsPaidResponse> => {
        const response = await this.token_1Raw(requestParameters);
        return await response.value();
    }

    /**
     * set a transaction to cancelled with token
     * set a transaction to cancelled with token
     */
    async token_2Raw(requestParameters: Token1Request): Promise<runtime.ApiResponse<TransactionTokenIsCancelledResponse>> {
        if (requestParameters.arguments === null || requestParameters.arguments === undefined) {
            throw new runtime.RequiredError('arguments','Required parameter requestParameters.arguments was null or undefined when calling token_2.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/transaction/token-is-cancelled`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TransactionTokenIsCancelledArgumentsToJSON(requestParameters.arguments),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TransactionTokenIsCancelledResponseFromJSON(jsonValue));
    }

    /**
     * set a transaction to cancelled with token
     * set a transaction to cancelled with token
     */
    token_2 = async(requestParameters: Token1Request): Promise<TransactionTokenIsCancelledResponse> => {
        const response = await this.token_2Raw(requestParameters);
        return await response.value();
    }

    /**
     * set a transaction to failed with token
     * set a transaction to failed with token
     */
    async token_3Raw(requestParameters: Token2Request): Promise<runtime.ApiResponse<TransactionTokenHasFailedResponse>> {
        if (requestParameters.arguments === null || requestParameters.arguments === undefined) {
            throw new runtime.RequiredError('arguments','Required parameter requestParameters.arguments was null or undefined when calling token_3.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/transaction/token-has-failed`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TransactionTokenHasFailedArgumentsToJSON(requestParameters.arguments),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TransactionTokenHasFailedResponseFromJSON(jsonValue));
    }

    /**
     * set a transaction to failed with token
     * set a transaction to failed with token
     */
    token_3 = async(requestParameters: Token2Request): Promise<TransactionTokenHasFailedResponse> => {
        const response = await this.token_3Raw(requestParameters);
        return await response.value();
    }

    /**
     * Update a specific Transaction object
     * Update a specific Transaction object
     */
    async updateTransactionRaw(requestParameters: UpdateTransactionRequest): Promise<runtime.ApiResponse<TransactionUpdateResponse>> {
        if (requestParameters.transactionId === null || requestParameters.transactionId === undefined) {
            throw new runtime.RequiredError('transactionId','Required parameter requestParameters.transactionId was null or undefined when calling updateTransaction.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/transaction/{transactionId}`.replace(`{${"transactionId"}}`, encodeURIComponent(String(requestParameters.transactionId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: TransactionModelToJSON(requestParameters.body),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TransactionUpdateResponseFromJSON(jsonValue));
    }

    /**
     * Update a specific Transaction object
     * Update a specific Transaction object
     */
    updateTransaction = async(requestParameters: UpdateTransactionRequest): Promise<TransactionUpdateResponse> => {
        const response = await this.updateTransactionRaw(requestParameters);
        return await response.value();
    }

}
