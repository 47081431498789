import { Box, Button } from "@mui/material";
import React from "react";
import { NavLink } from "react-router-dom";
import NavMenuLink from "./NavMenuLink";

interface NavLinkListProps {
    links: NavLink[];
}

const NavLinkList = ({ links }: NavLinkListProps) => {
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                ml: "375px",
            }}
        >
            {links.map((link) =>
                !link.menuItems ? (
                    <Button
                        key={link.text}
                        component={NavLink}
                        exact
                        to={link.to}
                        sx={{
                            color: "#fff",
                            borderRadius: "0px",
                            pb: "0px",
                            fontSize: "1.2em",
                        }}
                        activeStyle={{
                            color: "#F3B70D",
                            borderBottom: "1px solid #F3B70D",
                        }}
                        onClick={link.handleClick}
                    >
                        {link.text}
                    </Button>
                ) : (
                    <NavMenuLink key={link.text} link={link} />
                )
            )}
        </Box>
    );
};
export default NavLinkList;
