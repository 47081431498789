/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal Client Api
 * Bumbal API documentation
 *
 * The version of the OpenAPI document: 2.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel } from '../runtime';
import {
    NotificationFiltersModel,
    NotificationFiltersModelFromJSON,
    NotificationFiltersModelFromJSONTyped,
    NotificationFiltersModelToJSON,
    NotificationOptionsModel,
    NotificationOptionsModelFromJSON,
    NotificationOptionsModelFromJSONTyped,
    NotificationOptionsModelToJSON,
} from './';


export interface NotificationRetrieveListArgumentsInterface {
    options?: NotificationOptionsModel;
    filters?: NotificationFiltersModel;
    limit?: number;
    offset?: number;
    sorting_column?: NotificationRetrieveListArgumentsSortingColumnEnum;
    sorting_direction?: NotificationRetrieveListArgumentsSortingDirectionEnum;
    search_text?: string;
}

/**
 * 
 * @export
 * @interface NotificationRetrieveListArguments
 */
export class NotificationRetrieveListArguments extends BaseModel<NotificationRetrieveListArgumentsInterface> {

    /**
     * 
     * @type {NotificationOptionsModel}
     * @memberof NotificationRetrieveListArguments
     */
    options?: NotificationOptionsModel;
    /**
     * 
     * @type {NotificationFiltersModel}
     * @memberof NotificationRetrieveListArguments
     */
    filters?: NotificationFiltersModel;
    /**
     * 
     * @type {number}
     * @memberof NotificationRetrieveListArguments
     */
    limit?: number;
    /**
     * 
     * @type {number}
     * @memberof NotificationRetrieveListArguments
     */
    offset?: number;
    /**
     * Sorting Column
     * @type {string}
     * @memberof NotificationRetrieveListArguments
     */
    sorting_column?: NotificationRetrieveListArgumentsSortingColumnEnum;
    /**
     * Sorting Direction
     * @type {string}
     * @memberof NotificationRetrieveListArguments
     */
    sorting_direction?: NotificationRetrieveListArgumentsSortingDirectionEnum;
    /**
     * 
     * @type {string}
     * @memberof NotificationRetrieveListArguments
     */
    search_text?: string;

    constructor(data?: NotificationRetrieveListArgumentsInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(value: NotificationRetrieveListArgumentsInterface): { [property: string]: any } {
        return {};
    }

}

export function NotificationRetrieveListArgumentsFromJSON(json: any): NotificationRetrieveListArguments {
    return NotificationRetrieveListArgumentsFromJSONTyped(json, false);
}

export function NotificationRetrieveListArgumentsFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotificationRetrieveListArguments {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = NotificationRetrieveListArguments;
    try {
        let localClass = require.context('../override', true)('./NotificationRetrieveListArguments');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'options': !exists(json, 'options') ? undefined : NotificationOptionsModelFromJSON(json['options']),
        'filters': !exists(json, 'filters') ? undefined : NotificationFiltersModelFromJSON(json['filters']),
        'limit': !exists(json, 'limit') ? undefined : Number(json['limit']),
        'offset': !exists(json, 'offset') ? undefined : Number(json['offset']),
        'sorting_column': !exists(json, 'sorting_column') ? undefined : json['sorting_column'],
        'sorting_direction': !exists(json, 'sorting_direction') ? undefined : json['sorting_direction'],
        'search_text': !exists(json, 'search_text') ? undefined : json['search_text'],
    });
}

export function NotificationRetrieveListArgumentsToJSON(value?: NotificationRetrieveListArguments | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = NotificationRetrieveListArguments;
    try {
        let localClass = require.context('../override', true)('./NotificationRetrieveListArguments');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return {
        
        'options': NotificationOptionsModelToJSON(value.options),
        'filters': NotificationFiltersModelToJSON(value.filters),
        'limit': value.limit,
        'offset': value.offset,
        'sorting_column': value.sorting_column,
        'sorting_direction': value.sorting_direction,
        'search_text': value.search_text,
        ...createClass.toJson(value),
    };
}

/**
* @export
* @enum {string}
*/
export enum NotificationRetrieveListArgumentsSortingColumnEnum {
    Id = 'id',
    Name = 'name',
    Description = 'description',
    Subject = 'subject'
}
/**
* @export
* @enum {string}
*/
export enum NotificationRetrieveListArgumentsSortingDirectionEnum {
    ASC = 'ASC',
    DESC = 'DESC'
}


