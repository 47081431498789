/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal Client Api
 * Bumbal API documentation
 *
 * The version of the OpenAPI document: 2.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel } from '../runtime';
import {
    AssignmentFiltersModel,
    AssignmentFiltersModelFromJSON,
    AssignmentFiltersModelFromJSONTyped,
    AssignmentFiltersModelToJSON,
    AssignmentOptionsModel,
    AssignmentOptionsModelFromJSON,
    AssignmentOptionsModelFromJSONTyped,
    AssignmentOptionsModelToJSON,
} from './';


export interface AssignmentRetrieveListArgumentsInterface {
    options?: AssignmentOptionsModel;
    filters?: AssignmentFiltersModel;
    limit?: number;
    offset?: number;
    search_text?: string;
    sorting_column?: AssignmentRetrieveListArgumentsSortingColumnEnum;
    sorting_direction?: AssignmentRetrieveListArgumentsSortingDirectionEnum;
    as_list?: boolean;
    count_only?: boolean;
}

/**
 * 
 * @export
 * @interface AssignmentRetrieveListArguments
 */
export class AssignmentRetrieveListArguments extends BaseModel<AssignmentRetrieveListArgumentsInterface> {

    /**
     * 
     * @type {AssignmentOptionsModel}
     * @memberof AssignmentRetrieveListArguments
     */
    options?: AssignmentOptionsModel;
    /**
     * 
     * @type {AssignmentFiltersModel}
     * @memberof AssignmentRetrieveListArguments
     */
    filters?: AssignmentFiltersModel;
    /**
     * 
     * @type {number}
     * @memberof AssignmentRetrieveListArguments
     */
    limit?: number;
    /**
     * 
     * @type {number}
     * @memberof AssignmentRetrieveListArguments
     */
    offset?: number;
    /**
     * 
     * @type {string}
     * @memberof AssignmentRetrieveListArguments
     */
    search_text?: string;
    /**
     * Sorting Column. Option include_party_name has to be set for party_name as the sorting column. Option include_booking_account has to be set for account_name as the sorting column.
     * @type {string}
     * @memberof AssignmentRetrieveListArguments
     */
    sorting_column?: AssignmentRetrieveListArgumentsSortingColumnEnum;
    /**
     * Sorting Direction
     * @type {string}
     * @memberof AssignmentRetrieveListArguments
     */
    sorting_direction?: AssignmentRetrieveListArgumentsSortingDirectionEnum;
    /**
     * 
     * @type {boolean}
     * @memberof AssignmentRetrieveListArguments
     */
    as_list?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AssignmentRetrieveListArguments
     */
    count_only?: boolean;

    constructor(data?: AssignmentRetrieveListArgumentsInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(value: AssignmentRetrieveListArgumentsInterface): { [property: string]: any } {
        return {};
    }

}

export function AssignmentRetrieveListArgumentsFromJSON(json: any): AssignmentRetrieveListArguments {
    return AssignmentRetrieveListArgumentsFromJSONTyped(json, false);
}

export function AssignmentRetrieveListArgumentsFromJSONTyped(json: any, ignoreDiscriminator: boolean): AssignmentRetrieveListArguments {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = AssignmentRetrieveListArguments;
    try {
        let localClass = require.context('../override', true)('./AssignmentRetrieveListArguments');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'options': !exists(json, 'options') ? undefined : AssignmentOptionsModelFromJSON(json['options']),
        'filters': !exists(json, 'filters') ? undefined : AssignmentFiltersModelFromJSON(json['filters']),
        'limit': !exists(json, 'limit') ? undefined : Number(json['limit']),
        'offset': !exists(json, 'offset') ? undefined : Number(json['offset']),
        'search_text': !exists(json, 'search_text') ? undefined : json['search_text'],
        'sorting_column': !exists(json, 'sorting_column') ? undefined : json['sorting_column'],
        'sorting_direction': !exists(json, 'sorting_direction') ? undefined : json['sorting_direction'],
        'as_list': !exists(json, 'as_list') ? undefined : json['as_list'],
        'count_only': !exists(json, 'count_only') ? undefined : json['count_only'],
    });
}

export function AssignmentRetrieveListArgumentsToJSON(value?: AssignmentRetrieveListArguments | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = AssignmentRetrieveListArguments;
    try {
        let localClass = require.context('../override', true)('./AssignmentRetrieveListArguments');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return {
        
        'options': AssignmentOptionsModelToJSON(value.options),
        'filters': AssignmentFiltersModelToJSON(value.filters),
        'limit': value.limit,
        'offset': value.offset,
        'search_text': value.search_text,
        'sorting_column': value.sorting_column,
        'sorting_direction': value.sorting_direction,
        'as_list': value.as_list,
        'count_only': value.count_only,
        ...createClass.toJson(value),
    };
}

/**
* @export
* @enum {string}
*/
export enum AssignmentRetrieveListArgumentsSortingColumnEnum {
    AssignmentNr = 'assignment.nr',
    DateTimeFrom = 'date_time_from',
    DateTimeTo = 'date_time_to',
    PartyName = 'party_name',
    AccountName = 'account_name'
}
/**
* @export
* @enum {string}
*/
export enum AssignmentRetrieveListArgumentsSortingDirectionEnum {
    Asc = 'asc',
    Desc = 'desc'
}


