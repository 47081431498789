/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal Client Api
 * Bumbal API documentation
 *
 * The version of the OpenAPI document: 2.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel } from '../runtime';
import {
    NoteCategoryFiltersModel,
    NoteCategoryFiltersModelFromJSON,
    NoteCategoryFiltersModelFromJSONTyped,
    NoteCategoryFiltersModelToJSON,
} from './';


export interface NoteCategoryRetrieveListArgumentsInterface {
    options?: object;
    filters?: NoteCategoryFiltersModel;
    limit?: number;
    offset?: number;
    sorting_column?: NoteCategoryRetrieveListArgumentsSortingColumnEnum;
    sorting_direction?: NoteCategoryRetrieveListArgumentsSortingDirectionEnum;
    search_text?: string;
    as_list?: boolean;
}

/**
 * 
 * @export
 * @interface NoteCategoryRetrieveListArguments
 */
export class NoteCategoryRetrieveListArguments extends BaseModel<NoteCategoryRetrieveListArgumentsInterface> {

    /**
     * 
     * @type {object}
     * @memberof NoteCategoryRetrieveListArguments
     */
    options?: object;
    /**
     * 
     * @type {NoteCategoryFiltersModel}
     * @memberof NoteCategoryRetrieveListArguments
     */
    filters?: NoteCategoryFiltersModel;
    /**
     * 
     * @type {number}
     * @memberof NoteCategoryRetrieveListArguments
     */
    limit?: number;
    /**
     * 
     * @type {number}
     * @memberof NoteCategoryRetrieveListArguments
     */
    offset?: number;
    /**
     * Sorting Column
     * @type {string}
     * @memberof NoteCategoryRetrieveListArguments
     */
    sorting_column?: NoteCategoryRetrieveListArgumentsSortingColumnEnum;
    /**
     * Sorting Direction
     * @type {string}
     * @memberof NoteCategoryRetrieveListArguments
     */
    sorting_direction?: NoteCategoryRetrieveListArgumentsSortingDirectionEnum;
    /**
     * 
     * @type {string}
     * @memberof NoteCategoryRetrieveListArguments
     */
    search_text?: string;
    /**
     * 
     * @type {boolean}
     * @memberof NoteCategoryRetrieveListArguments
     */
    as_list?: boolean;

    constructor(data?: NoteCategoryRetrieveListArgumentsInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(value: NoteCategoryRetrieveListArgumentsInterface): { [property: string]: any } {
        return {};
    }

}

export function NoteCategoryRetrieveListArgumentsFromJSON(json: any): NoteCategoryRetrieveListArguments {
    return NoteCategoryRetrieveListArgumentsFromJSONTyped(json, false);
}

export function NoteCategoryRetrieveListArgumentsFromJSONTyped(json: any, ignoreDiscriminator: boolean): NoteCategoryRetrieveListArguments {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = NoteCategoryRetrieveListArguments;
    try {
        let localClass = require.context('../override', true)('./NoteCategoryRetrieveListArguments');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'options': !exists(json, 'options') ? undefined : json['options'],
        'filters': !exists(json, 'filters') ? undefined : NoteCategoryFiltersModelFromJSON(json['filters']),
        'limit': !exists(json, 'limit') ? undefined : Number(json['limit']),
        'offset': !exists(json, 'offset') ? undefined : Number(json['offset']),
        'sorting_column': !exists(json, 'sorting_column') ? undefined : json['sorting_column'],
        'sorting_direction': !exists(json, 'sorting_direction') ? undefined : json['sorting_direction'],
        'search_text': !exists(json, 'search_text') ? undefined : json['search_text'],
        'as_list': !exists(json, 'as_list') ? undefined : json['as_list'],
    });
}

export function NoteCategoryRetrieveListArgumentsToJSON(value?: NoteCategoryRetrieveListArguments | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = NoteCategoryRetrieveListArguments;
    try {
        let localClass = require.context('../override', true)('./NoteCategoryRetrieveListArguments');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return {
        
        'options': value.options,
        'filters': NoteCategoryFiltersModelToJSON(value.filters),
        'limit': value.limit,
        'offset': value.offset,
        'sorting_column': value.sorting_column,
        'sorting_direction': value.sorting_direction,
        'search_text': value.search_text,
        'as_list': value.as_list,
        ...createClass.toJson(value),
    };
}

/**
* @export
* @enum {string}
*/
export enum NoteCategoryRetrieveListArgumentsSortingColumnEnum {
    Id = 'id',
    Name = 'name',
    Description = 'description',
    Subject = 'subject'
}
/**
* @export
* @enum {string}
*/
export enum NoteCategoryRetrieveListArgumentsSortingDirectionEnum {
    ASC = 'ASC',
    DESC = 'DESC'
}


