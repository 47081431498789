import { Button, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "@mui/material";

const NewsLetter = () => {
    const { t } = useTranslation();

    return (
        <Box
            height="25%"
            maxHeight='300px'
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{
                zIndex: 0,
                position: 'relative',
                boxShadow: "0px -1px 27px -16px rgba(0,0,0,0.73)"
            }}
        >
            <Paper
                elevation={24}
                sx={{
                    height: "160px",
                    width: "50rem",
                    textAlign: "center",
                    bottom: '15px',
                    position: 'absolute',
                    pt: '30px',
                    borderRadius: '5px',
                    zIndex: 2
                }}
            >
                <Box>
                    <Typography variant="h6" color="primary.main" sx={{ display: 'inline' }}>{t('home.newsletter.news')}</Typography>
                    <Typography variant="h6" color="secondary.main" sx={{ display: 'inline' }}>{t('home.newsletter.letter')}</Typography>
                </Box>
                <Typography variant="body1" fontSize="14px" color="#A2A2A2">
                    {t('home.newsletter.details')}
                </Typography>
                <Button
                    variant="contained"
                    component={Link}
                    target="_blank"
                    href="https://support.bumbal.eu/en/newsletter_preference"
                    sx={{
                        borderRadius: "5px",
                        fontSize: '0.875rem',
                        fontWeight: 700,
                        letterSpacing: '0.4px',
                        textTransform: 'none',
                        mt: '10px',
                        px: '30px'
                    }}
                >
                    {t('home.newsletter.subscribe')}
                </Button>
            </Paper>
            <Box sx={{ backgroundColor: 'secondary.main', width: '100%', height: '100px', position: 'absolute', bottom: '0px' }}></Box>
        </Box>
    );
}

export default NewsLetter;