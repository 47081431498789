/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal Client Api
 * Bumbal API documentation
 *
 * The version of the OpenAPI document: 2.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AddActivitiesToRouteArguments,
    AddActivitiesToRouteArgumentsFromJSON,
    AddActivitiesToRouteArgumentsToJSON,
    AddActivitiesToRouteResponse,
    AddActivitiesToRouteResponseFromJSON,
    AddActivitiesToRouteResponseToJSON,
    ApplyPlanningArguments,
    ApplyPlanningArgumentsFromJSON,
    ApplyPlanningArgumentsToJSON,
    AutoPlanArguments,
    AutoPlanArgumentsFromJSON,
    AutoPlanArgumentsToJSON,
    CheckAvailabilityArguments,
    CheckAvailabilityArgumentsFromJSON,
    CheckAvailabilityArgumentsToJSON,
    ModelApiResponse,
    ModelApiResponseFromJSON,
    ModelApiResponseToJSON,
    RemoveActivitiesFromRouteArguments,
    RemoveActivitiesFromRouteArgumentsFromJSON,
    RemoveActivitiesFromRouteArgumentsToJSON,
} from '../models';

export interface ApplyPlanningRequest {
    arguments: ApplyPlanningArguments;
}

export interface AutoPlanRequest {
    arguments: AutoPlanArguments;
}

export interface AutoPlanResultRequest {
    arguments: AutoPlanArguments;
}

export interface CheckAvailabilityRequest {
    arguments: CheckAvailabilityArguments;
}

export interface CheckAvailabilityResultRequest {
    arguments: CheckAvailabilityArguments;
}

export interface PlannerAddActivitiesToRouteRequest {
    arguments: AddActivitiesToRouteArguments;
}

export interface RemoveActivitiesFromRouteRequest {
    arguments: RemoveActivitiesFromRouteArguments;
}

/**
 * 
 */
export class PlannerApi extends runtime.BaseAPI {

    /**
     * Apply a planning schema
     * Apply a planning schema
     */
    async applyPlanningRaw(requestParameters: ApplyPlanningRequest): Promise<runtime.ApiResponse<ModelApiResponse>> {
        if (requestParameters.arguments === null || requestParameters.arguments === undefined) {
            throw new runtime.RequiredError('arguments','Required parameter requestParameters.arguments was null or undefined when calling applyPlanning.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/planner/apply-planning`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApplyPlanningArgumentsToJSON(requestParameters.arguments),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelApiResponseFromJSON(jsonValue));
    }

    /**
     * Apply a planning schema
     * Apply a planning schema
     */
    applyPlanning = async(requestParameters: ApplyPlanningRequest): Promise<ModelApiResponse> => {
        const response = await this.applyPlanningRaw(requestParameters);
        return await response.value();
    }

    /**
     * Plan a certain activity in any fitting route
     * Plan a certain activity in any fitting route
     */
    async autoPlanRaw(requestParameters: AutoPlanRequest): Promise<runtime.ApiResponse<ModelApiResponse>> {
        if (requestParameters.arguments === null || requestParameters.arguments === undefined) {
            throw new runtime.RequiredError('arguments','Required parameter requestParameters.arguments was null or undefined when calling autoPlan.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/planner/auto-plan`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AutoPlanArgumentsToJSON(requestParameters.arguments),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelApiResponseFromJSON(jsonValue));
    }

    /**
     * Plan a certain activity in any fitting route
     * Plan a certain activity in any fitting route
     */
    autoPlan = async(requestParameters: AutoPlanRequest): Promise<ModelApiResponse> => {
        const response = await this.autoPlanRaw(requestParameters);
        return await response.value();
    }

    /**
     * Fetch current result for a auto plan Request. This could be done, in progress or cancelled.
     * Fetch current result for a auto plan Request. This could be done, in progress or cancelled.
     */
    async autoPlanResultRaw(requestParameters: AutoPlanResultRequest): Promise<runtime.ApiResponse<ModelApiResponse>> {
        if (requestParameters.arguments === null || requestParameters.arguments === undefined) {
            throw new runtime.RequiredError('arguments','Required parameter requestParameters.arguments was null or undefined when calling autoPlanResult.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/planner/auto-plan-result`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AutoPlanArgumentsToJSON(requestParameters.arguments),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelApiResponseFromJSON(jsonValue));
    }

    /**
     * Fetch current result for a auto plan Request. This could be done, in progress or cancelled.
     * Fetch current result for a auto plan Request. This could be done, in progress or cancelled.
     */
    autoPlanResult = async(requestParameters: AutoPlanResultRequest): Promise<ModelApiResponse> => {
        const response = await this.autoPlanResultRaw(requestParameters);
        return await response.value();
    }

    /**
     * Change Activity Sequence
     * Change Activity Sequence
     */
    async changeActivitySequenceRaw(): Promise<runtime.ApiResponse<ModelApiResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/planner/change-activity-sequence`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelApiResponseFromJSON(jsonValue));
    }

    /**
     * Change Activity Sequence
     * Change Activity Sequence
     */
    changeActivitySequence = async(): Promise<ModelApiResponse> => {
        const response = await this.changeActivitySequenceRaw();
        return await response.value();
    }

    /**
     * check availability in planning for a certain set of activity properties
     * check availability in planning for a certain set of activity properties
     */
    async checkAvailabilityRaw(requestParameters: CheckAvailabilityRequest): Promise<runtime.ApiResponse<ModelApiResponse>> {
        if (requestParameters.arguments === null || requestParameters.arguments === undefined) {
            throw new runtime.RequiredError('arguments','Required parameter requestParameters.arguments was null or undefined when calling checkAvailability.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/planner/check-availability`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CheckAvailabilityArgumentsToJSON(requestParameters.arguments),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelApiResponseFromJSON(jsonValue));
    }

    /**
     * check availability in planning for a certain set of activity properties
     * check availability in planning for a certain set of activity properties
     */
    checkAvailability = async(requestParameters: CheckAvailabilityRequest): Promise<ModelApiResponse> => {
        const response = await this.checkAvailabilityRaw(requestParameters);
        return await response.value();
    }

    /**
     * Fetch current result for a checkAvailability Request. This could be done, in progress or cancelled.
     * Fetch current result for a checkAvailability Request. This could be done, in progress or cancelled.
     */
    async checkAvailabilityResultRaw(requestParameters: CheckAvailabilityResultRequest): Promise<runtime.ApiResponse<ModelApiResponse>> {
        if (requestParameters.arguments === null || requestParameters.arguments === undefined) {
            throw new runtime.RequiredError('arguments','Required parameter requestParameters.arguments was null or undefined when calling checkAvailabilityResult.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/planner/check-availability-result`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CheckAvailabilityArgumentsToJSON(requestParameters.arguments),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelApiResponseFromJSON(jsonValue));
    }

    /**
     * Fetch current result for a checkAvailability Request. This could be done, in progress or cancelled.
     * Fetch current result for a checkAvailability Request. This could be done, in progress or cancelled.
     */
    checkAvailabilityResult = async(requestParameters: CheckAvailabilityResultRequest): Promise<ModelApiResponse> => {
        const response = await this.checkAvailabilityResultRaw(requestParameters);
        return await response.value();
    }

    /**
     * Add Activities to Route
     * Add Activities to Route
     */
    async plannerAddActivitiesToRouteRaw(requestParameters: PlannerAddActivitiesToRouteRequest): Promise<runtime.ApiResponse<AddActivitiesToRouteResponse>> {
        if (requestParameters.arguments === null || requestParameters.arguments === undefined) {
            throw new runtime.RequiredError('arguments','Required parameter requestParameters.arguments was null or undefined when calling plannerAddActivitiesToRoute.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/planner/add-activities-to-route`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddActivitiesToRouteArgumentsToJSON(requestParameters.arguments),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AddActivitiesToRouteResponseFromJSON(jsonValue));
    }

    /**
     * Add Activities to Route
     * Add Activities to Route
     */
    plannerAddActivitiesToRoute = async(requestParameters: PlannerAddActivitiesToRouteRequest): Promise<AddActivitiesToRouteResponse> => {
        const response = await this.plannerAddActivitiesToRouteRaw(requestParameters);
        return await response.value();
    }

    /**
     * Remove Activities From Route
     * Remove Activities From Route
     */
    async removeActivitiesFromRouteRaw(requestParameters: RemoveActivitiesFromRouteRequest): Promise<runtime.ApiResponse<ModelApiResponse>> {
        if (requestParameters.arguments === null || requestParameters.arguments === undefined) {
            throw new runtime.RequiredError('arguments','Required parameter requestParameters.arguments was null or undefined when calling removeActivitiesFromRoute.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/planner/remove-activities-from-route`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RemoveActivitiesFromRouteArgumentsToJSON(requestParameters.arguments),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelApiResponseFromJSON(jsonValue));
    }

    /**
     * Remove Activities From Route
     * Remove Activities From Route
     */
    removeActivitiesFromRoute = async(requestParameters: RemoveActivitiesFromRouteRequest): Promise<ModelApiResponse> => {
        const response = await this.removeActivitiesFromRouteRaw(requestParameters);
        return await response.value();
    }

}
