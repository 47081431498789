/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal Client Api
 * Bumbal API documentation
 *
 * The version of the OpenAPI document: 2.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel } from '../runtime';

export interface FilledCapacityModelInterface {
    capacity_type_id?: number;
    capacity_type_name?: string;
    capacity_value?: number;
    uom_id?: number;
    uom_name?: FilledCapacityModelUomNameEnum;
}

/**
 * 
 * @export
 * @interface FilledCapacityModel
 */
export class FilledCapacityModel extends BaseModel<FilledCapacityModelInterface> {

    /**
     * id for capacity type
     * @type {number}
     * @memberof FilledCapacityModel
     */
    capacity_type_id?: number;
    /**
     * name of capacity type
     * @type {string}
     * @memberof FilledCapacityModel
     */
    capacity_type_name?: string;
    /**
     * Capacity value
     * @type {number}
     * @memberof FilledCapacityModel
     */
    capacity_value?: number;
    /**
     * id of used unit of measurement for values provided in capacity_value
     * @type {number}
     * @memberof FilledCapacityModel
     */
    uom_id?: number;
    /**
     * Name of used unit of measurement for values provided in capacity_value
     * @type {string}
     * @memberof FilledCapacityModel
     */
    uom_name?: FilledCapacityModelUomNameEnum;

    constructor(data?: FilledCapacityModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(value: FilledCapacityModelInterface): { [property: string]: any } {
        return {};
    }

}

export function FilledCapacityModelFromJSON(json: any): FilledCapacityModel {
    return FilledCapacityModelFromJSONTyped(json, false);
}

export function FilledCapacityModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): FilledCapacityModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = FilledCapacityModel;
    try {
        let localClass = require.context('../override', true)('./FilledCapacityModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'capacity_type_id': !exists(json, 'capacity_type_id') ? undefined : Number(json['capacity_type_id']),
        'capacity_type_name': !exists(json, 'capacity_type_name') ? undefined : json['capacity_type_name'],
        'capacity_value': !exists(json, 'capacity_value') ? undefined : Number(json['capacity_value']),
        'uom_id': !exists(json, 'uom_id') ? undefined : Number(json['uom_id']),
        'uom_name': !exists(json, 'uom_name') ? undefined : json['uom_name'],
    });
}

export function FilledCapacityModelToJSON(value?: FilledCapacityModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = FilledCapacityModel;
    try {
        let localClass = require.context('../override', true)('./FilledCapacityModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return {
        
        'capacity_type_id': value.capacity_type_id,
        'capacity_type_name': value.capacity_type_name,
        'capacity_value': value.capacity_value,
        'uom_id': value.uom_id,
        'uom_name': value.uom_name,
        ...createClass.toJson(value),
    };
}

/**
* @export
* @enum {string}
*/
export enum FilledCapacityModelUomNameEnum {
    CM = 'CM',
    M = 'M',
    MI = 'MI',
    CM3 = 'CM3',
    M3 = 'M3',
    LI = 'LI',
    KG = 'KG',
    PD = 'PD',
    Amount = 'amount',
    MM = 'MM',
    MM3 = 'MM3',
    DM = 'DM',
    DM3 = 'DM3',
    G = 'G',
    MG = 'MG',
    CG = 'CG',
    DG = 'DG'
}


