/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal Client Api
 * Bumbal API documentation
 *
 * The version of the OpenAPI document: 2.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel } from '../runtime';
import {
    UsersFiltersModel,
    UsersFiltersModelFromJSON,
    UsersFiltersModelFromJSONTyped,
    UsersFiltersModelToJSON,
    UsersOptionsModel,
    UsersOptionsModelFromJSON,
    UsersOptionsModelFromJSONTyped,
    UsersOptionsModelToJSON,
} from './';


export interface UsersRetrieveListArgumentsInterface {
    options?: UsersOptionsModel;
    filters?: UsersFiltersModel;
    limit?: number;
    offset?: number;
    sorting_column?: UsersRetrieveListArgumentsSortingColumnEnum;
    sorting_direction?: UsersRetrieveListArgumentsSortingDirectionEnum;
    search_text?: string;
    as_list?: boolean;
}

/**
 * 
 * @export
 * @interface UsersRetrieveListArguments
 */
export class UsersRetrieveListArguments extends BaseModel<UsersRetrieveListArgumentsInterface> {

    /**
     * 
     * @type {UsersOptionsModel}
     * @memberof UsersRetrieveListArguments
     */
    options?: UsersOptionsModel;
    /**
     * 
     * @type {UsersFiltersModel}
     * @memberof UsersRetrieveListArguments
     */
    filters?: UsersFiltersModel;
    /**
     * 
     * @type {number}
     * @memberof UsersRetrieveListArguments
     */
    limit?: number;
    /**
     * 
     * @type {number}
     * @memberof UsersRetrieveListArguments
     */
    offset?: number;
    /**
     * Sorting Column
     * @type {string}
     * @memberof UsersRetrieveListArguments
     */
    sorting_column?: UsersRetrieveListArgumentsSortingColumnEnum;
    /**
     * Sorting Direction
     * @type {string}
     * @memberof UsersRetrieveListArguments
     */
    sorting_direction?: UsersRetrieveListArgumentsSortingDirectionEnum;
    /**
     * 
     * @type {string}
     * @memberof UsersRetrieveListArguments
     */
    search_text?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UsersRetrieveListArguments
     */
    as_list?: boolean;

    constructor(data?: UsersRetrieveListArgumentsInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(value: UsersRetrieveListArgumentsInterface): { [property: string]: any } {
        return {};
    }

}

export function UsersRetrieveListArgumentsFromJSON(json: any): UsersRetrieveListArguments {
    return UsersRetrieveListArgumentsFromJSONTyped(json, false);
}

export function UsersRetrieveListArgumentsFromJSONTyped(json: any, ignoreDiscriminator: boolean): UsersRetrieveListArguments {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = UsersRetrieveListArguments;
    try {
        let localClass = require.context('../override', true)('./UsersRetrieveListArguments');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'options': !exists(json, 'options') ? undefined : UsersOptionsModelFromJSON(json['options']),
        'filters': !exists(json, 'filters') ? undefined : UsersFiltersModelFromJSON(json['filters']),
        'limit': !exists(json, 'limit') ? undefined : Number(json['limit']),
        'offset': !exists(json, 'offset') ? undefined : Number(json['offset']),
        'sorting_column': !exists(json, 'sorting_column') ? undefined : json['sorting_column'],
        'sorting_direction': !exists(json, 'sorting_direction') ? undefined : json['sorting_direction'],
        'search_text': !exists(json, 'search_text') ? undefined : json['search_text'],
        'as_list': !exists(json, 'as_list') ? undefined : json['as_list'],
    });
}

export function UsersRetrieveListArgumentsToJSON(value?: UsersRetrieveListArguments | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = UsersRetrieveListArguments;
    try {
        let localClass = require.context('../override', true)('./UsersRetrieveListArguments');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return {
        
        'options': UsersOptionsModelToJSON(value.options),
        'filters': UsersFiltersModelToJSON(value.filters),
        'limit': value.limit,
        'offset': value.offset,
        'sorting_column': value.sorting_column,
        'sorting_direction': value.sorting_direction,
        'search_text': value.search_text,
        'as_list': value.as_list,
        ...createClass.toJson(value),
    };
}

/**
* @export
* @enum {string}
*/
export enum UsersRetrieveListArgumentsSortingColumnEnum {
    SearchZip = 'search_zip',
    DateTimeFrom = 'date_time_from',
    Activated = 'activated'
}
/**
* @export
* @enum {string}
*/
export enum UsersRetrieveListArgumentsSortingDirectionEnum {
    Asc = 'asc',
    Desc = 'desc'
}


