/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal Client Api
 * Bumbal API documentation
 *
 * The version of the OpenAPI document: 2.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel } from '../runtime';
import {
    QuestionnaireAnswerFiltersModel,
    QuestionnaireAnswerFiltersModelFromJSON,
    QuestionnaireAnswerFiltersModelFromJSONTyped,
    QuestionnaireAnswerFiltersModelToJSON,
    QuestionnaireAnswerOptionsModel,
    QuestionnaireAnswerOptionsModelFromJSON,
    QuestionnaireAnswerOptionsModelFromJSONTyped,
    QuestionnaireAnswerOptionsModelToJSON,
} from './';


export interface QuestionnaireAnswerRetrieveListArgumentsInterface {
    options?: QuestionnaireAnswerOptionsModel;
    filters?: QuestionnaireAnswerFiltersModel;
    limit?: number;
    offset?: number;
    sorting_column?: QuestionnaireAnswerRetrieveListArgumentsSortingColumnEnum;
    sorting_direction?: QuestionnaireAnswerRetrieveListArgumentsSortingDirectionEnum;
    search_text?: string;
}

/**
 * 
 * @export
 * @interface QuestionnaireAnswerRetrieveListArguments
 */
export class QuestionnaireAnswerRetrieveListArguments extends BaseModel<QuestionnaireAnswerRetrieveListArgumentsInterface> {

    /**
     * 
     * @type {QuestionnaireAnswerOptionsModel}
     * @memberof QuestionnaireAnswerRetrieveListArguments
     */
    options?: QuestionnaireAnswerOptionsModel;
    /**
     * 
     * @type {QuestionnaireAnswerFiltersModel}
     * @memberof QuestionnaireAnswerRetrieveListArguments
     */
    filters?: QuestionnaireAnswerFiltersModel;
    /**
     * 
     * @type {number}
     * @memberof QuestionnaireAnswerRetrieveListArguments
     */
    limit?: number;
    /**
     * 
     * @type {number}
     * @memberof QuestionnaireAnswerRetrieveListArguments
     */
    offset?: number;
    /**
     * Sorting Column
     * @type {string}
     * @memberof QuestionnaireAnswerRetrieveListArguments
     */
    sorting_column?: QuestionnaireAnswerRetrieveListArgumentsSortingColumnEnum;
    /**
     * Sorting Direction
     * @type {string}
     * @memberof QuestionnaireAnswerRetrieveListArguments
     */
    sorting_direction?: QuestionnaireAnswerRetrieveListArgumentsSortingDirectionEnum;
    /**
     * 
     * @type {string}
     * @memberof QuestionnaireAnswerRetrieveListArguments
     */
    search_text?: string;

    constructor(data?: QuestionnaireAnswerRetrieveListArgumentsInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(value: QuestionnaireAnswerRetrieveListArgumentsInterface): { [property: string]: any } {
        return {};
    }

}

export function QuestionnaireAnswerRetrieveListArgumentsFromJSON(json: any): QuestionnaireAnswerRetrieveListArguments {
    return QuestionnaireAnswerRetrieveListArgumentsFromJSONTyped(json, false);
}

export function QuestionnaireAnswerRetrieveListArgumentsFromJSONTyped(json: any, ignoreDiscriminator: boolean): QuestionnaireAnswerRetrieveListArguments {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = QuestionnaireAnswerRetrieveListArguments;
    try {
        let localClass = require.context('../override', true)('./QuestionnaireAnswerRetrieveListArguments');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'options': !exists(json, 'options') ? undefined : QuestionnaireAnswerOptionsModelFromJSON(json['options']),
        'filters': !exists(json, 'filters') ? undefined : QuestionnaireAnswerFiltersModelFromJSON(json['filters']),
        'limit': !exists(json, 'limit') ? undefined : Number(json['limit']),
        'offset': !exists(json, 'offset') ? undefined : Number(json['offset']),
        'sorting_column': !exists(json, 'sorting_column') ? undefined : json['sorting_column'],
        'sorting_direction': !exists(json, 'sorting_direction') ? undefined : json['sorting_direction'],
        'search_text': !exists(json, 'search_text') ? undefined : json['search_text'],
    });
}

export function QuestionnaireAnswerRetrieveListArgumentsToJSON(value?: QuestionnaireAnswerRetrieveListArguments | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = QuestionnaireAnswerRetrieveListArguments;
    try {
        let localClass = require.context('../override', true)('./QuestionnaireAnswerRetrieveListArguments');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return {
        
        'options': QuestionnaireAnswerOptionsModelToJSON(value.options),
        'filters': QuestionnaireAnswerFiltersModelToJSON(value.filters),
        'limit': value.limit,
        'offset': value.offset,
        'sorting_column': value.sorting_column,
        'sorting_direction': value.sorting_direction,
        'search_text': value.search_text,
        ...createClass.toJson(value),
    };
}

/**
* @export
* @enum {string}
*/
export enum QuestionnaireAnswerRetrieveListArgumentsSortingColumnEnum {
    Id = 'id',
    Name = 'name',
    Description = 'description',
    Subject = 'subject'
}
/**
* @export
* @enum {string}
*/
export enum QuestionnaireAnswerRetrieveListArgumentsSortingDirectionEnum {
    ASC = 'ASC',
    DESC = 'DESC'
}


