import { Box, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import ProgressBar from "../../progress_bar/ProgressBar";
import bbLogo from "../../../assets/small_logo_bumbal.png";
import EmojiFlagsIcon from "@mui/icons-material/EmojiFlags";
import { getAcademyMetadata } from "../../../utils/MetaDataUtil";
import { Course } from "../../../interface/CourseContent";
import SubjectOverview from "./subjects/SubjectOverview";
import { StateProps } from "../../../interface/ProgressIndicator";

interface ComponentProps {
    content: Course;
    course: string;
    activeVersion: number;
}

type CourseContentProps = StateProps & ComponentProps;

const CourseContent = ({
    content,
    course,
    activeVersion,
    userReducer,
}: CourseContentProps) => {
    const { t } = useTranslation();

    const academyMetaData = getAcademyMetadata(
        userReducer?.user?.meta_data ?? []
    
    );
    const totalProgress = academyMetaData.getTotalProgres(
        course,
        activeVersion
    );

    return (
        <Box padding="2.5% 5%" sx={{ backgroundColor: "#FBFBFB" }}>
            <Box sx={{ maxWidth: "900px", mx: "auto" }}>
                <Box textAlign="center" paddingBottom="20px">
                    <Typography variant="h4" fontWeight="bold">
                        {t("course.course")}
                    </Typography>
                    <Typography
                        variant="h6"
                        fontWeight="400"
                        sx={{ color: "#878787" }}
                    >
                        {t("course.progress_overview")}
                    </Typography>
                </Box>
                <ProgressBar
                    value={totalProgress}
                    barColor="#C4C4C4"
                    startIcon={
                        <EmojiFlagsIcon
                            htmlColor="#fff"
                            sx={{ fontSize: "1.3rem", width: "25px" }}
                        />
                    }
                    endIcon={<img src={bbLogo} width="25px" alt="" />}
                />
            </Box>
            <SubjectOverview
                version={activeVersion}
                subjects={content.versions[activeVersion].subjects}
                data={academyMetaData}
                course={course}
            />
        </Box>
    );
};

const mapStateToProps = (state) => ({
    userReducer: state.user,
});

export default connect(mapStateToProps)(CourseContent);
