/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal Client Api
 * Bumbal API documentation
 *
 * The version of the OpenAPI document: 2.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiResponse27,
    ApiResponse27FromJSON,
    ApiResponse27ToJSON,
    ApiResponse28,
    ApiResponse28FromJSON,
    ApiResponse28ToJSON,
    ApiResponse29,
    ApiResponse29FromJSON,
    ApiResponse29ToJSON,
    ApiResponse401,
    ApiResponse401FromJSON,
    ApiResponse401ToJSON,
    ApiResponse403,
    ApiResponse403FromJSON,
    ApiResponse403ToJSON,
    ApiResponse405,
    ApiResponse405FromJSON,
    ApiResponse405ToJSON,
    QuestionnaireChangeLanguageArguments,
    QuestionnaireChangeLanguageArgumentsFromJSON,
    QuestionnaireChangeLanguageArgumentsToJSON,
    QuestionnaireChangeLanguageResponse,
    QuestionnaireChangeLanguageResponseFromJSON,
    QuestionnaireChangeLanguageResponseToJSON,
    QuestionnaireGetNextQuestionArguments,
    QuestionnaireGetNextQuestionArgumentsFromJSON,
    QuestionnaireGetNextQuestionArgumentsToJSON,
    QuestionnaireGetPreviousQuestionArguments,
    QuestionnaireGetPreviousQuestionArgumentsFromJSON,
    QuestionnaireGetPreviousQuestionArgumentsToJSON,
    QuestionnaireListResponse,
    QuestionnaireListResponseFromJSON,
    QuestionnaireListResponseToJSON,
    QuestionnaireModel,
    QuestionnaireModelFromJSON,
    QuestionnaireModelToJSON,
    QuestionnaireQuestionModel,
    QuestionnaireQuestionModelFromJSON,
    QuestionnaireQuestionModelToJSON,
    QuestionnaireRetrieveListArguments,
    QuestionnaireRetrieveListArgumentsFromJSON,
    QuestionnaireRetrieveListArgumentsToJSON,
} from '../models';

export interface ChangeLanguageRequest {
    arguments: QuestionnaireChangeLanguageArguments;
}

export interface DeleteQuestionnaireRequest {
    notificationId: number;
}

export interface GetNextQuestionRequest {
    arguments: QuestionnaireGetNextQuestionArguments;
}

export interface GetPreviousQuestionRequest {
    arguments: QuestionnaireGetPreviousQuestionArguments;
}

export interface RetrieveListQuestionnaireRequest {
    arguments: QuestionnaireRetrieveListArguments;
}

export interface RetrieveQuestionnaireRequest {
    notificationId: number;
    includeAnswers: boolean;
}

/**
 * 
 */
export class QuestionnaireApi extends runtime.BaseAPI {

    /**
     * change language of a Questionnaire
     * change language of a Questionnaire
     */
    async changeLanguageRaw(requestParameters: ChangeLanguageRequest): Promise<runtime.ApiResponse<QuestionnaireChangeLanguageResponse>> {
        if (requestParameters.arguments === null || requestParameters.arguments === undefined) {
            throw new runtime.RequiredError('arguments','Required parameter requestParameters.arguments was null or undefined when calling changeLanguage.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/questionnaire/change-language`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: QuestionnaireChangeLanguageArgumentsToJSON(requestParameters.arguments),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => QuestionnaireChangeLanguageResponseFromJSON(jsonValue));
    }

    /**
     * change language of a Questionnaire
     * change language of a Questionnaire
     */
    changeLanguage = async(requestParameters: ChangeLanguageRequest): Promise<QuestionnaireChangeLanguageResponse> => {
        const response = await this.changeLanguageRaw(requestParameters);
        return await response.value();
    }

    /**
     * Delete an Questionnaire entry
     * Delete an Questionnaire entry
     */
    async deleteQuestionnaireRaw(requestParameters: DeleteQuestionnaireRequest): Promise<runtime.ApiResponse<ApiResponse27>> {
        if (requestParameters.notificationId === null || requestParameters.notificationId === undefined) {
            throw new runtime.RequiredError('notificationId','Required parameter requestParameters.notificationId was null or undefined when calling deleteQuestionnaire.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/questionnaire/{questionnaireId}`.replace(`{${"notificationId"}}`, encodeURIComponent(String(requestParameters.notificationId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResponse27FromJSON(jsonValue));
    }

    /**
     * Delete an Questionnaire entry
     * Delete an Questionnaire entry
     */
    deleteQuestionnaire = async(requestParameters: DeleteQuestionnaireRequest): Promise<ApiResponse27> => {
        const response = await this.deleteQuestionnaireRaw(requestParameters);
        return await response.value();
    }

    /**
     * getNextQuestion of an Questionnaire
     * getNextQuestion of an Questionnaire
     */
    async getNextQuestionRaw(requestParameters: GetNextQuestionRequest): Promise<runtime.ApiResponse<QuestionnaireQuestionModel>> {
        if (requestParameters.arguments === null || requestParameters.arguments === undefined) {
            throw new runtime.RequiredError('arguments','Required parameter requestParameters.arguments was null or undefined when calling getNextQuestion.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/questionnaire/get-next-question`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: QuestionnaireGetNextQuestionArgumentsToJSON(requestParameters.arguments),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => QuestionnaireQuestionModelFromJSON(jsonValue));
    }

    /**
     * getNextQuestion of an Questionnaire
     * getNextQuestion of an Questionnaire
     */
    getNextQuestion = async(requestParameters: GetNextQuestionRequest): Promise<QuestionnaireQuestionModel> => {
        const response = await this.getNextQuestionRaw(requestParameters);
        return await response.value();
    }

    /**
     * getPreviousQuestion of an Questionnaire
     * getPreviousQuestion of an Questionnaire
     */
    async getPreviousQuestionRaw(requestParameters: GetPreviousQuestionRequest): Promise<runtime.ApiResponse<QuestionnaireQuestionModel>> {
        if (requestParameters.arguments === null || requestParameters.arguments === undefined) {
            throw new runtime.RequiredError('arguments','Required parameter requestParameters.arguments was null or undefined when calling getPreviousQuestion.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/questionnaire/get-previous-question`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: QuestionnaireGetPreviousQuestionArgumentsToJSON(requestParameters.arguments),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => QuestionnaireQuestionModelFromJSON(jsonValue));
    }

    /**
     * getPreviousQuestion of an Questionnaire
     * getPreviousQuestion of an Questionnaire
     */
    getPreviousQuestion = async(requestParameters: GetPreviousQuestionRequest): Promise<QuestionnaireQuestionModel> => {
        const response = await this.getPreviousQuestionRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieve List of Questionnaire
     * Retrieve List of Questionnaire
     */
    async retrieveListQuestionnaireRaw(requestParameters: RetrieveListQuestionnaireRequest): Promise<runtime.ApiResponse<QuestionnaireListResponse>> {
        if (requestParameters.arguments === null || requestParameters.arguments === undefined) {
            throw new runtime.RequiredError('arguments','Required parameter requestParameters.arguments was null or undefined when calling retrieveListQuestionnaire.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/questionnaire`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: QuestionnaireRetrieveListArgumentsToJSON(requestParameters.arguments),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => QuestionnaireListResponseFromJSON(jsonValue));
    }

    /**
     * Retrieve List of Questionnaire
     * Retrieve List of Questionnaire
     */
    retrieveListQuestionnaire = async(requestParameters: RetrieveListQuestionnaireRequest): Promise<QuestionnaireListResponse> => {
        const response = await this.retrieveListQuestionnaireRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieve an Questionnaire
     * Retrieve a Questionnaire
     */
    async retrieveQuestionnaireRaw(requestParameters: RetrieveQuestionnaireRequest): Promise<runtime.ApiResponse<QuestionnaireModel>> {
        if (requestParameters.notificationId === null || requestParameters.notificationId === undefined) {
            throw new runtime.RequiredError('notificationId','Required parameter requestParameters.notificationId was null or undefined when calling retrieveQuestionnaire.');
        }

        if (requestParameters.includeAnswers === null || requestParameters.includeAnswers === undefined) {
            throw new runtime.RequiredError('includeAnswers','Required parameter requestParameters.includeAnswers was null or undefined when calling retrieveQuestionnaire.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/questionnaire/{questionnaireId}`.replace(`{${"notificationId"}}`, encodeURIComponent(String(requestParameters.notificationId))).replace(`{${"include_answers"}}`, encodeURIComponent(String(requestParameters.includeAnswers))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => QuestionnaireModelFromJSON(jsonValue));
    }

    /**
     * Retrieve an Questionnaire
     * Retrieve a Questionnaire
     */
    retrieveQuestionnaire = async(requestParameters: RetrieveQuestionnaireRequest): Promise<QuestionnaireModel> => {
        const response = await this.retrieveQuestionnaireRaw(requestParameters);
        return await response.value();
    }

}
