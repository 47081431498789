/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal Client Api
 * Bumbal API documentation
 *
 * The version of the OpenAPI document: 2.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel } from '../runtime';
import {
    AddressModel,
    AddressModelFromJSON,
    AddressModelFromJSONTyped,
    AddressModelToJSON,
    AppliedCapacitiesModel,
    AppliedCapacitiesModelFromJSON,
    AppliedCapacitiesModelFromJSONTyped,
    AppliedCapacitiesModelToJSON,
    CapacityModel,
    CapacityModelFromJSON,
    CapacityModelFromJSONTyped,
    CapacityModelToJSON,
    EquipmentModel,
    EquipmentModelFromJSON,
    EquipmentModelFromJSONTyped,
    EquipmentModelToJSON,
    FileModel,
    FileModelFromJSON,
    FileModelFromJSONTyped,
    FileModelToJSON,
    GPSLocationModel,
    GPSLocationModelFromJSON,
    GPSLocationModelFromJSONTyped,
    GPSLocationModelToJSON,
    LinkModel,
    LinkModelFromJSON,
    LinkModelFromJSONTyped,
    LinkModelToJSON,
    MetaDataModel,
    MetaDataModelFromJSON,
    MetaDataModelFromJSONTyped,
    MetaDataModelToJSON,
    NoteModel,
    NoteModelFromJSON,
    NoteModelFromJSONTyped,
    NoteModelToJSON,
    PauseModel,
    PauseModelFromJSON,
    PauseModelFromJSONTyped,
    PauseModelToJSON,
    RecurrenceModel,
    RecurrenceModelFromJSON,
    RecurrenceModelFromJSONTyped,
    RecurrenceModelToJSON,
    TagModel,
    TagModelFromJSON,
    TagModelFromJSONTyped,
    TagModelToJSON,
    TrailerModel,
    TrailerModelFromJSON,
    TrailerModelFromJSONTyped,
    TrailerModelToJSON,
    UsersModel,
    UsersModelFromJSON,
    UsersModelFromJSONTyped,
    UsersModelToJSON,
    VehicleModel,
    VehicleModelFromJSON,
    VehicleModelFromJSONTyped,
    VehicleModelToJSON,
    ZoneModel,
    ZoneModelFromJSON,
    ZoneModelFromJSONTyped,
    ZoneModelToJSON,
} from './';


export interface RouteModelInterface {
    id?: number;
    nr?: string;
    name?: string;
    status_name?: RouteModelStatusNameEnum;
    status_id?: RouteModelStatusIdEnum;
    nr_of_stops?: number;
    pause_id?: number;
    pause?: PauseModel;
    driver_id?: number;
    driver_link?: LinkModel;
    driver_links?: Array<LinkModel>;
    driver?: UsersModel;
    user_link?: Array<LinkModel>;
    co_driver_ids?: Array<number>;
    co_drivers?: Array<UsersModel>;
    car_id?: number;
    vehicle_id?: number;
    vehicle_link?: Array<LinkModel>;
    vehicle?: VehicleModel;
    car_link?: Array<LinkModel>;
    car?: VehicleModel;
    trailer_id?: number;
    trailer_link?: Array<LinkModel>;
    trailer?: TrailerModel;
    equipment?: Array<EquipmentModel>;
    equipment_ids?: Array<number>;
    planned_start_duration?: number;
    planned_end_duration?: number;
    max_speed?: number;
    speed_factor?: number;
    duration_factor?: number;
    cost_per_meter?: number;
    cost_per_route?: number;
    cost_per_driving_minute?: number;
    cost_per_waiting_minute?: number;
    cost_per_service_minute?: number;
    earliest_date_time?: Date;
    latest_date_time?: Date;
    planned_date_time_from?: Date;
    planned_date_time_to?: Date;
    executed_date_time_from?: Date;
    executed_date_time_to?: Date;
    planned_driving_distance?: number;
    planned_driving_duration?: number;
    planned_waiting_duration?: number;
    planned_activity_duration?: number;
    planned_total_duration?: number;
    gps_locations?: Array<GPSLocationModel>;
    latest_known_position?: GPSLocationModel;
    recurrence_id?: number;
    recurrence_nr?: number;
    recurrence?: RecurrenceModel;
    overdue?: boolean;
    optimized?: boolean;
    blocked?: boolean;
    active?: boolean;
    start_address?: AddressModel;
    end_address?: AddressModel;
    planned_capacities?: object;
    applied_capacities?: AppliedCapacitiesModel;
    capacities?: Array<CapacityModel>;
    activity_ids?: Array<number>;
    links?: Array<LinkModel>;
    meta_data?: Array<MetaDataModel>;
    notes?: Array<NoteModel>;
    files?: Array<FileModel>;
    created_at?: Date;
    updated_at?: Date;
    tags?: Array<TagModel>;
    tag_names?: Array<string>;
    zones?: Array<ZoneModel>;
    zone_names?: Array<string>;
}

/**
 * 
 * @export
 * @interface RouteModel
 */
export class RouteModel extends BaseModel<RouteModelInterface> {

    /**
     * Unique ID of Route
     * @type {number}
     * @memberof RouteModel
     */
    id?: number;
    /**
     * Non-Unique number of Route
     * @type {string}
     * @memberof RouteModel
     */
    nr?: string;
    /**
     * Description
     * @type {string}
     * @memberof RouteModel
     */
    name?: string;
    /**
     * Route Status
     * @type {string}
     * @memberof RouteModel
     */
    status_name?: RouteModelStatusNameEnum;
    /**
     * Status ID of Route, 29:route_cancelled, 1:route_planned, 2:route_in_progress, 8:route_executed
     * @type {number}
     * @memberof RouteModel
     */
    status_id?: RouteModelStatusIdEnum;
    /**
     * number of stops on this route (excluding start_route and end_route activities)
     * @type {number}
     * @memberof RouteModel
     */
    nr_of_stops?: number;
    /**
     * id of pause scheme to apply
     * @type {number}
     * @memberof RouteModel
     */
    pause_id?: number;
    /**
     * 
     * @type {PauseModel}
     * @memberof RouteModel
     */
    pause?: PauseModel;
    /**
     * id of executing driver
     * @type {number}
     * @memberof RouteModel
     */
    driver_id?: number;
    /**
     * 
     * @type {LinkModel}
     * @memberof RouteModel
     */
    driver_link?: LinkModel;
    /**
     * 
     * @type {Array<LinkModel>}
     * @memberof RouteModel
     */
    driver_links?: Array<LinkModel>;
    /**
     * 
     * @type {UsersModel}
     * @memberof RouteModel
     */
    driver?: UsersModel;
    /**
     * 
     * @type {Array<LinkModel>}
     * @memberof RouteModel
     */
    user_link?: Array<LinkModel>;
    /**
     * Unique Identifier(s) for co-drivers on route
     * @type {Array<number>}
     * @memberof RouteModel
     */
    co_driver_ids?: Array<number>;
    /**
     * list of co-drivers on route
     * @type {Array<UsersModel>}
     * @memberof RouteModel
     */
    co_drivers?: Array<UsersModel>;
    /**
     * 
     * @type {number}
     * @memberof RouteModel
     */
    car_id?: number;
    /**
     * Bumbal internal id for vehicle associated with this route
     * @type {number}
     * @memberof RouteModel
     */
    vehicle_id?: number;
    /**
     * link object to identify a vehicle uniquely by an external id and provider name
     * @type {Array<LinkModel>}
     * @memberof RouteModel
     */
    vehicle_link?: Array<LinkModel>;
    /**
     * 
     * @type {VehicleModel}
     * @memberof RouteModel
     */
    vehicle?: VehicleModel;
    /**
     * 
     * @type {Array<LinkModel>}
     * @memberof RouteModel
     */
    car_link?: Array<LinkModel>;
    /**
     * 
     * @type {VehicleModel}
     * @memberof RouteModel
     */
    car?: VehicleModel;
    /**
     * 
     * @type {number}
     * @memberof RouteModel
     */
    trailer_id?: number;
    /**
     * 
     * @type {Array<LinkModel>}
     * @memberof RouteModel
     */
    trailer_link?: Array<LinkModel>;
    /**
     * 
     * @type {TrailerModel}
     * @memberof RouteModel
     */
    trailer?: TrailerModel;
    /**
     * 
     * @type {Array<EquipmentModel>}
     * @memberof RouteModel
     */
    equipment?: Array<EquipmentModel>;
    /**
     * Unique Identifier(s) for equipment on route
     * @type {Array<number>}
     * @memberof RouteModel
     */
    equipment_ids?: Array<number>;
    /**
     * Duration for start activity
     * @type {number}
     * @memberof RouteModel
     */
    planned_start_duration?: number;
    /**
     * Duration for end activity
     * @type {number}
     * @memberof RouteModel
     */
    planned_end_duration?: number;
    /**
     * Max Speed in km/h
     * @type {number}
     * @memberof RouteModel
     */
    max_speed?: number;
    /**
     * Speed Factor
     * @type {number}
     * @memberof RouteModel
     */
    speed_factor?: number;
    /**
     * Duration Factor
     * @type {number}
     * @memberof RouteModel
     */
    duration_factor?: number;
    /**
     * Cost per meter
     * @type {number}
     * @memberof RouteModel
     */
    cost_per_meter?: number;
    /**
     * Cost per route
     * @type {number}
     * @memberof RouteModel
     */
    cost_per_route?: number;
    /**
     * Cost per driving minute
     * @type {number}
     * @memberof RouteModel
     */
    cost_per_driving_minute?: number;
    /**
     * Cost per waiting minute
     * @type {number}
     * @memberof RouteModel
     */
    cost_per_waiting_minute?: number;
    /**
     * Cost per service minute
     * @type {number}
     * @memberof RouteModel
     */
    cost_per_service_minute?: number;
    /**
     * 
     * @type {Date}
     * @memberof RouteModel
     */
    earliest_date_time?: Date;
    /**
     * 
     * @type {Date}
     * @memberof RouteModel
     */
    latest_date_time?: Date;
    /**
     * planned date-time from
     * @type {Date}
     * @memberof RouteModel
     */
    planned_date_time_from?: Date;
    /**
     * planned date-time to
     * @type {Date}
     * @memberof RouteModel
     */
    planned_date_time_to?: Date;
    /**
     * executed date-time from
     * @type {Date}
     * @memberof RouteModel
     */
    executed_date_time_from?: Date;
    /**
     * executed date-time to
     * @type {Date}
     * @memberof RouteModel
     */
    executed_date_time_to?: Date;
    /**
     * Planned driving distance of this route in meters
     * @type {number}
     * @memberof RouteModel
     */
    planned_driving_distance?: number;
    /**
     * Planned driving duration of this route in minutes
     * @type {number}
     * @memberof RouteModel
     */
    planned_driving_duration?: number;
    /**
     * Planned waiting duration of this route in minutes
     * @type {number}
     * @memberof RouteModel
     */
    planned_waiting_duration?: number;
    /**
     * Planned duration for all activities in this route in minutes
     * @type {number}
     * @memberof RouteModel
     */
    planned_activity_duration?: number;
    /**
     * Total planned duration of this route in minutes
     * @type {number}
     * @memberof RouteModel
     */
    planned_total_duration?: number;
    /**
     * 
     * @type {Array<GPSLocationModel>}
     * @memberof RouteModel
     */
    gps_locations?: Array<GPSLocationModel>;
    /**
     * 
     * @type {GPSLocationModel}
     * @memberof RouteModel
     */
    latest_known_position?: GPSLocationModel;
    /**
     * id of recurrence where route belongs to
     * @type {number}
     * @memberof RouteModel
     */
    recurrence_id?: number;
    /**
     * nr within recurrence where route belongs to
     * @type {number}
     * @memberof RouteModel
     */
    recurrence_nr?: number;
    /**
     * 
     * @type {RecurrenceModel}
     * @memberof RouteModel
     */
    recurrence?: RecurrenceModel;
    /**
     * whether any activity on route is overdue
     * @type {boolean}
     * @memberof RouteModel
     */
    overdue?: boolean;
    /**
     * Activity optimized status within route.
     * @type {boolean}
     * @memberof RouteModel
     */
    optimized?: boolean;
    /**
     * a blocked route can not be auto-filled by customer calendars
     * @type {boolean}
     * @memberof RouteModel
     */
    blocked?: boolean;
    /**
     * if active=0: route has been removed and is no longer visible in any bumbal interface
     * @type {boolean}
     * @memberof RouteModel
     */
    active?: boolean;
    /**
     * 
     * @type {AddressModel}
     * @memberof RouteModel
     */
    start_address?: AddressModel;
    /**
     * 
     * @type {AddressModel}
     * @memberof RouteModel
     */
    end_address?: AddressModel;
    /**
     * 
     * @type {object}
     * @memberof RouteModel
     */
    planned_capacities?: object;
    /**
     * 
     * @type {AppliedCapacitiesModel}
     * @memberof RouteModel
     */
    applied_capacities?: AppliedCapacitiesModel;
    /**
     * 
     * @type {Array<CapacityModel>}
     * @memberof RouteModel
     */
    capacities?: Array<CapacityModel>;
    /**
     * activity ids on route in order of execution
     * @type {Array<number>}
     * @memberof RouteModel
     */
    activity_ids?: Array<number>;
    /**
     * 
     * @type {Array<LinkModel>}
     * @memberof RouteModel
     */
    links?: Array<LinkModel>;
    /**
     * 
     * @type {Array<MetaDataModel>}
     * @memberof RouteModel
     */
    meta_data?: Array<MetaDataModel>;
    /**
     * 
     * @type {Array<NoteModel>}
     * @memberof RouteModel
     */
    notes?: Array<NoteModel>;
    /**
     * 
     * @type {Array<FileModel>}
     * @memberof RouteModel
     */
    files?: Array<FileModel>;
    /**
     * created_at date time
     * @type {Date}
     * @memberof RouteModel
     */
    created_at?: Date;
    /**
     * updated_at date time
     * @type {Date}
     * @memberof RouteModel
     */
    updated_at?: Date;
    /**
     * 
     * @type {Array<TagModel>}
     * @memberof RouteModel
     */
    tags?: Array<TagModel>;
    /**
     * Tag names
     * @type {Array<string>}
     * @memberof RouteModel
     */
    tag_names?: Array<string>;
    /**
     * 
     * @type {Array<ZoneModel>}
     * @memberof RouteModel
     */
    zones?: Array<ZoneModel>;
    /**
     * Zone names
     * @type {Array<string>}
     * @memberof RouteModel
     */
    zone_names?: Array<string>;

    constructor(data?: RouteModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(value: RouteModelInterface): { [property: string]: any } {
        return {};
    }

}

export function RouteModelFromJSON(json: any): RouteModel {
    return RouteModelFromJSONTyped(json, false);
}

export function RouteModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): RouteModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = RouteModel;
    try {
        let localClass = require.context('../override', true)('./RouteModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'id': !exists(json, 'id') ? undefined : Number(json['id']),
        'nr': !exists(json, 'nr') ? undefined : json['nr'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'status_name': !exists(json, 'status_name') ? undefined : json['status_name'],
        'status_id': !exists(json, 'status_id') ? undefined : Number(json['status_id']),
        'nr_of_stops': !exists(json, 'nr_of_stops') ? undefined : Number(json['nr_of_stops']),
        'pause_id': !exists(json, 'pause_id') ? undefined : Number(json['pause_id']),
        'pause': !exists(json, 'pause') ? undefined : PauseModelFromJSON(json['pause']),
        'driver_id': !exists(json, 'driver_id') ? undefined : Number(json['driver_id']),
        'driver_link': !exists(json, 'driver_link') ? undefined : LinkModelFromJSON(json['driver_link']),
        'driver_links': !exists(json, 'driver_links') ? undefined : ((json['driver_links'] as Array<any>).map(LinkModelFromJSON)),
        'driver': !exists(json, 'driver') ? undefined : UsersModelFromJSON(json['driver']),
        'user_link': !exists(json, 'user_link') ? undefined : ((json['user_link'] as Array<any>).map(LinkModelFromJSON)),
        'co_driver_ids': !exists(json, 'co_driver_ids') ? undefined : json['co_driver_ids'],
        'co_drivers': !exists(json, 'co_drivers') ? undefined : ((json['co_drivers'] as Array<any>).map(UsersModelFromJSON)),
        'car_id': !exists(json, 'car_id') ? undefined : Number(json['car_id']),
        'vehicle_id': !exists(json, 'vehicle_id') ? undefined : Number(json['vehicle_id']),
        'vehicle_link': !exists(json, 'vehicle_link') ? undefined : ((json['vehicle_link'] as Array<any>).map(LinkModelFromJSON)),
        'vehicle': !exists(json, 'vehicle') ? undefined : VehicleModelFromJSON(json['vehicle']),
        'car_link': !exists(json, 'car_link') ? undefined : ((json['car_link'] as Array<any>).map(LinkModelFromJSON)),
        'car': !exists(json, 'car') ? undefined : VehicleModelFromJSON(json['car']),
        'trailer_id': !exists(json, 'trailer_id') ? undefined : Number(json['trailer_id']),
        'trailer_link': !exists(json, 'trailer_link') ? undefined : ((json['trailer_link'] as Array<any>).map(LinkModelFromJSON)),
        'trailer': !exists(json, 'trailer') ? undefined : TrailerModelFromJSON(json['trailer']),
        'equipment': !exists(json, 'equipment') ? undefined : ((json['equipment'] as Array<any>).map(EquipmentModelFromJSON)),
        'equipment_ids': !exists(json, 'equipment_ids') ? undefined : json['equipment_ids'],
        'planned_start_duration': !exists(json, 'planned_start_duration') ? undefined : Number(json['planned_start_duration']),
        'planned_end_duration': !exists(json, 'planned_end_duration') ? undefined : Number(json['planned_end_duration']),
        'max_speed': !exists(json, 'max_speed') ? undefined : Number(json['max_speed']),
        'speed_factor': !exists(json, 'speed_factor') ? undefined : Number(json['speed_factor']),
        'duration_factor': !exists(json, 'duration_factor') ? undefined : Number(json['duration_factor']),
        'cost_per_meter': !exists(json, 'cost_per_meter') ? undefined : Number(json['cost_per_meter']),
        'cost_per_route': !exists(json, 'cost_per_route') ? undefined : Number(json['cost_per_route']),
        'cost_per_driving_minute': !exists(json, 'cost_per_driving_minute') ? undefined : Number(json['cost_per_driving_minute']),
        'cost_per_waiting_minute': !exists(json, 'cost_per_waiting_minute') ? undefined : Number(json['cost_per_waiting_minute']),
        'cost_per_service_minute': !exists(json, 'cost_per_service_minute') ? undefined : Number(json['cost_per_service_minute']),
        'earliest_date_time': !exists(json, 'earliest_date_time') ? undefined : (new Date(json['earliest_date_time'].replace(' ', 'T'))),
        'latest_date_time': !exists(json, 'latest_date_time') ? undefined : (new Date(json['latest_date_time'].replace(' ', 'T'))),
        'planned_date_time_from': !exists(json, 'planned_date_time_from') ? undefined : (new Date(json['planned_date_time_from'].replace(' ', 'T'))),
        'planned_date_time_to': !exists(json, 'planned_date_time_to') ? undefined : (new Date(json['planned_date_time_to'].replace(' ', 'T'))),
        'executed_date_time_from': !exists(json, 'executed_date_time_from') ? undefined : (new Date(json['executed_date_time_from'].replace(' ', 'T'))),
        'executed_date_time_to': !exists(json, 'executed_date_time_to') ? undefined : (new Date(json['executed_date_time_to'].replace(' ', 'T'))),
        'planned_driving_distance': !exists(json, 'planned_driving_distance') ? undefined : Number(json['planned_driving_distance']),
        'planned_driving_duration': !exists(json, 'planned_driving_duration') ? undefined : Number(json['planned_driving_duration']),
        'planned_waiting_duration': !exists(json, 'planned_waiting_duration') ? undefined : Number(json['planned_waiting_duration']),
        'planned_activity_duration': !exists(json, 'planned_activity_duration') ? undefined : Number(json['planned_activity_duration']),
        'planned_total_duration': !exists(json, 'planned_total_duration') ? undefined : Number(json['planned_total_duration']),
        'gps_locations': !exists(json, 'gps_locations') ? undefined : ((json['gps_locations'] as Array<any>).map(GPSLocationModelFromJSON)),
        'latest_known_position': !exists(json, 'latest_known_position') ? undefined : GPSLocationModelFromJSON(json['latest_known_position']),
        'recurrence_id': !exists(json, 'recurrence_id') ? undefined : Number(json['recurrence_id']),
        'recurrence_nr': !exists(json, 'recurrence_nr') ? undefined : Number(json['recurrence_nr']),
        'recurrence': !exists(json, 'recurrence') ? undefined : RecurrenceModelFromJSON(json['recurrence']),
        'overdue': !exists(json, 'overdue') ? undefined : json['overdue'],
        'optimized': !exists(json, 'optimized') ? undefined : json['optimized'],
        'blocked': !exists(json, 'blocked') ? undefined : json['blocked'],
        'active': !exists(json, 'active') ? undefined : json['active'],
        'start_address': !exists(json, 'start_address') ? undefined : AddressModelFromJSON(json['start_address']),
        'end_address': !exists(json, 'end_address') ? undefined : AddressModelFromJSON(json['end_address']),
        'planned_capacities': !exists(json, 'planned_capacities') ? undefined : json['planned_capacities'],
        'applied_capacities': !exists(json, 'applied_capacities') ? undefined : AppliedCapacitiesModelFromJSON(json['applied_capacities']),
        'capacities': !exists(json, 'capacities') ? undefined : ((json['capacities'] as Array<any>).map(CapacityModelFromJSON)),
        'activity_ids': !exists(json, 'activity_ids') ? undefined : json['activity_ids'],
        'links': !exists(json, 'links') ? undefined : ((json['links'] as Array<any>).map(LinkModelFromJSON)),
        'meta_data': !exists(json, 'meta_data') ? undefined : ((json['meta_data'] as Array<any>).map(MetaDataModelFromJSON)),
        'notes': !exists(json, 'notes') ? undefined : ((json['notes'] as Array<any>).map(NoteModelFromJSON)),
        'files': !exists(json, 'files') ? undefined : ((json['files'] as Array<any>).map(FileModelFromJSON)),
        'created_at': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'].replace(' ', 'T'))),
        'updated_at': !exists(json, 'updated_at') ? undefined : (new Date(json['updated_at'].replace(' ', 'T'))),
        'tags': !exists(json, 'tags') ? undefined : ((json['tags'] as Array<any>).map(TagModelFromJSON)),
        'tag_names': !exists(json, 'tag_names') ? undefined : json['tag_names'],
        'zones': !exists(json, 'zones') ? undefined : ((json['zones'] as Array<any>).map(ZoneModelFromJSON)),
        'zone_names': !exists(json, 'zone_names') ? undefined : json['zone_names'],
    });
}

export function RouteModelToJSON(value?: RouteModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = RouteModel;
    try {
        let localClass = require.context('../override', true)('./RouteModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return {
        
        'id': value.id,
        'nr': value.nr,
        'name': value.name,
        'status_name': value.status_name,
        'status_id': value.status_id,
        'nr_of_stops': value.nr_of_stops,
        'pause_id': value.pause_id,
        'pause': PauseModelToJSON(value.pause),
        'driver_id': value.driver_id,
        'driver_link': LinkModelToJSON(value.driver_link),
        'driver_links': value.driver_links === undefined ? undefined : ((value.driver_links as Array<any>).map(LinkModelToJSON)),
        'driver': UsersModelToJSON(value.driver),
        'user_link': value.user_link === undefined ? undefined : ((value.user_link as Array<any>).map(LinkModelToJSON)),
        'co_driver_ids': value.co_driver_ids,
        'co_drivers': value.co_drivers === undefined ? undefined : ((value.co_drivers as Array<any>).map(UsersModelToJSON)),
        'car_id': value.car_id,
        'vehicle_id': value.vehicle_id,
        'vehicle_link': value.vehicle_link === undefined ? undefined : ((value.vehicle_link as Array<any>).map(LinkModelToJSON)),
        'vehicle': VehicleModelToJSON(value.vehicle),
        'car_link': value.car_link === undefined ? undefined : ((value.car_link as Array<any>).map(LinkModelToJSON)),
        'car': VehicleModelToJSON(value.car),
        'trailer_id': value.trailer_id,
        'trailer_link': value.trailer_link === undefined ? undefined : ((value.trailer_link as Array<any>).map(LinkModelToJSON)),
        'trailer': TrailerModelToJSON(value.trailer),
        'equipment': value.equipment === undefined ? undefined : ((value.equipment as Array<any>).map(EquipmentModelToJSON)),
        'equipment_ids': value.equipment_ids,
        'planned_start_duration': value.planned_start_duration,
        'planned_end_duration': value.planned_end_duration,
        'max_speed': value.max_speed,
        'speed_factor': value.speed_factor,
        'duration_factor': value.duration_factor,
        'cost_per_meter': value.cost_per_meter,
        'cost_per_route': value.cost_per_route,
        'cost_per_driving_minute': value.cost_per_driving_minute,
        'cost_per_waiting_minute': value.cost_per_waiting_minute,
        'cost_per_service_minute': value.cost_per_service_minute,
        'earliest_date_time': value.earliest_date_time === undefined ? undefined : (value.earliest_date_time.toApiFormat()),
        'latest_date_time': value.latest_date_time === undefined ? undefined : (value.latest_date_time.toApiFormat()),
        'planned_date_time_from': value.planned_date_time_from === undefined ? undefined : (value.planned_date_time_from.toApiFormat()),
        'planned_date_time_to': value.planned_date_time_to === undefined ? undefined : (value.planned_date_time_to.toApiFormat()),
        'executed_date_time_from': value.executed_date_time_from === undefined ? undefined : (value.executed_date_time_from.toApiFormat()),
        'executed_date_time_to': value.executed_date_time_to === undefined ? undefined : (value.executed_date_time_to.toApiFormat()),
        'planned_driving_distance': value.planned_driving_distance,
        'planned_driving_duration': value.planned_driving_duration,
        'planned_waiting_duration': value.planned_waiting_duration,
        'planned_activity_duration': value.planned_activity_duration,
        'planned_total_duration': value.planned_total_duration,
        'gps_locations': value.gps_locations === undefined ? undefined : ((value.gps_locations as Array<any>).map(GPSLocationModelToJSON)),
        'latest_known_position': GPSLocationModelToJSON(value.latest_known_position),
        'recurrence_id': value.recurrence_id,
        'recurrence_nr': value.recurrence_nr,
        'recurrence': RecurrenceModelToJSON(value.recurrence),
        'overdue': value.overdue,
        'optimized': value.optimized,
        'blocked': value.blocked,
        'active': value.active,
        'start_address': AddressModelToJSON(value.start_address),
        'end_address': AddressModelToJSON(value.end_address),
        'planned_capacities': value.planned_capacities,
        'applied_capacities': AppliedCapacitiesModelToJSON(value.applied_capacities),
        'capacities': value.capacities === undefined ? undefined : ((value.capacities as Array<any>).map(CapacityModelToJSON)),
        'activity_ids': value.activity_ids,
        'links': value.links === undefined ? undefined : ((value.links as Array<any>).map(LinkModelToJSON)),
        'meta_data': value.meta_data === undefined ? undefined : ((value.meta_data as Array<any>).map(MetaDataModelToJSON)),
        'notes': value.notes === undefined ? undefined : ((value.notes as Array<any>).map(NoteModelToJSON)),
        'files': value.files === undefined ? undefined : ((value.files as Array<any>).map(FileModelToJSON)),
        'created_at': value.created_at === undefined ? undefined : (value.created_at.toApiFormat()),
        'updated_at': value.updated_at === undefined ? undefined : (value.updated_at.toApiFormat()),
        'tags': value.tags === undefined ? undefined : ((value.tags as Array<any>).map(TagModelToJSON)),
        'tag_names': value.tag_names,
        'zones': value.zones === undefined ? undefined : ((value.zones as Array<any>).map(ZoneModelToJSON)),
        'zone_names': value.zone_names,
        ...createClass.toJson(value),
    };
}

/**
* @export
* @enum {string}
*/
export enum RouteModelStatusNameEnum {
    Cancelled = 'route_cancelled',
    Planned = 'route_planned',
    InProgress = 'route_in_progress',
    Executed = 'route_executed'
}
/**
* @export
* @enum {string}
*/
export enum RouteModelStatusIdEnum {
    NUMBER_29 = 29,
    NUMBER_1 = 1,
    NUMBER_2 = 2,
    NUMBER_8 = 8
}


