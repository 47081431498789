/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal Client Api
 * Bumbal API documentation
 *
 * The version of the OpenAPI document: 2.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiResponse401,
    ApiResponse401FromJSON,
    ApiResponse401ToJSON,
    ApiResponse403,
    ApiResponse403FromJSON,
    ApiResponse403ToJSON,
    ApiResponse405,
    ApiResponse405FromJSON,
    ApiResponse405ToJSON,
    ModelApiResponse,
    ModelApiResponseFromJSON,
    ModelApiResponseToJSON,
    SettingsGetConstantsResponse,
    SettingsGetConstantsResponseFromJSON,
    SettingsGetConstantsResponseToJSON,
    SettingsGetConstantsReversedResponse,
    SettingsGetConstantsReversedResponseFromJSON,
    SettingsGetConstantsReversedResponseToJSON,
    SettingsModel,
    SettingsModelFromJSON,
    SettingsModelToJSON,
    SettingsRetrieveListArguments,
    SettingsRetrieveListArgumentsFromJSON,
    SettingsRetrieveListArgumentsToJSON,
} from '../models';

export interface RetrieveListSettingsRequest {
    arguments: SettingsRetrieveListArguments;
}

export interface RetrieveSettingsRequest {
    settingsId: number;
}

export interface SetSettingRequest {
    body?: SettingsModel;
}

export interface UpdateSettingsRequest {
    settingsId: number;
}

/**
 * 
 */
export class SettingsApi extends runtime.BaseAPI {

    /**
     * getConstants, returns in structure \'string\':\'string\', can contain rounding errors for floating points, use the reversed function for correct floating points
     * getConstants, returns in structure \'string\':\'string\', can contain rounding errors for floating points
     */
    async getConstantsRaw(): Promise<runtime.ApiResponse<SettingsGetConstantsResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/settings/get-constants`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SettingsGetConstantsResponseFromJSON(jsonValue));
    }

    /**
     * getConstants, returns in structure \'string\':\'string\', can contain rounding errors for floating points, use the reversed function for correct floating points
     * getConstants, returns in structure \'string\':\'string\', can contain rounding errors for floating points
     */
    getConstants = async(): Promise<SettingsGetConstantsResponse> => {
        const response = await this.getConstantsRaw();
        return await response.value();
    }

    /**
     * getConstantsReversed, returns in structure \'string\':int/float
     * getConstantsReversed, returns in structure \'string\':int/float
     */
    async getConstantsReversedRaw(): Promise<runtime.ApiResponse<SettingsGetConstantsReversedResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/settings/get-constants-reversed`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SettingsGetConstantsReversedResponseFromJSON(jsonValue));
    }

    /**
     * getConstantsReversed, returns in structure \'string\':int/float
     * getConstantsReversed, returns in structure \'string\':int/float
     */
    getConstantsReversed = async(): Promise<SettingsGetConstantsReversedResponse> => {
        const response = await this.getConstantsReversedRaw();
        return await response.value();
    }

    /**
     * Retrieve List of Settings
     * Retrieve List of Settings
     */
    async retrieveListSettingsRaw(requestParameters: RetrieveListSettingsRequest): Promise<runtime.ApiResponse<Array<SettingsModel>>> {
        if (requestParameters.arguments === null || requestParameters.arguments === undefined) {
            throw new runtime.RequiredError('arguments','Required parameter requestParameters.arguments was null or undefined when calling retrieveListSettings.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/settings`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SettingsRetrieveListArgumentsToJSON(requestParameters.arguments),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SettingsModelFromJSON));
    }

    /**
     * Retrieve List of Settings
     * Retrieve List of Settings
     */
    retrieveListSettings = async(requestParameters: RetrieveListSettingsRequest): Promise<Array<SettingsModel>> => {
        const response = await this.retrieveListSettingsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieve an Settings
     * Retrieve a Settings
     */
    async retrieveSettingsRaw(requestParameters: RetrieveSettingsRequest): Promise<runtime.ApiResponse<SettingsModel>> {
        if (requestParameters.settingsId === null || requestParameters.settingsId === undefined) {
            throw new runtime.RequiredError('settingsId','Required parameter requestParameters.settingsId was null or undefined when calling retrieveSettings.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/settings/{settingsId}`.replace(`{${"settingsId"}}`, encodeURIComponent(String(requestParameters.settingsId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SettingsModelFromJSON(jsonValue));
    }

    /**
     * Retrieve an Settings
     * Retrieve a Settings
     */
    retrieveSettings = async(requestParameters: RetrieveSettingsRequest): Promise<SettingsModel> => {
        const response = await this.retrieveSettingsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Set (update) Setting value. If no id or key are set in the data, corresponding to one of teh Bumbal settings, no update can be performed.
     * Set (update) Setting value
     */
    async setSettingRaw(requestParameters: SetSettingRequest): Promise<runtime.ApiResponse<ModelApiResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/settings/set`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SettingsModelToJSON(requestParameters.body),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelApiResponseFromJSON(jsonValue));
    }

    /**
     * Set (update) Setting value. If no id or key are set in the data, corresponding to one of teh Bumbal settings, no update can be performed.
     * Set (update) Setting value
     */
    setSetting = async(requestParameters: SetSettingRequest): Promise<ModelApiResponse> => {
        const response = await this.setSettingRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update a Setting
     * Update a Settings
     */
    async updateSettingsRaw(requestParameters: UpdateSettingsRequest): Promise<runtime.ApiResponse<ModelApiResponse>> {
        if (requestParameters.settingsId === null || requestParameters.settingsId === undefined) {
            throw new runtime.RequiredError('settingsId','Required parameter requestParameters.settingsId was null or undefined when calling updateSettings.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/settings/{settingsId}`.replace(`{${"settingsId"}}`, encodeURIComponent(String(requestParameters.settingsId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelApiResponseFromJSON(jsonValue));
    }

    /**
     * Update a Setting
     * Update a Settings
     */
    updateSettings = async(requestParameters: UpdateSettingsRequest): Promise<ModelApiResponse> => {
        const response = await this.updateSettingsRaw(requestParameters);
        return await response.value();
    }

}
