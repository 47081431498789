/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal Client Api
 * Bumbal API documentation
 *
 * The version of the OpenAPI document: 2.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel } from '../runtime';

export interface PortalSettingModelInterface {
    id?: number;
    name?: string;
    value?: string;
    portal_setting_group_name?: string;
    portal_name?: string;
}

/**
 * 
 * @export
 * @interface PortalSettingModel
 */
export class PortalSettingModel extends BaseModel<PortalSettingModelInterface> {

    /**
     * Unique Identifier
     * @type {number}
     * @memberof PortalSettingModel
     */
    id?: number;
    /**
     * Name of portal setting
     * @type {string}
     * @memberof PortalSettingModel
     */
    name?: string;
    /**
     * Value of portal setting
     * @type {string}
     * @memberof PortalSettingModel
     */
    value?: string;
    /**
     * Name of portal settings group
     * @type {string}
     * @memberof PortalSettingModel
     */
    portal_setting_group_name?: string;
    /**
     * Name of portal
     * @type {string}
     * @memberof PortalSettingModel
     */
    portal_name?: string;

    constructor(data?: PortalSettingModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(value: PortalSettingModelInterface): { [property: string]: any } {
        return {};
    }

}

export function PortalSettingModelFromJSON(json: any): PortalSettingModel {
    return PortalSettingModelFromJSONTyped(json, false);
}

export function PortalSettingModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): PortalSettingModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = PortalSettingModel;
    try {
        let localClass = require.context('../override', true)('./PortalSettingModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'id': !exists(json, 'id') ? undefined : Number(json['id']),
        'name': !exists(json, 'name') ? undefined : json['name'],
        'value': !exists(json, 'value') ? undefined : json['value'],
        'portal_setting_group_name': !exists(json, 'portal_setting_group_name') ? undefined : json['portal_setting_group_name'],
        'portal_name': !exists(json, 'portal_name') ? undefined : json['portal_name'],
    });
}

export function PortalSettingModelToJSON(value?: PortalSettingModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = PortalSettingModel;
    try {
        let localClass = require.context('../override', true)('./PortalSettingModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return {
        
        'id': value.id,
        'name': value.name,
        'value': value.value,
        'portal_setting_group_name': value.portal_setting_group_name,
        'portal_name': value.portal_name,
        ...createClass.toJson(value),
    };
}


