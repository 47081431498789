import React from "react";
import { Box, Typography } from "@mui/material";
import Group_2 from "../../../assets/Group_2.png";
import { useTranslation } from "react-i18next";
import ProgressIndicator from "./ProgressIndicator";

const Banner = () => {
    const { t } = useTranslation();

    return (
        <Box
            style={{
                display: "flex",
                height: "60%",
                width: "100%",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "flex-end",
                backgroundImage: `url(${Group_2})`,
                backgroundSize: "cover",
                backgroundPosition: "center"

            }}
        >
            <Box
                style={{
                    display: "flex",
                    width: "100%",
                    height: "70%",
                    alignItems: "center",
                    flexDirection: "column",
                    justifyContent: "center"

                }}
            >
                <Box
                    style={{
                        display: "flex",
                        width: "100%",
                        height: "40%",
                        alignItems: "center",
                        flexDirection: "column",
                    }}
                >
                    <Box
                        style={{
                            display: "flex",
                            width: "100%",
                            alignItems: "center",
                            flexDirection: "column",
                        }}
                    >
                        <Typography variant="h4" color="primary.main">
                            {t("home.banner.title")}
                        </Typography>
                    </Box>
                    <Box
                        style={{
                            display: "flex",
                            width: "100%",
                            height: "50%",
                            alignItems: "center",
                            flexDirection: "column",
                        }}
                    >
                        <Typography
                            variant="h2"
                            fontWeight="bold"
                            color="secondary.contrastText"
                        >
                            Bumbal Academy
                        </Typography>
                    </Box>
                </Box>
                <Box
                    style={{
                        display: "flex",
                        width: "95%",
                        height: "50%",
                        justifyContent: "flex-end",
                        alignItems: "flex-end",
                        flexDirection: "row"
                    }}
                >
                    <Box
                        style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center",
                            width: "35%",
                            height: "100%",

                        }}
                        >
                        <ProgressIndicator/>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default Banner;