/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal Client Api
 * Bumbal API documentation
 *
 * The version of the OpenAPI document: 2.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel } from '../runtime';
import {
    LanguageFiltersModel,
    LanguageFiltersModelFromJSON,
    LanguageFiltersModelFromJSONTyped,
    LanguageFiltersModelToJSON,
} from './';


export interface LanguageRetrieveListArgumentsInterface {
    options?: object;
    filters?: LanguageFiltersModel;
    limit?: number;
    offset?: number;
    sorting_column?: LanguageRetrieveListArgumentsSortingColumnEnum;
    sorting_direction?: LanguageRetrieveListArgumentsSortingDirectionEnum;
    search_text?: string;
}

/**
 * 
 * @export
 * @interface LanguageRetrieveListArguments
 */
export class LanguageRetrieveListArguments extends BaseModel<LanguageRetrieveListArgumentsInterface> {

    /**
     * 
     * @type {object}
     * @memberof LanguageRetrieveListArguments
     */
    options?: object;
    /**
     * 
     * @type {LanguageFiltersModel}
     * @memberof LanguageRetrieveListArguments
     */
    filters?: LanguageFiltersModel;
    /**
     * 
     * @type {number}
     * @memberof LanguageRetrieveListArguments
     */
    limit?: number;
    /**
     * 
     * @type {number}
     * @memberof LanguageRetrieveListArguments
     */
    offset?: number;
    /**
     * Sorting Column
     * @type {string}
     * @memberof LanguageRetrieveListArguments
     */
    sorting_column?: LanguageRetrieveListArgumentsSortingColumnEnum;
    /**
     * Sorting Direction
     * @type {string}
     * @memberof LanguageRetrieveListArguments
     */
    sorting_direction?: LanguageRetrieveListArgumentsSortingDirectionEnum;
    /**
     * 
     * @type {string}
     * @memberof LanguageRetrieveListArguments
     */
    search_text?: string;

    constructor(data?: LanguageRetrieveListArgumentsInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(value: LanguageRetrieveListArgumentsInterface): { [property: string]: any } {
        return {};
    }

}

export function LanguageRetrieveListArgumentsFromJSON(json: any): LanguageRetrieveListArguments {
    return LanguageRetrieveListArgumentsFromJSONTyped(json, false);
}

export function LanguageRetrieveListArgumentsFromJSONTyped(json: any, ignoreDiscriminator: boolean): LanguageRetrieveListArguments {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = LanguageRetrieveListArguments;
    try {
        let localClass = require.context('../override', true)('./LanguageRetrieveListArguments');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'options': !exists(json, 'options') ? undefined : json['options'],
        'filters': !exists(json, 'filters') ? undefined : LanguageFiltersModelFromJSON(json['filters']),
        'limit': !exists(json, 'limit') ? undefined : Number(json['limit']),
        'offset': !exists(json, 'offset') ? undefined : Number(json['offset']),
        'sorting_column': !exists(json, 'sorting_column') ? undefined : json['sorting_column'],
        'sorting_direction': !exists(json, 'sorting_direction') ? undefined : json['sorting_direction'],
        'search_text': !exists(json, 'search_text') ? undefined : json['search_text'],
    });
}

export function LanguageRetrieveListArgumentsToJSON(value?: LanguageRetrieveListArguments | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = LanguageRetrieveListArguments;
    try {
        let localClass = require.context('../override', true)('./LanguageRetrieveListArguments');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return {
        
        'options': value.options,
        'filters': LanguageFiltersModelToJSON(value.filters),
        'limit': value.limit,
        'offset': value.offset,
        'sorting_column': value.sorting_column,
        'sorting_direction': value.sorting_direction,
        'search_text': value.search_text,
        ...createClass.toJson(value),
    };
}

/**
* @export
* @enum {string}
*/
export enum LanguageRetrieveListArgumentsSortingColumnEnum {
    Id = 'id',
    Name = 'name',
    Description = 'description',
    Subject = 'subject'
}
/**
* @export
* @enum {string}
*/
export enum LanguageRetrieveListArgumentsSortingDirectionEnum {
    ASC = 'ASC',
    DESC = 'DESC'
}


