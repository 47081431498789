import ActionTypes from "../../settings/ActionTypes";
import { AppActionTypes, BaseReducerAction, ReducerError } from "./types";
import EnvUtil from "../../utils/EnvUtil";

export function apiCallFailure(error?: any): AppActionTypes {
    return {
        type: ActionTypes.API_CALL_FAILURE,
        error: error
    }
}

export default class AppActions {

    static throw404error() {
        return {
            type: ActionTypes.API_404_ERROR
        }
    }

    static createError(type: ActionTypes, error: Error): ReducerError {
        if (EnvUtil.isDevelopment()) {
            console.error(error)
        }
        return {
            type: type,
            error: error
        }
    }

    static genericAction(type: ActionTypes): BaseReducerAction {
        return {
            type: type
        }
    }

}