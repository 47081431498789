
export default class LocalStorageManager {

    getToken = (): string | undefined => {
        return this.getItem('token');
    }

    getEnvironment = (): string | undefined => {
        return this.getItem('environment');
    }

    isLoggedIn = (): boolean => {
        return this.getItem('isLoggedIn') === 'true';
    }

    getId = (): string | undefined => {
        return this.getItem('id');
    }

    setLogin = (token?: string): LocalStorageManager => {
        if (token != null) {
            return this.setItem('token', token).setItem('isLoggedIn', 'true');
        } else {
            return this.clearLogin();
        }
    }

    setEnvironment = (environment?: string): LocalStorageManager => {
        if (environment != null) {
            return this.setItem('environment', environment);
        } else {
            return this.clearEnvironment();
        }
    }

    setId = (id?: number): LocalStorageManager => {
        if (id != null) {
            return this.setItem('id', String(id))
        } else {
            return this.clearId();
        }
    }

    clearLogin = (): LocalStorageManager  => {
        return this.setItem('isLoggedIn').setItem('token');
    }

    clearEnvironment = (): LocalStorageManager  => {
        return this.setItem('environment');
    }

    clearId = (): LocalStorageManager => {
        return this.setItem('id');
    }

    private getItem = (key: string): string | undefined => {
        return localStorage.getItem(key) ?? undefined;  // this makes more sense for ts
    }

    private setItem = (key: string, value?: string): LocalStorageManager => {
        if (value != null) {
            localStorage.setItem(key, value);
        } else {
            localStorage.removeItem(key);
        }
        return this;
    }

}