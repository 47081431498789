import { defaultAcademyMetadata } from './../../models/DefaultProgress';
import ActionTypes from "../../settings/ActionTypes";
import { UserActionTypes, UserState } from "./types";
import MetaData from '../../api/override/MetaDataModel';
import isBoolean from 'lodash.isboolean';
import isArray from 'lodash.isarray';
import every from 'lodash.every';
import mergeWith from 'lodash.mergewith';

const initialState: UserState = {
    error: undefined,
    user: undefined
}

const mergeAcademyData = (oldVal, newVal) => {
    const areArrays = isArray(oldVal) && isArray(newVal);
    if (areArrays) {
        const areEmpty = oldVal.length === 0 && newVal.length === 0;
        const areBooleanArrays = every(oldVal, isBoolean) && every(newVal, isBoolean);

        if (!areEmpty && areBooleanArrays) {
            if (oldVal.length < newVal.length) {
                newVal.splice(oldVal.length - 1);
            }
        }
    }
}

export function UserReducers(state = initialState, action: UserActionTypes): UserState {
    switch (action.type) {
        case ActionTypes.API_USER_RETRIEVE:
            return {
                ...state,
                error: undefined
            }
        case ActionTypes.API_USER_RETRIEVE_SUCCESS:
            const user = action.response.items?.[0]
            if (user !== undefined) {
                user.meta_data = user?.meta_data !== undefined ? user?.meta_data : [];
                const index = user.meta_data.findIndex((item) => item.name === 'bumbal_academy');
                const defaultData = new MetaData(defaultAcademyMetadata);
                if (index === -1) {
                    user.meta_data.push(defaultData);
                } else {
                    const academyData = user.meta_data[index] as MetaData;
                    academyData.value_object = mergeWith(defaultData.value_object, academyData.value_object, mergeAcademyData);
                }
            }

            return {
                ...state,
                user: action.response.items?.[0]
            }
        case ActionTypes.API_USER_RETRIEVE_ERROR:
            return {
                ...state,
                error: action.error
            }
        case ActionTypes.API_USER_UPDATE:
        case ActionTypes.API_USER_UPDATE_SUCCESS:
            return {
                ...state,
                error: undefined
            }
        case ActionTypes.META_DATA_UPDATE:
            const academyMetaData = action.params.academyMetaData;
            const index = state.user?.meta_data?.findIndex((item) => item.name === 'bumbal_academy');

            return {
                ...state,
                user: {
                    ...state.user,
                    meta_data: [
                        ...state.user?.meta_data?.slice(0, index) ?? [],
                        academyMetaData
                    ]
                }
            }
 
        default:
            return state;
    }
}