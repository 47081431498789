/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal Client Api
 * Bumbal API documentation
 *
 * The version of the OpenAPI document: 2.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel } from '../runtime';
import {
    CapacityModel,
    CapacityModelFromJSON,
    CapacityModelFromJSONTyped,
    CapacityModelToJSON,
    FileModel,
    FileModelFromJSON,
    FileModelFromJSONTyped,
    FileModelToJSON,
    LinkModel,
    LinkModelFromJSON,
    LinkModelFromJSONTyped,
    LinkModelToJSON,
    MetaDataModel,
    MetaDataModelFromJSON,
    MetaDataModelFromJSONTyped,
    MetaDataModelToJSON,
    TagModel,
    TagModelFromJSON,
    TagModelFromJSONTyped,
    TagModelToJSON,
} from './';


export interface VehicleModelInterface {
    id?: number;
    vehicle_type_id?: VehicleModelVehicleTypeIdEnum;
    vehicle_type_name?: VehicleModelVehicleTypeNameEnum;
    name?: string;
    registration_nr?: string;
    max_speed?: number;
    speed_factor?: number;
    start_duration?: number;
    end_duration?: number;
    duration_factor?: number;
    cost_per_meter?: number;
    cost_per_route?: number;
    cost_per_driving_minute?: number;
    cost_per_waiting_minute?: number;
    cost_per_service_minute?: number;
    applied_capacities?: object;
    capacities?: Array<CapacityModel>;
    tags?: Array<TagModel>;
    meta_data?: Array<MetaDataModel>;
    links?: Array<LinkModel>;
    files?: Array<FileModel>;
    created_at?: Date;
    updated_at?: Date;
    updated_by_name?: string;
}

/**
 * 
 * @export
 * @interface VehicleModel
 */
export class VehicleModel extends BaseModel<VehicleModelInterface> {

    /**
     * Unique Identifier
     * @type {number}
     * @memberof VehicleModel
     */
    id?: number;
    /**
     * Bumbal id for vehicle_type
     * @type {number}
     * @memberof VehicleModel
     */
    vehicle_type_id?: VehicleModelVehicleTypeIdEnum;
    /**
     * Bumbal id for vehicle_type
     * @type {string}
     * @memberof VehicleModel
     */
    vehicle_type_name?: VehicleModelVehicleTypeNameEnum;
    /**
     * name
     * @type {string}
     * @memberof VehicleModel
     */
    name?: string;
    /**
     * registration_nr
     * @type {string}
     * @memberof VehicleModel
     */
    registration_nr?: string;
    /**
     * Max Speed in km/h
     * @type {number}
     * @memberof VehicleModel
     */
    max_speed?: number;
    /**
     * Speed Factor
     * @type {number}
     * @memberof VehicleModel
     */
    speed_factor?: number;
    /**
     * Start duration of using this vehicle in minutes
     * @type {number}
     * @memberof VehicleModel
     */
    start_duration?: number;
    /**
     * End duration of using this vehicle in minutes
     * @type {number}
     * @memberof VehicleModel
     */
    end_duration?: number;
    /**
     * Duration Factor
     * @type {number}
     * @memberof VehicleModel
     */
    duration_factor?: number;
    /**
     * Cost per meter
     * @type {number}
     * @memberof VehicleModel
     */
    cost_per_meter?: number;
    /**
     * Cost per route
     * @type {number}
     * @memberof VehicleModel
     */
    cost_per_route?: number;
    /**
     * Cost per driving minute
     * @type {number}
     * @memberof VehicleModel
     */
    cost_per_driving_minute?: number;
    /**
     * Cost per waiting minute
     * @type {number}
     * @memberof VehicleModel
     */
    cost_per_waiting_minute?: number;
    /**
     * Cost per service minute
     * @type {number}
     * @memberof VehicleModel
     */
    cost_per_service_minute?: number;
    /**
     * 
     * @type {object}
     * @memberof VehicleModel
     */
    applied_capacities?: object;
    /**
     * 
     * @type {Array<CapacityModel>}
     * @memberof VehicleModel
     */
    capacities?: Array<CapacityModel>;
    /**
     * 
     * @type {Array<TagModel>}
     * @memberof VehicleModel
     */
    tags?: Array<TagModel>;
    /**
     * 
     * @type {Array<MetaDataModel>}
     * @memberof VehicleModel
     */
    meta_data?: Array<MetaDataModel>;
    /**
     * 
     * @type {Array<LinkModel>}
     * @memberof VehicleModel
     */
    links?: Array<LinkModel>;
    /**
     * 
     * @type {Array<FileModel>}
     * @memberof VehicleModel
     */
    files?: Array<FileModel>;
    /**
     * created_at date time
     * @type {Date}
     * @memberof VehicleModel
     */
    created_at?: Date;
    /**
     * updated_at date time
     * @type {Date}
     * @memberof VehicleModel
     */
    updated_at?: Date;
    /**
     * Vehicle updated by user full name
     * @type {string}
     * @memberof VehicleModel
     */
    updated_by_name?: string;

    constructor(data?: VehicleModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(value: VehicleModelInterface): { [property: string]: any } {
        return {};
    }

}

export function VehicleModelFromJSON(json: any): VehicleModel {
    return VehicleModelFromJSONTyped(json, false);
}

export function VehicleModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): VehicleModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = VehicleModel;
    try {
        let localClass = require.context('../override', true)('./VehicleModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'id': !exists(json, 'id') ? undefined : Number(json['id']),
        'vehicle_type_id': !exists(json, 'vehicle_type_id') ? undefined : Number(json['vehicle_type_id']),
        'vehicle_type_name': !exists(json, 'vehicle_type_name') ? undefined : json['vehicle_type_name'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'registration_nr': !exists(json, 'registration_nr') ? undefined : json['registration_nr'],
        'max_speed': !exists(json, 'max_speed') ? undefined : Number(json['max_speed']),
        'speed_factor': !exists(json, 'speed_factor') ? undefined : Number(json['speed_factor']),
        'start_duration': !exists(json, 'start_duration') ? undefined : Number(json['start_duration']),
        'end_duration': !exists(json, 'end_duration') ? undefined : Number(json['end_duration']),
        'duration_factor': !exists(json, 'duration_factor') ? undefined : Number(json['duration_factor']),
        'cost_per_meter': !exists(json, 'cost_per_meter') ? undefined : Number(json['cost_per_meter']),
        'cost_per_route': !exists(json, 'cost_per_route') ? undefined : Number(json['cost_per_route']),
        'cost_per_driving_minute': !exists(json, 'cost_per_driving_minute') ? undefined : Number(json['cost_per_driving_minute']),
        'cost_per_waiting_minute': !exists(json, 'cost_per_waiting_minute') ? undefined : Number(json['cost_per_waiting_minute']),
        'cost_per_service_minute': !exists(json, 'cost_per_service_minute') ? undefined : Number(json['cost_per_service_minute']),
        'applied_capacities': !exists(json, 'applied_capacities') ? undefined : json['applied_capacities'],
        'capacities': !exists(json, 'capacities') ? undefined : ((json['capacities'] as Array<any>).map(CapacityModelFromJSON)),
        'tags': !exists(json, 'tags') ? undefined : ((json['tags'] as Array<any>).map(TagModelFromJSON)),
        'meta_data': !exists(json, 'meta_data') ? undefined : ((json['meta_data'] as Array<any>).map(MetaDataModelFromJSON)),
        'links': !exists(json, 'links') ? undefined : ((json['links'] as Array<any>).map(LinkModelFromJSON)),
        'files': !exists(json, 'files') ? undefined : ((json['files'] as Array<any>).map(FileModelFromJSON)),
        'created_at': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'].replace(' ', 'T'))),
        'updated_at': !exists(json, 'updated_at') ? undefined : (new Date(json['updated_at'].replace(' ', 'T'))),
        'updated_by_name': !exists(json, 'updated_by_name') ? undefined : json['updated_by_name'],
    });
}

export function VehicleModelToJSON(value?: VehicleModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = VehicleModel;
    try {
        let localClass = require.context('../override', true)('./VehicleModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return {
        
        'id': value.id,
        'vehicle_type_id': value.vehicle_type_id,
        'vehicle_type_name': value.vehicle_type_name,
        'name': value.name,
        'registration_nr': value.registration_nr,
        'max_speed': value.max_speed,
        'speed_factor': value.speed_factor,
        'start_duration': value.start_duration,
        'end_duration': value.end_duration,
        'duration_factor': value.duration_factor,
        'cost_per_meter': value.cost_per_meter,
        'cost_per_route': value.cost_per_route,
        'cost_per_driving_minute': value.cost_per_driving_minute,
        'cost_per_waiting_minute': value.cost_per_waiting_minute,
        'cost_per_service_minute': value.cost_per_service_minute,
        'applied_capacities': value.applied_capacities,
        'capacities': value.capacities === undefined ? undefined : ((value.capacities as Array<any>).map(CapacityModelToJSON)),
        'tags': value.tags === undefined ? undefined : ((value.tags as Array<any>).map(TagModelToJSON)),
        'meta_data': value.meta_data === undefined ? undefined : ((value.meta_data as Array<any>).map(MetaDataModelToJSON)),
        'links': value.links === undefined ? undefined : ((value.links as Array<any>).map(LinkModelToJSON)),
        'files': value.files === undefined ? undefined : ((value.files as Array<any>).map(FileModelToJSON)),
        'created_at': value.created_at === undefined ? undefined : (value.created_at.toApiFormat()),
        'updated_at': value.updated_at === undefined ? undefined : (value.updated_at.toApiFormat()),
        'updated_by_name': value.updated_by_name,
        ...createClass.toJson(value),
    };
}

/**
* @export
* @enum {string}
*/
export enum VehicleModelVehicleTypeIdEnum {
    NUMBER_1 = 1,
    NUMBER_2 = 2,
    NUMBER_3 = 3,
    NUMBER_4 = 4
}
/**
* @export
* @enum {string}
*/
export enum VehicleModelVehicleTypeNameEnum {
    Car = 'car',
    SmallTruck = 'small_truck',
    Truck = 'truck',
    Bike = 'bike'
}


