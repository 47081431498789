import { MetaDataModel, MetaDataModelInterface } from '../models';

interface MetaDataOverrideInterface extends MetaDataModelInterface {
    value_object: object
}

export default class MetaData extends MetaDataModel {
    value_object: object

    constructor(data?: MetaDataModelInterface) {
        super(data);
        try {
            this.value_object = JSON.parse(data?.value ?? 'null');
        } catch {
            this.value_object = {}
        }
    }

    getSubjectProgress = (course: string, version: number, subject: number): number => {
        const chapterCompletions = this.value_object[course][version][subject];
        return Math.round((chapterCompletions.length > 0 ? (chapterCompletions.filter((v) => v).length / chapterCompletions.length) * 100 : 0));
    }

    getCurrentChapter = (course: string, version: number, subject: number): number => {
        const chapterCompletions = this.value_object[course][version][subject];
        const chapterIndex = chapterCompletions.indexOf(false);
        return chapterIndex > 0 ? chapterIndex : 0;
    }

    getTotalProgres = (course: string, version: number): number => {
        const subjectProgressions = this.value_object[course][version];

        let totalChapterCount = 0;

        const completedChapterCount = subjectProgressions.reduce((total: number, chapterProgressions: boolean[]) => {
            totalChapterCount += chapterProgressions.length;
            return total + chapterProgressions.filter((chapter: boolean) => chapter).length
        }, 0);

        return Math.round((completedChapterCount / totalChapterCount) * 100);
    }

    static toJson(value: MetaDataOverrideInterface): { [property: string]: any } {
        return {
            value: JSON.stringify(value.value_object)
        };
    }
}