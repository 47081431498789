/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal Client Api
 * Bumbal API documentation
 *
 * The version of the OpenAPI document: 2.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AddressListResponse,
    AddressListResponseFromJSON,
    AddressListResponseToJSON,
    AddressModel,
    AddressModelFromJSON,
    AddressModelToJSON,
    AddressRetrieveListArguments,
    AddressRetrieveListArgumentsFromJSON,
    AddressRetrieveListArgumentsToJSON,
    AddressReverseGeoCodeArguments,
    AddressReverseGeoCodeArgumentsFromJSON,
    AddressReverseGeoCodeArgumentsToJSON,
    AddressReverseGeoCodeResponse,
    AddressReverseGeoCodeResponseFromJSON,
    AddressReverseGeoCodeResponseToJSON,
    AddressValidationResponse,
    AddressValidationResponseFromJSON,
    AddressValidationResponseToJSON,
    ModelApiResponse,
    ModelApiResponseFromJSON,
    ModelApiResponseToJSON,
} from '../models';

export interface DeleteAddressRequest {
    addressId: number;
}

export interface RetrieveAddressRequest {
    addressId: number;
}

export interface RetrieveListAddressRequest {
    arguments: AddressRetrieveListArguments;
}

export interface ReverseGeoCodeAddressRequest {
    arguments: AddressReverseGeoCodeArguments;
}

export interface SetAddressRequest {
    body?: AddressModel;
}

export interface UpdateAddressRequest {
    addressId: number;
    body?: AddressModel;
}

export interface ValidateAddressRequest {
    city: string;
    isoCountry: string;
    street?: string;
    houseNr?: string;
    houseNrAddendum?: string;
    zipcode?: string;
    minimumCertainty?: number;
}

/**
 * 
 */
export class AddressApi extends runtime.BaseAPI {

    /**
     * Delete an address
     * Delete an address
     */
    async deleteAddressRaw(requestParameters: DeleteAddressRequest): Promise<runtime.ApiResponse<ModelApiResponse>> {
        if (requestParameters.addressId === null || requestParameters.addressId === undefined) {
            throw new runtime.RequiredError('addressId','Required parameter requestParameters.addressId was null or undefined when calling deleteAddress.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/address/{addressId}`.replace(`{${"addressId"}}`, encodeURIComponent(String(requestParameters.addressId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelApiResponseFromJSON(jsonValue));
    }

    /**
     * Delete an address
     * Delete an address
     */
    deleteAddress = async(requestParameters: DeleteAddressRequest): Promise<ModelApiResponse> => {
        const response = await this.deleteAddressRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieve an Address
     * Retrieve a Address
     */
    async retrieveAddressRaw(requestParameters: RetrieveAddressRequest): Promise<runtime.ApiResponse<AddressModel>> {
        if (requestParameters.addressId === null || requestParameters.addressId === undefined) {
            throw new runtime.RequiredError('addressId','Required parameter requestParameters.addressId was null or undefined when calling retrieveAddress.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/address/{addressId}`.replace(`{${"addressId"}}`, encodeURIComponent(String(requestParameters.addressId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AddressModelFromJSON(jsonValue));
    }

    /**
     * Retrieve an Address
     * Retrieve a Address
     */
    retrieveAddress = async(requestParameters: RetrieveAddressRequest): Promise<AddressModel> => {
        const response = await this.retrieveAddressRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieve List of Addresses
     * Retrieve List of Addresses
     */
    async retrieveListAddressRaw(requestParameters: RetrieveListAddressRequest): Promise<runtime.ApiResponse<AddressListResponse>> {
        if (requestParameters.arguments === null || requestParameters.arguments === undefined) {
            throw new runtime.RequiredError('arguments','Required parameter requestParameters.arguments was null or undefined when calling retrieveListAddress.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/address`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AddressRetrieveListArgumentsToJSON(requestParameters.arguments),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AddressListResponseFromJSON(jsonValue));
    }

    /**
     * Retrieve List of Addresses
     * Retrieve List of Addresses
     */
    retrieveListAddress = async(requestParameters: RetrieveListAddressRequest): Promise<AddressListResponse> => {
        const response = await this.retrieveListAddressRaw(requestParameters);
        return await response.value();
    }

    /**
     * Reverse Geo Code an address
     * Reverse Geo Code an address
     */
    async reverseGeoCodeAddressRaw(requestParameters: ReverseGeoCodeAddressRequest): Promise<runtime.ApiResponse<AddressReverseGeoCodeResponse>> {
        if (requestParameters.arguments === null || requestParameters.arguments === undefined) {
            throw new runtime.RequiredError('arguments','Required parameter requestParameters.arguments was null or undefined when calling reverseGeoCodeAddress.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/address/reverse-geo-code`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddressReverseGeoCodeArgumentsToJSON(requestParameters.arguments),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AddressReverseGeoCodeResponseFromJSON(jsonValue));
    }

    /**
     * Reverse Geo Code an address
     * Reverse Geo Code an address
     */
    reverseGeoCodeAddress = async(requestParameters: ReverseGeoCodeAddressRequest): Promise<AddressReverseGeoCodeResponse> => {
        const response = await this.reverseGeoCodeAddressRaw(requestParameters);
        return await response.value();
    }

    /**
     * Add a new Address
     * Add a new Address
     */
    async setAddressRaw(requestParameters: SetAddressRequest): Promise<runtime.ApiResponse<ModelApiResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/address/set`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddressModelToJSON(requestParameters.body),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelApiResponseFromJSON(jsonValue));
    }

    /**
     * Add a new Address
     * Add a new Address
     */
    setAddress = async(requestParameters: SetAddressRequest): Promise<ModelApiResponse> => {
        const response = await this.setAddressRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update a address
     * Update a address
     */
    async updateAddressRaw(requestParameters: UpdateAddressRequest): Promise<runtime.ApiResponse<ModelApiResponse>> {
        if (requestParameters.addressId === null || requestParameters.addressId === undefined) {
            throw new runtime.RequiredError('addressId','Required parameter requestParameters.addressId was null or undefined when calling updateAddress.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/address/{addressId}`.replace(`{${"addressId"}}`, encodeURIComponent(String(requestParameters.addressId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AddressModelToJSON(requestParameters.body),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelApiResponseFromJSON(jsonValue));
    }

    /**
     * Update a address
     * Update a address
     */
    updateAddress = async(requestParameters: UpdateAddressRequest): Promise<ModelApiResponse> => {
        const response = await this.updateAddressRaw(requestParameters);
        return await response.value();
    }

    /**
     * Validate an address
     * Validate an address
     */
    async validateAddressRaw(requestParameters: ValidateAddressRequest): Promise<runtime.ApiResponse<AddressValidationResponse>> {
        if (requestParameters.city === null || requestParameters.city === undefined) {
            throw new runtime.RequiredError('city','Required parameter requestParameters.city was null or undefined when calling validateAddress.');
        }

        if (requestParameters.isoCountry === null || requestParameters.isoCountry === undefined) {
            throw new runtime.RequiredError('isoCountry','Required parameter requestParameters.isoCountry was null or undefined when calling validateAddress.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.street !== undefined) {
            queryParameters['street'] = requestParameters.street;
        }

        if (requestParameters.houseNr !== undefined) {
            queryParameters['house_nr'] = requestParameters.houseNr;
        }

        if (requestParameters.houseNrAddendum !== undefined) {
            queryParameters['house_nr_addendum'] = requestParameters.houseNrAddendum;
        }

        if (requestParameters.zipcode !== undefined) {
            queryParameters['zipcode'] = requestParameters.zipcode;
        }

        if (requestParameters.city !== undefined) {
            queryParameters['city'] = requestParameters.city;
        }

        if (requestParameters.isoCountry !== undefined) {
            queryParameters['iso_country'] = requestParameters.isoCountry;
        }

        if (requestParameters.minimumCertainty !== undefined) {
            queryParameters['minimum_certainty'] = requestParameters.minimumCertainty;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/address/validate`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AddressValidationResponseFromJSON(jsonValue));
    }

    /**
     * Validate an address
     * Validate an address
     */
    validateAddress = async(requestParameters: ValidateAddressRequest): Promise<AddressValidationResponse> => {
        const response = await this.validateAddressRaw(requestParameters);
        return await response.value();
    }

}
