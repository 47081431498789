/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal Client Api
 * Bumbal API documentation
 *
 * The version of the OpenAPI document: 2.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel } from '../runtime';
import {
    ActivityFiltersModel,
    ActivityFiltersModelFromJSON,
    ActivityFiltersModelFromJSONTyped,
    ActivityFiltersModelToJSON,
    ActivityOptionsModel,
    ActivityOptionsModelFromJSON,
    ActivityOptionsModelFromJSONTyped,
    ActivityOptionsModelToJSON,
} from './';


export interface ActivityRetrieveListArgumentsInterface {
    options?: ActivityOptionsModel;
    filters?: ActivityFiltersModel;
    limit?: number;
    offset?: number;
    sorting_column?: ActivityRetrieveListArgumentsSortingColumnEnum;
    sorting_direction?: ActivityRetrieveListArgumentsSortingDirectionEnum;
    search_text?: string;
    as_list?: boolean;
    count_only?: boolean;
}

/**
 * 
 * @export
 * @interface ActivityRetrieveListArguments
 */
export class ActivityRetrieveListArguments extends BaseModel<ActivityRetrieveListArgumentsInterface> {

    /**
     * 
     * @type {ActivityOptionsModel}
     * @memberof ActivityRetrieveListArguments
     */
    options?: ActivityOptionsModel;
    /**
     * 
     * @type {ActivityFiltersModel}
     * @memberof ActivityRetrieveListArguments
     */
    filters?: ActivityFiltersModel;
    /**
     * 
     * @type {number}
     * @memberof ActivityRetrieveListArguments
     */
    limit?: number;
    /**
     * 
     * @type {number}
     * @memberof ActivityRetrieveListArguments
     */
    offset?: number;
    /**
     * Sorting Column
     * @type {string}
     * @memberof ActivityRetrieveListArguments
     */
    sorting_column?: ActivityRetrieveListArgumentsSortingColumnEnum;
    /**
     * Sorting Direction
     * @type {string}
     * @memberof ActivityRetrieveListArguments
     */
    sorting_direction?: ActivityRetrieveListArgumentsSortingDirectionEnum;
    /**
     * 
     * @type {string}
     * @memberof ActivityRetrieveListArguments
     */
    search_text?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ActivityRetrieveListArguments
     */
    as_list?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ActivityRetrieveListArguments
     */
    count_only?: boolean;

    constructor(data?: ActivityRetrieveListArgumentsInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(value: ActivityRetrieveListArgumentsInterface): { [property: string]: any } {
        return {};
    }

}

export function ActivityRetrieveListArgumentsFromJSON(json: any): ActivityRetrieveListArguments {
    return ActivityRetrieveListArgumentsFromJSONTyped(json, false);
}

export function ActivityRetrieveListArgumentsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ActivityRetrieveListArguments {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = ActivityRetrieveListArguments;
    try {
        let localClass = require.context('../override', true)('./ActivityRetrieveListArguments');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'options': !exists(json, 'options') ? undefined : ActivityOptionsModelFromJSON(json['options']),
        'filters': !exists(json, 'filters') ? undefined : ActivityFiltersModelFromJSON(json['filters']),
        'limit': !exists(json, 'limit') ? undefined : Number(json['limit']),
        'offset': !exists(json, 'offset') ? undefined : Number(json['offset']),
        'sorting_column': !exists(json, 'sorting_column') ? undefined : json['sorting_column'],
        'sorting_direction': !exists(json, 'sorting_direction') ? undefined : json['sorting_direction'],
        'search_text': !exists(json, 'search_text') ? undefined : json['search_text'],
        'as_list': !exists(json, 'as_list') ? undefined : json['as_list'],
        'count_only': !exists(json, 'count_only') ? undefined : json['count_only'],
    });
}

export function ActivityRetrieveListArgumentsToJSON(value?: ActivityRetrieveListArguments | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = ActivityRetrieveListArguments;
    try {
        let localClass = require.context('../override', true)('./ActivityRetrieveListArguments');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return {
        
        'options': ActivityOptionsModelToJSON(value.options),
        'filters': ActivityFiltersModelToJSON(value.filters),
        'limit': value.limit,
        'offset': value.offset,
        'sorting_column': value.sorting_column,
        'sorting_direction': value.sorting_direction,
        'search_text': value.search_text,
        'as_list': value.as_list,
        'count_only': value.count_only,
        ...createClass.toJson(value),
    };
}

/**
* @export
* @enum {string}
*/
export enum ActivityRetrieveListArgumentsSortingColumnEnum {
    ActivityStatusId = 'activity.status_id',
    ActivityNr = 'activity.nr',
    ActivityTypeId = 'activity_type_id',
    SearchZip = 'search_zip',
    DateTimeFrom = 'date_time_from',
    SequenceNr = 'sequence_nr'
}
/**
* @export
* @enum {string}
*/
export enum ActivityRetrieveListArgumentsSortingDirectionEnum {
    Asc = 'asc',
    Desc = 'desc'
}


