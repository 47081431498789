/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal Client Api
 * Bumbal API documentation
 *
 * The version of the OpenAPI document: 2.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ConfigModel,
    ConfigModelFromJSON,
    ConfigModelToJSON,
    SayWhenConfigModel,
    SayWhenConfigModelFromJSON,
    SayWhenConfigModelToJSON,
    VariablesModel,
    VariablesModelFromJSON,
    VariablesModelToJSON,
} from '../models';

/**
 * 
 */
export class SystemApi extends runtime.BaseAPI {

    /**
     * Retrieve System Configuration
     * Retrieve System Configuration
     */
    async systemGetConfigRaw(): Promise<runtime.ApiResponse<ConfigModel>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/system/get-config`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ConfigModelFromJSON(jsonValue));
    }

    /**
     * Retrieve System Configuration
     * Retrieve System Configuration
     */
    systemGetConfig = async(): Promise<ConfigModel> => {
        const response = await this.systemGetConfigRaw();
        return await response.value();
    }

    /**
     * Retrieve SayWhen System Configuration
     * Retrieve SayWhen System Configuration
     */
    async systemGetSayWhenConfigRaw(): Promise<runtime.ApiResponse<SayWhenConfigModel>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/system/get-say-when-config`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SayWhenConfigModelFromJSON(jsonValue));
    }

    /**
     * Retrieve SayWhen System Configuration
     * Retrieve SayWhen System Configuration
     */
    systemGetSayWhenConfig = async(): Promise<SayWhenConfigModel> => {
        const response = await this.systemGetSayWhenConfigRaw();
        return await response.value();
    }

    /**
     * Retrieve System Variables
     * Retrieve System Variables
     */
    async systemGetVariablesRaw(): Promise<runtime.ApiResponse<VariablesModel>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/system/get-variables`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => VariablesModelFromJSON(jsonValue));
    }

    /**
     * Retrieve System Variables
     * Retrieve System Variables
     */
    systemGetVariables = async(): Promise<VariablesModel> => {
        const response = await this.systemGetVariablesRaw();
        return await response.value();
    }

}
