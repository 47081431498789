/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal Client Api
 * Bumbal API documentation
 *
 * The version of the OpenAPI document: 2.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel } from '../runtime';
import {
    BlockedDateFiltersModel,
    BlockedDateFiltersModelFromJSON,
    BlockedDateFiltersModelFromJSONTyped,
    BlockedDateFiltersModelToJSON,
} from './';


export interface BlockedDateRetrieveListArgumentsInterface {
    options?: object;
    filters?: BlockedDateFiltersModel;
    limit?: number;
    offset?: number;
    sorting_column?: BlockedDateRetrieveListArgumentsSortingColumnEnum;
    sorting_direction?: BlockedDateRetrieveListArgumentsSortingDirectionEnum;
    search_text?: string;
    as_list?: boolean;
    count_only?: boolean;
}

/**
 * 
 * @export
 * @interface BlockedDateRetrieveListArguments
 */
export class BlockedDateRetrieveListArguments extends BaseModel<BlockedDateRetrieveListArgumentsInterface> {

    /**
     * 
     * @type {object}
     * @memberof BlockedDateRetrieveListArguments
     */
    options?: object;
    /**
     * 
     * @type {BlockedDateFiltersModel}
     * @memberof BlockedDateRetrieveListArguments
     */
    filters?: BlockedDateFiltersModel;
    /**
     * 
     * @type {number}
     * @memberof BlockedDateRetrieveListArguments
     */
    limit?: number;
    /**
     * 
     * @type {number}
     * @memberof BlockedDateRetrieveListArguments
     */
    offset?: number;
    /**
     * Sorting Column
     * @type {string}
     * @memberof BlockedDateRetrieveListArguments
     */
    sorting_column?: BlockedDateRetrieveListArgumentsSortingColumnEnum;
    /**
     * Sorting Direction
     * @type {string}
     * @memberof BlockedDateRetrieveListArguments
     */
    sorting_direction?: BlockedDateRetrieveListArgumentsSortingDirectionEnum;
    /**
     * 
     * @type {string}
     * @memberof BlockedDateRetrieveListArguments
     */
    search_text?: string;
    /**
     * 
     * @type {boolean}
     * @memberof BlockedDateRetrieveListArguments
     */
    as_list?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BlockedDateRetrieveListArguments
     */
    count_only?: boolean;

    constructor(data?: BlockedDateRetrieveListArgumentsInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(value: BlockedDateRetrieveListArgumentsInterface): { [property: string]: any } {
        return {};
    }

}

export function BlockedDateRetrieveListArgumentsFromJSON(json: any): BlockedDateRetrieveListArguments {
    return BlockedDateRetrieveListArgumentsFromJSONTyped(json, false);
}

export function BlockedDateRetrieveListArgumentsFromJSONTyped(json: any, ignoreDiscriminator: boolean): BlockedDateRetrieveListArguments {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = BlockedDateRetrieveListArguments;
    try {
        let localClass = require.context('../override', true)('./BlockedDateRetrieveListArguments');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'options': !exists(json, 'options') ? undefined : json['options'],
        'filters': !exists(json, 'filters') ? undefined : BlockedDateFiltersModelFromJSON(json['filters']),
        'limit': !exists(json, 'limit') ? undefined : Number(json['limit']),
        'offset': !exists(json, 'offset') ? undefined : Number(json['offset']),
        'sorting_column': !exists(json, 'sorting_column') ? undefined : json['sorting_column'],
        'sorting_direction': !exists(json, 'sorting_direction') ? undefined : json['sorting_direction'],
        'search_text': !exists(json, 'search_text') ? undefined : json['search_text'],
        'as_list': !exists(json, 'as_list') ? undefined : json['as_list'],
        'count_only': !exists(json, 'count_only') ? undefined : json['count_only'],
    });
}

export function BlockedDateRetrieveListArgumentsToJSON(value?: BlockedDateRetrieveListArguments | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = BlockedDateRetrieveListArguments;
    try {
        let localClass = require.context('../override', true)('./BlockedDateRetrieveListArguments');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return {
        
        'options': value.options,
        'filters': BlockedDateFiltersModelToJSON(value.filters),
        'limit': value.limit,
        'offset': value.offset,
        'sorting_column': value.sorting_column,
        'sorting_direction': value.sorting_direction,
        'search_text': value.search_text,
        'as_list': value.as_list,
        'count_only': value.count_only,
        ...createClass.toJson(value),
    };
}

/**
* @export
* @enum {string}
*/
export enum BlockedDateRetrieveListArgumentsSortingColumnEnum {
    Date = 'date',
    Description = 'description'
}
/**
* @export
* @enum {string}
*/
export enum BlockedDateRetrieveListArgumentsSortingDirectionEnum {
    ASC = 'ASC',
    DESC = 'DESC'
}


