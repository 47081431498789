/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal Client Api
 * Bumbal API documentation
 *
 * The version of the OpenAPI document: 2.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel } from '../runtime';
import {
    ActivityModelAppliedCapacities,
    ActivityModelAppliedCapacitiesFromJSON,
    ActivityModelAppliedCapacitiesFromJSONTyped,
    ActivityModelAppliedCapacitiesToJSON,
    ActivityStatsModel,
    ActivityStatsModelFromJSON,
    ActivityStatsModelFromJSONTyped,
    ActivityStatsModelToJSON,
    AddressAppliedModel,
    AddressAppliedModelFromJSON,
    AddressAppliedModelFromJSONTyped,
    AddressAppliedModelToJSON,
    AddressModel,
    AddressModelFromJSON,
    AddressModelFromJSONTyped,
    AddressModelToJSON,
    AllowedDriverModel,
    AllowedDriverModelFromJSON,
    AllowedDriverModelFromJSONTyped,
    AllowedDriverModelToJSON,
    AssignmentModel,
    AssignmentModelFromJSON,
    AssignmentModelFromJSONTyped,
    AssignmentModelToJSON,
    BrandModel,
    BrandModelFromJSON,
    BrandModelFromJSONTyped,
    BrandModelToJSON,
    CapacityModel,
    CapacityModelFromJSON,
    CapacityModelFromJSONTyped,
    CapacityModelToJSON,
    CommunicationModel,
    CommunicationModelFromJSON,
    CommunicationModelFromJSONTyped,
    CommunicationModelToJSON,
    DriverModel,
    DriverModelFromJSON,
    DriverModelFromJSONTyped,
    DriverModelToJSON,
    FileModel,
    FileModelFromJSON,
    FileModelFromJSONTyped,
    FileModelToJSON,
    LinkModel,
    LinkModelFromJSON,
    LinkModelFromJSONTyped,
    LinkModelToJSON,
    MetaDataModel,
    MetaDataModelFromJSON,
    MetaDataModelFromJSONTyped,
    MetaDataModelToJSON,
    NoteModel,
    NoteModelFromJSON,
    NoteModelFromJSONTyped,
    NoteModelToJSON,
    PackageLineModel,
    PackageLineModelFromJSON,
    PackageLineModelFromJSONTyped,
    PackageLineModelToJSON,
    PaymentModel,
    PaymentModelFromJSON,
    PaymentModelFromJSONTyped,
    PaymentModelToJSON,
    RecurrenceModel,
    RecurrenceModelFromJSON,
    RecurrenceModelFromJSONTyped,
    RecurrenceModelToJSON,
    RouteModel,
    RouteModelFromJSON,
    RouteModelFromJSONTyped,
    RouteModelToJSON,
    TagModel,
    TagModelFromJSON,
    TagModelFromJSONTyped,
    TagModelToJSON,
    TimeSlotModel,
    TimeSlotModelFromJSON,
    TimeSlotModelFromJSONTyped,
    TimeSlotModelToJSON,
    TrailerModel,
    TrailerModelFromJSON,
    TrailerModelFromJSONTyped,
    TrailerModelToJSON,
    TransactionModel,
    TransactionModelFromJSON,
    TransactionModelFromJSONTyped,
    TransactionModelToJSON,
    UsersModel,
    UsersModelFromJSON,
    UsersModelFromJSONTyped,
    UsersModelToJSON,
    VehicleModel,
    VehicleModelFromJSON,
    VehicleModelFromJSONTyped,
    VehicleModelToJSON,
    ZoneModel,
    ZoneModelFromJSON,
    ZoneModelFromJSONTyped,
    ZoneModelToJSON,
} from './';


export interface ActivityModelInterface {
    id?: number;
    uuid?: string;
    bundle_activity_id?: number;
    shipment_activity_id?: number;
    shipment_activity_nr?: string;
    bundle_shipment_activity_nr?: string;
    nr?: string;
    activity_type_name?: ActivityModelActivityTypeNameEnum;
    activity_type_id?: ActivityModelActivityTypeIdEnum;
    status_id?: number;
    status_name?: ActivityModelStatusNameEnum;
    assignment_id?: number;
    assignment_nr?: string;
    assignment?: AssignmentModel;
    assignment_sequence_nr?: number;
    remarks?: string;
    locked?: number;
    optimized?: boolean;
    priority?: number;
    top_priority?: boolean;
    sequence_nr?: number;
    planned_driving_time?: number;
    planned_driving_duration?: number;
    planned_driving_distance?: number;
    reference?: string;
    description?: string;
    earliest_delivery_date_time?: Date;
    latest_delivery_date_time?: Date;
    earliest_delivery_date?: Date;
    latest_delivery_date?: Date;
    date_time_from?: Date;
    date_time_to?: Date;
    planned_date_time_from?: Date;
    planned_date_time_to?: Date;
    executed_date_time_from?: Date;
    executed_date_time_to?: Date;
    duration?: number;
    depot_duration?: number;
    active?: boolean;
    route_id?: string;
    route_nr?: string;
    route_name?: string;
    route_date_time_from?: Date;
    route_date_time_to?: Date;
    route_earliest_date_time?: Date;
    route_latest_date_time?: Date;
    route_status_id?: number;
    route_status_name?: string;
    route_nr_of_stops?: number;
    route_overdue?: boolean;
    co_driver_ids?: Array<number>;
    co_drivers?: Array<UsersModel>;
    user_id?: number;
    driver_id?: number;
    driver_first_name?: string;
    driver_last_name?: string;
    driver_name_prefix?: string;
    driver_full_name?: string;
    addressapplied_id?: number;
    address_id?: number;
    address?: AddressModel;
    address_applied?: AddressAppliedModel;
    depot_address?: AddressModel;
    depot_address_id?: number;
    depot_activity?: Array<ActivityModel>;
    allowed_driver_ids?: Array<number>;
    allowed_drivers?: Array<AllowedDriverModel>;
    allowed_drivers_links?: Array<LinkModel>;
    applied_capacities?: ActivityModelAppliedCapacities;
    capacities?: Array<CapacityModel>;
    package_lines?: Array<PackageLineModel>;
    payments?: Array<PaymentModel>;
    time_slots?: Array<TimeSlotModel>;
    brand_id?: number;
    brand_name?: string;
    brand?: BrandModel;
    communication?: CommunicationModel;
    assignment_link?: LinkModel;
    route_link?: LinkModel;
    route?: RouteModel;
    driver?: DriverModel;
    driver_links?: Array<LinkModel>;
    car?: VehicleModel;
    vehicle?: VehicleModel;
    trailer?: TrailerModel;
    activity_id_before?: string;
    activity_id_after?: string;
    bundled_activity_ids?: Array<number>;
    tags?: Array<TagModel>;
    recurrence?: RecurrenceModel;
    tag_names?: Array<string>;
    tag_ids?: Array<number>;
    zones?: Array<ZoneModel>;
    zone_names?: Array<string>;
    links?: Array<LinkModel>;
    meta_data?: Array<MetaDataModel>;
    stats?: ActivityStatsModel;
    notes?: Array<NoteModel>;
    files?: Array<FileModel>;
    activity_created_at?: Date;
    activity_updated_at?: Date;
    activity_created_by?: number;
    activity_updated_by?: number;
    activity_created_by_user?: UsersModel;
    activity_updated_by_user?: UsersModel;
    activity_active?: boolean;
    activity_removed?: boolean;
    payment_total?: number;
    transaction_total?: number;
    due_total?: number;
    transactions?: Array<TransactionModel>;
}

/**
 * 
 * @export
 * @interface ActivityModel
 */
export class ActivityModel extends BaseModel<ActivityModelInterface> {

    /**
     * Unique Identifier
     * @type {number}
     * @memberof ActivityModel
     */
    id?: number;
    /**
     * unique per activity
     * @type {string}
     * @memberof ActivityModel
     */
    uuid?: string;
    /**
     * Unique Identifier for partner shipment activity
     * @type {number}
     * @memberof ActivityModel
     */
    bundle_activity_id?: number;
    /**
     * Unique Identifier for partner shipment activity
     * @type {number}
     * @memberof ActivityModel
     */
    shipment_activity_id?: number;
    /**
     * Number for partner shipment activity
     * @type {string}
     * @memberof ActivityModel
     */
    shipment_activity_nr?: string;
    /**
     * Number for partner bundle shipment activity
     * @type {string}
     * @memberof ActivityModel
     */
    bundle_shipment_activity_nr?: string;
    /**
     * Number of this Activity
     * @type {string}
     * @memberof ActivityModel
     */
    nr?: string;
    /**
     * Activity Type Name. breakdown (:20), bundled (:32), car_end (:6), car_start (:5), car_wash (:27), combi (:29), depot (:28), driver_end (:10), driver_start (:9), dropoff (:2), gas_refill (:14), interior_wash (:30), maintenance (:26), maintenance_planned (:15), maintenance_unplanned (:16), other (:31), pause (:13), pickup (:1), route_end (:4), route_start (:3), sanitary (:21), stop (:11), unplanned_stop (:18), user_other (:17), wait (:19)
     * @type {string}
     * @memberof ActivityModel
     */
    activity_type_name?: ActivityModelActivityTypeNameEnum;
    /**
     * Unique Activity type ID. 1 (:pickup), 2 (:dropoff), 3 (:route_start), 4 (:route_end), 5 (:car_start), 6 (:car_end), 9 (:driver_start), 10 (:driver_end), 11 (:stop), 13 (:pause), 14 (:gas_refill), 15 (:maintenance_planned), 16 (:maintenance_unplanned), 17 (:user_other), 18 (:unplanned_stop), 19 (:wait), 20 (:breakdown), 21 (:sanitary), 26 (:maintenance), 27 (:car_wash), 28 (:depot), 29 (:combi), 30 (:interior_wash), 31 (:other), 32 (:bundled)
     * @type {number}
     * @memberof ActivityModel
     */
    activity_type_id?: ActivityModelActivityTypeIdEnum;
    /**
     * StatusId of this Activity, 28: activity_cancelled, 20: activity_incomplete, 21: activity_new, 39: activity_awaiting, 22: activity_accepted, 3: activity_planned, 4: activity_in_progress, 9: activity_executed
     * @type {number}
     * @memberof ActivityModel
     */
    status_id?: number;
    /**
     * Activity Status
     * @type {string}
     * @memberof ActivityModel
     */
    status_name?: ActivityModelStatusNameEnum;
    /**
     * Assignment ID
     * @type {number}
     * @memberof ActivityModel
     */
    assignment_id?: number;
    /**
     * Assignment NR
     * @type {string}
     * @memberof ActivityModel
     */
    assignment_nr?: string;
    /**
     * 
     * @type {AssignmentModel}
     * @memberof ActivityModel
     */
    assignment?: AssignmentModel;
    /**
     * Assignment sequence number for multi day assignments
     * @type {number}
     * @memberof ActivityModel
     */
    assignment_sequence_nr?: number;
    /**
     * Remarks about this activity
     * @type {string}
     * @memberof ActivityModel
     */
    remarks?: string;
    /**
     * Activity locked status. 0 = not locked. 1 = locked on route and time. 2 = locked on route.
     * @type {number}
     * @memberof ActivityModel
     */
    locked?: number;
    /**
     * Activity optimized status within route.
     * @type {boolean}
     * @memberof ActivityModel
     */
    optimized?: boolean;
    /**
     * Priority level. 1 for highest priority, 3 for lowest priority. Default = 2
     * @type {number}
     * @memberof ActivityModel
     */
    priority?: number;
    /**
     * Activity has top priority
     * @type {boolean}
     * @memberof ActivityModel
     */
    top_priority?: boolean;
    /**
     * Sequence number on Route
     * @type {number}
     * @memberof ActivityModel
     */
    sequence_nr?: number;
    /**
     * Deprecated! Driving time from the activity before this one
     * @type {number}
     * @memberof ActivityModel
     */
    planned_driving_time?: number;
    /**
     * Driving time from the activity before this one
     * @type {number}
     * @memberof ActivityModel
     */
    planned_driving_duration?: number;
    /**
     * Driving distance from the activity before this one
     * @type {number}
     * @memberof ActivityModel
     */
    planned_driving_distance?: number;
    /**
     * Reference of this activity
     * @type {string}
     * @memberof ActivityModel
     */
    reference?: string;
    /**
     * description of this activity
     * @type {string}
     * @memberof ActivityModel
     */
    description?: string;
    /**
     * 
     * @type {Date}
     * @memberof ActivityModel
     */
    earliest_delivery_date_time?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ActivityModel
     */
    latest_delivery_date_time?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ActivityModel
     */
    earliest_delivery_date?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ActivityModel
     */
    latest_delivery_date?: Date;
    /**
     * Earliest date-time
     * @type {Date}
     * @memberof ActivityModel
     */
    date_time_from?: Date;
    /**
     * latest date-time
     * @type {Date}
     * @memberof ActivityModel
     */
    date_time_to?: Date;
    /**
     * planned date-time from (only filled for planned activities)
     * @type {Date}
     * @memberof ActivityModel
     */
    planned_date_time_from?: Date;
    /**
     * planned date-time to (only filled for planned activities)
     * @type {Date}
     * @memberof ActivityModel
     */
    planned_date_time_to?: Date;
    /**
     * executed date-time from (only filled for executed activities)
     * @type {Date}
     * @memberof ActivityModel
     */
    executed_date_time_from?: Date;
    /**
     * executed date-time to (only filled for executed activities)
     * @type {Date}
     * @memberof ActivityModel
     */
    executed_date_time_to?: Date;
    /**
     * Duration of this activity in minutes
     * @type {number}
     * @memberof ActivityModel
     */
    duration?: number;
    /**
     * Duration of the depot activity in minutes
     * @type {number}
     * @memberof ActivityModel
     */
    depot_duration?: number;
    /**
     * if active=0: activity has been removed and is no longer visible in any bumbal interface
     * @type {boolean}
     * @memberof ActivityModel
     */
    active?: boolean;
    /**
     * Route ID (unique)
     * @type {string}
     * @memberof ActivityModel
     */
    route_id?: string;
    /**
     * Route Number (not unique)
     * @type {string}
     * @memberof ActivityModel
     */
    route_nr?: string;
    /**
     * Route name
     * @type {string}
     * @memberof ActivityModel
     */
    route_name?: string;
    /**
     * Start date-time of route
     * @type {Date}
     * @memberof ActivityModel
     */
    route_date_time_from?: Date;
    /**
     * End date-time of route
     * @type {Date}
     * @memberof ActivityModel
     */
    route_date_time_to?: Date;
    /**
     * Earliest date-time of route
     * @type {Date}
     * @memberof ActivityModel
     */
    route_earliest_date_time?: Date;
    /**
     * Latest date-time of route
     * @type {Date}
     * @memberof ActivityModel
     */
    route_latest_date_time?: Date;
    /**
     * Status ID of this Route
     * @type {number}
     * @memberof ActivityModel
     */
    route_status_id?: number;
    /**
     * Status Name of this Route
     * @type {string}
     * @memberof ActivityModel
     */
    route_status_name?: string;
    /**
     * Number of stops on this route
     * @type {number}
     * @memberof ActivityModel
     */
    route_nr_of_stops?: number;
    /**
     * Route is overdue
     * @type {boolean}
     * @memberof ActivityModel
     */
    route_overdue?: boolean;
    /**
     * Unique Identifier(s) for co-drivers on route
     * @type {Array<number>}
     * @memberof ActivityModel
     */
    co_driver_ids?: Array<number>;
    /**
     * list of co-drivers on route
     * @type {Array<UsersModel>}
     * @memberof ActivityModel
     */
    co_drivers?: Array<UsersModel>;
    /**
     * ID of the user who will execute this activity
     * @type {number}
     * @memberof ActivityModel
     */
    user_id?: number;
    /**
     * Driver ID connected to this route/activity
     * @type {number}
     * @memberof ActivityModel
     */
    driver_id?: number;
    /**
     * Driver first name
     * @type {string}
     * @memberof ActivityModel
     */
    driver_first_name?: string;
    /**
     * Driver last name
     * @type {string}
     * @memberof ActivityModel
     */
    driver_last_name?: string;
    /**
     * Driver prefix
     * @type {string}
     * @memberof ActivityModel
     */
    driver_name_prefix?: string;
    /**
     * Driver full name
     * @type {string}
     * @memberof ActivityModel
     */
    driver_full_name?: string;
    /**
     * Address Applied ID
     * @type {number}
     * @memberof ActivityModel
     */
    addressapplied_id?: number;
    /**
     * Address ID
     * @type {number}
     * @memberof ActivityModel
     */
    address_id?: number;
    /**
     * 
     * @type {AddressModel}
     * @memberof ActivityModel
     */
    address?: AddressModel;
    /**
     * 
     * @type {AddressAppliedModel}
     * @memberof ActivityModel
     */
    address_applied?: AddressAppliedModel;
    /**
     * 
     * @type {AddressModel}
     * @memberof ActivityModel
     */
    depot_address?: AddressModel;
    /**
     * Depot Address ID
     * @type {number}
     * @memberof ActivityModel
     */
    depot_address_id?: number;
    /**
     * 
     * @type {Array<ActivityModel>}
     * @memberof ActivityModel
     */
    depot_activity?: Array<ActivityModel>;
    /**
     * Unique Identifier(s) for allowed drivers in activity
     * @type {Array<number>}
     * @memberof ActivityModel
     */
    allowed_driver_ids?: Array<number>;
    /**
     * 
     * @type {Array<AllowedDriverModel>}
     * @memberof ActivityModel
     */
    allowed_drivers?: Array<AllowedDriverModel>;
    /**
     * 
     * @type {Array<LinkModel>}
     * @memberof ActivityModel
     */
    allowed_drivers_links?: Array<LinkModel>;
    /**
     * 
     * @type {ActivityModelAppliedCapacities}
     * @memberof ActivityModel
     */
    applied_capacities?: ActivityModelAppliedCapacities;
    /**
     * 
     * @type {Array<CapacityModel>}
     * @memberof ActivityModel
     */
    capacities?: Array<CapacityModel>;
    /**
     * 
     * @type {Array<PackageLineModel>}
     * @memberof ActivityModel
     */
    package_lines?: Array<PackageLineModel>;
    /**
     * 
     * @type {Array<PaymentModel>}
     * @memberof ActivityModel
     */
    payments?: Array<PaymentModel>;
    /**
     * 
     * @type {Array<TimeSlotModel>}
     * @memberof ActivityModel
     */
    time_slots?: Array<TimeSlotModel>;
    /**
     * Brand ID
     * @type {number}
     * @memberof ActivityModel
     */
    brand_id?: number;
    /**
     * Brand name (must be unique)
     * @type {string}
     * @memberof ActivityModel
     */
    brand_name?: string;
    /**
     * 
     * @type {BrandModel}
     * @memberof ActivityModel
     */
    brand?: BrandModel;
    /**
     * 
     * @type {CommunicationModel}
     * @memberof ActivityModel
     */
    communication?: CommunicationModel;
    /**
     * 
     * @type {LinkModel}
     * @memberof ActivityModel
     */
    assignment_link?: LinkModel;
    /**
     * 
     * @type {LinkModel}
     * @memberof ActivityModel
     */
    route_link?: LinkModel;
    /**
     * 
     * @type {RouteModel}
     * @memberof ActivityModel
     */
    route?: RouteModel;
    /**
     * 
     * @type {DriverModel}
     * @memberof ActivityModel
     */
    driver?: DriverModel;
    /**
     * 
     * @type {Array<LinkModel>}
     * @memberof ActivityModel
     */
    driver_links?: Array<LinkModel>;
    /**
     * 
     * @type {VehicleModel}
     * @memberof ActivityModel
     */
    car?: VehicleModel;
    /**
     * 
     * @type {VehicleModel}
     * @memberof ActivityModel
     */
    vehicle?: VehicleModel;
    /**
     * 
     * @type {TrailerModel}
     * @memberof ActivityModel
     */
    trailer?: TrailerModel;
    /**
     * Activity ID of the activity which must be performed before this activity
     * @type {string}
     * @memberof ActivityModel
     */
    activity_id_before?: string;
    /**
     * Activity ID of the activity which must be performed after this activity
     * @type {string}
     * @memberof ActivityModel
     */
    activity_id_after?: string;
    /**
     * Ids of activities within bundle activity
     * @type {Array<number>}
     * @memberof ActivityModel
     */
    bundled_activity_ids?: Array<number>;
    /**
     * 
     * @type {Array<TagModel>}
     * @memberof ActivityModel
     */
    tags?: Array<TagModel>;
    /**
     * 
     * @type {RecurrenceModel}
     * @memberof ActivityModel
     */
    recurrence?: RecurrenceModel;
    /**
     * Tag names
     * @type {Array<string>}
     * @memberof ActivityModel
     */
    tag_names?: Array<string>;
    /**
     * Tag type ids
     * @type {Array<number>}
     * @memberof ActivityModel
     */
    tag_ids?: Array<number>;
    /**
     * 
     * @type {Array<ZoneModel>}
     * @memberof ActivityModel
     */
    zones?: Array<ZoneModel>;
    /**
     * Zone names
     * @type {Array<string>}
     * @memberof ActivityModel
     */
    zone_names?: Array<string>;
    /**
     * 
     * @type {Array<LinkModel>}
     * @memberof ActivityModel
     */
    links?: Array<LinkModel>;
    /**
     * 
     * @type {Array<MetaDataModel>}
     * @memberof ActivityModel
     */
    meta_data?: Array<MetaDataModel>;
    /**
     * 
     * @type {ActivityStatsModel}
     * @memberof ActivityModel
     */
    stats?: ActivityStatsModel;
    /**
     * 
     * @type {Array<NoteModel>}
     * @memberof ActivityModel
     */
    notes?: Array<NoteModel>;
    /**
     * 
     * @type {Array<FileModel>}
     * @memberof ActivityModel
     */
    files?: Array<FileModel>;
    /**
     * created_at date time
     * @type {Date}
     * @memberof ActivityModel
     */
    activity_created_at?: Date;
    /**
     * updated_at date time
     * @type {Date}
     * @memberof ActivityModel
     */
    activity_updated_at?: Date;
    /**
     * created_by user id
     * @type {number}
     * @memberof ActivityModel
     */
    activity_created_by?: number;
    /**
     * updated_by user id
     * @type {number}
     * @memberof ActivityModel
     */
    activity_updated_by?: number;
    /**
     * 
     * @type {UsersModel}
     * @memberof ActivityModel
     */
    activity_created_by_user?: UsersModel;
    /**
     * 
     * @type {UsersModel}
     * @memberof ActivityModel
     */
    activity_updated_by_user?: UsersModel;
    /**
     * Activity is active (=true). Inactive activities are not automatically considered in any of the application algorithms and will not be shown in the Bumbal Gui.
     * @type {boolean}
     * @memberof ActivityModel
     */
    activity_active?: boolean;
    /**
     * Activity is removed (=true). Removed activities are not automatically considered in any of the application algorithms and will not be shown in the Bumbal Gui. Removed activities are usually irrepairable.
     * @type {boolean}
     * @memberof ActivityModel
     */
    activity_removed?: boolean;
    /**
     * Total to be paid in cents. readonly
     * @type {number}
     * @memberof ActivityModel
     */
    payment_total?: number;
    /**
     * Total has been paid in cents. readonly
     * @type {number}
     * @memberof ActivityModel
     */
    transaction_total?: number;
    /**
     * Amount which hasn't been paid yet in cents. readonly
     * @type {number}
     * @memberof ActivityModel
     */
    due_total?: number;
    /**
     * 
     * @type {Array<TransactionModel>}
     * @memberof ActivityModel
     */
    transactions?: Array<TransactionModel>;

    constructor(data?: ActivityModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(value: ActivityModelInterface): { [property: string]: any } {
        return {};
    }

}

export function ActivityModelFromJSON(json: any): ActivityModel {
    return ActivityModelFromJSONTyped(json, false);
}

export function ActivityModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ActivityModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = ActivityModel;
    try {
        let localClass = require.context('../override', true)('./ActivityModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'id': !exists(json, 'id') ? undefined : Number(json['id']),
        'uuid': !exists(json, 'uuid') ? undefined : json['uuid'],
        'bundle_activity_id': !exists(json, 'bundle_activity_id') ? undefined : Number(json['bundle_activity_id']),
        'shipment_activity_id': !exists(json, 'shipment_activity_id') ? undefined : Number(json['shipment_activity_id']),
        'shipment_activity_nr': !exists(json, 'shipment_activity_nr') ? undefined : json['shipment_activity_nr'],
        'bundle_shipment_activity_nr': !exists(json, 'bundle_shipment_activity_nr') ? undefined : json['bundle_shipment_activity_nr'],
        'nr': !exists(json, 'nr') ? undefined : json['nr'],
        'activity_type_name': !exists(json, 'activity_type_name') ? undefined : json['activity_type_name'],
        'activity_type_id': !exists(json, 'activity_type_id') ? undefined : Number(json['activity_type_id']),
        'status_id': !exists(json, 'status_id') ? undefined : Number(json['status_id']),
        'status_name': !exists(json, 'status_name') ? undefined : json['status_name'],
        'assignment_id': !exists(json, 'assignment_id') ? undefined : Number(json['assignment_id']),
        'assignment_nr': !exists(json, 'assignment_nr') ? undefined : json['assignment_nr'],
        'assignment': !exists(json, 'assignment') ? undefined : AssignmentModelFromJSON(json['assignment']),
        'assignment_sequence_nr': !exists(json, 'assignment_sequence_nr') ? undefined : Number(json['assignment_sequence_nr']),
        'remarks': !exists(json, 'remarks') ? undefined : json['remarks'],
        'locked': !exists(json, 'locked') ? undefined : Number(json['locked']),
        'optimized': !exists(json, 'optimized') ? undefined : json['optimized'],
        'priority': !exists(json, 'priority') ? undefined : Number(json['priority']),
        'top_priority': !exists(json, 'top_priority') ? undefined : json['top_priority'],
        'sequence_nr': !exists(json, 'sequence_nr') ? undefined : Number(json['sequence_nr']),
        'planned_driving_time': !exists(json, 'planned_driving_time') ? undefined : Number(json['planned_driving_time']),
        'planned_driving_duration': !exists(json, 'planned_driving_duration') ? undefined : Number(json['planned_driving_duration']),
        'planned_driving_distance': !exists(json, 'planned_driving_distance') ? undefined : Number(json['planned_driving_distance']),
        'reference': !exists(json, 'reference') ? undefined : json['reference'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'earliest_delivery_date_time': !exists(json, 'earliest_delivery_date_time') ? undefined : (new Date(json['earliest_delivery_date_time'].replace(' ', 'T'))),
        'latest_delivery_date_time': !exists(json, 'latest_delivery_date_time') ? undefined : (new Date(json['latest_delivery_date_time'].replace(' ', 'T'))),
        'earliest_delivery_date': !exists(json, 'earliest_delivery_date') ? undefined : (new Date(json['earliest_delivery_date'].replace(' ', 'T'))),
        'latest_delivery_date': !exists(json, 'latest_delivery_date') ? undefined : (new Date(json['latest_delivery_date'].replace(' ', 'T'))),
        'date_time_from': !exists(json, 'date_time_from') ? undefined : (new Date(json['date_time_from'].replace(' ', 'T'))),
        'date_time_to': !exists(json, 'date_time_to') ? undefined : (new Date(json['date_time_to'].replace(' ', 'T'))),
        'planned_date_time_from': !exists(json, 'planned_date_time_from') ? undefined : (new Date(json['planned_date_time_from'].replace(' ', 'T'))),
        'planned_date_time_to': !exists(json, 'planned_date_time_to') ? undefined : (new Date(json['planned_date_time_to'].replace(' ', 'T'))),
        'executed_date_time_from': !exists(json, 'executed_date_time_from') ? undefined : (new Date(json['executed_date_time_from'].replace(' ', 'T'))),
        'executed_date_time_to': !exists(json, 'executed_date_time_to') ? undefined : (new Date(json['executed_date_time_to'].replace(' ', 'T'))),
        'duration': !exists(json, 'duration') ? undefined : Number(json['duration']),
        'depot_duration': !exists(json, 'depot_duration') ? undefined : Number(json['depot_duration']),
        'active': !exists(json, 'active') ? undefined : json['active'],
        'route_id': !exists(json, 'route_id') ? undefined : json['route_id'],
        'route_nr': !exists(json, 'route_nr') ? undefined : json['route_nr'],
        'route_name': !exists(json, 'route_name') ? undefined : json['route_name'],
        'route_date_time_from': !exists(json, 'route_date_time_from') ? undefined : (new Date(json['route_date_time_from'].replace(' ', 'T'))),
        'route_date_time_to': !exists(json, 'route_date_time_to') ? undefined : (new Date(json['route_date_time_to'].replace(' ', 'T'))),
        'route_earliest_date_time': !exists(json, 'route_earliest_date_time') ? undefined : (new Date(json['route_earliest_date_time'].replace(' ', 'T'))),
        'route_latest_date_time': !exists(json, 'route_latest_date_time') ? undefined : (new Date(json['route_latest_date_time'].replace(' ', 'T'))),
        'route_status_id': !exists(json, 'route_status_id') ? undefined : Number(json['route_status_id']),
        'route_status_name': !exists(json, 'route_status_name') ? undefined : json['route_status_name'],
        'route_nr_of_stops': !exists(json, 'route_nr_of_stops') ? undefined : Number(json['route_nr_of_stops']),
        'route_overdue': !exists(json, 'route_overdue') ? undefined : json['route_overdue'],
        'co_driver_ids': !exists(json, 'co_driver_ids') ? undefined : json['co_driver_ids'],
        'co_drivers': !exists(json, 'co_drivers') ? undefined : ((json['co_drivers'] as Array<any>).map(UsersModelFromJSON)),
        'user_id': !exists(json, 'user_id') ? undefined : Number(json['user_id']),
        'driver_id': !exists(json, 'driver_id') ? undefined : Number(json['driver_id']),
        'driver_first_name': !exists(json, 'driver_first_name') ? undefined : json['driver_first_name'],
        'driver_last_name': !exists(json, 'driver_last_name') ? undefined : json['driver_last_name'],
        'driver_name_prefix': !exists(json, 'driver_name_prefix') ? undefined : json['driver_name_prefix'],
        'driver_full_name': !exists(json, 'driver_full_name') ? undefined : json['driver_full_name'],
        'addressapplied_id': !exists(json, 'addressapplied_id') ? undefined : Number(json['addressapplied_id']),
        'address_id': !exists(json, 'address_id') ? undefined : Number(json['address_id']),
        'address': !exists(json, 'address') ? undefined : AddressModelFromJSON(json['address']),
        'address_applied': !exists(json, 'address_applied') ? undefined : AddressAppliedModelFromJSON(json['address_applied']),
        'depot_address': !exists(json, 'depot_address') ? undefined : AddressModelFromJSON(json['depot_address']),
        'depot_address_id': !exists(json, 'depot_address_id') ? undefined : Number(json['depot_address_id']),
        'depot_activity': !exists(json, 'depot_activity') ? undefined : ((json['depot_activity'] as Array<any>).map(ActivityModelFromJSON)),
        'allowed_driver_ids': !exists(json, 'allowed_driver_ids') ? undefined : json['allowed_driver_ids'],
        'allowed_drivers': !exists(json, 'allowed_drivers') ? undefined : ((json['allowed_drivers'] as Array<any>).map(AllowedDriverModelFromJSON)),
        'allowed_drivers_links': !exists(json, 'allowed_drivers_links') ? undefined : ((json['allowed_drivers_links'] as Array<any>).map(LinkModelFromJSON)),
        'applied_capacities': !exists(json, 'applied_capacities') ? undefined : ActivityModelAppliedCapacitiesFromJSON(json['applied_capacities']),
        'capacities': !exists(json, 'capacities') ? undefined : ((json['capacities'] as Array<any>).map(CapacityModelFromJSON)),
        'package_lines': !exists(json, 'package_lines') ? undefined : ((json['package_lines'] as Array<any>).map(PackageLineModelFromJSON)),
        'payments': !exists(json, 'payments') ? undefined : ((json['payments'] as Array<any>).map(PaymentModelFromJSON)),
        'time_slots': !exists(json, 'time_slots') ? undefined : ((json['time_slots'] as Array<any>).map(TimeSlotModelFromJSON)),
        'brand_id': !exists(json, 'brand_id') ? undefined : Number(json['brand_id']),
        'brand_name': !exists(json, 'brand_name') ? undefined : json['brand_name'],
        'brand': !exists(json, 'brand') ? undefined : BrandModelFromJSON(json['brand']),
        'communication': !exists(json, 'communication') ? undefined : CommunicationModelFromJSON(json['communication']),
        'assignment_link': !exists(json, 'assignment_link') ? undefined : LinkModelFromJSON(json['assignment_link']),
        'route_link': !exists(json, 'route_link') ? undefined : LinkModelFromJSON(json['route_link']),
        'route': !exists(json, 'route') ? undefined : RouteModelFromJSON(json['route']),
        'driver': !exists(json, 'driver') ? undefined : DriverModelFromJSON(json['driver']),
        'driver_links': !exists(json, 'driver_links') ? undefined : ((json['driver_links'] as Array<any>).map(LinkModelFromJSON)),
        'car': !exists(json, 'car') ? undefined : VehicleModelFromJSON(json['car']),
        'vehicle': !exists(json, 'vehicle') ? undefined : VehicleModelFromJSON(json['vehicle']),
        'trailer': !exists(json, 'trailer') ? undefined : TrailerModelFromJSON(json['trailer']),
        'activity_id_before': !exists(json, 'activity_id_before') ? undefined : json['activity_id_before'],
        'activity_id_after': !exists(json, 'activity_id_after') ? undefined : json['activity_id_after'],
        'bundled_activity_ids': !exists(json, 'bundled_activity_ids') ? undefined : json['bundled_activity_ids'],
        'tags': !exists(json, 'tags') ? undefined : ((json['tags'] as Array<any>).map(TagModelFromJSON)),
        'recurrence': !exists(json, 'recurrence') ? undefined : RecurrenceModelFromJSON(json['recurrence']),
        'tag_names': !exists(json, 'tag_names') ? undefined : json['tag_names'],
        'tag_ids': !exists(json, 'tag_ids') ? undefined : json['tag_ids'],
        'zones': !exists(json, 'zones') ? undefined : ((json['zones'] as Array<any>).map(ZoneModelFromJSON)),
        'zone_names': !exists(json, 'zone_names') ? undefined : json['zone_names'],
        'links': !exists(json, 'links') ? undefined : ((json['links'] as Array<any>).map(LinkModelFromJSON)),
        'meta_data': !exists(json, 'meta_data') ? undefined : ((json['meta_data'] as Array<any>).map(MetaDataModelFromJSON)),
        'stats': !exists(json, 'stats') ? undefined : ActivityStatsModelFromJSON(json['stats']),
        'notes': !exists(json, 'notes') ? undefined : ((json['notes'] as Array<any>).map(NoteModelFromJSON)),
        'files': !exists(json, 'files') ? undefined : ((json['files'] as Array<any>).map(FileModelFromJSON)),
        'activity_created_at': !exists(json, 'activity_created_at') ? undefined : (new Date(json['activity_created_at'].replace(' ', 'T'))),
        'activity_updated_at': !exists(json, 'activity_updated_at') ? undefined : (new Date(json['activity_updated_at'].replace(' ', 'T'))),
        'activity_created_by': !exists(json, 'activity_created_by') ? undefined : Number(json['activity_created_by']),
        'activity_updated_by': !exists(json, 'activity_updated_by') ? undefined : Number(json['activity_updated_by']),
        'activity_created_by_user': !exists(json, 'activity_created_by_user') ? undefined : UsersModelFromJSON(json['activity_created_by_user']),
        'activity_updated_by_user': !exists(json, 'activity_updated_by_user') ? undefined : UsersModelFromJSON(json['activity_updated_by_user']),
        'activity_active': !exists(json, 'activity_active') ? undefined : json['activity_active'],
        'activity_removed': !exists(json, 'activity_removed') ? undefined : json['activity_removed'],
        'payment_total': !exists(json, 'payment_total') ? undefined : Number(json['payment_total']),
        'transaction_total': !exists(json, 'transaction_total') ? undefined : Number(json['transaction_total']),
        'due_total': !exists(json, 'due_total') ? undefined : Number(json['due_total']),
        'transactions': !exists(json, 'transactions') ? undefined : ((json['transactions'] as Array<any>).map(TransactionModelFromJSON)),
    });
}

export function ActivityModelToJSON(value?: ActivityModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = ActivityModel;
    try {
        let localClass = require.context('../override', true)('./ActivityModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return {
        
        'id': value.id,
        'uuid': value.uuid,
        'bundle_activity_id': value.bundle_activity_id,
        'shipment_activity_id': value.shipment_activity_id,
        'shipment_activity_nr': value.shipment_activity_nr,
        'bundle_shipment_activity_nr': value.bundle_shipment_activity_nr,
        'nr': value.nr,
        'activity_type_name': value.activity_type_name,
        'activity_type_id': value.activity_type_id,
        'status_id': value.status_id,
        'status_name': value.status_name,
        'assignment_id': value.assignment_id,
        'assignment_nr': value.assignment_nr,
        'assignment': AssignmentModelToJSON(value.assignment),
        'assignment_sequence_nr': value.assignment_sequence_nr,
        'remarks': value.remarks,
        'locked': value.locked,
        'optimized': value.optimized,
        'priority': value.priority,
        'top_priority': value.top_priority,
        'sequence_nr': value.sequence_nr,
        'planned_driving_time': value.planned_driving_time,
        'planned_driving_duration': value.planned_driving_duration,
        'planned_driving_distance': value.planned_driving_distance,
        'reference': value.reference,
        'description': value.description,
        'earliest_delivery_date_time': value.earliest_delivery_date_time === undefined ? undefined : (value.earliest_delivery_date_time.toApiFormat().substr(0,10)),
        'latest_delivery_date_time': value.latest_delivery_date_time === undefined ? undefined : (value.latest_delivery_date_time.toApiFormat().substr(0,10)),
        'earliest_delivery_date': value.earliest_delivery_date === undefined ? undefined : (value.earliest_delivery_date.toApiFormat().substr(0,10)),
        'latest_delivery_date': value.latest_delivery_date === undefined ? undefined : (value.latest_delivery_date.toApiFormat().substr(0,10)),
        'date_time_from': value.date_time_from === undefined ? undefined : (value.date_time_from.toApiFormat()),
        'date_time_to': value.date_time_to === undefined ? undefined : (value.date_time_to.toApiFormat()),
        'planned_date_time_from': value.planned_date_time_from === undefined ? undefined : (value.planned_date_time_from.toApiFormat()),
        'planned_date_time_to': value.planned_date_time_to === undefined ? undefined : (value.planned_date_time_to.toApiFormat()),
        'executed_date_time_from': value.executed_date_time_from === undefined ? undefined : (value.executed_date_time_from.toApiFormat()),
        'executed_date_time_to': value.executed_date_time_to === undefined ? undefined : (value.executed_date_time_to.toApiFormat()),
        'duration': value.duration,
        'depot_duration': value.depot_duration,
        'active': value.active,
        'route_id': value.route_id,
        'route_nr': value.route_nr,
        'route_name': value.route_name,
        'route_date_time_from': value.route_date_time_from === undefined ? undefined : (value.route_date_time_from.toApiFormat()),
        'route_date_time_to': value.route_date_time_to === undefined ? undefined : (value.route_date_time_to.toApiFormat()),
        'route_earliest_date_time': value.route_earliest_date_time === undefined ? undefined : (value.route_earliest_date_time.toApiFormat()),
        'route_latest_date_time': value.route_latest_date_time === undefined ? undefined : (value.route_latest_date_time.toApiFormat()),
        'route_status_id': value.route_status_id,
        'route_status_name': value.route_status_name,
        'route_nr_of_stops': value.route_nr_of_stops,
        'route_overdue': value.route_overdue,
        'co_driver_ids': value.co_driver_ids,
        'co_drivers': value.co_drivers === undefined ? undefined : ((value.co_drivers as Array<any>).map(UsersModelToJSON)),
        'user_id': value.user_id,
        'driver_id': value.driver_id,
        'driver_first_name': value.driver_first_name,
        'driver_last_name': value.driver_last_name,
        'driver_name_prefix': value.driver_name_prefix,
        'driver_full_name': value.driver_full_name,
        'addressapplied_id': value.addressapplied_id,
        'address_id': value.address_id,
        'address': AddressModelToJSON(value.address),
        'address_applied': AddressAppliedModelToJSON(value.address_applied),
        'depot_address': AddressModelToJSON(value.depot_address),
        'depot_address_id': value.depot_address_id,
        'depot_activity': value.depot_activity === undefined ? undefined : ((value.depot_activity as Array<any>).map(ActivityModelToJSON)),
        'allowed_driver_ids': value.allowed_driver_ids,
        'allowed_drivers': value.allowed_drivers === undefined ? undefined : ((value.allowed_drivers as Array<any>).map(AllowedDriverModelToJSON)),
        'allowed_drivers_links': value.allowed_drivers_links === undefined ? undefined : ((value.allowed_drivers_links as Array<any>).map(LinkModelToJSON)),
        'applied_capacities': ActivityModelAppliedCapacitiesToJSON(value.applied_capacities),
        'capacities': value.capacities === undefined ? undefined : ((value.capacities as Array<any>).map(CapacityModelToJSON)),
        'package_lines': value.package_lines === undefined ? undefined : ((value.package_lines as Array<any>).map(PackageLineModelToJSON)),
        'payments': value.payments === undefined ? undefined : ((value.payments as Array<any>).map(PaymentModelToJSON)),
        'time_slots': value.time_slots === undefined ? undefined : ((value.time_slots as Array<any>).map(TimeSlotModelToJSON)),
        'brand_id': value.brand_id,
        'brand_name': value.brand_name,
        'brand': BrandModelToJSON(value.brand),
        'communication': CommunicationModelToJSON(value.communication),
        'assignment_link': LinkModelToJSON(value.assignment_link),
        'route_link': LinkModelToJSON(value.route_link),
        'route': RouteModelToJSON(value.route),
        'driver': DriverModelToJSON(value.driver),
        'driver_links': value.driver_links === undefined ? undefined : ((value.driver_links as Array<any>).map(LinkModelToJSON)),
        'car': VehicleModelToJSON(value.car),
        'vehicle': VehicleModelToJSON(value.vehicle),
        'trailer': TrailerModelToJSON(value.trailer),
        'activity_id_before': value.activity_id_before,
        'activity_id_after': value.activity_id_after,
        'bundled_activity_ids': value.bundled_activity_ids,
        'tags': value.tags === undefined ? undefined : ((value.tags as Array<any>).map(TagModelToJSON)),
        'recurrence': RecurrenceModelToJSON(value.recurrence),
        'tag_names': value.tag_names,
        'tag_ids': value.tag_ids,
        'zones': value.zones === undefined ? undefined : ((value.zones as Array<any>).map(ZoneModelToJSON)),
        'zone_names': value.zone_names,
        'links': value.links === undefined ? undefined : ((value.links as Array<any>).map(LinkModelToJSON)),
        'meta_data': value.meta_data === undefined ? undefined : ((value.meta_data as Array<any>).map(MetaDataModelToJSON)),
        'stats': ActivityStatsModelToJSON(value.stats),
        'notes': value.notes === undefined ? undefined : ((value.notes as Array<any>).map(NoteModelToJSON)),
        'files': value.files === undefined ? undefined : ((value.files as Array<any>).map(FileModelToJSON)),
        'activity_created_at': value.activity_created_at === undefined ? undefined : (value.activity_created_at.toApiFormat()),
        'activity_updated_at': value.activity_updated_at === undefined ? undefined : (value.activity_updated_at.toApiFormat()),
        'activity_created_by': value.activity_created_by,
        'activity_updated_by': value.activity_updated_by,
        'activity_created_by_user': UsersModelToJSON(value.activity_created_by_user),
        'activity_updated_by_user': UsersModelToJSON(value.activity_updated_by_user),
        'activity_active': value.activity_active,
        'activity_removed': value.activity_removed,
        'payment_total': value.payment_total,
        'transaction_total': value.transaction_total,
        'due_total': value.due_total,
        'transactions': value.transactions === undefined ? undefined : ((value.transactions as Array<any>).map(TransactionModelToJSON)),
        ...createClass.toJson(value),
    };
}

/**
* @export
* @enum {string}
*/
export enum ActivityModelActivityTypeNameEnum {
    Breakdown = 'breakdown',
    Bundled = 'bundled',
    CarEnd = 'car_end',
    CarStart = 'car_start',
    CarWash = 'car_wash',
    Combi = 'combi',
    Depot = 'depot',
    DriverEnd = 'driver_end',
    DriverStart = 'driver_start',
    DriverUnavailable = 'driver_unavailable',
    Dropoff = 'dropoff',
    EquipmentEnd = 'equipment_end',
    EquipmentStart = 'equipment_start',
    GasRefill = 'gas_refill',
    InteriorWash = 'interior_wash',
    Maintenance = 'maintenance',
    MaintenancePlanned = 'maintenance_planned',
    MaintenanceUnplanned = 'maintenance_unplanned',
    Other = 'other',
    OtherEnd = 'other_end',
    OtherStart = 'other_start',
    Pause = 'pause',
    Pickup = 'pickup',
    RouteEnd = 'route_end',
    RouteStart = 'route_start',
    Sanitary = 'sanitary',
    Stop = 'stop',
    TrailerAttach = 'trailer_attach',
    TrailerDetach = 'trailer_detach',
    UnplannedStop = 'unplanned_stop',
    Unsuccessful = 'unsuccessful',
    UserOther = 'user_other',
    Wait = 'wait',
    XmlUnspecified = 'xml_unspecified'
}
/**
* @export
* @enum {string}
*/
export enum ActivityModelActivityTypeIdEnum {
    NUMBER_1 = 1,
    NUMBER_2 = 2,
    NUMBER_3 = 3,
    NUMBER_4 = 4,
    NUMBER_5 = 5,
    NUMBER_6 = 6,
    NUMBER_9 = 9,
    NUMBER_10 = 10,
    NUMBER_11 = 11,
    NUMBER_13 = 13,
    NUMBER_14 = 14,
    NUMBER_15 = 15,
    NUMBER_16 = 16,
    NUMBER_17 = 17,
    NUMBER_18 = 18,
    NUMBER_19 = 19,
    NUMBER_20 = 20,
    NUMBER_21 = 21,
    NUMBER_26 = 26,
    NUMBER_27 = 27,
    NUMBER_28 = 28,
    NUMBER_29 = 29,
    NUMBER_30 = 30,
    NUMBER_31 = 31,
    NUMBER_32 = 32,
    NUMBER_33 = 33,
    NUMBER_34 = 34
}
/**
* @export
* @enum {string}
*/
export enum ActivityModelStatusNameEnum {
    Incomplete = 'activity_incomplete',
    New = 'activity_new',
    Accepted = 'activity_accepted',
    Planned = 'activity_planned',
    InProgress = 'activity_in_progress',
    Executed = 'activity_executed',
    Cancelled = 'activity_cancelled',
    Awaiting = 'activity_awaiting'
}


