import { Box, Button, Typography, Link } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

const IntroVideo = () => {
    const { t } = useTranslation();

    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            paddingY="5%"
            sx={{ backgroundColor: '#FBFBFB'}}
        >
            <Typography variant="h4" textAlign="center" paddingBottom="40px" fontWeight={600}>
                {t('home.intro.purpose')}
            </Typography>
            <Box
                width="80%"
                maxWidth="60em"
            >
                <Box
                    position="relative"
                    width="100%"
                    height="0"
                    paddingBottom="56.25%"
                >
                    <iframe
                        src="https://www.youtube.com/embed/vIPql3cWDT4"
                        title="What is the purpose of bumbal?"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        style={{
                            borderRadius: '20px',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%'
                        }}
                    />
                </Box>
            </Box>
            <Button
                variant="contained"
                component={Link}
                target="_blank"
                href="https://www.bumbal.eu/"
                sx={{
                    borderRadius: "5px",
                    fontSize: "0.875rem",
                    fontWeight: 700,
                    letterSpacing: "0.4px",
                    textTransform: "none",
                    mt: "30px",
                    px: "30px",
                }}
            >
                {t('home.intro.visit')}
            </Button>
        </Box>
    );
}

export default IntroVideo;