import {
    Typography,
    Button,
    Link,
    Paper,
    LinearProgress,
    LinearProgressProps,
    Box
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { Subject } from "../../../../interface/CourseContent";

interface ComponentProps {
    subject: Subject;
    url: string;
    index: number;
    progress: number;
}

type SubjectCardProps = LinearProgressProps & ComponentProps;

const SubjectCard = ({ progress, subject, url, index, ...props }: SubjectCardProps) => {
    const { t } = useTranslation();

    const btnTextLabel = (): string => {
        if (progress === 100) { return t('subject.card_btn_start_over') }
        else if (progress === 0 ) { return t('subject.card_btn_start') }
        else { return t('subject.card_btn_continue')}
    };

    return (
        <Paper
            elevation={3}
            sx={{
                height: "160px",
                width: "100%",
                borderRadius: '10px',
                display: "flex",
                flexDirection: "column",
                p: 0,
            }}
        >
            <LinearProgress
                variant="determinate"
                color="primary"
                value={progress}
                {...props}
                sx={{
                    backgroundColor: '#F6F6F6',
                    height: '13px',
                    borderTopLeftRadius: '10px',
                    borderTopRightRadius: '10px'
                }}
            />
            <Box display="flex" sx={{ flexDirection: 'column', alignItems: 'center', height: '100%' }}>
                <Box textAlign="center" flexGrow={1} paddingTop={1}>
                    <Typography variant="h5" fontWeight="600" sx={{ mb: -1 }}>{index + 1}</Typography>
                    <Typography variant="h5" fontWeight="600">{subject.title}</Typography>
                </Box>
                <Button
                    component={Link}
                    href={url}
                    variant="contained"
                    sx={{ mb: 2 }}
                >
                    {btnTextLabel()}
                </Button>
            </Box>
        </Paper>
    );
};

export default SubjectCard;
