/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal Client Api
 * Bumbal API documentation
 *
 * The version of the OpenAPI document: 2.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ModelApiResponse,
    ModelApiResponseFromJSON,
    ModelApiResponseToJSON,
    PackageLineListResponse,
    PackageLineListResponseFromJSON,
    PackageLineListResponseToJSON,
    PackageLineModel,
    PackageLineModelFromJSON,
    PackageLineModelToJSON,
    PackageLineRetrieveListArguments,
    PackageLineRetrieveListArgumentsFromJSON,
    PackageLineRetrieveListArgumentsToJSON,
    PackageLineUpdateArguments,
    PackageLineUpdateArgumentsFromJSON,
    PackageLineUpdateArgumentsToJSON,
} from '../models';

export interface CreatePackageLineRequest {
    body?: PackageLineModel;
}

export interface DeletePackageLineRequest {
    packageLineId: number;
}

export interface RetrieveListPackageLineRequest {
    arguments: PackageLineRetrieveListArguments;
}

export interface RetrievePackageLineRequest {
    packageLineId: number;
    includePackageLineStatus: boolean;
    includePackageLineTypeName: boolean;
    includePackageLineMetaData: boolean;
    includeAddressObject: boolean;
    includeTimeSlots: boolean;
    includeTimeSlotTags: boolean;
    includeRouteInfo: boolean;
    includeDriverInfo: boolean;
    includeCommunication: boolean;
    includePackageLineLinks: boolean;
    includePackageLinesInfo: boolean;
    includePackageLineFiles: boolean;
    includePackageLineFilesMetaData: boolean;
}

export interface SetPackageLineRequest {
    body?: PackageLineModel;
}

export interface UpdatePackageLineRequest {
    body?: PackageLineUpdateArguments;
}

/**
 * 
 */
export class PackageLineApi extends runtime.BaseAPI {

    /**
     * Create or update an PackageLine. If id or links are set in the data, and a corresponding package-line    *     is found in Bumbal, an update will be performed.
     * Create or update an Package Line
     */
    async createPackageLineRaw(requestParameters: CreatePackageLineRequest): Promise<runtime.ApiResponse<ModelApiResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/package-line`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PackageLineModelToJSON(requestParameters.body),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelApiResponseFromJSON(jsonValue));
    }

    /**
     * Create or update an PackageLine. If id or links are set in the data, and a corresponding package-line    *     is found in Bumbal, an update will be performed.
     * Create or update an Package Line
     */
    createPackageLine = async(requestParameters: CreatePackageLineRequest): Promise<ModelApiResponse> => {
        const response = await this.createPackageLineRaw(requestParameters);
        return await response.value();
    }

    /**
     * Delete an package-line
     * Delete an package-line
     */
    async deletePackageLineRaw(requestParameters: DeletePackageLineRequest): Promise<runtime.ApiResponse<ModelApiResponse>> {
        if (requestParameters.packageLineId === null || requestParameters.packageLineId === undefined) {
            throw new runtime.RequiredError('packageLineId','Required parameter requestParameters.packageLineId was null or undefined when calling deletePackageLine.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/package-line/{packageLineId}`.replace(`{${"packageLineId"}}`, encodeURIComponent(String(requestParameters.packageLineId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelApiResponseFromJSON(jsonValue));
    }

    /**
     * Delete an package-line
     * Delete an package-line
     */
    deletePackageLine = async(requestParameters: DeletePackageLineRequest): Promise<ModelApiResponse> => {
        const response = await this.deletePackageLineRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieve List of PackageLines
     * Retrieve List of PackageLines
     */
    async retrieveListPackageLineRaw(requestParameters: RetrieveListPackageLineRequest): Promise<runtime.ApiResponse<PackageLineListResponse>> {
        if (requestParameters.arguments === null || requestParameters.arguments === undefined) {
            throw new runtime.RequiredError('arguments','Required parameter requestParameters.arguments was null or undefined when calling retrieveListPackageLine.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/package-line`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PackageLineRetrieveListArgumentsToJSON(requestParameters.arguments),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PackageLineListResponseFromJSON(jsonValue));
    }

    /**
     * Retrieve List of PackageLines
     * Retrieve List of PackageLines
     */
    retrieveListPackageLine = async(requestParameters: RetrieveListPackageLineRequest): Promise<PackageLineListResponse> => {
        const response = await this.retrieveListPackageLineRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns a single package-line
     * Find package-line by ID
     */
    async retrievePackageLineRaw(requestParameters: RetrievePackageLineRequest): Promise<runtime.ApiResponse<PackageLineModel>> {
        if (requestParameters.packageLineId === null || requestParameters.packageLineId === undefined) {
            throw new runtime.RequiredError('packageLineId','Required parameter requestParameters.packageLineId was null or undefined when calling retrievePackageLine.');
        }

        if (requestParameters.includePackageLineStatus === null || requestParameters.includePackageLineStatus === undefined) {
            throw new runtime.RequiredError('includePackageLineStatus','Required parameter requestParameters.includePackageLineStatus was null or undefined when calling retrievePackageLine.');
        }

        if (requestParameters.includePackageLineTypeName === null || requestParameters.includePackageLineTypeName === undefined) {
            throw new runtime.RequiredError('includePackageLineTypeName','Required parameter requestParameters.includePackageLineTypeName was null or undefined when calling retrievePackageLine.');
        }

        if (requestParameters.includePackageLineMetaData === null || requestParameters.includePackageLineMetaData === undefined) {
            throw new runtime.RequiredError('includePackageLineMetaData','Required parameter requestParameters.includePackageLineMetaData was null or undefined when calling retrievePackageLine.');
        }

        if (requestParameters.includeAddressObject === null || requestParameters.includeAddressObject === undefined) {
            throw new runtime.RequiredError('includeAddressObject','Required parameter requestParameters.includeAddressObject was null or undefined when calling retrievePackageLine.');
        }

        if (requestParameters.includeTimeSlots === null || requestParameters.includeTimeSlots === undefined) {
            throw new runtime.RequiredError('includeTimeSlots','Required parameter requestParameters.includeTimeSlots was null or undefined when calling retrievePackageLine.');
        }

        if (requestParameters.includeTimeSlotTags === null || requestParameters.includeTimeSlotTags === undefined) {
            throw new runtime.RequiredError('includeTimeSlotTags','Required parameter requestParameters.includeTimeSlotTags was null or undefined when calling retrievePackageLine.');
        }

        if (requestParameters.includeRouteInfo === null || requestParameters.includeRouteInfo === undefined) {
            throw new runtime.RequiredError('includeRouteInfo','Required parameter requestParameters.includeRouteInfo was null or undefined when calling retrievePackageLine.');
        }

        if (requestParameters.includeDriverInfo === null || requestParameters.includeDriverInfo === undefined) {
            throw new runtime.RequiredError('includeDriverInfo','Required parameter requestParameters.includeDriverInfo was null or undefined when calling retrievePackageLine.');
        }

        if (requestParameters.includeCommunication === null || requestParameters.includeCommunication === undefined) {
            throw new runtime.RequiredError('includeCommunication','Required parameter requestParameters.includeCommunication was null or undefined when calling retrievePackageLine.');
        }

        if (requestParameters.includePackageLineLinks === null || requestParameters.includePackageLineLinks === undefined) {
            throw new runtime.RequiredError('includePackageLineLinks','Required parameter requestParameters.includePackageLineLinks was null or undefined when calling retrievePackageLine.');
        }

        if (requestParameters.includePackageLinesInfo === null || requestParameters.includePackageLinesInfo === undefined) {
            throw new runtime.RequiredError('includePackageLinesInfo','Required parameter requestParameters.includePackageLinesInfo was null or undefined when calling retrievePackageLine.');
        }

        if (requestParameters.includePackageLineFiles === null || requestParameters.includePackageLineFiles === undefined) {
            throw new runtime.RequiredError('includePackageLineFiles','Required parameter requestParameters.includePackageLineFiles was null or undefined when calling retrievePackageLine.');
        }

        if (requestParameters.includePackageLineFilesMetaData === null || requestParameters.includePackageLineFilesMetaData === undefined) {
            throw new runtime.RequiredError('includePackageLineFilesMetaData','Required parameter requestParameters.includePackageLineFilesMetaData was null or undefined when calling retrievePackageLine.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.includePackageLineStatus !== undefined) {
            queryParameters['include_package_line_status'] = requestParameters.includePackageLineStatus;
        }

        if (requestParameters.includePackageLineTypeName !== undefined) {
            queryParameters['include_package_line_type_name'] = requestParameters.includePackageLineTypeName;
        }

        if (requestParameters.includePackageLineMetaData !== undefined) {
            queryParameters['include_package_line_meta_data'] = requestParameters.includePackageLineMetaData;
        }

        if (requestParameters.includeAddressObject !== undefined) {
            queryParameters['include_address_object'] = requestParameters.includeAddressObject;
        }

        if (requestParameters.includeTimeSlots !== undefined) {
            queryParameters['include_time_slots'] = requestParameters.includeTimeSlots;
        }

        if (requestParameters.includeTimeSlotTags !== undefined) {
            queryParameters['include_time_slot_tags'] = requestParameters.includeTimeSlotTags;
        }

        if (requestParameters.includeRouteInfo !== undefined) {
            queryParameters['include_route_info'] = requestParameters.includeRouteInfo;
        }

        if (requestParameters.includeDriverInfo !== undefined) {
            queryParameters['include_driver_info'] = requestParameters.includeDriverInfo;
        }

        if (requestParameters.includeCommunication !== undefined) {
            queryParameters['include_communication'] = requestParameters.includeCommunication;
        }

        if (requestParameters.includePackageLineLinks !== undefined) {
            queryParameters['include_package_line_links'] = requestParameters.includePackageLineLinks;
        }

        if (requestParameters.includePackageLinesInfo !== undefined) {
            queryParameters['include_package_lines_info'] = requestParameters.includePackageLinesInfo;
        }

        if (requestParameters.includePackageLineFiles !== undefined) {
            queryParameters['include_package_line_files'] = requestParameters.includePackageLineFiles;
        }

        if (requestParameters.includePackageLineFilesMetaData !== undefined) {
            queryParameters['include_package_line_files_meta_data'] = requestParameters.includePackageLineFilesMetaData;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/package-line/{packageLineId}`.replace(`{${"packageLineId"}}`, encodeURIComponent(String(requestParameters.packageLineId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PackageLineModelFromJSON(jsonValue));
    }

    /**
     * Returns a single package-line
     * Find package-line by ID
     */
    retrievePackageLine = async(requestParameters: RetrievePackageLineRequest): Promise<PackageLineModel> => {
        const response = await this.retrievePackageLineRaw(requestParameters);
        return await response.value();
    }

    /**
     * Set (create or update) an PackageLine. If id or links are set in the data, and a corresponding package-line is found in Bumbal, an update will be performed.
     * Set (create or update) an PackageLine
     */
    async setPackageLineRaw(requestParameters: SetPackageLineRequest): Promise<runtime.ApiResponse<ModelApiResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/package-line/set`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PackageLineModelToJSON(requestParameters.body),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelApiResponseFromJSON(jsonValue));
    }

    /**
     * Set (create or update) an PackageLine. If id or links are set in the data, and a corresponding package-line is found in Bumbal, an update will be performed.
     * Set (create or update) an PackageLine
     */
    setPackageLine = async(requestParameters: SetPackageLineRequest): Promise<ModelApiResponse> => {
        const response = await this.setPackageLineRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update package-lines in bulk
     * Update package-lines in bulk
     */
    async updatePackageLineRaw(requestParameters: UpdatePackageLineRequest): Promise<runtime.ApiResponse<ModelApiResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/package-line/update`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PackageLineUpdateArgumentsToJSON(requestParameters.body),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelApiResponseFromJSON(jsonValue));
    }

    /**
     * Update package-lines in bulk
     * Update package-lines in bulk
     */
    updatePackageLine = async(requestParameters: UpdatePackageLineRequest): Promise<ModelApiResponse> => {
        const response = await this.updatePackageLineRaw(requestParameters);
        return await response.value();
    }

}
