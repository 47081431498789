import * as React from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper/Paper";
import { Box } from "@mui/system";
import { IconButton, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Group_73 from "../../../assets/Group_73.png";
import Planner from "../../../assets/Planner.png";
import Driver from "../../../assets/Driver.png";
import Admin from "../../../assets/Admin.png";
import Arrow from "../../../assets/arrow.png";
import { useTranslation } from "react-i18next";
import { BBNavLink } from "../../../interface/NavLink";
import { Link } from 'react-router-dom';

const useStyles = makeStyles(() => ({
    containerTitle: {
        textAlign: "center",
        paddingBottom: "50px",
        paddingTop: "20px",
        fontWeight: '600'
    },
    containerPos: {
        padding: "50px 0px 120px",
        zIndex: 0,
        position: 'relative',
        boxShadow: "0px 1px 27px -16px rgba(0,0,0,0.73)"
    },
    paperStyles: {
        height: "350px",
        width: "250px",
        padding: "25px 3px 3px",
        textDecoration: "none",
        borderRadius: "30px",
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column'
    },
    paperLabels: {
        margin: "20px",
    },
    gridStyles: {
        margin: 'auto',
        flexDirection: "row",
        justifyContent: "space-between",
        maxWidth: '1000px'
    }
}));

type CourseCard = BBNavLink & { image: string }

export const navLinks: CourseCard[] = [
    {
        to: '/driver',
        text: 'driver',
        image: Driver
    },
    {
        to: '/admin',
        text: 'admin',
        image: Admin
    },
    {
        to: '/planner',
        text: 'planner',
        image: Planner
    }
]

const CourseOverview = () => {
    const classes = useStyles();

    const { t } = useTranslation();

    return (
        <Box className={classes.containerPos} >
            <Typography variant="h4" className={classes.containerTitle}>
                {t('home.course_overview.start_learning')}
            </Typography>

            <Box sx={{ backgroundImage: `url(${Group_73})`, backgroundRepeat: "round" }}>
                <Grid container className={classes.gridStyles}>
                    {navLinks.map((link) => (
                        <Grid item container direction="column" md={4} alignItems="center" key={link.text} sx={{my: '30px'}}>
                            <Link to={link.to} style={{ textDecoration: 'none' }}>
                                <Paper elevation={3} className={classes.paperStyles}>
                                    <Box sx={{ flexGrow: 1 }}>
                                        <Typography variant="h5" className={classes.paperLabels}>
                                            {t(`navigation.links.${link.text}`)}
                                        </Typography>
                                        <img src={link.image} alt="" height="150px"></img>
                                    </Box>
                                    <Box sx={{ textAlign: 'right' }}>
                                        <IconButton color="primary" aria-label="go to course">
                                            <img src={Arrow} alt=""></img>
                                        </IconButton>
                                    </Box>
                                </Paper>
                            </Link>
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </Box>
    );
}

export default CourseOverview;