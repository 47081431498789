/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal Client Api
 * Bumbal API documentation
 *
 * The version of the OpenAPI document: 2.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel } from '../runtime';
import {
    SystemProviderFiltersModel,
    SystemProviderFiltersModelFromJSON,
    SystemProviderFiltersModelFromJSONTyped,
    SystemProviderFiltersModelToJSON,
} from './';


export interface SystemProviderRetrieveListArgumentsInterface {
    filters?: SystemProviderFiltersModel;
    limit?: number;
    offset?: number;
    sorting_column?: SystemProviderRetrieveListArgumentsSortingColumnEnum;
    sorting_direction?: SystemProviderRetrieveListArgumentsSortingDirectionEnum;
    search_text?: string;
    as_list?: boolean;
}

/**
 * 
 * @export
 * @interface SystemProviderRetrieveListArguments
 */
export class SystemProviderRetrieveListArguments extends BaseModel<SystemProviderRetrieveListArgumentsInterface> {

    /**
     * 
     * @type {SystemProviderFiltersModel}
     * @memberof SystemProviderRetrieveListArguments
     */
    filters?: SystemProviderFiltersModel;
    /**
     * 
     * @type {number}
     * @memberof SystemProviderRetrieveListArguments
     */
    limit?: number;
    /**
     * 
     * @type {number}
     * @memberof SystemProviderRetrieveListArguments
     */
    offset?: number;
    /**
     * Sorting Column
     * @type {string}
     * @memberof SystemProviderRetrieveListArguments
     */
    sorting_column?: SystemProviderRetrieveListArgumentsSortingColumnEnum;
    /**
     * Sorting Direction
     * @type {string}
     * @memberof SystemProviderRetrieveListArguments
     */
    sorting_direction?: SystemProviderRetrieveListArgumentsSortingDirectionEnum;
    /**
     * 
     * @type {string}
     * @memberof SystemProviderRetrieveListArguments
     */
    search_text?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SystemProviderRetrieveListArguments
     */
    as_list?: boolean;

    constructor(data?: SystemProviderRetrieveListArgumentsInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(value: SystemProviderRetrieveListArgumentsInterface): { [property: string]: any } {
        return {};
    }

}

export function SystemProviderRetrieveListArgumentsFromJSON(json: any): SystemProviderRetrieveListArguments {
    return SystemProviderRetrieveListArgumentsFromJSONTyped(json, false);
}

export function SystemProviderRetrieveListArgumentsFromJSONTyped(json: any, ignoreDiscriminator: boolean): SystemProviderRetrieveListArguments {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = SystemProviderRetrieveListArguments;
    try {
        let localClass = require.context('../override', true)('./SystemProviderRetrieveListArguments');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'filters': !exists(json, 'filters') ? undefined : SystemProviderFiltersModelFromJSON(json['filters']),
        'limit': !exists(json, 'limit') ? undefined : Number(json['limit']),
        'offset': !exists(json, 'offset') ? undefined : Number(json['offset']),
        'sorting_column': !exists(json, 'sorting_column') ? undefined : json['sorting_column'],
        'sorting_direction': !exists(json, 'sorting_direction') ? undefined : json['sorting_direction'],
        'search_text': !exists(json, 'search_text') ? undefined : json['search_text'],
        'as_list': !exists(json, 'as_list') ? undefined : json['as_list'],
    });
}

export function SystemProviderRetrieveListArgumentsToJSON(value?: SystemProviderRetrieveListArguments | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = SystemProviderRetrieveListArguments;
    try {
        let localClass = require.context('../override', true)('./SystemProviderRetrieveListArguments');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return {
        
        'filters': SystemProviderFiltersModelToJSON(value.filters),
        'limit': value.limit,
        'offset': value.offset,
        'sorting_column': value.sorting_column,
        'sorting_direction': value.sorting_direction,
        'search_text': value.search_text,
        'as_list': value.as_list,
        ...createClass.toJson(value),
    };
}

/**
* @export
* @enum {string}
*/
export enum SystemProviderRetrieveListArgumentsSortingColumnEnum {
    Id = 'id',
    Name = 'name'
}
/**
* @export
* @enum {string}
*/
export enum SystemProviderRetrieveListArgumentsSortingDirectionEnum {
    Asc = 'asc',
    Desc = 'desc'
}


