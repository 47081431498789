/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal Client Api
 * Bumbal API documentation
 *
 * The version of the OpenAPI document: 2.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel } from '../runtime';
import {
    RouteFiltersModel,
    RouteFiltersModelFromJSON,
    RouteFiltersModelFromJSONTyped,
    RouteFiltersModelToJSON,
    RouteOptionsModel,
    RouteOptionsModelFromJSON,
    RouteOptionsModelFromJSONTyped,
    RouteOptionsModelToJSON,
} from './';


export interface RouteRetrieveListArgumentsInterface {
    options?: RouteOptionsModel;
    filters?: RouteFiltersModel;
    limit?: number;
    offset?: number;
    search_text?: string;
    sorting_column?: RouteRetrieveListArgumentsSortingColumnEnum;
    sorting_direction?: RouteRetrieveListArgumentsSortingDirectionEnum;
    as_list?: boolean;
    count_only?: boolean;
}

/**
 * 
 * @export
 * @interface RouteRetrieveListArguments
 */
export class RouteRetrieveListArguments extends BaseModel<RouteRetrieveListArgumentsInterface> {

    /**
     * 
     * @type {RouteOptionsModel}
     * @memberof RouteRetrieveListArguments
     */
    options?: RouteOptionsModel;
    /**
     * 
     * @type {RouteFiltersModel}
     * @memberof RouteRetrieveListArguments
     */
    filters?: RouteFiltersModel;
    /**
     * 
     * @type {number}
     * @memberof RouteRetrieveListArguments
     */
    limit?: number;
    /**
     * 
     * @type {number}
     * @memberof RouteRetrieveListArguments
     */
    offset?: number;
    /**
     * 
     * @type {string}
     * @memberof RouteRetrieveListArguments
     */
    search_text?: string;
    /**
     * Sorting Column
     * @type {string}
     * @memberof RouteRetrieveListArguments
     */
    sorting_column?: RouteRetrieveListArgumentsSortingColumnEnum;
    /**
     * Sorting Direction
     * @type {string}
     * @memberof RouteRetrieveListArguments
     */
    sorting_direction?: RouteRetrieveListArgumentsSortingDirectionEnum;
    /**
     * 
     * @type {boolean}
     * @memberof RouteRetrieveListArguments
     */
    as_list?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RouteRetrieveListArguments
     */
    count_only?: boolean;

    constructor(data?: RouteRetrieveListArgumentsInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(value: RouteRetrieveListArgumentsInterface): { [property: string]: any } {
        return {};
    }

}

export function RouteRetrieveListArgumentsFromJSON(json: any): RouteRetrieveListArguments {
    return RouteRetrieveListArgumentsFromJSONTyped(json, false);
}

export function RouteRetrieveListArgumentsFromJSONTyped(json: any, ignoreDiscriminator: boolean): RouteRetrieveListArguments {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = RouteRetrieveListArguments;
    try {
        let localClass = require.context('../override', true)('./RouteRetrieveListArguments');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'options': !exists(json, 'options') ? undefined : RouteOptionsModelFromJSON(json['options']),
        'filters': !exists(json, 'filters') ? undefined : RouteFiltersModelFromJSON(json['filters']),
        'limit': !exists(json, 'limit') ? undefined : Number(json['limit']),
        'offset': !exists(json, 'offset') ? undefined : Number(json['offset']),
        'search_text': !exists(json, 'search_text') ? undefined : json['search_text'],
        'sorting_column': !exists(json, 'sorting_column') ? undefined : json['sorting_column'],
        'sorting_direction': !exists(json, 'sorting_direction') ? undefined : json['sorting_direction'],
        'as_list': !exists(json, 'as_list') ? undefined : json['as_list'],
        'count_only': !exists(json, 'count_only') ? undefined : json['count_only'],
    });
}

export function RouteRetrieveListArgumentsToJSON(value?: RouteRetrieveListArguments | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = RouteRetrieveListArguments;
    try {
        let localClass = require.context('../override', true)('./RouteRetrieveListArguments');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return {
        
        'options': RouteOptionsModelToJSON(value.options),
        'filters': RouteFiltersModelToJSON(value.filters),
        'limit': value.limit,
        'offset': value.offset,
        'search_text': value.search_text,
        'sorting_column': value.sorting_column,
        'sorting_direction': value.sorting_direction,
        'as_list': value.as_list,
        'count_only': value.count_only,
        ...createClass.toJson(value),
    };
}

/**
* @export
* @enum {string}
*/
export enum RouteRetrieveListArgumentsSortingColumnEnum {
    StatusId = 'status_id',
    DateTimeFrom = 'date_time_from',
    Id = 'id',
    Nr = 'nr',
    Name = 'name',
    EarliestDateTime = 'earliest_date_time',
    LatestDateTime = 'latest_date_time'
}
/**
* @export
* @enum {string}
*/
export enum RouteRetrieveListArgumentsSortingDirectionEnum {
    Asc = 'asc',
    Desc = 'desc'
}


