/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal Client Api
 * Bumbal API documentation
 *
 * The version of the OpenAPI document: 2.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AddressAppliedModel,
    AddressAppliedModelFromJSON,
    AddressAppliedModelToJSON,
    ModelApiResponse,
    ModelApiResponseFromJSON,
    ModelApiResponseToJSON,
} from '../models';

export interface RetrieveAddressAppliedRequest {
    addressId: number;
}

export interface UpdateAddressAppliedRequest {
    addressId: number;
    body?: AddressAppliedModel;
}

/**
 * 
 */
export class AddressAppliedApi extends runtime.BaseAPI {

    /**
     * Retrieve an Applied Address
     * Retrieve an Applied Address
     */
    async retrieveAddressAppliedRaw(requestParameters: RetrieveAddressAppliedRequest): Promise<runtime.ApiResponse<AddressAppliedModel>> {
        if (requestParameters.addressId === null || requestParameters.addressId === undefined) {
            throw new runtime.RequiredError('addressId','Required parameter requestParameters.addressId was null or undefined when calling retrieveAddressApplied.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/address-applied/{addressId}`.replace(`{${"addressId"}}`, encodeURIComponent(String(requestParameters.addressId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AddressAppliedModelFromJSON(jsonValue));
    }

    /**
     * Retrieve an Applied Address
     * Retrieve an Applied Address
     */
    retrieveAddressApplied = async(requestParameters: RetrieveAddressAppliedRequest): Promise<AddressAppliedModel> => {
        const response = await this.retrieveAddressAppliedRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update an AddressApplied
     * Update a AddressApplied
     */
    async updateAddressAppliedRaw(requestParameters: UpdateAddressAppliedRequest): Promise<runtime.ApiResponse<ModelApiResponse>> {
        if (requestParameters.addressId === null || requestParameters.addressId === undefined) {
            throw new runtime.RequiredError('addressId','Required parameter requestParameters.addressId was null or undefined when calling updateAddressApplied.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/address-applied/{addressId}`.replace(`{${"addressId"}}`, encodeURIComponent(String(requestParameters.addressId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AddressAppliedModelToJSON(requestParameters.body),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelApiResponseFromJSON(jsonValue));
    }

    /**
     * Update an AddressApplied
     * Update a AddressApplied
     */
    updateAddressApplied = async(requestParameters: UpdateAddressAppliedRequest): Promise<ModelApiResponse> => {
        const response = await this.updateAddressAppliedRaw(requestParameters);
        return await response.value();
    }

}
