/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal Client Api
 * Bumbal API documentation
 *
 * The version of the OpenAPI document: 2.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ModelApiResponse,
    ModelApiResponseFromJSON,
    ModelApiResponseToJSON,
    TagListResponse,
    TagListResponseFromJSON,
    TagListResponseToJSON,
    TagModel,
    TagModelFromJSON,
    TagModelToJSON,
    TagsRetrieveListArguments,
    TagsRetrieveListArgumentsFromJSON,
    TagsRetrieveListArgumentsToJSON,
} from '../models';

export interface CreateTagRequest {
    body?: TagModel;
}

export interface DeleteTagRequest {
    tagId: number;
}

export interface RetrieveListTagsRequest {
    arguments: TagsRetrieveListArguments;
}

export interface RetrieveTagRequest {
    tagId: number;
}

export interface UpdateTagRequest {
    tagId: number;
}

/**
 * 
 */
export class TagsApi extends runtime.BaseAPI {

    /**
     * adds a tag to an object
     * adds a tag to an object
     */
    async addTagToObjectRaw(): Promise<runtime.ApiResponse<ModelApiResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/tags/add-tag-to-object`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelApiResponseFromJSON(jsonValue));
    }

    /**
     * adds a tag to an object
     * adds a tag to an object
     */
    addTagToObject = async(): Promise<ModelApiResponse> => {
        const response = await this.addTagToObjectRaw();
        return await response.value();
    }

    /**
     * Add a new Tag
     * Add a new Tag
     */
    async createTagRaw(requestParameters: CreateTagRequest): Promise<runtime.ApiResponse<ModelApiResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/tags`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TagModelToJSON(requestParameters.body),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelApiResponseFromJSON(jsonValue));
    }

    /**
     * Add a new Tag
     * Add a new Tag
     */
    createTag = async(requestParameters: CreateTagRequest): Promise<ModelApiResponse> => {
        const response = await this.createTagRaw(requestParameters);
        return await response.value();
    }

    /**
     * Delete a Tag
     * Delete a Tag
     */
    async deleteTagRaw(requestParameters: DeleteTagRequest): Promise<runtime.ApiResponse<ModelApiResponse>> {
        if (requestParameters.tagId === null || requestParameters.tagId === undefined) {
            throw new runtime.RequiredError('tagId','Required parameter requestParameters.tagId was null or undefined when calling deleteTag.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/tags/{tagId}`.replace(`{${"tagId"}}`, encodeURIComponent(String(requestParameters.tagId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelApiResponseFromJSON(jsonValue));
    }

    /**
     * Delete a Tag
     * Delete a Tag
     */
    deleteTag = async(requestParameters: DeleteTagRequest): Promise<ModelApiResponse> => {
        const response = await this.deleteTagRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieve List of Tags
     * Retrieve List of Tags
     */
    async retrieveListTagsRaw(requestParameters: RetrieveListTagsRequest): Promise<runtime.ApiResponse<TagListResponse>> {
        if (requestParameters.arguments === null || requestParameters.arguments === undefined) {
            throw new runtime.RequiredError('arguments','Required parameter requestParameters.arguments was null or undefined when calling retrieveListTags.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/tags`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: TagsRetrieveListArgumentsToJSON(requestParameters.arguments),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TagListResponseFromJSON(jsonValue));
    }

    /**
     * Retrieve List of Tags
     * Retrieve List of Tags
     */
    retrieveListTags = async(requestParameters: RetrieveListTagsRequest): Promise<TagListResponse> => {
        const response = await this.retrieveListTagsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieve an Tag
     * Retrieve a Tag
     */
    async retrieveTagRaw(requestParameters: RetrieveTagRequest): Promise<runtime.ApiResponse<TagModel>> {
        if (requestParameters.tagId === null || requestParameters.tagId === undefined) {
            throw new runtime.RequiredError('tagId','Required parameter requestParameters.tagId was null or undefined when calling retrieveTag.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/tags/{tagId}`.replace(`{${"tagId"}}`, encodeURIComponent(String(requestParameters.tagId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TagModelFromJSON(jsonValue));
    }

    /**
     * Retrieve an Tag
     * Retrieve a Tag
     */
    retrieveTag = async(requestParameters: RetrieveTagRequest): Promise<TagModel> => {
        const response = await this.retrieveTagRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update an Tag
     * Update a Tag
     */
    async updateTagRaw(requestParameters: UpdateTagRequest): Promise<runtime.ApiResponse<ModelApiResponse>> {
        if (requestParameters.tagId === null || requestParameters.tagId === undefined) {
            throw new runtime.RequiredError('tagId','Required parameter requestParameters.tagId was null or undefined when calling updateTag.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/tags/{tagId}`.replace(`{${"tagId"}}`, encodeURIComponent(String(requestParameters.tagId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelApiResponseFromJSON(jsonValue));
    }

    /**
     * Update an Tag
     * Update a Tag
     */
    updateTag = async(requestParameters: UpdateTagRequest): Promise<ModelApiResponse> => {
        const response = await this.updateTagRaw(requestParameters);
        return await response.value();
    }

}
