import JWTToken from '../interface/JWTToken';
import jwtDecode from 'jwt-decode';
import LocalStorageManager from './LocalStorageManager';

export default class TokenUtil {

    private static default: TokenUtil;

    private token?: JWTToken;
    private role?: string;
    private lsm: LocalStorageManager;

    private constructor() {
        this.lsm = new LocalStorageManager();
        this.token = this.getDecodedToken();
    }

    static getTokenUtil(): TokenUtil {
        if (TokenUtil.default == null) {
            TokenUtil.default = new TokenUtil();
        }
        return TokenUtil.default;
    }

    getRole(): string {
        return this.role ?? '';
    }

    isGuest(): boolean {
        return this.role === '1';
    }

    setToken(decodedToken: JWTToken): TokenUtil {
        this.token = decodedToken;
        this.role = decodedToken.role_id;
        return this;
    }

    getDecodedToken(): JWTToken | undefined {
        try {
            if(this.lsm.getToken() != null) {
                this.refreshToken();
                if (this.isTokenExpired()) {
                    this.clearToken();
                    this.lsm.clearLogin();
                    window.location.href = '/sign-in';
                    return undefined;
                }
            } else {
                this.clearToken()
            }
        } catch (e) {
            this.clearToken();
        }
        return this.token
    }

    private refreshToken() {
        const jwt = this.lsm.getToken();
        if (jwt != null) {
            this.setToken(jwtDecode(jwt))
        }
    }

    clearToken() {
        this.token = undefined;
        this.role = undefined;
    }

    isAdmin(): boolean {
        return this.getRole() === '5'
    }

    isTokenExpired(): boolean {
        try {
            this.refreshToken();
            
            return Math.floor(Date.now()/1000) > (this.token?.exp ?? 0);
        } catch (e) {
            return false;
        }
    }

}
