import React, { useState } from 'react';
import CourseHeader from './components/CourseHeader';
import { useTranslation } from 'react-i18next';
import CourseContent from './components/CourseContent';

const CourseView = ({ location }) => {
    const { i18n } = useTranslation();
    const language = i18n.resolvedLanguage;
    const content = require(`../../static/users/${language}${location.pathname}.json`);

    const [activeVersion, setActiveVersion] = useState<number>(0);

    const changeVersion = (version: number) => {
        setActiveVersion(version)
    }

    return (
        <>
            <CourseHeader
                title={content.title}
                isActive={activeVersion === 1}
                version={content.versions[activeVersion]}
                showSwitch={content.versions.length > 1}
                changeVersion={changeVersion}
            />
            <CourseContent
                content={content}
                course={content.title.toLowerCase()}
                activeVersion={activeVersion}
            />
        </>
    )
}

export default CourseView;