import FetchMap from "../../models/FetchMap";
import ActionTypes from "../../settings/ActionTypes";
import {
    AppState,
    AppActionTypes
} from './types';

const initialState: AppState = {
    error: new Map<ActionTypes, any[]>(),
    isFetchingData: new FetchMap<ActionTypes>(),
};

export function AppReducers(state = initialState, action: AppActionTypes): AppState {
    let newState = state;
    let fetchingAction: string = action.type.replace('_ERROR', '').replace('_FAILURE', '').replace('_SUCCESS', '');
    if (action.type.includes('API')) {
        newState = {
            ...newState,
            isFetchingData: newState.isFetchingData.set(ActionTypes[fetchingAction], !(action.type.includes('_ERROR') || action.type.includes('_FAILURE') || action.type.includes('_SUCCESS')))
        }
    }
    if (action.type.includes('FAILURE') || action.type.includes('ERROR')) {
        if (newState.error.has(action.type)) {
            newState = {
                ...newState,
                error: newState.error.set(action.type, newState.error.get(action.type)?.concat(action) ?? [action])
            }
        } else {
            newState = {
                ...newState,
                error: newState.error.set(action.type, [action])
            }
        }
    }

    switch (action.type) {
        default:
            return newState;
    }
}