/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal Client Api
 * Bumbal API documentation
 *
 * The version of the OpenAPI document: 2.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel } from '../runtime';

export interface LinkModelInterface {
    id?: number;
    link_id?: string;
    object_type?: number;
    object_id?: number;
    provider_id?: number;
    provider_reference?: string;
    provider_name?: string;
}

/**
 * 
 * @export
 * @interface LinkModel
 */
export class LinkModel extends BaseModel<LinkModelInterface> {

    /**
     * Unique ID
     * @type {number}
     * @memberof LinkModel
     */
    id?: number;
    /**
     * The external Link ID
     * @type {string}
     * @memberof LinkModel
     */
    link_id?: string;
    /**
     * The object type id which this link is connected to
     * @type {number}
     * @memberof LinkModel
     */
    object_type?: number;
    /**
     * The object ID which this link is connected to
     * @type {number}
     * @memberof LinkModel
     */
    object_id?: number;
    /**
     * The Provider ID which this link is connected to
     * @type {number}
     * @memberof LinkModel
     */
    provider_id?: number;
    /**
     * A Provider reference which this link is connected to
     * @type {string}
     * @memberof LinkModel
     */
    provider_reference?: string;
    /**
     * The name of the external Provider
     * @type {string}
     * @memberof LinkModel
     */
    provider_name?: string;

    constructor(data?: LinkModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(value: LinkModelInterface): { [property: string]: any } {
        return {};
    }

}

export function LinkModelFromJSON(json: any): LinkModel {
    return LinkModelFromJSONTyped(json, false);
}

export function LinkModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): LinkModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = LinkModel;
    try {
        let localClass = require.context('../override', true)('./LinkModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'id': !exists(json, 'id') ? undefined : Number(json['id']),
        'link_id': !exists(json, 'link_id') ? undefined : json['link_id'],
        'object_type': !exists(json, 'object_type') ? undefined : Number(json['object_type']),
        'object_id': !exists(json, 'object_id') ? undefined : Number(json['object_id']),
        'provider_id': !exists(json, 'provider_id') ? undefined : Number(json['provider_id']),
        'provider_reference': !exists(json, 'provider_reference') ? undefined : json['provider_reference'],
        'provider_name': !exists(json, 'provider_name') ? undefined : json['provider_name'],
    });
}

export function LinkModelToJSON(value?: LinkModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = LinkModel;
    try {
        let localClass = require.context('../override', true)('./LinkModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return {
        
        'id': value.id,
        'link_id': value.link_id,
        'object_type': value.object_type,
        'object_id': value.object_id,
        'provider_id': value.provider_id,
        'provider_reference': value.provider_reference,
        'provider_name': value.provider_name,
        ...createClass.toJson(value),
    };
}


