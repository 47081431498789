/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal Client Api
 * Bumbal API documentation
 *
 * The version of the OpenAPI document: 2.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel } from '../runtime';
import {
    EmailModel,
    EmailModelFromJSON,
    EmailModelFromJSONTyped,
    EmailModelToJSON,
    FileModel,
    FileModelFromJSON,
    FileModelFromJSONTyped,
    FileModelToJSON,
    LinkModel,
    LinkModelFromJSON,
    LinkModelFromJSONTyped,
    LinkModelToJSON,
    MetaDataModel,
    MetaDataModelFromJSON,
    MetaDataModelFromJSONTyped,
    MetaDataModelToJSON,
    NoteModel,
    NoteModelFromJSON,
    NoteModelFromJSONTyped,
    NoteModelToJSON,
    OpeningHoursRuleModel,
    OpeningHoursRuleModelFromJSON,
    OpeningHoursRuleModelFromJSONTyped,
    OpeningHoursRuleModelToJSON,
    PhoneNrModel,
    PhoneNrModelFromJSON,
    PhoneNrModelFromJSONTyped,
    PhoneNrModelToJSON,
    TagModel,
    TagModelFromJSON,
    TagModelFromJSONTyped,
    TagModelToJSON,
} from './';


export interface AddressModelInterface {
    id?: number;
    address_id?: number;
    party_id?: number;
    party_name?: string;
    code?: string;
    summary?: string;
    full_name?: string;
    name_1?: string;
    name_2?: string;
    street_1?: string;
    street_2?: string;
    full_addressline?: string;
    house_nr?: string;
    house_nr_addendum?: string;
    zipcode?: string;
    city?: string;
    state?: string;
    iso_country?: string;
    country_name?: string;
    time_from?: string;
    time_to?: string;
    opening_hours?: Array<OpeningHoursRuleModel>;
    duration?: number;
    address_type_names?: Array<string>;
    emails?: Array<EmailModel>;
    phone_nrs?: Array<PhoneNrModel>;
    latitude?: string;
    longitude?: string;
    contact_person?: string;
    user_id?: number;
    links?: Array<LinkModel>;
    meta_data?: Array<MetaDataModel>;
    notes?: Array<NoteModel>;
    files?: Array<FileModel>;
    tag_names?: Array<string>;
    tags?: Array<TagModel>;
}

/**
 * 
 * @export
 * @interface AddressModel
 */
export class AddressModel extends BaseModel<AddressModelInterface> {

    /**
     * 
     * @type {number}
     * @memberof AddressModel
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof AddressModel
     */
    address_id?: number;
    /**
     * 
     * @type {number}
     * @memberof AddressModel
     */
    party_id?: number;
    /**
     * 
     * @type {string}
     * @memberof AddressModel
     */
    party_name?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressModel
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressModel
     */
    summary?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressModel
     */
    full_name?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressModel
     */
    name_1?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressModel
     */
    name_2?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressModel
     */
    street_1?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressModel
     */
    street_2?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressModel
     */
    full_addressline?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressModel
     */
    house_nr?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressModel
     */
    house_nr_addendum?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressModel
     */
    zipcode?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressModel
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressModel
     */
    state?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressModel
     */
    iso_country?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressModel
     */
    country_name?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressModel
     */
    time_from?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressModel
     */
    time_to?: string;
    /**
     * 
     * @type {Array<OpeningHoursRuleModel>}
     * @memberof AddressModel
     */
    opening_hours?: Array<OpeningHoursRuleModel>;
    /**
     * Default duration for activities on this address in minutes
     * @type {number}
     * @memberof AddressModel
     */
    duration?: number;
    /**
     * Address Type names
     * @type {Array<string>}
     * @memberof AddressModel
     */
    address_type_names?: Array<string>;
    /**
     * 
     * @type {Array<EmailModel>}
     * @memberof AddressModel
     */
    emails?: Array<EmailModel>;
    /**
     * 
     * @type {Array<PhoneNrModel>}
     * @memberof AddressModel
     */
    phone_nrs?: Array<PhoneNrModel>;
    /**
     * 
     * @type {string}
     * @memberof AddressModel
     */
    latitude?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressModel
     */
    longitude?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressModel
     */
    contact_person?: string;
    /**
     * 
     * @type {number}
     * @memberof AddressModel
     */
    user_id?: number;
    /**
     * 
     * @type {Array<LinkModel>}
     * @memberof AddressModel
     */
    links?: Array<LinkModel>;
    /**
     * 
     * @type {Array<MetaDataModel>}
     * @memberof AddressModel
     */
    meta_data?: Array<MetaDataModel>;
    /**
     * 
     * @type {Array<NoteModel>}
     * @memberof AddressModel
     */
    notes?: Array<NoteModel>;
    /**
     * 
     * @type {Array<FileModel>}
     * @memberof AddressModel
     */
    files?: Array<FileModel>;
    /**
     * Tag names
     * @type {Array<string>}
     * @memberof AddressModel
     */
    tag_names?: Array<string>;
    /**
     * 
     * @type {Array<TagModel>}
     * @memberof AddressModel
     */
    tags?: Array<TagModel>;

    constructor(data?: AddressModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(value: AddressModelInterface): { [property: string]: any } {
        return {};
    }

}

export function AddressModelFromJSON(json: any): AddressModel {
    return AddressModelFromJSONTyped(json, false);
}

export function AddressModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddressModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = AddressModel;
    try {
        let localClass = require.context('../override', true)('./AddressModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'id': !exists(json, 'id') ? undefined : Number(json['id']),
        'address_id': !exists(json, 'address_id') ? undefined : Number(json['address_id']),
        'party_id': !exists(json, 'party_id') ? undefined : Number(json['party_id']),
        'party_name': !exists(json, 'party_name') ? undefined : json['party_name'],
        'code': !exists(json, 'code') ? undefined : json['code'],
        'summary': !exists(json, 'summary') ? undefined : json['summary'],
        'full_name': !exists(json, 'full_name') ? undefined : json['full_name'],
        'name_1': !exists(json, 'name_1') ? undefined : json['name_1'],
        'name_2': !exists(json, 'name_2') ? undefined : json['name_2'],
        'street_1': !exists(json, 'street_1') ? undefined : json['street_1'],
        'street_2': !exists(json, 'street_2') ? undefined : json['street_2'],
        'full_addressline': !exists(json, 'full_addressline') ? undefined : json['full_addressline'],
        'house_nr': !exists(json, 'house_nr') ? undefined : json['house_nr'],
        'house_nr_addendum': !exists(json, 'house_nr_addendum') ? undefined : json['house_nr_addendum'],
        'zipcode': !exists(json, 'zipcode') ? undefined : json['zipcode'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'state': !exists(json, 'state') ? undefined : json['state'],
        'iso_country': !exists(json, 'iso_country') ? undefined : json['iso_country'],
        'country_name': !exists(json, 'country_name') ? undefined : json['country_name'],
        'time_from': !exists(json, 'time_from') ? undefined : json['time_from'],
        'time_to': !exists(json, 'time_to') ? undefined : json['time_to'],
        'opening_hours': !exists(json, 'opening_hours') ? undefined : ((json['opening_hours'] as Array<any>).map(OpeningHoursRuleModelFromJSON)),
        'duration': !exists(json, 'duration') ? undefined : Number(json['duration']),
        'address_type_names': !exists(json, 'address_type_names') ? undefined : json['address_type_names'],
        'emails': !exists(json, 'emails') ? undefined : ((json['emails'] as Array<any>).map(EmailModelFromJSON)),
        'phone_nrs': !exists(json, 'phone_nrs') ? undefined : ((json['phone_nrs'] as Array<any>).map(PhoneNrModelFromJSON)),
        'latitude': !exists(json, 'latitude') ? undefined : json['latitude'],
        'longitude': !exists(json, 'longitude') ? undefined : json['longitude'],
        'contact_person': !exists(json, 'contact_person') ? undefined : json['contact_person'],
        'user_id': !exists(json, 'user_id') ? undefined : Number(json['user_id']),
        'links': !exists(json, 'links') ? undefined : ((json['links'] as Array<any>).map(LinkModelFromJSON)),
        'meta_data': !exists(json, 'meta_data') ? undefined : ((json['meta_data'] as Array<any>).map(MetaDataModelFromJSON)),
        'notes': !exists(json, 'notes') ? undefined : ((json['notes'] as Array<any>).map(NoteModelFromJSON)),
        'files': !exists(json, 'files') ? undefined : ((json['files'] as Array<any>).map(FileModelFromJSON)),
        'tag_names': !exists(json, 'tag_names') ? undefined : json['tag_names'],
        'tags': !exists(json, 'tags') ? undefined : ((json['tags'] as Array<any>).map(TagModelFromJSON)),
    });
}

export function AddressModelToJSON(value?: AddressModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = AddressModel;
    try {
        let localClass = require.context('../override', true)('./AddressModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return {
        
        'id': value.id,
        'address_id': value.address_id,
        'party_id': value.party_id,
        'party_name': value.party_name,
        'code': value.code,
        'summary': value.summary,
        'full_name': value.full_name,
        'name_1': value.name_1,
        'name_2': value.name_2,
        'street_1': value.street_1,
        'street_2': value.street_2,
        'full_addressline': value.full_addressline,
        'house_nr': value.house_nr,
        'house_nr_addendum': value.house_nr_addendum,
        'zipcode': value.zipcode,
        'city': value.city,
        'state': value.state,
        'iso_country': value.iso_country,
        'country_name': value.country_name,
        'time_from': value.time_from,
        'time_to': value.time_to,
        'opening_hours': value.opening_hours === undefined ? undefined : ((value.opening_hours as Array<any>).map(OpeningHoursRuleModelToJSON)),
        'duration': value.duration,
        'address_type_names': value.address_type_names,
        'emails': value.emails === undefined ? undefined : ((value.emails as Array<any>).map(EmailModelToJSON)),
        'phone_nrs': value.phone_nrs === undefined ? undefined : ((value.phone_nrs as Array<any>).map(PhoneNrModelToJSON)),
        'latitude': value.latitude,
        'longitude': value.longitude,
        'contact_person': value.contact_person,
        'user_id': value.user_id,
        'links': value.links === undefined ? undefined : ((value.links as Array<any>).map(LinkModelToJSON)),
        'meta_data': value.meta_data === undefined ? undefined : ((value.meta_data as Array<any>).map(MetaDataModelToJSON)),
        'notes': value.notes === undefined ? undefined : ((value.notes as Array<any>).map(NoteModelToJSON)),
        'files': value.files === undefined ? undefined : ((value.files as Array<any>).map(FileModelToJSON)),
        'tag_names': value.tag_names,
        'tags': value.tags === undefined ? undefined : ((value.tags as Array<any>).map(TagModelToJSON)),
        ...createClass.toJson(value),
    };
}


