/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal Client Api
 * Bumbal API documentation
 *
 * The version of the OpenAPI document: 2.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AuthenticateModel,
    AuthenticateModelFromJSON,
    AuthenticateModelToJSON,
    CredentialsModel,
    CredentialsModelFromJSON,
    CredentialsModelToJSON,
    ModelApiResponse,
    ModelApiResponseFromJSON,
    ModelApiResponseToJSON,
} from '../models';

export interface AuthenticateSignInRequest {
    body?: CredentialsModel;
}

export interface AuthenticateSignOutRequest {
    token: string;
}

/**
 * 
 */
export class AuthenticateApi extends runtime.BaseAPI {

    /**
     * Check a token for validity
     * Check a token for validity
     */
    async authenticateCheckTokenRaw(): Promise<runtime.ApiResponse<ModelApiResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/authenticate/check-token`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelApiResponseFromJSON(jsonValue));
    }

    /**
     * Check a token for validity
     * Check a token for validity
     */
    authenticateCheckToken = async(): Promise<ModelApiResponse> => {
        const response = await this.authenticateCheckTokenRaw();
        return await response.value();
    }

    /**
     * Sign In with your user credentials, you will get a access token if successful
     * Sign In with your user credentials
     */
    async authenticateSignInRaw(requestParameters: AuthenticateSignInRequest): Promise<runtime.ApiResponse<AuthenticateModel>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/authenticate/sign-in`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CredentialsModelToJSON(requestParameters.body),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AuthenticateModelFromJSON(jsonValue));
    }

    /**
     * Sign In with your user credentials, you will get a access token if successful
     * Sign In with your user credentials
     */
    authenticateSignIn = async(requestParameters: AuthenticateSignInRequest): Promise<AuthenticateModel> => {
        const response = await this.authenticateSignInRaw(requestParameters);
        return await response.value();
    }

    /**
     * Sign out using your access token
     * Sign out
     */
    async authenticateSignOutRaw(requestParameters: AuthenticateSignOutRequest): Promise<runtime.ApiResponse<ModelApiResponse>> {
        if (requestParameters.token === null || requestParameters.token === undefined) {
            throw new runtime.RequiredError('token','Required parameter requestParameters.token was null or undefined when calling authenticateSignOut.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.token !== undefined) {
            queryParameters['token'] = requestParameters.token;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/authenticate/sign-out`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelApiResponseFromJSON(jsonValue));
    }

    /**
     * Sign out using your access token
     * Sign out
     */
    authenticateSignOut = async(requestParameters: AuthenticateSignOutRequest): Promise<ModelApiResponse> => {
        const response = await this.authenticateSignOutRaw(requestParameters);
        return await response.value();
    }

}
