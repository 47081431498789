/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal Client Api
 * Bumbal API documentation
 *
 * The version of the OpenAPI document: 2.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel } from '../runtime';
import {
    FileModel,
    FileModelFromJSON,
    FileModelFromJSONTyped,
    FileModelToJSON,
    LinkModel,
    LinkModelFromJSON,
    LinkModelFromJSONTyped,
    LinkModelToJSON,
    MetaDataModel,
    MetaDataModelFromJSON,
    MetaDataModelFromJSONTyped,
    MetaDataModelToJSON,
    NoteModel,
    NoteModelFromJSON,
    NoteModelFromJSONTyped,
    NoteModelToJSON,
    TagModel,
    TagModelFromJSON,
    TagModelFromJSONTyped,
    TagModelToJSON,
    UsersModel,
    UsersModelFromJSON,
    UsersModelFromJSONTyped,
    UsersModelToJSON,
} from './';


export interface PartyModelInterface {
    id?: number;
    party_type_name?: PartyModelPartyTypeNameEnum;
    party_type_id?: number;
    name_1?: string;
    name_2?: string;
    nr?: string;
    contact_person?: string;
    url?: string;
    tags?: Array<TagModel>;
    tag_names?: Array<string>;
    tag_ids?: Array<number>;
    links?: Array<LinkModel>;
    meta_data?: Array<MetaDataModel>;
    notes?: Array<NoteModel>;
    files?: Array<FileModel>;
    created_at?: Date;
    updated_at?: Date;
    party_created_by_user?: UsersModel;
    party_updated_by_user?: UsersModel;
}

/**
 * 
 * @export
 * @interface PartyModel
 */
export class PartyModel extends BaseModel<PartyModelInterface> {

    /**
     * Unique Identifier
     * @type {number}
     * @memberof PartyModel
     */
    id?: number;
    /**
     * Type of this party
     * @type {string}
     * @memberof PartyModel
     */
    party_type_name?: PartyModelPartyTypeNameEnum;
    /**
     * PartyTypeID of this party. 2 = contractor, 3 = booking
     * @type {number}
     * @memberof PartyModel
     */
    party_type_id?: number;
    /**
     * Name 1 for party
     * @type {string}
     * @memberof PartyModel
     */
    name_1?: string;
    /**
     * Name 2 for party
     * @type {string}
     * @memberof PartyModel
     */
    name_2?: string;
    /**
     * Number of this party
     * @type {string}
     * @memberof PartyModel
     */
    nr?: string;
    /**
     * Contact person for party
     * @type {string}
     * @memberof PartyModel
     */
    contact_person?: string;
    /**
     * Url for party website
     * @type {string}
     * @memberof PartyModel
     */
    url?: string;
    /**
     * 
     * @type {Array<TagModel>}
     * @memberof PartyModel
     */
    tags?: Array<TagModel>;
    /**
     * Tag names
     * @type {Array<string>}
     * @memberof PartyModel
     */
    tag_names?: Array<string>;
    /**
     * Tag ids
     * @type {Array<number>}
     * @memberof PartyModel
     */
    tag_ids?: Array<number>;
    /**
     * 
     * @type {Array<LinkModel>}
     * @memberof PartyModel
     */
    links?: Array<LinkModel>;
    /**
     * 
     * @type {Array<MetaDataModel>}
     * @memberof PartyModel
     */
    meta_data?: Array<MetaDataModel>;
    /**
     * 
     * @type {Array<NoteModel>}
     * @memberof PartyModel
     */
    notes?: Array<NoteModel>;
    /**
     * 
     * @type {Array<FileModel>}
     * @memberof PartyModel
     */
    files?: Array<FileModel>;
    /**
     * created_at date time
     * @type {Date}
     * @memberof PartyModel
     */
    created_at?: Date;
    /**
     * updated_at date time
     * @type {Date}
     * @memberof PartyModel
     */
    updated_at?: Date;
    /**
     * 
     * @type {UsersModel}
     * @memberof PartyModel
     */
    party_created_by_user?: UsersModel;
    /**
     * 
     * @type {UsersModel}
     * @memberof PartyModel
     */
    party_updated_by_user?: UsersModel;

    constructor(data?: PartyModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(value: PartyModelInterface): { [property: string]: any } {
        return {};
    }

}

export function PartyModelFromJSON(json: any): PartyModel {
    return PartyModelFromJSONTyped(json, false);
}

export function PartyModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): PartyModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = PartyModel;
    try {
        let localClass = require.context('../override', true)('./PartyModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'id': !exists(json, 'id') ? undefined : Number(json['id']),
        'party_type_name': !exists(json, 'party_type_name') ? undefined : json['party_type_name'],
        'party_type_id': !exists(json, 'party_type_id') ? undefined : Number(json['party_type_id']),
        'name_1': !exists(json, 'name_1') ? undefined : json['name_1'],
        'name_2': !exists(json, 'name_2') ? undefined : json['name_2'],
        'nr': !exists(json, 'nr') ? undefined : json['nr'],
        'contact_person': !exists(json, 'contact_person') ? undefined : json['contact_person'],
        'url': !exists(json, 'url') ? undefined : json['url'],
        'tags': !exists(json, 'tags') ? undefined : ((json['tags'] as Array<any>).map(TagModelFromJSON)),
        'tag_names': !exists(json, 'tag_names') ? undefined : json['tag_names'],
        'tag_ids': !exists(json, 'tag_ids') ? undefined : json['tag_ids'],
        'links': !exists(json, 'links') ? undefined : ((json['links'] as Array<any>).map(LinkModelFromJSON)),
        'meta_data': !exists(json, 'meta_data') ? undefined : ((json['meta_data'] as Array<any>).map(MetaDataModelFromJSON)),
        'notes': !exists(json, 'notes') ? undefined : ((json['notes'] as Array<any>).map(NoteModelFromJSON)),
        'files': !exists(json, 'files') ? undefined : ((json['files'] as Array<any>).map(FileModelFromJSON)),
        'created_at': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'].replace(' ', 'T'))),
        'updated_at': !exists(json, 'updated_at') ? undefined : (new Date(json['updated_at'].replace(' ', 'T'))),
        'party_created_by_user': !exists(json, 'party_created_by_user') ? undefined : UsersModelFromJSON(json['party_created_by_user']),
        'party_updated_by_user': !exists(json, 'party_updated_by_user') ? undefined : UsersModelFromJSON(json['party_updated_by_user']),
    });
}

export function PartyModelToJSON(value?: PartyModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = PartyModel;
    try {
        let localClass = require.context('../override', true)('./PartyModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return {
        
        'id': value.id,
        'party_type_name': value.party_type_name,
        'party_type_id': value.party_type_id,
        'name_1': value.name_1,
        'name_2': value.name_2,
        'nr': value.nr,
        'contact_person': value.contact_person,
        'url': value.url,
        'tags': value.tags === undefined ? undefined : ((value.tags as Array<any>).map(TagModelToJSON)),
        'tag_names': value.tag_names,
        'tag_ids': value.tag_ids,
        'links': value.links === undefined ? undefined : ((value.links as Array<any>).map(LinkModelToJSON)),
        'meta_data': value.meta_data === undefined ? undefined : ((value.meta_data as Array<any>).map(MetaDataModelToJSON)),
        'notes': value.notes === undefined ? undefined : ((value.notes as Array<any>).map(NoteModelToJSON)),
        'files': value.files === undefined ? undefined : ((value.files as Array<any>).map(FileModelToJSON)),
        'created_at': value.created_at === undefined ? undefined : (value.created_at.toApiFormat()),
        'updated_at': value.updated_at === undefined ? undefined : (value.updated_at.toApiFormat()),
        'party_created_by_user': UsersModelToJSON(value.party_created_by_user),
        'party_updated_by_user': UsersModelToJSON(value.party_updated_by_user),
        ...createClass.toJson(value),
    };
}

/**
* @export
* @enum {string}
*/
export enum PartyModelPartyTypeNameEnum {
    Contractor = 'contractor',
    Booking = 'booking'
}


