/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal Client Api
 * Bumbal API documentation
 *
 * The version of the OpenAPI document: 2.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiResponse18,
    ApiResponse18FromJSON,
    ApiResponse18ToJSON,
    ApiResponse401,
    ApiResponse401FromJSON,
    ApiResponse401ToJSON,
    ApiResponse403,
    ApiResponse403FromJSON,
    ApiResponse403ToJSON,
    ApiResponse405,
    ApiResponse405FromJSON,
    ApiResponse405ToJSON,
    ApiResponse48,
    ApiResponse48FromJSON,
    ApiResponse48ToJSON,
    ApiResponse49,
    ApiResponse49FromJSON,
    ApiResponse49ToJSON,
    ApiResponse50,
    ApiResponse50FromJSON,
    ApiResponse50ToJSON,
    ModelApiResponse,
    ModelApiResponseFromJSON,
    ModelApiResponseToJSON,
    QuestionnaireTemplateQuestionTextListResponse,
    QuestionnaireTemplateQuestionTextListResponseFromJSON,
    QuestionnaireTemplateQuestionTextListResponseToJSON,
    QuestionnaireTemplateQuestionTextModel,
    QuestionnaireTemplateQuestionTextModelFromJSON,
    QuestionnaireTemplateQuestionTextModelToJSON,
    QuestionnaireTemplateQuestionTextRetrieveListArguments,
    QuestionnaireTemplateQuestionTextRetrieveListArgumentsFromJSON,
    QuestionnaireTemplateQuestionTextRetrieveListArgumentsToJSON,
} from '../models';

export interface CreateQuestionnaireTemplateQuestionTextRequest {
    body?: QuestionnaireTemplateQuestionTextModel;
}

export interface DeleteQuestionnaireTemplateQuestionTextRequest {
    notificationId: number;
}

export interface RetrieveListQuestionnaireTemplateQuestionTextRequest {
    arguments: QuestionnaireTemplateQuestionTextRetrieveListArguments;
}

export interface RetrieveQuestionnaireTemplateQuestionTextRequest {
    notificationId: number;
}

export interface SetQuestionnaireTemplateQuestionTextRequest {
    body?: QuestionnaireTemplateQuestionTextModel;
}

export interface UpdateQuestionnaireTemplateQuestionTextRequest {
    notificationId: number;
    body?: QuestionnaireTemplateQuestionTextModel;
}

/**
 * 
 */
export class QuestionnaireTemplateQuestionTextApi extends runtime.BaseAPI {

    /**
     * Add a new QuestionnaireTemplateQuestionText
     * Add a new QuestionnaireTemplateQuestionText
     */
    async createQuestionnaireTemplateQuestionTextRaw(requestParameters: CreateQuestionnaireTemplateQuestionTextRequest): Promise<runtime.ApiResponse<ApiResponse50>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/questionnaire-template-question-text`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: QuestionnaireTemplateQuestionTextModelToJSON(requestParameters.body),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResponse50FromJSON(jsonValue));
    }

    /**
     * Add a new QuestionnaireTemplateQuestionText
     * Add a new QuestionnaireTemplateQuestionText
     */
    createQuestionnaireTemplateQuestionText = async(requestParameters: CreateQuestionnaireTemplateQuestionTextRequest): Promise<ApiResponse50> => {
        const response = await this.createQuestionnaireTemplateQuestionTextRaw(requestParameters);
        return await response.value();
    }

    /**
     * Delete an QuestionnaireTemplateQuestionText entry
     * Delete an QuestionnaireTemplateQuestionText entry
     */
    async deleteQuestionnaireTemplateQuestionTextRaw(requestParameters: DeleteQuestionnaireTemplateQuestionTextRequest): Promise<runtime.ApiResponse<ApiResponse49>> {
        if (requestParameters.notificationId === null || requestParameters.notificationId === undefined) {
            throw new runtime.RequiredError('notificationId','Required parameter requestParameters.notificationId was null or undefined when calling deleteQuestionnaireTemplateQuestionText.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/questionnaire-template-question-text/{questionnaire-template-question-textId}`.replace(`{${"notificationId"}}`, encodeURIComponent(String(requestParameters.notificationId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResponse49FromJSON(jsonValue));
    }

    /**
     * Delete an QuestionnaireTemplateQuestionText entry
     * Delete an QuestionnaireTemplateQuestionText entry
     */
    deleteQuestionnaireTemplateQuestionText = async(requestParameters: DeleteQuestionnaireTemplateQuestionTextRequest): Promise<ApiResponse49> => {
        const response = await this.deleteQuestionnaireTemplateQuestionTextRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieve List of QuestionnaireTemplateQuestionText
     * Retrieve List of QuestionnaireTemplateQuestionText
     */
    async retrieveListQuestionnaireTemplateQuestionTextRaw(requestParameters: RetrieveListQuestionnaireTemplateQuestionTextRequest): Promise<runtime.ApiResponse<QuestionnaireTemplateQuestionTextListResponse>> {
        if (requestParameters.arguments === null || requestParameters.arguments === undefined) {
            throw new runtime.RequiredError('arguments','Required parameter requestParameters.arguments was null or undefined when calling retrieveListQuestionnaireTemplateQuestionText.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/questionnaire-template-question-text`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: QuestionnaireTemplateQuestionTextRetrieveListArgumentsToJSON(requestParameters.arguments),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => QuestionnaireTemplateQuestionTextListResponseFromJSON(jsonValue));
    }

    /**
     * Retrieve List of QuestionnaireTemplateQuestionText
     * Retrieve List of QuestionnaireTemplateQuestionText
     */
    retrieveListQuestionnaireTemplateQuestionText = async(requestParameters: RetrieveListQuestionnaireTemplateQuestionTextRequest): Promise<QuestionnaireTemplateQuestionTextListResponse> => {
        const response = await this.retrieveListQuestionnaireTemplateQuestionTextRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieve an QuestionnaireTemplateQuestionText
     * Retrieve a QuestionnaireTemplateQuestionText
     */
    async retrieveQuestionnaireTemplateQuestionTextRaw(requestParameters: RetrieveQuestionnaireTemplateQuestionTextRequest): Promise<runtime.ApiResponse<QuestionnaireTemplateQuestionTextModel>> {
        if (requestParameters.notificationId === null || requestParameters.notificationId === undefined) {
            throw new runtime.RequiredError('notificationId','Required parameter requestParameters.notificationId was null or undefined when calling retrieveQuestionnaireTemplateQuestionText.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/questionnaire-template-question-text/{questionnaire-template-question-textId}`.replace(`{${"notificationId"}}`, encodeURIComponent(String(requestParameters.notificationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => QuestionnaireTemplateQuestionTextModelFromJSON(jsonValue));
    }

    /**
     * Retrieve an QuestionnaireTemplateQuestionText
     * Retrieve a QuestionnaireTemplateQuestionText
     */
    retrieveQuestionnaireTemplateQuestionText = async(requestParameters: RetrieveQuestionnaireTemplateQuestionTextRequest): Promise<QuestionnaireTemplateQuestionTextModel> => {
        const response = await this.retrieveQuestionnaireTemplateQuestionTextRaw(requestParameters);
        return await response.value();
    }

    /**
     * Set (create or update) a d=QuestionnaireTemplateQuestionText. If id or links are set in the data, and a corresponding QuestionnaireTemplateQuestionText is found in Bumbal, an update will be performed.
     * Set (create or update) a QuestionnaireTemplateQuestionText
     */
    async setQuestionnaireTemplateQuestionTextRaw(requestParameters: SetQuestionnaireTemplateQuestionTextRequest): Promise<runtime.ApiResponse<ModelApiResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/questionnaire-template-question-text/set`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: QuestionnaireTemplateQuestionTextModelToJSON(requestParameters.body),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelApiResponseFromJSON(jsonValue));
    }

    /**
     * Set (create or update) a d=QuestionnaireTemplateQuestionText. If id or links are set in the data, and a corresponding QuestionnaireTemplateQuestionText is found in Bumbal, an update will be performed.
     * Set (create or update) a QuestionnaireTemplateQuestionText
     */
    setQuestionnaireTemplateQuestionText = async(requestParameters: SetQuestionnaireTemplateQuestionTextRequest): Promise<ModelApiResponse> => {
        const response = await this.setQuestionnaireTemplateQuestionTextRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update a specific QuestionnaireTemplateQuestionText object
     * Update a specific QuestionnaireTemplateQuestionText object
     */
    async updateQuestionnaireTemplateQuestionTextRaw(requestParameters: UpdateQuestionnaireTemplateQuestionTextRequest): Promise<runtime.ApiResponse<ApiResponse48>> {
        if (requestParameters.notificationId === null || requestParameters.notificationId === undefined) {
            throw new runtime.RequiredError('notificationId','Required parameter requestParameters.notificationId was null or undefined when calling updateQuestionnaireTemplateQuestionText.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/questionnaire-template-question-text/{questionnaire-template-question-textId}`.replace(`{${"notificationId"}}`, encodeURIComponent(String(requestParameters.notificationId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: QuestionnaireTemplateQuestionTextModelToJSON(requestParameters.body),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResponse48FromJSON(jsonValue));
    }

    /**
     * Update a specific QuestionnaireTemplateQuestionText object
     * Update a specific QuestionnaireTemplateQuestionText object
     */
    updateQuestionnaireTemplateQuestionText = async(requestParameters: UpdateQuestionnaireTemplateQuestionTextRequest): Promise<ApiResponse48> => {
        const response = await this.updateQuestionnaireTemplateQuestionTextRaw(requestParameters);
        return await response.value();
    }

}
