/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal Client Api
 * Bumbal API documentation
 *
 * The version of the OpenAPI document: 2.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiResponse401,
    ApiResponse401FromJSON,
    ApiResponse401ToJSON,
    ApiResponse403,
    ApiResponse403FromJSON,
    ApiResponse403ToJSON,
    ApiResponse405,
    ApiResponse405FromJSON,
    ApiResponse405ToJSON,
    QuestionnaireQuestionTypeListResponse,
    QuestionnaireQuestionTypeListResponseFromJSON,
    QuestionnaireQuestionTypeListResponseToJSON,
    QuestionnaireQuestionTypeModel,
    QuestionnaireQuestionTypeModelFromJSON,
    QuestionnaireQuestionTypeModelToJSON,
    QuestionnaireQuestionTypeRetrieveListArguments,
    QuestionnaireQuestionTypeRetrieveListArgumentsFromJSON,
    QuestionnaireQuestionTypeRetrieveListArgumentsToJSON,
} from '../models';

export interface RetrieveListQuestionnaireQuestionTypeRequest {
    arguments: QuestionnaireQuestionTypeRetrieveListArguments;
}

export interface RetrieveQuestionnaireQuestionTypeRequest {
    notificationId: number;
}

/**
 * 
 */
export class QuestionnaireQuestionTypeApi extends runtime.BaseAPI {

    /**
     * Retrieve List of QuestionnaireQuestionType
     * Retrieve List of QuestionnaireQuestionType
     */
    async retrieveListQuestionnaireQuestionTypeRaw(requestParameters: RetrieveListQuestionnaireQuestionTypeRequest): Promise<runtime.ApiResponse<QuestionnaireQuestionTypeListResponse>> {
        if (requestParameters.arguments === null || requestParameters.arguments === undefined) {
            throw new runtime.RequiredError('arguments','Required parameter requestParameters.arguments was null or undefined when calling retrieveListQuestionnaireQuestionType.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/questionnaire-question-type`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: QuestionnaireQuestionTypeRetrieveListArgumentsToJSON(requestParameters.arguments),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => QuestionnaireQuestionTypeListResponseFromJSON(jsonValue));
    }

    /**
     * Retrieve List of QuestionnaireQuestionType
     * Retrieve List of QuestionnaireQuestionType
     */
    retrieveListQuestionnaireQuestionType = async(requestParameters: RetrieveListQuestionnaireQuestionTypeRequest): Promise<QuestionnaireQuestionTypeListResponse> => {
        const response = await this.retrieveListQuestionnaireQuestionTypeRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieve an QuestionnaireQuestionType
     * Retrieve a QuestionnaireQuestionType
     */
    async retrieveQuestionnaireQuestionTypeRaw(requestParameters: RetrieveQuestionnaireQuestionTypeRequest): Promise<runtime.ApiResponse<QuestionnaireQuestionTypeModel>> {
        if (requestParameters.notificationId === null || requestParameters.notificationId === undefined) {
            throw new runtime.RequiredError('notificationId','Required parameter requestParameters.notificationId was null or undefined when calling retrieveQuestionnaireQuestionType.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["ApiKey"] = this.configuration.apiKey("ApiKey"); // api_key authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/questionnaire-question-type/{questionnaire-question-typeId}`.replace(`{${"notificationId"}}`, encodeURIComponent(String(requestParameters.notificationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => QuestionnaireQuestionTypeModelFromJSON(jsonValue));
    }

    /**
     * Retrieve an QuestionnaireQuestionType
     * Retrieve a QuestionnaireQuestionType
     */
    retrieveQuestionnaireQuestionType = async(requestParameters: RetrieveQuestionnaireQuestionTypeRequest): Promise<QuestionnaireQuestionTypeModel> => {
        const response = await this.retrieveQuestionnaireQuestionTypeRaw(requestParameters);
        return await response.value();
    }

}
