import React from "react";
import { Box, Typography } from "@mui/material";

export  function ComingSoon() {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "auto",
        height: "100%",
      }}
    >
      <Typography variant="h1">Coming Soon...</Typography>
    </Box>
  );
}
